import { postWithAuth, getWithAuth } from '../../Basic/HttpService';

export const getAllSlippage = async () => {
    let res = await getWithAuth(`SlippageReasons`);
    return res;
};

export const getSlippageById = async (id) => {
    let res = await getWithAuth(`SlippageReasons/${id}`);
    return res;
};

export const postSlippageData = async (data) => {
    let res = await postWithAuth(`SlippageReasons`, data);
    return res;
};
