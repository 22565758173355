import { TextField, InputLabel, Typography, withStyles, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLoginInfo } from '../../Hooks/useLoginInfo';
import './inputStyle.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        '@media (max-width: 1024px)': {
            '& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiOutlinedInput-marginDense': {
                padding: 0
            }
        }
    }
}));

const Singleselect = (props) => {
    const classes = useStyles();
    const { forceEditable, isDisabled, inputLabel, isRequired, ...rest } = props;
    let { isReadOnlyUser } = useLoginInfo();
    forceEditable && (isReadOnlyUser = false);

    return isDisabled ? (
        <div className="input-style">
            <InputLabel shrink className="input_label">
                {inputLabel}
            </InputLabel>
            <div className="input_value">{props.value ? props.value?.name : 'N/A'}</div>
        </div>
    ) : (
        <>
            {inputLabel && (
                <InputLabel required={isRequired || false} shrink>
                    {inputLabel}
                </InputLabel>
            )}
            <Autocomplete
                id="combo-box-demo"
                style={{ marginTop: -8 }}
                disabled={isReadOnlyUser}
                // autoSelect
                freeSolo={props.freeSolo}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                renderInput={(params) => <TextField margin="dense" {...params} variant="outlined" placeholder={rest.placeholder} />}
                {...rest}
                className={classes.root}
            />
        </>
    );
};
export default Singleselect;
