import { useEffect, useState } from 'react';
import { get } from '../Basic/HttpService';
import { AppStorage } from '../Basic/storage-service';

export const usePortalSettings = () => {
    const portalSettings = AppStorage.getPortalSetting() || { expired: true };
    let [state, setState] = useState({});
    useEffect(() => {
        async function getPortalSetting() {
            if (portalSettings.expired) {
                const res = await get(`portalSetting`);
                if (res.data?.details[0]) {
                    AppStorage.setPortalSetting(res.data.details[0]);
                    setState(AppStorage.getPortalSetting());
                }
            } else {
                setState(AppStorage.getPortalSetting());
            }
        }
        getPortalSetting();
    }, []);

    return state;
};
