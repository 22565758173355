import ImageUploading from 'react-images-uploading';
import React, { useState, useEffect, useContext, useRef } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import { SecondaryButton } from '../../../../../Core/Controls';

import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import { useWindowSize } from '../../../../../Core/Hooks/useWindowSize';
import getResizedImage from '../../../../../Core/Controls/ImageResizer';
import { InspectionCheCkContaxt } from './AddInspectionCheck/InspectionCheckProvide';
import ImageCard from '../DriverDailyChecks/imageCard';

const ImageUpload = (props) => {
    const { disabled, state, onImageChange, submitImage, onClose, maxNumber, RemoveImage, isAPI, loader } = props;

    const [windowWidth, windowHeight] = useWindowSize();

    let imageSizeLimit = { height: 800, width: 800 };

    const fileInputRef = useRef(null);

    const resizeImage = (image, maxWidth, maxHeight) => {
        return new Promise((resolve) => {
            imageSizeLimit = { height: maxHeight, width: maxWidth };
            const img = new Image();
            img.src = image.data_url;

            const canvas = document.createElement('canvas');
            document.body.append(canvas);
            canvas.style.display = 'none';
            const ctx = canvas.getContext('2d');
            img.onload = () => {
                let ch = img.height;
                let cw = img.width;

                // Calculate aspect ratio and resize based on fixed width and height
                if (ch > imageSizeLimit.height || cw > imageSizeLimit.width) {
                    if (ch > cw) {
                        const ratio = imageSizeLimit.height / ch;
                        cw = cw * ratio;
                        ch = imageSizeLimit.height;
                    } else {
                        const ratio = imageSizeLimit.width / cw;
                        ch = ch * ratio;
                        cw = imageSizeLimit.width;
                    }
                }
                if (ch > imageSizeLimit.height) {
                    const ratio = imageSizeLimit.height / ch;
                    cw = cw * ratio;
                    ch = imageSizeLimit.height;
                }
                if (cw > imageSizeLimit.width) {
                    const ratio = imageSizeLimit.width / cw;
                    ch = ch * ratio;
                    cw = imageSizeLimit.width;
                }

                canvas.height = ch;
                canvas.width = cw;

                // Draw image to canvas
                ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, cw, ch);

                // Convert canvas to data URL (base64)
                const dataUrl = canvas.toDataURL('image/jpeg', 0.8); // You can adjust the quality from 0 to 1
                resolve({ ...image, data_url: dataUrl });
            };
        });
    };

    const getCompressedSize = (base64String) => {
        if (base64String) {
            let stringLength = base64String.length - 'data:image/jpeg;base64,'.length;
            let sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812; // base64 size formula
            return sizeInBytes / 1024; // Convert bytes to KB
        }
    };

    const onCompressedImageChange = (imageList) => {
        console.log(imageList, 'imageList');
        const resizedImages = imageList.map((image) => {
            if (image.data_url) {
                return resizeImage(image, 800, 800); // Resize to 300x300
            } else {
                return image;
            }
        });

        Promise.all(resizedImages).then((newImages) => {
            onImageChange(newImages);
            newImages.forEach((image) => {
                console.log('Compressed Image Size:', getCompressedSize(image.data_url), 'KB');
            });
        });
    };

    const [image, setImage] = useState();

    const scrollNext = (id) => {
        document.getElementById('slider-scroll').scrollBy({ left: 100, top: 0, behavior: 'smooth' });
        // smoothscroll.polyfill();
    };

    const scrollPrev = (id) => {
        document.getElementById('slider-scroll').scrollBy({ left: -100, top: 0, behavior: 'smooth' });
        // smoothscroll.polyfill();
    };

    const setCurrentImage = (image) => {
        setImage(image);
    };

    const TitleComp = (props) => {
        console.log(props, 'sassssss');

        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>Upload Image {props.inspectionDesc}</div>
            </div>
        );
    };

    const handleImageChange = (e) => {
        const files = e.target.files; // Get the list of files
        const array = [...state.selectedImages]; // Preserve the already selected images

        // Loop through each file and process them
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    // Create an object for each image
                    const object = {
                        data_url: reader.result, // Base64 image data
                        file: file // Original file
                    };

                    // Add the new image object to the array
                    array.push(object);

                    // Update the state or pass the new array to a callback function
                    onCompressedImageChange(array);

                    console.log(reader.result, 'reader.result');
                };

                // Read the file as a Data URL
                reader.readAsDataURL(file);
            }
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div>
            <ImageUploading multiple value={state.selectedImages} onChange={onCompressedImageChange} maxNumber={maxNumber} dataURLKey="data_url">
                {({ imageList, onImageUpload, onImageRemove }) => (
                    <DialogComp title={<TitleComp onImageUpload={onImageUpload} />} onClose={onClose} maxWidth="md" fullWidth className="dialog-container">
                        <div className="avatar">
                            {disabled ? null : (
                                <div>
                                    <input
                                        type="file"
                                        accept="image/*, application/pdf, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        // capture="camera" // This should invoke the camera
                                        onChange={handleImageChange}
                                        // style={{ display: 'none' }} // Hide the file input
                                        id="file-input"
                                        multiple
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                    />
                                    <Button variant="contained" color="primary" onClick={handleButtonClick}>
                                        <BackupIcon /> {windowWidth <= 900 ? '' : `\u00a0 Add Image`}
                                    </Button>{' '}
                                </div>

                                // &nbsp;
                                // </div>
                            )}
                            {/* {loader ? (
                                <CircularProgress />
                            ) : (
                                <> */}
                            {imageList.length > 0 ? (
                                <ImageCard avtar={!image ? imageList[0] : image} />
                            ) : (
                                <div className="image-card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography>No image found</Typography>
                                </div>
                            )}
                            {/* </> */}
                            {/* )} */}
                            <div className="carousel-wrapper-class">
                                <div className="other-images d-flex" id={'slider-scroll'}>
                                    {imageList.map((im, i) => (
                                        <span className="other-image" style={{ position: 'relative' }} key={i}>
                                            {disabled ? null : loader ? null : (
                                                <>
                                                    {isAPI ? (
                                                        <span style={{ position: 'absolute', top: '-8px', right: '-10px', zIndex: 10000000 }}>
                                                            <RemoveCircleIcon
                                                                onClick={() => RemoveImage(im, i)}
                                                                style={{ fill: '#f44336', cursor: 'pointer' }}
                                                            />
                                                        </span>
                                                    ) : (
                                                        <span style={{ position: 'absolute', top: '-8px', right: '-10px', zIndex: 10000000 }}>
                                                            <RemoveCircleIcon onClick={() => onImageRemove(i)} style={{ fill: '#f44336', cursor: 'pointer' }} />
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                            <img className={im === image ? 'active' : ''} onClick={() => setCurrentImage(im)} src={im} />
                                        </span>
                                    ))}
                                    <div className="arrow">
                                        <span className="arrow-left" onClick={() => scrollPrev()}>
                                            <ChevronLeftIcon />
                                        </span>
                                        <span className="arrow-right" onClick={() => scrollNext()}>
                                            <ChevronRightIcon />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {disabled ? null : (
                                <div style={{ marginTop: '5px' }}>
                                    <SecondaryButton onClick={submitImage} disabled={!state.showSubmit}>
                                        Submit
                                    </SecondaryButton>
                                </div>
                            )}
                        </div>
                    </DialogComp>
                )}
            </ImageUploading>
        </div>
    );
};

export default ImageUpload;
