import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import './SpeedDialStyle.scss';
import { Backdrop } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
        transform: 'translateZ(0px)',
        flexGrow: 1
    },
    exampleWrapper: {
        position: 'relative',
        marginTop: theme.spacing(3)
    },

    speedDial: {
        position: 'absolute',

        '&.MuiSpeedDial-directionLeft': {
            bottom: theme.spacing(2),
            right: theme.spacing(2)
        },
        '&.MuiSpeedDial-directionRight': {
            top: theme.spacing(2),
            left: theme.spacing(2)
        }
    }
}));

export default function SpeedDials(props) {
    const { actions, direction } = props;
    const classes = useStyles(props);
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <div className={(classes.root, 'speed-dial')}>
            <div className={(classes.exampleWrapper, 'speed-dial-body')}>
                <SpeedDial
                    ariaLabel="SpeedDial example"
                    varient="secondary"
                    className={classes.speedDial}
                    icon={<SpeedDialIcon />}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    open={open}
                    direction={direction}
                    color="secondary"
                >
                    {actions.map((m) => (
                        <SpeedDialAction key={m.name} icon={m.icon} tooltipTitle={m.name} onClick={m.action || null} />
                    ))}
                </SpeedDial>
            </div>
        </div>
    );
}
