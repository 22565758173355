import { postWithAuth, getWithAuth } from "../../Basic/HttpService";

export const getAllVehicleTypes = async () => {
  let res = await getWithAuth(`vehicletypes`);
  return res;
};

export const getVehicleTypesById = async (id) => {
  let res = await getWithAuth(`vehicletypes/${id}`);
  return res;
};

export const postVehicleTypesData = async (data) => {
  let res = await postWithAuth(`vehicletypes`, data);
  return res;
};

export const getVehicleTypesDropDown = async () => {
  let res = await getWithAuth(`vehicletypes/Dropdowns`);
  return res;
};
