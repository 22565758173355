import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { formatters } from '../../../../../../Core/Controls';
import AssignServiceAgentModal from './assignServiceAgentModal';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22
    },
    active: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    completed: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    active: {
        backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
    },
    completed: {
        backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
    }
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <SettingsIcon />,
        2: <GroupAddIcon />,
        3: <TrendingUpIcon />,
        4: <HomeWorkIcon />,
        5: <CheckCircleOutlineIcon />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export default function CustomizedRecallSteppers(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(1);

    const [step, setStep] = React.useState({ steps: [], serviceDate: false });

    useEffect(async () => {
        let TempStep = [
            {
                title: 'Created',
                value: formatters.DateTimeFormatter(props.stepData.recallDateCreated)
            },
            {
                title: 'Booked',
                AgentName: props.stepData.serviceAgent,
                value: formatters.DateTimeFormatter(props.stepData.recallDateBooked)
            },
            {
                title: 'Onsite',
                value: formatters.DateTimeFormatter(props.stepData.recallDateOnSite)
            },
            {
                title: 'In Workshop',
                value: formatters.DateTimeFormatter(props.stepData.recallDateWorkshop)
            },
            {
                title: 'Completed',
                value: formatters.DateTimeFormatter(props.stepData.recallDateCompleted)
            }
        ];

        setActiveStep(props.stepData.recallStatusID);
        setStep((st) => ({ ...st, steps: TempStep, totalSteps: TempStep.length }));
    }, [props.stepData]);

    const popUpHandler = (label) => {
        setStep((st) => ({
            ...st,
            serviceDate: true,
            fromPage:
                label === 'Booked'
                    ? 'serviceAgent'
                    : label === 'Completed'
                    ? 'CompleteRecall'
                    : label === 'Onsite'
                    ? 'onSite'
                    : label === 'In Workshop'
                    ? 'workShop'
                    : ''
        }));
    };

    const closeServiceAgentModal = (res) => {
        setStep((st) => ({
            ...st,
            serviceDate: false
        }));
        res && props.isRefreshDetails();
    };

    const ServiceAgentArrivalTimetitle =
        step.fromPage === 'serviceAgent'
            ? 'Booked Recall'
            : step.fromPage === 'CompleteRecall'
            ? 'Complete Recall'
            : step.fromPage === 'onSite'
            ? 'On Site'
            : step.fromPage === 'workShop'
            ? 'In Work Shop'
            : '';

    return (
        <div className={classes.root}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {step.steps.map((s, i) => (
                    <Step key={s.title}>
                        <StepLabel StepIconComponent={ColorlibStepIcon} onClick={activeStep === i ? () => popUpHandler(s.title) : ''}>
                            <div className={`${activeStep === i ? '' : 'stepper-label-to-hide'}`}>
                                <div>{s.AgentName ? `${s.AgentName}` : s.title}</div>
                                <Typography variant="caption" display="block">
                                    {s.value}
                                </Typography>
                            </div>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            {step.serviceDate ? (
                <DialogComp title={ServiceAgentArrivalTimetitle} onClose={() => closeServiceAgentModal(false)} maxWidth="xs" fullWidth>
                    <AssignServiceAgentModal
                        stepData={props.stepData}
                        recallID={props.stepData.recallID}
                        fromPage={step.fromPage}
                        onClose={closeServiceAgentModal}
                    />
                </DialogComp>
            ) : null}
        </div>
    );
}
