import { postWithAuth, getWithAuth,putWithAuth } from '../../Basic/HttpService';

export const getAllApiData = async () => {
    let res = await getWithAuth(`DriverLicenceTypes`);
    return res;
};

export const getApiDataByID = async (id) => {
    let res = await getWithAuth(`DriverLicenceTypes/${id}`);
    return res;
};

export const postDataToApi = async (data) => {
    let res = await postWithAuth(`DriverLicenceTypes`, data);
    return res;
};
export const updateDataToApi = async (data) => {
    let res = await putWithAuth(`DriverLicenceTypes/${data.driverLicenceTypeID}`, data);
    return res;
};