import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Table, TableBody, TableContainer, TableRow, Paper, TableCell, Typography, TableHead } from '@material-ui/core';
import moment from 'moment';

// import { useToast } from '../../../../../Core/Hooks/useToast';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { Link } from 'react-router-dom';
import { CustomChip } from '../../../../../Core/Controls';
import DriverVehicleModal from './manageDriver';
import DriverHistory from './driverHistory';
import DialogComp from '../../../../../Core/Dialog/DialogComp';

const gridStyle = {
    title: {
        color: '#f50057'
    },
    header: {
        borderBottom: '1px solid rgba(0,0,0,0.2)'
    },
    tbHead: {
        padding: '5px 0px 6px 8px'
    },
    tbContent: {
        margin: '8px 0px 14px 0px;'
    }
};

export const DriverInfo = (props) => {
    const [state, setState] = useState({
        showHistory: false,
        showManageDriver: false,
        driverList: []
    });

    const openModal = (params) => {
        setState((st) => ({
            ...st,
            [params]: !st[params]
        }));
    };

    return (
        <div>
            {state.showHistory && (
                <DialogComp title={`Driver History (${props.vehicleDisc})`} onClose={() => openModal('showHistory')} maxWidth="sm">
                    <DriverHistory vehicleID={props.vehicleID} vehicleDisc={props.vehicleDisc} />
                </DialogComp>
            )}
            {state.showManageDriver && (
                <DialogComp title="Manage Vehicle Driver" onClose={() => openModal('showManageDriver')} maxWidth="sm">
                    <DriverVehicleModal vehicleID={props.vehicleID} onClose={() => openModal('showManageDriver')} />
                </DialogComp>
            )}
            <Grid container>
                <Grid item xs={12}>
                    <TableContainer style={{ border: '1px solid rgba(0,0,0,0.2)' }}>
                        <div style={gridStyle.header}>
                            <div style={gridStyle.title}>
                                <Grid container style={{ alignItems: 'center', padding: '0px 6px' }}>
                                    <Grid xs={4} lg={4} item style={{ alignItems: 'center' }}>
                                        <Typography variant="h6" color="secondary" style={gridStyle.tbHead}>
                                            Driver
                                        </Typography>
                                    </Grid>

                                    <Grid xs={8} lg={8} container item justify="flex-end">
                                        {/* <Grid item xs={3}></Grid> */}
                                        <div >
                                            <CustomChip
                                                icon={LocalOfferIcon}
                                                style={{ marginRight: 3 }}
                                                label="Manage Driver"
                                                onClick={() => openModal('showManageDriver')}
                                            />
                                            <CustomChip
                                                style={{ marginRight: 5 }}
                                                icon={AssignmentIcon}
                                                label="History"
                                                onClick={() => openModal('showHistory')}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <Table aria-label="simple table" size="medium">
                            <TableBody>
                                <TableRow key={1}>
                                    <TableCell style={{ color: 'inherit' }} width="20%">
                                        {' '}
                                        Driver Name
                                    </TableCell>

                                    <TableCell style={{ color: 'inherit' }} width="75%" align="center">
                                        {props.driver ? (
                                            <Link to={`/controller/editdriver/${props.driverID}/${props.driver}`} style={{ textDecoration: 'none' }}>
                                                {props.driver}
                                            </Link>
                                        ) : (
                                            'There is no driver assigned for this vehicle'
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
};
