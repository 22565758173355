import { postWithAuth, getWithAuth, putWithAuth } from '../../../Basic/HttpService';

export const getEndUserByID = async (Id) => {
    let res = await getWithAuth(`endusers/${Id}`);
    return res;
};
export const getEndUserById = async (Id) => {
    let res = await getWithAuth(`endusers/CustomerID=${Id}`);
    return res;
};

export const postEndUser = async (data) => {
    let res = await postWithAuth('endusers', data);
    return res;
};
