import { useEffect, useState } from "react";
import { AppStorage } from "../Basic/storage-service";

export const useLoginInfo = () => {
  let [ state, setState ] = useState( {
    // isLoggedIn: false,
    // isReadOnlyUser: false,
    // token: '',
    // displayName: '',
    // lastLogin: null,
    // userRoles: [],
    isLoggedIn: AppStorage.isLoggedIn(),
      token: AppStorage.getToken(),
      displayName: AppStorage.getName(),
      isReadOnlyUser: AppStorage.isReadOnlyUser(),
      lastLogin: AppStorage.getLastLogin(),
      userRoles: AppStorage.getUserRoles(),
  } );
  useEffect( () => {
    setState( {
      isLoggedIn: AppStorage.isLoggedIn(),
      token: AppStorage.getToken(),
      displayName: AppStorage.getName(),
      isReadOnlyUser: AppStorage.isReadOnlyUser(),
      lastLogin: AppStorage.getLastLogin(),
      userRoles: AppStorage.getUserRoles(),
    } )
  }, [ AppStorage.loginInfoUpdatedAt() ] );

  return state;
}