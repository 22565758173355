import React from 'react';
import { usePortalSettings } from '../../../Core/Hooks/usePortalSettings';

let Home = () => {
    const portalSettings = usePortalSettings();
    const img = !!portalSettings.portalSettingBanner
        ? portalSettings.portalSettingBanner
        : `${process.env.PUBLIC_URL}/${portalSettings.portalSettingBannerImage}`;
    return (
        <div style={{ height: 'calc(100vh - 64px)' }}>
            <div
            //  style={{ height: '100%' }}
            >
                <img
                    src={img}
                    // height="100%"
                    width="100%"
                    style={{
                        objectFit: 'cover',
                        height: 'calc(100vh - 68px)'
                    }}
                />
            </div>
        </div>
    );
};

export default Home;
