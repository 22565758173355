import { postWithAuth, getWithAuth } from "../../../Basic/HttpService";

export const getAllRetorqueposition = async () => {
  let res = await getWithAuth(`Retorquepositions`);
  return res;
};

export const getRetorquepositionId = async (id) => {
  let res = await getWithAuth(`Retorquepositions/${id}`);
  return res;
};

export const postRetorquepositionData = async (data) => {
  let res = await postWithAuth(`Retorquepositions`, data);
  return res;
};
