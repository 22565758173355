import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const postAssignEngineer = async (data) => {
    let res = await postWithAuth(`VehicleJobProgress/VehicleJob_AssignEngineer`, data);
    return res;
};
export const postJobWorkStatus = async (data) => {
    let res = await postWithAuth(`VehicleJobProgress/VehicleJob_WorkStatus`, data);
    return res;
};
export const postJobStepSignOff = async (id) => {
    let res = await postWithAuth(`VehicleJobProgress/VehicleJob_TechnicianSignOff?JobID=${id}`);
    return res;
};
export const postJobCostReviewed = async (id) => {
    let res = await postWithAuth(`VehicleJobProgress/VehicleJob_CostReviewed?JobID=${id}`);
    return res;
};
export const postFactionInspection = async (id) => {
    let res = await postWithAuth(`VehicleJobProgress/VehicleJob_CompleteFactoryInspection?JobID=${id}`);
    return res;
};
