import { getWithAuth } from '../../../Basic/HttpService';

export const getAllVehicleSchedules = async (filterID, custId, depotId, year, serviceAgentID, engineerUserID, scheduleEventID) => {
    if (filterID === ':') filterID = '';
    let res = await getWithAuth(
        `Schedules?mode=${filterID}&CustomerID=${custId || ''}&DepotID=${depotId || ''}&Year=${year || ''}&ServiceAgentID=${
            serviceAgentID || ''
        }&EngineerUserID=${engineerUserID}&ScheduleEventID=${scheduleEventID}`
    );
    return res;
};

export const GetAllSchedules_Vehicle = async (Id, custId, depotId, year, serviceAgentID, engineerUserID, scheduleEventID) => {
    let res = await getWithAuth(
        `VehicleTabs/GetAllSchedules_Vehicle?vehicleId=${Id}?CustomerID=${custId || ''}&DepotID=${depotId || ''}&Year=${year || ''}&ServiceAgentID=${
            serviceAgentID || ''
        }&EngineerUserID=${engineerUserID}&ScheduleEventID=${scheduleEventID}`
    );
    return res;
};
