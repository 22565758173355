import React, { useEffect, useState } from 'react';
import { BreadCrumbs, TabView } from '../../../../Core/Controls';
import { getVehicleContractStatus_Dropdown } from '../../../../Core/Services/InternalUser/InternalDashService';
import ContractStatusScreen from './listScreen';
import InternalUserJobsScreen from '../../Controllers/JOBS/InternalJobsScreen';

const crumbs = [
    { name: 'Home', path: '/' },
    // { name: 'Vehicles', active: true },
    { name: 'Vehicle Contract Status', active: true }
];

const ContractStatusTabScreen = (props) => {
    const id = props.match.params.id;
    const [index, setIndex] = useState(undefined);
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        isAdd: true,
        /////
        contracStatus: []
    });

    useEffect(async () => {
        let dropDownResponse = await getVehicleContractStatus_Dropdown();
        if (dropDownResponse.success) {
            let selectedIndex = dropDownResponse.data.list.findIndex((a) => a.id == id);
            setState((st) => ({
                ...st,
                contracStatus: dropDownResponse.data.list || []
            }));
            setIndex(selectedIndex);
        }
    }, []);

    // const tabs = state.contracStatus.map((a) => {
    //     return {
    //         label: `${a.name}`,
    //         body: <ContractStatusScreen contractStatusID={a.id} />
    //     };
    // });

    const tabs = state.contracStatus.map((a) => {
        return {
            label: `${a.name}`,
            body:
                a.id === 10 ? (
                    <InternalUserJobsScreen screenCode="FI" fromTabScreen={true} />
                ) : a.id === 11 ? (
                    <InternalUserJobsScreen screenCode="FC" fromTabScreen={true} />
                ) : (
                    <ContractStatusScreen contractStatusID={a.id} />
                )
        };
    });

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <TabView selectedIndex={index} tabList={tabs} id="VehicleContrctStatus_tabs" />
        </div>
    );
};

export default ContractStatusTabScreen;
