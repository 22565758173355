import InputText from "./index";
import { MandatoryField } from "./MandatoryField";

export const PositiveInput = (props) => {
  const checkPrevention = (event) => {
    if (/[^0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };
  return <InputText id="positive" type="number" onKeyPress={checkPrevention} {...props} />;
};

export const MandatoryPositiveInput = (props) => {
  const checkPrevention = (event) => {
    if (/[^0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };
  return <MandatoryField id="positive" type="number" onKeyPress={checkPrevention} {...props} />;
};
