import React, { useState, useEffect, useMemo } from 'react';
import { getAllVehicleCategories } from '../../../../Core/Services/InternalUser/vehicleCategoryService';
import DataGrid from '../../../../Core/Controls/Grid';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import VehicleCategoryModal from './VehicleCategoryModal';
import { BreadCrumbs, EditButton, formatters } from '../../../../Core/Controls';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import '../../.././commonStyle.scss';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicles', active: true },
    { name: 'Vehicle Categories', active: true }
];
const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'vehicleCategoryDescription', name: 'Description' },
    { key: 'vehicleCategoryActive', name: 'Is Active ?', formatter: formatters.YesNo, width: '15%' }
];

function VehicleCategory(props) {
    const [state, setState] = useState({
        vehicleCategoryList: [],
        loader: true,
        open: false
    });

    useEffect(async () => {
        pullVehicleCategory();
    }, []);

    const pullVehicleCategory = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const vehicleCategoryHandler = (params) => {
        setState((state) => ({ ...state, open: true, vehicleCategoryID: params?.vehicleCategoryID }));
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => vehicleCategoryHandler(row)} toolTipTitle="Edit vehicle Category" />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        return newColumn;
    });

    const title = state.vehicleCategoryID ? 'Update Vehicle Category' : 'Add Vehicle Category';

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullVehicleCategory();
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />

            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={'VehicleCategories'}
                btnTitle="Add Vehicle Category"
                onBtnClick={() => vehicleCategoryHandler(null)}
                isReload={state.isReload}
            />
            {state.open ? (
                <DialogComp title={title} onClose={() => closeModal(false)} maxWidth="md">
                    <VehicleCategoryModal vehicleCategoryID={state.vehicleCategoryID} onClose={() => closeModal(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
}

export default VehicleCategory;
