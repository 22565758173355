import React, { useEffect, useState } from 'react';
import { BreadCrumbs, TabView } from '../../../../Core/Controls';
import { getVehicleCategory } from '../../../../Core/Services/InternalUser/Vehicle/vehicleChecks';
import VehicleDailyChecksAdmin from './vehicleDailyChecksAdmin';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicle Checks', active: true },
    { name: 'Daily Checks', active: true }
];

const VehicleDailyChecksTab = () => {
    const [state, setState] = useState({
        checkList: []
    });

    useEffect(async () => {
        let CheckRes = await getVehicleCategory();
        setState(() => ({
            checkList: CheckRes?.data.list
        }));
    }, []);

    const heading = state.checkList?.map((o) => o.vehicleCategoryDescription);
    const filterTab = state.checkList?.filter((o, index) => !heading.includes(o.vehicleCategoryDescription, index + 1));
    const checkChecks = filterTab?.map((tab) => {
        return {
            label: tab.vehicleCategoryDescription,
            body: (
                <div className="accordion_comp">
                    <VehicleDailyChecksAdmin heading={tab.vehicleCategoryDescription} />
                </div>
            )
        };
    });

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <TabView tabList={checkChecks} id="check_check" />
        </div>
    );
};

export default VehicleDailyChecksTab;
