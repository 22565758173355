import { FormHelperText, Grid, InputLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { SecondaryButton, SelectBox, MandatoryField } from "../../../Core/Controls";
import { useToast } from "../../../Core/Hooks/useToast";
import "../../commonStyle.scss";
import { getAllVorTypeList, postVorTypeDetail } from "../../../Core/Services/InternalUser/VOR/vorTypeDetailService";

const VORTypeDialog = (props) => {
  const [state, setState] = useState({
    vorTypeDetailDescription: "",
    vorTypeDescription: "",
    vorTypeDetailTypeID: "",
    dropList: [],
  });
  const [errors, setErrors] = useState({});
  const { showToastSuccess, showToastError } = useToast();
  const pullDropList = async () => {
    let res = await getAllVorTypeList();

    const dataList = res.data.list.map((item) => ({
      id: item.vorTypeID,
      name: item.vorTypeDescription,
    }));

    setState((st) => ({
      ...st,
      dropList: dataList,
    }));
  };
  useEffect(() => {
    if (!props.data.isAdd) {
      let temp = props.data.selectedRowData;
      setState((st) => ({
        ...st,
        vorTypeDetailID: temp.vorTypeDetailID,
        vorTypeDetailTypeID: temp.vorTypeDetailTypeID,
        vorTypeDetailDescription: temp.vorTypeDetailDescription,
        vorTypeDescription: temp.vorTypeDescription,
      }));
    }
    pullDropList();
  }, []);

  const validate = () => {
    let noErrors = true;
    let err = {};
    if (!/[^\s]/.test(state.vorTypeDetailTypeID)) {
      err.vorTypeDetailTypeID = true;
      noErrors = false;
    }
    if (!/[^\s]/.test(state.vorTypeDetailDescription)) {
      err.vorTypeDetailDescription = true;
      noErrors = false;
    }
    setErrors(err);
    return noErrors;
  };

  const handleData = async () => {
    if (validate()) {
      let response = await postVorTypeDetail({
        vorTypeDetailID: state.vorTypeDetailID || null,
        vorTypeDetailTypeID: state.vorTypeDetailTypeID || "",
        vorTypeDetailDescription: state.vorTypeDetailDescription,
      });
      if (response) {
        response.success
          ? showToastSuccess(state.isAdd ? "VOR Type Detail added successfully" : "VOR Type Detail updated successfully")
          : showToastError(response.message || "Something went wrong");
      } else {
        showToastError("Something went wrong");
      }
      props.onClose();
    }
  };
  const valueChanged = (ev) => {
    let temp = ev.target;
    setState((st) => ({
      ...st,
      [temp.name]: temp.value,
    }));
    setErrors({});
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="mandatory-fields">
          <InputLabel required shrink>
            VOR Type
          </InputLabel>
          <SelectBox name="vorTypeDetailTypeID" value={state.vorTypeDetailTypeID || ""} onChange={valueChanged} List={state.dropList} />
          <FormHelperText error>{errors.vorTypeDetailTypeID ? "This field is required" : null}</FormHelperText>
        </Grid>
        <Grid item xs={12} className="mandatory-fields">
          <MandatoryField
            inputLabel="Description"
            name="vorTypeDetailDescription"
            placeholder="Description"
            defaultValue={props.data.selectedRowData.vorTypeDetailDescription || ""}
            onChange={valueChanged}
            errors={errors.vorTypeDetailDescription ? "This field is required" : null}
          />
        </Grid>
        <Grid item xs={12} className="submit_btn">
          <SecondaryButton onClick={handleData}>{props.data.isAdd ? "ADD" : "UPDATE"}</SecondaryButton>
        </Grid>
      </Grid>
    </>
  );
};
export default VORTypeDialog;
