import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import HealingRoundedIcon from '@material-ui/icons/HealingRounded';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import CallMissedIcon from '@material-ui/icons/CallMissed';

import { Link } from 'react-router-dom';
import '../../InternalUser/InternalDash.scss';

const LeadDashboard = () => {
    const [state, setState] = useState({
        rows_new: [],
        tilesData: []
    });

    const driverCheck = 'Driver Check';

    useEffect(async () => {}, []);

    return (
        <div className="internal-dashboard">
            <Container className="cardGrid" maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/Enquires`}>
                            <LibraryAddCheckIcon className="purple_iconStyle" />
                            <Card className="purple_cardStyle ">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">10</Typography>
                                    <Typography component="h1">New Enquires</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/maintenance/Breakdown`}>
                            <LocalShippingRoundedIcon className="yellow_card_icon" />
                            <Card className="yellow_cardDesign">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">10</Typography>
                                    <Typography component="h1">Live Enquires</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/maintenance/Defects`}>
                            <CallMissedIcon className="red_card_icon" />
                            <Card className="red_cardDesign">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">10</Typography>
                                    <Typography component="h1">Lost Enquires</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/maintenance/${driverCheck}`}>
                            <CheckCircleOutlineIcon className="iconStyle" />
                            <Card className="cardDesign">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">10</Typography>
                                    <Typography component="h1">Successful Enquires</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default LeadDashboard;
