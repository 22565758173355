import { getWithAuth, postWithAuth } from '../Basic/HttpService';

export const getAllDashboardWidgets = async (screenName) => {
    let res = await getWithAuth(`UserWidget/screen/${screenName}`);
    return res;
};
export const getWidgetsInfo = async (id, id2 = null) => {
    let res;
    if (id2) {
        res = await getWithAuth(`UserWidget/${id}/${id2}`);
    } else {
        res = await getWithAuth(`UserWidget/${id}`);
    }
    return res;
};

export const postTheme = async (data) => {
    let res = await postWithAuth(`UserWidget`, data);
    return res;
};
