import React, { useEffect, useState, useMemo } from 'react';
import InvoiceModalScreen from './addEditInvoice';
import { EditButton, formatters } from '../../../Core/Controls';
import '../../commonStyle.scss';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import JobDetail from '../Controllers/JOBS/JOBItems/JobDetails';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';

const basecolumns = [
    { key: 'action', name: 'Action', width: 80, addFileUploaderIcon: true },
    { key: 'invoiceDate', name: 'Invoice Date', formatter: formatters.Date_DDMMYYYY },
    { key: 'dateDue', name: 'Due Date', formatter: formatters.Date_DDMMYYYY },
    { key: 'datePaid', name: 'Date Paid', formatter: formatters.Date_DDMMYYYY },
    { key: 'invoiceNumber', name: 'Number' },
    { key: 'status', name: 'Status' },
    { key: 'supplier', name: 'Supplier' },
    { key: 'vat', name: 'VAT' },
    { key: 'net', name: 'NET' }
];

const InvoiceScreen = (props) => {
    const [state, setState] = useState({
        modalToggle: false,
        invoiceID: null,
        invoiceList: [],
        showLoader: true,
        VehicleID: props.VehicleID || null,
        ServiceAgentID: props.ServiceAgentID || null,
        isReload: null
    });
    const isCustomer = useLoginInfo().userRoles.includes('customer');

    console.log(props.jobStatusID, '>>>>>>>>>>>>>>>jobStatusID');

    useEffect(async () => {
        let getUrl;
        if (props.fromPage === 'job') {
            getUrl = `Invoices${props.JobID ? `?JobID=${props.JobID}` : ''}`;
        } else {
            if (props.VehicleID) {
                getUrl = `Invoices${props.VehicleID ? `?VehicleID=${props.VehicleID}` : ''}`;
            } else {
                getUrl = `Invoices?status=${props.statusCode}`;
            }
        }

        setState((st) => ({
            ...st,
            getUrl: getUrl
        }));
    }, []);

    useEffect(() => {
        if (props.jobStatusID) pullInvoiceData();
    }, [props.jobStatusID]);

    const pullInvoiceData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const actionButton = (data, row) => {
        return (
            <div className="Invoice-btn-update">
                <EditButton onClick={() => handleInvoiceUpdate(row)} toolTipTitle="Edit Invoice" />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = actionButton;
        }
        return tempCols;
    }, []);

    const title = `${props.fromPage === 'job' ? 'Add Invoice' : isCustomer ? '' : 'Add Invoice'}`;

    const handleInvoiceUpdate = (params) => {
        setState((state) => ({ ...state, modalToggle: true, invoiceID: params?.invoiceID }));
    };

    const closeAddDialog = (res) => {
        setState((state) => ({ ...state, modalToggle: false }));
        res && pullInvoiceData();
        res && props.pullJobDetail();
    };

    return (
        <div className={props.fromPage === 'job' || props.VehicleID ? '' : 'screen'}>
            {state.modalToggle ? (
                <InvoiceModalScreen
                    ServiceAgentID={state.ServiceAgentID}
                    invoiceID={state.invoiceID}
                    VehicleID={state.VehicleID}
                    onClose={closeAddDialog}
                    JobID={props.JobID}
                />
            ) : (
                <>
                    <GijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={columns}
                        getUrl={state.getUrl}
                        btnTitle={title}
                        onBtnClick={handleInvoiceUpdate}
                        idFieldName="invoiceID"
                        fileUploaderConfigName="Invoice"
                        isReload={state.isReload}
                        offset={props.fromPage === 'job' ? 410 : 350}
                    />
                </>
            )}
        </div>
    );
};

export default InvoiceScreen;
