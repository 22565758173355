import { postWithAuth, getWithAuth } from '../../Basic/HttpService';

export const getAllFuelTypes = async () => {
    let res = await getWithAuth(`FuelTypes`);
    return res;
};

export const getFuelTypesById = async (id) => {
    let res = await getWithAuth(`FuelTypes/${id}`);
    return res;
};

export const postFuelTypesData = async (data) => {
    let res = await postWithAuth(`FuelTypes`, data);
    return res;
};
