import { postWithAuth, getWithAuth } from "../../Basic/HttpService";

export const getAllMake = async () => {
    let res = await getWithAuth(`makes`);
    return res;
};

export const getMakeById = async (id) => {
    let res = await getWithAuth(`makes/${id}`);
    return res;
};

export const postMakeData = async (data) => {
    let res = await postWithAuth(`makes`, data);
    return res;
};
