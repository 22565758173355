import { postWithAuth, getWithAuth,putWithAuth } from '../../Basic/HttpService';

export const getAllBookingStatus = async () => {
    let res = await getWithAuth(`BookingStatus`);
    return res;
};


export const postBookingStatus = async (data) => {
    let res = await postWithAuth(`BookingStatus`, data);
    return res;
};
