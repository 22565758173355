import React, { useState, useEffect } from 'react';
import ImageUploading from 'react-images-uploading';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import './imageModal.scss';

import {
    Grid,
    Paper,
    InputLabel,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Typography,
    Tooltip,
    CircularProgress,
    FormHelperText,
    Badge
} from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import { SecondaryButton, PrimaryButton, MandatoryField } from '../../../../../Core/Controls';
import ImageCard from './imageCard';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import { useWindowSize } from '../../../../../Core/Hooks/useWindowSize';

const ImageModal = (props) => {
    console.log(props, 'pros');
    console.log(props);
    const [windowWidth, windowHeight] = useWindowSize();

    useEffect(() => {
        if (props.images?.length == 0) {
            setImage();
        }
    }, [props.images]);

    const [image, setImage] = useState();

    const scrollNext = (id) => {
        document.getElementById('slider-scroll').scrollBy({ left: 100, top: 0, behavior: 'smooth' });
        // smoothscroll.polyfill();
    };

    const scrollPrev = (id) => {
        document.getElementById('slider-scroll').scrollBy({ left: -100, top: 0, behavior: 'smooth' });
        // smoothscroll.polyfill();
    };

    const setCurrentImage = (image) => {
        setImage(image);
    };

    const TitleComp = (props) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>Upload Image {props.inspectionDesc}</div>
            {/* <div>
                <Button variant="contained" color="primary" onClick={props.onImageUpload}>
                    <BackupIcon /> &nbsp; Add Image
                </Button>
                &nbsp;
                <Button variant="contained" color="secondary" onClick={props.onImageRemoveAll}>
                    <DeleteSweepIcon />
                    &nbsp; Remove all images
                </Button>
            </div> */}
        </div>
    );

    return (
        // <div style={{ width: 900, height: 900 }}>
        // <Grid item style={{ width: '100%', height: 700 }}>
        <ImageUploading multiple value={props.images} onChange={props.onImageChange} maxNumber={props.maxNumber} dataURLKey="data_url">
            {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove }) => {
                return (
                    <DialogComp
                        title={<TitleComp onImageUpload={onImageUpload} onImageRemoveAll={onImageRemoveAll} />}
                        onClose={props.onClose}
                        maxWidth="md"
                        fullWidth
                        className="dialog-container"
                    >
                        <div className="avatar">
                            {props.disabled ? null : (
                                <div>
                                    <Button variant="contained" color="primary" onClick={onImageUpload}>
                                        <BackupIcon /> {windowWidth <= 900 ? '' : `\u00a0 Add Image`}
                                    </Button>
                                    &nbsp;
                                </div>
                            )}
                            {imageList.length > 0 ? (
                                <ImageCard avtar={!image ? imageList[0] : image} />
                            ) : (
                                <div className="image-card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography>No image found</Typography>
                                </div>
                            )}
                            <div className="carousel-wrapper-class">
                                <div className="other-images d-flex" id={'slider-scroll'}>
                                    {imageList.map((im, i) => (
                                        <span className="other-image" style={{ position: 'relative' }} key={i}>
                                            {props.disabled ? null : (
                                                <span style={{ position: 'absolute', top: '-8px', right: '-10px', zIndex: 10000000 }}>
                                                    <RemoveCircleIcon onClick={() => onImageRemove(i)} style={{ fill: '#f44336', cursor: 'pointer' }} />
                                                </span>
                                            )}
                                            <img className={im === image ? 'active' : ''} onClick={() => setCurrentImage(im)} src={im} />
                                        </span>
                                    ))}
                                    <div className="arrow">
                                        <span className="arrow-left" onClick={() => scrollPrev()}>
                                            <ChevronLeftIcon />
                                        </span>
                                        <span className="arrow-right" onClick={() => scrollNext()}>
                                            <ChevronRightIcon />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {props.disabled ? null : (
                                <div style={{ marginTop: '5px' }}>
                                    <SecondaryButton onClick={props.submitImage}>Submit</SecondaryButton>
                                </div>
                            )}
                        </div>
                    </DialogComp>
                );
            }}
        </ImageUploading>
        // </Grid>
        // </div>
    );
};

export default ImageModal;
