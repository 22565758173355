import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from '../../../../Core/Controls/Grid/index';
import { AddButton, EditButton, BreadCrumbs, TabView } from '../../../../Core/Controls';
// import DialogBox from './scheduleEventsDialog';
// import DialogComp from '../../../../Core/Dialog/DialogComp';
import '../../../commonStyle.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';

import { getAllScheduleEvents, getAllDropdowns } from '../../../../Core/Services/InternalUser/scheduleEventsService';
import { getContractType_DropDowns } from '../../../../Core/Services/Admin/ContractService';
import ContractListScreen from './contractListScreen';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicles', active: true },
    { name: 'Contract Schedule', active: true }
];
const basecolumns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'scheduleEventName', name: 'Name' },
    { key: 'scheduleEventInternalTypeTitle', name: 'Event Type' },
    { key: 'scheduleVMRSCategory', name: 'VMRS Category' },
    { key: 'scheduleVMRSSubCategory', name: 'VMRS Sub Category' }
];

const ContractEvents = () => {
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        isAdd: true,
        /////
        contractTypes: []
    });

    useEffect(async () => {
        let dropDownResponse = await getContractType_DropDowns();
        setState((st) => ({
            ...st,
            contractTypes: dropDownResponse.data.contractTypes || []
        }));
    }, []);

    const pullApiData = async () => {
        // let res = await getAllScheduleEvents();
        let dropDownResponse = await getAllDropdowns();
        setState((st) => ({
            ...st,
            // rows: [...res.data],
            dropDown: dropDownResponse.data,
            isReload: new Date()
            // showLoader: false
        }));
    };
    const renderEdit = (ev, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <EditButton
                    toolTipTitle="Edit Schedule Events"
                    onClick={() => {
                        setState((st) => ({
                            ...st,
                            open: true,
                            selectedRowData: row,
                            isAdd: false
                        }));
                    }}
                />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);

    const addBtnClicked = () => {
        setState((st) => ({
            ...st,
            open: true,
            isAdd: true,
            selectedRowData: {}
        }));
    };
    const handleClose = (res) => {
        setState((st) => ({
            ...st,
            open: false
        }));
        res && pullApiData();
    };

    const urlState = 'ScheduleEvents';

    const tabs = state.contractTypes.map((a) => {
        return {
            label: `${a.name}`,
            body: <ContractListScreen contractTypeID={a.id} />
        };
    });

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <TabView tabList={tabs} id="testing_tabs" />
            {/* <GijgoGrid
                columns={columns}
                getUrl={urlState}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                btnTitle="Add Schedule Events"
                onBtnClick={addBtnClicked}
            /> */}
            {/* {state.open ? (
                <DialogComp
                    open={state.open}
                    onClose={() => {
                        handleClose(false);
                    }}
                    title={state.isAdd ? 'Add Schedule Event' : 'Edit Schedule Event'}
                    maxWidth="lg"
                    overflow="auto"
                >
                    <DialogBox
                        data={state}
                        onClose={() => {
                            handleClose(true);
                        }}
                    />
                </DialogComp>
            ) : null} */}
        </div>
    );
};

export default ContractEvents;
