import React, { useEffect, useState, useMemo } from 'react';
import { BreadCrumbs, DataGrid, EditButton, formatters } from '../../../Core/Controls';
import { getVehicleBreakdowns, getAllVehicleBreakdowns, getVechicleIDByRegNO } from '../../../Core/Services/InternalUser/ServiceAgent/breakdownService';
import AddBreaddownModal from '../../InternalUser/Maintenance/BreakDown/AddBreakdown';
import WorkIcon from '@material-ui/icons/Work';
import { CustomIconButton, SecondaryButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import BuildIcon from '@material-ui/icons/Build';
import { useHistory } from 'react-router-dom';
import { AppStorage } from '../../../Core/Basic/storage-service';
const baseColumns = [
    { key: 'action', name: 'Action', width: 120, addFileUploaderIcon: true },
    {
        key: 'defectDate',
        name: 'Date',
        formatter: formatters.Date_DDMMYYYY,
        width: 90
    },
    { key: 'defectLocation', name: 'Location', width: 250 },
    { key: 'defectContactName', name: 'Reported By', width: 170 },
    { key: 'defectContactNumber', name: 'Reported Phone', width: 200 },
    { key: 'defectJobStatusDescription', name: 'Status', width: 350 },
    { key: 'defectTypeCode', name: 'Code', width: 80 }
];

let crubsSet = {
    ':all': 'All Breakdowns',
    typebo: 'BreakDown Onside',
    typebr: 'BreakDown Roadside'
};

const ServiceAgentBreakdown = (props) => {
    let history = useHistory();
    let checkhaveAccess = AppStorage.getAllowAcces();
    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Breakdowns', active: true },
        { name: crubsSet[props?.match?.params?.all], active: true }
    ];
    const [state, setState] = useState({
        breakdownList: [],
        loader: true,
        modalToggle: false,
        breakID: null,
        rowVehicleID: null,
        filterVal: []
    });

    const handleBreakDown = (params, VehicleID, modalName) => {
        setState((st) => ({
            ...st,
            modalToggle: true,
            breakID: params,
            rowVehicleID: VehicleID,
            modalName: modalName
        }));
    };

    const closeModal = (res) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.modalToggle = false;

            if (res) newSt.filterVal = '';
            return newSt;
        });

        res && pullBreakDownAndUpdateState();
    };

    useEffect(async () => {
        pullBreakDownAndUpdateState();
        // setState((st) => {
        //     let newSt = { ...st };
        //     newSt.modalToggle = false;
        //     return newSt;
        // });
    }, [props?.match]);

    useEffect(() => {
        setState((st) => {
            let newSt = { ...st };
            newSt.modalToggle = false;
            return newSt;
        });
    }, [props?.match?.params?.all]);

    const pullBreakDownAndUpdateState = async () => {
        let defecttypecode = '';
        if (props.VehicleID) {
            defecttypecode = '';
        }
        if (props?.match?.params?.all == 'typebo') {
            defecttypecode = 'bo';
        }
        if (props?.match?.params?.all == 'typebr') {
            defecttypecode = 'br';
        }
        if (props?.match?.params?.all == ':all') {
            defecttypecode = '';
        }

        setState((st) => ({
            ...st,
            isReload: new Date(),
            defecttypecode: defecttypecode
            // modalToggle: false
        }));
    };

    let urlForcondition = `VehicleBreakdowns/?${props.VehicleID ? `VehicleID=${props.VehicleID}` : ''}defecttypecode=${state.defecttypecode}`;

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.defectJobID}/${val?.defectVehicleID}`,
            state: 'breakdown'
        });
    };

    const handleEditBreakdown = (val) => {
        history.push({
            pathname: `/maintenance/breakdowns/Edit/${val?.defectID}/${val?.defectVehicleID}`,
            state: 'Edit'
        });
    };

    const dataFormatter = (data, row) => {
        return (
            <Grid item>
                <CustomIconButton
                    icon={BuildIcon}
                    color="secondary"
                    className="modal-btn-update btn_space"
                    // onClick={() => handleBreakDown(row.defectID, row.defectVehicleID, 'Edit')}
                    onClick={() => handleEditBreakdown(row)}
                    toolTipTitle="Edit Breakdown"
                />
                {/* <EditButton toolTipTitle="Edit Breakdown" onClick={() => handleBreakDown(row.defectID, row.defectVehicleID, 'Edit')} /> */}

                {checkhaveAccess?.CanViewJobs && (
                    <CustomIconButton
                        icon={WorkIcon}
                        color="secondary"
                        className="modal-btn-update btn_space"
                        toolTipTitle="Job Details"
                        onClick={() => handleEditClick(row)}
                    />
                )}
            </Grid>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        if (!props.VehicleID) {
            tempCols.splice(1, 0, { key: 'defectRegNumber', name: 'Reg No', width: '8%' });
        }

        return tempCols;
    }, []);
    const gridBtnTitle = state.show || props.VehicleID ? 'Add Breakdown' : null;

    const inputChange = (e) => {
        const value = e.target.value;
        setState((st) => ({
            ...st,
            filterVal: value
        }));
    };

    useEffect(async () => {
        if (!props.VehicleID) {
            if (state.filterVal.length > 4) {
                let resBrekDown = await getVechicleIDByRegNO(state.filterVal);
                setState((st) => {
                    let newSt = { ...st };
                    if (resBrekDown.data?.list) {
                        newSt.show = resBrekDown.success;
                        newSt.filterVehicleID = resBrekDown.data?.list[0].vehicleID;
                    } else {
                        newSt.show = false;
                    }
                    return newSt;
                });
            } else {
                setState((st) => ({ ...st, show: false }));
            }
        }

        // pullBreakDownAndUpdateState();
    }, [props.VehicleID, state.filterVal]);

    return (
        <div className={!props.frompage ? `screen` : ''}>
            {/* {props.frompage !== 'Vehicles' ? <BreadCrumbs crumbs={crumbs} lassName="edit-service-agent-btn" m={5} /> : null} */}

            {state.modalToggle ? (
                <>
                    <br />
                    <AddBreaddownModal
                        VehicleID={props.VehicleID || state.rowVehicleID}
                        onClose={closeModal}
                        breakDownID={state.breakID}
                        isAddJobBtnShow={true}
                        modalName={state.modalName}
                    />
                </>
            ) : (
                <>
                    {props.frompage !== 'Vehicles' ? (
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center', position: 'relative', top: 0 }}>
                            <div style={{ width: 'auto' }}>
                                <BreadCrumbs crumbs={crumbs} className="edit-service-agent-btn" />
                            </div>
                            {/* &nbsp;&nbsp;
                            {checkhaveAccess?.CanAddBreakdowns && (
                                <div style={{ width: 'auto' }}>
                                    <input type="text" onChange={inputChange} placeholder="Enter Reg Number" value={state.filterVal} style={{ padding: 5 }} />
                                </div>
                            )} */}
                            {gridBtnTitle && (
                                <div style={{ marginLeft: '4px' }}>
                                    <SecondaryButton
                                        style={{ padding: '2px 16px' }}
                                        onClick={() => handleBreakDown(null, state.filterVehicleID, 'Add')}
                                        fullWidth
                                        // className="submit_btn"
                                    >
                                        Add
                                    </SecondaryButton>
                                </div>
                            )}
                        </div>
                    ) : null}
                    <GijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={columns}
                        idFieldName="defectID"
                        fileUploaderConfigName="BreakdownScreen"
                        getUrl={urlForcondition}
                        // btnTitle={gridBtnTitle}
                        // onBtnClick={() => handleBreakDown(null, state.filterVehicleID, 'Add')}
                        /// isReload={state.isReload}
                    />
                </>
            )}
        </div>
    );
};

export default ServiceAgentBreakdown;
