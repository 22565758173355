import { postWithAuth, getWithAuth, putWithAuth } from '../../Basic/HttpService';

export const postCustomerLicence = async (CustomerId) => {
    let res = await postWithAuth(`DavisCompanies/${CustomerId}`);
    return res;
};

export const getCustomerLicence = async (CompanyCode) => {
    let res = await getWithAuth(`DavisCompanies/Credits/${CompanyCode}`);
    return res;
};

export const postArchiveCustomerLicence = async (id) => {
    let res = await postWithAuth(`DavisCompanies/ArchiveCompany/${id}`);
    return res;
};
