import { postWithAuth, getWithAuth } from "../../../Basic/HttpService";

export const getAllVorTypes = async () => {
  let res = await getWithAuth(`vortypes`);
  return res;
};

export const getVorById = async (id) => {
  let res = await getWithAuth(`vortypes/${id}`);
  return res;
};

export const postVorData = async (data) => {
  let res = await postWithAuth(`vortypes`, data);
  return res;
};
