import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState, useMemo } from 'react';
import { TabView, DataGrid, formatters } from '../../../../../Core/Controls';
import { getTop10VORVehiclesByOccurance, getTop10VORVehiclesByDays } from '../../../../../Core/Services/InternalUser/ControllerService/customerService';

const columns = [
    { key: 'reg', name: 'Reg No' },
    { key: 'make', name: 'Make' },
    { key: 'total', name: 'Total' }
];

const VorReportingTab = (props) => {
    const [data, setData] = useState({
        showLoader1: true,
        showLoader2: true,
        vehiclesByOccurance: [],
        vehiclesByDay: []
    });

    useEffect(async () => {
        if (props.customerID) {
            let res = await getTop10VORVehiclesByOccurance(props.customerID);
            if (res.success) {
                setData((st) => ({ ...st, vehiclesByOccurance: res?.data?.list || [], showLoader1: false }));
            }
            let res1 = await getTop10VORVehiclesByDays(props.customerID);
            if (res1.success) {
                setData((st) => ({ ...st, vehiclesByDay: res1?.data?.list || [], showLoader2: false }));
            }
        }
    }, []);

    const tempTab = [
        {
            label: 'VOR Vehicle By Occurance',
            body: (
                <div style={{ marginTop: 4 }}>
                    <DataGrid rows={data.vehiclesByOccurance} columns={columns} height={450} loadingData={data.showLoader1} />
                </div>
            )
        },
        {
            label: 'VOR Vehicle By Days',
            body: (
                <div style={{ marginTop: 4 }}>
                    <DataGrid rows={data.vehiclesByDay} columns={columns} height={450} loadingData={data.showLoader2} />
                </div>
            )
        }
    ];

    return (
        <div className="controller-screen">
            <TabView tabList={tempTab} />
        </div>
    );
};
export default VorReportingTab;
