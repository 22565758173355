import React, { useEffect, useState, useCallback } from 'react';
import { useToast } from '../../../Core/Hooks/useToast';
import { Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { getPartReceived_GetHistory, getPartRequests_GetNotes, getPartStockAdjustments_GetNotes } from '../../../Core/Services/Admin/partService';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'grey',
        color: theme.palette.common.white,
        padding: '2px 5px'
    },
    body: {
        fontSize: 15,
        padding: '2px 5px',
        color: theme.palette.common.black
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white'
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: '500px'
    }
});

const fetchHistoryByScreenId = async (screenId, partId) => {
    if (screenId === 'O') {
        return getPartRequests_GetNotes(partId);
    }
    if (screenId === 'R') {
        return getPartReceived_GetHistory(partId);
    }
    if (screenId === 'A') {
        return getPartStockAdjustments_GetNotes(partId);
    }
    return { success: false, message: 'Invalid screen ID' };
};

const PartRequestHistory = ({ screenId, PartID, isReloadAPICall }) => {
    const classes = useStyles();
    const { showToastError } = useToast();
    const [state, setState] = useState({
        showLoader: true,
        partHistoryList: []
    });

    const pullHistoryAndUpdateState = useCallback(async () => {
        const res = await fetchHistoryByScreenId(screenId, PartID);

        if (res.success) {
            setState({ showLoader: false, partHistoryList: res.data });
        } else {
            setState((st) => ({ ...st, showLoader: false }));
            showToastError(res.message);
        }
    }, [screenId, PartID, showToastError]);

    useEffect(() => {
        pullHistoryAndUpdateState();
    }, [pullHistoryAndUpdateState, isReloadAPICall]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <TableContainer className="custom-scroll" style={{ height: '450px', borderRadius: '0px' }}>
                    <Table className={classes.table} style={{ minWidth: 550 }} aria-label="customized table" stickyHeader>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Date</StyledTableCell>
                                {screenId === 'O' ? (
                                    <>
                                        <StyledTableCell>User</StyledTableCell>
                                        <StyledTableCell align="right">Quantity</StyledTableCell>
                                    </>
                                ) : screenId === 'R' ? (
                                    <>
                                        <StyledTableCell align="right">Warehouse</StyledTableCell>
                                        <StyledTableCell align="right">Quantity</StyledTableCell>
                                        <StyledTableCell align="right">Service Area</StyledTableCell>
                                        <StyledTableCell align="right">Received</StyledTableCell>
                                    </>
                                ) : (
                                    <>
                                        <StyledTableCell>User</StyledTableCell>
                                        <StyledTableCell align="right">Minimum Level</StyledTableCell>
                                        <StyledTableCell align="right">Target Level</StyledTableCell>
                                        <StyledTableCell align="right">Warehouse</StyledTableCell>
                                        <StyledTableCell align="right">Service Area</StyledTableCell>
                                    </>
                                )}
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {state.partHistoryList.length > 0 ? (
                                state.partHistoryList.map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell>{row.date}</StyledTableCell>
                                        {screenId === 'O' ? (
                                            <>
                                                <StyledTableCell>{row.userName}</StyledTableCell>
                                                <StyledTableCell style={{ textAlign: 'right' }}>{row.qty}</StyledTableCell>
                                            </>
                                        ) : screenId === 'R' ? (
                                            <>
                                                <StyledTableCell align="right">{row.warehouse}</StyledTableCell>
                                                <StyledTableCell align="right">{row.qty}</StyledTableCell>
                                                <StyledTableCell align="right">{row.sa}</StyledTableCell>
                                                <StyledTableCell align="right">{row.received}</StyledTableCell>
                                            </>
                                        ) : (
                                            <>
                                                <StyledTableCell>{row.userName}</StyledTableCell>
                                                <StyledTableCell style={{ textAlign: 'right' }}>{row.newMinimumStockLevel}</StyledTableCell>
                                                <StyledTableCell style={{ textAlign: 'right' }}>{row.newTargetStockLevel}</StyledTableCell>
                                                <StyledTableCell align="right">{row.warehouse}</StyledTableCell>
                                                <StyledTableCell align="right">{row.sa}</StyledTableCell>
                                            </>
                                        )}
                                    </StyledTableRow>
                                ))
                            ) : (
                                <StyledTableRow>
                                    <StyledTableCell colSpan={screenId === 'O' ? 3 : screenId === 'R' ? 5 : 6}>
                                        <Alert severity="info">No Record Found</Alert>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default PartRequestHistory;
