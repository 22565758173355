import React, { useState, useMemo, useEffect } from 'react';
import { EditButton, formatters } from '../../../../../Core/Controls';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import AddEditEndUser from './addEditEndUSer';
import ReactDOM from 'react-dom';
// import { postEndUser } from '../../../../../Core/Services/InternalUser/ControllerService/endUserService';
const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: 70
    },
    {
        key: 'endUserName',
        name: 'Name',
        width: 150
    },

    {
        key: 'endUserContactName',
        name: 'Contact Name',
        width: 150
    },
    {
        key: 'endUserContactPhone',
        name: 'Contact Phone',
        width: 170
    },
    {
        key: 'endUserContactEmail',
        name: 'Contact Email',
        width: 170
    },
    {
        key: 'endUserAddress',
        name: 'Address'
    }
];

const EndUser = (props) => {
    const [state, setState] = useState({
        showModal: false
    });
    const pullApiData = () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };
    const handleModal = (val, res) => {
        setState((st) => ({ ...st, showModal: !st.showModal, endUserId: val?.endUserID }));
        res && pullApiData();
    };

    const dataFormatter = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleModal(row, false)} toolTipTitle="Edit Report Catalogue" />
            </div>
        );
    };

    const renderEmail = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = <div style={{ cursor: 'pointer', color: '#f50057' }}>{formatters.mailFormatter(row.endUserContactEmail)}</div>;
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderPhone = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = <div style={{ cursor: 'pointer', color: '#f50057' }}>{formatters.phoneNumberFormatter(row.endUserContactPhone)}</div>;
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        let emailCol = tempCols.find((element) => element.key === 'endUserContactEmail');
        let phoneCol = tempCols.find((element) => element.key === 'endUserContactPhone');

        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        if (emailCol) {
            emailCol.formatter = renderEmail;
        }
        if (phoneCol) {
            phoneCol.formatter = renderPhone;
        }
        return tempCols;
    }, []);

    const getUrl = `endusers?CustomerID=${props.customerID}`;
    return (
        <div>
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={getUrl}
                btnTitle="Add Item"
                onBtnClick={() => handleModal(null, false)}
                isReload={state.isReload}
            />

            {state.showModal ? (
                <DialogComp title={state.endUserId ? 'Update End User' : 'Add End User'} onClose={() => handleModal(null, false)} maxWidth="lg">
                    <AddEditEndUser customerID={props.customerID} onClose={() => handleModal(null, true)} endUserId={state.endUserId} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default EndUser;
