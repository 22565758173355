import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import '../../Maintenance/BreackdownDefectStepper/defect.scss';
import StatusModal from './statusModal';
import LiveEquityModal from './liveEnquiryModal';
import { formatters } from '../../../../Core/Controls';
import Typography from '@material-ui/core/Typography';

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 15
    },
    active: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    completed: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 25,
        height: 25,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ['@media (max-width:780px)']: {
            width: (active) => (active ? 35 : 25),
            height: (active) => (active ? 35 : 25),
            marginTop: (active) => (active ? '0px' : '4px')
        }
    },
    active: {
        backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
    },
    completed: {
        backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
    },
    icons: {
        fontSize: '1.0rem',
        ['@media (max-width:780px)']: {
            fontSize: (active) => (active ? '1.0rem' : '0.5rem')
        }
    }
});

const ColorlibStepIcon = (props) => {
    const { active, completed, stepCount } = props;
    const classes = useColorlibStepIconStyles(active);

    const icons = [<AddIcon className={classes.icons} />, <GroupAddIcon className={classes.icons} />, <AutorenewIcon className={classes.icons} />];

    if (stepCount === 6) {
        icons.splice(3, 0, <VideoLabelIcon className={classes.icons} />);
    }
    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed
            })}
        >
            {icons[props.icon - 1]}
        </div>
    );
};

const ColorlibStepIconWrapper = (stepCount) => {
    return (props) => <ColorlibStepIcon stepCount={stepCount} {...props} />;
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export default function EnquiryStepper(props) {
    const classes = useStyles();

    const [activeStep, setActiveStep] = React.useState(1);

    const [step, setStep] = React.useState({ statusShow: false, show: false, steps: [] });

    useEffect(async () => {
        let TempStep = [
            {
                title: 'New Enquiry',
                isBtn: false,
                value: formatters.DateTimeFormatter(props.data?.leadDateCreated)
            },
            {
                title: 'Live Enquiry',
                isBtn: props.data?.leadLiveDate ? null : <AddIcon fontSize="small" />,
                value: formatters.DateTimeFormatter(props.data?.leadLiveDate)
            },
            {
                ...{ title: +props.data?.leadStatusID == 4 ? 'Lost' : +props.data?.leadStatusID == 3 ? 'Successful' : 'Status' },
                isBtn: props.data?.leadOutcomeDate ? null : <AddIcon fontSize="small" />,
                value: formatters.DateTimeFormatter(props.data?.leadOutcomeDate)
            }
        ];
        setActiveStep(props.data?.leadStatusID || 0);

        setStep((st) => ({ ...st, steps: TempStep }));
    }, [props.data?.leadStatusID, props.data?.leadLostReasonID]);

    const closeModal = (res) => {
        setStep((st) => ({
            ...st,
            show: false,
            statusShow: false
        }));
        res && props.onClose(res);
    };

    const popUpHandler = (label) => {
        if (label === 'Live Enquiry') {
            setStep((st) => ({
                ...st,
                show: true
            }));
        }
        if (label === 'Status') {
            setStep((st) => ({
                ...st,
                statusShow: true
            }));
        }
    };

    return (
        <div className={classes.root}>
            <Stepper
                className="stepTest"
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector active={activeStep} />}
                style={{
                    padding: '5px 0px 5px 0px',
                    margin: '0px -5px 0px -5px'
                }}
            >
                {step.steps.map((label, i) => (
                    <Tooltip disableFocusListener title={`${label.title} ${label.value ? label.value : ''}`} key={i}>
                        <Step>
                            <StepLabel className="Stepper-label" StepIconComponent={ColorlibStepIconWrapper(step.steps.length)}>
                                <div className={`${activeStep === i ? '' : 'stepper-label-to-hide'}`}>
                                    <div>
                                        {label.title}
                                        {!label.value && label.isBtn ? (
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => {
                                                    popUpHandler(label.title);
                                                }}
                                                disabled={activeStep === i ? false : true}
                                            >
                                                {label.isBtn}
                                            </IconButton>
                                        ) : null}
                                    </div>
                                    <Typography variant="caption" display="block">
                                        {label.value}
                                    </Typography>
                                </div>
                            </StepLabel>
                        </Step>
                    </Tooltip>
                ))}
            </Stepper>
            {step.show ? (
                <DialogComp title="Live Enquiry" onClose={() => closeModal(false)} overflow="auto" maxWidth="md">
                    <LiveEquityModal leadID={props.data?.leadID} onClose={() => closeModal(true)} />
                </DialogComp>
            ) : null}
            {step.statusShow ? (
                <DialogComp title="Lead Outcome" onClose={() => closeModal(false)} overflow="auto" maxWidth="sm">
                    <StatusModal leadID={props.data?.leadID} onClose={() => closeModal(true)} leadLostReasonsList={props.leadLostReasonsList} />
                </DialogComp>
            ) : null}
        </div>
    );
}
