import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const StyledTableCell = withStyles((theme) => ({
    head: {
        // padding: ' 5px',
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: '#bdbdbd82'
    },
    body: {
        fontSize: 12
        // padding: ' 5px'
        // fontSize: 11,
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'grey',
            color: 'white'
        },
        cursor: 'pointer'
    }
}))(TableRow);

const SelectedVehicleTableComp = (props) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <TableContainer className="custom-scroll" style={{ height: '350px' }}>
                    <Table size="small" width="550px" stickyHeader>
                        <TableHead>
                            <TableRow className="header">
                                <StyledTableCell>Reg Number</StyledTableCell>
                                {props.showEarliestOffHire && <StyledTableCell>Earliest off hire date</StyledTableCell>}
                                <StyledTableCell>Vehicle Type</StyledTableCell>
                                <StyledTableCell>Service Area</StyledTableCell>
                                <StyledTableCell>Technician</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.selectedRecords.map((q) => {
                                return (
                                    <StyledTableRow key={q.reg}>
                                        <StyledTableCell>{q.reg}</StyledTableCell>
                                        {props.showEarliestOffHire && <StyledTableCell>{moment(q.earliestOffHire).format('YYYY-MM-DD')}</StyledTableCell>}
                                        <StyledTableCell>{q.vehicleType}</StyledTableCell>
                                        <StyledTableCell>{q.sa}</StyledTableCell>
                                        <StyledTableCell>{q.technician}</StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default SelectedVehicleTableComp;
