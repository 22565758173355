import React, { useState, useMemo } from 'react';
import { EditButton } from '../../../../../Core/Controls';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import AddEditFaultTypes from './AddEditFaultTypes';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: 60
    },
    {
        key: 'faultTypeDescription',
        name: 'Description'
    }
];

const FaultTypes = (props) => {
    const [state, setState] = useState({
        showModal: false
    });
    const pullApiData = () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };
    const handleModal = (val, res) => {
        setState((st) => ({ ...st, showModal: !st.showModal, faultTypeID: val?.faultTypeID }));
        res && pullApiData();
    };

    const dataFormatter = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleModal(row, false)} toolTipTitle="Edit Fault Type" />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);

    const getUrl = `FaultTypes?CustomerID=${props.customerID}`;
    return (
        <div>
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={getUrl}
                btnTitle="Add Fault Type"
                onBtnClick={() => handleModal(null, false)}
                isReload={state.isReload}
            />

            {state.showModal ? (
                <DialogComp title={state.faultTypeID ? 'Update Fault Type' : 'Add Fault Type'} onClose={() => handleModal(null, false)} maxWidth="lg">
                    <AddEditFaultTypes customerID={props.customerID} onClose={() => handleModal(null, true)} faultTypeID={state.faultTypeID} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default FaultTypes;
