import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel } from '@material-ui/core';
import { SecondaryButton, MandatoryField, SimpleField, SingleSelect } from '../../../../../Core/Controls';
import { getVMRSCategoryById, getVmrsCategoryDropDown, postVMRSCategoryData } from '../../../../../Core/Services/InternalUser/vmrsCategoryService';
import { useToast } from '../../../../../Core/Hooks/useToast';
import '../../../../commonStyle.scss';

const VmrsCategoryModal = (props) => {
    const [state, setState] = useState({
        vmrsCustomerIDValue: '',
        vmrsVehicleTypeIDValue: '',
        vmrsDescription: '',
        customerCodeList: [],
        vehicleTypeList: [],
        vmrsCategoryID: null,
        errors: {}
    });
    const [btnLoader, setBtnLoader] = useState(false);

    const [tempOptions, setTempOptions] = useState({
        custIDValue: null,
        vehicleTypeIDValue: null
    });
    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        let vmrsDropDowns = await getVmrsCategoryDropDown();
        if (vmrsDropDowns.success) {
            const customerCodeList = vmrsDropDowns.data.customers.map((item) => ({
                id: item.customerId,
                name: item.customerCompanyName
            }));
            const vehicle_type = vmrsDropDowns.data.vehicleTypes.map((item) => ({
                id: item.vehicleTypeID,
                name: item.vehicleTypeDescription
            }));
            setState((st) => ({
                ...st,
                customerCodeList: customerCodeList,
                vehicleTypeList: vehicle_type
            }));
        }
        if (props.vmrsCategoryID) {
            let res = await getVMRSCategoryById(props.vmrsCategoryID);
            if (res.success) {
                setState((state) => ({
                    ...state,
                    vmrsCategoryID: res.data.vmrsCategoryID,
                    vmrsDescription: res.data.vmrsDescription,
                    nominalCode: res.data.nominalCode,
                    vmrsCustomerIDValue: res.data.vmrsCustomerID,
                    vmrsVehicleTypeIDValue: res.data.vmrsVehicleTypeID,
                    vmrsType: res.data.vmrsType
                }));
            }
        }
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };

    useEffect(() => {
        let customerId = null;
        let vehicleId = null;
        if (state.vmrsCustomerIDValue) {
            customerId = state.customerCodeList.find(({ id }) => id === state.vmrsCustomerIDValue);
        }
        if (state.vmrsVehicleTypeIDValue) {
            vehicleId = state.vehicleTypeList.find(({ id }) => id === state.vmrsVehicleTypeIDValue);
        }
        setTempOptions((st) => ({
            ...st,
            custIDValue: customerId,
            vehicleTypeIDValue: vehicleId
        }));
    }, [state.vmrsCustomerIDValue, state.vmrsVehicleTypeIDValue]);

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({ ...st, [nm]: val }));
    };

    const validations = () => {
        const { vmrsDescription } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(vmrsDescription)) {
            errors.vmrsDescription = 'This field is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitVmrsCategory = async () => {
        if (validations()) {
            const vmrsCategoryData = {
                vmrsCategoryID: state.vmrsCategoryID,
                vmrsDescription: state.vmrsDescription,
                nominalCode: state.nominalCode,
                vmrsCustomerID: tempOptions.custIDValue?.id,
                vmrsVehicleTypeID: tempOptions.vehicleTypeIDValue?.id,
                vmrsType: state.vmrsType
            };
            setBtnLoader(true);
            let res = await postVMRSCategoryData(vmrsCategoryData);
            if (res.success) {
                showToastSuccess('VMRS Category Added Successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    return (
        <Grid container direction="row" spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Description"
                    id="outlined-basic"
                    name="vmrsDescription"
                    variant="outlined"
                    placeholder="Description"
                    onChange={handleInputChange}
                    value={state.vmrsDescription}
                    errors={state.errors.vmrsDescription}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <SimpleField
                    inputLabel="Nominal Code"
                    id="outlined-basic"
                    name="nominalCode"
                    placeholder="Nominal Code"
                    variant="outlined"
                    onChange={handleInputChange}
                    value={state.nominalCode}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <SimpleField
                    inputLabel="VMRS Type"
                    id="outlined-basic"
                    name="vmrsType"
                    placeholder="VMRS Type"
                    variant="outlined"
                    onChange={handleInputChange}
                    value={state.vmrsType}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <InputLabel shrink>Customer</InputLabel>
                <SingleSelect value={tempOptions.custIDValue} onChange={singleSelectChange('custIDValue')} options={state.customerCodeList} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <InputLabel shrink>Vehicle Type</InputLabel>
                <SingleSelect value={tempOptions.vehicleTypeIDValue} onChange={singleSelectChange('vehicleTypeIDValue')} options={state.vehicleTypeList} />
            </Grid>
            <Grid item xs={12} sm={12} className="submit_btn">
                <SecondaryButton isBtnLoader={btnLoader} onClick={submitVmrsCategory}>{props.vmrsCategoryID ? 'Update' : 'Add'}</SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default VmrsCategoryModal;
