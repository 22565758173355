import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getAllVmrsSubCategorys = async () => {
    let res = await getWithAuth(`VMRSSubCategory`);
    return res;
};

export const getVmrsSubCategoryById = async (id) => {
    let res = await getWithAuth(`VMRSSubCategory/${id}`);
    return res;
};

export const postVmrsSubCategoryData = async (data) => {
    let res = await postWithAuth(`VMRSSubCategory`, data);
    return res;
};
