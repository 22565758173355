import { postWithAuth, getWithAuth } from '../../Basic/HttpService';

export const addPortalSettings = async (data) => {
    let res = await postWithAuth('PortalSetting', data);
    return res;
};

export const getPortalSettings = async () => {
    let res = await getWithAuth(`PortalSetting`);
    return res;
};
