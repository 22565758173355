import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { SecondaryCheckbox, InputText, SecondaryButton, SelectBox, MandatoryField } from '../../../../Core/Controls';
import {
    getVehicleDailyCheckById,
    postVehicleDailyChecks,
    getAllVehicleCheckSectionDropDown
} from '../../../../Core/Services/InternalUser/vehicleDailyChecksAdminService';
import { useToast } from '../../../../Core/Hooks/useToast';
import '../../../commonStyle.scss';
import { MandatoryPositiveInput } from '../../../../Core/Controls/Inputs/PositiveInput';

const CheckSectionModal = (props) => {
    const [state, setState] = useState({
        dailyCheckSectionID: null,
        dailyCheckSectionDescription: '',
        dailyCheckSectionPosition: '',
        dailyCheckSectionActive: true,
        vehicleCategoryDescription: '',
        dailyCheckSectionLegalRequirement: false,
        errors: {},
        categoryList: []
    });

    const { showToastSuccess, showToastError } = useToast();

    //Previous Code using async await

    // useEffect(async () => {
    //     if (props.checkSectionID) {
    //         let res = await getVehicleDailyCheckById(props.checkSectionID);
    //         if (res.success) {
    //             setState((state) => ({
    //                 ...state,
    //                 dailyCheckSectionID: res.data.dailyCheckSectionID,
    //                 dailyCheckSectionDescription: res.data.dailyCheckSectionDescription,
    //                 dailyCheckSectionPosition: res.data.dailyCheckSectionPosition,
    //                 vehicleCategoryDescription: res.data.dailyCheckSectionVehicleCategoryID,
    //                 dailyCheckSectionActive: res.data.dailyCheckSectionActive,
    //                 dailyCheckSectionLegalRequirement: res.data.dailyCheckSectionLegalRequirement
    //             }));
    //         }
    //     }

    //     let res = await getAllVehicleCheckSectionDropDown();
    //     setState((state) => ({
    //         ...state,
    //         categoryList: res.data.map((item) => ({
    //             id: item.vehicleCategoryID,
    //             name: item.vehicleCategoryDescription
    //         }))
    //     }));
    // }, []);

    //New Code using Promise.all

    useEffect(() => {
        let res = props.checkSectionID && getVehicleDailyCheckById(props.checkSectionID);
        let resDrop = getAllVehicleCheckSectionDropDown();

        Promise.all([res, resDrop]).then((value) => {
            let [res, ...resDrop] = value;
            resDrop = resDrop[0];
            if (props.checkSectionID) {
                if (res.success) {
                    setState((state) => ({
                        ...state,
                        dailyCheckSectionID: res.data.dailyCheckSectionID,
                        dailyCheckSectionDescription: res.data.dailyCheckSectionDescription,
                        dailyCheckSectionPosition: res.data.dailyCheckSectionPosition,
                        vehicleCategoryDescription: res.data.dailyCheckSectionVehicleCategoryID,
                        dailyCheckSectionActive: res.data.dailyCheckSectionActive,
                        dailyCheckSectionLegalRequirement: res.data.dailyCheckSectionLegalRequirement
                    }));
                }
            }

            setState((state) => ({
                ...state,
                categoryList: resDrop.data.list.map((item) => ({
                    id: item.vehicleCategoryID,
                    name: item.vehicleCategoryDescription
                }))
            }));
        });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((state) => ({ ...state, [name]: value }));
    };

    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    const validations = () => {
        const { dailyCheckSectionDescription, dailyCheckSectionPosition, vehicleCategoryDescription } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(dailyCheckSectionDescription)) {
            errors.dailyCheckSectionDescription = 'This field is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(dailyCheckSectionPosition)) {
            errors.dailyCheckSectionPosition = 'This field is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(vehicleCategoryDescription)) {
            errors.vehicleCategoryDescription = 'This field is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const checkSectionData = {
                dailyCheckSectionID: state.dailyCheckSectionID,
                dailyCheckSectionPosition: state.dailyCheckSectionPosition,
                dailyCheckSectionDescription: state.dailyCheckSectionDescription,
                dailyCheckSectionActive: state.dailyCheckSectionActive,
                dailyCheckSectionLegalRequirement: state.dailyCheckSectionLegalRequirement,
                // vehicleCategoryDescription: state.vehicleCategoryDescription,
                dailyCheckSectionVehicleCategoryID: state.vehicleCategoryDescription
            };
            let res = await postVehicleDailyChecks(checkSectionData);
            if (res.success) {
                if (props.checkSectionID) {
                    showToastSuccess('Vehicle Daily Check updated successfully');
                } else {
                    showToastSuccess('Vehicle Daily Check added successfully');
                }
                props.onClose(true);
            } else {
                showToastError(res.message);
                // props.onClose(false);
            }
        }
    };

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={6} className="mandatory-fields">
                <MandatoryPositiveInput
                    inputLabel="Section Position"
                    //   type="number"
                    name="dailyCheckSectionPosition"
                    onChange={handleInputChange}
                    value={state.dailyCheckSectionPosition}
                    errors={state.errors.dailyCheckSectionPosition}
                />
            </Grid>
            <Grid item xs={6} className="mandatory-fields">
                <InputLabel required shrink>
                    Category
                </InputLabel>
                <SelectBox
                    name="vehicleCategoryDescription"
                    value={state.vehicleCategoryDescription || ' '}
                    onChange={handleInputChange}
                    List={state.categoryList}
                />
                <FormHelperText error>{state.errors.vehicleCategoryDescription}</FormHelperText>
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Description"
                    name="dailyCheckSectionDescription"
                    onChange={handleInputChange}
                    value={state.dailyCheckSectionDescription}
                    errors={state.errors.dailyCheckSectionDescription}
                />
            </Grid>
            <Grid item xs={6}>
                <SecondaryCheckbox checked={state.dailyCheckSectionActive} name="dailyCheckSectionActive" onChange={handleCheckbox} label="Is Active ?" />
            </Grid>
            <Grid item xs={6}>
                <SecondaryCheckbox
                    checked={state.dailyCheckSectionLegalRequirement}
                    name="dailyCheckSectionLegalRequirement"
                    onChange={handleCheckbox}
                    label="Legal Requirement"
                />
            </Grid>

            <Grid item xs={12} className="submit_btn">
                <SecondaryButton onClick={submitHandler}>{props.checkSectionID ? 'Update' : 'Add'}</SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default CheckSectionModal;
