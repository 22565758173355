import React, { useState, useEffect, useMemo } from 'react';
import { getAllDefectTypes } from '../../../../Core/Services/InternalUser/defectTypeService';
import DataGrid from '../../../../Core/Controls/Grid';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import DefectTypeModal from './DefectTypeModal';
import { BreadCrumbs, AddButton, EditButton, formatters } from '../../../../Core/Controls';
import '../../.././commonStyle.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicles', active: true },
    { name: 'Defect Types ', active: true }
];

const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'defectTypeCode', name: 'Defect Type Code', width: '10%' },
    { key: 'defectTypeDescription', name: 'Description', width: '70' },
    { key: 'defectEmailDocument', name: 'Email Document ?', formatter: formatters.YesNo, width: '15%' }
];

function DefectType(props) {
    const [state, setState] = useState({
        DefectTypeList: [],
        loader: true,
        open: false,
        data: null
    });

    useEffect(async () => {
        pullData();
    }, []);

    const pullData = async () => {
        // let res = await getAllDefectTypes();
        // setState((state) => ({
        //     ...state,
        //     DefectTypeList: res.data.list,
        //     loader: false
        // }));
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const submitHandler = (params) => {
        setState((state) => ({ ...state, open: true, data: params }));
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => submitHandler(row)} toolTipTitle="Edit Defect Type" />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        return newColumn;
    });

    const title = state.data ? 'Update Defect Type' : 'Add Defect Type';
    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullData();
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />

            {/* <DataGrid
                columns={columns}
                rows={state.DefectTypeList}
                isRowHovered={true}
                enableSearch={true}
                loadingData={state.loader}
                btnTitle="Add Defect Type"
                onBtnClick={() => submitHandler(null)}
                paginate
                offset={210}
            /> */}
            <GijgoGrid
                columns={columns}
                getUrl="DefectTypes"
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                btnTitle="Add Defect Type"
                onBtnClick={() => submitHandler(null)}
            />
            {state.open ? (
                <DialogComp title={title} onClose={() => closeModal(false)} maxWidth="sm">
                    <DefectTypeModal data={state.data} onClose={() => closeModal(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
}
export default DefectType;
