import React, { useEffect, useMemo, useRef, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { Button, CircularProgress, Grid, Badge, IconButton, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useLoginInfo } from '../../../Hooks/useLoginInfo';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import './ButtonModal.scss';
import Tooltip from '@material-ui/core/Tooltip';
import { memo } from 'react';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddBoxIcon from '@material-ui/icons/AddBox';

const GlobalCss = withStyles((theme) => ({
    '@global': {
        '.MuiButton-fullWidth ': {
            // padding: '10px',
            [theme.breakpoints.down('sm')]: {
                height: '28px'
            }
        },
        '.MuiButton': {
            // padding: '10px',
            [theme.breakpoints.down('sm')]: {
                height: '28px'
            }
        },
        '.controller-screen .btn-hw .MuiButton-fullWidth': {
            // padding: '10px',
            [theme.breakpoints.down('sm')]: {
                height: '28px'
            }
        }
    }
}))(() => null);

export const AddButton = (props) => {
    const { toolTipTitle, iconFontSize, ...rest } = props;
    return (
        <span className="modal-btn">
            <Tooltip title={toolTipTitle || 'Add'} arrow>
                <AddIcon style={{ fontSize: iconFontSize || 22, marginTop: 10, cursor: 'pointer', ...rest.style }} color="secondary" {...rest} />
            </Tooltip>
        </span>
    );
};
export const AddBoxButton = (props) => {
    const { toolTipTitle, iconFontSize, ...rest } = props;
    return (
        <span className="modal-btn">
            <Tooltip title={toolTipTitle || 'Add'} arrow>
                <AddBoxIcon style={{ fontSize: iconFontSize || 22, marginTop: 10, cursor: 'pointer', ...rest.style }} color="secondary" {...rest} />
            </Tooltip>
        </span>
    );
};

export const EditButton = (props) => {
    const { toolTipTitle, iconFontSize, onClick, ...rest } = props;
    const [show, setShow] = React.useState(false);
    return (
        <span className="modal-btn-update">
            <Tooltip
                title={toolTipTitle || 'Edit'}
                arrow
                open={show}
                disableHoverListener
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
            >
                <EditIcon
                    style={{ fontSize: iconFontSize || 22, marginTop: 5 }}
                    onClick={() => {
                        setShow(false);
                        if (onClick) return onClick();
                    }}
                    color="secondary"
                    {...rest}
                />
            </Tooltip>
        </span>
    );
};

export const DeleteButton = (props) => {
    const { toolTipTitle, iconFontSize, ...rest } = props;
    return (
        <span className="modal-btn-update">
            <Tooltip title={toolTipTitle || 'Delete'} arrow>
                <DeleteIcon style={{ fontSize: iconFontSize || 22, marginTop: 5 }} color="secondary" {...rest} />
            </Tooltip>
        </span>
    );
};

export const CustomIconButton = (props) => {
    const { toolTipTitle, iconFontSize, onClick, ...rest } = props;
    const [show, setShow] = React.useState(false);
    const Icon = props.icon;
    return (
        <span className="custom-icon-btn">
            <Tooltip title={toolTipTitle || ''} arrow open={show} disableHoverListener onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                <div>
                    <Icon
                        style={{ fontSize: iconFontSize || 22, marginTop: 7, color: props.color }}
                        color="secondary"
                        onClick={(e) => {
                            setShow(false);
                            if (onClick) return onClick(e);
                        }}
                        {...rest}
                    />
                    {rest.children}
                </div>
            </Tooltip>
        </span>
    );
};

export const CustomColorButton = (props) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            color: (props) => `${props.textColour || 'white'}`,
            backgroundColor: (props) => `${props.BtnColor || '#53af50'}`,
            '& .MuiButton-contained': {
                color: (props) => `${props.textColour || 'white'}`,
                backgroundColor: (props) => `${props.BtnColor || '#53af50'}`
            },
            '& .MuiButton-root:hover': {
                color: (props) => `${props.textColour || 'white'} !important`,
                backgroundColor: (props) => `${props.BtnColor || '#53af50'} !important`
            }
        }
    }));

    const classes = useStyles(props);
    const { forceEnabled, toolTipTitle, ...rest } = props;
    let { isReadOnlyUser } = useLoginInfo();
    forceEnabled && (isReadOnlyUser = false);
    return (
        <Tooltip title={toolTipTitle || ''} arrow className={classes.container}>
            <Button variant="contained" disabled={isReadOnlyUser || rest.isBtnLoader} {...rest} fullWidth>
                {!rest.isBtnLoader ? (
                    <>{rest.children}</>
                ) : (
                    <Grid style={{ position: 'relative', width: '100%' }}>
                        <Grid>{rest.children}</Grid>

                        <Grid style={{ position: 'absolute', right: 0, top: '3px' }}>
                            <CircularProgress size={20} />
                        </Grid>
                    </Grid>
                )}
            </Button>
        </Tooltip>
    );
};

export const CustomNewColorButton = (props) => {
    const { forceEnabled, toolTipTitle, ...rest } = props;
    let { isReadOnlyUser } = useLoginInfo();
    forceEnabled && (isReadOnlyUser = false);
    return (
        <Tooltip title={toolTipTitle || ''} arrow>
            <Button
                variant="contained"
                disabled={isReadOnlyUser || rest.isBtnLoader}
                {...rest}
                fullWidth
                style={{
                    color: `${props.textColour || 'white'}`,
                    backgroundColor: `${props.BtnColor || '#53af50'}`
                }}
            >
                {!rest.isBtnLoader ? (
                    <>{rest.children}</>
                ) : (
                    <Grid style={{ position: 'relative', width: '100%' }}>
                        <Grid>{rest.children}</Grid>

                        <Grid style={{ position: 'absolute', right: 0, top: '3px' }}>
                            <CircularProgress size={20} />
                        </Grid>
                    </Grid>
                )}
            </Button>
        </Tooltip>
    );
};

export const DefaultButton = (props) => {
    const { forceEnabled, toolTipTitle, ...rest } = props;
    let { isReadOnlyUser } = useLoginInfo();
    forceEnabled && (isReadOnlyUser = false);
    return (
        <Tooltip title={toolTipTitle || ''} arrow>
            <Button variant="contained" disabled={isReadOnlyUser || rest.isBtnLoader} {...rest} fullWidth>
                {!rest.isBtnLoader ? (
                    <>{rest.children}</>
                ) : (
                    <Grid style={{ position: 'relative', width: '100%' }}>
                        <Grid>{rest.children}</Grid>

                        <Grid style={{ position: 'absolute', right: 0, top: '3px' }}>
                            <CircularProgress size={20} />
                        </Grid>
                    </Grid>
                )}
            </Button>
        </Tooltip>
    );
};

export const PrimaryButton = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <>
            <GlobalCss />
            <Tooltip title={toolTipTitle || ''} arrow>
                <DefaultButton {...rest} color="primary" fullWidth>
                    {rest.children}
                </DefaultButton>
            </Tooltip>
        </>
    );
};

export const SecondaryButton = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <>
            <GlobalCss />
            <Tooltip title={toolTipTitle || ''} arrow>
                {/* <GlobalCss /> */}
                <span>
                    <DefaultButton {...rest} color="secondary" fullWidth>
                        {rest.children}
                        {/* <div>{rest.children}</div>{' '}
                    <div>
                        <CircularProgress size={20} />
                    </div> */}
                    </DefaultButton>
                </span>
            </Tooltip>
        </>
    );
};

export const YesButton = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <Tooltip title={toolTipTitle || ''} arrow>
            <div>
                <DefaultButton className={rest.state ? 'btnActive' : 'btninActive'} value={true} {...rest}>
                    {rest.btnName || 'Yes'}
                </DefaultButton>
            </div>
        </Tooltip>
    );
};

export const NoButton = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <Tooltip title={toolTipTitle || ''} arrow>
            <div>
                <DefaultButton className={rest.state ? 'btninActive' : 'btnActiveNo'} value={true} {...rest}>
                    {rest.btnName || 'No'}
                </DefaultButton>
            </div>
        </Tooltip>
    );
};

export const PassAllbutton = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <Tooltip title={toolTipTitle || ''} arrow>
            <div className="passButton">
                <DefaultButton className={rest.state ? 'btnCheckbox' : 'btnWithoutCheckbox'} value={true} {...rest}>
                    <DoneAllIcon className="doneIcon" />
                </DefaultButton>
            </div>
        </Tooltip>
    );
};

const Pass = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <div className="passButton">
            <Tooltip title={toolTipTitle || ''} arrow>
                <DefaultButton className={rest.state ? 'btnCheckbox' : 'btnWithoutCheckbox'} value={true} {...rest}>
                    <DoneIcon className="doneIcon" />
                </DefaultButton>
            </Tooltip>
        </div>
    );
};

const Fail = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <div className="failButton">
            <Tooltip title={toolTipTitle || ''} arrow>
                <DefaultButton className={rest.state ? 'btnCheckbox' : 'btnWithoutCheckbox'} value={true} {...rest}>
                    <ClearIcon className="failIcon" />
                </DefaultButton>
            </Tooltip>
        </div>
    );
};

const NABtn = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <div className="naButton">
            <Tooltip title={toolTipTitle || ''} arrow>
                <DefaultButton className={rest.state ? 'btnCheckbox' : 'btnWithoutCheckbox'} value={true} {...rest}>
                    <label className="naIcon"> N/A</label>
                </DefaultButton>
            </Tooltip>
        </div>
    );
};
const MBtn = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <div className="mButton">
            <Tooltip title={toolTipTitle || ''} arrow>
                <DefaultButton className={rest.state ? 'btnCheckbox' : 'btnWithoutCheckbox'} value={true} {...rest}>
                    <label className="naIcon"> M</label>
                </DefaultButton>
            </Tooltip>
        </div>
    );
};

const RBtn = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <div className="rButton">
            <Tooltip title={toolTipTitle || ''} arrow>
                <DefaultButton className={rest.state ? 'btnCheckbox' : 'btnWithoutCheckbox'} value={true} {...rest}>
                    <label className="naIcon">R</label>
                </DefaultButton>
            </Tooltip>
        </div>
    );
};

const UploadBtn = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <div className="failButton">
            <Tooltip title={toolTipTitle || ''} arrow>
                <DefaultButton className={rest.state ? 'btnCheckbox' : 'btnWithoutCheckbox'} value={true} {...rest}>
                    <CameraAltIcon className="failIcon" />
                </DefaultButton>
            </Tooltip>
        </div>
    );
};

export const UploadWithBadge = (props) => {
    const { toolTipTitle, count, ...rest } = props;
    return (
        <Badge
            badgeContent={count || 0}
            // showZero
            max={99}
            color="primary"
            anchorOrigin={{
                vertical: 'top',
                horizontal: rest.direction ? rest.direction : 'left'
            }}
        >
            <div className="uploadWithBadgeButton">
                <Tooltip title={toolTipTitle || ''} arrow>
                    <DefaultButton className={rest.state ? 'btnCheckbox' : 'btnWithoutCheckbox'} value={true} {...rest}>
                        <CameraAltIcon className="doneIcon" />
                    </DefaultButton>
                </Tooltip>
            </div>
        </Badge>
    );
};

export const CheckCustomdBtn = (props) => {
    const { toolTipTitle, btnLabel, btn, btnID, ...rest } = props;
    return (
        <div className="basicButton">
            <Tooltip title={toolTipTitle || ''} arrow>
                <DefaultButton className={rest.state ? `btnCheckbox${btnID}` : 'btnWithoutCheckbox'} value={true} {...rest}>
                    <label className="naIcon">{btnLabel}</label>
                </DefaultButton>
            </Tooltip>
        </div>
    );
};
export const BackButton = (props) => {
    const { toolTipTitle, iconFontSize, ...rest } = props;
    return (
        <span className="modal-btn-update">
            <Tooltip title={toolTipTitle || 'Go Back'} arrow>
                <IconButton size="small" style={{ cursor: 'pointer' }} color="secondary" {...rest}>
                    <ArrowBackIcon />
                </IconButton>
            </Tooltip>
        </span>
    );
};
export const PassButton = memo(Pass);
export const FailButton = memo(Fail);
export const NAButton = memo(NABtn);
export const MButton = memo(MBtn);
export const RButton = memo(RBtn);
export const UploadButton = memo(UploadBtn);
// export const passAllbutton = memo(PassAll);
