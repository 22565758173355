import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { InputText } from '../../../../../../Core/Controls';
import { InspectionCheCkContaxt } from './InspectionCheckProvide';
import { useContext } from 'react';

export const InspectionBreakPerfomance = ({ classes }) => {
    const { state, handleInput } = useContext(InspectionCheCkContaxt);

    return (
        <Grid item container direction="row" xs={12} sm={12} md={6} lg={6}>
            <Grid item xs={12} md={12} lg={12}>
                <TableContainer component={Paper}>
                    <div
                        style={{
                            border: '1px solid rgba(0,0,0,0.2)'
                        }}
                    >
                        <h3 className={classes.title}> Brake Performance</h3>
                    </div>
                    <Table aria-label="simple table" className={classes.paper}>
                        <TableHead>
                            <TableRow>
                                <TableCell width="15%">IM Reference</TableCell>
                                <TableCell align="left" width="50%">
                                    Item Inspected
                                </TableCell>
                                <TableCell align="left" width="20%">
                                    Efficiency
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell> 71</TableCell>
                                <TableCell> Service Brake Performance</TableCell>
                                <TableCell>
                                    <InputText
                                        placeholder="%"
                                        name="vehicleInspectionServiceBrakePerformance"
                                        value={state.vehicleInspectionServiceBrakePerformance}
                                        onChange={handleInput}
                                        type="number"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell> 72</TableCell>
                                <TableCell> Secondary Brake Performance</TableCell>
                                <TableCell>
                                    <InputText
                                        placeholder="%"
                                        name="vehicleInspectionSecondaryBrakePerformance"
                                        value={state.vehicleInspectionSecondaryBrakePerformance}
                                        onChange={handleInput}
                                        type="number"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell> 73</TableCell>
                                <TableCell> Parking Brake Performance</TableCell>
                                <TableCell>
                                    <InputText
                                        placeholder="%"
                                        name="vehicleInspectionParkingBrakePerformance"
                                        value={state.vehicleInspectionParkingBrakePerformance}
                                        onChange={handleInput}
                                        type="number"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};
