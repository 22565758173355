import { postWithAuth, getWithAuth, putWithAuth } from '../../Basic/HttpService';

export const postHireFleetContract = async (data) => {
    let res = await postWithAuth(`HireFleetContract`, data);
    return res;
};

export const postMaintenanceFleetContract = async (data) => {
    let res = await postWithAuth(`ContractMaintenance`, data);
    return res;
};
export const postFleetContract = async (data) => {
    let res = await postWithAuth(`Contracts`, data);
    return res;
};
export const postReviewStatus = async (id) => {
    let res = await postWithAuth(`Contracts/Contract_SetReviewed?ContractID=${id}`);
    return res;
};

export const postVehicleContract_DeleteContract = async (data) => {
    let res = await postWithAuth(`VehicleContract/VehicleContract_DeleteContract`, data);
    return res;
};

export const getContractHistoryById = async (HistoryID) => {
    let res = await getWithAuth(`VehicleContrcatHistory/VehicleContrcatHistory_GetDetails?HistoryID=${HistoryID}`);
    return res;
};

export const GetMasterContractHistory = async (id) => {
    let res = await getWithAuth(`Contracts/Contracts_GetNotes?Id=${id}`);
    return res;
};

export const VehicleContracts_GetNotesHistory = async (id) => {
    let res = await getWithAuth(`VehicleContract/VehicleContracts_GetNotesHistory?Id=${id}`);
    return res;
};
