import React, { useEffect, useState, useMemo } from 'react';
import { getJourney } from '../../../../../Core/Services/InternalUser/ControllerService/journeyService';
import { EditButton, DataGrid, formatters } from '../../../../../Core/Controls';
import moment from 'moment';
import JourneysAddEditModal from './journeysaddEdit';
import '../../../../commonStyle.scss';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
import { AppStorage } from '../../../../../Core/Basic/storage-service';

const baseColumns = [
    { key: 'action', name: 'Action', width: 60 },
    { key: 'journeyDateStarted', name: 'Start Date', formatter: formatters.Date_DDMMMYYYY_HHMM },
    { key: 'journeyDateEnded', name: 'End Date', formatter: formatters.Date_DDMMMYYYY_HHMM },
    { key: 'journeyStartOdometerReading', name: 'Start Mileage' },
    { key: 'journeyEndOdometerReading', name: 'End Mileage' },
    { key: 'journeyStartLocation', name: 'Location Start' },
    { key: 'journeyEndLocation', name: 'Location End' },
    { key: 'driver', name: 'Driver' }
];

const VehicleJourneys = (props) => {
    const [state, setState] = useState({
        show: false,
        journeyList: [],
        showLoader: true,
        isReload: null
    });
    let checkhaveAccess = AppStorage.getAllowAcces();
    useEffect(async () => {
        pullJourneyData();
    }, [props.VehicleID]);

    const pullJourneyData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const handleAddEdit = (data) => {
        setState((st) => ({
            ...st,
            show: true,
            journeyID: data?.journeyID
        }));
    };

    const closeModal = (res) => {
        setState((st) => ({
            ...st,
            show: false
        }));
        res && pullJourneyData();
    };

    const editFormatter = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton toolTipTitle="Edit Journey" onClick={() => handleAddEdit(row)} />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = editFormatter;
        }
        return tempCols;
    }, []);

    let getUrl = `Journeys?VehicleID=${props.VehicleID}&Active=true`;

    return (
        <div>
            {state.show ? (
                <JourneysAddEditModal onClose={closeModal} VehicleID={props.VehicleID} journeyID={state.journeyID} />
            ) : (
                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl={getUrl}
                    btnTitle={checkhaveAccess?.CanUpdateVehicleJourneys ? 'Add Journey' : ''}
                    onBtnClick={() => handleAddEdit(null)}
                    isReload={state.isReload}
                />
            )}
        </div>
    );
};
export default VehicleJourneys;
