import React, { useState, useEffect, useMemo } from 'react';
import { getAllVehicleTypes } from '../../../../Core/Services/InternalUser/vehicleTypeService';
import DataGrid from '../../../../Core/Controls/Grid';
import { formatters } from '../../../../Core/Controls';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import VehicleTypeModal from './VehicleTypeModal';
import { BreadCrumbs, AddButton, EditButton } from '../../../../Core/Controls';
import '../../.././commonStyle.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicles', active: true },
    { name: 'Vehicle Types', active: true }
];
const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'vehicleTypeDescription', name: 'Vehicle Type' },
    { key: 'vehicleCategoryDescription', name: 'Category', width: '9%' },
    { key: 'vehicleTypeActive', name: 'Is Active ?', formatter: formatters.YesNo, width: '8%' },
    { key: 'vehicleTypeRFLApplicable', name: 'RFL Applicable?', formatter: formatters.YesNo, width: '12%' },
    { key: 'vehicleTypeInsuranceApplicable', name: 'Insurance Applicable?', formatter: formatters.YesNo },
    { key: 'vehicleTypeMOTApplicable', name: 'MOT Applicable?', formatter: formatters.YesNo, width: '13%' },
    { key: 'vehicleTypeMileageApplicable', name: 'Mileage Applicable?', formatter: formatters.YesNo, width: '13%' },
    { key: 'vehicleTypeFuelCardApplicable', name: 'Fuel Card Applicable?', formatter: formatters.YesNo }
];

function VehicleType(props) {
    const [state, setState] = useState({
        vehicleTypeList: [],
        loader: true,
        open: false
    });

    useEffect(async () => {
        pullVehicleType();
    }, []);

    const pullVehicleType = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const handleVehicleType = (params) => {
        setState((state) => ({ ...state, open: true, vehicleTypeID: params }));
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleVehicleType(row.vehicleTypeID)} toolTipTitle="Edit Vehicle Type" />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        return newColumn;
    });

    const title = state.vehicleTypeID ? 'Update Vehicle Types' : 'Add Vehicle Types';

    const closeVehicleModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullVehicleType();
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={'vehicletypes'}
                btnTitle="Add Vehicle Type"
                onBtnClick={() => handleVehicleType(null)}
                isReload={state.isReload}
            />
            {state.open ? (
                <DialogComp title={title} onClose={() => closeVehicleModal(false)} maxWidth="md">
                    <VehicleTypeModal vehicleTypeID={state.vehicleTypeID} onClose={() => closeVehicleModal(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
}

export default VehicleType;
