import { postWithAuth, getWithAuth, putWithAuth } from '../../Basic/HttpService';

export const getAllBranch = async () => {
    let res = await getWithAuth('branches');
    return res;
};

export const getBranchById = async (Id) => {
    let res = await getWithAuth(`branches/${Id}`);
    return res;
};
export const addBranches = async (data) => {
    let res = await postWithAuth('branches', {
        address1: data.address1,
        address2: data.address2,
        address3: data.address3,
        branchCode: data.branchCode,
        branchFax: data.fax,
        country: data.country,
        county: data.county,
        id: data.branchID,
        name: data.branchName,
        phone: data.phone,
        postcode: data.postcode,
        shortName: data.shortName,
        town: data.town
    });
    return res;
};
export const updateBranches = async (data) => {
    let res = await putWithAuth(`branches/${data.branchID}`, {
        address1: data.address1,
        address2: data.address2,
        address3: data.address3,
        branchCode: data.branchCode,
        branchFax: data.fax,
        country: data.country,
        county: data.county,
        id: data.branchID,
        name: data.branchName,
        phone: data.phone,
        postcode: data.postcode,
        shortName: data.shortName,
        town: data.town
    });
    return res;
};
