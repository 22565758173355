import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { TableBody, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: '#f50057'
    },
    body: {
        fontSize: 14,
        padding: '4px',
        height: '40px'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    title: {
        color: '#f50057',
        marginLeft: 2,
        marginTop: 0,
        marginBottom: 0
    },
    cellValue: {
        fontSize: '0.800rem'
    },
    label: {
        position: 'fixed',
        top: 0
    }
});

export default function CustomizedTables(props) {
    const classes = useStyles();

    return (
        <TableContainer style={{ border: '1px solid rgba(0,0,0,0.2)' }}>
            <div
                style={{
                    borderBottom: '1px solid rgba(0,0,0,0.2)'
                }}
            >
                <h2 className={classes.title}>{props.tableHeader}</h2>
            </div>
            <Table aria-label="customized table">
                <TableBody>
                    {props.tableData.map((row) => (
                        <StyledTableRow key={row.name}>
                            <StyledTableCell align="left">
                                <div className="controller-label">
                                    <Typography variant="caption">{row.name1}</Typography>{' '}
                                </div>
                                <Typography className={classes.cellValue} variant="button">
                                    {row.value1 || 'N/A'}
                                </Typography>
                            </StyledTableCell>
                            {row.name2 && (
                                <StyledTableCell align="left">
                                    <div className="controller-label">
                                        <Typography variant="caption">{row.name2}</Typography>{' '}
                                    </div>
                                    <Typography className={classes.cellValue} variant="button">
                                        {row.value2 || 'N/A'}
                                    </Typography>
                                </StyledTableCell>
                            )}
                            {row.name3 && (
                                <StyledTableCell align="left">
                                    <div className="controller-label">
                                        <Typography variant="caption">{row.name3}</Typography>{' '}
                                    </div>
                                    <Typography className={classes.cellValue} variant="button">
                                        {row.value3 || 'N/A'}
                                    </Typography>
                                </StyledTableCell>
                            )}
                            {row.name4 && (
                                <StyledTableCell align="left">
                                    <div className="controller-label">
                                        <Typography variant="caption">{row.name4}</Typography>{' '}
                                    </div>
                                    <Typography className={classes.cellValue} variant="button">
                                        {row.value4 || 'N/A'}
                                    </Typography>
                                </StyledTableCell>
                            )}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
