import React, { useEffect, useMemo, useState } from 'react';
import '../../../../Components/commonStyle.scss';
import { BreadCrumbs, CustomChip, DataGrid, EditButton, formatters, SecondaryButton } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import { GetFilterdReport, GetReportExcelDFile } from '../../../../Core/Services/InternalUser/ReportsService';
import moment from 'moment';
import BuildIcon from '@material-ui/icons/Build';
import { Grid } from '@material-ui/core';

const baseCrumbs = [
    { name: 'Home', path: '/' },
    { name: 'Reports', path: '/', active: true },
    { name: 'Reports List', path: '/reports/reportlist/' }
];

const FilteredReportsListScreen = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const [state, setState] = useState({
        column: [],
        rows: [],
        showLoader: true
    });
    const pullReportsAndUpdateState = async () => {
        let res = await GetFilterdReport(props.match.params.id);

        if (res.success) {
            let colData = res.data.columns?.map((m) => ({
                key: m.reportCatalogueColumnDatabaseName.charAt(0).toLowerCase() + m.reportCatalogueColumnDatabaseName.slice(1),
                name: m.reportCatalogueColumnDisplayName
                // type: m.reportCatalogueColumnFieldType,
                // formatter: m.reportCatalogueColumnFieldType === 'D' ? formatters.Date_DDMMYY : null
            }));

            setState((st) => ({
                ...st,
                column: colData || [],
                rows: res.data.rows || [],
                showLoader: false
            }));
        }
        if (!res.success) {
            showToastError(res.message);
        }
    };

    useEffect(() => {
        pullReportsAndUpdateState();
    }, [props.match.params.id]);

    const crumbs = useMemo(() => {
        const tempCrumbs = [...baseCrumbs];
        tempCrumbs.push({ name: `${props.match.params.name}`, path: '/', active: true });
        return tempCrumbs;
    }, [props.match.params.name]);

    const downloadPdf = async () => {
        let res = await GetReportExcelDFile(props.match.params.id);
        if (res) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.getElementById('tempDownloadPDFLink') || document.createElement('a');
            link.id = 'tempDownloadPDFLink';
            link.href = url;
            link.setAttribute('download', `Report${moment().format('DD-MMM-YYYY HH:mm')}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return true;
        }
    };
    return (
        <div className="screen">
            <Grid container spacing={1}>
                <Grid item>
                    <BreadCrumbs crumbs={crumbs} />
                </Grid>
                {state.rows.length > 0 && (
                    <Grid item>
                        <CustomChip color="secondary" className="btn-m" icon={BuildIcon} label="Download" onClick={downloadPdf} />
                    </Grid>
                )}
            </Grid>
            <DataGrid
                rows={state.rows}
                columns={state.column}
                enableSearch={true}
                loadingData={state.showLoader}
                // btnTitle="Add Report"
                // onBtnClick={() => handleAdd(null)}
                paginate
                offset={230}
            />
        </div>
    );
};
export default FilteredReportsListScreen;
