import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useToast } from '../../../../Core/Hooks/useToast';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormControlLabel, FormControl, FormHelperText } from '@material-ui/core';

import {
    InputText,
    SelectBox,
    PrimaryButton,
    SecondaryCheckbox,
    SecondaryButton,
    DatePicker,
    Multiselect,
    DataGrid,
    formatters,
    AddButton,
    EditButton,
    SingleSelect
} from '../../../../Core/Controls';
import { CheckConviction } from './checkConviction';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
    getLicenceCheckbyid,
    getlicenceConvictionList,
    postLicenceCheck,
    LicenceCheckDropdown
} from '../../../../Core/Services/InternalUser/licenceCheckService';
import moment from 'moment';
const baseColumns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'licenceConvictionDateConvicted', name: 'Date of convicted', formatter: formatters.Date_DDMMYY },
    { key: 'licenceConvictionOffenceCode', name: 'Offence code' },
    { key: 'licenceConvictionOffenceDescription', name: 'Offence discription' },
    { key: 'licenceConvictionPoints', name: 'Points' },
    { key: 'licenceConvictionDateEffectiveUntil', name: 'Effective until', formatter: formatters.Date_DDMMYY },
    { key: 'licenceConvictionDateBannedUntil', name: 'Banned until', formatter: formatters.Date_DDMMYY }
];

const styles = { link: { textDecoration: 'none', color: '#f50057' } };

export const AddEditLicence = (props) => {
    const [state, setState] = useState({
        show: false,
        licenceCheckID: props.licenceCheckID,
        convictionList: [],
        driversList: [],
        licenceCategoriesList: [],
        licenceCheckFrequencyList: [],
        licenceRiskLevelsList: [],
        licenceCheckRiskLevel: '',
        date: moment().format('YYYY-MM-DD'),
        licenceCheckCategoriesAllowed: null
    });

    const [licenceCategories, setlicenceCategories] = useState({
        licenceCheckCategoriesAllowedValue: []
    });

    const [errors, seterrors] = useState({});

    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        let res = await LicenceCheckDropdown();
        setState((st) => ({
            ...st,
            driversList: res.data.drivers,
            licenceCategoriesList: res.data?.licenceCategories,
            licenceCheckFrequencyList: res.data?.licenceCheckFrequency,
            licenceRiskLevelsList: res.data?.licenceRiskLevels
        }));

        if (state.licenceCheckID) {
            let result = await getLicenceCheckbyid(state.licenceCheckID);
            let data = result.data?.details[0];
            setState((st) => ({
                ...st,
                licenceCheckID: data.licenceCheckID,
                licenceCheckDriverID: data.licenceCheckDriverID,
                licenceCheckLicenceNumber: data.licenceCheckLicenceNumber,
                licenceCheckLicenceIssueNumber: data.licenceCheckLicenceIssueNumber,
                licenceCheckAddress: data.licenceCheckAddress,
                licenceCheckDateChecked: formatters.DateFormatter(data.licenceCheckDateChecked),
                licenceCheckDateCheckExpires: formatters.DateFormatter(data.licenceCheckDateCheckExpires),
                licenceCheckPhotoExpiryDate: formatters.DateFormatter(data.licenceCheckPhotoExpiryDate),
                licenceCheckLicenceExpiryDate: formatters.DateFormatter(data.licenceCheckLicenceExpiryDate),
                licenceCheckDateOfBirth: formatters.DateFormatter(data.licenceCheckDateOfBirth),
                licenceCheckRiskLevel: data.licenceCheckRiskLevel,
                licenceCheckPoints: data.licenceCheckPoints,
                licenceCheckAutoCreated: data.licenceCheckAutoCreated,
                licenceCheckCategoriesAllowed: data.licenceCheckCategoriesAllowed.split(',') || [],
                licenceCheckShortTerm: data.licenceCheckShortTerm
            }));
            pullLicenceConvictionList();
        }
    }, []);

    useEffect(() => {
        if (state.licenceCheckCategoriesAllowed) {
            const a = state.licenceCheckCategoriesAllowed.map((i) => i);
            const selectedModule = state.licenceCategoriesList?.filter((w) => a.indexOf(w.id) > -1);
            setlicenceCategories((st) => ({ ...st, licenceCheckCategoriesAllowedValue: selectedModule }));
        }
    }, [state.licenceCategoriesList, state.licenceCheckCategoriesAllowed]);

    // useEffect(() => {
    //     let newSt = {};
    //     if (state.licenceCheckCategoriesAllowed) {
    //         newSt.licenceCheckCategoriesAllowedValue = state.licenceCategoriesList.find(({ id }) => id === state.licenceCheckCategoriesAllowed);
    //     }

    //     setTempOptions((st) => ({ ...st, ...newSt }));
    // }, [state.licenceCheckCategoriesAllowed]);

    // const singleSelectChange = (nm) => (ev, val) => {
    //     setTempOptions((st) => {
    //         let newSt = { ...st, [nm]: val };
    //         return newSt;
    //     });
    // };

    const pullLicenceConvictionList = async () => {
        let res = await getlicenceConvictionList(state.licenceCheckID);
        setState((st) => ({ ...st, convictionList: res.data?.list }));
    };

    const multiChange = (e, value) => {
        setlicenceCategories((st) => ({ ...st, licenceCheckCategoriesAllowed: value }));
        setState((st) => {
            const nst = { ...st, licenceCheckCategoriesAllowed: value.map((c) => c.id) };
            return nst;
        });
    };
    const handleConvictionModal = () => {
        setState((st) => ({ ...st, show: !st.show, licenceConvictionCheckID: null }));
    };

    const handleChange = useCallback((e) => {
        const { name, value, type } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: true }));
        } else if (value === 'false' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: false }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    }, []);

    const rederEdit = (val) => {
        setState((st) => ({
            ...st,
            licenceConvictionCheckID: val,
            show: !st.show
        }));
    };

    const dataFormatter = (data) => {
        return <EditButton onClick={() => rederEdit(data.row.licenceConvictionID)} />;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;

            actionCol.headerRenderer = () => {
                return (
                    <span>
                        <AddButton toolTipTitle="Add Convict" onClick={() => handleConvictionModal(null)} />
                    </span>
                );
            };
        }

        return tempCols;
    }, []);

    const btnCondition = state.licenceCheckID ? 'Upadate' : 'Add';

    const validation = () => {
        let {
            licenceCheckDateChecked,
            licenceCheckLicenceNumber,
            licenceCheckLicenceExpiryDate,
            licenceCheckPhotoExpiryDate,
            licenceCheckPoints,
            licenceCheckCategoriesAllowed
        } = state;
        let isValid = true;
        let errors = {};
        if (!licenceCheckDateChecked) {
            errors.licenceCheckDateChecked = 'Date of check is required';
            isValid = false;
        }
        if (!licenceCheckLicenceNumber) {
            errors.licenceCheckLicenceNumber = 'Licence number is required';
            isValid = false;
        }
        if (!licenceCheckLicenceExpiryDate) {
            errors.licenceCheckLicenceExpiryDate = 'Licence expiry date is required';
            isValid = false;
        }
        if (!licenceCheckPhotoExpiryDate) {
            errors.licenceCheckPhotoExpiryDate = 'Photo expiry date is required';
            isValid = false;
        }
        if (!licenceCheckPoints) {
            errors.licenceCheckPoints = 'Current effective points is required';
            isValid = false;
        }
        if (!licenceCheckCategoriesAllowed) {
            errors.licenceCheckCategoriesAllowed = 'licence Categories is required';
            isValid = false;
        }

        seterrors((st) => ({ ...st, ...errors }));

        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            let data = {
                licenceCheckID: state.licenceCheckID,
                licenceCheckDriverID: props.driverID,
                licenceCheckDateChecked: state.licenceCheckDateChecked,
                licenceCheckDateCheckExpires: state.licenceCheckDateCheckExpires || '',
                licenceCheckLicenceNumber: state.licenceCheckLicenceNumber,
                licenceCheckLicenceIssueNumber: state.licenceCheckLicenceIssueNumber,
                licenceCheckAddress: state.licenceCheckAddress,
                licenceCheckPhotoExpiryDate: state.licenceCheckPhotoExpiryDate,
                licenceCheckLicenceExpiryDate: state.licenceCheckLicenceExpiryDate,
                licenceCheckDateOfBirth: state.licenceCheckDateOfBirth || null,
                licenceCheckRiskLevel: state.licenceCheckRiskLevel,
                licenceCheckPoints: state.licenceCheckPoints,
                licenceCheckAutoCreated: state.licenceCheckAutoCreated || true,
                licenceCheckCategoriesAllowed: state.licenceCheckCategoriesAllowed.length > 0 ? state.licenceCheckCategoriesAllowed : null,
                licenceCheckShortTerm: state.licenceCheckShortTerm
            };

            let res = await postLicenceCheck(data);
            if (res.success) {
                showToastSuccess(`Licence Check ${btnCondition} Successfully`);
                props.onClose(true);
            } else {
                showToastError('Error');
            }
        }
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink error>
                        Date of check
                    </InputLabel>
                    <DatePicker
                        onChange={handleChange}
                        name="licenceCheckDateChecked"
                        value={state.licenceCheckDateChecked}
                        InputProps={{ inputProps: { max: `${state.date}` } }}
                    />
                    <FormHelperText error>{errors.licenceCheckDateChecked}</FormHelperText>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink error>
                        Licence number
                    </InputLabel>
                    <InputText placeholder="Licence number" onChange={handleChange} name="licenceCheckLicenceNumber" value={state.licenceCheckLicenceNumber} />
                    <FormHelperText error>{errors.licenceCheckLicenceNumber}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink>Issue number</InputLabel>
                    <InputText
                        placeholder="Issue number"
                        onChange={handleChange}
                        name="licenceCheckLicenceIssueNumber"
                        value={state.licenceCheckLicenceIssueNumber}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink>Short term licence</InputLabel>
                    <FormControl component="fieldset">
                        <Grid item xs={12}>
                            <RadioGroup
                                row
                                aria-label="licenceCheckShortTerm"
                                name="licenceCheckShortTerm"
                                onChange={handleChange}
                                value={state.licenceCheckShortTerm}
                            >
                                <FormControlLabel checked={state.licenceCheckShortTerm === true} value={'true'} control={<Radio />} label="Yes" />
                                <FormControlLabel checked={state.licenceCheckShortTerm === false} value={'false'} control={<Radio />} label="No" />
                            </RadioGroup>
                        </Grid>
                    </FormControl>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4}lg={3}>
                    <InputLabel shrink>Date Expiry check</InputLabel>
                    <DatePicker onChange={handleChange} name="licenceCheckDateCheckExpires" value={state.licenceCheckDateCheckExpires} />
                </Grid> */}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink>Date of birth</InputLabel>
                    <DatePicker onChange={handleChange} name="licenceCheckDateOfBirth" value={state.licenceCheckDateOfBirth} />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4}lg={3}>
                    <InputLabel shrink >
                        Driver name
                    </InputLabel>
                    <SelectBox onChange={handleChange} List={state.driversList} name="licenceCheckDriverID" value={state.licenceCheckDriverID} />
                </Grid> */}
                <Grid item xs={12} sm={6} md={8} lg={9}>
                    <InputLabel shrink>Address</InputLabel>
                    <InputText placeholder="Address" onChange={handleChange} name="licenceCheckAddress" value={state.licenceCheckAddress} />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink error>
                        Licence expiry date
                    </InputLabel>
                    <DatePicker onChange={handleChange} name="licenceCheckLicenceExpiryDate" value={state.licenceCheckLicenceExpiryDate} />
                    <FormHelperText error>{errors.licenceCheckLicenceExpiryDate}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink error>
                        Photocard expiry date
                    </InputLabel>
                    <DatePicker onChange={handleChange} name="licenceCheckPhotoExpiryDate" value={state.licenceCheckPhotoExpiryDate} />
                    <FormHelperText error>{errors.licenceCheckPhotoExpiryDate}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink error>
                        Current effective points{' '}
                    </InputLabel>
                    <InputText placeholder="Current effective points" onChange={handleChange} name="licenceCheckPoints" value={state.licenceCheckPoints} />
                    <FormHelperText error>{errors.licenceCheckPoints}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink>Risk level</InputLabel>
                    <SelectBox List={state.licenceRiskLevelsList} name="licenceCheckRiskLevel" value={state.licenceCheckRiskLevel} onChange={handleChange} />
                </Grid>
                {/* <Grid item xs={12} sm={4} style={{ marginTop: 17 }} eroro>
                    <SecondaryCheckbox onChange={handleCheckbox} label="Add details of convictions" />
                </Grid> */}
                <Grid item xs={12} sm={12}>
                    <InputLabel shrink error>
                        Licence category
                    </InputLabel>
                    {/* <SingleSelect
                        options={state.licenceCategoriesList}
                        onChange={singleSelectChange('licenceCheckCategoriesAllowedValue')}
                        name="licenceCheckCategoriesAllowedValue"
                        value={tempOptions.licenceCheckCategoriesAllowedValue}
                    /> */}
                    <Multiselect
                        options={state.licenceCategoriesList || []}
                        onChange={multiChange}
                        placeholder="Licence category"
                        name="licenceCheckCategoriesAllowed"
                        value={licenceCategories.licenceCheckCategoriesAllowedValue || []}
                    />
                    <FormHelperText error>{errors.licenceCheckCategoriesAllowed}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <InputLabel style={{ fontSize: 13 }}>
                        Please indicate which categories this driver holds on their driving licence.The categories listed here are the ones currently used by
                        the DVLA The resources below may assist you
                    </InputLabel>
                    <ul style={{ margin: 1, fontSize: 15 }}>
                        <li>
                            <a style={styles.link} href="https://www.gov.uk/driving-licence-categories" target="_blank">
                                Description of driving licence categories
                            </a>
                        </li>
                        <li>
                            <a style={styles.link} href="https://www.gov.uk/old-driving-licence-categories" target="_blank">
                                Compare old and new driving licence categories
                            </a>
                        </li>
                        <li>
                            <a style={styles.link} href="https://www.gov.uk/vehicles-can-drive" target="_blank">
                                Vehides you can drive tool
                            </a>
                        </li>
                    </ul>
                </Grid>
            </Grid>
            <br />
            <Grid container xs={12} spacing={1}>
                <Grid item xs={12} sm={6}>
                    <SecondaryButton onClick={handleSubmit}>{btnCondition}</SecondaryButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SecondaryButton onClick={() => props.onClose(false)}>Cancel</SecondaryButton>
                </Grid>
            </Grid>
            {/* <PrimaryButton onClick={handleConvictionModal}>Add Conviction</PrimaryButton> */}
            {state.licenceCheckID && (
                <>
                    <DataGrid
                        rows={state.convictionList}
                        columns={columns}
                        // loadingData={state.showLoader}
                        // enableSearch={true}
                        // btnTitle={'Add Conviction'}
                        // idFieldName="LicenceChecks"
                        // fileUploaderConfigName="Add Conviction"
                        // onBtnClick={() => handleConvictionModal(null)}
                        offset={600}
                    />

                    {state.show ? (
                        <DialogComp title={'Add Conviction'} onClose={handleConvictionModal} maxWidth="md" overflow="auto">
                            <CheckConviction
                                state={state}
                                handleChange={handleChange}
                                handleConvictionModal={handleConvictionModal}
                                pullLicenceConvictionList={pullLicenceConvictionList}
                            />
                        </DialogComp>
                    ) : null}
                </>
            )}
        </div>
    );
};
