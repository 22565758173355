import { postWithAuth, getWithAuth } from "../../Basic/HttpService";

export const getAllVMRSCategory = async () => {
    let res = await getWithAuth(`VMRSCategory`);
    return res;
};

export const getVMRSCategoryById = async (id) => {
    let res = await getWithAuth(`VMRSCategory/${id}`);
    return res;
};

export const postVMRSCategoryData = async (data) => {
    let res = await postWithAuth(`VMRSCategory`, data);
    return res;
};

export const getVmrsCategoryDropDown = async () => {
    let res = await getWithAuth(`VMRSCategory/screenDropdowns`);
    return res;
};
