import React, { useEffect, useState, useMemo } from 'react';
import { EditButton, BreadCrumbs, formatters } from '../../../../../Core/Controls';
import '../../../../commonStyle.scss';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
import JobDetail from '../../JOBS/JOBItems/JobDetails';
import { useLoginInfo } from '../../../../../Core/Hooks/useLoginInfo';
import { Button, ButtonGroup, Grid } from '@material-ui/core';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
import InvoiceModalScreen from '../../../Invoices/addEditInvoice';

const final = [
    { name: 'Home', path: '/' },
    { name: 'Invoice', active: true }
];

const getCrumbs = (code) => {
    let newColumn = [...final];
    if (code === 'P') {
        newColumn.splice(3, 0, { name: 'Purchase-Paid', active: true });
    } else if (code === 'U') {
        newColumn.splice(3, 0, { name: 'Purchase-Unpaid', active: true });
    } else if (code === 'H') {
        newColumn.splice(3, 0, { name: 'Purchase-Hold', active: true });
    } else {
        newColumn.splice(2, 0, { name: 'Purchase-All', active: true });
    }
    return newColumn;
};

const basecolumns = [
    { key: 'action', name: 'Action', width: 80, addFileUploaderIcon: true },
    { key: 'invoiceDate', name: 'Invoice Date', formatter: formatters.Date_DDMMYYYY },
    { key: 'dateDue', name: 'Due Date', formatter: formatters.Date_DDMMYYYY },
    { key: 'datePaid', name: 'Date Paid', formatter: formatters.Date_DDMMYYYY },
    { key: 'invoiceNumber', name: 'Number' },
    { key: 'status', name: 'Status' },
    { key: 'supplier', name: 'Supplier' },
    { key: 'vat', name: 'VAT' },
    { key: 'net', name: 'NET' }
];

const VehiclePurchaseInvoice = (props) => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const [state, setState] = useState({
        modalToggle: false,
        invoiceID: null,
        invoiceList: [],
        showLoader: true,
        VehicleID: props.VehicleID || null,
        ServiceAgentID: props.ServiceAgentID || null,
        isReload: null,
        statusCode: props.statusCode || ''
    });
    const crumbs = getCrumbs(state.statusCode);
    const isCustomer = useLoginInfo().userRoles.includes('customer');

    useEffect(async () => {
        let getUrl;
        if (props.fromPage === 'job') {
            getUrl = `VehicleTabs/Invoices_Get_Vehicle${props.JobID ? `?JobID=${props.JobID}` : ''}`;
        } else {
            if (props.VehicleID) {
                getUrl = `VehicleTabs/Invoices_Get_Vehicle${props.VehicleID ? `?VehicleID=${props.VehicleID}` : ''}`;
            } else {
                getUrl = `VehicleTabs/Invoices_Get_Vehicle?status=${state.statusCode}`;
            }
        }

        setState((st) => ({
            ...st,
            getUrl: getUrl
        }));
        // pullInvoiceData();
    }, [state.statusCode]);

    const pullInvoiceData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const actionButton = (data, row) => {
        return (
            <div className="Invoice-btn-update">
                <EditButton onClick={() => handleInvoiceUpdate(row)} toolTipTitle="Edit Invoice" />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = actionButton;
        }
        return tempCols;
    }, []);

    const title = `${props.fromPage === 'job' ? '' : isCustomer ? '' : 'Add Invoice'}`;

    const handleInvoiceUpdate = (params) => {
        setState((state) => ({ ...state, modalToggle: true, invoiceID: params?.invoiceID, VehicleID: params?.vehicleID }));
    };

    const closeAddDialog = (res) => {
        setState((state) => ({ ...state, modalToggle: false }));
        res && pullInvoiceData();
    };

    const handleChange = (statusID) => {
        setState((st) => ({ ...st, statusCode: statusID }));
    };

    return (
        <div className={props.fromPage === 'job' || props.VehicleID ? '' : 'screen'}>
            {state.modalToggle ? (
                <InvoiceModalScreen ServiceAgentID={state.ServiceAgentID} invoiceID={state.invoiceID} VehicleID={state.VehicleID} onClose={closeAddDialog} />
            ) : (
                <>
                    {!props.VehicleID && (
                        <Grid container justify="space-between">
                            <Grid item>
                                <BreadCrumbs crumbs={crumbs} />
                            </Grid>
                            <Grid item>
                                <Grid component="label" item alignItems="center">
                                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                                        <Button
                                            style={{ fontSize: 12 }}
                                            variant={state.statusCode === '' ? 'contained' : 'outlined'}
                                            onClick={() => handleChange('')}
                                        >
                                            All
                                        </Button>
                                        <Button
                                            style={{ fontSize: 12 }}
                                            variant={state.statusCode === 'P' ? 'contained' : 'outlined'}
                                            onClick={() => handleChange('P')}
                                        >
                                            Paid
                                        </Button>
                                        <Button
                                            style={{ fontSize: 12 }}
                                            variant={state.statusCode === 'U' ? 'contained' : 'outlined'}
                                            onClick={() => handleChange('U')}
                                        >
                                            Unpaid
                                        </Button>
                                        <Button
                                            style={{ fontSize: 12 }}
                                            variant={state.statusCode === 'H' ? 'contained' : 'outlined'}
                                            onClick={() => handleChange('H')}
                                        >
                                            Hold
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {props.JobID && <JobDetail scheduleJobID={props.JobID} refresherFlag={state.refresherFlag} VehicleID={props.VehicleID} />}
                    <GijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={columns}
                        getUrl={state.getUrl}
                        onBtnClick={handleInvoiceUpdate}
                        idFieldName={`${checkhaveAccess?.CanUploadInvoiceDocuments ? 'invoiceID' : null}`}
                        fileUploaderConfigName={`${checkhaveAccess?.CanUploadInvoiceDocuments ? 'Invoice' : null}`}
                        isReload={state.isReload}
                        offset={props.fromPage === 'job' ? 410 : 350}
                    />
                </>
            )}
        </div>
    );
};

export default VehiclePurchaseInvoice;
