import { postWithAuth, getWithAuth } from '../../Basic/HttpService';

export const fleetInsuranceList = async (id) => {
    let res = await getWithAuth(`FleetInsurances?CustomerID=${id}`);
    return res;
};

export const getFleetInsurancesByID = async (id) => {
    let res = await getWithAuth(`FleetInsurances/${id}`);
    return res;
};

export const postFleetInsurances = async (data) => {
    let res = await postWithAuth(`FleetInsurances`, data);
    return res;
};
