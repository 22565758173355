import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { SecondaryButton, MandatoryField } from '../../../../Core/Controls';
import { getFuelTypesById, postFuelTypesData } from '../../../../Core/Services/InternalUser/fuelTypeService';
import { SecondaryCheckbox } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import '../../.././commonStyle.scss';
import { YesButton, NoButton } from '../../../../Core/Controls';

function FuelTypesModal(props) {
    const [state, setState] = useState({
        fuelTypeID: null,
        fuelTypeDescription: '',
        fuelTypeDisplayBatteryLevel: false,
        fuelTypeActive: true,
        errors: {}
    });

    const { showToastSuccess, showToastError } = useToast();

    const [btnLoader, setBtnLoader] = useState(false);

    useEffect(async () => {
        if (props.fuelTypeID) {
            let res = await getFuelTypesById(props.fuelTypeID);
            if (res.success) {
                setState((state) => ({
                    ...state,
                    fuelTypeID: res.data.fuelTypeID,
                    fuelTypeDescription: res.data.fuelTypeDescription,
                    fuelTypeDisplayBatteryLevel: res.data.fuelTypeDisplayBatteryLevel,
                    fuelTypeActive: res.data.fuelTypeActive
                }));
            }
        }
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };
    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const validations = () => {
        const { fuelTypeDescription } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(fuelTypeDescription)) {
            errors.fuelTypeDescription = 'Fuel Type Description is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            setBtnLoader(true);
            const AllData = {
                fuelTypeID: state.fuelTypeID,
                fuelTypeDescription: state.fuelTypeDescription,
                fuelTypeDisplayBatteryLevel: state.fuelTypeDisplayBatteryLevel,
                fuelTypeActive: state.fuelTypeActive
            };
            let res = await postFuelTypesData(AllData);
            if (res.success) {
                showToastSuccess('Fuel Types Saved Successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Fuel Type Description"
                    name="fuelTypeDescription"
                    placeholder="Fuel Type Description"
                    onChange={handleInputChange}
                    value={state.fuelTypeDescription}
                    errors={state.errors.fuelTypeDescription}
                />
            </Grid>
            <Grid item xs={12} sm={4} style={{ paddingTop: '15px' }}>
                Display Battery Level?
            </Grid>
            <Grid item xs={6} sm={4}>
                <YesButton state={state.fuelTypeDisplayBatteryLevel} name="fuelTypeDisplayBatteryLevel" onClick={() => handleClick('fuelTypeDisplayBatteryLevel', true)} />
            </Grid>
            <Grid item xs={6} sm={4}>
                <NoButton state={state.fuelTypeDisplayBatteryLevel} name="fuelTypeDisplayBatteryLevel" onClick={() => handleClick('fuelTypeDisplayBatteryLevel', false)} />
            </Grid>
            <Grid item xs={12}>
                <SecondaryCheckbox checked={state.fuelTypeActive} name="fuelTypeActive" onChange={handleCheckbox} label="Is Active ?" />
            </Grid>

            <Grid item xs={12} className="submit_btn">
                <SecondaryButton isBtnLoader={btnLoader} onClick={submitHandler}>{props.fuelTypeID ? 'Update' : 'Add'}</SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default FuelTypesModal;
