import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import SyncIcon from '@material-ui/icons/Sync';
import DoneIcon from '@material-ui/icons/Done';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

import './jobs.scss';
import { getAllJobDropDowns } from '../../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import AssignJobStep from './AssignStep';
import JobsWorkInProgress from './WorkInProgressStep';
import JobTechSignOff from './TechnecianSignOffStep';
import JobServiceArea from './ServiceAreaStep';
import JobAdminstration from './Administrationstep';
import { Grid } from '@material-ui/core';
import { formatters } from '../../../../../../Core/Controls';
import { useLoginInfo } from '../../../../../../Core/Hooks/useLoginInfo';
import FactoryInspection from './FactoryInspection';
import BuildIcon from '@material-ui/icons/Build';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import { useWindowSize } from '../../../../../../Core/Hooks/useWindowSize';
import { useHistory } from 'react-router-dom';

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)'
    },
    active: {
        '& $line': {
            borderColor: 'red'
        }
    },
    completed: {
        '& $line': {
            borderColor: 'red'
        }
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1
    }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center'
    },
    active: {
        color: '#784af4'
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18
    }
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active
            })}
        >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 15
    },
    active: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    completed: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    line: {
        height: 5,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: 'black',
        width: 35,
        height: 35,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        '@media(max-width: 1024px)': {
            width: 22,
            height: 22 // Adjust height for devices between 821px and 1024px
        }
    },
    active: {
        backgroundImage: 'linear-gradient( 136deg, #0f9b0f  30%, #0b4d0b 80%, #031a03 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        color: '#fff'
    },
    factoryInspection: {
        backgroundImage: 'linear-gradient( 136deg, #0f9b0f  30%, #0b4d0b 80%, #031a03 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        color: '#fff'
    },

    completed: {
        backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        color: '#fff'
    }
});

function ColorlibStepIcon({ isFactoryInspectionStep, jobStatusID, ...props }) {
    const classes = useColorlibStepIconStyles();
    const { active, completed, icon } = props;
    let icons = {};

    if (isFactoryInspectionStep) {
        icons = {
            1: <ReportProblemIcon style={{ fontSize: 16 }} />,
            2: <GroupAddIcon style={{ fontSize: 16 }} />,
            3: <SyncIcon style={{ fontSize: 16 }} />,
            4: <DoneIcon style={{ fontSize: 16 }} />,
            5: <BuildIcon style={{ fontSize: 16 }} />,
            6: <QueryBuilderIcon style={{ fontSize: 16 }} />,
            7: <ImportantDevicesIcon style={{ fontSize: 16 }} />,
            8: <AssignmentTurnedInIcon style={{ fontSize: 16 }} />
        };
    } else {
        icons = {
            1: <ReportProblemIcon style={{ fontSize: 16 }} />,
            2: <GroupAddIcon style={{ fontSize: 16 }} />,
            3: <SyncIcon style={{ fontSize: 16 }} />,
            4: <DoneIcon style={{ fontSize: 16 }} />,
            5: <QueryBuilderIcon style={{ fontSize: 16 }} />,
            6: <ImportantDevicesIcon style={{ fontSize: 16 }} />,
            7: <AssignmentTurnedInIcon style={{ fontSize: 16 }} />
        };
    }

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: icon == 5 && jobStatusID === 'FI' ? active : completed,
                [classes.factoryInspection]: icon == 5 && jobStatusID === 'FI'
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node
};
// function TabColorlibStepIcon({ isFactoryInspectionStep, jobStatusID, ...props }) {
//     const classes = useColorlibStepIconStyles();
//     const { active, completed, icon } = props;
//     let icons = {};

//     if (isFactoryInspectionStep) {
//         icons = {
//             1: <ReportProblemIcon style={{ fontSize: 8 }} />,
//             2: <GroupAddIcon style={{ fontSize: 8 }} />,
//             3: <SyncIcon style={{ fontSize: 8 }} />,
//             4: <DoneIcon style={{ fontSize: 8 }} />,
//             5: <BuildIcon style={{ fontSize: 8 }} />,
//             6: <QueryBuilderIcon style={{ fontSize: 8 }} />,
//             7: <ImportantDevicesIcon style={{ fontSize: 8 }} />,
//             8: <AssignmentTurnedInIcon style={{ fontSize: 8 }} />
//         };
//     } else {
//         icons = {
//             1: <ReportProblemIcon style={{ fontSize: 8 }} />,
//             2: <GroupAddIcon style={{ fontSize: 8 }} />,
//             3: <SyncIcon style={{ fontSize: 8 }} />,
//             4: <DoneIcon style={{ fontSize: 8 }} />,
//             5: <QueryBuilderIcon style={{ fontSize: 8 }} />,
//             6: <ImportantDevicesIcon style={{ fontSize: 8 }} />,
//             7: <AssignmentTurnedInIcon style={{ fontSize: 8 }} />
//         };
//     }

//     return (
//         <div
//             className={clsx(classes.root, {
//                 [classes.active]: active,
//                 [classes.completed]: icon == 5 && jobStatusID === 'FI' ? active : completed,
//                 [classes.factoryInspection]: icon == 5 && jobStatusID === 'FI'
//             })}
//         >
//             {icons[String(props.icon)]}
//         </div>
//     );
// }

function TabColorlibStepIcon({ isFactoryInspectionStep, jobStatusID, ...props }) {
    const classes = useColorlibStepIconStyles();
    const { active, completed, icon } = props;
    let icons = {};

    if (isFactoryInspectionStep) {
        icons = {
            1: <ReportProblemIcon style={{ fontSize: 8 }} />,
            2: <GroupAddIcon style={{ fontSize: 8 }} />,
            3: <SyncIcon style={{ fontSize: 8 }} />,
            4: <DoneIcon style={{ fontSize: 8 }} />,
            5: <BuildIcon style={{ fontSize: 8 }} />,
            6: <QueryBuilderIcon style={{ fontSize: 8 }} />,
            7: <ImportantDevicesIcon style={{ fontSize: 8 }} />,
            8: <AssignmentTurnedInIcon style={{ fontSize: 8 }} />
        };
    } else {
        icons = {
            1: <ReportProblemIcon style={{ fontSize: 8 }} />,
            2: <GroupAddIcon style={{ fontSize: 8 }} />,
            3: <SyncIcon style={{ fontSize: 8 }} />,
            4: <DoneIcon style={{ fontSize: 8 }} />,
            5: <QueryBuilderIcon style={{ fontSize: 8 }} />,
            6: <ImportantDevicesIcon style={{ fontSize: 8 }} />,
            7: <AssignmentTurnedInIcon style={{ fontSize: 8 }} />
        };
    }

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: icon == 5 && jobStatusID === 'FI' ? active : completed,
                [classes.factoryInspection]: icon == 5 && jobStatusID === 'FI'
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

TabColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiStepper-root': {
            padding: '5px'
        }
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    stepLabel: {
        fontSize: 14,
        '@media(max-width:1024px)': {
            fontSize: 12,
            fontWeight: 600
        }
    },
    stepAssign: {
        fontSize: 10,
        fontWeight: 700,
        '@media(max-width:1024px)': {
            fontSize: 8,
            fontWeight: 600
        }
    },
    stepdate: {
        fontSize: 10,
        fontWeight: 700,
        '@media(max-width:1024px)': {
            fontSize: 8,
            fontWeight: 600
        }
    },
    stepperlabel: { marginTop: -10 }
}));

let workInProgConfig = {
    AP: {
        label: 'Awaiting Parts',
        date: 'jobAwaitingPartsDate',
        userName: 'jobAwaitingPartsUserName'
    },
    ES: {
        label: 'Submitted for Estimate',
        date: 'jobEstimatedDate',
        userName: 'jobEstimatedUserName'
    },
    EA: {
        label: 'Estimate Approved',
        date: 'jobEstimateApprovedDate',
        userName: 'jobEstimateApprovedUserName'
    },
    JP: {
        label: 'Paused',
        date: 'jobPausedDate',
        userName: 'jobPausedUserName'
    },
    WP: {
        label: 'Work In Progress',
        date: 'jobWorkInProgressDate',
        userName: 'jobWorkInProgressUserName'
    },
    TA: { label: 'Not Started' }
};
function getSteps(val) {
    return [
        {
            label: (
                <Grid container>
                    <Grid item xs={12}>
                        Created
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{val.jobCreatedUserName}</b>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 9, fontWeight: 700 }}>
                            <b>{formatters.DateTimeFormatter(val.jobDateCreated)}</b>
                        </small>
                    </Grid>
                </Grid>
            )
        },
        {
            label: (
                <Grid container>
                    <Grid item xs={12}>
                        {val.jobEngineerUserID ? 'Assigned' : 'Unassigned'}
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{val.jobEngineer?.toUpperCase()}</b>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 9, fontWeight: 700 }}>
                            <b>{formatters.DateTimeFormatter(val.jobDateEngineerAssigned)}</b>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>{val.jobEngineerAssignedUserName && <b>({val.jobEngineerAssignedUserName})</b>}</small>
                    </Grid>
                </Grid>
            )
        },
        {
            label: (
                <Grid container>
                    <Grid item xs={12}>
                        {workInProgConfig[val.jobStatusID]?.label
                            ? workInProgConfig[val.jobStatusID]?.label
                            : !val.jobEngineerUserID
                            ? 'Not Started'
                            : 'Work in Progress'}
                    </Grid>
                    {/* {val.jobStatusID === 'FI' ? (
                        <Grid item xs={12}>
                            <small style={{ fontSize: 10, fontWeight: 700 }}>
                                <b>Factory Inspection </b>
                            </small>
                        </Grid>
                    ) : ( */}
                    <>
                        <Grid item xs={12}>
                            <small style={{ fontSize: 10, fontWeight: 700 }}>
                                <b> {val[`${workInProgConfig[val.jobStatusID]?.userName}`] || val?.jobWorkInProgressUserName}</b>
                            </small>
                        </Grid>
                        <Grid item xs={12}>
                            <small style={{ fontSize: 9, fontWeight: 700 }}>
                                <b>{formatters.DateTimeFormatter(val[`${workInProgConfig[val.jobStatusID]?.date}`] || val.jobWorkInProgressDate)}</b>
                            </small>
                        </Grid>
                    </>
                    {/* )} */}
                </Grid>
            )
        },
        {
            label: (
                <Grid container>
                    <Grid item xs={12}>
                        Technician Sign off
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{val.jobSignOffUserName}</b>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 9, fontWeight: 700 }}>
                            <b>{formatters.DateTimeFormatter(val.jobSignOffDate)}</b>
                        </small>
                    </Grid>
                </Grid>
            )
        },
        {
            label: (
                <Grid container>
                    <Grid item xs={12}>
                        {!val.jobCostReviewedDate ? 'Awaiting Cost Review' : 'Cost Reviewed'}
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{val.jobCostReviewedUserName}</b>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 9, fontWeight: 700 }}>
                            <b>{formatters.DateTimeFormatter(val.jobCostReviewedDate)}</b>
                        </small>
                    </Grid>
                </Grid>
            )
        },
        {
            label: (
                <Grid container>
                    <Grid item xs={12}>
                        {'Administration'}
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{(val.jobStatusID === 'AS' || val.jobStatusID === 'IN') && val.vehicleJobStatus}</b>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{val.jobStatusID === 'AS' ? val.jobProformaCreatedUserName : val.jobApprovedUserName}</b>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 9, fontWeight: 700 }}>
                            <b>{formatters.DateTimeFormatter(val.jobStatusID === 'AS' ? val.jobProformaDateCreated : val.jobApprovedDate)}</b>
                        </small>
                    </Grid>
                </Grid>
            )
        },
        {
            label: (
                <Grid container>
                    <Grid item xs={12}>
                        Completed
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{val.jobCompletionUserName}</b>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 9, fontWeight: 700 }}>
                            <b>{formatters.DateTimeFormatter(val.jobCompletionDate)}</b>
                        </small>
                    </Grid>
                </Grid>
            )
        }
    ];
}

function getSteps_Fi(val, isFactoryInspectionStep, classes) {
    let list = [];
    let tempList = [
        {
            label: (
                <div className={classes.stepperlabel}>
                    <div className={classes.stepLabel}>Created</div>
                    <div className={classes.stepAssign}>{val.jobCreatedUserName}</div>
                    <div className={classes.stepdate}>{formatters.DateTimeFormatter(val.jobDateCreated)}</div>
                </div>
            )
        },
        {
            label: (
                <div className={classes.stepperlabel}>
                    <div className={classes.stepLabel}>{val.jobEngineerUserID ? 'Assigned' : 'Unassigned'}</div>
                    <div className={classes.stepAssign}>{val.jobEngineer?.toUpperCase()}</div>
                    <div className={classes.stepAssign}>{formatters.DateTimeFormatter(val.jobDateEngineerAssigned)}</div>
                    <div className={classes.stepdate}>{val.jobEngineerAssignedUserName && <b>({val.jobEngineerAssignedUserName})</b>}</div>
                </div>
            )
        },
        {
            label: (
                <div className={classes.stepperlabel}>
                    <div className={classes.stepLabel}>
                        {workInProgConfig[val.jobStatusID]?.label
                            ? workInProgConfig[val.jobStatusID]?.label
                            : !val.jobEngineerUserID
                            ? 'Not Started'
                            : 'Work in Progress'}
                    </div>
                    <div className={classes.stepAssign}>{val[`${workInProgConfig[val.jobStatusID]?.userName}`] || val?.jobWorkInProgressUserName}</div>
                    <div className={classes.stepdate}>
                        {formatters.DateTimeFormatter(val[`${workInProgConfig[val.jobStatusID]?.date}`] || val.jobWorkInProgressDate)}
                    </div>
                </div>
            )
        },
        {
            label: (
                <div className={classes.stepperlabel}>
                    <div className={classes.stepLabel}>Technician Sign off</div>
                    <div className={classes.stepAssign}>{val.jobSignOffUserName}</div>
                    <div className={classes.stepdate}>{formatters.DateTimeFormatter(val.jobSignOffDate)}</div>
                </div>
            )
        },

        {
            label: (
                <div className={classes.stepperlabel}>
                    <div className={classes.stepLabel}> {!val.jobCostReviewedDate ? 'Awaiting Cost Review' : 'Cost Reviewed'}</div>
                    <div className={classes.stepAssign}>{val.jobCostReviewedUserName}</div>
                    <div className={classes.stepdate}>{formatters.DateTimeFormatter(val.jobCostReviewedDate)}</div>
                </div>
            )
        },
        {
            label: (
                <div className={classes.stepperlabel}>
                    <div className={classes.stepLabel}> {'Administration'}</div>
                    <div className={classes.stepAssign}>{(val.jobStatusID === 'AS' || val.jobStatusID === 'IN') && val.vehicleJobStatus}</div>
                    <div className={classes.stepAssign}>
                        {(val.jobStatusID === 'FI' && val.jobProformaCreatedUserName) || val.jobStatusID === 'AS'
                            ? val.jobProformaCreatedUserName
                            : val.jobApprovedUserName}
                    </div>
                    <div className={classes.stepdate}>
                        {formatters.DateTimeFormatter(
                            (val.jobStatusID === 'FI' && val.jobProformaCreatedUserName) || val.jobStatusID === 'AS'
                                ? val.jobProformaDateCreated
                                : val.jobApprovedDate
                        )}
                    </div>
                </div>
            )
        },
        {
            label: (
                <div className={classes.stepperlabel}>
                    <div className={classes.stepLabel}>Completed</div>
                    <div className={classes.stepAssign}>{val.jobCompletionUserName}</div>
                    <div className={classes.stepdate}>{formatters.DateTimeFormatter(val.jobCompletionDate)}</div>
                </div>
            )
        }
    ];

    if (isFactoryInspectionStep) {
        tempList.splice(4, 0, {
            label: (
                <div className={classes.stepperlabel}>
                    <div className={classes.stepLabel}> Factory Inspection</div>
                    <div className={classes.stepAssign}>{val.factoryInspectionUser}</div>
                    <div className={classes.stepdate}>{formatters.DateTimeFormatter(val.factoryInspectionDate)}</div>
                </div>
            )
        });
    }

    list = [...tempList];

    return list;
}

export default function JobCustomizeStepper(props) {
    const { showToastWarning, showToastError, showToastSuccess } = useToast();
    const screenStatus = {
        AA: 1,
        TA: 2,
        WP: props.jobDetails.outstandingLines === 0 ? 3 : 2,
        TS: 4,
        CR: 5,
        AS: 5,
        IN: 6,
        AP: 2,
        ES: 2,
        EA: 2,
        JP: 2
    };
    const screenStatusFi = {
        AA: 1,
        TA: 2,
        WP: props.jobDetails.outstandingLines === 0 ? 3 : 2,
        TS: props.jobDetails.factoryInspectionUser ? 5 : 4,
        FI: props.jobDetails.jobCostReviewedDate ? 6 : 5,
        CR: 6,
        AS: 6,
        IN: 7,
        AP: 2,
        ES: 2,
        EA: 2,
        JP: 2,
        FC: 5
    };

    const history = useHistory();
    const [width, Height] = useWindowSize();
    const { userRoles } = useLoginInfo();
    const isServiceAgent = userRoles.includes('servicearea');
    const isCustomer = userRoles.includes('customer');
    const isinternal = userRoles.includes('internal');
    const isEngineer = userRoles.includes('engineer');
    // const isFactoryInspectionStep = true;
    const isFactoryInspectionStep = props.jobDetails.jobStatusID === 'FI' || props.jobDetails.factoryInspectionUser ? true : false;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState();

    console.log(props.jobDetails.jobProformaCreatedUserName, 'props.jobDetails');

    const redirectToSalesInv = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${props.jobDetails.jobID}/${props.jobDetails?.jobVehicleID}`,
            search: '?tab_testing_tabs=2'
        });
    };

    // jobID;
    // jobVehicleID;

    useEffect(() => {
        if (isFactoryInspectionStep) {
            setActiveStep(screenStatusFi[props.jobDetails.jobStatusID]);
        } else {
            setActiveStep(screenStatus[props.jobDetails.jobStatusID]);
        }
    }, [props.jobDetails.outstandingLines]);

    // const [activeStep, setActiveStep] = React.useState(props.jobDetails.outstandingLines);
    const [modalStep, setModalStep] = React.useState(-1);
    // const steps = getSteps(props.jobDetails || {});
    const steps = getSteps_Fi(props.jobDetails || {}, isFactoryInspectionStep, classes);
    const [state, setState] = useState({
        modalIndex: 0,
        modalLabel: '',
        customersList: [],
        activeStepIndex: 0
    });

    useEffect(() => {
        if (props?.jobDetails?.jobStatusID) {
            if (isFactoryInspectionStep) {
                setActiveStep(screenStatusFi[props.jobDetails.jobStatusID]);
            } else {
                setActiveStep(screenStatus[props.jobDetails.jobStatusID]);
            }
        }
    }, [props.jobDetails.jobStatusID]);

    const handleNext = () => {
        closeSteps();
        props.pullJobDetail();
    };

    useEffect(() => {
        if (isFactoryInspectionStep) {
            setActiveStep(screenStatusFi[props.jobDetails.jobStatusID]);
        } else {
            setActiveStep(screenStatus[props.jobDetails.jobStatusID]);
        }
    }, [props.jobDetails.outstandingLines]);

    function getStepContent_FI(step) {
        switch (step) {
            case 0:
                return null;
            case 1:
                return activeStep === 1 ? (
                    <DialogComp title={'Assign Engineer'} onClose={closeSteps} maxWidth="sm" fullWidth>
                        <AssignJobStep
                            isDisabled={!isCustomer && activeStep === 1}
                            handleNext={handleNext}
                            technicianList={state.technicianList || []}
                            serviceAgentList={state.serviceAgentList}
                            jobDetails={props.jobDetails}
                        />
                    </DialogComp>
                ) : null;
            case 2:
                return activeStep === 2 || activeStep === 3 ? (
                    <DialogComp
                        title={workInProgConfig[props.jobDetails.jobStatusID]?.label || 'Work In Progress'}
                        onClose={closeSteps}
                        maxWidth="md"
                        fullWidth
                    >
                        <JobsWorkInProgress handleNext={handleNext} jobDetails={props.jobDetails} isDisabled={!isCustomer && activeStep < 5} />
                    </DialogComp>
                ) : null;
            case 3:
                return activeStep === 3 ? (
                    <DialogComp title={'Technician Signoff'} onClose={closeSteps} maxWidth="sm" fullWidth>
                        <JobTechSignOff
                            handleNext={handleNext}
                            jobDetails={props.jobDetails}
                            isEngineer={isEngineer}
                            isServiceAgent={isServiceAgent}
                            isDisabled={!isCustomer && activeStep === 3}
                        />
                    </DialogComp>
                ) : null;
            case 4:
                return activeStep === 4 || activeStep === 5 || activeStep === 6 ? (
                    <DialogComp title={'Factory Inspection'} onClose={closeSteps} maxWidth="md" fullWidth>
                        <FactoryInspection
                            handleNext={handleNext}
                            jobDetails={props.jobDetails}
                            isEngineer={isEngineer}
                            isServiceAgent={isServiceAgent}
                            isDisabled={!isCustomer && activeStep === 3}
                            onClose={closeSteps}
                        />
                    </DialogComp>
                ) : null;

            case 5:
                return !isEngineer ? (
                    activeStep === 5 ? (
                        <DialogComp title={'Cost Review'} onClose={closeSteps} maxWidth="sm" fullWidth>
                            <JobServiceArea
                                handleNext={handleNext}
                                onClose={closeSteps}
                                jobDetails={props.jobDetails}
                                isServiceAgent={isServiceAgent}
                                isinternal={isinternal}
                                isDisabled={!isCustomer && activeStep === 5}
                            />
                        </DialogComp>
                    ) : null
                ) : (
                    showToastWarning('You are not allowed to update this job')
                );

            case 6:
                return !isEngineer && !isServiceAgent ? (
                    activeStep === 5 || activeStep === 6 ? (
                        <DialogComp title={'Administration'} onClose={closeSteps} maxWidth="lg" fullWidth>
                            <JobAdminstration
                                handleNext={handleNext}
                                jobStatus={props.jobDetails.jobStatusID}
                                isFactoryInspection={props.jobDetails.jobStatusID === 'FI' && props.jobDetails.jobProformaCreatedUserName}
                                onClose={closeSteps}
                                isProformaCreated={props.jobDetails.jobProformaCreatedUserName}
                                jobDetails={props.jobDetails}
                                customersList={state.customersList}
                                isDisabled={!isCustomer && (activeStep === 5 || activeStep === 6) && isinternal}
                                redirectToSalesInv={redirectToSalesInv}
                            />
                        </DialogComp>
                    ) : null
                ) : (
                    showToastWarning('You are not allowed to update this job')
                );
            default:
                return null;
        }
    }
    function getStepContent(step) {
        switch (step) {
            case 0:
                return null;
            case 1:
                return activeStep === 1 ? (
                    <DialogComp title={'Assign Engineer'} onClose={closeSteps} maxWidth="sm" fullWidth>
                        <AssignJobStep
                            isDisabled={!isCustomer && activeStep === 1}
                            handleNext={handleNext}
                            technicianList={state.technicianList || []}
                            serviceAgentList={state.serviceAgentList}
                            jobDetails={props.jobDetails}
                        />
                    </DialogComp>
                ) : null;
            case 2:
                return activeStep === 2 || activeStep === 3 ? (
                    <DialogComp
                        title={workInProgConfig[props.jobDetails.jobStatusID]?.label || 'Work In Progress'}
                        onClose={closeSteps}
                        maxWidth="md"
                        fullWidth
                    >
                        <JobsWorkInProgress handleNext={handleNext} jobDetails={props.jobDetails} isDisabled={!isCustomer && activeStep < 5} />
                    </DialogComp>
                ) : null;
            case 3:
                return activeStep === 3 ? (
                    <DialogComp title={'Technician Signoff'} onClose={closeSteps} maxWidth="sm" fullWidth>
                        <JobTechSignOff
                            handleNext={handleNext}
                            jobDetails={props.jobDetails}
                            isEngineer={isEngineer}
                            isServiceAgent={isServiceAgent}
                            isDisabled={!isCustomer && activeStep === 3}
                        />
                    </DialogComp>
                ) : null;
            case 4:
                return !isEngineer ? (
                    activeStep === 4 ? (
                        <DialogComp title={'Cost Review'} onClose={closeSteps} maxWidth="sm" fullWidth>
                            <JobServiceArea
                                handleNext={handleNext}
                                onClose={closeSteps}
                                jobDetails={props.jobDetails}
                                isServiceAgent={isServiceAgent}
                                isinternal={isinternal}
                                isDisabled={!isCustomer && activeStep === 4}
                            />
                        </DialogComp>
                    ) : null
                ) : (
                    showToastWarning('You are not allowed to update this job')
                );
            case 5:
                return !isEngineer && !isServiceAgent ? (
                    activeStep === 5 || activeStep === 6 ? (
                        <DialogComp title={'Administration'} onClose={closeSteps} maxWidth="lg" fullWidth>
                            <JobAdminstration
                                handleNext={handleNext}
                                jobStatus={props.jobDetails.jobStatusID}
                                isFactoryInspection={props.jobDetails.jobStatusID === 'FI' && props.jobDetails.jobProformaCreatedUserName}
                                onClose={closeSteps}
                                isProformaCreated={props.jobDetails.jobProformaCreatedUserName}
                                jobDetails={props.jobDetails}
                                customersList={state.customersList}
                                isDisabled={!isCustomer && (activeStep === 5 || activeStep === 6) && isinternal}
                                redirectToSalesInv={redirectToSalesInv}
                            />
                        </DialogComp>
                    ) : null
                ) : (
                    showToastWarning('You are not allowed to update this job')
                );
            default:
                return null;
        }
    }

    const handleSteps = (tabindex, tablable) => {
        try {
            setState((st) => ({ ...st, modalIndex: tabindex, modalLabel: tablable }));
            setModalStep(tabindex);
        } catch (error) {
            console.warn(error);
        }
    };

    const closeSteps = () => {
        try {
            setState((st) => ({ ...st, modalIndex: 0, modalLabel: null }));
            setModalStep(0);
        } catch (error) {
            console.warn(error);
        }
    };

    useEffect(() => {
        let fetchData = async () => {
            if (props.jobDetails.jobID) {
                try {
                    let res = await getAllJobDropDowns(props.jobDetails.jobID);
                    setState((state) => ({
                        ...state,
                        serviceAgentList: res.data?.serviceAgents || [],
                        technicianList: res.data?.technician || [],
                        customersList: res.data.customers || []
                    }));
                } catch (error) {
                    console.warn(error);
                }
            }
        };

        return fetchData();
    }, [props.VehicleID]);

    return (
        <div className={classes.root}>
            <Stepper alternativeLabel activeStep={activeStep} connector={width > 1024 ? <ColorlibConnector /> : <QontoConnector />}>
                {/* <Stepper alternativeLabel activeStep={activeStep}> */}
                {steps.map((label, i) => (
                    <Step
                        key={label}
                        onClick={
                            !props.jobDetails.stepperAllowed
                                ? () => {
                                      showToastWarning('You are not allowed to update this job');
                                  }
                                : props.jobDetails.jobStatusID === 'IN'
                                ? () => {}
                                : () => {
                                      handleSteps(i, label.label);
                                  }
                        }
                    >
                        {width > 500 ? (
                            <StepLabel
                                StepIconComponent={(res) =>
                                    width > 1024 ? (
                                        <ColorlibStepIcon
                                            {...res}
                                            isFactoryInspectionStep={isFactoryInspectionStep}
                                            jobStatusID={props?.jobDetails?.jobStatusID}
                                        />
                                    ) : (
                                        <TabColorlibStepIcon
                                            {...res}
                                            isFactoryInspectionStep={isFactoryInspectionStep}
                                            jobStatusID={props?.jobDetails?.jobStatusID}
                                        />
                                    )
                                }
                            >
                                {label.label}
                            </StepLabel>
                        ) : (
                            <StepLabel StepIconComponent={(res) => <TabColorlibStepIcon {...res} isFactoryInspectionStep={isFactoryInspectionStep} />}>
                                {' '}
                                {activeStep === i ? label.label : null}
                            </StepLabel>
                        )}
                    </Step>
                ))}
            </Stepper>

            {isFactoryInspectionStep ? getStepContent_FI(modalStep) : getStepContent(modalStep)}
        </div>
    );
}
