import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Table, TableBody, TableContainer, TableRow, Paper, TableCell, Typography, FormHelperText, InputLabel } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Link } from 'react-router-dom';
import { InputText, SecondaryButton, SelectBox, CustomChip, MandatoryField, DatePicker, SecondaryCheckbox } from '../../../Core/Controls';
import { postDriverMilege, getDriverList, postDriverCheckIn } from '../../../Core/Services/InternalUser/Vehicle/driverInfoService';
import { useToast } from '../../../Core/Hooks/useToast';
import DriverChecks from './driverCheck';

const gridStyle = {
    title: {
        color: '#f50057'
    },
    header: {
        border: '1px solid rgba(0,0,0,0.2)'
    },
    tbHead: {
        padding: '5px 0px 6px 8px'
    },
    tbContent: {
        margin: '8px 0px 14px 0px;'
    }
};

export default function DriverVehicleModal(props) {
    const [state, setState] = useState({
        customerID: null,
        driverList: [],
        vehicleDriverHistoryID: null,
        historyList: null,
        vehicleDriverHistoryDriverID: null,
        isDriverCheck: false,
        isCheckIn: true
    });
    const [error, setError] = useState({});

    useEffect(async () => {
        if (props.vehicleID) {
            let res = await getDriverList(props.vehicleID);
            if (res.success) {
                setState((st) => ({
                    ...st,
                    historyList: res.data.vehicleDriverHistory[0]?.vehicleDriverHistoryID,
                    driverList: res.data.details.map((m) => ({
                        id: m.driverID,
                        name: m.driver
                    }))
                }));
            }
        }
    }, []);

    const { showToastSuccess, showToastError } = useToast();

    const validate = () => {
        let errors = {};
        let isValid = true;
        if (!state.date) {
            errors.date = 'Date Change is required';
            isValid = false;
        }
        if (!state.mileage) {
            errors.mileage = 'Mileage is required';
            isValid = false;
        }
        setError(errors);
        return isValid;
    };

    const inputChange = (event) => {
        const { name, value, checked } = event.target;
        if (name == 'isDriverCheck') {
            setState((state) => ({
                ...state,
                [name]: checked
            }));
        } else {
            setState((state) => ({
                ...state,
                [name]: value
            }));
        }
    };

    const handelSubmit = async () => {
        if (validate()) {
            let data = {
                vehicleDriverHistoryID: state.historyList ? state.historyList : null,
                vehicleDriverHistoryVehicleID: props.vehicleID,
                vehicleDriverHistoryDriverID: state.vehicleDriverHistoryDriverID,
                vehicleDriverHistoryDateAssigned: state.date,
                vehicleDriverHistoryMileageAssigned: state.mileage
            };
            let submutRes = await postDriverCheckIn(data);
            if (submutRes.success) {
                showToastSuccess('Vehicle Check in Successfully');
                if (state.isDriverCheck) {
                    setState((state) => ({
                        ...state,
                        isCheckIn: false
                    }));
                } else {
                    props.onClose();
                }
            } else {
                showToastError(submutRes.message);
            }
        }
    };
    return (
        <div>
            <Grid container spacing={2} style={{ overflow: 'hidden' }}>
                {state.isCheckIn ? (
                    <>
                        <Grid item xs={6}>
                            <InputLabel required shrink error>
                                Date of Change
                            </InputLabel>
                            <DatePicker inputLabel="Date Of Change" placeholder="Date of change" name="date" value={state.date} onChange={inputChange} />
                            <FormHelperText error>{error.date}</FormHelperText>
                        </Grid>
                        <Grid item xs={6} className="mandatory-fields">
                            <MandatoryField
                                inputLabel="Mileage of Change"
                                placeholder="Odometer Reading"
                                type="number"
                                name="mileage"
                                value={state.mileage}
                                onChange={inputChange}
                                errors={error.mileage}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} className="checkbox-font">
                            <SecondaryCheckbox checked={state.isDriverCheck} name="isDriverCheck" onChange={inputChange} label="Perform Driver Check?" />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography> If this driver is assigned to another vehicle, they will be removed from that vehicle </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ marginBottom: 20 }}>
                            <SecondaryButton onClick={handelSubmit}>Check In</SecondaryButton>
                        </Grid>
                    </>
                ) : (
                    <DriverChecks filterVehicleID={props.vehicleID} regNumber={props.regNumber} onClose={props.onClose} fromPage="checkIn" />
                )}
            </Grid>
        </div>
    );
}
