import { Breadcrumbs, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';

const BreadCrumbs = (props) => {
    return (
        <Breadcrumbs separator=">>" aria-label="breadcrumb">
            {props.crumbs
                ? props.crumbs.map((crumb, i) => {
                      return (
                          <div key={i}>
                              {crumb.active ? (
                                  crumb.onClick ? (
                                      <Typography
                                          color="secondary"
                                          style={{ fontSize: `${props.fontWeight ? props.fontWeight : '1rem'}`, cursor: 'pointer' }}
                                          onClick={crumb.onClick}
                                      >
                                          {crumb.name}
                                      </Typography>
                                  ) : (
                                      <Typography color="secondary" style={{ fontSize: `${props.fontWeight ? props.fontWeight : '1rem'}`, cursor: 'default' }}>
                                          {crumb.name}
                                      </Typography>
                                  )
                              ) : (
                                  <Link to={crumb.path} style={{ textDecoration: 'none', fontSize: `${props.fontWeight ? props.fontWeight : '1rem'}` }}>
                                      {crumb.name}
                                  </Link>
                              )}
                          </div>
                      );
                  })
                : null}
        </Breadcrumbs>
    );
};

export default BreadCrumbs;
