import React, { useEffect, useMemo, useState } from 'react';
import AddEditDefectModal from './addDefect';
import DataGrid from '../../../Core/Controls/Grid';
import { getAllDefects } from '../../../Core/Services/InternalUser/Maintenance/DefectsService';
import { getVechicleIDByRegNO } from '../../../Core/Services/InternalUser/ServiceAgent/breakdownService';
import { EditButton, BreadCrumbs, formatters } from '../../../Core/Controls';
import DefectEditDetails from '../../InternalUser/Maintenance/Defects/DefectEditDetails';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import BuildIcon from '@material-ui/icons/Build';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';

const modalgridStyle = {
    formatterEditBtn: { textAlign: 'center' }
};

const baseCrumbs = [
    { name: 'Home', path: '/' },
    { name: 'Maintenance', active: true },
    { name: 'Defects', active: true }
];

const baseColumns = [
    { key: 'action', name: 'Action', width: 100, addFileUploaderIcon: true },
    // { key: 'customer', name: 'Customer Name', width: '10%' },
    { key: 'defectRegNumber', name: 'Reg No', width: 100 },
    {
        key: 'defectNumberGenerated',
        name: 'Defect No',
        headerRenderer: formatters.ColumnHeaderAlignLeft,
        formatter: formatters.ColumnTextAlignLeft,
        width: 80
    },
    {
        key: 'defectDate',
        name: 'Date ',
        formatter: formatters.Date_DDMMYYYY,
        width: 90
    },

    { key: 'defectDescription', name: 'Details' },
    { key: 'defectContactName', name: 'Reported By', width: '15%' },
    // { key: 'defectDriverName', name: 'Driver Name', width: 110 },
    // { key: 'defectDriverTelephone', name: 'Driver Phone', width: 120 },
    { key: 'serviceAgentName', name: 'Service Area', width: 150 },
    { key: 'defectJobStatusDescription', name: 'Status', width: 180 }
    // { key: 'defectType', name: 'Defect Type', width: 130 }
];

const DriverDefectsScreen = (props) => {
    const [state, setState] = useState({
        modalToggle: false,
        rowVehicleID: null,
        defectID: null,
        List: [],
        showLoader: true,
        fromPage: 'Defects',
        show: false,
        filterVal: ''
    });

    useEffect(async () => {
        if (!props.VehicleID) {
            if (state.filterVal.length > 4) {
                let resBrekDown = await getVechicleIDByRegNO(state.filterVal);
                setState((st) => {
                    let newSt = { ...st };
                    if (resBrekDown.data?.list) {
                        newSt.show = resBrekDown.success;
                        newSt.filterVehicleID = resBrekDown.data?.list[0].vehicleID;
                        newSt.isReload = new Date();
                    } else {
                        newSt.show = false;
                    }

                    return newSt;
                });
            } else {
                setState((st) => ({ ...st, show: false }));
            }
        }
        // pullDefects();
    }, [state.filterVal]);

    const pullDefects = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const handleEditDefect = (val) => {
        props.history.push({
            pathname: `/maintenance/defect/edit/${val?.defectID}/${val?.defectVehicleID}`,
            state: 'Edit'
        });
    };

    const renderEdit = (data, row) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CustomIconButton
                    icon={BuildIcon}
                    color="secondary"
                    className="modal-btn-update btn_space"
                    toolTipTitle="Edit Breakdown"
                    onClick={() => handleEditDefect(row)}
                    // onClick={() => handleUpdate(row, null, 'modalEditDetail')}
                />
                {/* <EditButton toolTipTitle="Edit Defect" onClick={() => handleUpdate(row, null, 'modalEditDetail')} /> */}
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);

    const closeByGridByBredcrumb = () => {
        setState((state) => ({ ...state, modalToggle: false, modalEditDetail: false }));
    };

    const crumbs = useMemo(() => {
        const tempCrumbs = [...baseCrumbs];
        let actionCol = baseCrumbs.find((element) => element.name === 'Defects');
        if (actionCol) {
            actionCol.onClick = closeByGridByBredcrumb;
        }
        if (state.modalEditDetail) {
            tempCrumbs.splice(3, 0, {
                name: `Defect Number ${state.defectNumberGenerated} [${formatters.DateTimeFormatter(state.defectDate)}]`,
                active: true
            });
        }
        return tempCrumbs;
    }, [state.modalToggle]);

    const handleUpdate = (data, vehicleId, modalName) => {
        setState((st) => {
            let newSt = { ...st };
            if (data) {
                newSt.defectID = data.defectID;
                newSt.rowVehicleID = data.defectVehicleID;
                newSt.defectNumberGenerated = data.defectNumberGenerated;
                newSt.defectDate = data.defectDate;
            }
            if (vehicleId) {
                newSt.rowVehicleID = vehicleId;
            }
            newSt[modalName] = true;

            return newSt;
        });
    };

    const closeModalHandler = (res, modalName) => {
        setState((state) => ({ ...state, [modalName]: false, filterVal: '' }));
        res && pullDefects();
    };

    const inputChange = (e) => {
        const value = e.target.value;
        setState((st) => ({
            ...st,
            filterVal: value
        }));
    };

    const gridBtnTitle = state.show ? 'Add Defect' : null;
    return (
        <div className={`screen`} style={{ marginTop: 8 }}>
            <>
                {!state.modalEditDetail ? (
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center', position: 'relative', top: 0 }}>
                        <div style={{ width: 'auto' }}>
                            <BreadCrumbs crumbs={crumbs} className="edit-service-agent-btn" />
                        </div>
                        &nbsp;&nbsp;
                        {/* {!state.modalToggle ? (
                            <div style={{ width: 'auto' }}>
                                <input type="text" onChange={inputChange} placeholder="Enter Reg Number" value={state.filterVal} style={{ padding: 5 }} />
                            </div>
                        ) : null} */}
                    </div>
                ) : null}
            </>

            {state.modalToggle ? (
                <>
                    <AddEditDefectModal
                        fromGrid={true}
                        filterVehicleID={state.filterVehicleID}
                        regNumber={state.regNumber}
                        onClose={() => closeModalHandler(true, 'modalToggle')}
                    />
                </>
            ) : state.modalEditDetail ? (
                <DefectEditDetails
                    defectID={state.defectID}
                    VehicleID={state.rowVehicleID}
                    onClose={() => closeModalHandler(true, 'modalEditDetail')}
                    fromPage={state.fromPage}
                    pullDetails={props.pullDetails}
                />
            ) : (
                <>
                    <GijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={columns}
                        getUrl="VehicleDefects"
                        btnTitle={gridBtnTitle}
                        idFieldName="defectID"
                        fileUploaderConfigName="DefectScreen"
                        onBtnClick={() => handleUpdate(null, state.filterVehicleID, 'modalToggle')}
                        isReload={state.isReload}
                    />
                </>
            )}
        </div>
    );
};

export default DriverDefectsScreen;
