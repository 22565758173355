import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getAllVehicleJob = async (id) => {
    let res = await getWithAuth(`VehicleJob?VehicleID=${id}`);
    return res;
};

export const getAllCustomerJob = async (id) => {
    let res = await getWithAuth(`VehicleJob?customerID=${id}`);
    return res;
};
export const getAllJob = async (id) => {
    let res = await getWithAuth(`VehicleJob`);
    return res;
};
export const postJobData = async (data) => {
    let res = await postWithAuth(`VehicleJob/AddJob`, data);
    return res;
};

export const getVehicleJobDeleteDropDown = async (data) => {
    let res = await getWithAuth(`VehicleJob/Reason/screenDropdowns`, data);
    return res;
};

export const getVehicleDefectDeleteDropDown = async (data) => {
    let res = await getWithAuth(`VehicleDefects/Reason/screenDropdowns`, data);
    return res;
};

export const DeleteJob = async (data) => {
    let res = await postWithAuth(`VehicleJob/VehicleJobDelete`, data);
    return res;
};

export const DeleteDefect = async (data) => {
    let res = await postWithAuth(`VehicleDefects/VehicleDefectDelete`, data);
    return res;
};
