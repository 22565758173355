import { Grid, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { YesButton, NoButton } from '../../../../../Core/Controls';
import './style.scss';

const SubmitWarnngModal = (props) => {
    const [state, setState] = useState({
        jobQueryMessage: '',
        jobQueryClosed: false,
        showLoader: true
    });

    return (
        <div className="job-query-modal" style={{ width: '500px' }}>
            <Grid container spacing={2}>
                <Grid xs={12} item style={{ marginBottom: 8 }}>
                    <Grid xs={12} container spacing={1}>
                        <Grid item xs={6}>
                            <YesButton toolTipTitle="Yes" btnName="Yes" state={state.jobQueryClosed} onClick={() => props.onYes()} />
                        </Grid>
                        <Grid item xs={6}>
                            <NoButton toolTipTitle="No" btnName="No" state={state.jobQueryClosed} onClick={() => props.onClose(false)} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
export default SubmitWarnngModal;
