import React from 'react';
import { BackButton, BreadCrumbs, TabView } from '../../../../Core/Controls';
import CustomerDashBoard from './dashboard';
import DepoDashboard from '../Depots/index';
import NoteDashBoard from './../Notes/index';
import CustVehicleScreen from './CustVehicle';
import DefectScreen from '../../Maintenance/Defects/defectScreen';
import BreakDownDashboard from './CustBreakdown';
import FleetInsurance from './FleetInsurance';
import EndUser from './EndUser';
import CostCenter from '../../Vehicles/CostCenterScreen/costCenter';

import JobScreen from '../JOBS/JobScreen';
import './customer.scss';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import FaultTypes from './FaultTypes';
import VorReportingModal from '../../VorReporting/vorReporting';
import BreakdownDefectReporting from '../../Maintenance/BreakDownDefectReporting';
import JobReportScreen from '../JOBS/jobReporting';

export default function EditCustomer(props) {
    const id = props.match.params.id;
    const customer = props.match.params.customer;
    let checkhaveAccess = AppStorage.getAllowAcces();

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Customers', path: '/controller/Customer' },
        { name: `${customer}`, active: true }
    ];

    const tabs = [
        {
            label: 'Dashboard',
            body: <CustomerDashBoard customerID={id} />
        },
        ...(checkhaveAccess?.CanViewNotes
            ? [
                  {
                      label: 'Notes',
                      body: <NoteDashBoard frompage="Customers" customerID={id} noteRefsID={id} />
                  }
              ]
            : []),
        {
            label: 'Defects',
            body: <DefectScreen isCust={true} customerID={id} frompage="Vehicles" fromTab={true} />
        },
        {
            label: 'Breakdowns',
            body: <BreakDownDashboard frompage="Vehicles" customerID={id} fromTab={true} />
        },
        // {
        //     label: 'Depots',
        //     body: <DepoDashboard isCust={true} customerID={id} fromTab={true} />
        // },
        { label: 'Vehicles', body: <CustVehicleScreen customerID={id} /> },
        {
            label: 'Jobs',
            body: <JobScreen customerID={id} frompage="JOBS" fromTab={true} />
        },
        {
            label: 'Fleet Insurance',
            body: <FleetInsurance customerID={id} fromTab={true} />
        },
        // {
        //     label: 'Cost Centers',
        //     body: <CostCenter customerID={id} fromTab={true} />
        // },
        {
            label: 'End User',
            body: <EndUser customerID={id} fromTab={true} />
        },
        {
            label: 'Fault Types',
            body: <FaultTypes customerID={id} fromTab={true} />
        },
        {
            label: 'VOR Reporting',
            body: <VorReportingModal customerID={id} fromTab={true} />
        },
        {
            label: 'Defect Reporting',
            body: <BreakdownDefectReporting customerID={id} fromTab={true} />
        },
        {
            label: 'Breakdown Reporting',
            body: <BreakdownDefectReporting customerID={id} fromTab={true} />
        },
        {
            label: 'Job Reporting',
            body: <JobReportScreen customerID={id} fromTab={true} />
        }
    ];
    const GoBack = () => {
        props.history.push('/controller/Customer');
    };
    return (
        <div className="screen">
            <div style={{ width: 'auto', display: 'flex', alignItems: 'center' }}>
                <BackButton onClick={GoBack} />
                <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" />
            </div>
            <TabView tabList={tabs} id="testing_tabs" />
        </div>
    );
}
