import React, { useEffect, useState, useMemo } from 'react';
import { EditButton, BreadCrumbs, DataGrid, formatters, SecondaryButton } from '../../../Core/Controls';
import { getAllEnquiry } from '../../../Core/Services/InternalUser/ControllerService/EnquiriService';
import { useHistory } from 'react-router-dom';

let obj = {
    1: 'New Enquiries',
    2: 'Live Enquiries',
    3: 'Successful Enquiries',
    4: 'Lost Enquiries'
};
const setEnquiry = { 1: 'new_enquiry', 2: 'live_enquiry', 3: 'successful_enquiry', 4: 'lost_enquiry' };

const basecolumns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'leadCompanyName', name: 'Company Name' },
    { key: 'leadMainContactName', name: 'Contact Name' },
    { key: 'leadMainContactPhone', name: 'Contact Phone' },
    { key: 'leadMainContactEmail', name: 'Contact Email' },
    { key: 'leadCity', name: 'City' },
    { key: 'leadReferrerName', name: 'Referrer Name' }
];

const LeadEnquiriesScreen = (props) => {
    let history = useHistory();
    const crumbs = [
        { name: 'Home', path: '/' },
        { name: obj[props.id], active: true }
    ];

    const [state, setState] = useState({
        modalToggle: false,
        EnquiriesID: null,
        enquiriesList: [],
        showLoader: true
    });

    useEffect(async () => {
        pullEnquiriesData();
    }, []);

    const pullEnquiriesData = async () => {
        let res = {};
        res = await getAllEnquiry();
        setState((st) => ({
            ...st,
            enquiriesList: res.data?.list?.filter((m) => {
                if (m.leadStatusID == props.id) {
                    return m;
                }
            }),
            showLoader: false
        }));
    };

    const actionButton = (data) => {
        return (
            <div className="Enquiries-btn-update">
                <EditButton onClick={() => handleEnquiriesUpdate(data?.row?.leadID)} toolTipTitle="Edit Enquiries" />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = actionButton;
        }
        return tempCols;
    }, []);

    const handleEnquiriesUpdate = (params) => {
        history.push(`/Enquiries/edit/${params}?${setEnquiry[props.id]}`);
    };

    const handleEnquiriesAdd = () => {
        history.push(`/Enquiries/add/0`);
    };

    return (
        <div className="screen">
            <>
                {!props.VehicleID && <BreadCrumbs crumbs={crumbs} />}
                <DataGrid
                    columns={columns}
                    rows={state.enquiriesList}
                    isRowHovered={true}
                    enableSearch={true}
                    headerRowHeight={45}
                    loadingData={state.showLoader}
                    paginate
                    btnTitle={props.id == 1 ? 'Add Enquiry' : null}
                    onBtnClick={handleEnquiriesAdd}
                    offset={220}
                />
            </>
            {/* )} */}
        </div>
    );
};

export default LeadEnquiriesScreen;
