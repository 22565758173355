import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormHelperText, Typography } from '@material-ui/core';
import { SecondaryButton, formatters, SingleSelect, DatePicker, BreadCrumbs, SimpleField } from '../../../Core/Controls';
import { useToast } from '../../../Core/Hooks/useToast';
import '../../commonStyle.scss';
import { GetVehicleSalesInvoice, UpdateVehicleSalesInvoice, VehicleSalesInvoiceGenratePdf } from '../../../Core/Services/InternalUser/JOB/jobItemsService';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';
import moment from 'moment';
import { some } from 'lodash';
import { Table, TableBody, TableContainer, Paper } from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from '../../../Core/Controls/Table/StyledCell&Row';
import { useHistory } from 'react-router-dom';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';

let crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Invoices', active: true },
    { name: 'Update Sales Invoice', active: true }
];

function UpdateSalesInvoice(props) {
    const [state, setState] = useState({
        vatRate: [],
        vehicleDetails: [],
        dateDue: moment().format('YYYY-DD-MM'),
        datePaid: null,
        status: '',
        errors: {},
        statusValue: null,
        invoiceLines: [],
        partsDetails: []
    });
    const { userRoles } = useLoginInfo();
    const history = useHistory();
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();
    const isCustomer = userRoles.includes('customer');

    useEffect(async () => {
        let res1 = await GetVehicleSalesInvoice(props.match?.params?.id);
        if (res1.success) {
            setState((st) => ({
                ...st,
                vatRate: res1.data.vatRate,
                vehicleDetails: res1.data?.vehicleDetails || [],
                invoiceLines: res1.data.invoiceLines,
                partsDetails: res1.data.partsDetails,
                dateDue: formatters.DateFormatter(res1.data?.vehicleDetails[0]?.invoiceDue),
                datePaid: formatters.DateFormatter(res1.data?.vehicleDetails[0]?.invoiceDatePaid),
                statusList: res1.data.statusList,
                status: res1.data?.vehicleDetails[0]?.vehicleSalesInvoiceStatusID
            }));
        }
    }, []);
    useEffect(() => {
        let getStatus;
        if (state.status) {
            getStatus = state.statusList.find(({ id }) => id === state.status);
        }
        setState((st) => ({
            ...st,
            statusValue: getStatus
        }));
    }, [state.status]);

    const inputChange = (e) => {
        const { name, value, type } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handelSubmit = async () => {
        setBtnLoader(true);
        let formData = {
            dateDue: state.dateDue,
            datePaid: state.datePaid,
            status: state.statusValue?.id,
            invoiceID: props.match?.params?.id
        };
        let res = await UpdateVehicleSalesInvoice(formData);
        if (res.success) {
            showToastSuccess('Invoice Updated Successfully');
            history.goBack();
            setBtnLoader(false);
        } else {
            showToastError(res.message);
            setBtnLoader(false);
        }
    };

    const GeneratePdfInvoice = async () => {
        let res = await VehicleSalesInvoiceGenratePdf(props.match?.params?.id);
        if (res.success) {
            showToastSuccess('Invoice PDF Generated Successfully');
            history.goBack();
        } else {
            showToastError(res.message);
        }
    };
    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => ({ ...st, [nm]: val }));
    };
    const valFormater = (val) => {
        return <span> £{formatters.ToFixedDigit(val)}</span>;
    };
    return (
        <div className="screen">
            <Grid container spacing={2} className="mt_10">
                <Grid item xs={12}>
                    <BreadCrumbs crumbs={crumbs} />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Grid item container xs={12} style={{ backgroundColor: '#f5f9f9', padding: '0 5px' }}>
                        <Grid item xs={6} sm={3}>
                            <FormHelperText shrink>Reg Number</FormHelperText>
                            <Typography variant="subtitle2">{state.vehicleDetails[0]?.regNumber || 'N/A'}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} container justify="flex-start">
                            <Grid item>
                                <FormHelperText shrink>Invoice Number</FormHelperText>
                                <Typography variant="subtitle2">{state.vehicleDetails[0]?.invoiceNumber || 'N/A'}</Typography>
                            </Grid>
                            {/* <Grid item>
                                <InputLabel shrink>{'\u{2800}'}</InputLabel>
                                <CustomIconButton icon={PictureAsPdfIcon} toolTipTitle="Generate Pdf" forceEnabled={true} onClick={GeneratePdfInvoice} />
                            </Grid> */}
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormHelperText shrink>Customer</FormHelperText>
                            <Typography variant="subtitle2">{state.vehicleDetails[0]?.customer || 'N/A'}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormHelperText shrink>Invoice Date</FormHelperText>
                            <Typography variant="subtitle2">{formatters.DateOnlyFormatter(state.vehicleDetails[0]?.invoiceDate)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} lg={3} className="mandatory-fields">
                    <InputLabel shrink required>
                        Invoice Date Due
                    </InputLabel>
                    <DatePicker isDisabled={true} placeholder="Invoice Date Due" value={state.dateDue} onChange={inputChange} name="dateDue" autoFocus={true} />
                    <FormHelperText error>{state.errors.dateDue}</FormHelperText>
                </Grid>
                {/* <Grid item xs={12} sm={4} lg={3} className="mandatory-fields">
                    <InputLabel shrink required>
                        Invoice Date Paid
                    </InputLabel>
                    <DatePicker
                        InputProps={{ inputProps: { min: `${formatters.DateFormatter(state.vehicleDetails[0]?.invoiceDate)}` } }}
                        isDisabled={true}
                        placeholder="Date Paid"
                        value={state.datePaid}
                        onChange={inputChange}
                        name="datePaid"
                    />
                    <FormHelperText error>{state.errors.datePaid}</FormHelperText>
                </Grid> */}
                <Grid item xs={12} sm={4} lg={3} style={{ marginTop: -8 }}>
                    <FormHelperText shrink>Status</FormHelperText>
                    {/* {state.status === 'I' ? (
                        <SimpleField isDisabled={true} value={'Proforma sent to invoice'} />
                    ) : ( */}
                    <SingleSelect isDisabled={true} value={state.statusValue} onChange={singleSelectChange('statusValue')} options={state.statusList || []} />
                    {/* )} */}
                </Grid>
                {/* {!isCustomer && state.status !== 'I' && (
                    <Grid item xs={12} sm={4} md={3} lg={1}>
                        <InputLabel shrink>{'\u{2800}'}</InputLabel>
                        <SecondaryButton isBtnLoader={btnLoader} onClick={handelSubmit}>
                            Submit
                        </SecondaryButton>
                    </Grid>
                )} */}
                <Grid item xs={12} sm={4} md={3} lg={1}>
                    <InputLabel shrink>{'\u{2800}'}</InputLabel>
                    <SecondaryButton isBtnLoader={btnLoader} onClick={() => history.goBack()}>
                        Cancel
                    </SecondaryButton>
                </Grid>
                <Grid item xs={12} style={{ height: `calc(100vh - 214px)` }} className="custom-scroll">
                    <TableContainer
                        component={Paper}
                        style={{
                            overflow: 'hidden'
                        }}
                    >
                        <Table style={{ height: '100%' }}>
                            <TableBody>
                                {state.invoiceLines.map((m, i) => {
                                    let temp = 0;
                                    return (
                                        <React.Fragment>
                                            {/* <StyledTableRow>
                                                <StyledTableCell colSpan={6}>
                                                    <Typography variant="h6" color="secondary">
                                                        Labour
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell>&nbsp;</StyledTableCell>
                                            </StyledTableRow> */}
                                            <StyledTableRow>
                                                <StyledTableCell align="left" width="30%">
                                                    <FormHelperText shrink>Labour Description</FormHelperText>
                                                    <Typography variant="subtitle2">{m.labourDescription || 'N/A'}</Typography>
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="left" width="10%">
                                                    <FormHelperText shrink>Cost Type</FormHelperText>
                                                    <Typography variant="subtitle2">{m.costType || 'N/A'}</Typography>
                                                </StyledTableCell> */}
                                                <StyledTableCell align="left" width="5%">
                                                    <FormHelperText shrink>Hours</FormHelperText>
                                                    <Typography variant="subtitle2">{m.labourHours || 'N/A'}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell width="10%">
                                                    <FormHelperText shrink>Cost</FormHelperText>
                                                    <Typography variant="subtitle2">{valFormater(m.labourCost)}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell width="5%">
                                                    <FormHelperText shrink>Discount %</FormHelperText>
                                                    <Typography variant="subtitle2">{m.labourDiscount || '0'}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell width="15%">
                                                    <FormHelperText shrink>Ex VAT</FormHelperText>
                                                    <Typography variant="subtitle2">{valFormater(m.exVat)}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell width="15%">
                                                    <FormHelperText shrink>VAT Amount</FormHelperText>
                                                    <Typography variant="subtitle2">
                                                        {valFormater(m.labourVAT)}({m.labourVATPercent}%)
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell width="20%">
                                                    <FormHelperText shrink>Total</FormHelperText>
                                                    <Typography variant="subtitle2">{valFormater(m.exVat + m.labourVAT)}</Typography>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            {some(state.partsDetails, (r) => r.labourID == m.labourID) && (
                                                <React.Fragment>
                                                    {/* <StyledTableRow>
                                                        <StyledTableCell width="5%">&nbsp;</StyledTableCell>
                                                        <StyledTableCell width="95%" colSpan={6}>
                                                            <Typography variant="h6" color="secondary">
                                                                Parts
                                                            </Typography>
                                                        </StyledTableCell>
                                                    </StyledTableRow> */}
                                                    {state.partsDetails.map((p, k) => {
                                                        if (p.labourID == m.labourID) {
                                                            temp++;
                                                            return (
                                                                <StyledTableRow>
                                                                    <StyledTableCell width="20%" style={{ paddingLeft: 40 }}>
                                                                        {temp == 1 && <FormHelperText shrink>Part Number </FormHelperText>}
                                                                        <Typography variant="subtitle2">
                                                                            {p.partNumber || 'N/A'}
                                                                            <br /> Description:{p.partDescription || 'N/A'}
                                                                        </Typography>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell width="10%">
                                                                        {temp == 1 && <FormHelperText shrink>Quantity </FormHelperText>}
                                                                        <Typography variant="subtitle2">{p.qty || 'N/A'}</Typography>{' '}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell width="10%">
                                                                        {temp == 1 && <FormHelperText shrink>Unit Price </FormHelperText>}
                                                                        <Typography variant="subtitle2">{valFormater(p.unitPrice)}</Typography>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell width="10%">
                                                                        {temp == 1 && <FormHelperText shrink>Discount % </FormHelperText>}
                                                                        <Typography variant="subtitle2">{p.discount || '0'}</Typography>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell width="15%">
                                                                        {temp == 1 && <FormHelperText shrink>Ex VAT</FormHelperText>}
                                                                        <Typography variant="subtitle2">{valFormater(p.exVat)}</Typography>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell width="15%">
                                                                        {temp == 1 && <FormHelperText shrink>VAT Amount</FormHelperText>}
                                                                        <Typography variant="subtitle2">
                                                                            {valFormater(p.partsVAT)}({p.partsVATPercent}%)
                                                                        </Typography>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell width="20%">
                                                                        {temp == 1 && <FormHelperText shrink>Total</FormHelperText>}
                                                                        <Typography variant="subtitle2">{valFormater(p.exVat + p.partsVAT)}</Typography>
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            );
                                                        }
                                                    })}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
}

export default UpdateSalesInvoice;
