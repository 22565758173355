import { Grid, Paper, Button, OutlinedInput, InputAdornment, IconButton } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { SimpleField, BreadCrumbs } from '../../../Core/Controls';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';
import SendIcon from '@material-ui/icons/Send';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from '@material-ui/core/Badge';
import { getCustomerMessages, getCustomerMessagesList, postMessage } from '../../../Core/Services/InternalUser/Message/message';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '40%',
        marginLeft: 'auto',
        margin: 10,
        backgroundColor: '#fef0d2',
        padding: 20
    },
    outgoning: {
        width: '40%',
        margin: 10,
        backgroundColor: '#a7ebf3',
        padding: 20
    },
    empty: {
        textAlign: 'center',
        margin: 10,
        padding: 20
    },
    customScroll: {
        // height: '200px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.1em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#1976d2',
            opacity: 5,
            outline: '1px solid slategrey'
        }
    },
    activeBorder: {
        borderLeft: '7px solid #1976d2 '
    }
}));

const borderStyle = {
    br: {
        borderRight: '5px solid #ffae00'
    },
    bl: {
        borderLeft: '5px solid #1976d2'
    }
};

const customerList = {
    width: '100%',
    height: 'calc(100vh - 280px)'
};

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Message', active: true }
];

const InternalUserChatting = () => {
    const { displayName } = useLoginInfo();
    const isCustomer = useLoginInfo().userRoles.includes('customer');
    const [state, setState] = useState({
        message: null,
        custList: [],
        customerID: '',
        customerName: null,
        messageList: [],
        sendMessage: false,
        showLoader: true,
        filterCustList: []
    });

    useEffect(async () => {
        let res = await getCustomerMessagesList();
        if (res.success) {
            setState((st) => {
                let newSt = { ...st };
                if (!isCustomer) {
                    newSt.custList = res.data.list;
                    newSt.filterCustList = res.data.list;
                    newSt.customerID = res.data.list[0]?.customerID;
                }
                return newSt;
            });
        }
    }, []);

    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() => elementRef.current.scrollIntoView());
        return <div ref={elementRef} />;
    };

    const getMessages = async () => {
        let res = await getCustomerMessages(state.customerID);
        if (res.success) {
            setState((st) => ({
                ...st,
                messageList: res.data.list.sort((a, b) => (a.sdate > b.sdate ? 1 : b.sdate > a.sdate ? -1 : 0)),
                showLoader: false
            }));
        }
    };
    useEffect(async () => {
        getMessages();
    }, [state.customerID]);

    const onFilterChange = (e) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.filterCustList = st.custList.filter((m) => m.customer.toLowerCase().includes(e.target.value));
            return newSt;
        });
    };

    const getMessage = (id) => {
        setState((st) => ({
            ...st,
            customerID: id,
            showLoader: true
        }));
    };

    function handleEnter(e) {
        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
            submitMessage();
        }
    }
    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const validation = () => {
        let isValid = true;
        if (!state.message) {
            isValid = false;
        }
        if (!/[^\s]/.test(state.message)) {
            isValid = false;
        }
        return isValid;
    };

    const submitMessage = async () => {
        if (validation()) {
            let data = {
                customerID: state.customerID,
                message: state.message
            };

            let res = await postMessage(data);
            if (res.success) {
                getMessages();
                setState((st) => ({
                    ...st,
                    message: ''
                }));
            }
        }
    };

    const classes = useStyles();

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <Grid
                container
                style={{
                    height: 'calc(100vh - 150px)',
                    overflow: 'auto',
                    marginTop: '20px',
                    boxShadow: '2px 2px 7px 1px #9E9E9E'
                }}
            >
                {' '}
                {!isCustomer && (
                    <Grid
                        item
                        xs={5}
                        md={4}
                        lg={3}
                        xl={2}
                        style={{
                            boxShadow: '0px 0px 10px 0px #9E9E9E',
                            padding: '0px 10px 10px 10px'
                        }}
                    >
                        <Typography variant="h5" color="secondary" style={{ textAlign: 'center', padding: 10 }}>
                            Customers
                        </Typography>

                        <SimpleField placeholder="Search" name="flterList" onChange={onFilterChange} />
                        <List dense className={classes.customScroll} style={customerList}>
                            {state.filterCustList.map((item, index) => {
                                const labelId = `checkbox-list-secondary-label-${item.customerID}`;
                                return (
                                    <Button
                                        className={item.customerID === state.customerID ? classes.activeBorder : ''}
                                        style={{ margin: '2px', width: '98%' }}
                                        onClick={() => getMessage(item.customerID)}
                                    >
                                        <ListItem key={index}>
                                            <ListItemText style={{ marginLeft: '5px' }} id={labelId} primary={item.customer} />
                                            <Badge badgeContent={item.unreadMessageCount} color="primary" style={{ padding: 1 }}></Badge>
                                        </ListItem>
                                    </Button>
                                );
                            })}
                        </List>
                    </Grid>
                )}
                <Grid
                    item
                    xs={!isCustomer ? 7 : 12}
                    md={!isCustomer ? 8 : 12}
                    lg={!isCustomer ? 9 : 12}
                    xl={!isCustomer ? 10 : 12}
                    style={{ padding: '0px 10px 10px 10px' }}
                >
                    <Typography variant="h6">&nbsp;</Typography>
                    {state.showLoader ? (
                        <div
                            style={{
                                height: 'calc(100vh - 250px)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                        </div>
                    ) : (
                        <div className={classes.customScroll} style={{ height: 'calc(100vh - 250px)' }}>
                            {state.messageList.length !== 0 ? (
                                <>
                                    {state.messageList.map((m, i) => {
                                        let classText = m.name === displayName ? classes.outgoning : classes.paper;

                                        return (
                                            <div>
                                                <Paper
                                                    className={classText}
                                                    style={m.rdate ? (m.name === displayName ? borderStyle.bl : borderStyle.br) : null}
                                                >
                                                    <Grid container spacing={2}>
                                                        <div style={{ width: '100%' }}>
                                                            <Typography>{m.notes}</Typography>
                                                        </div>
                                                        <div style={{ width: '50%' }}>
                                                            <small>
                                                                {m.name} @ {moment(m.sdate).format('MMM Do YYYY, h:mm a')}
                                                            </small>
                                                        </div>

                                                        {m.rdate && (
                                                            <div style={{ width: '50%', textAlign: 'right' }}>
                                                                <small> seen {moment(m.rdate).format('MMM Do YYYY, h:mm a')}</small>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </Paper>
                                            </div>
                                        );
                                    })}
                                    <AlwaysScrollToBottom />
                                </>
                            ) : (
                                <div>
                                    <div className={classes.empty}>
                                        <Grid container spacing={2}>
                                            <div style={{ width: '100%' }}>
                                                <Typography>There are no messages sent/received</Typography>
                                            </div>
                                            <div style={{ width: '100%' }}>
                                                <small>{moment().format('MMM Do YYYY, h:mm a')}</small>
                                            </div>
                                        </Grid>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    <Grid container spacing={1} justify="space-between">
                        <Grid item xs={12}>
                            <OutlinedInput
                                fullWidth
                                placeholder="Type a new message"
                                name="message"
                                value={state.message}
                                onChange={handleInput}
                                onKeyDown={handleEnter}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton edge="end">
                                            <SendIcon onClick={submitMessage} style={{ cursor: 'pointer' }} color="secondary" />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default InternalUserChatting;
