import React, { useState } from 'react';
import { useToast } from '../../../../../Core/Hooks/useToast';
import Grid from '@material-ui/core/Grid';
import { SecondaryButton } from '../../../../../Core/Controls';
import { MandatoryPositiveInput } from '../../../../../Core/Controls/Inputs/PositiveInput';
import { FormHelperText, InputLabel, Table, TableBody, Hidden, TableContainer, Typography, TableRow, Paper, TableCell } from '@material-ui/core';
import { DatePicker } from '../../../../../Core/Controls';
import { postBatteryLevels } from '../../../../../Core/Services/InternalUser/ControllerService/mileageLogService';
import moment from 'moment';

const gridStyle = {
    title: {
        color: '#f50057'
    },
    header: {
        borderBottom: '1px solid rgba(0,0,0,0.2)'
    },
    tbHead: {
        padding: '5px 0px 6px 8px'
    }
};
const AddBatteryLevels = (props) => {
    const { vehicleLevelOfCharge, vehicleStateOfCharge, vehicleBatteryLevelDate } = props.otherFields;
    const [state, setState] = useState({
        batteryLevelID: null,
        batteryLevelVehicleID: props.vehicleID,
        batteryLevelDate: '',
        batteryLevelOfCharge: '',
        batteryLevelStateOfCharge: '',
        date: moment().format('YYYY-MM-DD')
    });
    const [error, setError] = useState({});
    const { showToastSuccess, showToastError } = useToast();

    const validate = () => {
        let errors = {};
        let isValid = true;
        const { batteryLevelDate, batteryLevelOfCharge } = state;
        if (!/[^\s]/.test(batteryLevelDate)) {
            errors.batteryLevelDate = 'Reading date is required';
            isValid = false;
        }
        if (+batteryLevelOfCharge < 0) {
            errors.batteryLevelOfCharge = 'Odometer reading can not be negative';
            isValid = false;
        }
        if (!/[^\s]/.test(batteryLevelOfCharge)) {
            errors.batteryLevelOfCharge = 'Odometer reading  is required';
            isValid = false;
        }
        setError(errors);
        return isValid;
    };

    const handleData = async () => {
        if (validate()) {
            let res = await postBatteryLevels({
                batteryLevelID: state.batteryLevelID,
                batteryLevelVehicleID: state.batteryLevelVehicleID,
                batteryLevelDate: state.batteryLevelDate,
                batteryLevelOfCharge: +state.batteryLevelOfCharge,
                batteryLevelStateOfCharge: state.batteryLevelStateOfCharge,
                batteryLevelCaptureSource: 'Manual Entry',
                batteryLevelActive: true
            });
            if (res.success) {
                showToastSuccess('Battery level added sucessfully');
                props.reloadData(props.VehicleID);
                setState((state) => ({
                    ...state,
                    batteryLevelDate: '',
                    batteryLevelOfCharge: '',
                    batteryLevelStateOfCharge: ''
                }));
            } else {
                showToastError(res?.message || 'Something went wrong!');
                setState((state) => ({
                    ...state,
                    batteryLevelDate: '',
                    batteryLevelOfCharge: ''
                }));
            }
        }
    };

    const inputChange = (event) => {
        const { name, value } = event.target;
        if (name == 'batteryLevelOfCharge' || name == ' batteryLevelStateOfCharge') {
            if (value == 0 || value <= 99) {
                setState((state) => ({
                    ...state,
                    [name]: value
                }));
            }
        } else {
            setState((state) => ({
                ...state,
                [name]: value
            }));
        }
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <TableContainer style={{ border: '1px solid rgba(0,0,0,0.2)' }}>
                        <div style={gridStyle.header}>
                            <div style={gridStyle.title}>
                                <Grid container style={{ alignItems: 'center' }}>
                                    <Grid xs={12} sm={3} md={3} lg={3} xl={3} item style={{ alignItems: 'center' }}>
                                        <Typography variant="h6" color="secondary" style={gridStyle.tbHead}>
                                            Battery Level
                                        </Typography>
                                    </Grid>

                                    <Grid xs={12} sm={9} md={9} lg={9} xl={9} container item justify="flex-end">
                                        <Typography variant="caption" style={{ fontSize: '0.800rem', color: 'black', padding: '7px' }}>
                                            Latest Battery Levels {vehicleLevelOfCharge || 0}% (level of charge) / {vehicleStateOfCharge || 0}% (state of
                                            charge)
                                            {vehicleBatteryLevelDate != 'N/A' ? (
                                                vehicleBatteryLevelDate ? (
                                                    <span> on {moment(vehicleBatteryLevelDate).format('DD/MM/YYYY')} </span>
                                                ) : (
                                                    'Data not recorded'
                                                )
                                            ) : (
                                                ' Data not recorded'
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <Table aria-label="simple table" size="small">
                            <TableBody>
                                <Hidden only={['xs']}>
                                    <TableRow>
                                        <TableCell style={{ color: 'inherit', padding: '5px' }} width="30%">
                                            <InputLabel required shrink>
                                                Date
                                            </InputLabel>
                                            <DatePicker
                                                name="batteryLevelDate"
                                                value={(state.batteryLevelDate && moment(state.batteryLevelDate).format('YYYY-MM-DD')) || ''}
                                                onChange={inputChange}
                                                InputProps={{ inputProps: { max: `${state.date}` } }}
                                            />
                                            <FormHelperText error>{error.batteryLevelDate}</FormHelperText>
                                        </TableCell>
                                        <TableCell style={{ color: 'inherit', padding: '5px' }} width="30%">
                                            <MandatoryPositiveInput
                                                inputLabel="Level Of Charge %"
                                                placeholder="Level Of Charge %"
                                                name="batteryLevelOfCharge"
                                                value={state.batteryLevelOfCharge || ''}
                                                onChange={inputChange}
                                                errors={error.batteryLevelOfCharge}
                                                InputProps={{ inputProps: { min: 0, max: 99 } }}
                                            />
                                        </TableCell>
                                        <TableCell style={{ color: 'inherit', padding: '5px' }} width="30%">
                                            <MandatoryPositiveInput
                                                inputLabel="State Of Charge %"
                                                placeholder="State Of Charge %"
                                                name="batteryLevelStateOfCharge"
                                                value={state.batteryLevelStateOfCharge || ''}
                                                onChange={inputChange}
                                                errors={error.batteryLevelStateOfCharge}
                                                InputProps={{ inputProps: { min: 0, max: 99 } }}
                                            />
                                        </TableCell>
                                        <TableCell align="right" width="5%" style={{ padding: '5px' }}>
                                            <InputLabel>{'\u{2800}'}</InputLabel>
                                            <SecondaryButton onClick={handleData}>Add</SecondaryButton>
                                        </TableCell>
                                    </TableRow>
                                </Hidden>
                                <Hidden only={['lg', 'xl', 'md', 'sm']}>
                                    <TableRow>
                                        <TableCell style={{ color: 'inherit', padding: '5px' }} width="100%">
                                            <InputLabel required shrink>
                                                Date
                                            </InputLabel>
                                            <DatePicker
                                                name="batteryLevelDate"
                                                value={(state.batteryLevelDate && moment(state.batteryLevelDate).format('YYYY-MM-DD')) || ''}
                                                onChange={inputChange}
                                                InputProps={{ inputProps: { max: `${state.date}` } }}
                                            />
                                            <FormHelperText error>{error.batteryLevelDate}</FormHelperText>
                                            <MandatoryPositiveInput
                                                inputLabel="Level Of Charge %"
                                                placeholder="Level Of Charge %"
                                                name="batteryLevelOfCharge"
                                                value={state.batteryLevelOfCharge || ''}
                                                onChange={inputChange}
                                                errors={error.batteryLevelOfCharge}
                                                InputProps={{ inputProps: { min: 0, max: 99 } }}
                                            />
                                            <MandatoryPositiveInput
                                                inputLabel="State Of Charge %"
                                                placeholder="State Of Charge %"
                                                name="batteryLevelStateOfCharge"
                                                value={state.batteryLevelStateOfCharge || ''}
                                                onChange={inputChange}
                                                errors={error.batteryLevelStateOfCharge}
                                                InputProps={{ inputProps: { min: 0, max: 99 } }}
                                            />
                                            <div>
                                                <InputLabel>{'\u{2800}'}</InputLabel>
                                                <SecondaryButton onClick={handleData}>Add</SecondaryButton>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </Hidden>
                                <TableRow key={1}></TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
};
export default AddBatteryLevels;
