import React, { useEffect, useState, useMemo } from 'react';
import { DataGrid, EditButton, formatters } from '../../../../../Core/Controls';
import { getCustomerBreakdowns } from '../../../../../Core/Services/InternalUser/ServiceAgent/breakdownService';
import AddBreaddownModal from '../../../Maintenance/BreakDown/AddBreakdown';
import WorkIcon from '@material-ui/icons/Work';
import { CustomIconButton } from '../../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { Link } from 'react-router-dom';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
import { useHistory } from 'react-router-dom';
import { AppStorage } from '../../../../../Core/Basic/storage-service';

const baseColumns = [
    { key: 'action', name: 'Action', width: 145, addFileUploaderIcon: true },
    {
        key: 'defectDate',
        name: 'Date',
        formatter: formatters.Date_DDMMYYYY
    },
    { key: 'defectRegNumber', name: 'Registration' },
    { key: 'defectLocation', name: 'Location' },
    // { key: 'defectDriverName', name: 'Driver Name' },
    // { key: 'defectDriverTelephone', name: 'Driver Number' },
    { key: 'defectContactName', name: 'Reported By' },
    { key: 'defectContactNumber', name: 'Reported Phone' },
    { key: 'defectJobStatusDescription', name: 'Status' },
    { key: 'defectType', name: 'Defect Type' }
];

const BreakDownDashboard = (props) => {
    const [state, setState] = useState({
        breakdownList: [],
        loader: true,
        modalToggle: false,
        breakID: null,
        rowVehicleID: null
    });
    let history = useHistory();
    let checkhaveAccess = AppStorage.getAllowAcces();

    const handleBreakDown = (params, VehicleID) => {
        setState((st) => ({
            ...st,
            modalToggle: true,
            breakID: params,
            rowVehicleID: VehicleID
        }));
    };
    const closeModal = (res) => {
        setState((st) => ({
            ...st,
            modalToggle: false
        }));
        res && pullBreakDownAndUpdateState();
    };

    useEffect(async () => {
        pullBreakDownAndUpdateState();
    }, []);

    const pullBreakDownAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };
    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.defectJobID}/${val?.defectVehicleID}`,

            state: 'breakdown'
        });
    };

    const dataFormatter = (data, row) => {
        return (
            <>
                <EditButton toolTipTitle="Edit Breakdown" onClick={() => handleBreakDown(row.defectID, row.defectVehicleID)} />

                {checkhaveAccess?.CanViewJobs && (
                    <CustomIconButton
                        icon={WorkIcon}
                        color="secondary"
                        className="modal-btn-update btn_space"
                        toolTipTitle="Book Job Details"
                        onClick={() => handleEditClick(row)}
                    />
                )}
            </>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);
    const gridBtnTitle = !props.VehicleID ? '' : 'Add Breakdown';

    return (
        <div className={!props.frompage ? `screen` : ''}>
            {state.modalToggle ? (
                <AddBreaddownModal VehicleID={props.VehicleID || state.rowVehicleID} onClose={() => closeModal(true)} breakDownID={state.breakID} />
            ) : (
                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={columns}
                    idFieldName="defectID"
                    fileUploaderConfigName="BreakdownScreen"
                    getUrl={`VehicleBreakdowns?customerID=${props.customerID}`}
                    btnTitle={gridBtnTitle}
                    onBtnClick={() => handleBreakDown(null)}
                    isReload={state.isReload}
                />
            )}
        </div>
    );
};

export default BreakDownDashboard;
