import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getAllMileageLogById = async (Id) => {
    let res = await getWithAuth(`OdometerReadings?vehicleID=${Id}`);
    return res;
};

export const postMileageLog = async (data) => {
    let res = await postWithAuth(`OdometerReadings`, data);
    return res;
};

export const postBatteryLevels = async (data) => {
    let res = await postWithAuth(`BatteryLevels`, data);
    return res;
};
