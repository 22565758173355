import React, { useState, useEffect, useMemo } from 'react';
import { getAllVMRSCategory } from '../../../../../Core/Services/InternalUser/vmrsCategoryService';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import VmrsCategoryModal from './vmrsCategoryModal';
import { BreadCrumbs, DataGrid, EditButton, AddButton } from '../../../../../Core/Controls';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
import '../../../../commonStyle.scss';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Jobs', active: true },
    { name: 'VMRS Categories', active: true }
];

const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'vmrsDescription', name: 'Description' },
    { key: 'nominalCode', name: 'Nominal Code', width: '14%' },
    { key: 'customerName', name: 'Customer Code', width: '14%' },
    { key: 'vehicleTypeDescription', name: 'Vehicle Type', width: '14%' },
    { key: 'vmrsType', name: 'VMRS Type', width: '14%' }
];

const VmrsCategory = (props) => {
    const [state, setState] = useState({
        vmrsCategoryList: [],
        loader: true,
        modalToggle: false
    });

    const pullMakeData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const handleMakeUpdate = (params) => {
        setState((state) => ({ ...state, modalToggle: true, userID: params?.vmrsCategoryID }));
    };

    const editActionButton = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleMakeUpdate(row)} toolTipTitle="Edit VMRS Category" />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = editActionButton;
        }
        return newColumn;
    });

    const title = state.userID ? 'Update VMRS Category' : 'Add VMRS Category';

    const closeMakeDialog = (res) => {
        setState((state) => ({ ...state, modalToggle: false }));
        res && pullMakeData();
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                columns={columns}
                getUrl="VMRSCategory"
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                btnTitle="Add VMRS Category"
                onBtnClick={() => handleMakeUpdate(null)}
            />
            {state.modalToggle ? (
                <DialogComp title={title} onClose={() => closeMakeDialog(false)} maxWidth="md">
                    <VmrsCategoryModal vmrsCategoryID={state.userID} onClose={() => closeMakeDialog(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default VmrsCategory;
