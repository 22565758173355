import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { SecondaryButton, SingleSelect, InputText, AppButtonGroup } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import { addLeadFinalise } from '../../../../Core/Services/InternalUser/ControllerService/EnquiriService';

const StatusModal = (props) => {
    const [state, setState] = useState({
        errors: {},
        leadStatusID: '',
        lostReasonID: '',
        comment: '',
        leadStatusList: [
            { id: 3, name: 'Successful' },
            { id: 4, name: 'Lost' }
        ]
    });
    const { showToastSuccess, showToastError } = useToast();

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => ({ ...st, [nm]: val }));
    };

    const validations = () => {
        let formIsValid = true;
        let errors = {};
        if (!/[^\s]/.test(state.comment)) {
            errors.comment = 'Comment is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(state.leadStatusID)) {
            errors.statusId = 'Status is required';
            formIsValid = false;
        }
        if (state.leadStatusID == 4 && !/[^\s]/.test(state.lostReasonID)) {
            errors.reasonId = 'Reason is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const SubmitHandler = async () => {
        if (validations()) {
            let FormData = {
                leadID: props.leadID,
                statusID: state.leadStatusID,
                Description: state.comment,
                lostReasonID: state.lostReasonID?.id
            };
            let res = await addLeadFinalise(FormData);
            if (res.success) {
                showToastSuccess('Status updated successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
        }
    };

    const InputChangeHandler = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };
    const onBtnSelected = (o) => {
        setState((dt) => ({
            ...dt,
            ...o
        }));
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink required>
                    Lead Status
                </InputLabel>
                <AppButtonGroup
                    width="50%"
                    name="leadStatusID"
                    onChange={onBtnSelected}
                    value={state.leadStatusID}
                    options={state.leadStatusList}
                    valuePropertyName="id"
                    textPropertyName="name"
                />
                <FormHelperText error>{state.errors.statusId}</FormHelperText>
            </Grid>
            <Grid item xs={12} className={`${state.leadStatusID == 3 ? '' : 'mandatory-fields'}`}>
                <InputLabel shrink required>
                    Lost Reason
                </InputLabel>
                <SingleSelect
                    disabled={+state.leadStatusID == 3}
                    options={props.leadLostReasonsList || []}
                    value={state.lostReasonID}
                    onChange={singleSelectChange('lostReasonID')}
                />
                <FormHelperText error>{state.errors.reasonId}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputLabel shrink>Comment</InputLabel>
                <InputText multiline rows={3} onChange={InputChangeHandler} placeholder="Comment" name="comment" />
                <FormHelperText error>{state.errors.comment}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton fullWidth className="submit_btn" onClick={SubmitHandler}>
                    Save
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default StatusModal;
