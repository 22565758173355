import { postWithAuth, getWithAuth } from "../../Basic/HttpService";

export const getAllProductGroups = async () => {
  let res = await getWithAuth(`productgroups`);
  return res;
};

export const getProductGroupsById = async (id) => {
  let res = await getWithAuth(`productgroups/${id}`);
  return res;
};

export const postProductGroupsData = async (data) => {
  let res = await postWithAuth(`productgroups`, data);
  return res;
};
