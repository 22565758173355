import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { InputText, SecondaryButton, SecondaryCheckbox, MandatoryField } from '../../../../Core/Controls';
import '../../../commonStyle.scss';

const CostCenterDialog = (props) => {
    const [state, setState] = useState({
        id: null,
        value: '',
        isChecked: true,
        isError: false
    });

    useEffect(() => {
        if (!props.data.isAdd) {
            const { costCentreID, costCentreDescription, costCentreActive, btnLoader } = props.data.selectedRowData;
            setState((st) => ({
                ...st,
                id: costCentreID,
                value: costCentreDescription,
                isChecked: costCentreActive,
                btnLoader: btnLoader
            }));
        } else {
            const { btnLoader } = props.data.selectedRowData;
            setState((st) => ({
                ...st,
                btnLoader: btnLoader
            }));
        }
    }, []);
    const validate = () => {
        if (/[^\s]/.test(state.value)) {
            return true;
        }
        return false;
    };
    const handleData = () => {
        if (validate()) {
            props.saveData(state);
            props.onClose();
        } else {
            setState((st) => ({
                ...st,
                isError: true
            }));
        }
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Description"
                        placeholder="Description"
                        autoFocus
                        defaultValue={props.data.selectedRowData.costCentreDescription || ''}
                        onChange={(event) => {
                            setState((st) => ({
                                ...st,
                                value: event.target.value,
                                isError: false
                            }));
                        }}
                        errors={state.isError ? 'This field is required' : null}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SecondaryCheckbox
                        defaultChecked
                        checked={state.isChecked}
                        disabled={props.data.isAdd ? true : false}
                        name="costCentreActive"
                        onChange={(event) => {
                            setState((st) => ({
                                ...st,
                                isChecked: event.target.checked
                            }));
                        }}
                        label="Is Active ?"
                    />
                </Grid>
                <Grid item xs={12} className="submit_btn">
                    <SecondaryButton onClick={handleData} isBtnLoader={state.btnLoader}>
                        {props.data.isAdd ? 'ADD' : 'UPDATE'}
                    </SecondaryButton>
                </Grid>
            </Grid>
        </>
    );
};
export default CostCenterDialog;
