import {
  postWithAuth,
  getWithAuth,
  putWithAuth,
} from "../../Basic/HttpService";

export const getAllCostCenter = async () => {
  let res = await getWithAuth(`Costcentres`);
  return res;
};

export const postCostCenter = async (data) => {
  let res = await postWithAuth(`Costcentres`, data);
  return res;
};
export const putCostCenter = async (data) => {
  let res = await putWithAuth(`Costcentres/${data.costCentreID}`, data);
  return res;
};
