import React, { useEffect, useMemo, useState } from 'react';
import { getChecks } from '../../../../../Core/Services/InternalUser/Vehicle/vehicleTabChecks';
import { EditButton, DataGrid, formatters } from '../../../../../Core/Controls';
import { getDriverVehicleCheck } from '../../../../../Core/Services/InternalUser/Vehicle/driverVehicle';
import DriverDailyChecks from './driverDailyChecks';
import EditDriverDailyChecks from './editDriverChecks';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';

import moment from 'moment';

const basecolumns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'vehicleDailyCheckDateCheck', name: 'Checked', formatter: formatters.Date_DDMMMYYYY_HHMM, width: '12%' },
    { key: 'vehicleDailyCheckResult', name: 'Result', width: '7%' },
    { key: 'status', name: 'Status' },
    { key: 'vehicleDailyCheckCheckerName', name: 'Checker Name' },
    { key: 'vehicleDailyCheckDriverName', name: 'Driver Name' },
    { key: 'vehicleDailyCheckDriverComment', name: 'Driver Comment' },
    { key: 'vehicleDailyCheckCheckerComment', name: 'Checker Comment' }
];

const DriverDailyChecksGrid = (props) => {
    const [checks, setChecks] = useState({
        addChecksShow: false,
        editChecksShow: false,
        checklist: [],
        showLoader: true
    });

    useEffect(async () => {
        pullChecksData();
    }, [props.VehicleID]);

    const pullChecksData = async () => {
        setChecks((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const editChecksScreen = (id) => {
        setChecks((st) => ({
            ...st,
            editChecksShow: true,
            checksID: id.vehicleDailyCheckId
        }));
    };

    const actionButton = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => editChecksScreen(row)} toolTipTitle="Edit Check" />
            </div>
        );
    };

    const onClose = () => {
        setChecks((st) => ({
            ...st,
            addChecksShow: false
        }));
        pullChecksData();
    };

    const handleAddChecks = () => {
        setChecks((st) => ({
            ...st,
            addChecksShow: true
        }));
    };

    const editClose = () => {
        setChecks((st) => ({
            ...st,
            editChecksShow: false
        }));
        pullChecksData();
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = actionButton;
        }
        return tempCols;
    }, []);
    return (
        <div>
            {checks.editChecksShow ? (
                <EditDriverDailyChecks onClose={editClose} chekcsID={checks.checksID} VehicleID={props.VehicleID} />
            ) : checks.addChecksShow ? (
                <DriverDailyChecks onClose={onClose} VehicleID={props.VehicleID} startTime={checks.startTime} endTime={checks.endTime} />
            ) : (
                <GijgoGrid
                    columns={columns}
                    getUrl={`DriverDailyCheck/List?VehicleID=${props.VehicleID}`}
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    isReload={checks.isReload}
                    onBtnClick={handleAddChecks}
                    btnTitle="Add Check"
                />
            )}
        </div>
    );
};

export default DriverDailyChecksGrid;
