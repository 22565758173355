import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getAllEnquiry = async () => {
    let res = await getWithAuth('Leads');
    return res;
};

export const getEnquiryScreenDropdowns = async () => {
    let res = await getWithAuth(`Leads/screenDropdowns`);
    return res;
};

export const addNewEnquiry = async (data) => {
    let res = await postWithAuth('Leads', data);
    return res;
};

export const getEnquiryById = async (Id) => {
    let res = await getWithAuth(`Leads/${Id}`);
    return res;
};

export const getEnquiryNotes = async (LeadNoteLeadID) => {
    let res = await getWithAuth(`LeadNotes/${LeadNoteLeadID}`);
    return res;
};

export const addLeadNotes = async (data) => {
    let res = await postWithAuth('LeadNotes', data);
    return res;
};

export const addLiveEnquiry = async (data) => {
    let res = await postWithAuth('Leads/LiveEnquiry', data);
    return res;
};

export const addLeadFinalise = async (data) => {
    let res = await postWithAuth('Leads/LeadFinalise', data);
    return res;
};
