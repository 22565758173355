import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { InputLabel, FormHelperText } from "@material-ui/core";
import { InputText, SecondaryButton, MandatoryField } from "../../../Core/Controls";
import { getRetorquepositionId, postRetorquepositionData } from "../../../Core/Services/InternalUser/VOR/retorquePositionsService";
import { useToast } from "../../../Core/Hooks/useToast";
import "../../commonStyle.scss";

function RetorquePositionsModal(props) {
  const [state, setState] = useState({
    retorquePositionID: null,
    retorquePositionDescription: "",
    errors: {},
  });

  const { showToastSuccess, showToastError } = useToast();

  useEffect(async () => {
    if (props.retorquePositionID) {
      let res = await getRetorquepositionId(props.retorquePositionID);
      if (res.success) {
        setState((state) => ({
          ...state,
          retorquePositionID: res.data.retorquePositionID,
          retorquePositionDescription: res.data.retorquePositionDescription,
        }));
      }
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const validations = () => {
    const { retorquePositionDescription } = state;
    let emptyStrigPattern = /[^\s]/;
    let formIsValid = true;
    let errors = {};
    if (!emptyStrigPattern.test(retorquePositionDescription)) {
      errors.retorquePositionDescription = " Retorque Position is required";
      formIsValid = false;
    }
    setState((state) => ({ ...state, errors: errors }));
    return formIsValid;
  };

  const submitHandler = async () => {
    if (validations()) {
      const retorquepositionData = {
        retorquePositionID: state.retorquePositionID,
        retorquePositionDescription: state.retorquePositionDescription,
      };
      let res = await postRetorquepositionData(retorquepositionData);
      if (res.success) {
        showToastSuccess("Retorque Position Updated sucessfully");
        props.onClose(true);
      } else {
        showToastError(res.message);
      }
    }
  };

  return (
    <Grid container direction="row" spacing={2} justify="center" alignItems="center">
      <Grid item xs={12} className="mandatory-fields">
        <MandatoryField
          inputLabel="Description"
          name="retorquePositionDescription"
          placeholder="Description"
          onChange={handleInputChange}
          value={state.retorquePositionDescription}
          errors={state.errors.retorquePositionDescription}
        />
      </Grid>

      <Grid item xs={12} className="submit_btn">
        <SecondaryButton onClick={submitHandler}>{props.retorquePositionID ? "Update" : "Add"}</SecondaryButton>
      </Grid>
    </Grid>
  );
}

export default RetorquePositionsModal;
