import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { InputLabel, FormHelperText } from '@material-ui/core';
import { InputText, SecondaryButton, DatePicker, formatters } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import { getLicenceConvictionbyid, postlicenceConviction } from '../../../../Core/Services/InternalUser/licenceCheckService';

export const CheckConviction = (props) => {
    const { state, handleConvictionModal, pullLicenceConvictionList } = props;

    const [newSt, setNewSt] = useState({ licenceConvictionID: null });
    const [errors, setErrors] = useState({});
    const { showToastSuccess, showToastError } = useToast();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewSt((st) => ({ ...st, [name]: value }));
    };

    const btnConditions = state.licenceConvictionCheckID ? 'Update' : 'Add';
    useEffect(async () => {
        if (state.licenceConvictionCheckID) {
            let res = await getLicenceConvictionbyid(state.licenceConvictionCheckID);

            setNewSt((w) => ({
                ...w,
                licenceConvictionID: res.data.details[0].licenceConvictionID,
                licenceConvictionCheckID: res.data.details[0].licenceConvictionCheckID,
                licenceConvictionOffenceCode: res.data.details[0].licenceConvictionOffenceCode,
                licenceConvictionOffenceDescription: res.data.details[0].licenceConvictionOffenceDescription,
                licenceConvictionPoints: res.data.details[0].licenceConvictionPoints,
                licenceConvictionDateConvicted: formatters.DateFormatter(res.data.details[0].licenceConvictionDateConvicted),
                licenceConvictionDateEffectiveUntil: formatters.DateFormatter(res.data.details[0].licenceConvictionDateEffectiveUntil),
                licenceConvictionDateBannedUntil: formatters.DateFormatter(res.data.details[0].licenceConvictionDateBannedUntil),
                licenceConvictionDateCreated: formatters.DateFormatter(res.data.details[0].licenceConvictionDateCreated),
                licenceConvictionCreatedUserID: res.data.details[0].licenceConvictionCreatedUserID,
                licenceConvictionDateUpdated: res.data.details[0].licenceConvictionDateUpdated,
                licenceConvictionUpdatedUserID: res.data.details[0].licenceConvictionUpdatedUserID,
                licenceConvictionDateDeleted: res.data.details[0].licenceConvictionDateDeleted,
                licenceConvictionDeletedUserID: res.data.details[0].licenceConvictionDeletedUserID
            }));
        }
    }, []);

    const validation = () => {
        let errors = {};
        let isValid = true;
        let {
            licenceConvictionOffenceDescription,
            licenceConvictionOffenceCode,
            licenceConvictionPoints,
            licenceConvictionDateConvicted,
            licenceConvictionDateEffectiveUntil
        } = newSt;
        if (!licenceConvictionDateConvicted) {
            errors.licenceConvictionDateConvicted = ' Date convicted is required';
            isValid = false;
        }
        if (!licenceConvictionDateEffectiveUntil) {
            errors.licenceConvictionDateEffectiveUntil = 'Effective until is required';
            isValid = false;
        }
        if (!licenceConvictionPoints) {
            errors.licenceConvictionPoints = 'Points is required';
            isValid = false;
        }
        if (!licenceConvictionOffenceCode) {
            errors.licenceConvictionOffenceCode = 'Offence code is required';
            isValid = false;
        }
        if (!licenceConvictionOffenceDescription) {
            errors.licenceConvictionOffenceDescription = 'Offence description is required';
            isValid = false;
        }
        setErrors((w) => ({ ...w, ...errors }));
        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            let data = {
                licenceConvictionID: newSt.licenceConvictionID || null,
                licenceConvictionCheckID: state.licenceCheckID || null,
                licenceConvictionOffenceCode: newSt.licenceConvictionOffenceCode || null,
                licenceConvictionOffenceDescription: newSt.licenceConvictionOffenceDescription || null,
                licenceConvictionPoints: newSt.licenceConvictionPoints || null,
                licenceConvictionDateConvicted: newSt.licenceConvictionDateConvicted || null,
                licenceConvictionDateEffectiveUntil: newSt.licenceConvictionDateEffectiveUntil || null,
                licenceConvictionDateBannedUntil: newSt.licenceConvictionDateBannedUntil || null
            };
            let res = await postlicenceConviction(data);
            if (res.success) {
                showToastSuccess(`Conviction ${btnConditions} Successfully`);
                handleConvictionModal();
                pullLicenceConvictionList();
            } else {
                showToastError('error');
            }
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                    <InputLabel shrink error>
                        Date convicted{' '}
                    </InputLabel>
                    <DatePicker onChange={handleChange} name="licenceConvictionDateConvicted" value={newSt.licenceConvictionDateConvicted} />
                    <FormHelperText error>{errors.licenceConvictionDateConvicted}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                    <InputLabel shrink error>
                        Effective until
                    </InputLabel>
                    <DatePicker
                        placeholder="Effective until"
                        onChange={handleChange}
                        name="licenceConvictionDateEffectiveUntil"
                        value={newSt.licenceConvictionDateEffectiveUntil}
                    />
                    <FormHelperText error>{errors.licenceConvictionDateEffectiveUntil}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                    <InputLabel shrink>Banned until</InputLabel>
                    <DatePicker
                        placeholder="Banned until"
                        onChange={handleChange}
                        name="licenceConvictionDateBannedUntil"
                        value={newSt.licenceConvictionDateBannedUntil}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                    <InputLabel shrink error>
                        Points
                    </InputLabel>
                    <InputText
                        type="number"
                        placeholder="Points"
                        onChange={handleChange}
                        name="licenceConvictionPoints"
                        value={+newSt.licenceConvictionPoints}
                    />
                    <FormHelperText error>{errors.licenceConvictionPoints}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                    <InputLabel shrink error>
                        Offence code
                    </InputLabel>
                    <InputText
                        placeholder="Offence code"
                        onChange={handleChange}
                        name="licenceConvictionOffenceCode"
                        value={newSt.licenceConvictionOffenceCode}
                    />
                    <FormHelperText error>{errors.licenceConvictionOffenceCode}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                    <InputLabel shrink error>
                        Offence description
                    </InputLabel>
                    <InputText
                        placeholder="Offence description"
                        onChange={handleChange}
                        name="licenceConvictionOffenceDescription"
                        value={newSt.licenceConvictionOffenceDescription}
                    />
                    <FormHelperText error>{errors.licenceConvictionOffenceDescription}</FormHelperText>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                    <InputLabel shrink error>
                        Current effective points on licence{' '}
                    </InputLabel>
                    <InputText placeholder="Current effective points on licence" onChange={handleChange} />
                </Grid> */}
            </Grid>
            <br />
            <Grid container xs={12} spacing={1}>
                <Grid item xs={12} sm={6}>
                    <SecondaryButton onClick={handleSubmit}>{btnConditions}</SecondaryButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SecondaryButton onClick={handleConvictionModal}>Cancel</SecondaryButton>
                </Grid>
            </Grid>
        </>
    );
};
