import { CircularProgress } from '@material-ui/core';
import React from 'react';
import _ from 'lodash';
import './UploaderModal.scss';

const SingleDocumentViewer = (props) => {
    return (
        <div style={{ height: '100%' }}>
            {_.isEmpty(props.imageList) ? (
                <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress color="secondary" />
                </div>
            ) : (
                <>
                    {props.slectedFileExt === '.pdf' ? (
                        <iframe src={props.imageList} id="editviewer" frameBorder="0" scrolling="auto" allowFullScreen width="100%" height="100%"></iframe>
                    ) : (
                        <div style={{ height: '100%' }}>
                            <img
                                src={props.imageList}
                                alt="No document selected"
                                id="printableImage"
                                width="100%"
                                height="100%"
                                style={{ objectFit: props.fromPurchase ? 'contain' : '' }}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default SingleDocumentViewer;
