import React, { useState, useEffect, useMemo } from 'react';
import { getAllRetorqueTypes } from '../../../Core/Services/InternalUser/VOR/retorqueReasonsService';
import DataGrid from '../../../Core/Controls/Grid';
import DialogComp from '../../../Core/Dialog/DialogComp';
import RetorqueReasonsModal from './RetorqueReasonsModal';
import { BreadCrumbs, AddButton, EditButton } from '../../../Core/Controls';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import '../../commonStyle.scss';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicles', active: true },
    { name: 'Retorque Reasons', active: true }
];

const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'retorqueReasonDescription', name: 'Description' }
];

function RetorqueReasons(props) {
    const [state, setState] = useState({
        RetorqueList: [],
        loader: true,
        open: false
    });

    useEffect(async () => {
        pullRetorquereasons();
    }, []);

    const pullRetorquereasons = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const retorqueHandler = (params) => {
        setState((state) => ({ ...state, open: true, retorqueReasonID: params?.retorqueReasonID }));
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => retorqueHandler(row)} toolTipTitle="Edit Retorque Reason" />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        return newColumn;
    });

    const title = state.retorqueReasonID ? 'Update Retorque Reason' : 'Add Retorque Reason';

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullRetorquereasons();
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />

            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={'Retorquereasons'}
                btnTitle="Add Retorque Reason"
                onBtnClick={() => retorqueHandler(null)}
                isReload={state.isReload}
            />
            {state.open ? (
                <DialogComp title={title} onClose={() => closeModal(false)} maxWidth="md">
                    <RetorqueReasonsModal retorqueReasonID={state.retorqueReasonID} onClose={() => closeModal(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
}
export default RetorqueReasons;
