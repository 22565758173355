import { PinDropSharp } from '@material-ui/icons';
import React, { useEffect, useState, useMemo } from 'react';
import { DataGrid, EditButton, formatters } from '../../../../Core/Controls';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { getAllVehicleDailyChecks } from '../../../../Core/Services/InternalUser/vehicleDailyChecksAdminService';
import CheckSectionModal from './vehicleDailyCheckModal';

const basecolumns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'dailyCheckSectionDescription', name: 'Description' },
    {
        key: 'dailyCheckSectionPosition',
        name: 'Position',
        width: '8%',
        formatter: formatters.ColumnTextAlignRight,
        headerRenderer: formatters.ColumnHeaderAlignRight
    },
    { key: 'dailyCheckSectionActive', name: 'Is Active ?', formatter: formatters.YesNo, width: '8%' },
    { key: 'dailyCheckSectionLegalRequirement', name: 'Legal Requirement', formatter: formatters.YesNo, width: '16%' }
];
const VehicleDailyChecksAdmin = (props) => {
    const [state, setState] = useState({
        checkSectionList: [],
        showLoader: true,
        modalToggle: false
    });

    useEffect(async () => {
        pullData();
    }, []);

    const pullData = async () => {
        let res = await getAllVehicleDailyChecks();

        setState((state) => ({
            ...state,
            checkSectionList: res.data.list.filter((m) => {
                return m.vehicleCategoryDescription === props.heading;
            }),
            showLoader: false
        }));
    };
    const actionButton = (data) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton
                    onClick={() => {
                        handleButton(data.row);
                    }}
                    toolTipTitle="Edit Vehicle Daily Check"
                />
            </div>
        );
    };

    const handleButton = (params) => {
        setState((state) => ({ ...state, modalToggle: true, userID: params.dailyCheckSectionID }));
    };

    const closeDialog = (res) => {
        setState((state) => ({ ...state, modalToggle: false }));
        res && pullData();
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = actionButton;
        }

        return tempCols;
    }, []);

    const title = state.userID ? 'Update Daily Check' : 'Add Daily Check';

    return (
        <div>
            <DataGrid
                columns={columns}
                rows={state.checkSectionList}
                isRowHovered={true}
                loadingData={state.showLoader}
                btnTitle="Add Daily Check Item"
                onBtnClick={handleButton}
                paginate
                offset={250}
            />
            {state.modalToggle ? (
                <DialogComp title={title} onClose={() => closeDialog(false)}>
                    <CheckSectionModal checkSectionID={state.userID} onClose={() => closeDialog(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default VehicleDailyChecksAdmin;
