import { createContext, useEffect, useState } from 'react';
import { useToast } from '../../../../Core/Hooks/useToast';
import {
    addVehicle,
    getVehicleById,
    getVehicleScreenDropdowns,
    getRegBasedData
} from '../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { find } from 'lodash';

import { formatters } from '../../../../Core/Controls';

import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { getJobEnggById } from '../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';

export const VehicleScreenContext = createContext();

export const VehicleProvider = (props) => {
    let history = useHistory();

    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);

    const [state, setState] = useState({
        customerAutoApprovalLimit: '',
        vehicleFuelType: '',
        errors: {},
        VehicleID: null,
        regNumber: '',
        customerID: '',
        customerName: '',
        customerList: [],
        technician: [],
        depotsID: '',
        depotName: '',
        depotsList: [],
        modelID: '',
        modelList: [],
        ModelAsPerMake: [],
        vehicleAsPerCategoryList: [],
        depotWithCustList: [],
        makeID: '',
        makeList: [],
        wheelPlan: '',
        vehicleTypeID: '',
        vehicleTypeList: [],
        vehicleTypeDescription: '',
        vehicleCategoryID: '',
        vehicleCategoryDescription: '',
        vehicleCategoryList: [],
        capacity: '',
        co2Emission: '',
        fuelType: '',
        costCentreID: '',
        costCentreList: [],
        ownershipTypeID: '',
        vehicleContractID: null,
        ownershipTypeList: [],
        chassisNumber: '',
        vehiclePrimaryServiceAgentID: '',
        vehiclePrimaryServiceAgentIDList: [],
        vehicleEndUserID: '',
        vehicleEndUserIDList: [],
        fuelTypeList: [],
        vehicleSecondaryServiceAgentID: null,
        contractName: '',
        depotFilterName: '',
        vehicleTechnicianUserID: null
    });

    const [otherFields, setOtherFields] = useState({
        errors: {},
        assetNumber: '',
        fleetNumber: '',
        ministryNumber: '',
        description: '',
        colour: '',
        MPG: '',
        P11DValue: '',
        radioCode: '',
        vehicleTankCapacity: '',
        vehicleTelematicsIMEI: '',
        vehicleNextInspectionDate: '',
        vehicleNextServiceDateDue: '',
        vehicleNextServiceMileage: '',
        vehicleCheckFrequency: '',
        vehicleMaintenanceRef: '',
        primaryAgent: '',
        vehicleLatestMileage: 'N/A',
        vehicleLastMileageDate: 'N/A',
        displayBatteryLevel: 'N',
        vehicleBatteryLevelDate: 'N/A',
        vehicleLevelOfCharge: '',
        vehicleStateOfCharge: '',
        vehicleServiceScheduleID: '',
        vehicleDOTScheduleID: '',
        vehicleNoOfAxles: null,
        vehicleCalibrationScheduleID: null,
        vehicleBrakeTestScheduleID: null,
        // vehicleLolerTestScheduleID: null,
        vehicleNextTachoCalibrationScheduleID: null,
        vehicleChassisWarrantyYears: null,
        vehicleBodyWarrantyYears: null,
        vehicleTracker: '',
        vehicleSpeedLimiter: null,
        vehicleVaisala: '',
        vehicleDistributor: '',
        vehicleCameraSystem: '',
        vehicleSheetingSystem: false,
        vehicleControlSystem: '',
        vehicleOnboardWeighingSystem: '',
        vehicleGrossWeight: null,
        vehicleTyreSizeAxle1: '',
        contractTypeID: null
    });

    const [jobFields, setJobFields] = useState({
        vehicleV5CRegistrationUptoDate: false,
        jobAdminFee: '',
        odometerUnitCode: '',
        odometerUnitCodeList: [],
        cabTypeID: '',
        cabTypeList: [],
        vehicleSuspensionTypeID: '',
        suspensionList: [],
        vehicleBodyTypeID: '',
        vehicleBodyTypeList: [],
        vehicleConfigurationTypeID: '',
        configurationTypeList: [],
        vehicleRFLResponsible: false,
        euro4Compliant: false,
        RDE2Compliant: false,
        shuntVehicle: false,
        vehicleCoveredByFleetInsurance: false,
        vehicleIsSORN: false,
        vehicleMaintenanceRef: '',
        hireSupplierID: '',
        hireSupplierList: [],
        hireHistoryID: '',
        hireHistoryList: [],
        vehicleMaintenanceProviderID: '',
        vehicleMaintenanceProviderList: [],
        vehicleLatestMileage: '',
        vehicleAnnualMileage: '',
        vehicleTaxAmount: '',
        vehicleMOTRequired: false,
        calibrationSchedules: []
    });

    const [dateFields, setDateFields] = useState({
        dateOfReg1: '',
        fleetDate: '',
        dateOfReg: '',
        motExpiry: '',
        motExpiry1: '',
        taxDue: '',
        insuranceRenewalDate: '',
        vehicleRMStartDate: '',
        vehicleVOREndDate: '',
        vehicleRMEndDate: '',
        vehicleRMStartDate: '',
        vehicleNextCalibrationDate: '',
        vehicleNextLolerTestDate: '',
        vehicleNextTachoCalibrationDate: ''
    });

    const [tempOptions, setTempOptions] = useState({
        customerIDValue: null,
        depotsIDValue: null,
        makeIDValue: null,
        modelIDValue: null,
        fuelTypeValue: null,
        vehiclePrimaryServiceAgentIDValue: null,
        vehicleEndUserIDValue: null,
        vehicleSecondaryServiceAgentIDValue: null,
        vehicleCategoryIDValue: null,
        vehicleTypeIDValue: null,
        costCentreIDValue: null,
        ownershipTypeIDValue: null,
        odometerUnitCodeValue: null,
        cabTypeIDValue: null,
        vehicleMaintenanceProviderIDValue: null,
        vehicleSuspensionTypeIDValue: null,
        vehicleBodyTypeIDValue: null,
        vehicleConfigurationTypeIDValue: null,
        vehicleServiceScheduleIDValue: null,
        vehicleDOTScheduleIDValue: null,
        vehicleTechnicianUserIDValue: null,
        vehicleCalibrationScheduleIDValue: null,
        vehicleBrakeTestScheduleIDValue: null,
        vehicleBrakeTestScheduleIDValue: null,
        vehicleNextTachoCalibrationScheduleIDValue: null
    });
    const isCustomer = useLoginInfo().userRoles.includes('customer');

    const pullMileageData = async (VehicleID) => {
        if (VehicleID) {
            let res = await getVehicleById(VehicleID);
            const data = res.data?.details[0];

            setOtherFields((st) => ({
                ...st,
                vehicleLatestMileage: data.vehicleLatestMileage || 'N/A',
                vehicleLastMileageDate: data.vehicleLastMileageDate ? moment(data.vehicleLastMileageDate).format('DD/MM/YYYY') : 'N/A',
                displayBatteryLevel: data.displayBatteryLevel,
                vehicleBatteryLevelDate: formatters.DateFormatter(data.vehicleBatteryLevelDate),
                vehicleLevelOfCharge: data.vehicleLevelOfCharge,
                vehicleStateOfCharge: data.vehicleStateOfCharge
            }));
        }
    };
    const getVehicleDataById = async () => {
        let res = await getVehicleById(props.VehicleID);
        const data = res?.data?.details[0];
        if (data) {
            setState((st) => ({
                ...st,
                VehicleID: data.vehicleID,
                regNumber: data.vehicleRegNumber,
                customerID: data.vehicleCustomerID,
                depotsID: data.vehicleDepotID,
                modelID: data.vehicleModel,
                makeID: data.vehicleMakeID,
                makeDescription: data.makeDescription,
                wheelPlan: data.vehicleWheelPlan,
                vehicleTypeID: data.vehicleTypeID,
                vehicleCategoryID: data.vehicleCategoryID,
                customerName: data.customer,
                depotName: data.depotName,
                driver: data.driver,
                vehicleDriverID: data.vehicleDriverID,
                vehicleCategoryDescription: data.vehicleCategoryDescription,
                vehicleTypeDescription: data.vehicleTypeDescription,
                capacity: data.vehicleEngineCapacity,
                co2Emission: data.vehicleCO2Emission,
                fuelType: data.vehicleFuelTypeID,
                vehicleFuelType: data.vehicleFuelType,
                costCentreID: data.vehicleCostCentreID,
                ownershipTypeID: data.vehicleOwnershipTypeID,
                vehicleContractID: data.vehicleContractID,
                chassisNumber: data.vehicleChassisNumber,
                vehiclePrimaryServiceAgentID: data.vehiclePrimaryServiceAgentID,
                vehicleEndUserID: data.vehicleEndUserID,
                endUserName: data.endUserName || '',
                vehicleSecondaryServiceAgentID: data.vehicleSecondaryServiceAgentID,
                vehicleTechnicianUserID: data.vehicleTechnicianUserID,
                vehicleDirectVisionStandardRating: data.vehicleDirectVisionStandardRating,
                fileCount: data.fileCount,
                contractName: data.contractName || 'N/A'
            }));
            setOtherFields((st) => ({
                ...st,
                assetNumber: data.vehicleAssetNumber,
                fleetNumber: data.vehicleFleetNumber,
                ministryNumber: data.vehicleMinistryNumber,
                description: data?.vehicleDescription,
                colour: data.vehicleColour,
                MPG: data.vehicleMPG,
                P11DValue: data.vehicleP11DValue,
                vehicleNextServiceMileage: data.vehicleNextServiceMileage,
                vehicleCheckFrequency: data.vehicleCheckFrequency,
                vehicleMaintenanceRef: data.vehicleMaintenanceRef,
                vehicleNoOfAxles: data.vehicleNoOfAxles,
                vehicleChassisWarrantyYears: data.vehicleChassisWarrantyYears,
                vehicleBodyWarrantyYears: data.vehicleBodyWarrantyYears,
                vehicleTracker: data.vehicleTracker,
                vehicleSpeedLimiter: data.vehicleSpeedLimiter,
                vehicleVaisala: data.vehicleVaisala,
                vehicleDistributor: data.vehicleDistributor,
                vehicleCameraSystem: data.vehicleCameraSystem,
                vehicleSheetingSystem: data.vehicleSheetingSystem,
                vehicleControlSystem: data.vehicleControlSystem,
                primaryAgent: data.primaryAgent,
                vehicleLatestMileage: data.vehicleLatestMileage,
                vehicleLastMileageDate: data.vehicleLastMileageDate ? moment(data.vehicleLastMileageDate).format('DD/MM/YYYY') : 'N/A',
                displayBatteryLevel: data.displayBatteryLevel,
                vehicleBatteryLevelDate: formatters.DateFormatter(data.vehicleBatteryLevelDate),
                vehicleLevelOfCharge: data.vehicleLevelOfCharge,
                vehicleStateOfCharge: data.vehicleStateOfCharge,
                service: data.service,
                schedule: data.schedule,
                ownershipType: data.ownershipType || '',
                engineer: data.engineer,
                vehicleOnboardWeighingSystem: data.vehicleOnboardWeighingSystem,
                vehicleGrossWeight: data.vehicleGrossWeight,
                vehicleTyreSizeAxle1: data.vehicleTyreSizeAxle1,
                contractTypeID: data.contractTypeID
            }));
            setJobFields((st) => ({
                ...st,
                radioCode: data.vehicleRadioCode,
                vehicleTankCapacity: data.vehicleTankCapacity,
                vehicleTelematicsIMEI: data.vehicleTelematicsIMEI,
                vehicleV5CRegistrationUptoDate: data.vehicleV5CRegistrationUptoDate,
                jobAdminFee: data.vehicleJobAdminFee,
                odometerUnitCode: data.vehicleOdometerUnitCode,
                cabTypeID: data.vehicleCabTypeID,
                vehicleSuspensionTypeID: data.vehicleSuspensionTypeID,
                vehicleLatestMileage: data.vehicleLatestMileage,
                vehicleBodyTypeID: data.vehicleBodyTypeID,
                vehicleConfigurationTypeID: data.vehicleConfigurationTypeID,
                vehicleRFLResponsible: data.vehicleRFLResponsible,
                vehicleMaintenanceRef: data.vehicleMaintenanceRef,
                hireSupplierID: data.vehicleHireSupplierID,
                hireHistoryID: data.vehicleHireHistoryID,
                vehicleMaintenanceProviderID: data.vehicleMaintenanceProviderID,
                vehicleAnnualMileage: data.vehicleAnnualMileage,
                vehicleTaxAmount: data.vehicleTaxAmount,
                vorVehicleID: data.vorVehicleID,
                vehicleServiceScheduleID: data.vehicleServiceScheduleID,
                vehicleDOTScheduleID: data.vehicleDOTScheduleID,
                vehicleCalibrationScheduleID: data.vehicleCalibrationScheduleID,
                vehicleBrakeTestScheduleID: data.vehicleBrakeTestScheduleID,
                // vehicleLolerTestScheduleID: data.vehicleLolerTestScheduleID,
                vehicleNextTachoCalibrationScheduleID: data.vehicleNextTachoCalibrationScheduleID,
                vehicleActive: data.vehicleActive,
                vehicleIsSORN: data.vehicleIsSORN,
                euro4Compliant: data.vehicleEuro4Compliant,
                RDE2Compliant: data.vehicleRDE2Compliant,
                shuntVehicle: data.vehicleShuntVehicle,
                vehicleCoveredByFleetInsurance: data.vehicleCoveredByFleetInsurance,
                vehicleMOTRequired: data.vehicleMOTRequired
            }));

            setDateFields((st) => ({
                ...st,
                dateOfReg: formatters.DateFormatter(data.vehicleDateOfRegistration),
                dateOfReg1: formatters.YearFormatter(data.vehicleDateOfRegistration),
                fleetDate: formatters.DateFormatter(data.vehicleFleetDate),
                vehicleNextInspectionDate: formatters.DateFormatter(data.vehicleNextInspectionDate),
                vehicleNextServiceDateDue: formatters.DateFormatter(data.vehicleNextServiceDateDue),
                vehicleNextServiceDateDueDisplay: data.vehicleNextServiceDateDue,
                motExpiry: formatters.DateFormatter(data.vehicleMOTExpiry),
                motExpiry1: formatters.YearFormatter(data.vehicleMOTExpiry),
                taxDue: formatters.DateFormatter(data.vehicleTaxDue),
                insuranceRenewalDate: formatters.DateFormatter(data.vehicleInsuranceRenewalDate),
                vehicleRMEndDate: formatters.DateFormatter(data.vehicleRMEndDate),
                vehicleRMStartDate: formatters.DateFormatter(data.vehicleRMStartDate),
                activeRecallCount: data.activeRecallCount,
                vehicleNextServiceMileage: data.vehicleNextServiceMileage,
                vehicleID: data.vehicleID,
                serviceAgentID: data.serviceAgentID,
                vehicleServiceScheduleID: data.vehicleServiceScheduleID,
                vehicleDOTScheduleID: data.vehicleDOTScheduleID,
                vehicleNextCalibrationDate: formatters.DateFormatter(data.vehicleNextCalibrationDate),
                vehicleNextLolerTestDate: formatters.DateFormatter(data.vehicleNextLolerTestDate),
                vehicleNextTachoCalibrationDate: formatters.DateFormatter(data.vehicleNextTachoCalibrationDate)
            }));
        } else {
            showToastError('Vehicle details not found');
            history.push(`/controller/Vehicles`);
        }
    };
    useEffect(async () => {
        let res = await getVehicleScreenDropdowns();
        setState((st) => ({
            ...st,
            customerList: res.data.customers,
            depotWithCustList: res.data.depots.filter((w) => w.name),
            modelList: res.data.models.map((w) => ({
                ...w,
                id: w.name,
                name: w.name
            })),
            vehicleTypeList: res.data.vehicleTypes,
            makeList: res.data.makes,
            costCentreList: res.data.costCentres,
            ownershipTypeList: res.data.vehicleOwnershipTypes,
            vehicleCategoryList: res.data.vehicleCategories,
            vehiclePrimaryServiceAgentIDList: res.data.serviceAgents,
            vehicleEndUserIDList: res.data.endUsers,
            fuelTypeList: res.data.fuelTypes
            // technician: res.data.technician
        }));
        setJobFields((st) => ({
            ...st,
            cabTypeList: res.data.cabTypes,
            odometerUnitCodeList: res.data.vehicleOdometerUnits,
            suspensionList: res.data.suspensionTypes,
            vehicleBodyTypeList: res.data.bodyTypes,
            configurationTypeList: res.data.configurationTypes,
            hireSupplierList: res.data.vehicleHireReasons,
            hireHistoryList: res.data.vehicleHireTypes,
            vehicleMaintenanceProviderList: res.data.maintenanceProviders,
            scheduleEvents: res.data.scheduleEvents,
            scheduleInspection: res.data.scheduleInspection || [],
            calibrationSchedules: res.data?.calibrationSchedules || []
        }));

        if (props.VehicleID) {
            getVehicleDataById();
        } else {
            setDateFields((st) => ({
                ...st,
                fleetDate: new Date().toISOString().slice(0, 10)
            }));
        }

        if (props.customerID) {
            setState((st) => ({
                ...st,
                customerID: props.customerID
            }));
        }
    }, [props.VehicleID, props.vorID, props.customerID]);

    useEffect(async () => {
        let ModelListAsPerMake = [];
        if (tempOptions.makeIDValue) {
            var item = state.makeList.find((item) => item.id === tempOptions.makeIDValue.id);
            if (item) {
                state.modelList.filter((obj) => {
                    if (item.id === obj.modelMakeID) {
                        ModelListAsPerMake.push(obj);
                    }
                });
            }
            setState((st) => ({ ...st, ModelAsPerMake: ModelListAsPerMake }));
        }
        let vehicleType = [];
        if (tempOptions.vehicleCategoryIDValue) {
            state.vehicleTypeList.filter((obj) => {
                if (tempOptions.vehicleCategoryIDValue.id === obj.vehicleTypeCategoryID) {
                    vehicleType.push(obj);
                }
            });
            setState((st) => ({ ...st, vehicleAsPerCategoryList: vehicleType }));
        }
    }, [tempOptions.vehicleCategoryIDValue, tempOptions.makeIDValue, tempOptions.customerIDValue, isCustomer, state.depotsList]);

    useEffect(() => {
        let newSt = {};
        if (state.customerID) {
            newSt.customerIDValue = state.customerList.find(({ id }) => id === +state.customerID);
        }
        if (state.makeID) {
            newSt.makeIDValue = state.makeList.find(({ id }) => id === state.makeID);
        }

        if (state.modelID) {
            newSt.modelIDValue = state.modelList.find(({ id }) => id === state.modelID);
        }
        if (state.fuelType) {
            newSt.fuelTypeValue = state.fuelTypeList.find(({ id }) => id === state.fuelType);
        }
        if (state.vehiclePrimaryServiceAgentID) {
            newSt.vehiclePrimaryServiceAgentIDValue = state.vehiclePrimaryServiceAgentIDList.find(({ id }) => id === state.vehiclePrimaryServiceAgentID);
        }
        if (state.vehicleSecondaryServiceAgentID) {
            newSt.vehicleSecondaryServiceAgentIDValue = state.vehiclePrimaryServiceAgentIDList.find(({ id }) => id === state.vehicleSecondaryServiceAgentID);
        }
        // if (state.vehicleTechnicianUserID && state.technician.length > 0) {
        //     newSt.vehicleTechnicianUserIDValue = state.technician.find(({ id }) => id === state.vehicleTechnicianUserID);
        // }
        if (state.vehicleEndUserID) {
            newSt.vehicleEndUserIDValue = state.vehicleEndUserIDList.find(({ id }) => id === state.vehicleEndUserID);
        }
        if (state.vehicleCategoryID) {
            newSt.vehicleCategoryIDValue = state.vehicleCategoryList.find(({ id }) => id === state.vehicleCategoryID);
        }
        if (state.vehicleTypeID) {
            newSt.vehicleTypeIDValue = state.vehicleTypeList.find(({ id }) => id === state.vehicleTypeID);
        }
        if (state.costCentreID) {
            newSt.costCentreIDValue = state.costCentreList.find(({ id }) => id === state.costCentreID);
        }
        if (state.ownershipTypeID) {
            newSt.ownershipTypeIDValue = state.ownershipTypeList.find(({ id }) => id === state.ownershipTypeID);
        }

        if (jobFields.odometerUnitCode) {
            newSt.odometerUnitCodeValue = jobFields.odometerUnitCodeList.find(({ id }) => id === jobFields.odometerUnitCode);
        }

        if (jobFields.cabTypeID) {
            newSt.cabTypeIDValue = jobFields.cabTypeList.find(({ id }) => id === jobFields.cabTypeID);
        }

        if (jobFields.vehicleMaintenanceProviderID) {
            newSt.vehicleMaintenanceProviderIDValue = jobFields.vehicleMaintenanceProviderList.find(({ id }) => id === jobFields.vehicleMaintenanceProviderID);
        }

        if (jobFields.vehicleSuspensionTypeID) {
            newSt.vehicleSuspensionTypeIDValue = jobFields.suspensionList.find(({ id }) => id === jobFields.vehicleSuspensionTypeID);
        }

        if (jobFields.vehicleBodyTypeID) {
            newSt.vehicleBodyTypeIDValue = jobFields.vehicleBodyTypeList.find(({ id }) => id === jobFields.vehicleBodyTypeID);
        }

        if (jobFields.vehicleConfigurationTypeID) {
            newSt.vehicleConfigurationTypeIDValue = jobFields.configurationTypeList.find(({ id }) => id === jobFields.vehicleConfigurationTypeID);
        }
        if (jobFields.vehicleServiceScheduleID) {
            newSt.vehicleServiceScheduleIDValue = jobFields.scheduleEvents.find(({ id }) => id === jobFields.vehicleServiceScheduleID);
        }
        if (jobFields.vehicleDOTScheduleID) {
            newSt.vehicleDOTScheduleIDValue = jobFields.scheduleInspection.find(({ id }) => id === jobFields.vehicleDOTScheduleID);
        }
        if (jobFields.vehicleCalibrationScheduleID) {
            newSt.vehicleCalibrationScheduleIDValue = jobFields.calibrationSchedules.find(({ id }) => id === jobFields.vehicleCalibrationScheduleID);
        }
        if (jobFields.vehicleBrakeTestScheduleID) {
            newSt.vehicleBrakeTestScheduleIDValue = jobFields.calibrationSchedules.find(({ id }) => id === jobFields.vehicleBrakeTestScheduleID);
        }
        if (jobFields.vehicleNextTachoCalibrationScheduleID) {
            newSt.vehicleNextTachoCalibrationScheduleIDValue = jobFields.calibrationSchedules.find(
                ({ id }) => id === jobFields.vehicleNextTachoCalibrationScheduleID
            );
        }
        setTempOptions((st) => ({
            ...st,
            ...newSt
        }));
    }, [
        state.customerID,
        state.makeID,
        state.modelID,
        state.fuelType,
        state.vehiclePrimaryServiceAgentID,
        state.vehicleSecondaryServiceAgentID,
        state.vehicleTechnicianUserID,
        state.vehicleCategoryID,
        state.vehicleTypeID,
        state.costCentreID,
        state.ownershipTypeID,
        state.vehicleEndUserID,
        jobFields.odometerUnitCode,
        jobFields.cabTypeID,
        jobFields.vehicleMaintenanceProviderID,
        jobFields.vehicleSuspensionTypeID,
        jobFields.vehicleBodyTypeID,
        jobFields.vehicleConfigurationTypeID,
        jobFields.vehicleDOTScheduleID,
        jobFields.vehicleCalibrationScheduleID,
        jobFields.vehicleBrakeTestScheduleID,
        jobFields.vehicleLolerTestScheduleID,
        jobFields.vehicleNextTachoCalibrationScheduleID
    ]);

    useEffect(async () => {
        if (tempOptions.vehiclePrimaryServiceAgentIDValue?.id) {
            let res = await getJobEnggById(tempOptions.vehiclePrimaryServiceAgentIDValue?.id);
            if (res.success) {
                let newSt = {};
                if (state.vehicleTechnicianUserID && res.data.list.length > 0) {
                    newSt.vehicleTechnicianUserIDValue = res.data.list.find(({ id }) => id === state.vehicleTechnicianUserID);
                } else {
                    newSt.vehicleTechnicianUserIDValue = null;
                }
                setTempOptions((st) => ({
                    ...st,
                    ...newSt
                }));
                setState((d) => ({ ...d, technician: res.data.list }));
            }
        }
    }, [tempOptions.vehiclePrimaryServiceAgentIDValue?.id]);

    useEffect(() => {
        try {
            if (state.depotsID) {
                let getFilterDepots = state.depotWithCustList.find(({ id }) => id === state.depotsID);
                setState((st) => ({
                    ...st,

                    depotFilterName: getFilterDepots?.name
                }));
            }
        } catch (error) {
            console.worn(error);
        }
    }, [state.depotWithCustList, state.depotsID]);

    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => {
            let newSt = { ...st, [nm]: val };
            // if (nm === 'customerIDValue') {
            //     newSt.depotsIDValue = null;
            // }
            if (nm === 'makeIDValue') {
                newSt.modelIDValue = null;
            }
            if (nm === 'vehicleCategoryIDValue') {
                newSt.vehicleTypeIDValue = null;
            }
            return newSt;
        });
    };

    const handleTabPress = (e) => {
        if (e.keyCode == 9) {
            e.preventDefault();
            e.stopPropagation();
            pullRegBasedData(e.target.value);
        }
    };
    const fetchIdFromList = (list, name) => {
        return name ? find(list, (m) => m.name?.toLowerCase() === name.toLowerCase())?.id : null;
    };
    const pullRegBasedData = async (value) => {
        let res = await getRegBasedData(value.toUpperCase());
        if (res.success) {
            const data = res?.data?.mot;
            if (res?.data?.message) {
                showToastError(res?.data?.message);
            } else {
                setOtherFields((st) => ({
                    ...st,
                    colour: data.colour
                }));
                setDateFields((st) => ({
                    ...st,
                    dateOfReg: formatters.DateFormatter(data.registrationDate),
                    taxDue: formatters.DateFormatter(data.taxDueDate),
                    motExpiry: formatters.DateFormatter(data.motExpiryDate)
                }));
                setState((st) => ({
                    ...st,
                    regNumber: data.registrationNumber,
                    customerID: data.vehicleCustomerId,
                    makeID: formatters.capitalize(data.make),
                    modelID: formatters.capitalize(data.model),
                    wheelPlan: data.wheelplan,
                    capacity: data.engineCapacity,
                    co2Emission: data.co2Emissions,
                    fuelType: fetchIdFromList(st.fuelTypeList, data?.fuelType),
                    vehicleTypeID: fetchIdFromList(st.vehicleTypeList, data?.vehicleType),
                    vehicleCategoryID: fetchIdFromList(st.vehicleCategoryList, data?.vehicleCategory)
                }));
            }
        }
        if (!res.success) {
            showToastError(res.message);
        }
    };

    const dateFieldsChange = (e) => {
        const { name, value } = e.target;
        setDateFields((st) => ({ ...st, [name]: value }));
    };
    const jobFieldsChange = (e) => {
        const { name, value } = e.target;
        setJobFields((st) => ({ ...st, [name]: value }));
    };

    const otherFieldsChange = (e) => {
        const { name, value, checked, type } = e.target;
        if (type == 'checkbox') {
            setOtherFields((st) => ({ ...st, [name]: checked }));
        } else {
            setOtherFields((st) => ({ ...st, [name]: value }));
        }
    };

    const validations = () => {
        const { regNumber } = state;
        const { customerIDValue, depotsIDValue, vehiclePrimaryServiceAgentIDValue, vehicleCategoryIDValue, vehicleTypeIDValue, ownershipTypeIDValue } =
            tempOptions;
        let formIsValid = true;
        let errors = {};
        let errors2 = {};
        let errorMessage = '';
        if (!vehicleTypeIDValue) {
            errors.vehicleTypeID = 'The Vehicle Type field is required';
            errorMessage = errorMessage.concat(errors.vehicleTypeID) + '\n';
            formIsValid = false;
        }
        if (!vehicleCategoryIDValue) {
            errors.vehicleCategoryID = 'The Category field is required';
            errorMessage = errorMessage.concat(errors.vehicleCategoryID) + '\n';
            formIsValid = false;
        }
        if (!state.depotsID) {
            errors.depotsID = 'The depot field is required';
            errorMessage = errorMessage.concat(errors.depotsID) + '\n';
            formIsValid = false;
        }
        if (!customerIDValue && !isCustomer) {
            errors.customerID = 'The Customer field is required';
            errorMessage = errorMessage.concat(errors.customerID) + '\n';
            formIsValid = false;
        }
        if (!regNumber) {
            errors.regNumber = 'Reg Number is required';
            errorMessage = errorMessage.concat(errors.regNumber) + '\n';
            formIsValid = false;
        }
        if (!vehiclePrimaryServiceAgentIDValue) {
            errors.vehiclePrimaryServiceAgentID = 'Service Area is required';
            errorMessage = errorMessage.concat(errors.vehiclePrimaryServiceAgentID) + '\n';
            formIsValid = false;
        }
        if (!ownershipTypeIDValue) {
            errors.ownershipTypeID = 'Owner Ship Type is required';
            errorMessage = errorMessage.concat(errors.ownershipTypeID) + '\n';
            formIsValid = false;
        }
        // if (!dateFields.fleetDate) {
        //     errors2.fleetDate = 'Fleet Date is required';
        //     formIsValid = false;
        // }
        // if (!dateFields.vehicleNextInspectionDate) {
        //     errors2.vehicleNextInspectionDate = 'Next inspection date is required';
        //     formIsValid = false;
        // }
        // if (!dateFields.vehicleNextServiceDateDue) {
        //     errors2.vehicleNextServiceDateDue = 'First service due Date is required';
        //     formIsValid = false;
        // }
        // if (!dateFields.dateOfReg) {
        //     errors2.dateOfReg = 'Date of reg is required';
        //     formIsValid = false;
        // }

        setState((st) => ({ ...st, errors: errors }));
        setOtherFields((st) => ({ ...st, errors: errors2 }));
        if (errorMessage) {
            alert(errorMessage);
        }
        return formIsValid;
    };

    const showRatingModal = (name, value) => {
        setState((st) => ({ ...st, showRating: true, vehicleDirectVisionStandardRating: value }));
    };

    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setJobFields((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };
    const handleClick = (name, value) => {
        setJobFields((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handleSubmit = async () => {
        if (validations()) {
            const formData = {
                VehicleID: state.VehicleID,
                vehicleRegNumber: state.regNumber,
                vehicleCustomerId: isCustomer ? null : tempOptions.customerIDValue?.id,
                vehicleDepotID: state.depotsID,
                vehicleModel: tempOptions.modelIDValue?.id,
                vehicleMake: tempOptions.makeIDValue?.id,
                vehicleWheelPlan: state.wheelPlan,
                vehicleTypeID: tempOptions.vehicleTypeIDValue?.id,
                vehicleCategoryID: tempOptions.vehicleCategoryIDValue?.id,
                vehicleEngineCapacity: state.capacity,
                vehicleCO2Emission: state.co2Emission,
                vehicleChassisNumber: state.chassisNumber,
                vehicleFuelTypeID: tempOptions.fuelTypeValue?.id,
                vehicleCostCentreID: tempOptions.costCentreIDValue?.id,
                vehicleOwnershipTypeID: tempOptions.ownershipTypeIDValue?.id,
                vehiclePrimaryServiceAgentID: tempOptions.vehiclePrimaryServiceAgentIDValue?.id,
                vehicleEndUserID: tempOptions.vehicleEndUserIDValue?.id || null,
                vehicleSecondaryServiceAgentID: tempOptions.vehicleSecondaryServiceAgentIDValue?.id,
                vehicleTechnicianUserID: tempOptions.vehicleTechnicianUserIDValue?.id,
                vehicleDirectVisionStandardRating: state.vehicleDirectVisionStandardRating,
                vehicleServiceScheduleID: tempOptions.vehicleServiceScheduleIDValue?.id,
                vehicleDOTScheduleID: tempOptions.vehicleDOTScheduleIDValue?.id,
                vehicleCalibrationScheduleID: tempOptions.vehicleCalibrationScheduleIDValue?.id,
                vehicleBrakeTestScheduleID: tempOptions.vehicleBrakeTestScheduleIDValue?.id,
                // vehicleLolerTestScheduleID: tempOptions.vehicleBrakeTestScheduleIDValue?.id,
                vehicleNextTachoCalibrationScheduleID: tempOptions.vehicleNextTachoCalibrationScheduleIDValue?.id,
                vehicleAssetNumber: otherFields.assetNumber,
                vehicleFleetNumber: otherFields.fleetNumber,
                vehicleMinistryNumber: otherFields.ministryNumber,
                vehicleDescription: otherFields.description,
                vehicleColour: otherFields.colour,
                vehicleMPG: otherFields.MPG,
                vehicleP11DValue: otherFields.P11DValue,
                vehicleNextServiceMileage: otherFields.vehicleNextServiceMileage,
                vehicleCheckFrequency: otherFields.vehicleCheckFrequency,
                vehicleNoOfAxles: otherFields.vehicleNoOfAxles,
                vehicleChassisWarrantyYears: otherFields.vehicleChassisWarrantyYears,
                vehicleBodyWarrantyYears: otherFields.vehicleBodyWarrantyYears,
                vehicleTracker: otherFields.vehicleTracker,
                vehicleSpeedLimiter: otherFields.vehicleSpeedLimiter,
                vehicleVaisala: otherFields.vehicleVaisala,
                vehicleDistributor: otherFields.vehicleDistributor,
                vehicleCameraSystem: otherFields.vehicleCameraSystem,
                vehicleSheetingSystem: otherFields.vehicleSheetingSystem,
                vehicleControlSystem: otherFields.vehicleControlSystem,
                vehicleRadioCode: jobFields.radioCode,
                vehicleTankCapacity: jobFields.vehicleTankCapacity,
                vehicleTelematicsIMEI: jobFields.vehicleTelematicsIMEI,
                vehicleV5CRegistrationUptoDate: jobFields.vehicleV5CRegistrationUptoDate,
                vehicleJobAdminFee: jobFields.jobAdminFee,
                vehicleOdometerUnitCode: tempOptions.odometerUnitCodeValue?.id,
                vehicleCabTypeID: tempOptions.cabTypeIDValue?.id,
                vehicleSuspensionTypeID: tempOptions.vehicleSuspensionTypeIDValue?.id,
                vehicleBodyTypeID: tempOptions.vehicleBodyTypeIDValue?.id,
                vehicleConfigurationTypeID: tempOptions.vehicleConfigurationTypeIDValue?.id,
                vehicleRFLResponsible: jobFields.vehicleRFLResponsible,
                vehicleMaintenanceRef: jobFields.vehicleMaintenanceRef,
                vehicleHireSupplierID: jobFields.hireSupplierID,
                vehicleHireHistoryID: jobFields.hireHistoryID,
                vehicleMaintenanceProviderID: tempOptions.vehicleMaintenanceProviderIDValue?.id,
                vehicleLatestMileage: jobFields.vehicleLatestMileage,
                vehicleAnnualMileage: jobFields.vehicleAnnualMileage,
                vehicleTaxAmount: jobFields.vehicleTaxAmount,

                vehicleFleetDate: dateFields.fleetDate,
                vehicleNextInspectionDate: dateFields.vehicleNextInspectionDate,
                vehicleNextServiceDateDue: dateFields.vehicleNextServiceDateDue,
                vehicleInsuranceRenewalDate: dateFields.insuranceRenewalDate,
                vehicleEuro4Compliant: jobFields.euro4Compliant,
                vehicleRDE2Compliant: jobFields.RDE2Compliant,
                vehicleShuntVehicle: jobFields.shuntVehicle,
                vehicleRMEndDate: dateFields.vehicleRMEndDate,
                vehicleRMStartDate: dateFields.vehicleRMStartDate,
                vehicleCoveredByFleetInsurance: jobFields.vehicleCoveredByFleetInsurance,
                vehicleIsSORN: jobFields.vehicleIsSORN,
                vehicleDateOfRegistration: dateFields.dateOfReg,
                vehicleMOTExpiry: dateFields.motExpiry,
                vehicleTaxDue: dateFields.taxDue,
                vehicleNextCalibrationDate: dateFields.vehicleNextCalibrationDate,
                vehicleMOTRequired: jobFields.vehicleMOTRequired,
                vehicleNextLolerTestDate: dateFields.vehicleNextLolerTestDate,
                vehicleOnboardWeighingSystem: otherFields.vehicleOnboardWeighingSystem,
                vehicleGrossWeight: otherFields.vehicleGrossWeight,
                vehicleTyreSizeAxle1: otherFields.vehicleTyreSizeAxle1,
                vehicleNextTachoCalibrationDate: dateFields.vehicleNextTachoCalibrationDate
            };
            setBtnLoader(true);
            let res = await addVehicle(formData);
            if (res.success) {
                showToastSuccess(res.message);
                props.onFormSubmit(true);
                !state.VehicleID && history.push(`/controller/editVehicle/${res.data.vehicleID}`);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    const getDepotsValue = (val) => {
        try {
            setState((st) => {
                let newSt = { ...st };
                if (val.id) {
                    newSt.depotsID = val.id;
                    newSt.depotFilterName = val.name;
                } else {
                    newSt.depotsID = val.depotID;
                    newSt.depotFilterName = `${val.depotName} [${val.depotAccountCode || 'N/A'}](${val.depotAddress1}, ${val.depotTown} , ${
                        val.depotPostCode
                    })`;
                }
                return newSt;
            });
        } catch (error) {
            console.log(error);
        }
    };

    const clearVal = () => {
        try {
            setState((st) => {
                let newSt = { ...st };

                newSt.depotsID = null;
                newSt.depotFilterName = '';

                return newSt;
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <VehicleScreenContext.Provider
            value={{
                state,
                handleSubmit,
                inputChange,
                otherFields,
                otherFieldsChange,
                jobFields,
                jobFieldsChange,
                handleCheckbox,
                dateFieldsChange,
                dateFields,
                handleTabPress,
                handleClick,
                pullMileageData,
                getVehicleDataById,
                showRatingModal,
                singleSelectChange,
                tempOptions,
                btnLoader,
                getDepotsValue,
                clearVal
            }}
        >
            {props.children}
        </VehicleScreenContext.Provider>
    );
};
