import React, { useEffect, useMemo, useState } from 'react';
import AddEditModel from './addEditModel';
import DataGrid from '../../../../Core/Controls/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { getAllModels } from '../../../../Core/Services/InternalUser/modelService';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { AddButton, EditButton, BreadCrumbs, formatters } from '../../../../Core/Controls';
import ReactDOM from 'react-dom';
import '../../../commonStyle.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';

const modelgridStyle = {
    gridContainer: { height: '600px' },
    formatterImageContainer: { width: '100%', textAlign: 'center' },
    formatterImage: { width: 90, height: 'auto', backgroundSize: 'cover' },
    formatterEditBtn: { textAlign: 'center' }
};

const renderImage = (data) => {
    const spn = document.createElement('span');
    let isBase64Image = data?.row.modelPhoto?.includes('base64');
    const lnk = data?.row.modelPhoto ? (
        <div style={modelgridStyle.formatterImageContainer}>
            <img
                src={isBase64Image ? data?.row.modelPhoto : 'data:image/png;base64,' + data?.row.modelPhoto}
                alt={'logo'}
                style={modelgridStyle.formatterImage}
            />
        </div>
    ) : (
        <div style={modelgridStyle.formatterImageContainer}>No Image Found</div>
    );

    ReactDOM.render(lnk, spn);
    return spn;
};

const basecolumns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'modelCode', name: 'Code' },
    { key: 'modelDescription', name: 'Description' },
    { key: 'vehicleTypeDescription', name: 'Type' },
    { key: 'makeDescription', name: 'Make' },
    { key: 'modelPhoto', name: 'Logo', formatter: renderImage, filterable: 'no' },
    { key: 'modelWarrantyGroupCode', name: 'Warranty Group' },
    { key: 'modelMargin', name: 'Margin', headerRenderer: formatters.ColumnHeaderAlignRight, formatter: formatters.ColumnTextAlignRight }
];
const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicles', active: true },
    { name: 'Models', active: true }
];

const ModelScreen = () => {
    const [state, setState] = useState({ modalToggle: false, userId: null, List: [], showLoader: true });

    useEffect(async () => {
        pullModel();
    }, []);

    const pullModel = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const renderEdit = (data, row) => {
        return (
            <div style={modelgridStyle.formatterEditBtn}>
                <EditButton onClick={() => handleUpdate(row)} toolTipTitle="Edit Model" />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }

        return tempCols;
    }, []);

    const handleUpdate = (params) => {
        setState((state) => ({ ...state, userId: params?.modelID, modalToggle: true }));
    };

    const closeModelHandler = (res) => {
        setState((state) => ({ ...state, modalToggle: false }));
        res && pullModel();
    };

    const title = state.userId ? 'Update Model' : 'Add Model';

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={'models'}
                btnTitle="Add Model"
                onBtnClick={()=>handleUpdate(null)}
                isReload={state.isReload}
            />

            {state.modalToggle ? (
                <DialogComp title={title} onClose={() => closeModelHandler(false)} maxWidth="lg" overflow="auto">
                    <AddEditModel modelId={state.userId} onClose={() => closeModelHandler(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default ModelScreen;
