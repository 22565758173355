import { postWithAuth, getWithAuth, putWithAuth } from '../../../Basic/HttpService';

export const getCustomerMessages = async (value) => {
    let res = await getWithAuth(`CustomerMessages?CustomerID=${value}`);
    return res;
};

export const getCustomerMessagesList = async () => {
    let res = await getWithAuth(`CustomerMessages/GetCustomerList`);
    return res;
};

export const postMessage = async (data) => {
    let res = await postWithAuth(`CustomerMessages/SendMessage`, data);
    return res;
};

// public class CustomerMessagesVM
// {
// public long? CustomerID { get; set; }
// public string Message { get; set; }
// }

// api/CustomerMessages?CustomerID={value}=> get

// don't pass if customer is null

// api/CustomerMessages/GetCustomerList=> get

// api/CustomerMessages/SendMessage=> post with above object
