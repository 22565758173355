import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { InputText, SecondaryButton } from '../../../../Core/Controls';
import { getConfigIdFromName } from '../../../../Core/Controls/GenericFileUploader/FileUploaderConfig';
import { FileUploaderSendMultiDocs } from '../../../../Core/Services/FileUploaderService';
import { useToast } from '../../../../Core/Hooks/useToast';
import { emailValidation } from '../../../../Core/Basic/StaticService';
import Paper from '@material-ui/core/Paper';
import { useWindowSize } from '../../../../Core/Hooks/useWindowSize';

const useStyles = makeStyles((theme) => ({
    modalHeight: {
        height: `calc(100vh - 330px)`, // Default height calculation
        overflowY: 'auto', // Enable vertical scrolling
        '@media (min-width: 821px) and (max-width: 1024px)': {
            height: `calc(100vh - 650px)` // Adjust height for devices between 821px and 1024px
        },
        '@media (min-width: 770px) and (max-width: 820px)': {
            height: `calc(100vh - 480px)` // Adjust height for devices between 770px and 820px
        },
        '@media (min-width: 768px) and (max-width: 769px)': {
            height: `calc(100vh - 320px)` // Adjust height for devices between 768px and 769px
        },
        '@media (min-width: 712px) and (max-width: 767px)': {
            height: `calc(100vh - 400px)` // Adjust height for devices between 712px and 767px
        }
    }
}));

const consolidateEmails = (data) => {
    const customerMap = {};

    data.forEach(({ customer, customerID, docEmailTo, regNumber, scheduleDocID, event, agent, document }) => {
        const email = docEmailTo;

        if (!customerMap[customer]) {
            customerMap[customer] = {
                customer,
                customerID,
                emails: [], // Array of email objects with email, eventreg, and scheduleDocID
                regNumbers: [], // Array to store unique registration numbers
                events: [], // Array to store unique events
                tableToRender: '' // Table to render as an HTML string
            };
            customerMap[
                customer
            ].tableToRender = `<table border="1"><thead><tr><th>Event</th><th>Document</th><th>Reg Number</th><th>Service Area</th></tr></thead><tbody>`;
        }

        // Add unique registration numbers to regNumbers array
        if (!customerMap[customer].regNumbers.includes(regNumber)) {
            customerMap[customer].regNumbers.push(regNumber);
        }

        // Add unique events to events array
        if (!customerMap[customer].events.includes(event)) {
            customerMap[customer].events.push(event);
        }

        const eventReg = `${event}-${regNumber}`;

        // Check if the email already exists in the customer's emails array
        let emailObj = customerMap[customer].emails.find((e) => e.email === email);

        if (emailObj) {
            if (!emailObj.eventreg.includes(eventReg)) {
                emailObj.eventreg.push(eventReg);
            }

            if (!emailObj.scheduleDocID.includes(scheduleDocID)) {
                emailObj.scheduleDocID.push(scheduleDocID);
            }
        } else {
            customerMap[customer].emails.push({
                email: email,
                eventreg: [eventReg],
                scheduleDocID: [scheduleDocID]
            });
        }

        const tableRow = `<tr><td>${event}</td><td>${document}</td><td>${regNumber}</td><td>${agent}</td></tr>`;

        customerMap[customer].tableToRender += tableRow;
    });

    Object.keys(customerMap).forEach((customer) => {
        customerMap[customer].tableToRender += `</tbody></table>`;
    });

    return Object.values(customerMap);
};
const MailCommonComponent = (props) => {
    const classes = useStyles();
    const { showToastSuccess, showToastError } = useToast();

    let [state, setState] = useState({
        records: [],
        emailErrors: {}
    });
    const [btnLoader, setBtnLoader] = useState(false);

    useEffect(async () => {
        let list = consolidateEmails(props.selectedRecords);
        setState((st) => ({ ...st, records: list }));
    }, []);

    const submitHandler = async () => {
        const updatedErrors = {};
        let hasError = false;

        state.records.forEach((emailObj, recordIndex) => {
            emailObj.emails.forEach((emailItem, customerIndex) => {
                if (!emailValidation(emailItem.email)) {
                    updatedErrors[`${recordIndex}-${customerIndex}`] = 'Email is required';
                    hasError = true;
                }
            });
        });

        if (hasError) {
            setState({ ...state, emailErrors: updatedErrors });
        } else {
            if (props.selectedRecords.length > 0) {
                const SendDocs = state.records.flatMap((m) =>
                    m.emails.map((emailObj) => ({
                        toEmail: emailObj.email,
                        recordIds: emailObj.scheduleDocID,
                        customerID: m.customerID,
                        customer: m.customer,
                        regNumber: m.regNumbers?.join(', '),
                        tableToRender: m.tableToRender.trim()
                    }))
                );
                let data = {
                    SendDocs: SendDocs,
                    configId: getConfigIdFromName(props.fileUploaderConfigName),
                    docIDPassed: props.idFieldName == 'scheduleDocID' ? true : false
                };
                setBtnLoader(true);
                let res = await FileUploaderSendMultiDocs(data);
                if (res.success) {
                    showToastSuccess(res.message);
                    setBtnLoader(false);
                    props.onClose(true);
                } else {
                    showToastError(res.message);
                    setBtnLoader(false);
                }
            } else {
                showToastError('Please select documents to mail');
            }
        }
    };
    const handleEmailChange = (e, recordIndex, customerIndex) => {
        const updatedRecords = [...state.records];
        updatedRecords[recordIndex].emails[customerIndex].email = e.target.value;
        setState({ ...state, records: updatedRecords });
    };
    return (
        <>
            <Grid container spacing={2} style={{ overflow: 'auto' }} className={classes.modalHeight} alignContent="flex-start">
                {state.records.map((emailObj, index) => (
                    <Grid item xs={12} key={index}>
                        <Paper style={{ padding: '8px', border: '1px solid #022205' }}>
                            <Typography variant="h6" color="error">
                                Customer: {emailObj.customer}
                            </Typography>
                            {emailObj.emails.map((m, customerIndex) => (
                                <Grid container>
                                    <Grid item xs={12} key={customerIndex}>
                                        <InputText
                                            multiline
                                            rows={2}
                                            value={m.email}
                                            onChange={(e) => handleEmailChange(e, index, customerIndex)}
                                            placeholder="Enter email"
                                        />
                                        <br />
                                        {state.emailErrors[`${index}-${customerIndex}`] && (
                                            <Typography variant="body2" color="error">
                                                {state.emailErrors[`${index}-${customerIndex}`]}
                                            </Typography>
                                        )}
                                        <span>
                                            Documents: <i>{m.eventreg?.join(', ')}</i>
                                        </span>
                                    </Grid>
                                </Grid>
                            ))}
                        </Paper>
                    </Grid>
                ))}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ marginTop: 10, marginBottom: 5 }}>
                    <SecondaryButton fullWidth isBtnLoader={btnLoader} onClick={submitHandler} disabled={!state.toEmail && !state.subject && btnLoader}>
                        Submit
                    </SecondaryButton>
                </Grid>
            </Grid>
        </>
    );
};
export default MailCommonComponent;
