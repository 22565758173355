import { Button, ButtonGroup, Grid, Switch } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { BreadCrumbs, EditButton, formatters } from '../../../../Core/Controls';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import MaintenanceContractModal from './maintenanceAddEditModal';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { ChipWithoutLable } from '../../../../Core/Controls/Inputs/DatePicker';
import LinkIcon from '@material-ui/icons/Link';
import ReactDOM from 'react-dom';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { GetContractMaintenanceExcel } from '../../../../Core/Services/InternalUser/excelDownload';

var $ = window.$;

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Contracts Maintenance', path: '/', active: true }
];

const baseColumns = [
    // {
    //     key: 'action',
    //     name: 'Action',
    //     width: 80
    //     // addFileUploaderIcon: true
    // },
    { key: 'regNo', name: 'Reg Number', width: 100 },
    { key: 'customer', name: 'Customer', width: '20%' },
    { key: 'depot', name: 'Depot', width: '20%' },

    {
        key: 'startDate',
        name: 'Start',
        formatter: formatters.Date_DDMMYYYY,
        width: 100
    },
    { key: 'endDate', name: 'End', formatter: formatters.Date_DDMMYYYY, width: 100 },
    { key: 'purchaseOrder', name: 'Purchase Order', width: 110 },
    { key: 'rate', name: 'Rate (Annually)', formatter: formatters.NumberWithPound, width: 110 },
    { key: 'serviceAgentName', name: 'Service Area', width: 120 },
    { key: 'tech', name: 'Engineer', width: 120 },
    { key: 'vehicleType', name: 'Vehicle Type', width: 100 }
];

const ContractMaintenanceScreen = (props) => {
    let DummyList = [];
    const { userRoles } = useLoginInfo();
    const isEngineer = userRoles.includes('engineer');
    const isServicearea = userRoles.includes('servicearea');
    const query = new URLSearchParams(props.history.location.search);
    const isFreeStock = query.get('isFreeStock');
    const isContractID = query.get('contractID');
    const serviceAgentName = query.get('agentName') || '';

    const [state, setState] = useState({ selectedRecord: {}, filterFlag: !isFreeStock, buttonState: 1 });
    let checkhaveAccess = AppStorage.getAllowAcces();
    const editActionBtn = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleModal(row)} toolTipTitle="Edit Address" />
            </div>
        );
    };

    const handleEditRegClick = (val) => {
        props.history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: `?redirectLabel=Contract`
        });
    };

    const renderRegNo = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.regNo}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        if (isEngineer) {
            tempCols.splice(
                0,
                tempCols.length,
                { key: 'customer', name: 'Customer', width: '15%' },
                { key: 'depot', name: 'Depot', width: '15%' },
                { key: 'regNo', name: 'Reg Number', width: '12%' },
                { key: 'vehicleType', name: 'Vehicle Type', width: '15%' }
            );
        }
        if (isServicearea) {
            tempCols.splice(
                0,
                tempCols.length,
                { key: 'customer', name: 'Customer', width: 200 },
                { key: 'depot', name: 'Depot', width: 200 },
                { key: 'regNo', name: 'Reg Number', width: 100 },
                { key: 'rate', name: 'Rate (Annually)', formatter: formatters.NumberWithPound, width: 120 },
                { key: 'vehicleType', name: 'Vehicle Type', width: 100 },
                {
                    key: 'startDate',
                    name: 'Start',
                    formatter: formatters.Date_DDMMYYYY,
                    width: 100
                },
                { key: 'tech', name: 'Engineer', width: 150 },
                { key: 'endDate', name: 'End', formatter: formatters.Date_DDMMYYYY, width: 100 }
            );
        }
        let regNoCol = tempCols.find((element) => element.key === 'regNo');
        if (regNoCol) {
            regNoCol.formatter = renderRegNo;
        }
        return tempCols;
    }, []);

    const handleModal = (res) => {
        setState((st) => ({ ...st, showModal: true, selectedRecord: res }));
        // res && pullDepotsAndUpdateState();
    };
    const handleClose = (res, ContractID = '') => {
        setState((st) => ({ ...st, showModal: false }));
        if (res) {
            props.history.push({
                pathname: `/contractList/editcontractList/${ContractID}`,
                search: ``
            });
        }
    };

    const getVal = async (gridRef, recList) => {
        setState((st) => ({
            ...st,
            showModal: true,
            checkedVehicleList: DummyList
        }));
    };

    const mailButton = (gridRef, valueList) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justify="center">
                <ChipWithoutLable icon={LinkIcon} onClick={getVal} style={{ fontWeight: 'bold' }} toolTipTitle={'Link Contract'} />
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleChange = (event) => {
        const { name, checked } = event.target;
        setState((st) => ({ ...st, [name]: checked }));
    };

    const onRowDataBound = (e, $row, id, record, GridRef) => {
        if (DummyList.some((q) => record.vehicleID == q.vehicleID)) {
            GridRef.setSelected(id);
        }
        if (record.contractID) {
            var checkboxCell = $row.find('input[type="checkbox"][data-role="selectRow"]').closest('td');
            checkboxCell.empty().append('<span></span>');
        }
    };

    const onRowSelect = (e, $row, id, record) => {
        let vehicleIndex = DummyList.findIndex((q) => q.vehicleID === record.vehicleID);
        if (vehicleIndex == -1) {
            DummyList.push(record);
        }
    };
    const onRowUnSelect = (e, $row, id, record) => {
        let vehicleIndex = DummyList.findIndex((q) => q.vehicleID === record.vehicleID);
        if (vehicleIndex > -1) {
            DummyList.splice(vehicleIndex, 1);
        }
    };

    let getUrl = `ContractMaintenance/ContractMaintenance_List?FreeStock=${state.filterFlag}${serviceAgentName && `&serviceAgentName=${serviceAgentName}`}`;

    const handleFilter = (cond, val) => {
        setState((st) => ({
            ...st,
            buttonState: val,
            filterFlag: cond
        }));
    };

    return (
        <div className="screen">
            <Grid container>
                {/* <Grid container justify="space-between">
                    <Grid item>
                        <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" />
                    </Grid>
                    <Grid component="label" item className="childSwitchBtnContainer" alignItems="center">
                        <Grid container alignItems="center">
                            <Grid item>Non-contract</Grid>
                            <Grid item>
                                <Switch checked={state.filterFlag} onChange={handleChange} value="checked" name="filterFlag" />
                            </Grid>
                            <Grid item>All</Grid>
                        </Grid>
                    </Grid>
                </Grid> */}
                <Grid container justify="space-between">
                    <Grid item>
                        <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" />
                    </Grid>
                    {!isEngineer && (
                        <Grid component="label" item alignItems="center">
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button
                                    style={{ fontSize: 12 }}
                                    variant={state.buttonState === 1 ? 'contained' : 'outlined'}
                                    onClick={() => handleFilter(true, 1)}
                                >
                                    Contract
                                </Button>
                                <Button
                                    style={{ fontSize: 12 }}
                                    variant={state.buttonState === 2 ? 'contained' : 'outlined'}
                                    onClick={() => handleFilter(false, 2)}
                                >
                                    Non-contract
                                </Button>
                                <Button
                                    style={{ fontSize: 12 }}
                                    variant={state.buttonState === 3 ? 'contained' : 'outlined'}
                                    onClick={() => handleFilter('', 3)}
                                >
                                    All
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    )}
                </Grid>
                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl={getUrl}
                    selectionType={checkhaveAccess.CanAddMaintenanceContracts ? 'multiple' : ''}
                    selectionMethod={checkhaveAccess.CanAddMaintenanceContracts ? 'checkbox' : ''}
                    showCheckboxColFilterBtn={checkhaveAccess.CanAddMaintenanceContracts ? mailButton : ''}
                    onRowDataBound={(e, $row, id, record, GridRef) => onRowDataBound(e, $row, id, record, GridRef)}
                    onRowSelect={(e, $row, id, record) => onRowSelect(e, $row, id, record)}
                    onRowUnSelect={(e, $row, id, record) => onRowUnSelect(e, $row, id, record)}
                    // btnTitle={btnTitle}
                    // onBtnClick={handleAdd}
                    isReload={state.isReload}
                    // idFieldName="serviceAgentID"
                    // fileUploaderConfigName="ServiceAgentScreen"
                    // mapBtnToolTipTitle={mapBtnTitle}
                    // mapBtnIcon={RoomIcon}
                    // mapBtnLabel={mapBtnTitle}
                    // onMapBtnClick={handleShowLocation}
                    downloadBtnName={!isEngineer ? 'Download xlsx' : null}
                    downloadCustomUrl={(params) =>
                        GetContractMaintenanceExcel({
                            FreeStock: state.filterFlag,
                            fileExt: 'xlsx',
                            filterValues: params || {}
                        })
                    }
                />
            </Grid>
            {state.showModal ? (
                <DialogComp title={'Setup Vehicle Contract Maintenance'} onClose={() => handleClose(false)} maxWidth="xl" fullWidth overflow="auto" fullScreen>
                    <MaintenanceContractModal onClose={handleClose} selectedRecords={state.checkedVehicleList || []} contractID={isContractID} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default ContractMaintenanceScreen;
