import { postWithAuth, getWithAuth, putWithAuth } from '../../../Basic/HttpService';

export const getAllDefects = async () => {
    let res = await getWithAuth(`VehicleDefects`);
    return res;
};

export const getAllVehicleDefects = async (id) => {
    let res = await getWithAuth(`VehicleDefects?VehicleID=${id}`);
    return res;
};

export const getAllCustomerDefects = async (id) => {
    let res = await getWithAuth(`VehicleDefects?customerID=${id}`);
    return res;
};

export const getVehicleBreakdownDropdowns = async (id) => {
    let res = await getWithAuth(`VehicleBreakdowns/screenDropDowns?VehicleID=${id}`);
    return res;
};
export const getDefectDropdowns = async () => {
    let res = await getWithAuth(`VehicleBreakdowns/screenDropDowns`);
    return res;
};
export const postDefect = async (data) => {
    let res = await postWithAuth(`VehicleDefects`, data);
    return res;
};

export const getDefectById = async (id) => {
    let res = await getWithAuth(`VehicleDefects/${id}`);
    return res;
};
export const getDefectFaultType = async (custId, startDate, endDate, vehicleCategoryID, makeID) => {
    let res = await getWithAuth(
        `VehicleDefects/VehicleDefectByFaultType?ID=${custId}&startDate=${startDate}&endDate=${endDate}&vehicleCategoryID=${vehicleCategoryID}&makeID=${makeID}`
    );
    return res;
};
export const getBreakDownFaultType = async (custId, startDate, endDate, vehicleCategoryID, makeID) => {
    let res = await getWithAuth(
        `VehicleBreakdowns/VehicleBreakdownByFaultType?ID=${custId}&startDate=${startDate}&endDate=${endDate}&vehicleCategoryID=${vehicleCategoryID}&makeID=${makeID}`
    );
    return res;
};
