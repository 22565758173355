import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Table, TableBody, TableContainer, TableRow, Paper, TableCell, Typography, TableHead, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getDriverHistoryList } from '../../../../../Core/Services/InternalUser/Vehicle/driverInfoService';

export default function DriverHistory(props) {
    const [state, setState] = useState({
        rows: [],
        showLoader: true
    });

    useEffect(async () => {
        if (props.vehicleID) {
            let res = await getDriverHistoryList(props.vehicleID);
            if (res.success) {
                setState((st) => ({
                    ...st,
                    rows: res.data.drivers,
                    showLoader: false
                }));
            } else {
                setState((st) => ({
                    ...st,
                    showLoader: false
                }));
            }
        }
    }, []);

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    {state.showLoader ? (
                        <div style={{ display: 'flex', justifyContent: 'space-around', height: 100 }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <TableContainer style={{ width: '100%', border: '1px solid #e4e4e4', marginBottom: 20 }}>
                            <Table aria-label="simple table" size="large">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Driver</TableCell>
                                        <TableCell>From</TableCell>
                                        <TableCell>To</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {state.rows?.map((row) => {
                                        return (
                                            <TableRow key={row.name}>
                                                <TableCell style={{ color: 'inherit' }}>
                                                    {' '}
                                                    <Link to={`/controller/editdriver/${row.driverID}/${row.driver}`} style={{ textDecoration: 'none' }}>
                                                        {row.driver}
                                                    </Link>
                                                </TableCell>
                                                <TableCell style={{ color: 'inherit' }}>{row.assigned ? row.assigned : 'N/A'}</TableCell>
                                                <TableCell style={{ color: 'inherit' }}>{row.removed ? row.removed : 'N/A'}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            {state.rows?.length <= 0 && <div style={{ display: 'flex', justifyContent: 'space-around', height: 100 }}>No History found</div>}
                        </TableContainer>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}
