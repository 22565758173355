import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DatePicker, InputText, NoButton, SecondaryButton, SelectBox, YesButton, SingleSelect } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';
import '../../../../commonStyle.scss';
import { getAllScheduleEvents, getAllScheduleDropdowns } from '../../../../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';
import { getSchedulesById, postSchedule } from '../../../../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';
import { getJobEnggById } from '../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import { useLoginInfo } from '../../../../../Core/Hooks/useLoginInfo';

const ScheduleAddEdit = (props) => {
    console.log(props, 'props');
    const [state, setState] = useState({
        vehicleID: null,
        scheduleID: null,
        scheduleEventID: props.vehicleServiceScheduleID || '',
        scheduleDateDue: props.vehicleNextServiceDateDue || '',
        scheduleEndDate: '',
        scheduleMilesDue: props.vehicleNextServiceMileage || '',
        scheduleFirstEvent: '',
        dropList: [],
        serviceAgentsList: [],
        scheduleEventName: '',
        scheduleServiceAgentIDValue: null,
        scheduleEngineerIDValue: null
    });

    const [errors, setErrors] = useState({});
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);
    const { userRoles } = useLoginInfo();
    const isServiceArea = userRoles.includes('servicearea');

    const pullDropList = async () => {
        let res = await getAllScheduleDropdowns(props.vehicleId);
        setState((st) => ({
            ...st,
            dropList: res.data.scheduleEvents,
            serviceAgentsList: res.data.serviceAgents,
            engineersList: res.data.engineers
        }));
    };

    const pullSchedule = async () => {
        console.log(props.scheduleId);
        if (props.scheduleId) {
            let res = await getSchedulesById(props.scheduleId);
            console.log(res, 'res');
            let temp = res.data[0];
            setState((st) => ({
                ...st,
                scheduleID: temp.scheduleID || null,
                vehicleID: temp.vehicleID || '',
                scheduleEventID: temp.scheduleEventID || '',
                scheduleDateDue: temp.scheduleDateDue || '',
                scheduleMilesDue: temp.scheduleMilesDue || '',
                scheduleFirstEvent: temp.scheduleFirstEvent || '',
                scheduleServiceAgentID: temp.scheduleServiceAgentID || '',
                scheduleEngineerID: temp.scheduleEngineerID || '',
                scheduleEventName: temp.scheduleEventName,
                scheduleEndDate: temp.scheduleEndDate || ''
            }));
        }
    };
    useEffect(() => {
        pullDropList();
        if (!props.isAdd) {
            pullSchedule();
        }
    }, []);

    useEffect(() => {
        let newSt = {};

        if (state.scheduleServiceAgentID && state.serviceAgentsList.length > 0) {
            newSt.scheduleServiceAgentIDValue = state.serviceAgentsList.find(({ id }) => id === state.scheduleServiceAgentID);
        }

        setState((st) => ({
            ...st,
            ...newSt
        }));
    }, [state.scheduleServiceAgentID, state.serviceAgentsList]);

    useEffect(async () => {
        if (state.scheduleServiceAgentIDValue?.id) {
            let res = await getJobEnggById(state.scheduleServiceAgentIDValue?.id);
            if (res.success) {
                let newSt = {};
                if (state.scheduleEngineerID && res.data.list.length > 0) {
                    newSt.scheduleEngineerIDValue = res.data.list.find(({ id }) => id === state.scheduleEngineerID);
                }
                setState((d) => ({ ...d, ...newSt, engineersList: res.data.list }));
            }
        }
    }, [state.scheduleServiceAgentIDValue?.id]);
    const validate = () => {
        let noErrors = true;
        let err = {};
        if (!/[^\s]/.test(state.scheduleEventID)) {
            err.scheduleEventID = true;
            noErrors = false;
        }
        if (!state.scheduleServiceAgentIDValue?.id) {
            err.scheduleServiceAgentID = true;
            noErrors = false;
        }
        if (isServiceArea && !state.scheduleEngineerIDValue?.id) {
            err.scheduleEngineerID = true;
            noErrors = false;
        }
        if (!/[^\s]/.test(state.scheduleDateDue)) {
            err.scheduleDateDue = true;
            noErrors = false;
        }

        let temp = state.scheduleMilesDue;

        if (+temp < 0) {
            err.scheduleMilesDueN = true;
            noErrors = false;
        }
        setErrors(err);
        return noErrors;
    };

    const handleData = async () => {
        if (validate()) {
            try {
                setBtnLoader(true);
                let response = await postSchedule({
                    scheduleID: state.scheduleID || null,
                    vehicleID: state.vehicleID || props.vehicleId,
                    scheduleEventID: state.scheduleEventID || null,
                    scheduleDateDue: state.scheduleDateDue || '',
                    scheduleMilesDue: +state.scheduleMilesDue || null,
                    // scheduleFirstEvent: state.scheduleFirstEvent || false,
                    scheduleServiceAgentID: state.scheduleServiceAgentIDValue?.id,
                    scheduleEngineerID: state.scheduleEngineerIDValue?.id,
                    scheduleEndDate: state.scheduleEndDate
                });
                if (response) {
                    response.success ? showToastSuccess('Vehicle Schedule updated successfully') : showToastError(response.message || 'Something went wrong');
                    setBtnLoader(false);
                } else {
                    showToastError('Something went wrong');
                    setBtnLoader(false);
                }
                props.onClose();
            } catch (error) {
                setBtnLoader(false);
                showToastError(`An error occurred: ${error.message}`);
            }
        }
    };
    const valueChanged = (ev) => {
        let temp = ev.target;
        setState((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
        setErrors({});
    };

    const singleSelectChange = (nm) => (ev, val) => {
        let newSt = {};
        if (nm == 'scheduleServiceAgentIDValue') {
            newSt.scheduleEngineerIDValue = {};
        }
        newSt[nm] = val;
        setState((st) => ({ ...st, ...newSt }));
    };

    const yesNoClicked = (ev) => {
        if (ev.target.innerText == 'YES') {
            setState((st) => ({
                ...st,
                scheduleFirstEvent: true
            }));
        } else {
            setState((st) => ({
                ...st,
                scheduleFirstEvent: false
            }));
        }
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} className="mandatory-fields">
                    <InputLabel shrink>Schedule Event</InputLabel>
                    <InputText value={state.scheduleEventName || ''} disabled />
                </Grid>
                <Grid item xs={12} sm={6} className="mandatory-fields">
                    <InputLabel shrink>Date Due</InputLabel>
                    <DatePicker name="scheduleDateDue" value={moment(state.scheduleDateDue).format('YYYY-MM-DD') || ''} onChange={valueChanged} />
                    <FormHelperText error>{errors.scheduleDateDue ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel shrink>Miles Due</InputLabel>
                    <InputText
                        type="number"
                        name="scheduleMilesDue"
                        placeholder="Miles Due"
                        defaultValue={state.scheduleMilesDue || ''}
                        value={state.scheduleMilesDue || ''}
                        onChange={valueChanged}
                    />
                    <FormHelperText error>{errors.scheduleMilesDueN ? 'Negative value not allowed' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} className="mandatory-fields">
                    <InputLabel shrink>Service Area</InputLabel>
                    <SingleSelect
                        options={state.serviceAgentsList}
                        value={state.scheduleServiceAgentIDValue}
                        onChange={singleSelectChange('scheduleServiceAgentIDValue')}
                    />
                    <FormHelperText error>{errors.scheduleServiceAgentID ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} className={isServiceArea ? 'mandatory-fields' : null}>
                    <InputLabel shrink>Engineer</InputLabel>
                    {/* <SelectBox
                        name="scheduleServiceAgentID"
                        value={state.scheduleServiceAgentID || ''}
                        onChange={valueChanged}
                        List={state.serviceAgentsList || []}
                    /> */}
                    <SingleSelect
                        options={state.engineersList}
                        value={state.scheduleEngineerIDValue}
                        onChange={singleSelectChange('scheduleEngineerIDValue')}
                    />
                    <FormHelperText error>{errors.scheduleEngineerID ? 'This field is required' : null}</FormHelperText>
                </Grid>

                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton isBtnLoader={btnLoader} onClick={handleData}>
                        {'SAVE'}
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
                </Grid>
            </Grid>
        </>
    );
};
export default ScheduleAddEdit;
