import React, { useState, useEffect, useMemo } from 'react';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import PersonIcon from '@material-ui/icons/Person';
import { FormHelperText, Grid, InputLabel, Typography } from '@material-ui/core';
import { DatePicker, InputText, MandatoryField, SecondaryButton } from '../../../../Core/Controls';
import { postDriverCheckOut } from '../../../../Core/Services/InternalUser/Vehicle/driverInfoService';
import { useToast } from '../../../../Core/Hooks/useToast';

const VehicleCheckOut = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        isReload: null
    });
    const [error, setError] = useState({});

    useEffect(async () => {}, []);

    const inputChange = (event) => {
        const { name, value, checked } = event.target;

        setState((state) => ({
            ...state,
            [name]: value
        }));
    };

    const validate = () => {
        let errors = {};
        let isValid = true;
        if (!state.date) {
            errors.date = 'Date Change is required';
            isValid = false;
        }

        setError(errors);
        return isValid;
    };

    const handelSubmit = async () => {
        if (validate()) {
            let data = {
                vehicleDriverHistoryID: props.data.vehicleDriverHistoryID,
                vehicleID: props.data.vehicleID,
                dateCheckOut: state.date,
                mileageCheckOut: state.mileage
            };

            let submutRes = await postDriverCheckOut(data);
            if (submutRes.success) {
                showToastSuccess('Vehicle Check Out Successfully');
                props.onClose(true);
            } else {
                showToastError(submutRes.message);
            }
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <InputLabel required shrink error>
                    Date of Change
                </InputLabel>
                <DatePicker inputLabel="Date Of Change" placeholder="Date of change" name="date" value={state.date} onChange={inputChange} />
                <FormHelperText error>{error.date}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputLabel shrink>Mileage of Change</InputLabel>
                <InputText multiline placeholder="Odometer Reading" value={state.mileage} onChange={inputChange} name="mileage" type="number" />
                {/* <FormHelperText error>{state.errors.DefectSignOffNotes}</FormHelperText> */}
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <SecondaryButton onClick={handelSubmit}>Check Out</SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default VehicleCheckOut;
