import { Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { AppButtonGroup, DataGrid, InputPassword, InputText, TabView, formatters, Accordion, PrimaryCheckbox, SecondaryCheckbox } from '../../../Core/Controls';
import FileUploaderButton from '../../../Core/Controls/GenericFileUploader';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { useToast } from '../../../Core/Hooks/useToast';

const columns = [
    {
        key: 'action',
        name: 'Action',
        width: 130,
        formatter: (params) => <CustomIconButton icon={Edit} toolTipTitle="Edit Row" />,
        addFileUploaderIcon: true
    },
    {
        key: 'id',
        name: 'ID',
        width: 130,
        formatter({ row }) {
            return <>ID value is: {row.id}</>;
        }
    },
    { key: 'title', name: 'Title' },
    { key: 'count', name: 'Count' },
    { key: 'isActive', name: 'Is Active ?', formatter: formatters.YesNo },
    { key: 'dueDate', name: 'Due Date', formatter: formatters.Date_DDMMYYYY }
];

const dummyData = [
    { id: 0, title: 'row1', count: 20, isActive: true, dueDate: '2021-01-07' },
    { id: 1, title: 'row1', count: 40, isActive: false, dueDate: '2020-01-11' },
    { id: 2, title: 'row1', count: 60, isActive: false, dueDate: '2020-01-11' },
    { id: 0, title: 'row2', count: 20, isActive: false, dueDate: '2020-01-11' },
    { id: 1, title: 'row2', count: 40, isActive: false, dueDate: '2020-01-11' },
    { id: 2, title: 'row2', count: 60, isActive: true, dueDate: '2021-01-08' },
    { id: 0, title: 'row3', count: 20, isActive: true, dueDate: '2021-01-09' },
    { id: 1, title: 'row3', count: 40, isActive: false, dueDate: '2020-01-11' },
    { id: 2, title: 'row3', count: 60, isActive: true, dueDate: '2021-01-08' },
    { id: 0, title: 'row4', count: 20, isActive: true, dueDate: '2021-01-3' },
    { id: 1, title: 'row4', count: 40, isActive: false, dueDate: '2020-01-11' },
    { id: 2, title: 'row4', count: 60, isActive: false, dueDate: '2020-01-11' }
];

// SimpleAccordion component //
const Notes = () => {
    return <h5> From Notes</h5>;
};
const Dashboard = () => {
    return <h5> From Dashboard</h5>;
};
const List = [
    {
        header: 'Dashboard',
        accordianBody: Dashboard()
    },
    {
        header: 'Notes',
        accordianBody: Notes()
    }
];

const options = [
    { id: 1, name: 'Button1' },
    { id: 2, name: 'Button2' },
    { id: 3, name: 'Button3' }
];

const HelpComponent = (props) => {
    const [state, setState] = useState({ gridRow: [], showLoader: true, selectedBtn: null });
    useEffect(() => {
        setTimeout(() => {
            setState((st) => ({
                ...st,
                showLoader: false,
                gridRow: dummyData
            }));
        }, 1000);
    }, []);

    const Tab1 = () => {
        return <h5> From Tab1</h5>;
    };
    const Tab2 = () => {
        return <h5> From Tab2</h5>;
    };
    const tabs = [
        {
            label: 'Tab1',
            body: Tab1()
        },
        {
            label: 'Tab2',
            body: Tab2()
        }
    ];
    const onBtnSelected = (o) => {
        setState((dt) => ({
            ...dt,
            ...o
        }));
    };

    return (
        <div style={{ padding: 8 }}>
            <Accordion header={'Text Box & Password'} color="primary">
                <Grid xs={12}>
                    <Grid item>
                        Text Box: <InputText label="Name" />
                    </Grid>
                    <Grid item>
                        Password field: <InputPassword fullWidth label="Password" />
                    </Grid>
                </Grid>
            </Accordion>
            <Accordion header={'Checkbox Primary, Secondary & forceEditable'} color="secondary">
                <Grid>
                    <Grid item>
                        Primary Checkbox: <PrimaryCheckbox />
                    </Grid>
                    <Grid item>
                        Secondary Checkbox with Force Editable: <SecondaryCheckbox forceEditable={true} />
                    </Grid>
                </Grid>
            </Accordion>
            <Accordion header={'Grid, File Uploader inside Grid, File Uploader Separate Button'}>
                <Grid xs={12}>
                    <Grid item>
                        Separate Button for Edit Page (button in edit with ID = 1, second record in grid)
                        <br />
                        <FileUploaderButton recordId={1} fileUploaderConfigName="UserScreen" fileCount={99} />
                    </Grid>
                    <Grid item>
                        <DataGrid
                            idFieldName="id" // as from row 'id' is the property name contains actual id of record
                            fileUploaderConfigName="UserScreen"
                            rows={state.gridRow}
                            columns={columns}
                            height={250}
                            searchBoxClassName="data-grid-search-box"
                            enableSearch={true}
                            loadingData={state.showLoader}
                            paginate //to add pagination to the datagrid
                            //offset={210} "provide offset as 210 as displayed for a full screen grid when you are adding pagination"
                            //rowsPerPage={5} "Default is 10"
                        />
                    </Grid>
                </Grid>
            </Accordion>

            <Accordion header={'Tab Component'}>
                <TabView tabList={tabs} id="testing_tabs" />
            </Accordion>
            <Accordion header={'Button Component'}>
                {state.selectedBtn}
                <AppButtonGroup
                    name="selectedBtn"
                    onChange={onBtnSelected}
                    value={state.selectedBtn}
                    options={options}
                    valuePropertyName="id"
                    textPropertyName="name"
                />
            </Accordion>
        </div>
    );
};

export default HelpComponent;
