import { Grid, TableBody, TableContainer, TableRow, Table, TableHead, Checkbox, withStyles, TableCell, Tooltip, DialogTitle } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { DeleteHolidayRecodrs, ServiceAreaHolidays_ByDate } from '../../../Core/Services/ServiceAgent/Holiday.js';
import { useToast } from '../../../Core/Hooks/useToast.js';
import moment from 'moment';
import { ChipWithoutLable } from '../../../Core/Controls/Inputs/DatePicker.jsx';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationModal from '../../../Core/Modal/ConfirmationModal.jsx';
import DialogComp from '../../../Core/Dialog/DialogComp.jsx';
import { EditButton, formatters, SecondaryButton } from '../../../Core/Controls/index.jsx';
import AddUpdateHoliday from './AddUpdateHoliday.jsx';
import { AppStorage } from '../../../Core/Basic/storage-service.js';

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 16,
        padding: '2px 8px',
        color: '#f50057'
    },
    body: {
        fontSize: 14,
        padding: '2px 8px'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        height: '40px',
        '&:nth-of-type(odd)': {
            backgroundColor: '#F5F5F5'
        }
    }
}))(TableRow);
const ViewHoliday = ({ selectedDate, serviceAreaID, onClose }) => {
    const [state, setState] = useState({
        errors: {},
        deLinkList: [],
        list: [],
        modalData: {}
    });
    const { showToastSuccess, showToastError } = useToast();
    let UserHasAccess = AppStorage.getAllowAcces().CanAddUpdateHolidays;

    const CallApiBydate = async () => {
        let res = await ServiceAreaHolidays_ByDate(selectedDate);
        if (res.success) {
            setState((d) => ({ ...d, list: res.data.list }));
        }
    };
    useEffect(async () => {
        if (selectedDate) {
            CallApiBydate();
        }
    }, [selectedDate]);

    const handleClick = (e, row) => {
        const { checked } = e.target;

        setState((st) => {
            let deLinkList = [...st.deLinkList];
            let list = [...st.list];

            const vehicleIndex = deLinkList.findIndex((q) => row.userHolidayRecordID === q.userHolidayRecordID);
            const selectedVehicleIndex = list.findIndex((q) => row.userHolidayRecordID === q.userHolidayRecordID);
            if (selectedVehicleIndex !== -1) {
                list[selectedVehicleIndex].isSelected = checked;
            }

            if (checked && vehicleIndex === -1) {
                deLinkList.push(row);
            } else if (!checked && vehicleIndex !== -1) {
                deLinkList.splice(vehicleIndex, 1);
            }

            return { ...st, deLinkList, list };
        });
    };
    const closeConfirmationModal = (res) => {
        setState((st) => ({
            ...st,
            showConfirm: false
        }));
    };
    const deLinkContractVehicleHandler = async () => {
        let data = {
            Ids: state.deLinkList.map((q) => q.userHolidayRecordID)
        };
        let res = await DeleteHolidayRecodrs(data);
        if (res.success) {
            closeConfirmationModal();
            onClose(true);
            showToastSuccess(res?.data?.message);
        } else {
            showToastError(res?.message);
        }
    };
    const confirmationModalHandler = (res) => {
        setState((st) => ({
            ...st,
            showConfirm: true
        }));
    };
    const handelAddEdit = (data) => {
        setState((st) => ({ ...st, modalData: data, showEditModal: true }));
    };
    const closeEditMdal = (res) => {
        setState((st) => ({ ...st, showEditModal: false, modalData: null }));
        res && CallApiBydate();
        res && onClose(true);
    };
    const RenderTitle = () => {
        return (
            <Grid item xs={6} md={5} container spacing={1}>
                <Grid item>
                    <DialogTitle style={{ padding: 0 }}>View Holiday</DialogTitle>
                </Grid>
                {UserHasAccess && (
                    <Grid item>
                        <SecondaryButton onClick={() => handelAddEdit(null)}>Add Holiday</SecondaryButton>{' '}
                    </Grid>
                )}
            </Grid>
        );
    };
    return (
        <DialogComp onClose={onClose} title={RenderTitle()} maxWidth="md" fullWidth>
            <Grid container spacing={2} style={{ paddingBottom: 6 }}>
                <TableContainer
                    style={{
                        border: '1px solid rgba(0,0,0,0.2)',
                        height: 'calc(100vh - 455px)'
                    }}
                    className="custom-scroll"
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell width={20}>
                                    <Grid container style={{ marginLeft: '5px' }}>
                                        {state.deLinkList.length > 0 && UserHasAccess && (
                                            <ChipWithoutLable
                                                icon={DeleteIcon}
                                                onClick={confirmationModalHandler}
                                                style={{ fontWeight: 'bold' }}
                                                toolTipTitle={'Delete Selected Records'}
                                            />
                                        )}
                                    </Grid>
                                </StyledTableCell>
                                <StyledTableCell></StyledTableCell>

                                <StyledTableCell>User</StyledTableCell>
                                <StyledTableCell>Start Date</StyledTableCell>
                                <StyledTableCell>End Date</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {state.list.map((m) => {
                                return (
                                    <StyledTableRow>
                                        <StyledTableCell>
                                            {UserHasAccess && (
                                                <Tooltip title={'Delete'} arrow>
                                                    <Checkbox
                                                        title="Delete"
                                                        name={'isSelected'}
                                                        onChange={(e) => handleClick(e, m)}
                                                        checked={m.isSelected ? true : false}
                                                    />
                                                </Tooltip>
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {UserHasAccess && <EditButton style={{ marginTop: 5 }} onClick={() => handelAddEdit(m)} toolTipTitle="Edit" />}
                                        </StyledTableCell>
                                        <StyledTableCell style={{ cursor: 'pointer', fontWeight: 600, marginTop: 5 }}>{m.userName}</StyledTableCell>
                                        <StyledTableCell style={{ marginTop: 5 }}>{formatters.DateOnlyFormatter(m.holidayStartDate)}</StyledTableCell>
                                        <StyledTableCell style={{ marginTop: 5 }}>{formatters.DateOnlyFormatter(m.holidayEndDate)}</StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {state.showConfirm ? (
                    <DialogComp title={`Confirmation`} onClose={() => closeConfirmationModal(false)} maxWidth="md" fullWidth>
                        <ConfirmationModal
                            textAlign="center"
                            message={`Are you sure you want to delete holiday records?`}
                            handleSubmit={() => deLinkContractVehicleHandler(false)}
                            handleCancel={() => closeConfirmationModal(false)}
                        />
                    </DialogComp>
                ) : null}
                {state.showEditModal ? (
                    <DialogComp title={state.modalData?.userHolidayRecordID ? `Update` : 'Add'} onClose={() => closeEditMdal(false)} maxWidth="md" fullWidth>
                        <AddUpdateHoliday selectedDate={selectedDate} serviceAreaID={serviceAreaID} userData={state.modalData} onClose={closeEditMdal} />
                    </DialogComp>
                ) : null}
            </Grid>
        </DialogComp>
    );
};

export default ViewHoliday;
