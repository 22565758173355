import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import SendIcon from '@material-ui/icons/Send';
import SelectAll from '@material-ui/icons/SelectAll';
import { DashWidgetsProvider as StateProvider, DashboardContext } from './DashboardProvider';
// import CloseIcon from '@material-ui/icons/Close';
// import { Link } from 'react-router-dom';
// import { Opacity } from '@material-ui/icons';
// import { useHistory } from 'react-router-dom';
// import BrushIcon from '@material-ui/icons/Brush';
import DialogComp from '../../../Core/Dialog/DialogComp';
import { CardContent, Grid, Tooltip } from '@material-ui/core';
import SetWidgets from './widgetSetting';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        border: '2px solid rgb(90 114 123 / 8%) ',
        Opacity: 0.9,
        minWidth: 345,
        borderRadius: 15,
        boxShadow: 'rgb(90 114 123 / 11%) 0px 7px 30px 0px'
    },

    avatar: {
        height: 40,
        width: 40
    }
}));

const gridStyle = {
    1: 3,
    2: 6,
    3: 12
};

const DashboardCard = (props) => {
    const classes = useStyles();
    const { hover, handleHover, onChange, handleTheme, state, open } = useContext(DashboardContext);
    const { list } = props;
    let checkCond = list.length > 3 ? true : false;

    let Bgcolor = state.themes.find((m) => m.id === props.themeID);

    return (
        <Card className={classes.root}>
            <Typography variant="h6" style={{ background: Bgcolor?.backgroundColour, color: Bgcolor?.textColour, padding: 7, textAlign: 'center' }}>
                {props.heading}
                &nbsp;&nbsp;
                <Tooltip title={'Change Background'}>
                    <SelectAll onClick={handleTheme(props.heading)} style={{ cursor: 'pointer', height: 15, marginLeft: -10 }} size="samll" />
                </Tooltip>
            </Typography>

            <Grid container style={{ background: Bgcolor?.backgroundColour }} justify="space-between">
                {list?.map((m, i) => {
                    let Icon = m?.Icon;
                    let gs = gridStyle[props.gridstyleID];
                    let Total = m?.total?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                    return (
                        <Tooltip title={m.button ? '' : m.subTittle} arrow key={`card${i}`}>
                            <Grid item xs={gs == 12 ? 12 : 6} md={gs} xl={gs}>
                                <div
                                    onMouseEnter={handleHover(true, m.subTittle, props.themeID)}
                                    onMouseLeave={handleHover(false, m.subTittle, props.themeID)}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        padding: '10px',
                                        border: '1px solid rgb(90 114 123 / 4%)',
                                        background: hover?.id === m?.subTittle ? hover?.backgroundColorHover : Bgcolor?.backgroundColour,
                                        color: hover?.id === m?.subTittle ? hover?.color : Bgcolor?.textColour
                                    }}
                                >
                                    <div style={{ width: '6%' }}></div>
                                    <div style={{ width: '16%', marginTop: 5 }}>
                                        <Avatar
                                            aria-label="recipe"
                                            className={classes.avatar}
                                            style={{ backgroundColor: m?.background, boxShadow: ` 0px 0px 0px 8px ${m?.showDowBackground}` }}
                                        >
                                            {m?.numberIcon ? <span style={{ fontSize: 10 }}>{m?.numberIcon}%</span> : <Icon />}
                                        </Avatar>
                                    </div>
                                    &nbsp;&nbsp; &nbsp;
                                    <div
                                        style={{
                                            marginLeft: 10,
                                            width: '80%',
                                            cursor: 'pointer',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            width: '50%',
                                            overflow: 'hidden',
                                            paddingBottom: m.button ? 17 : 10
                                        }}
                                        onClick={() => onChange(m?.path)}
                                    >
                                        {m?.total ? (
                                            <div style={{ fontSize: 23, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>
                                                {' '}
                                                {`£${Total.toLocaleString('en-US')}`}
                                                <span style={{ fontSize: 17, fontFamily: `"Public Sans", sans-serif`, marginLeft: 4 }}>({m?.title})</span>
                                            </div>
                                        ) : m.button ? (
                                            <div style={{ fontSize: 20, fontWeight: 550, fontFamily: `"Public Sans", sans-serif` }}>{m?.title}</div>
                                        ) : (
                                            <div style={{ fontSize: 26, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>{m?.title}</div>
                                        )}
                                        <span style={{ fontSize: 14, marginBottom: 10, fontFamily: `"Public Sans", sans-serif` }}> {m?.subTittle}</span>
                                    </div>
                                </div>
                            </Grid>
                        </Tooltip>
                    );
                })}
            </Grid>

            {open ? (
                <DialogComp onClose={handleTheme(props.heading)} title="Settings" maxWidth="md">
                    <SetWidgets
                        heading={props.heading}
                        widgetID={props.widgetID}
                        userWidgetID={props.userWidgetID}
                        pullDashboardData={props.pullDashboardData}
                        onClose={handleTheme(props.heading)}
                    />
                </DialogComp>
            ) : null}
        </Card>
    );
};

const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <DashboardCard {...props} />
        </StateProvider>
    );
};
export default Screen;
