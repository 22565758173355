import { Button, ButtonGroup, Grid, Hidden, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { SecondaryButton, SelectBox, SingleSelect } from '../../../../../../Core/Controls';
import { getJobEnggById } from '../../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import { postAssignEngineer, postJobWorkStatus } from '../../../../../../Core/Services/InternalUser/ControllerService/jobStepperService';
import { useToast } from '../../../../../../Core/Hooks/useToast';

let List = [
    { id: 'AP', name: 'Awaiting Parts' },
    { id: 'ES', name: 'Submitted for Estimate' },
    { id: 'EA', name: 'Estimate Approved' },
    { id: 'WP', name: 'Work In Progress' },
    { id: 'JP', name: 'Paused' }
    // { id: 'FI', name: 'Factory Inspecton' }
];

const JobsWorkInProgress = ({ handleNext, jobDetails, isDisabled }) => {
    const { showToastWarning, showToastError, showToastSuccess } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);
    const [state, setState] = useState({
        currentJobStatus: '',
        filterTechnician: [],
        jobAgentID: jobDetails.jobServiceAgentID,
        jobEngineerUserID: jobDetails.jobEngineerUserID || ''
    });

    useEffect(() => {
        let index = List.findIndex((k) => k.id === jobDetails.jobStatusID);
        setState((st) => {
            const nst = { ...st };
            if (index > -1) {
                nst['currentJobStatus'] = List[index].id;
            } else {
                nst['currentJobStatus'] = '';
            }
            return nst;
        });
    }, []);
    let PullEngineerList = async (serviceAreaID) => {
        if (serviceAreaID) {
            let res = await getJobEnggById(serviceAreaID);
            if (res.success) {
                setState((d) => ({ ...d, filterTechnician: res.data.list }));
            }
        }
    };

    useEffect(() => {
        if (state.jobAgentID) {
            try {
                PullEngineerList(state.jobAgentID);
            } catch (error) {
                console.log(error);
            }
        }
    }, [state.jobAgentID]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((st) => {
            const nst = { ...st };
            nst[name] = value;
            return nst;
        });
    };
    const handleBtn = (j) => {
        setState((st) => {
            const nst = { ...st };
            nst['currentJobStatus'] = j.id;
            return nst;
        });
    };

    const handleSubmit = async () => {
        setBtnLoader(true);
        let data = {
            jobID: jobDetails.jobID,
            currentJobStatus: state.currentJobStatus,
            jobEngineerUserID: state.jobEngineerUserID
        };
        let res = await postJobWorkStatus(data);
        if (res.success) {
            showToastSuccess(res.data.message);
            handleNext();
            setBtnLoader(false);
        } else {
            showToastError(res.message);
        }
        setBtnLoader(false);
    };

    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                <Hidden only={['xs', 'sm']}>
                    <Grid item xs={12}>
                        <InputLabel shrink> Status </InputLabel>
                        <ButtonGroup size="small" color="primary" disabled={!isDisabled}>
                            {List.map((j, i) => {
                                return (
                                    <Button
                                        key={i}
                                        variant={state.currentJobStatus === j.id ? 'contained' : 'outlined'}
                                        color={'primary'}
                                        style={{ fontSize: 12 }}
                                        onClick={() => {
                                            handleBtn(j);
                                        }}
                                    >
                                        {j.name}
                                    </Button>
                                );
                            })}
                        </ButtonGroup>
                    </Grid>
                </Hidden>

                <Hidden only={['md', 'xl', 'lg']}>
                    <Grid item xs={12}>
                        <InputLabel shrink>Status </InputLabel>
                        <SelectBox
                            List={List || []}
                            name="currentJobStatus"
                            value={state.currentJobStatus}
                            onChange={handleInputChange}
                            disabled={!isDisabled}
                        />
                    </Grid>
                </Hidden>

                <Grid item xs={12}>
                    <InputLabel shrink>Engineer </InputLabel>
                    <SelectBox
                        List={state.filterTechnician || []}
                        name="jobEngineerUserID"
                        value={state.jobEngineerUserID}
                        onChange={handleInputChange}
                        disabled={!isDisabled}
                    />
                </Grid>
                {isDisabled && (
                    <Grid item xs={12}>
                        <SecondaryButton onClick={handleSubmit} isBtnLoader={btnLoader}>
                            Submit
                        </SecondaryButton>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default JobsWorkInProgress;
