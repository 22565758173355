import { createContext, useCallback, useEffect, useState } from 'react';
import { useToast } from '../../../../Core/Hooks/useToast';
import { getDefectById, getVehicleBreakdownDropdowns, postDefect, getDefectDropdowns } from '../../../../Core/Services/InternalUser/Maintenance/DefectsService';
import { useHistory } from 'react-router-dom';
import { formatters } from '../../../../Core/Controls';
import { getAllNotes } from '../../../../Core/Services/InternalUser/ControllerService/noteService';

export const DefectsScreenContext = createContext();

const trimmedHost = window.location.host.includes('localhost') ? 'localhost' : window.location.host;

export const DefectsProvider = (props) => {
    let history = useHistory();
    // const { DefectID, VehicleID } = props?.match?.params ;
    let DefectID = null;
    let VehicleID = null;

    if (props?.match?.params.DefectID) {
        DefectID = props.match.params.DefectID;
    }
    if (props?.match?.params.VehicleID) {
        VehicleID = props.match.params.VehicleID;
    }
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);

    const [defectFields, setDefectFields] = useState({
        errors: {},
        customerName: '',
        depotName: '',
        vehicleLoadTypesList: [],
        depotsList: [],
        vehicleID: null,
        defectTypeCode: 'DD',
        defectNumberGenerated: '',
        defectRegNumber: '',
        defectID: '',
        defectVehicleID: '',
        defectReportingDepotID: '',
        defectLocation: '',
        defectCustomerDefectNumber: '',
        defectMileage: '',
        defectContactName: '',
        defectContactNumber: '',
        defectDriverName: '',
        defectDriverTelephone: '',
        defectDescription: '',
        preferredServiceAgent: '',
        defectAuthorisedBy: '',
        defectNumberOfPassengers: '',
        defectInsuranceReference: '',
        domainName: '',
        defectVehicleLoadTypeID: 1,
        defectPG9Issued: '',
        vehicleJobProgress: [],
        vorVehicleID: null,
        isRefresh: '',
        isReload: true,
        faultTypes: [],
        defectFaultTypes: []
    });

    const [selectedData, setSelectedData] = useState({
        modules: []
    });

    const [userSettings, setUserSettings] = useState({
        defectAlreadyBooked: false,
        defectAlreadyCompleted: false,
        defectCallOutRequired: false,
        defectUrgentCallOut: false,
        defectVOR: false,
        defectPassengersInvolved: false,
        defectAccidentDamage: false,
        defectReplacementVehicle: false,
        defectVehicleLoaded: false,
        defectPG9Issued: false,
        defectPoliceOnScene: false,
        defectMobilityRequested: false,
        defectMobilityUsed: false,
        defectAdvisedDontDrive: false
    });

    useEffect(async () => {
        if (props.VehicleID || VehicleID) {
            let res1 = await getVehicleBreakdownDropdowns(props.VehicleID || VehicleID);
            setDefectFields((st) => ({
                ...st,
                vehicleLoadTypesList: res1.data.vehicleLoadTypes,
                depotsList: res1.data.depots,
                vehicleID: props.VehicleID || VehicleID,
                jobProgressStatusList: res1.data.jobProgressStatus,
                faultTypes: res1.data.faultTypes
            }));
        }

        if (props.defectID || DefectID) {
            pullDefectByID();
            // pullNotes();
        }
    }, []);

    useEffect(() => {
        pullNotes();
    }, [defectFields.isRefresh]);

    const pullNotes = async () => {
        if (props.NoteVehicleID || props.NoteDriverID || props.NoteServiceAgID || props.customerID || props.JobID || props.defectID || DefectID) {
            let res = await getAllNotes(
                'Defects',
                props.NoteVehicleID,
                props.NoteDriverID,
                props.NoteServiceAgID,
                props.customerID,
                props.JobID,
                props.defectID || DefectID
            );
            if (res.success) {
                setDefectFields((st) => ({ ...st, NoteList: res.data.list }));
            }
            if (!res.success) {
                showToastError(res.message);
            }
        }
    };

    const pullDefectByID = async () => {
        let res = await getDefectById(props.defectID || DefectID);
        if (res.success) {
            const data = res.data.details[0];
            let vehicleJobProgressDates = res.data.vehicleJobProgressDates;
            if (data) {
                setDefectFields((defectFields) => ({
                    ...defectFields,
                    defectNumberGenerated: data.defectNumberGenerated,
                    defectRegNumber: data.defectRegNumber,
                    defectID: data.defectID,
                    defectTypeCode: data.defectTypeCode,
                    defectVehicleID: data.defectVehicleID,
                    defectReportingDepotID: data.defectReportingDepotID,
                    defectLocation: data.defectLocation,
                    defectCustomerDefectNumber: data.defectCustomerDefectNumber,
                    defectContactName: data.defectContactName,
                    defectContactNumber: data.defectContactNumber,
                    defectDriverName: data.defectDriverName,
                    defectDriverTelephone: data.defectDriverTelephone,
                    defectDescription: data.defectDescription,
                    preferredServiceAgent: data.preferredServiceAgent,
                    defectAuthorisedBy: data.defectAuthorisedBy,
                    defectNumberOfPassengers: data.defectNumberOfPassengers,
                    defectInsuranceReference: data.defectInsuranceReference,
                    domainName: data.domainName,
                    defectVehicleLoadTypeID: data.defectVehicleLoadTypeID,
                    defectPoliceOnScene: data.defectPoliceOnScene,
                    defectAdvisedDontDrive: data.defectAdvisedDontDrive,
                    defectMobilityRequested: data.defectMobilityRequested,
                    defectMobilityUsed: data.defectMobilityUsed,
                    defectResolvedNotes: data.defectResolvedNotes,
                    defectResolvedSignature: data.defectResolvedSignature,
                    vorVehicleID: data.vorVehicleID,
                    model: data.model,
                    make: data.make,
                    customer: data.customer,
                    reportingDepot: data.reportingDepot,
                    defectMileage: data.defectMileage,
                    defectPreferredServiceAgent: data.defectPreferredServiceAgent,
                    homeDepotID: data.homeDepotID,
                    customerID: data.customerID,
                    defectFaultTypes: res.data.faultIds,
                    //
                    vehicleJobProgress: vehicleJobProgressDates,
                    defectDateCreated: formatters.DateTimeFormatter(data.defectDateCreated),
                    defectDateCallOutArrived: formatters.DateTimeFormatter(data.defectDateCallOutArrived),
                    defectDateVehicleRecovered: formatters.DateTimeFormatter(data.defectDateVehicleRecovered),
                    defectDateResolved: formatters.DateTimeFormatter(data.defectDateResolved),
                    defectDateSignOff: formatters.DateTimeFormatter(data.defectDateSignOff),
                    fileCount: data.fileCount,
                    isRefresh: new Date(),
                    isReload: false
                }));
                setUserSettings((st) => ({
                    ...st,
                    defectAlreadyBooked: data.defectAlreadyBooked,
                    defectAlreadyCompleted: data.defectAlreadyCompleted,
                    defectCallOutRequired: data.defectCallOutRequired,
                    defectUrgentCallOut: data.defectUrgentCallOut,
                    defectVOR: data.defectVOR,
                    defectPassengersInvolved: data.defectPassengersInvolved,
                    defectAccidentDamage: data.defectAccidentDamage,
                    defectReplacementVehicle: data.defectReplacementVehicle,
                    defectVehicleLoaded: data.defectVehicleLoaded,
                    defectPG9Issued: data.defectPG9Issued,
                    vehicleLoadTypeDescription: data.vehicleLoadTypeDescription
                }));
            } else {
                setDefectFields((defectFields) => ({
                    ...defectFields,
                    isReload: false
                }));
            }
        }
    };

    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setUserSettings((st) => ({ ...st, [name]: true }));
        } else if (value === 'false' && type.toLowerCase() === 'radio') {
            setUserSettings((st) => ({ ...st, [name]: false }));
        } else {
            setDefectFields((st) => ({ ...st, [name]: value }));
        }
    }, []);

    const singleSelectChange = (nm) => (ev, val) => {
        setDefectFields((st) => ({ ...st, [nm]: val }));
    };

    const selectHandler = useCallback((e) => {
        const { name, checked } = e.target;
        setDefectFields((defectFields) => ({ ...defectFields, [name]: checked }));
    }, []);

    const handleClick = useCallback((name, value) => {
        setDefectFields((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    }, []);

    useEffect(() => {
        if (defectFields.defectFaultTypes) {
            const selectedFaultTypes = defectFields.faultTypes.filter((w) => defectFields.defectFaultTypes.indexOf(w.id) > -1);
            setSelectedData((st) => ({
                ...st,
                modules: selectedFaultTypes
            }));
        }
    }, [defectFields.defectFaultTypes, defectFields.faultTypes]);

    const ModuleChange = (e, value) => {
        setSelectedData((st) => ({ ...st, modules: value }));
        setDefectFields((st) => {
            const nst = { ...st, defectFaultTypes: value.map((c) => c.id) };
            return nst;
        });
    };

    const validations = () => {
        const {
            defectReportingDepotID,
            defectLocation,
            defectCustomerDefectNumber,
            defectContactName,
            defectContactNumber,
            defectDriverName,
            defectDriverTelephone,
            defectDescription,
            defectAuthorisedBy,
            defectVehicleLoadTypeID,
            defectReportingDepotIDValue
        } = defectFields;
        let formIsValid = true;
        let errors = {};
        if (!defectReportingDepotIDValue) {
            errors.defectReportingDepotID = 'This field is required';
            formIsValid = false;
        }
        if (!defectLocation) {
            errors.defectLocation = 'This field is required';
            formIsValid = false;
        }
        // if (!defectCustomerDefectNumber) {
        //     errors.defectCustomerDefectNumber = 'This field is required';
        //     formIsValid = false;
        // }
        if (!defectContactName) {
            errors.defectContactName = 'This field is required';
            formIsValid = false;
        }
        if (!defectContactNumber) {
            errors.defectContactNumber = 'This field is required';
            formIsValid = false;
        }
        // if (!defectDriverName) {
        //     errors.defectDriverName = 'This field is required';
        //     formIsValid = false;
        // }
        // if (!defectDriverTelephone) {
        //     errors.defectDriverTelephone = 'This field is required';
        //     formIsValid = false;
        // }
        if (!defectDescription) {
            errors.defectDescription = 'This field is required';
            formIsValid = false;
        }

        if (!defectAuthorisedBy) {
            errors.defectAuthorisedBy = 'This field is required';
            formIsValid = false;
        }
        if (!defectVehicleLoadTypeID) {
            errors.defectVehicleLoadTypeID = 'This field is required';
            formIsValid = false;
        }
        setDefectFields((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const FormData = {
                defectID: defectFields.defectID,
                defectTypeCode: defectFields.defectTypeCode,
                defectVehicleID: defectFields.vehicleID,
                defectReportingDepotID: defectFields.defectReportingDepotIDValue?.id,
                defectLocation: defectFields.defectLocation,
                defectCustomerDefectNumber: defectFields.defectCustomerDefectNumber,
                defectContactName: defectFields.defectContactName,
                defectContactNumber: defectFields.defectContactNumber,
                defectDriverName: defectFields.defectDriverName,
                defectDriverTelephone: defectFields.defectDriverTelephone,
                defectDescription: defectFields.defectDescription,
                defectAuthorisedBy: defectFields.defectAuthorisedBy,
                preferredServiceAgent: defectFields.preferredServiceAgent,
                defectAlreadyBooked: userSettings.defectAlreadyBooked,
                defectAlreadyCompleted: userSettings.defectAlreadyCompleted,
                defectCallOutRequired: userSettings.defectCallOutRequired,
                defectUrgentCallOut: userSettings.defectUrgentCallOut,
                defectVOR: userSettings.defectVOR,
                defectPassengersInvolved: userSettings.defectPassengersInvolved,
                defectNumberOfPassengers: defectFields.defectNumberOfPassengers,
                defectAccidentDamage: userSettings.defectAccidentDamage,
                defectInsuranceReference: defectFields.defectInsuranceReference,
                domainName: trimmedHost,
                defectReplacementVehicle: userSettings.defectReplacementVehicle,
                defectVehicleLoaded: userSettings.defectVehicleLoaded,
                defectVehicleLoadTypeID: defectFields.defectVehicleLoadTypeIDValue?.id,
                defectPG9Issued: userSettings.defectPG9Issued,
                defectPoliceOnScene: userSettings.defectPoliceOnScene,
                defectAdvisedDontDrive: userSettings.defectAdvisedDontDrive,
                defectMobilityRequested: userSettings.defectMobilityRequested,
                defectMobilityUsed: userSettings.defectMobilityUsed,
                defectMileage: defectFields.defectMileage,
                faultIds: selectedData.modules.map(({ id }) => id)
            };
            setBtnLoader(true);
            let res = await postDefect(FormData);
            if (res.success) {
                showToastSuccess(`Defect number ${res.data.defectNumber} generated successfully`);
                history.push(`/maintenance/defect/edit/${res.data.defectID}/${props.VehicleID}`);
                // props.onClose(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    return (
        <DefectsScreenContext.Provider
            value={{
                defectFields,
                userSettings,
                submitHandler,
                inputChange,
                selectHandler,
                handleClick,
                pullDefectByID,
                pullNotes,
                singleSelectChange,
                setDefectFields,
                btnLoader,
                DefectID,
                VehicleID,
                ModuleChange,
                selectedData
            }}
        >
            {props.children}
        </DefectsScreenContext.Provider>
    );
};
