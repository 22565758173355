import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormHelperText, Card, Typography, ButtonGroup, Button } from '@material-ui/core';
import { SingleSelect, DatePicker, formatters, BreadCrumbs, SecondaryButton } from '../../../Core/Controls';
import { getVehicleScreenDropdowns } from '../../../Core/Services/InternalUser/ControllerService/vehicleService';
import '../Controllers/controllerStyle.scss';
import { getVORVehiclesByType } from '../../../Core/Services/InternalUser/ControllerService/customerService';
import moment from 'moment';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { makeStyles } from '@material-ui/styles';
import './vorReporting.scss';
import RenderReporting from './RenderReportingDesign';
import { RenderReportingByFilter } from './RenderReportingDesign';
import { ReportingBarChart } from '../../../Core/Controls/Charts/index';
import { GetVORReportingExcelFile } from '../../../Core/Services/InternalUser/excelDownload';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import AddVor from '../Controllers/VOR/AddEditVor';
import DialogComp from '../../../Core/Dialog/DialogComp';
import FaultTypes from './../Controllers/Customers/FaultTypes/index';
import { getJobEnggById } from '../../../Core/Services/InternalUser/ControllerService/jobDetailsService';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'VOR Reporting', active: true }
];
const useStyles = makeStyles((theme) => ({
    root: {
        border: '2px solid #f5f5f5',
        Opacity: 0.9,
        minWidth: 120,
        borderRadius: 10,
        boxShadow: 'none',
        background: '#8080800d',
        height: '100%',
        width: '100%'
    }
}));

const baseColumns = [
    // { key: 'action', name: 'Action', width: 100 },
    { key: 'vehicleRegNumber', name: 'Reg No', width: 120 },
    { key: 'vorVehicleDateStarted', name: 'Start Date', width: '13%', formatter: formatters.Date_DDMMMYYYY_HHMM },
    { key: 'vorVehicleDateEnded', name: 'End Date', width: '13%', formatter: formatters.Date_DDMMMYYYY_HHMM },
    { key: 'vorDays', name: 'VOR Days', width: '20%' },
    { key: 'vorTypeDescription', name: 'Type', width: '10%' },
    { key: 'vorTypeDetailDescription', name: 'Reason' },
    { key: 'vehicleCategoryDescription', name: 'Sub Category', width: '10%' },
    { key: 'depotName', name: 'Depot', width: '10%' }
];

let startDate = moment().startOf('month').format('YYYY-MM-DD');
let endDate = moment().format('YYYY-MM-DD');
function VorReportingModal(props) {
    let history = useHistory();
    const [state, setState] = useState({
        customerList: [],
        errors: {},
        depotWithCustList: [],
        depotsList: [],
        customerID: +props?.match?.params.id || props.customerID || null,
        depotsID: null,
        startDate: startDate,
        endDate: endDate,
        VehicleByType: [],
        VorList: [],
        NumberOfDepot: [],
        sumOfAllDepot: 0,
        setTop5ID: 1,
        setBarData: 4,
        top5Days: 1,
        setBarVal: 1,
        set_type: 6,
        show: false,
        vehicleCategoryList: [],
        vehicleDepotsList: [],
        byReg: [],
        depot: [],
        faultType: [],
        make: [],
        model: [],
        reason: [],
        type: [],
        TypeIsReload: null,
        reasonList: [],
        FaultTypes: [],
        makeList: [],
        vehicleCategoryListdropdown: [],
        filterTechnician: []
    });

    const handleEditRegClick = (val, cond) => {
        switch (cond) {
            case 'Vehicles':
                history.push({
                    pathname: `/controller/vor`,
                    state: { vehicleRegNumber: val.reg },
                    startDate: state.startDate,
                    endDate: state.endDate,
                    customerID: val.customer
                });
                break;
            case 'Models':
                history.push({
                    pathname: `/controller/vor`,
                    // state: { customerCompanyName: val.customer, vehicleRegNumber: val.reg },
                    startDate: state.startDate,
                    endDate: state.endDate,
                    customerID: val.customer,
                    model: val.model
                });
                break;
            case 'Makes':
                history.push({
                    pathname: `/controller/vor`,
                    // state: { customerCompanyName: val.customer, vehicleRegNumber: val.reg },
                    startDate: state.startDate,
                    endDate: state.endDate,
                    customerID: val.customer,
                    make: val.make
                });
                break;
            case 'Category':
                history.push({
                    pathname: `/controller/vor`,
                    state: { vorTypeDescription: val.vorType },
                    startDate: state.startDate,
                    endDate: state.endDate,
                    customerID: val.customer
                });
                break;
            case 'Depots':
                history.push({
                    pathname: `/controller/vor`,
                    state: { depotName: val.name },
                    startDate: state.startDate,
                    endDate: state.endDate,
                    customerID: val.customer
                });
                break;
            case 'reg':
                history.push({
                    pathname: `/controller/vor`,
                    state: val,
                    startDate: state.startDate,
                    endDate: state.endDate
                });
                break;
        }
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => closeVorModal(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.vehicleRegNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const classes = useStyles();

    const handleReports = (val) => {
        setState((st) => ({ ...st, top5Days: val }));
    };

    const handleBarChart = (val) => {
        setState((st) => ({ ...st, setBarVal: val }));
    };

    const [tempOptions, setTempOptions] = useState({
        customerIDValue: {},
        depotsIDValue: {},
        vehicleCategoryIDValue: {},
        makeIDValue: {}
    });

    const inputChange = useCallback((e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    }, []);

    let getURl = () => {
        return `VORVehicles?startDate=${state.startDate}&endDate=${state.endDate}&CustomerId=${tempOptions.customerIDValue?.id || ''}&vehicleCategoryID=${
            tempOptions.vehicleCategoryIDValue?.id || ''
        }&makeID=${tempOptions.makeIDValue?.id || ''}&ServiceAgentID=${tempOptions.serviceAreaIDValue?.id || ''}&TechnicianUserID=${
            tempOptions.engineerIDValue?.id || ''
        }&DepotID=${tempOptions.deoptIDValue?.id || ''}`;
    };

    const columns = useMemo(() => {
        let newColumn = [...baseColumns];
        let actionCol = newColumn.find((item) => item.key === 'vehicleRegNumber');
        if (actionCol) {
            actionCol.formatter = renderRegNumberEdit;
        }
        return newColumn;
    }, []);

    const title = state.navigationMenuID ? 'Update Menu Items' : 'Add Menu Items';

    useEffect(async () => {
        let res = await getVehicleScreenDropdowns();
        setState((st) => ({
            ...st,
            customerList: res.data.customers,
            depotsList: res.data.depots || [],
            makeList: res.data.makes || [],
            vehicleCategoryListdropdown: res.data.vehicleCategories || [],
            technician: res.data.technician || [],
            serviceAgents: res.data.serviceAgents || []
        }));
    }, []);

    useEffect(() => {
        let newSt = {};
        if (state.customerID && state.customerList?.length > 0) {
            newSt.customerIDValue = state.customerList.find(({ id }) => id === +state.customerID);
        }
        // else {
        //     newSt.customerIDValue = state.customerList[0];
        // }
        setTempOptions((st) => ({
            ...st,
            ...newSt
        }));
    }, [state.customerID, state.customerList]);

    //bar Data Days/Occurrence handler

    useEffect(() => {
        let vehicleCategoryList = [];
        let vehicleDepotsList = [];
        let reasonList = [];
        let FaultTypes = [];
        switch (state.setBarVal) {
            case 1:
                vehicleCategoryList =
                    state.type &&
                    state.type.map((m) => ({
                        ...m,
                        name: m.vorType,
                        ['Total Days']: m.totalDays,
                        customer: tempOptions?.customerIDValue?.id
                    }));
                vehicleDepotsList =
                    state.depot &&
                    state.depot.map((m) => ({
                        ...m,
                        name: m.depot,
                        ['Total Days']: m.totalDays,
                        customer: tempOptions?.customerIDValue?.id
                    }));
                reasonList =
                    state.reason &&
                    state.reason.map((m) => ({
                        ...m,
                        name: m.vorTypeDetailDescription,
                        ['Total Days']: m.totalDays,
                        customer: tempOptions?.customerIDValue?.id
                    }));
                FaultTypes =
                    state.faultType &&
                    state.faultType.map((m) => ({
                        ...m,
                        name: m.faultTypeDescription,
                        ['Total Days']: m.totalDays,
                        customer: tempOptions?.customerIDValue?.id
                    }));
                break;
            case 2:
                vehicleCategoryList =
                    state.type &&
                    state.type.map((m) => ({
                        ...m,
                        name: m.vorType,
                        ['Total Days']: m.total,
                        customer: tempOptions?.customerIDValue?.id
                    }));
                vehicleDepotsList =
                    state.depot &&
                    state.depot.map((m) => ({
                        ...m,
                        name: m.depot,
                        ['Total Days']: m.total,
                        customer: tempOptions?.customerIDValue?.id
                    }));
                reasonList =
                    state.reason &&
                    state.reason.map((m) => ({
                        ...m,
                        name: m.vorTypeDetailDescription,
                        ['Total Days']: m.total,
                        customer: tempOptions?.customerIDValue?.id
                    }));
                FaultTypes =
                    state.faultType &&
                    state.faultType.map((m) => ({
                        ...m,
                        name: m.faultTypeDescription,
                        ['Total Days']: m.total,
                        customer: tempOptions?.customerIDValue?.id
                    }));
                break;
        }
        setState((st) => ({
            ...st,
            vehicleCategoryList: vehicleCategoryList,
            vehicleDepotsList: vehicleDepotsList,
            reasonList: reasonList,
            FaultTypes: FaultTypes
        }));
    }, [state.setBarVal, state.TypeIsReload]);

    //Top 5 Days/Occurrence handler

    useEffect(() => {
        let top5Reg = [];
        let top5Makes = [];
        let top5models = [];
        switch (state.top5Days) {
            case 1:
                top5Reg = state?.byReg && state?.byReg.map((m) => ({ ...m, val: m.reg, sum: m.totalDays, customer: tempOptions?.customerIDValue?.id }));
                top5Makes = state?.make && state?.make?.map((m) => ({ ...m, val: m.make, sum: m.totalDays, customer: tempOptions?.customerIDValue?.id }));
                top5models = state?.model && state?.model?.map((m) => ({ ...m, val: m.model, sum: m.totalDays, customer: tempOptions?.customerIDValue?.id }));
                break;

            case 2:
                top5Reg = state?.byReg && state?.byReg.map((m) => ({ ...m, val: m.reg, sum: m.total, customer: tempOptions?.customerIDValue?.id }));
                top5Makes = state?.make && state?.make?.map((m) => ({ ...m, val: m.make, sum: m.total, customer: tempOptions?.customerIDValue?.id }));
                top5models = state?.model && state?.model?.map((m) => ({ ...m, val: m.model, sum: m.total, customer: tempOptions?.customerIDValue?.id }));

                break;
        }
        setState((st) => ({ ...st, top5Reg: top5Reg, top5Makes: top5Makes, top5models: top5models }));
    }, [state.top5Days, state.TypeIsReload]);

    useEffect(async () => {
        // if (state.customerList?.length > 0) {
        let res = await getVORVehiclesByType(
            tempOptions.customerIDValue?.id || '',
            state.startDate,
            state.endDate,
            tempOptions.vehicleCategoryIDValue?.id || '',
            tempOptions.makeIDValue?.id || '',
            tempOptions.serviceAreaIDValue?.id || '',
            tempOptions.engineerIDValue?.id || '',
            tempOptions.deoptIDValue?.id || ''
        );
        let newSt = {};
        let data = res?.data;
        if (res.success) {
            newSt.TypeIsReload = new Date();
            newSt.byReg = data.byReg;
            newSt.depot = data.depot;
            newSt.faultType = data.faultType;
            newSt.make = data.make;
            newSt.model = data.model;
            newSt.reason = data.reason;
            newSt.type = data.type;
        }
        setState((st) => ({
            ...st,
            ...newSt,
            url: getURl()
        }));
        // }
    }, [
        state.startDate,
        state.endDate,
        tempOptions.customerIDValue,
        tempOptions.makeIDValue,
        tempOptions.vehicleCategoryIDValue,
        tempOptions.serviceAreaIDValue,
        tempOptions.engineerIDValue,
        tempOptions.deoptIDValue
    ]);

    const singleSelectChange = (nm) => (ev, val) => {
        if (nm === 'serviceAreaIDValue' && val?.id) PullEngineerList(val?.id);
        if (val) {
            setTempOptions((st) => {
                let newSt = { ...st, [nm]: val };
                if (nm === 'customerIDValue') {
                    newSt.depotsIDValue = null;
                }
                return newSt;
            });
        } else {
            setTempOptions((st) => ({ ...st, [nm]: null }));
        }
    };

    let PullEngineerList = async (serviceAreaID) => {
        if (serviceAreaID) {
            let res = await getJobEnggById(serviceAreaID);
            if (res.success) {
                setState((d) => ({ ...d, filterTechnician: res.data.list }));
            }
        }
    };

    useEffect(() => {
        const uniquescheduleEventNames = [...new Set(state.VorList.map((item) => item.depotName))];
        let Depot = uniquescheduleEventNames.map((m) => {
            let a = state.VorList.filter(function (val) {
                return val.depotName === m;
            }).length;
            return { name: m || 'No Depot Found', ['Total Days']: a, customer: tempOptions.customerIDValue.name };
        });
        let sumOfAllDepot = 0;
        let totalCount = [...Depot].forEach(function (item) {
            sumOfAllDepot += item.total;
        });
        setState((st) => ({ ...st, NumberOfDepot: Depot, sumOfAllDepot: sumOfAllDepot }));
    }, [state.VorList]);

    const convertData = (res) => {
        setState((st) => ({ ...st, VorList: res.data.list }));
        return {
            records: res.data.list,
            total: res.data.total
        };
    };
    const handleStatus = (val) => {
        setState((st) => ({ ...st, setTop5ID: val }));
    };

    const handleBar = (val) => {
        setState((st) => ({ ...st, setBarData: val }));
    };

    let settop5List = {
        1: state.top5Reg,
        2: state.top5models,
        3: state.top5Makes,
        4: state.vehicleCategoryList,
        5: state.vehicleDepotsList,
        6: state.reasonList,
        7: state.FaultTypes
    };
    let label = {
        1: 'Vehicles',
        2: 'Models',
        3: 'Makes',
        4: 'By Category',
        5: 'By Depot',
        6: 'By Reason',
        7: 'By Fault Type'
    };

    const closeVorModal = (val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.show = !st.show;
            newSt.vorVehicleID = val.vorVehicleID || '';
            newSt.VehicleID = val.vorVehicleVehicleID || '';
            newSt.regNo = val.vehicleRegNumber;
            return newSt;
        });
    };

    console.log(tempOptions, '<<====temp');

    return (
        <div className={`vorReporting ${props.fromTab ? '' : 'screen'}`}>
            {props.fromTab ? '' : <BreadCrumbs crumbs={crumbs} />}
            <Grid container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                        <InputLabel required shrink>
                            Customer
                        </InputLabel>
                        <SingleSelect
                            options={state.customerList}
                            value={tempOptions.customerIDValue}
                            onChange={singleSelectChange('customerIDValue')}
                            disabled={props.customerID}
                        />
                        {/* <FormHelperText error>{state.errors.customerID}</FormHelperText> */}
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                        <InputLabel required shrink>
                            Depot
                        </InputLabel>
                        <SingleSelect
                            options={state.depotsList}
                            value={tempOptions.deoptIDValue}
                            onChange={singleSelectChange('deoptIDValue')}
                            disabled={props.customerID}
                        />
                        {/* <FormHelperText error>{state.errors.customerID}</FormHelperText> */}
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                        <InputLabel required shrink>
                            Service Area
                        </InputLabel>
                        <SingleSelect
                            options={state.serviceAgents}
                            value={tempOptions.serviceAreaIDValue}
                            onChange={singleSelectChange('serviceAreaIDValue')}
                            disabled={props.customerID}
                        />
                        {/* <FormHelperText error>{state.errors.customerID}</FormHelperText> */}
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                        <InputLabel required shrink>
                            Engineer
                        </InputLabel>
                        <SingleSelect
                            options={state.filterTechnician}
                            value={tempOptions.engineerIDValue}
                            onChange={singleSelectChange('engineerIDValue')}
                            disabled={props.customerID}
                        />
                        {/* <FormHelperText error>{state.errors.customerID}</FormHelperText> */}
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                        <InputLabel required shrink>
                            Vehicle Category
                        </InputLabel>
                        <SingleSelect
                            options={state.vehicleCategoryListdropdown}
                            value={tempOptions.vehicleCategoryIDValue}
                            onChange={singleSelectChange('vehicleCategoryIDValue')}
                        />
                        {/* <FormHelperText error>{state.errors.vehicleCategoryID}</FormHelperText> */}
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                        <InputLabel shrink>Make</InputLabel>
                        <SingleSelect options={state.makeList} value={tempOptions.makeIDValue} onChange={singleSelectChange('makeIDValue')} />
                    </Grid>
                    <Grid item xs={12} md={4} sm={4} lg={3} xl={3}>
                        <InputLabel shrink>Start Date</InputLabel>
                        <DatePicker value={state.startDate} onChange={inputChange} name="startDate" />
                    </Grid>
                    <Grid item xs={12} md={4} sm={4} lg={3} xl={3}>
                        <InputLabel shrink>End Date</InputLabel>
                        <DatePicker value={state.endDate} onChange={inputChange} name="endDate" />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: -2, marginBottom: -2 }}>
                    <Grid item xs={12}>
                        <Card className={classes.root}>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={6} lg={10}>
                                    <div style={{ width: '100%', textAlign: 'left', color: '#f50057', fontWeight: 600, margin: '10px 20px' }}>
                                        Top 5 {label[state.setTop5ID]}&nbsp;&nbsp;
                                        <ButtonGroup color="secondary" ria-label="outlined primary button group" size="small">
                                            <Button
                                                variant={state.top5Days === 1 ? 'contained' : 'outlined'}
                                                onClick={() => handleReports(1)}
                                                style={{ fontSize: 10 }}
                                            >
                                                Days
                                            </Button>
                                            <Button
                                                variant={state.top5Days === 2 ? 'contained' : 'outlined'}
                                                onClick={() => handleReports(2)}
                                                style={{ fontSize: 10 }}
                                            >
                                                Occurrence
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={6} md={6} lg={2}>
                                    <div style={{ marginRight: 20, marginTop: 10, float: 'right' }}>
                                        <ButtonGroup color="primary" aria-label="outlined primary button group" size="small">
                                            <Button
                                                variant={state.setTop5ID === 1 ? 'contained' : 'outlined'}
                                                onClick={() => handleStatus(1)}
                                                style={{ fontSize: 10 }}
                                            >
                                                Vehicles
                                            </Button>
                                            <Button
                                                variant={state.setTop5ID === 2 ? 'contained' : 'outlined'}
                                                onClick={() => handleStatus(2)}
                                                style={{ fontSize: 10 }}
                                            >
                                                Models
                                            </Button>
                                            <Button
                                                variant={state.setTop5ID === 3 ? 'contained' : 'outlined'}
                                                onClick={() => handleStatus(3)}
                                                style={{ fontSize: 10 }}
                                            >
                                                {' '}
                                                Makes
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                    {/*  */}
                                </Grid>
                            </Grid>

                            <RenderReportingByFilter
                                list={settop5List[state.setTop5ID]}
                                noRow={`No VOR found by ${label[state.setTop5ID]}`}
                                handleEditRegClick={handleEditRegClick}
                                label={label[state.setTop5ID]}
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} lg={12}>
                        <Card className={classes.root}>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <div style={{ width: '100%', textAlign: 'left', color: '#f50057', fontWeight: 600, margin: '10px 20px' }}>
                                        {label[state.setBarData]}
                                        &nbsp;&nbsp;
                                        <ButtonGroup color="secondary" aria-label="outlined primary button group" size="small">
                                            <Button
                                                variant={state.setBarVal === 1 ? 'contained' : 'outlined'}
                                                onClick={() => handleBarChart(1)}
                                                style={{ fontSize: 10 }}
                                            >
                                                Days
                                            </Button>
                                            <Button
                                                variant={state.setBarVal === 2 ? 'contained' : 'outlined'}
                                                onClick={() => handleBarChart(2)}
                                                style={{ fontSize: 10 }}
                                            >
                                                Occurrence
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <div style={{ marginRight: 20, marginTop: 10, float: 'right' }}>
                                        <ButtonGroup color="primary" aria-label="outlined primary button group" size="small">
                                            <Button
                                                variant={state.setBarData === 4 ? 'contained' : 'outlined'}
                                                onClick={() => handleBar(4)}
                                                style={{ fontSize: 10 }}
                                            >
                                                By Category
                                            </Button>
                                            <Button
                                                variant={state.setBarData === 5 ? 'contained' : 'outlined'}
                                                onClick={() => handleBar(5)}
                                                style={{ fontSize: 10 }}
                                            >
                                                {' '}
                                                By Depot
                                            </Button>
                                            <Button
                                                variant={state.setBarData === 6 ? 'contained' : 'outlined'}
                                                onClick={() => handleBar(6)}
                                                style={{ fontSize: 10 }}
                                            >
                                                By Reason
                                            </Button>
                                            <Button
                                                variant={state.setBarData === 7 ? 'contained' : 'outlined'}
                                                onClick={() => handleBar(7)}
                                                style={{ fontSize: 10 }}
                                            >
                                                {' '}
                                                By Fault Type
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Grid>
                            </Grid>
                            <ReportingBarChart
                                small={true}
                                list={settop5List[state.setBarData] || []}
                                label={label[state.setBarData]}
                                hideCurrnecy={true}
                                handleEditRegClick={handleEditRegClick}
                                setval={state.setBarData === 4 ? 'Category' : 'Depots'}
                            />
                        </Card>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {state.url && (
                        <GijgoGrid
                            dataConverter={(res) => convertData(res)}
                            columns={columns}
                            getUrl={state.url}
                            isReload={state.isReload}
                            showDownload={true}
                            downloadBtnName="Download xlsx"
                            downloadCustomUrl={() =>
                                GetVORReportingExcelFile({
                                    fromDate: state.startDate,
                                    toDate: state.endDate,
                                    customerID: tempOptions.customerIDValue?.id || '',
                                    vehicleCategoryID: tempOptions.vehicleCategoryIDValue?.id || '',
                                    makeID: tempOptions.makeIDValue?.id || '',
                                    fileExt: 'xlsx'
                                })
                            }
                        />
                    )}
                </Grid>
            </Grid>
            {state.show ? (
                // <AddEditDriver DriverID={state.DriverID} onFormSubmit={closeDriverModal} />
                <DialogComp title={`Update VOR - ${state.regNo}`} onClose={closeVorModal} maxWidth="lg" overflow="auto">
                    <AddVor vorVehicleID={state.vorVehicleID} update={true} onClose={closeVorModal} VehicleID={props.VehicleID || state.VehicleID} />
                </DialogComp>
            ) : null}
        </div>
    );
}

export default VorReportingModal;
