import React, { useState, useEffect, useMemo } from 'react';
import { getAllMenuItems } from '../../../Core/Services/InternalUser/menuItemService';
import DataGrid from '../../../Core/Controls/Grid';
import DialogComp from '../../../Core/Dialog/DialogComp';
import MenuModal from './MenuModal';
import { BreadCrumbs, AddButton, EditButton, formatters } from '../../../Core/Controls';
import '../../commonStyle.scss';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';

const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'navigationMenuLabel', name: 'Menu label' },
    { key: 'screenLabel', name: 'Screen label' },
    // { key: 'NavigationMenuURL', name: 'URL' },
    // { key: 'NavigationMenuAuthType', name: 'Auth Type' },
    { key: 'navigationMenuActive', name: 'Is Active ?' }
];

function Menu(props) {
    const [state, setState] = useState({
        menuList: [],
        loader: true,
        open: false,
        isReload: null
    });

    const pullMenuItems = async () => {
        // let res = await getAllMenuItems(props.tabID); //
        // setState((state) => ({
        //     ...state,
        //     menuList: res.data.menus,
        //     loader: false
        // }));
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const submitHandler = (params) => {
        setState((state) => ({ ...state, open: true, navigationMenuID: params }));
    };

    const ActionButtons = (id, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => submitHandler(row.navigationMenuID)} toolTipTitle="Edit Items" />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        return newColumn;
    }, []);

    const title = state.navigationMenuID ? 'Update Menu Items' : 'Add Menu Items';

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullMenuItems();
    };

    let getUrl = `NavigationMenu?roleID=${props.tabID}`;

    return (
        <div>
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={getUrl}
                btnTitle="Add Item"
                onBtnClick={() => submitHandler(null)}
                isReload={state.isReload}
            />
            {state.open ? (
                <DialogComp title={title} onClose={() => closeModal(false)} maxWidth="md">
                    <MenuModal navigationMenuID={state.navigationMenuID} tabID={props.tabID} roleName={props.roleName} onClose={() => closeModal(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
}
export default Menu;
