import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { MandatoryField, SecondaryButton } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import { ResetPassByAdmin } from '../../../../Core/Services/Admin/userService';

const ResetPasswordAdmin = (props) => {
    const [state, setState] = useState({
        errors: {},
        enableChange: false
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError, showToastWarning } = useToast();

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            nst.enableChange = nst.new && nst.confirm && nst.new === nst.confirm;
            return nst;
        });
    };

    const handleResetPass = async (e) => {
        e.preventDefault();
        if (state.new && state.confirm) {
            let pattern = /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$)/gm;
            if (!pattern.test(state.new)) {
                showToastError('Password should be complex with 8 char minimum.');
            } else {
                try {
                    setBtnLoader(true);
                    const res = await ResetPassByAdmin(props.userId, state.new, state.confirm);
                    if (!res.success) {
                        console.error(`When admin trying to change user password (${props.userId})`, res);
                        showToastError(res.messages);
                        setBtnLoader(false);
                    } else {
                        showToastSuccess(res.message);
                        props.onFormSubmit();
                        setBtnLoader(false);
                    }
                } catch (error) {
                    setBtnLoader(false);
                    showToastError(`An error occurred: ${error.message}`);
                }
            }
        }
        // if (user) {
        //     props.onFormSubmit(false);
        // }
    };

    return (
        <Grid>
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="New Password"
                    placeholder="New Password"
                    fullWidth
                    name="new"
                    type="Password"
                    onChange={fieldChange}
                    // value={state.Password}
                    errors={state.errors['Password']}
                />
            </Grid>
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Confirm Password"
                    placeholder="Confirm Password"
                    fullWidth
                    name="confirm"
                    type="Password"
                    onChange={fieldChange}
                    // value={state.Password}
                    errors={state.errors['Password']}
                />
            </Grid>
            <Grid item xs={12} className="submit_btn">
                <SecondaryButton onClick={handleResetPass} isBtnLoader={btnLoader} disabled={!state.enableChange}>
                    Set Password
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default ResetPasswordAdmin;
