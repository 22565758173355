import { postWithAuth, getWithAuth } from "../../../Basic/HttpService";

export const getAllMaintenanceProvider = async () => {
    let res = await getWithAuth(`MaintenanceProvider`);
    return res;
};

export const getMaintenanceProviderById = async (id) => {
    let res = await getWithAuth(`MaintenanceProvider/${id}`);
    return res;
};

export const postMaintenanceProviderData = async (data) => {
    let res = await postWithAuth(`MaintenanceProvider`, data);
    return res;
};
