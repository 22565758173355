import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from '../../../../Core/Controls/Grid/index';
import { AddButton, EditButton, BreadCrumbs } from '../../../../Core/Controls';
import DialogBox from './bookingStatusDialog';
import { useToast } from '../../../../Core/Hooks/useToast';
import DialogComp from '../../../../Core//Dialog/DialogComp';
import '../../../commonStyle.scss';
import { getAllBookingStatus, postBookingStatus } from '../../../../Core/Services/InternalUser/bookingStatusService';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Jobs', active: true },
    { name: 'Booking Status', active: true }
];
const basecolumns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'bookingStatusDescription', name: 'Description' }
];
const BookingStatus = () => {
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {}
    });
    const { showToastSuccess, showToastError } = useToast();

    const pullApiData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };
    const renderEdit = (ev, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <EditButton
                    toolTipTitle="Edit Booking Status"
                    onClick={() => {
                        setState((st) => ({
                            ...st,
                            open: true,
                            title: 'Edit Booking Status',
                            selectedRowData: row,
                            isAdd: false
                        }));
                    }}
                />
            </div>
        );
    };
    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);

    const saveData = async (outData) => {
        let response = await postBookingStatus({
            bookingStatusID: outData.id ?? null,
            bookingStatusDescription: outData.value
        });
        if (response) {
            response.success
                ? showToastSuccess(response.message || 'Booking Status updated successfully')
                : showToastError(response.message || 'Something went wrong');
        } else {
            showToastError('Something went wrong');
        }
        pullApiData();
    };
    const addBtnClicked = () => {
        setState((st) => ({
            ...st,
            open: true,
            title: 'Add Booking Status',
            isAdd: true,
            selectedRowData: {}
        }));
    };
    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false
        }));
    };
    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                columns={columns}
                getUrl={`BookingStatus`}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                btnTitle="Add Booking Status"
                onBtnClick={addBtnClicked}
            />
            {state.open ? (
                <DialogComp open={state.open} onClose={handleClose} title={state.title} maxWidth="md">
                    <DialogBox data={state} onClose={handleClose} saveData={saveData} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default BookingStatus;
