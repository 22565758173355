import React, { useEffect } from 'react';
import { TabView, CustomChip, BackButton } from '../../../../Core/Controls';
import VehicleDashBoard from './vehicleDash';
import NoteDashboard from './../Notes/index';
import DefectScreen from '../../Maintenance/Defects/defectScreen';
import ScheduleDashboard from './ScheduleVehicle/scheduleVehicle';
import VehicleJourneys from './VehicleJourneys';
import FuelPurchaseDashboard from './../FuelPurchaseTab';
import DriverDailyChecksGrid from './DriverDailyChecks';
import BuildIcon from '@material-ui/icons/Build';
import DefaultMesaage from './Message/index';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MileageLog from '../MileageLog/mileageLog';
import { useState } from 'react';
import { getTitleData } from '../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { useLoader } from '../../../../Core/Hooks/useLoader';
import AddVor from '../VOR/AddEditVor';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import ScheduleCalender from '../../../ServiceAgent/Schedules/ScheduleDashboard';
import ContractDash from '../Vehicles/Contract/index';
import ContractHistoryList from '../Vehicles/Contract/contractHistory';
import VehicleRecalls from './VehicleRecall/index';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ContractStepper from '../../ContractScreens/ContractStatusTab/contractStepper';
import VehicleBreakdown from './VehicleTabScreen/VehicleBreakdowns';
import VORVehicle from './VehicleTabScreen/VORVehicle';
import VehicleJobs from './VehicleTabScreen/VehicleJobs';
import VehiclePurchaseInvoice from './VehicleTabScreen/VehiclePurchaseInvoice';
import VehicleSalesInvoice from './VehicleTabScreen/VehicleSalesInvoice';
import VehicleScheduleCalender from './VehicleTabScreen/VehicleScheduleCalender';
import VehicleSchedule from './VehicleTabScreen/VehicleSchedules';

export default function EditVehicle(props) {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const { userRoles } = useLoginInfo();
    const isCustomer = userRoles.includes('customer');
    const id = props.match.params.id;
    const serviceAgentid = props.match.params.serviceAgentid;
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isRedirectLabel = searchParams.get('redirectLabel');
    const isContractHistoryIDParam = searchParams.get('contractHistoryID');
    const isReset = searchParams.get('isReset');

    const { showLoader, hideLoader } = useLoader();

    const [tabIndex, setTabIndex] = useState(undefined);
    const [state, setState] = useState({
        desc: '',
        vorID: null,
        depotID: null,
        customerID: null,
        technicianUserID: null,
        loader: true,
        regNumber: '',
        vehiclePrimaryServiceAgentID: null,
        vehicleContractID: null,
        isStepperReload: null
    });

    const handleStepperContract = (val) => {
        setState((st) => ({
            ...st,
            isStepperReload: val
        }));
        pullDetails();
    };

    const pullDetails = async () => {
        showLoader();
        let res = await getTitleData(id);
        if (res.success) {
            let data = res.data.details[0];
            setState((st) => ({
                ...st,
                desc: data?.vehicleDescription,
                vorID: data?.vorVehicleID,
                loader: false,
                vehicleContractID: data?.vehicleContractID,
                customerID: data?.vehicleCustomerID,
                regNumber: data?.vehicleDescription.split('-')[0].trim(),
                vehiclePrimaryServiceAgentID: data?.vehiclePrimaryServiceAgentID,
                depotID: data?.depotID,
                technicianUserID: data?.technicianUserID,
                customerCreditAccountMessage: data?.customerCreditAccountMessage,
                customerIsOnStop: data?.customerIsOnStop,
                customerCompanyName: data?.customerCompanyName,
                displayVehicleProgress: data?.displayVehicleProgress === 'Y' ? true : false,
                isStepperReload: new Date()
            }));
            hideLoader();
        } else {
            hideLoader();
        }
    };

    const closeAddPopUp = (flag, name) => {
        setState((st) => ({ ...st, showVORPopup: false }));
        flag && pullDetails();
    };

    const handleAddPopUp = () => {
        setState((st) => ({ ...st, showVORPopup: true }));
    };
    const selectTabIndex = (label) => {
        let tabs = newTabs();
        const index = tabs.findIndex((tab) => tab.label === label);
        if (index != -1) {
            var d = new Date();
            var n = d.getTime();
            setState((st) => ({ ...st, timeDependency: n }));
            const searchParams = new URLSearchParams(location.search);
            searchParams.delete('redirectLabel');
            searchParams.delete('isReset');
            searchParams.set('tab_testing_tabs', index);
            history.replace({ search: searchParams.toString() });
            setTabIndex(index);
        }
    };

    useEffect(async () => {
        pullDetails();
    }, [id]);

    useEffect(() => {
        if (isRedirectLabel) {
            selectTabIndex(isRedirectLabel);
        }
    }, [state.vehicleContractID, isRedirectLabel]);

    const tabs = [
        {
            label: 'Dashboard',
            body: (
                <VehicleDashBoard
                    VehicleID={id}
                    changeIndex={selectTabIndex}
                    pullDetails={pullDetails}
                    vorID={state.vorID}
                    vehicleDisc={state.desc}
                    depotID={state.depotID}
                    customerID={state.customerID}
                    vehicleContractID={state.vehicleContractID}
                    customerCreditAccountMessage={state.customerCreditAccountMessage}
                    customerIsOnStop={state.customerIsOnStop}
                    customerCompanyName={state.customerCompanyName}

                    // displayVehicleProgress={state.displayVehicleProgress}
                />
            )
        },
        {
            label: 'Breakdowns',
            body: <VehicleBreakdown frompage="Vehicles" VehicleID={id} fromTab={true} />
        },
        {
            label: 'Defects',
            body: <DefectScreen VehicleID={id} frompage="Vehicles" fromTab={true} pullDetails={pullDetails} />
        },
        {
            label: 'VOR',
            body: <VORVehicle VehicleID={id} frompage="Vehicles" fromTab={true} />
        },
        {
            label: 'Calendar',
            body: <VehicleScheduleCalender scheduleVehicleID={id} fromTab={true} serviceAgentid={state.vehiclePrimaryServiceAgentID} />
        },
        {
            label: 'Schedules',
            body: (
                <VehicleSchedule
                    scheduleVehicleID={id}
                    fromTab={true}
                    serviceAgentid={state.vehiclePrimaryServiceAgentID}
                    vehicleDisc={state.desc}
                    handleStepperContract={handleStepperContract}
                    isStepperReload={state.isStepperReload}
                />
            )
        },
        // {
        //     label: 'Estimates',
        //     body: <h1>Estimates Screen</h1>
        // },

        {
            label: 'Jobs',
            body: (
                <>
                    <VehicleJobs
                        VORId={state.vorID}
                        VehicleID={id}
                        frompage="JOBS"
                        fromTab={true}
                        ServiceAgentID={state.vehiclePrimaryServiceAgentID}
                        depotID={state.depotID}
                        technicianUserID={state.technicianUserID}
                        customerID={state.customerID}
                        pullDetails={pullDetails}
                    />
                </>
            )
        },
        ...(checkhaveAccess?.CanViewInvoices
            ? [
                  ...(!isCustomer
                      ? [
                            {
                                label: 'Purchase Invoices',
                                body: <VehiclePurchaseInvoice VehicleID={id} ServiceAgentID={state.vehiclePrimaryServiceAgentID} />
                            }
                        ]
                      : []),
                  {
                      label: 'Sales Invoices',
                      body: <VehicleSalesInvoice VehicleID={id} ServiceAgentID={state.vehiclePrimaryServiceAgentID} />
                  }
              ]
            : []),
        // { label: 'Mileage', body: <MileageLog VehicleID={id} /> },
        ...(checkhaveAccess?.CanViewNotes
            ? [
                  {
                      label: 'Notes',
                      body: <NoteDashboard frompage="Vehicles" NoteVehicleID={id} />
                  }
              ]
            : []),
        // {
        //     label: 'Journeys',
        //     body: <VehicleJourneys VehicleID={id} frompage="Journeys" />
        // },

        {
            label: 'Checks',
            body: <DriverDailyChecksGrid VehicleID={id} frompage="Checks" fromTab={true} />
        },
        {
            label: 'Recalls',
            body: <VehicleRecalls VehicleID={id} frompage="Checks" fromTab={true} vehicleDesc={state.desc} />
        },
        // { label: 'Fuel', body: <FuelPurchaseDashboard VehicleID={id} /> },
        // {
        //     label: 'Messages',
        //     body: <DefaultMesaage VehicleID={id} frompage="Message" fromTab={true} />
        // },
        ...(checkhaveAccess?.CanViewContracts && state.vehicleContractID
            ? [
                  {
                      label: 'Contract',
                      body: (
                          <ContractDash
                              VehicleID={id}
                              frompage="Contract"
                              vehicleContractID={state.vehicleContractID}
                              fromTab={true}
                              pullDetails={pullDetails}
                              isStepperReload={state.isStepperReload}
                          />
                      )
                  }
              ]
            : []),
        ...(checkhaveAccess?.CanViewContracts
            ? [
                  {
                      label: 'Contract History',
                      body: <ContractHistoryList VehicleID={id} contractHistoryID={isContractHistoryIDParam} />
                  }
              ]
            : [])
    ];

    let newTabs = () => {
        let newTabs = [...tabs];
        if (!checkhaveAccess?.CanViewBreakdowns) {
            let index = newTabs.findIndex((m) => m.label === 'Breakdowns');
            newTabs.splice(index, 1);
        }
        if (!checkhaveAccess?.CanViewDefects) {
            let index = newTabs.findIndex((m) => m.label === 'Defects');
            newTabs.splice(index, 1);
        }
        if (!checkhaveAccess?.CanViewDailyChecks) {
            let index = newTabs.findIndex((m) => m.label === 'Checks');
            newTabs.splice(index, 1);
        }
        if (!checkhaveAccess?.CanViewSchedules) {
            let index = newTabs.findIndex((m) => m.label === 'Schedules');
            newTabs.splice(index, 1);
        }
        if (!checkhaveAccess?.CanViewJobs) {
            let index = newTabs.findIndex((m) => m.label === 'Jobs');
            newTabs.splice(index, 1);
        }
        if (!checkhaveAccess?.CanViewMessages) {
            let index = newTabs.findIndex((m) => m.label === 'Messages');
            newTabs.splice(index, 1);
        }
        if (!checkhaveAccess?.CanViewSalesInvoices) {
            let index = newTabs.findIndex((m) => m.label === 'Sales Invoices');
            newTabs.splice(index, 1);
        }
        if (!checkhaveAccess?.CanViewVOR) {
            let index = newTabs.findIndex((m) => m.label === 'VOR');
            newTabs.splice(index, 1);
        }
        if (!checkhaveAccess?.CanViewContracts) {
            let index = newTabs.findIndex((m) => m.label === 'Contract History');
            newTabs.splice(index, 1);
        }

        return newTabs;
    };

    const GoBack = () => {
        history.goBack();
    };
    return (
        <div className="screen">
            <Grid container spacing={1}>
                <Grid item>
                    <BackButton onClick={GoBack} />
                </Grid>
                <Grid item>
                    <Typography variant="h5">{state.desc?.toUpperCase() || ''}</Typography>
                </Grid>
                {state.vorID && (
                    <Grid item>
                        <CustomChip color="secondary" className="btn-m" icon={BuildIcon} label="VOR" onClick={handleAddPopUp} />
                    </Grid>
                )}
            </Grid>
            <TabView tabList={newTabs()} id="testing_tabs" selectedIndex={tabIndex} timeDependency={state.timeDependency}>
                {state.displayVehicleProgress && (
                    <ContractStepper vehicleID={id} pullDetails={pullDetails} handleStepperContract={handleStepperContract} isReload={state.isStepperReload} />
                )}
            </TabView>
            {state.showVORPopup ? (
                <DialogComp
                    title={`${state.vorID ? 'Update' : 'Add'} VOR - ${state.regNumber}`}
                    onClose={() => closeAddPopUp(false)}
                    maxWidth="lg"
                    overflow="auto"
                >
                    <AddVor VehicleID={id} update={state.vorID} vorVehicleID={state.vorID} onClose={closeAddPopUp} />
                </DialogComp>
            ) : null}
        </div>
    );
}
