import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';
import { memoize } from 'lodash';

export const getAllJobQueryList = async (id) => {
    let res = await getWithAuth(`JobQuery/${id}`);
    return res;
};

export const postJobQueryModal = async (data) => {
    let res = await postWithAuth(`JobQuery`, data);
    return res;
};

export const UpdateJobStatus = async (data) => {
    let res = await postWithAuth(`VehicleJob/VehicleJobsUpdateStatus`, data);
    return res;
};

export const JobsSubmitcost = async (data) => {
    let res = await postWithAuth(`VehicleJob/VehicleJobsSubmitcost`, data);
    return res;
};

export const JobsApproveJob = async (data) => {
    let res = await postWithAuth(`VehicleJob/VehicleJobsApproveJob`, data);
    return res;
};

export const JobsConfirmJob = async (data) => {
    let res = await postWithAuth(`VehicleJob/ConfirmJob/${data.id}`, data);
    return res;
};

export const getAllJobUpdateNotesList = async (id) => {
    let res = await getWithAuth(`JobUpdates?JobUpdateJobID=${id}`);
    return res;
};
export const addUpdateJobUpdateNote = async (data) => {
    let res = await postWithAuth(`JobUpdates`, data);
    return res;
};
export const getJobUpdateNotesbyId = async (id) => {
    let res = await getWithAuth(`JobUpdates/${id}`);
    return res;
};
