import React, { useState, useEffect, useMemo } from 'react';
import { BreadCrumbs, DataGrid, EditButton, formatters } from '../../../../Core/Controls';
import AddMaintenanceProvider from './addMaintenanceProvider';
import { getAllMaintenanceProvider } from '../../../../Core/Services/InternalUser/ServiceAgent/maintenanceProvider';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Admin', active: true },
    { name: 'Maintenance Providers', path: '/', active: true }
];

// const getAddress = (data) => {
//     return (
//         <div>
//             {formatters.formatWithComma(data.row?.maintenanceProviderAddress1)}
//             {formatters.formatWithComma(data.row?.maintenanceProviderAddress2)}
//             {formatters.formatWithComma(data.row?.maintenanceProviderCity)}
//             {formatters.formatWithComma(data.row?.maintenanceProviderCounty)}
//             {data.row?.maintenanceProviderPostcode}
//         </div>
//     );
// };

const baseColumns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'maintenanceProviderName', name: 'Name' },
    // { key: 'maintenanceProviderCounty', name: 'County', width: '10%' },
    { key: 'maintenanceProviderEmail', name: 'Email', width: '15%' },
    { key: 'maintenanceProviderContactName', name: 'Contact Name', width: '10%' },
    {
        key: 'maintenanceProviderContactPhone',
        name: 'Contact Phone',
        headerRenderer: formatters.ColumnHeaderAlignLeft,
        formatter: formatters.ColumnTextAlignleft,
        width: '8%'
    },
    { key: 'maintenanceProviderContractStartDate', name: 'Start Date', formatter: formatters.Date_DDMMYYYY, width: '6%' },
    { key: 'maintenanceProviderContractEndDate', name: 'End Date', formatter: formatters.Date_DDMMYYYY, width: '6%' },
    {
        key: 'address',
        name: 'Address',
        // formatter: (data) =>
        //     formatters.addressFormatter(
        //         data.row?.maintenanceProviderAddress1,
        //         data.row?.maintenanceProviderAddress2,
        //         data.row?.maintenanceProviderCity,
        //         data.row?.maintenanceProviderCounty,
        //         data.row?.maintenanceProviderPostCode
        //     ),
        width: '30%'
    }
];

const MaintenanceProvider = () => {
    const [state, setState] = useState({
        modalToggle: false,
        providerList: [],
        showLoader: true
    });

    const dataFormatter = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton toolTipTitle="Edit Provider" onClick={() => handleProvider(row.maintenanceProviderID)} />
            </div>
        );
    };

    useEffect(async () => {
        pulldata();
    }, []);

    const pulldata = async () => {
        // let res = await getAllMaintenanceProvider();
        // if (res.success) {
        //     setState((st) => ({
        //         ...st,
        //         providerList: res.data.list,
        //         showLoader: false
        //     }));
        // }
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };
    const handleProvider = (params) => {
        setState((st) => ({
            ...st,
            modalToggle: true,
            providerID: params
        }));
    };

    const closeProviderModal = () => {
        setState((st) => ({
            ...st,
            modalToggle: false
        }));
        pulldata();
    };
    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);
    const gridBtnTitle = 'Add Provider';
    let getUrl = `MaintenanceProvider`;
    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} m={5} />
            {state.modalToggle ? (
                <>
                    <br />
                    <AddMaintenanceProvider providerID={state.providerID} onClose={closeProviderModal} />
                </>
            ) : (
                // <DataGrid
                //     columns={columns}
                //     className="custom-scroll"
                //     rows={state.providerList}
                //     isRowHovered={true}
                //     headerRowHeight={45}
                //     enableSearch={true}
                //     loadingData={state.showLoader}
                //     btnTitle={gridBtnTitle}
                //     onBtnClick={() => handleProvider(null)}
                //     paginate
                //     offset={210}
                // />
                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl={getUrl}
                    btnTitle={gridBtnTitle}
                    onBtnClick={() => handleProvider(null)}
                    isReload={state.isReload}
                />
            )}
        </div>
    );
};

export default MaintenanceProvider;
