import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useToast } from '../../../../Core/Hooks/useToast';
import { BreadCrumbs, formatters } from '../../../../Core/Controls';
import '../../../commonStyle.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import WorkIcon from '@material-ui/icons/Work';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import DepotDetails from '../../Controllers/DetailsModal/depotDetails';
import CustomerDetails from '../../Controllers/DetailsModal/customerDetails';
import ServiceAgentDetails from '../../Controllers/DetailsModal/serviceAgent';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import { Button, ButtonGroup, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { GetOutstandingDocSchedulesExcelFile } from '../../../../Core/Services/InternalUser/excelDownload';
import DescriptionIcon from '@material-ui/icons/Description';
import { ChipWithoutLable } from '../../../../Core/Controls/Inputs/DatePicker';
import { Mail } from '@material-ui/icons';
import MailOutStandingDoc from './MailOutStandingDoc';
import ScheduleSetReceivedModal from '../OutstandingDocuments/ScheduleSetReceived';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useWindowSize } from '../../../../Core/Hooks/useWindowSize';

var $ = window.$;

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Signed Off', active: true },
    { name: 'Event Signed Off', path: '/', active: true }
];
const baseColumns = [
    { key: 'regNumber', name: 'Reg Number', width: 100 },
    { key: 'event', name: ' Event', width: 160 },
    { key: 'completionDate', name: 'Completion Date', formatter: formatters.Date_DDMMYYYY, width: 140 },
    { key: 'customer', name: 'Customer Name', width: 230 },
    { key: 'depot', name: 'Depot Name', width: 210 },
    { key: 'agent', name: 'Service Area', width: 120 },
    { key: 'technician', name: 'Engineer', width: 150 },
    { key: 'ownership', name: 'Ownership', width: 150 },
    { key: 'document', name: 'Document', width: 150 }
];

const EventSignedOff = (props) => {
    let history = useHistory();

    const [width, height] = useWindowSize();
    const isCustomer = useLoginInfo().userRoles.includes('customer');
    const location = useLocation();

    const params = new URLSearchParams(window.location.search);
    const agent = params.get('agentName') || '';

    let checkhaveAccess = AppStorage.getAllowAcces();

    const [state, setState] = useState({
        show: false,
        showLoader: true,
        outStandingDocsList: [],
        depotID: null,
        recordType: false,
        showSetReceived: false,
        docSent: false
    });

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.jobID}/${val?.vehicleID}`,
            state: 'breakdown'
        });
    };
    const handleshowSetReceived = (val) => {
        setState((st) => ({ ...st, data: val, showSetReceived: true }));
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location?.search);
        const isdocSent = searchParams.get('docSent');
        if (isdocSent) {
            setState((st) => ({ ...st, docSent: isdocSent == 1 }));
        }
    }, []);

    const dataFormatter = (data, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                {checkhaveAccess?.CanViewJobs && !isCustomer && (
                    <CustomIconButton
                        icon={WorkIcon}
                        color="secondary"
                        className="modal-btn-update btn_space"
                        toolTipTitle="Job Details"
                        onClick={() => handleEditClick(row)}
                    />
                )}
                {state.recordType == false && (
                    <CustomIconButton
                        icon={DescriptionIcon}
                        color="secondary"
                        className="modal-btn-update btn_space"
                        toolTipTitle="Set Received Documents"
                        onClick={() => handleshowSetReceived(row)}
                    />
                )}
            </div>
        );
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.regNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.agent}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.depot}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customer}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleClose = (res) => {
        let newst = {};
        newst.open = false;
        newst.showSaModal = false;
        newst.showCustomerModal = false;
        newst.showDepotModal = false;
        newst.showSetReceived = false;
        newst.showModal = false;
        if (res) newst.isReload = new Date();
        setState((st) => ({
            ...st,
            ...newst
        }));
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let regNumberCol = tempCols.find((element) => element.key === 'regNumber');
        let agentCol = tempCols.find((element) => element.key === 'agent');
        let customerCol = tempCols.find((element) => element.key === 'customer');
        let depotCol = tempCols.find((element) => element.key === 'depot');
        if (checkhaveAccess?.CanViewJobs || checkhaveAccess?.CanUploadScheduleDocuments) {
            tempCols.splice(0, 0, {
                key: 'action',
                name: 'Action',
                width: 100,
                addFileUploaderIcon: true,
                formatter: dataFormatter
            });
        }
        if (state.recordType == true) {
            tempCols.push({ key: 'completionDate', name: 'Completion Date', width: 130 });
        }
        if (regNumberCol) {
            regNumberCol.formatter = renderRegNumberEdit;
        }
        if (agentCol) {
            agentCol.formatter = renderAgentName;
        }
        if (customerCol) {
            customerCol.formatter = renderCustomerName;
        }
        if (depotCol) {
            depotCol.formatter = renderDepotName;
        }
        return tempCols;
    }, [state.recordType]);

    const handleChange = (val) => {
        setState((st) => ({ ...st, docSent: val }));
    };
    let idFieldName = state.recordType == true ? 'scheduleID' : 'scheduleDocID';
    let fileUploaderConfigName = 'ScheduleDocumentsRequired';

    const getVal = async (gridRef, recList) => {
        var val = [];
        $(`${gridRef} tbody :checkbox:checked`).each(function (i) {
            val[i] = $(this).val();
        });

        let newList = val.map((m, i) => {
            let getValues = m - 1;
            return recList[getValues];
        });

        setState((st) => ({
            ...st,
            showModal: true,
            selectedRecords: newList
        }));
    };
    const mailButton = (gridRef, valueList) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justify="center">
                <ChipWithoutLable
                    icon={Mail}
                    onClick={() => getVal(gridRef, valueList)}
                    style={{ fontWeight: 'bold' }}
                    toolTipTitle={'Mail Documents'}
                    color="secondary"
                />
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const onRowDataBound = (e, $row, id, record) => {
        if (record.fileCount == 0) {
            var checkboxCell = $row.find('input[type="checkbox"][data-role="selectRow"]').closest('td');
            checkboxCell.empty().append('<span></span>');
        }
    };
    return (
        <div className="screen">
            <Grid container justify="flex-end">
                <Grid container justify="space-between">
                    <Grid item>
                        <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" />
                    </Grid>
                    {/* <Grid component="label" item alignItems="center">
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button style={{ fontSize: 12 }} variant={state.docSent === false ? 'contained' : 'outlined'} onClick={() => handleChange(false)}>
                                Not Sent
                            </Button>
                            <Button style={{ fontSize: 12 }} variant={state.docSent === true ? 'contained' : 'outlined'} onClick={() => handleChange(true)}>
                                Sent
                            </Button>
                            <Button style={{ fontSize: 12 }} variant={state.docSent === '' ? 'contained' : 'outlined'} onClick={() => handleChange('')}>
                                All
                            </Button>
                        </ButtonGroup>
                    </Grid> */}
                </Grid>

                <GijgoGrid
                    getUrl={`VehicleInspection/GetEventsSignedOffDocuments?DocSent=${state.docSent}${agent && `&agent=${agent}`}`}
                    selectionType={'multiple'}
                    selectionMethod={'checkbox'}
                    showCheckboxColFilterBtn={mailButton}
                    columns={columns}
                    idFieldName={checkhaveAccess?.CanUploadScheduleDocuments ? idFieldName : ''}
                    fileUploaderConfigName={fileUploaderConfigName}
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    isReload={state.isReload}
                    fileUploaderModalClose={true}
                    onRowDataBound={(e, $row, id, record) => onRowDataBound(e, $row, id, record)}
                />
                {state.showSaModal ? (
                    <DialogComp open={true} onClose={handleClose} title={'Service Area Details'}>
                        <ServiceAgentDetails serviceAgentID={state.selectedRowData.serviceAgentID} onFormSubmit={handleClose} />
                    </DialogComp>
                ) : null}
                {state.showCustomerModal ? (
                    <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                        <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={handleClose} />
                    </DialogComp>
                ) : null}
                {state.showDepotModal ? (
                    <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                        <DepotDetails customerID={state.selectedRowData.depotID} onFormSubmit={handleClose} />
                    </DialogComp>
                ) : null}
                {state.showSetReceived ? (
                    <DialogComp open={true} onClose={() => handleClose(false)} title={`Set Received (${state.data?.regNumber})`}>
                        <ScheduleSetReceivedModal val={state.data} onFormSubmit={handleClose} />
                    </DialogComp>
                ) : null}
                {/* {state.showModal ? (
                    <DialogComp title={'Mail Documents'} onClose={() => handleClose(false)} maxWidth="md">
                        <MailOutStandingDoc
                            onClose={handleClose}
                            selectedRecords={state.selectedRecords || []}
                            idFieldName={idFieldName}
                            fileUploaderConfigName={fileUploaderConfigName}
                            code="VEHICLEDOCS"
                        />
                    </DialogComp>
                ) : null} */}
                {state.showModal ? (
                    <DialogComp title={'Mail Documents'} onClose={() => handleClose(false)} maxWidth="md" fullScreen={width > 1024 ? false : true}>
                        <MailOutStandingDoc
                            onClose={handleClose}
                            selectedRecords={state.selectedRecords || []}
                            idFieldName={idFieldName}
                            fileUploaderConfigName={fileUploaderConfigName}
                            code="VEHICLEDOCS"
                        />
                    </DialogComp>
                ) : null}
            </Grid>
        </div>
    );
};
export default EventSignedOff;
