import React, { useEffect, useState, useMemo } from 'react';
import { EditButton, BreadCrumbs, formatters } from '../../../Core/Controls';
import DataGrid from '../../../Core/Controls/Grid';
import '../../commonStyle.scss';
import { useToast } from '../../../Core/Hooks/useToast';
import AddEditReportCatalogue from './addEditReportCatalogue';

import { getAllReportCatalogues } from '../../../Core/Services/Admin/reportCatalogueService';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: 80
    },
    {
        name: 'Name',
        key: 'name'
    },
    {
        key: 'dateCreated',
        name: 'Creation Date',
        formatter: formatters.Date_DDMMMYYYY_HHMM
    },
    {
        key: 'createdBy',
        name: 'Created By'
    },
    {
        key: 'dateUpdated',
        name: 'Updated Date',
        formatter: formatters.Date_DDMMMYYYY_HHMM
    },
    {
        key: 'updatedBy',
        name: 'Updated By'
    }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Report Catalogues', path: '/', active: true }
];

const ReportCataloguesScreen = (props) => {
    const { showToastError } = useToast();

    const [state, setState] = useState({
        show: false,
        showLoader: true,

        reportCataloguesList: [],
        reportCataloguesData: null,
        reportCatalogueID: null,
        isReload: null
    });

    const pullReportCataloguesAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const handleAdd = (record) => {
        setState((st) => ({
            ...st,
            show: true,
            reportCatalogueID: record
        }));
    };

    const closeReportCataloguesModal = (res) => {
        setState((st) => ({
            ...st,
            show: false,
            showAsgPermi: false
        }));
        res && pullReportCataloguesAndUpdateState();
    };

    const dataFormatter = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleAdd(row.reportCatalogueID)} toolTipTitle="Edit Report Catalogue" />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);

    const Crumbs = useMemo(() => {
        const tempCols = [...crumbs];
        let actionCol = tempCols.find((element) => element.name === 'Report Catalogues');
        if (actionCol) {
            actionCol.onClick = closeReportCataloguesModal();
        }
        return tempCols;
    }, []);

    const title = state.reportCataloguesData ? 'Update Report Catalogue' : 'Add Report Catalogue';
    let getUrl = `reportcatalogue`;
    return (
        <div className="screen">
            <BreadCrumbs crumbs={Crumbs} />

            {state.show ? (
                <AddEditReportCatalogue reportCatalogueID={state.reportCatalogueID} onClose={closeReportCataloguesModal} />
            ) : (
                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl={getUrl}
                    btnTitle="Add Report Catalogue"
                    onBtnClick={() => handleAdd(null)}
                    isReload={state.isReload}
                />
            )}
        </div>
    );
};
export default ReportCataloguesScreen;
