import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { SingleSelect } from '../../../Core/Controls';
import { AppStorage } from '../../../Core/Basic/storage-service';
import { ServiceAreaHolidays_DropDowns, ServiceAreaHolidays_GetDetails } from '../../../Core/Services/ServiceAgent/Holiday.js';
import DialogComp from '../../../Core/Dialog/DialogComp.jsx';
import './sacalender.scss';
import moment from 'moment';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo.js';
import ViewHoliday from './ViewHoliday.jsx';
import AddUpdateHoliday from './AddUpdateHoliday.jsx';

let BlockPeriodObj = {
    8: { color: '#A4A4AA', name: 'Morning', txtColor: 'white' },
    9: { color: '#A4A4AA', name: 'Afternoon', txtColor: 'white' },
    10: { color: '#A4A4AA', name: 'Full day', txtColor: 'white' },
    11: { color: '#A4A4AA', name: 'Morning', txtColor: 'white' }
};

const monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const Month = ({ monthName, year, monthIndex, handleOpen, holidayList, isUserPermission }) => {
    const daysInMonth = moment([year, monthIndex]).daysInMonth();

    const firstDay = moment([year, monthIndex, 1]).day();
    const adjustedFirstDay = firstDay === 0 ? 6 : firstDay - 1;

    const today = moment();

    const days = [];

    for (let i = 0; i < adjustedFirstDay; i++) {
        days.push(<div className="empty" key={`empty-${i}`}></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
        const isToday = today.isSame(moment([year, monthIndex, day]), 'day');
        const currentDay = moment([year, monthIndex, day]).day();
        const adjustedDay = currentDay === 0 ? 6 : currentDay - 1;
        let indexObject = holidayList.find((k) => moment(k.holidayDate).format('YYYY-MM-DD') === moment([year, monthIndex, day]).format('YYYY-MM-DD')) || {};

        const isWeekend = adjustedDay === 6; // 5 is Saturday, 6 is Sunday

        days.push(
            <Grid
                container
                alignContent="center"
                className="day"
                key={day}
                style={{
                    background: isWeekend
                        ? '#9e1b32'
                        : !indexObject.success && isToday
                        ? '#009E60'
                        : indexObject.success
                        ? '#f2d4ce'
                        : BlockPeriodObj[monthIndex]?.color
                        ? BlockPeriodObj[monthIndex]?.color
                        : '#F4F4F5',

                    color: isWeekend
                        ? 'white'
                        : isToday
                        ? 'white'
                        : indexObject.success
                        ? 'black'
                        : BlockPeriodObj[monthIndex]?.txtColor
                        ? BlockPeriodObj[monthIndex]?.txtColor
                        : 'black',
                    fontWeight: isToday ? 800 : 600
                }}
                onClick={isUserPermission ? () => handleOpen(moment([year, monthIndex, day]).format('YYYY-MM-DD'), indexObject) : () => {}}
            >
                <Grid item>
                    {' '}
                    {!indexObject.success ? (
                        day
                    ) : (
                        <abbr title={`${indexObject?.techOff} engineer${indexObject?.techOff > 1 ? 's' : ''} on holiday`} style={{ textDecoration: 'none' }}>
                            {day}
                        </abbr>
                    )}
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid item xs={12} sm md={6} lg={3} xl={3}>
            <div className="month">
                <div style={{ fontSize: 20, fontWeight: 700, padding: 5 }}>{monthName}</div>
                <div className="days-grid">
                    <div className="day-name">Mon</div>
                    <div className="day-name">Tue</div>
                    <div className="day-name">Wed</div>
                    <div className="day-name">Thu</div>
                    <div className="day-name">Fri</div>
                    <div className="day-name">Sat</div>
                    <div className="day-name">Sun</div>
                    {days}
                </div>
            </div>
        </Grid>
    );
};

export default function ServiceAreaHolidays() {
    const { userRoles } = useLoginInfo();
    const isinternal = userRoles.includes('internal');

    let isUserPermission = AppStorage.getAllowAcces().CanViewHolidayLeave || isinternal;
    const [state, setState] = useState({
        openModal: false,
        showEditModal: false,
        year: { id: moment().year(), name: moment().year().toString() },
        yearList: [],
        serviceArea: [],
        serviceAreaID: {},
        holidayList: [],
        modalData: {}
    });

    useEffect(() => {
        if (state.serviceArea.length == 1) setState((st) => ({ ...st, serviceAreaID: state.serviceArea[0] }));
    }, [state.serviceArea]);

    const getLastFiveYears = () => {
        const years = [];
        const currentYear = 2024;

        for (let i = 0; i < 10; i++) {
            years.push(currentYear + i);
        }

        return years.map((val) => ({
            id: val,
            name: val.toString()
        }));
    };

    const handleOpen = (date, data) => {
        let newst = {};
        newst.selectedDate = date;
        newst.modalData = data || {};
        newst.showEditModal = !state.showEditModal;
        setState((st) => ({
            ...st,
            ...newst
        }));
    };
    const handleclose = (res) => {
        setState((st) => ({ ...st, selectedDate: '', modalData: {}, showEditModal: false }));
        res && pullData();
    };

    useEffect(() => {
        let fetchData = async () => {
            let dropdownRes = await ServiceAreaHolidays_DropDowns();
            try {
                if (dropdownRes.success)
                    setState((st) => ({
                        ...st,
                        serviceArea: dropdownRes?.data?.list || [],
                        yearList: getLastFiveYears()
                    }));
            } catch (error) {
                console.warn('fail to fetch data', error);
            }
        };
        return fetchData();
    }, []);

    const pullData = useCallback(async () => {
        try {
            let res = await ServiceAreaHolidays_GetDetails(state?.serviceAreaID?.id, state?.year?.id);
            if (res.success) {
                setState((st) => ({ ...st, holidayList: res.data.list || [], error: null }));
            }
        } catch (error) {
            console.error('Error fetching holiday data:', error);
        }
    }, [state?.serviceAreaID?.id, state?.year?.id]);

    useEffect(() => {
        if (state?.serviceAreaID?.id && state?.year?.id) {
            pullData();
        }
    }, [pullData, state?.serviceAreaID?.id, state?.year?.id]);

    const WorkCategoryChange = (nm) => (e, val) => {
        setState((state) => ({
            ...state,
            [nm]: val
        }));
    };

    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={3} xl={2} className="mandatory-fields">
                    <SingleSelect
                        inputLabel="Service Area"
                        isRequired={true}
                        style={{ marginTop: -8 }}
                        options={state.serviceArea}
                        value={state.serviceAreaID}
                        onChange={WorkCategoryChange('serviceAreaID')}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={3} xl={2} className="mandatory-fields">
                    <SingleSelect
                        inputLabel="Year"
                        isRequired={true}
                        style={{ marginTop: -8 }}
                        options={state.yearList}
                        value={state.year}
                        onChange={WorkCategoryChange('year')}
                    />
                </Grid>
            </Grid>
            {state?.serviceAreaID?.id && state?.year?.id ? (
                <>
                    <Grid container spacing={1}>
                        <div className="calendar">
                            {monthList.map((month, index) => (
                                <Month
                                    key={index}
                                    monthName={month}
                                    year={state.year.id}
                                    monthIndex={index}
                                    handleOpen={handleOpen}
                                    holidayList={state.holidayList}
                                    isUserPermission={isUserPermission}
                                />
                            ))}
                        </div>
                    </Grid>
                </>
            ) : (
                <Grid container justify="center">
                    <Grid>
                        {' '}
                        <h3> {!state?.serviceAreaID?.id ? 'Please select a service Area' : 'Please select Year'} </h3>
                    </Grid>
                </Grid>
            )}

            {state.showEditModal ? (
                // <DialogComp onClose={handleclose} title={'View Holidays'} maxWidth="md" fullWidth>
                <ViewHoliday selectedDate={state.selectedDate} serviceAreaID={state.serviceAreaID.id} onClose={handleclose} />
            ) : // </DialogComp>
            null}
        </div>
    );
}
