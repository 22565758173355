import React, { useContext, useEffect, useState } from 'react';
import { Badge, Grid, Snackbar, Tooltip } from '@material-ui/core';
import { AppContext } from './PageLevel/AppContextProvider';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useWindowSize } from '../Hooks/useWindowSize';
// import { useLoginInfo } from '../Hooks/useLoginInfo';

const FilterCustomerList = () => {
    const [list, setList] = useState([]);
    const [open, setOpen] = useState(false);
    const [widths] = useWindowSize();

    const { commonState } = useContext(AppContext);

    useEffect(() => {
        const fetchData = async () => {
            let res = commonState.dashBoardData;
            let filterList = [];
            if (res) {
                try {
                    filterList = res.data.filterdCustomer.map((k) => k.customerCompanyName);
                    setList(filterList);
                } catch (error) {
                    console.error(error);
                }
            }
        };

        fetchData();
    }, [commonState]);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{ maxWidth: 600 }}>
            {widths <= 996 ? (
                <Grid container spacing={2}>
                    <Grid
                        item
                        style={{
                            fontSize: 13,
                            color: 'white',
                            fontWeight: 'bolder',
                            cursor: 'pointer'
                        }}
                        onClick={handleClick}
                    >
                        <Tooltip title={'Filters'} arrow style={{ cursor: 'pointer' }}>
                            <Badge color="secondary" badgeContent={list.length} max={999} showZero>
                                <FilterListIcon />
                            </Badge>
                        </Tooltip>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    {list.slice(0, 2).map((k, i) => (
                        <Grid
                            item
                            key={i}
                            style={{
                                fontSize: 13,
                                color: 'white',
                                fontWeight: 700
                            }}
                        >
                            &nbsp; {k}
                        </Grid>
                    ))}
                    {list.length > 2 && (
                        <>
                            <Grid
                                item
                                style={{
                                    fontSize: 13,
                                    color: 'white',
                                    fontWeight: 'bolder',
                                    cursor: 'pointer'
                                }}
                                onClick={handleClick}
                            >
                                {list.length - 2} MORE
                            </Grid>
                        </>
                    )}
                </Grid>
            )}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                onClose={handleClose}
                message={
                    <div>
                        {list.length > 0 ? (
                            list.map((k, i) => (
                                <div
                                    key={i}
                                    style={{
                                        fontSize: 13,
                                        color: 'white'
                                    }}
                                >
                                    &nbsp; {k}
                                </div>
                            ))
                        ) : (
                            <div
                                style={{
                                    fontSize: 13,
                                    color: 'white'
                                }}
                            >
                                All Customers
                            </div>
                        )}
                    </div>
                }
            />
        </div>
    );
};

export default FilterCustomerList;
