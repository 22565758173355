import React, { useEffect, useState, useMemo } from 'react';
import { getAllDriver } from '../../../Core/Services/InternalUser/ControllerService/driverService';
import { useToast } from '../../../Core/Hooks/useToast';
import { EditButton, DataGrid, BreadCrumbs, formatters } from '../../../Core/Controls';
import AddEditDriver from '../../InternalUser/Controllers/Driver/addEditDriver';
import '../../commonStyle.scss';
import { Link } from 'react-router-dom';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { AppStorage } from '../../../Core/Basic/storage-service';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: '4%'
    },
    { key: 'driverFirstName', name: 'Name', width: '18%' },
    { key: 'driverDepotName', name: 'Depot Name', width: '18%' },
    {
        key: 'driverAddress1',
        name: 'Address',
        formatter: (data) => formatters.addressFormatter(data.row?.driveAddress1, data.row?.driveAddress2, data.row?.driverTown, data.row?.driverCounty)
    }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Drivers', active: true }
];

const CustomerDriverScreen = (props) => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const { showToastError } = useToast();

    const [state, setState] = useState({
        show: false,
        showLoader: true,
        DriverList: [],
        DriverID: null
    });

    const pullDriverAndUpdateState = async () => {
        setState((st) => ({ ...st, isReload: new Date() }));
    };

    useEffect(async () => {
        pullDriverAndUpdateState();
    }, []);

    const handleAdd = () => {
        setState((st) => ({
            ...st,
            show: true
        }));
    };

    const closeDriverModal = (res) => {
        setState((st) => ({
            ...st,
            show: false
        }));
        res && pullDriverAndUpdateState();
    };

    const handleEditClick = (val) => {
        props.history.push({
            pathname: `/controller/editdriver/${val.driverID}/${val.driverFirstName}`,
            name: val.driverName
        });
    };

    const dataFormatter = (data, row) => {
        return <EditButton toolTipTitle="Edit Driver" onClick={() => handleEditClick(row)} />;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        let driverNameCol = tempCols.find((element) => element.key === 'driverFirstName');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        if (driverNameCol) {
            driverNameCol.formatter = (data) => data.row.driverFirstName + ' ' + data.row.driverLastName;
        }
        return tempCols;
    }, []);

    return (
        <div className="screen">
            {state.show ? (
                <AddEditDriver DriverID={state.DriverID} onFormSubmit={closeDriverModal} />
            ) : (
                <>
                    <BreadCrumbs crumbs={crumbs} />
                    <GijgoGrid
                        columns={columns}
                        getUrl="drivers"
                        dataConverter={(res) => ({
                            records: res.data.drivers,
                            total: res.data.total
                        })}
                        isReload={state.isReload}
                        onBtnClick={handleAdd}
                        btnTitle={checkhaveAccess?.CanAddDrivers ? 'Add Driver' : null}
                    />
                </>
            )}
        </div>
    );
};
export default CustomerDriverScreen;
