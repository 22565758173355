import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel } from '@material-ui/core';
import { SecondaryButton, InputText } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import { addLiveEnquiry } from '../../../../Core/Services/InternalUser/ControllerService/EnquiriService';

const LiveEquityModal = (props) => {
    const [state, setState] = useState({
        errors: {},
        description: ''
    });
    const { showToastSuccess, showToastError } = useToast();

    const SubmitHandler = async () => {
        let FormData = {
            leadID: props.leadID,
            description: state.description
        };
        let res = await addLiveEnquiry(FormData);
        if (res.success) {
            showToastSuccess('Live enquiry saved successfully');
            props.onClose(true);
        } else {
            showToastError(res.message);
        }
    };

    const InputChangeHandler = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    return (
        <Grid container spacing={2} style={{ width: '600px' }}>
            <Grid item xs={12} sm={12}>
                <InputLabel shrink>Note</InputLabel>
                <InputText multiline rows={8} onChange={InputChangeHandler} placeholder="Note" name="description" />
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton fullWidth disabled={!state.description} className="submit_btn" onClick={SubmitHandler}>
                    Save
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default LiveEquityModal;
