import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from '../../../../Core/Controls/Grid/index';
import { DeleteButton, EditButton, formatters } from '../../../../Core/Controls';
import '../../../commonStyle.scss';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { getAllMileageLogById } from '../../../../Core/Services/InternalUser/ControllerService/mileageLogService';
import DialogBox from './mileageLogDialog';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const basecolumns = [
    { key: 'odometerReadingDate', name: 'Date', formatter: formatters.Date_DDMMYYYY },
    { key: 'odometerReading', name: 'Odometer Reading' },
    { key: 'odometerReadingCaptureSource', name: 'Type' },
    { key: 'addBy', name: 'Added By' }
];

const MileageLog = (props) => {
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        title: '',
        btnFlag: '',
        isReload: null
    });
    let checkhaveAccess = AppStorage.getAllowAcces();
    const isCustomer = useLoginInfo().userRoles.includes('customer');
    useEffect(async () => {
        pullApiData();
    }, [props.VehicleID]);

    const pullApiData = async () => {
        // let res = await getAllMileageLogById(props.VehicleID);
        // setState((st) => ({
        //     ...st,
        //     rows: [...res.data.list],
        //     showLoader: false
        // }));
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const renderEdit = (data, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <EditButton
                    toolTipTitle="Edit Mileage Log"
                    onClick={() => {
                        setState((st) => ({ ...st, selectedRowData: row, open: true, title: 'Edit Mileage Log', btnFlag: 'save' }));
                    }}
                />
                &nbsp;
                {!isCustomer && (
                    <DeleteButton
                        toolTipTitle="Delete Mileage Log"
                        onClick={() => {
                            setState((st) => ({ ...st, selectedRowData: row, open: true, title: 'Delete Mileage Log', btnFlag: 'delete' }));
                        }}
                    />
                )}
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        if (checkhaveAccess?.CanUpdateVehicleMileages) {
            tempCols.splice(0, 0, { key: 'action', name: 'Action', width: 80, formatter: renderEdit });
        }
        return tempCols;
    }, []);

    const addBtnClicked = () => {
        setState((st) => ({ ...st, title: 'Add Mileage Log', open: true, btnFlag: 'add' }));
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            selectedRowData: {}
        }));
        pullApiData();
    };

    let getUrl = `OdometerReadings?vehicleID=${props.VehicleID}`;

    return (
        <div className="controller-screen">
            {/* <DataGrid
                rows={state.rows || []}
                columns={columns}
                enableSearch={true}
                loadingData={state.showLoader}
                btnTitle={'Add Entry'}
                onBtnClick={addBtnClicked}
                paginate
                offset={290}
            /> */}
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={getUrl}
                btnTitle={checkhaveAccess?.CanUpdateVehicleMileages ? 'Add Entry' : ''}
                onBtnClick={addBtnClicked}
                isReload={state.isReload}
            />
            {state.open ? (
                <DialogComp open={true} onClose={handleClose} title={state.title}>
                    <DialogBox onClose={handleClose} rowData={state.selectedRowData} btnFlag={state.btnFlag} vehicleID={props.VehicleID} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default MileageLog;
