import React, { useState, useEffect, useMemo } from 'react';
import { getAllSlippage } from '../../../../Core/Services/InternalUser/slippageService';
import DataGrid from '../../../../Core/Controls/Grid';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import SlippageModal from './SlippageModal';
import { BreadCrumbs, EditButton } from '../../../../Core/Controls';
import '../../.././commonStyle.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Events', active: true },
    { name: 'Slippage Reasons', active: true }
];

const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'slippageReasonDescription', name: 'Description' }
];

function Slippage(props) {
    const [state, setState] = useState({
        slippageList: [],
        loader: true,
        open: false
    });

    const pullSlippage = async () => {
        setState((state) => ({
            ...state,
            isReload: new Date()
        }));
    };

    const handleSlippageUpdate = (params) => {
        setState((state) => ({ ...state, open: true, slippageReasonId: params?.slippageReasonID }));
    };

    const editActionButton = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleSlippageUpdate(row)} toolTipTitle="Edit Slippage Reason" />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = editActionButton;
        }
        return newColumn;
    });

    const title = state.slippageReasonId ? 'Update Slippage Reasons' : 'Add Slippage Reasons';

    const closeSlippageModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullSlippage();
    };
    const urlState = 'SlippageReasons';

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                columns={columns}
                getUrl={urlState}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                btnTitle="Add Slippage Reason"
                onBtnClick={() => handleSlippageUpdate(null)}
            />
            {state.open ? (
                <DialogComp title={title} onClose={() => closeSlippageModal(false)} maxWidth="md">
                    <SlippageModal slippageID={state.slippageReasonId} onClose={() => closeSlippageModal(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
}

export default Slippage;
