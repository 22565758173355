import { postWithAuth, getWithAuth, putWithAuth } from '../../Basic/HttpService';

export const getAllReportCatalogues = async () => {
    let res = await getWithAuth('reportcatalogue');
    return res;
};

export const getAllDropdowns = async () => {
    let res = await getWithAuth(`reportcatalogue/screenDropdowns`);
    return res;
};

export const getReportCataloguesById = async (Id) => {
    let res = await getWithAuth(`reportcatalogue/${Id}`);
    return res;
};

export const addReportCatalogues = async (data) => {
    let res = await postWithAuth('reportcatalogue', data);
    return res;
};
