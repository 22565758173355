import React, { useEffect, useState, useMemo } from 'react';
import '../../commonStyle.scss';
import { BreadCrumbs } from '../../../Core/Controls';
import { IconButton } from '@material-ui/core';
import { Assignment } from '@material-ui/icons';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { EditButton } from '../../../Core/Controls';
import DialogComp from '../../../Core/Dialog/DialogComp';
import VorSubCatDialog from './VorSubCatDialog';

const basecolumns = [
    { key: 'action', name: 'Action', width: '7%' },
    //{ key: 'vorSubCategoryId', name: 'VORSubCategoryId', width: '30%' },
    //{ key: 'vorSubCategoryCategoryId', name: 'VORSubCategoryCategoryId' },
    { key: 'vorSubCategoryDescription', name: 'Description' }
];

function VorSubCat(props) {
    const [state, setState] = useState({
        open: false,
    });

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Vehicles', active: true },
        {
            name:
                <div onClick={() => { props.closeType(false, null); props.closeSubCat(false, null); }} style={{ cursor: 'pointer' }}>
                    VOR Types ({props.focusedRow.vorTypeDescription})
                </div>,
        },
        {
            name:
                <div onClick={() => props.closeSubCat(false, null)} style={{ cursor: 'pointer' }} >
                    VOR Reasons ({props.focusedRow.vorTypeDetailDescription})
                </div>,
        },
        { name: 'VOR Sub Categories', active: true },
    ];

    const renderEdit = (ev, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <EditButton
                    toolTipTitle="Edit"
                    onClick={() => {
                        setState((st) => ({
                            ...st,
                            open: true,
                            selectedRowData: row,
                        }));
                    }}
                />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);

    const addBtnClicked = () => {
        setState((st) => ({
            ...st,
            open: true,
            selectedRowData: null,
        }));
    }

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            selectedRowData: null,
            isReload: !state.isReload
        }));
    }

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />

            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={`VORSubCategories?VORSubCategoryCategoryId=${props.focusedRow.vorTypeDetailID}`}
                btnTitle="Add VOR Type Detail"
                onBtnClick={addBtnClicked}
                isReload={state.isReload}
            />

            {state.open && (
                <>
                    <DialogComp
                        open={state.open}
                        onClose={() => {
                            handleClose(false);
                        }}
                        title={state.selectedRowData == null ? 'Add VOR Sub Category' : 'Edit VOR Sub Category'}
                        maxWidth="md"
                    >
                        <VorSubCatDialog focusedRow={props.focusedRow} onClose={handleClose} selectedRowData={state.selectedRowData} />
                    </DialogComp>
                </>
            )}
        </div>
    );
}
export default VorSubCat;
