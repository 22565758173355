import React, { useState, useEffect } from 'react';
import { BreadCrumbs, TabView } from '../../../Core/Controls';
import { getMenuTab } from '../../../Core/Services/InternalUser/menuItemService';
import MenuItems from './Menu';

function MenuTab(props) {
    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Navigation', active: true },
        { name: 'Menu Items', active: true }
    ];
    const [state, setState] = useState({ leftNavigationMenuTabs: [] });

    useEffect(async () => {
        let res = await getMenuTab();
        if (res.success) {
            setState((state) => ({
                ...state,
                leftNavigationMenuTabs: res.data.roles
            }));
        }
    }, []);
    const tabs = state.leftNavigationMenuTabs.map((m) => ({
        label: m.name,
        body: <MenuItems tabID={m.id} roleName={m.name} />
    }));

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} m={5} />
            <TabView tabList={tabs} id="testing_tabs" />
        </div>
    );
}

export default MenuTab;
