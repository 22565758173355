import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import React, { useEffect, useState, useMemo } from 'react';
import { DataGrid, InputText, SecondaryButton, formatters } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';
import '../../../../commonStyle.scss';
import './scheduleVehicleStyle.scss';
import { getAllNotesById, postScheduleNotes } from '../../../../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';
import moment from 'moment';
import { AppStorage } from '../../../../../Core/Basic/storage-service';

const basecolumns = [
    { key: 'scheduleNoteDateCreated', width: '23%', name: 'Created on', formatter: formatters.Date_DDMMMYYYY_HHMM },
    { key: 'scheduleNoteCreatedBy', width: ' 22%', name: 'From User' },
    { key: 'scheduleNoteDescription', width: '55%', name: 'Notes' }
];

const ScheduleNotes = (props) => {
    let getAccessParam = AppStorage.getAllowAcces();

    const [state, setState] = useState({
        noteText: '',
        showLoader: true
    });
    const [errors, setErrors] = useState({});
    const { showToastSuccess, showToastError } = useToast();
    const pullNoteList = async (Id) => {
        let res = await getAllNotesById(Id);

        setState((st) => ({
            ...st,
            rows: [...(res.data.schedules || [])],
            showLoader: false
        }));
    };
    useEffect(() => {
        pullNoteList(props.scheduleId);
    }, []);

    const validate = () => {
        let noErrors = true;
        let err = {};
        if (!/[^\s]/.test(state.noteText)) {
            err.noteText = true;
            noErrors = false;
        }
        setErrors(err);
        return noErrors;
    };

    const handleData = async () => {
        if (validate()) {
            let response = await postScheduleNotes({
                scheduleNoteScheduleID: props.scheduleId,
                scheduleNoteDescription: state.noteText
            });
            if (response) {
                response.success ? showToastSuccess('Note added successfully') : showToastError(response.message || 'Something went wrong');
            } else {
                showToastError('Something went wrong');
            }
            props.onClose();
        }
    };
    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        return tempCols;
    }, []);

    const valueChanged = (ev) => {
        let temp = ev.target;
        setState((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
        setErrors({});
    };

    const JobSingleNote = (props) => {
        return (
            <div className="single-note">
                <div className="msg" style={{ fontSize: 14, fontStyle: 'italic' }}>
                    {props.note.scheduleNoteDescription}
                </div>
                <div className="footer" style={{ fontSize: 12, color: 'grey', fontStyle: 'italic', textAlign: 'right', float: 'right' }}>
                    {props.note.scheduleNoteCreatedBy} @ {moment(props.note.scheduleNoteDateCreated).format('LLL')}
                </div>
            </div>
        );
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={10}></Grid>
                <Grid item xs={2}>
                    <SecondaryButton onClick={handleData} disabled={!getAccessParam['CanUpdateScheduleNotes']}>
                        ADD
                    </SecondaryButton>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                    <InputText name="noteText" rows={4} multiline onChange={valueChanged} fullWidth />
                    <FormHelperText error>{errors.noteText ? 'Note is empty!' : null}</FormHelperText>
                </Grid>

                {/* <Grid xs={12} className="notes_grid">
                    <DataGrid rows={state.rows} columns={columns} height={350} loadingData={state.showLoader} />
                </Grid> */}

                <Grid item xs={12} style={{ padding: '5px', minHeight: '471px', minWidth: '300px' }} className="grid-style">
                    {state.rows?.map((n, i) => (
                        <>
                            <JobSingleNote key={i} note={n} />
                            <hr style={{ opacity: '30%', marginTop: '25px' }} />
                        </>
                    ))}
                </Grid>
            </Grid>
        </div>
    );
};
export default ScheduleNotes;
