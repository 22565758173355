import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { SecondaryButton, MandatoryField } from '../../../../Core/Controls';
import { getServiceAgentFacilitiesById, postServiceAgentFacilitiesData } from '../../../../Core/Services/InternalUser/serviceAgentFacilitiesService';
import { SecondaryCheckbox } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import '../../.././commonStyle.scss';

function ServiceAgentFacilitiesModal(props) {
    const [state, setState] = useState({
        serviceAgentFacilityID: null,
        serviceAgentFacilityName: '',
        serviceAgentFacilityActive: true,
        errors: {},
        serviceAgentFacilityAddInMapFilter: false
    });

    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        if (props.serviceAgentFacilityID) {
            let res = await getServiceAgentFacilitiesById(props.serviceAgentFacilityID);
            if (res.success) {
                setState((state) => ({
                    ...state,
                    serviceAgentFacilityID: res.data.serviceAgentFacilityID,
                    serviceAgentFacilityName: res.data.serviceAgentFacilityName,
                    serviceAgentFacilityActive: res.data.serviceAgentFacilityActive,
                    serviceAgentFacilityAddInMapFilter: res.data.serviceAgentFacilityAddInMapFilter
                }));
            }
        }
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };
    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    const validations = () => {
        const { serviceAgentFacilityName } = state;

        let formIsValid = true;
        let errors = {};
        if (!serviceAgentFacilityName.trim()) {
            errors.serviceAgentFacilityName = 'Facility Name is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const ServiceAgentData = {
                serviceAgentFacilityID: state.serviceAgentFacilityID,
                serviceAgentFacilityName: state.serviceAgentFacilityName,
                serviceAgentFacilityActive: state.serviceAgentFacilityActive,
                serviceAgentFacilityAddInMapFilter: state.serviceAgentFacilityAddInMapFilter
            };
            let res = await postServiceAgentFacilitiesData(ServiceAgentData);
            if (res.success) {
                showToastSuccess('ServiceAgentFacilities Updated successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
        }
    };

    return (
        <Grid container direction="row" spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Facility Name"
                    name="serviceAgentFacilityName"
                    placeholder="Facility Name"
                    onChange={handleInputChange}
                    value={state.serviceAgentFacilityName}
                    errors={state.errors.serviceAgentFacilityName}
                />
            </Grid>
            <Grid item xs={6}>
                <SecondaryCheckbox
                    checked={state.serviceAgentFacilityAddInMapFilter}
                    name="serviceAgentFacilityAddInMapFilter"
                    onChange={handleCheckbox}
                    label="Add in Map Filter?"
                />
            </Grid>
            <Grid item xs={6}>
                <SecondaryCheckbox checked={state.serviceAgentFacilityActive} name="serviceAgentFacilityActive" onChange={handleCheckbox} label="Is Active?" />
            </Grid>
            <Grid item xs={12} className="submit_btn">
                <SecondaryButton onClick={submitHandler}>{props.serviceAgentFacilityID ? 'Update' : 'Add'}</SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default ServiceAgentFacilitiesModal;
