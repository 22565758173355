import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputText, BreadCrumbs, SecondaryButton, formatters } from '../../../Core/Controls';
import { getVechicleIDByRegNO } from '../../../Core/Services/InternalUser/ServiceAgent/breakdownService';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { CustomChip } from '../../../Core/Controls/Inputs/DatePicker';
import AddBreaddownModal from '../Maintenance/addBreakdown';
import AddEditDefectModal from '../Maintenance/addDefect';
import DriverChecks from '../Maintenance/driverCheck';
import Box from '@material-ui/core/Box';
import { getTitleData } from '../../../Core/Services/InternalUser/ControllerService/vehicleService';

import { Table, TableBody, TableContainer, TableRow, Paper, TableCell, Typography, TableHead, InputLabel } from '@material-ui/core';
import { NavigateBeforeSharp } from '@material-ui/icons';
import DriverVehicleModal from '../Maintenance/checkIn';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
const gridStyle = {
    title: {
        color: '#f50057'
    },
    header: {
        border: '1px solid rgba(0,0,0,0.2)'
    },
    tbHead: {
        padding: '5px 0px 6px 8px'
    },
    tbContent: {
        margin: '8px 0px 14px 0px;'
    }
};

const DriverDash = (props) => {
    const [show, setShow] = useState(false);
    const [state, setState] = useState({ isVehicle: false, regNumber: '', vehicleMakeModel: '' });
    let screenName = props.match.params.screenName.charAt(0).toUpperCase() + props.match.params.screenName.slice(1); // Caps First Letter

    const crumbs = show
        ? [
              { name: 'Home', path: '/' },
              { name: `${screenName}`, path: '/', active: true },
              { name: `${'Add ' + screenName}`, path: '/', active: true }
          ]
        : [
              { name: 'Home', path: '/' },
              { name: `${screenName}`, path: '/', active: true }
          ];

    const getVehicleByRegNo = async (e) => {
        const { name, value } = e.target;
        if (value.length > 4) {
            let resBrekDown = await getVechicleIDByRegNO(value);
            setState((st) => {
                let newSt = { ...st };
                if (resBrekDown.data?.list) {
                    newSt.isVehicle = resBrekDown.success;
                    newSt.filterVehicleID = resBrekDown.data?.list[0].vehicleID;
                    newSt.vehicleMakeModel = resBrekDown.data?.list[0].vehicleMakeModel;
                    newSt.regNumber = value;
                } else {
                    newSt.isVehicle = false;
                    newSt.filterVehicleID = '';
                    newSt.regNumber = '';
                    newSt.vehicleMakeModel = '';
                }
                return newSt;
            });
        } else {
            setState((st) => ({ ...st, isVehicle: false, regNumber: null }));
        }
    };

    // Pull details
    useEffect(async () => {
        let res = await getTitleData(state.filterVehicleID);
        if (res.success) {
            let data = res.data.details[0];
            setState((st) => ({
                ...st,
                desc: data?.vehicleDescription
            }));
        }
    }, [state.isVehicle]);

    const onBtnClick = () => {
        setShow(true);
    };
    const onClose = () => {
        setShow(false);
        setState((st) => ({ ...st, isVehicle: false, regNumber: null, vehicleMakeModel: null }));
    };

    const onCheckInSucc = (mileage) => {
        // setState((st) => ({ ...st, isVehicle: false }));
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" />

            <Grid container>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <div style={gridStyle.header}>
                            <div style={gridStyle.title}>
                                <Grid container style={{ alignItems: 'center', padding: '0px 6px' }}>
                                    <Grid xs={12} item style={{ alignItems: 'center' }}>
                                        <Typography variant="h6" color="secondary" style={gridStyle.tbHead}>
                                            {show ? state.desc?.toUpperCase() || '' : screenName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <Table aria-label="simple table" size="medium">
                            <TableBody>
                                <TableRow key={1}>
                                    <TableCell style={{ color: 'inherit' }} width="75%">
                                        {!show ? (
                                            <Grid container>
                                                <Grid item xs={12} md={5} sm={6} className="height-58">
                                                    <div className="grid-filter">
                                                        <InputLabel shrink>Search Reg Number</InputLabel>
                                                        <InputText
                                                            forceEditable
                                                            placeholder="Search"
                                                            onChange={getVehicleByRegNo}
                                                            value={state.filterText}
                                                            autoFocus={true}
                                                            inputProps={{ style: { textTransform: state.filterText ? '' : 'uppercase' } }}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Box width="100%" />
                                                {state.isVehicle && (
                                                    <Grid item xs={12} md={5} sm={6} className="height-45">
                                                        <InputLabel shrink>{'\u{2800}'}</InputLabel>
                                                        <SecondaryButton onClick={onBtnClick}>Add {screenName}</SecondaryButton>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        ) : screenName.toLowerCase() === 'breakdown' ? (
                                            <AddBreaddownModal
                                                filterVehicleID={state.filterVehicleID}
                                                regNumber={state.regNumber}
                                                breakdownType={props.location.state.breakdownType}
                                                onClose={onClose}
                                            />
                                        ) : screenName.toLowerCase() === 'defects' ? (
                                            <AddEditDefectModal filterVehicleID={state.filterVehicleID} regNumber={state.regNumber} onClose={onClose} />
                                        ) : screenName.toLowerCase() === 'driver check' ? (
                                            <DriverChecks filterVehicleID={state.filterVehicleID} regNumber={state.regNumber} onClose={onClose} />
                                        ) : screenName.toLowerCase() === 'check in' ? (
                                            <DriverVehicleModal
                                                fromPage={'driver'}
                                                regNumber={state.regNumber}
                                                onCheckInSucc={onCheckInSucc}
                                                vehicleID={state.filterVehicleID}
                                                onClose={onClose}
                                            />
                                        ) : null}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
};

export default DriverDash;
