import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import FileUploaderButton from '../../../Core/Controls/GenericFileUploader';
import { getJobRequireScheduledDocuments } from '../../../Core/Services/InternalUser/ControllerService/jobDetailsService';

const RequiredDocumentsModal = (props) => {
    const [jobdocument, setJobDocument] = useState({
        list: [],
        refresherFlag: ''
    });
    useEffect(async () => {
        if (props.rowData.scheduleID) {
            let res = await getJobRequireScheduledDocuments(props.rowData.scheduleID);
            setJobDocument((jobdocument) => ({ ...jobdocument, list: res.data.list }));
        }
    }, [props.rowData.scheduleID, jobdocument.refresherFlag]);

    const dataReload = () => {
        setJobDocument((jobdocument) => ({ ...jobdocument, refresherFlag: new Date() }));
        props?.onFormSubmit && props?.onFormSubmit(true);
    };

    return (
        <Grid container>
            {jobdocument.list.length > 0 ? (
                jobdocument.list.map((m) => {
                    return (
                        <Grid item xs={6}>
                            <div style={{ paddingTop: '5px' }}>
                                {m.docName}
                                <span style={{ position: 'relative', top: '-7px', left: '7px', marginRight: '15px' }}>
                                    <FileUploaderButton
                                        recordId={m.scheduleDocID}
                                        fileUploaderConfigName="ScheduleDocumentsRequired"
                                        fileCount={m.fileCount}
                                        gridRelaod={dataReload}
                                        docIDPassed={true}
                                    />
                                </span>
                            </div>
                        </Grid>
                    );
                })
            ) : (
                <p>No Required Documents Found...</p>
            )}
        </Grid>
    );
};

export default RequiredDocumentsModal;
