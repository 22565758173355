import { postWithAuth, getWithAuth, putWithAuth } from '../../../Basic/HttpService';

export const getFaultTypesByID = async (Id) => {
    let res = await getWithAuth(`faultTypes/${Id}`);
    return res;
};
export const getFaultTypesById = async (Id) => {
    let res = await getWithAuth(`faultTypes/CustomerID=${Id}`);
    return res;
};

export const postFaultTypes = async (data) => {
    let res = await postWithAuth('faultTypes', data);
    return res;
};
