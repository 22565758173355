import { InputLabel, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { InputText } from '..';
import { useLoginInfo } from '../../Hooks/useLoginInfo';

const SelectBox = (props) => {
    const { List, inputLabel, forceEditable, isRequired, isDisabled, ...rest } = props;
    let { isReadOnlyUser } = useLoginInfo();
    forceEditable && (isReadOnlyUser = false);
    let dropdownValueName = '';
    if (rest.value) {
        dropdownValueName = List.find((l) => l.id === rest.value)?.name;
    }
    let insertEmpty = rest.insertEmpty;
    if (insertEmpty === undefined) {
        insertEmpty = true;
    }
    return isReadOnlyUser ? (
        <InputText {...rest} value={dropdownValueName} />
    ) : isDisabled ? (
        <div className="input-style">
            <InputLabel shrink className="input_label">
                {inputLabel}
            </InputLabel>
            <div className="input_value">{dropdownValueName ? dropdownValueName : 'N/A'}</div>
        </div>
    ) : (
        <>
            {inputLabel && (
                <InputLabel required={isRequired || false} shrink>
                    {inputLabel}
                </InputLabel>
            )}

            <TextField select size="small" variant="outlined" {...rest} fullWidth>
                {insertEmpty && (
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                )}
                {List.length > 0 &&
                    List.map((option) => (
                        <MenuItem key={option.id} value={option.id || ''}>
                            {option.name}
                        </MenuItem>
                    ))}
            </TextField>
        </>
    );
};

export default SelectBox;
