import { postWithAuth, getWithAuth } from '../../Basic/HttpService';
import moment from 'moment';
import _ from 'lodash';

const downloadFileWithExt = async (res, fileExt, fileName = 'Report') => {
    if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.getElementById('tempDownloadPDFLink') || document.createElement('a');
        link.id = 'tempDownloadPDFLink';
        link.href = url;
        link.setAttribute('download', `${fileName}${moment().format('DD-MMM-YYYY HH:mm')}.${fileExt}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        return true;
    }
};

export const GetVORReportingExcelFile = async (params) => {
    let res = await getWithAuth(
        `VORVehicles/GetExcelFile?startDate=${params.fromDate}&endDate=${params.toDate}&Id=${params.customerID}&vehicleCategoryID=${params.vehicleCategoryID}&makeID=${params.makeID}`,
        {
            responseType: 'blob',
            returnOrgRes: true
        }
    );
    downloadFileWithExt(res, params.fileExt, 'VOR_Reporting');
};
export const GetDefectReportingExcelFile = async (params) => {
    let res = await getWithAuth(`VehicleDefects/GetDefectExcelFile?startDate=${params.fromDate}&endDate=${params.toDate}&Id=${params.customerID}`, {
        responseType: 'blob',
        returnOrgRes: true
    });
    downloadFileWithExt(res, params.fileExt);
};
export const GetBreakdownReportingExcelFile = async (params) => {
    let res = await getWithAuth(`VehicleBreakdowns/GetBreakdownExcelFile?startDate=${params.fromDate}&endDate=${params.toDate}&Id=${params.customerID}`, {
        responseType: 'blob',
        returnOrgRes: true
    });
    downloadFileWithExt(res, params.fileExt, 'Breakdown');
};
export const GetJobReportingExcelFile = async (params) => {
    let res = await getWithAuth(`VehicleJob/GetJobExcelFile?startDate=${params.fromDate}&endDate=${params.toDate}&Id=${params.customerID}`, {
        responseType: 'blob',
        returnOrgRes: true
    });
    downloadFileWithExt(res, params.fileExt, 'JobReporting');
};

export const GetSchedulesExcelFile = async (params) => {
    let res = await getWithAuth(
        `Schedules/DownLoadExcelFile?status=${params.status}
    &mode=${params.mode}
    &startDate=${params.startDate}
    &endDate=${params.endDate}`,
        {
            responseType: 'blob',
            returnOrgRes: true
        }
    );
    downloadFileWithExt(res, params.fileExt, 'Schedule');
};

export const GetOutstandingDocSchedulesExcelFile = async (params) => {
    let temp = '';

    if (!_.isEmpty(params.filterValues)) {
        for (const property in params.filterValues) {
            temp = temp + `&${property}=${params.filterValues[property]}`;
        }
    }
    let res = await getWithAuth(`Schedules/OutstandingScheduleDoc/DownLoadExcelFile?received=${params.received}${temp}`, {
        responseType: 'blob',
        returnOrgRes: true
    });
    downloadFileWithExt(res, params.fileExt, 'OutstandingDoc');
};

export const GetHireContractExcel = async (params) => {
    let temp = '';

    if (!_.isEmpty(params.filterValues)) {
        for (const property in params.filterValues) {
            temp = temp + `&${property}=${params.filterValues[property]}`;
        }
    }
    let res = await getWithAuth(
        `HireFleetContract/HireFleet_List?fileType=excel&FreeStock=${params.FreeStock}${
            params.vehicleJobStatus ? `&vehicleJobStatus=${params.vehicleJobStatus}` : ''
        }${temp}`,
        {
            responseType: 'blob',
            returnOrgRes: true
        }
    );
    downloadFileWithExt(res, params.fileExt, 'HireContract');
};

export const GetContractMaintenanceExcel = async (params) => {
    let temp = '';

    if (!_.isEmpty(params.filterValues)) {
        for (const property in params.filterValues) {
            temp = temp + `&${property}=${params.filterValues[property]}`;
        }
    }
    let res = await getWithAuth(`ContractMaintenance/ContractMaintenance_List?fileType=excel&FreeStock=${params.FreeStock}${temp}`, {
        responseType: 'blob',
        returnOrgRes: true
    });
    downloadFileWithExt(res, params.fileExt, 'ContractMaintenance');
};
