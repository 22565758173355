import React, { useState, useEffect, useMemo } from "react";
import { getAllWorkshopresources } from "../../../Core/Services/InternalUser/VOR/workshopResourcesService";
import DataGrid from "../../../Core/Controls/Grid";
import DialogComp from "../../../Core/Dialog/DialogComp";
import WorkshopResourcesModal from "./WorkshopResourcesModal";
import { formatters } from "../../../Core/Controls";
import { BreadCrumbs, AddButton, EditButton } from "../../../Core/Controls";
import "../../commonStyle.scss";

const crumbs = [
  { name: "Home", path: "/" },
  { name: "VOR", path: "/" },
  { name: "Workshop Resources ", active: true },
];

const base_columns = [
  { key: "action", name: "Action", width: "5%" },
  { key: "workshopResourceName", name: "Resource Name" },
  { key: "worktshopResourceActive", name: "Is Active ?", formatter: formatters.YesNo },
];

function WorkshopResources(props) {
  const [state, setState] = useState({
    WorkshopResourcesList: [],
    loader: true,
    open: false,
  });

  useEffect(async () => {
    pullWorkshopResources();
  }, []);

  const pullWorkshopResources = async () => {
    let res = await getAllWorkshopresources();
    setState((state) => ({
      ...state,
      WorkshopResourcesList: res.data,
      loader: false,
    }));
  };

  const workshopResourcesHandler = (params) => {
    setState((state) => ({ ...state, open: true, workshopResourceID: params.workshopResourceID }));
  };

  const ActionButtons = (data) => {
    return (
      <div style={{ textAlign: "center" }}>
        <EditButton onClick={() => workshopResourcesHandler(data.row)} />
      </div>
    );
  };

  const columns = useMemo(() => {
    let newColumn = [...base_columns];
    let actionCol = newColumn.find((item) => item.key === "action");
    if (actionCol) {
      actionCol.formatter = ActionButtons;
    }
    return newColumn;
  });

  const title = state.workshopResourceID ? "Update Workshop Resource" : "Add Workshop Resource";

  const closeModal = (res) => {
    setState((state) => ({ ...state, open: false }));
    res && pullWorkshopResources();
  };

  return (
    <div className="screen">
      <BreadCrumbs crumbs={crumbs} />
      <AddButton onClick={workshopResourcesHandler} className="add_btn" />
      <DataGrid columns={columns} rows={state.WorkshopResourcesList} isRowHovered={true} enableSearch={true} loadingData={state.loader} />
      {state.open ? (
        <DialogComp title={title} onClose={() => closeModal(false)} maxWidth="md">
          <WorkshopResourcesModal workshopResourceID={state.workshopResourceID} onClose={() => closeModal(true)} />
        </DialogComp>
      ) : null}
    </div>
  );
}
export default WorkshopResources;
