import React, { useEffect, useMemo, useState } from 'react';
import AddFleetInsurance from './addfleetInsurance';
import { DataGrid, EditButton, formatters } from '../../../../../Core/Controls';
import { fleetInsuranceList } from '../../../../../Core/Services/InternalUser/fleetInsurance';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
import { AppStorage } from '../../../../../Core/Basic/storage-service';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: '5%'
    },
    { key: 'fleetInsuranceInsurerName', name: 'Name' },
    { key: 'fleetInsuranceInsurerTelephone', name: 'Phone' },
    { key: 'fleetInsurancePolicyNumber', name: 'Policy Number' },
    { key: 'fleetInsuranceStartDate', name: 'Period From', formatter: formatters.Date_DDMMYYYY },
    { key: 'fleetInsuranceExpiryDate', name: 'Period To', formatter: formatters.Date_DDMMYYYY },
    { key: 'fleetInsuranceBusinessEstablished', name: 'Vehicle Years' },
    { key: 'fleetInsuranceBrokerName', name: 'Broker ' },
    { key: 'fleetInsuranceBrokerTelephone', name: 'Broker Phone' }
];

const FleetInsurance = (props) => {
    const [state, setState] = useState({
        showDialog: false,
        resList: [],
        isReload: null
    });
    let checkhaveAccess = AppStorage.getAllowAcces();

    const dataFormatter = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton toolTipTitle="Edit Insurance" onClick={() => handleDialog(row.fleetInsuranceId)} />
            </div>
        );
    };

    const pullListData = async () => {
        // let res = await fleetInsuranceList(props.customerID);
        setState((st) => ({
            ...st,
            // resList: res.data.list
            isReload: new Date()
        }));
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);

    const handleDialog = (data) => {
        setState((st) => ({
            ...st,
            showDialog: true,
            fleetInsuranceId: data
        }));
    };

    const closeDialog = (res) => {
        setState((st) => ({
            ...st,
            showDialog: false
        }));
        res && pullListData();
    };
    const gridBtnTitle = 'Add Insurance';

    let getUrl = `FleetInsurances?CustomerID=${props.customerID}`;

    return (
        <div>
            {state.showDialog ? (
                <AddFleetInsurance onClose={closeDialog} customerID={props.customerID} fleetInsuranceId={state.fleetInsuranceId} />
            ) : (
                // <DataGrid
                //     columns={columns}
                //     enableSearch={true}
                //     btnTitle={gridBtnTitle}
                //     offset={280}
                //     onBtnClick={() => handleDialog(null)}
                //     rows={state.resList}
                // />
                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl={getUrl}
                    btnTitle={`${checkhaveAccess?.CanUpdateCustomerFleetInsurance ? gridBtnTitle : ''}`}
                    onBtnClick={() => handleDialog(null)}
                    isReload={state.isReload}
                />
            )}
        </div>
    );
};

export default FleetInsurance;
