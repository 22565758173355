import { postWithAuth, putWithAuth } from '../Basic/HttpService';

export const postSignature = async (sign) => {
    let res = await postWithAuth(`/SaveSignature`, { signature: sign });
    return res;
};

export const postSignatureStatus = async (ID) => {
    let res = await putWithAuth(`/SetSignatureStatus/${ID}`);
    return res;
};
