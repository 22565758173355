import { postWithAuth, getWithAuth } from "../../Basic/HttpService";

export const getAllVehicleCheckSection = async () => {
    let res = await getWithAuth(`VehicleCheckSection`);
    return res;
};

export const getVehicleCheckSectionById = async (id) => {
    let res = await getWithAuth(`VehicleCheckSection/${id}`);
    return res;
};

export const postVehicleCheckSectionData = async (data) => {
    let res = await postWithAuth(`VehicleCheckSection`, data);
    return res;
};

export const getAllVehicleCheckSectionDropDown = async () => {
    let res = await getWithAuth(`VehicleCategories`);
    return res;
};
