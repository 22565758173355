import React, { useEffect, useMemo, useState } from 'react';
import { AddButton, DataGrid, EditButton, formatters, DeleteButton } from '../../../../Core/Controls';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import '../../../commonStyle.scss';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { AddEditLicence } from './AddLicenceChecks';
import { getLicenceCheckList } from '../../../../Core/Services/InternalUser/licenceCheckService';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { Grid } from '@material-ui/core';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: 80
    },
    { key: 'licenceCheckDateChecked', name: 'Date of Check', formatter: formatters.Date_DDMMYY },
    { key: 'licenceCheckLicenceExpiryDate', name: 'Date expires', formatter: formatters.Date_DDMMYY },
    { key: 'licenceCheckPoints', name: 'Points (at time) ' },
    { key: 'licenceCheckPhotoExpiryDate', name: 'Photocard Expiry', formatter: formatters.Date_DDMMYY },
    { key: 'licenceCheckLicenceExpiryDate', name: 'License Expiry', formatter: formatters.Date_DDMMYY },
    { key: 'licenceCheckRiskLevel', name: 'Risk Level' }
];

export const LicenceChecks = (props) => {
    const [state, setState] = useState({ show: false, list: [], licenceCheckID: null, isReload: '' });

    const gridBtnTitle = 'Add Licence Checks ';

    const pullListData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const rederEdit = (val) => {
        setState((st) => ({
            ...st,
            licenceCheckID: val,
            show: !st.show
        }));
    };

    const dataFormatter = (data, row) => {
        return (
            <Grid container justify="center">
                <Grid item>
                    <EditButton onClick={() => rederEdit(row.licenceCheckID)} />;
                </Grid>
            </Grid>
        );
    };
    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);

    const handleAdd = () => {
        setState((st) => ({
            ...st,
            show: !st.show,
            licenceCheckID: null
        }));
    };

    const hadleClose = (res) => {
        setState((st) => ({
            ...st,
            show: !st.show,
            licenceCheckID: null
        }));
        res && pullListData();
    };

    return (
        <>
            {state?.show ? (
                <AddEditLicence onClose={hadleClose} licenceCheckID={state.licenceCheckID} driverID={props.driverID} />
            ) : (
                // <DataGrid
                //     rows={state.list}
                //     columns={columns}
                //     // loadingData={state.showLoader}
                //     enableSearch={true}
                //     btnTitle={gridBtnTitle}
                //     fileUploaderConfigName="LicenceChecks"
                //     onBtnClick={() => handleAdd(null)}
                //     offset={props.fromTab ? 290 : 280}
                // />

                <GijgoGrid
                    columns={columns}
                    getUrl={`LicenceCheck`}
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    isReload={state.isReload}
                    onBtnClick={() => handleAdd(null)}
                    btnTitle={gridBtnTitle}
                    fileUploaderConfigName="LicenceChecks"
                    offset={props.fromTab ? 290 : 280}
                />
            )}
        </>
    );
};
