import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, Typography } from '@material-ui/core';
import { Table, TableBody, TableHead, Divider } from '@material-ui/core';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { SingleSelect, SimpleField, SecondaryCheckbox } from '../../../../../../Core/Controls';
import { StyledTableCell, StyledTableRow } from '../../../../../../Core/Controls/Table/StyledCell&Row';
import { some } from 'lodash';

import { useWindowSize } from '../../../../../../Core/Hooks/useWindowSize';

function SalesLines(props) {
    const {
        invoiceLinesCheckBox,
        invoiceLinesCheckField,
        PartSelectChange,
        handleFields,
        invoiceLines,
        partsDetails,
        vatRate,
        invoiceItemVATRateID,
        onInvoiceItemChange
    } = props;
    const [width, height] = useWindowSize();

    let checkSize = width <= 1760 ? 2 : 1;

    return (
        <div>
            <TableContainer
                component={Paper}
                style={{
                    minWidth: 900
                }}
            >
                <Table style={{ height: '100%' }}>
                    {invoiceLines.map((m, i) => {
                        let temp = 0;
                        return (
                            <TableBody>
                                {/* <React.Fragment> */}
                                <StyledTableRow>
                                    <StyledTableCell colSpan={i == 0 ? 4 : 6}>
                                        <Typography variant="h6" color="secondary">
                                            Labour
                                        </Typography>
                                    </StyledTableCell>
                                    {i == 0 && (
                                        <StyledTableCell colSpan={2}>
                                            <FormHelperText shrink>VAT Rate</FormHelperText>
                                            <SingleSelect value={invoiceItemVATRateID} onChange={onInvoiceItemChange} options={vatRate} />
                                        </StyledTableCell>
                                    )}
                                </StyledTableRow>
                                {/* {i === 0 && (
                                    <StyledTableRow>
                                        
                                        <StyledTableCell align="left">Labour Description</StyledTableCell>
                                        <StyledTableCell width="8%">Hours</StyledTableCell>
                                        <StyledTableCell width="10%">Labour Rate</StyledTableCell>

                                        <StyledTableCell width="10%">Ex VAT Total</StyledTableCell>
                                        <StyledTableCell width="15%">VAT Rate</StyledTableCell>
                                        <StyledTableCell width="10%">VAT Amount</StyledTableCell>
                                    </StyledTableRow>
                                )} */}
                                <StyledTableRow>
                                    <StyledTableCell align="left">
                                        {/* <FormHelperText shrink>Labour Description</FormHelperText> */}
                                        <Typography variant="subtitle2">
                                            {m.labourDescription || 'N/A'}
                                            <br /> Cost Type: {m.costType || 'N/A'}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell width={width > 1024 ? '8%' : '10%'}>
                                        <SimpleField
                                            inputLabel="Hours"
                                            name="labourHours"
                                            placeholder="Hours"
                                            onChange={invoiceLinesCheckField(null, m.itemID)}
                                            value={m.labourHours}
                                            // type="number"
                                            disabled
                                            // isDisabled={true}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell width={width > 1024 ? '10%' : '15%'}>
                                        <SimpleField
                                            inputLabel="Labour Rate"
                                            name="labourRate"
                                            placeholder="Labour Rate"
                                            onChange={invoiceLinesCheckField(null, m.itemID)}
                                            value={m.labourRate}
                                            // type="number"
                                            disabled
                                            // isDisabled={true}
                                        />
                                    </StyledTableCell>
                                    {/* <StyledTableCell width="10%">
                                            <SimpleField
                                                inputLabel="Discount %"
                                                name="labourDiscount"
                                                placeholder="Discount %"
                                                onChange={invoiceLinesCheckField(null, m.itemID)}
                                                value={m.labourDiscount}
                                                type="number"
                                            />
                                        </StyledTableCell> */}
                                    <StyledTableCell width={width > 1024 ? '10%' : '15%'}>
                                        <SimpleField
                                            inputLabel="Ex VAT Total"
                                            name="labourExVAT"
                                            placeholder="Ex VAT Total"
                                            onChange={invoiceLinesCheckField(null, m.itemID)}
                                            value={m.labourExVAT}
                                            disabled
                                            // isDisabled={true}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell width="15%">
                                        {/* <FormHelperText shrink>VAT Rate</FormHelperText> */}
                                        <SingleSelect
                                            value={m.invoiceItemVATRateID}
                                            onChange={invoiceLinesCheckField(m.labourExVAT, m.itemID)}
                                            options={vatRate}
                                            inputLabel="VAT Rate"
                                        />
                                        <FormHelperText error>{m.vatIdError ? 'This Field is Required' : null}</FormHelperText>
                                    </StyledTableCell>
                                    <StyledTableCell width={width > 1024 ? '10%' : '15%'}>
                                        <SimpleField
                                            inputLabel="VAT Amount"
                                            name="invoiceItemVAT"
                                            placeholder="VAT"
                                            onChange={invoiceLinesCheckField(null, m.itemID)}
                                            value={m.invoiceItemVAT}
                                            disabled
                                            // isDisabled={true}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                                {some(partsDetails, (r) => r.jobItemID == m.itemID) && (
                                    <React.Fragment>
                                        <StyledTableRow>
                                            {/* <StyledTableCell colSpan={1}>&nbsp;</StyledTableCell> */}
                                            <StyledTableCell colSpan={6}>
                                                <Typography variant="h6" color="secondary">
                                                    Parts
                                                </Typography>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        {partsDetails.map((p, k) => {
                                            if (p.jobItemID == m.itemID) {
                                                temp++;
                                                return (
                                                    <StyledTableRow>
                                                        {/* <StyledTableCell width="5%">&nbsp;</StyledTableCell> */}
                                                        <StyledTableCell>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={9} md={10} lg={11}>
                                                                    {temp == 1 && <FormHelperText shrink>Part Number </FormHelperText>}
                                                                    <Typography variant="subtitle2">
                                                                        {p.partNumber || 'N/A'}
                                                                        <br /> Description:{p.partDescription || 'N/A'}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <SimpleField
                                                                inputLabel={temp == 1 ? 'Quantity' : null}
                                                                name="qty"
                                                                placeholder="Quantity"
                                                                onChange={handleFields(p.partID)}
                                                                value={p.qty}
                                                                // type="number"
                                                                disabled={true}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <SimpleField
                                                                inputLabel={temp == 1 ? 'Unit Price' : null}
                                                                name="unitPrice"
                                                                placeholder="Unit Price"
                                                                onChange={handleFields(p.partID)}
                                                                value={p.unitPrice}
                                                                // type="number"
                                                                disabled={true}
                                                            />
                                                        </StyledTableCell>

                                                        <StyledTableCell>
                                                            <SimpleField
                                                                inputLabel={temp == 1 ? 'Ex VAT Total' : null}
                                                                name="total"
                                                                placeholder="Ex VAT Total"
                                                                onChange={handleFields(p.partID)}
                                                                value={p.total}
                                                                disabled={true}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {/* {temp == 1 && <FormHelperText shrink>VAT Rate</FormHelperText>} */}
                                                            <SingleSelect
                                                                value={p.invoiceItemVATRateID}
                                                                onChange={PartSelectChange(p.total, p.partID)}
                                                                options={vatRate}
                                                                inputLabel={temp == 1 ? 'VAT Rate' : null}
                                                            />
                                                            <FormHelperText error>{p.vatIdError ? 'This Field is Required' : null}</FormHelperText>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <SimpleField
                                                                inputLabel={temp == 1 ? 'VAT Amount' : null}
                                                                name="invoiceItemVAT"
                                                                placeholder="VAT"
                                                                onChange={handleFields(p.partID)}
                                                                value={p.invoiceItemVAT}
                                                                disabled={true}
                                                            />
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            }
                                        })}
                                    </React.Fragment>
                                )}
                            </TableBody>
                        );
                    })}
                </Table>
            </TableContainer>
        </div>
    );
}
export default SalesLines;
