import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, Typography } from '@material-ui/core';
import { getServiceAgentById } from '../../../../Core/Services/InternalUser/ServiceAgent/serviceAgentService';
import { formatters } from '../../../../Core/Controls';
import { getEndUserByID } from '../../../../Core/Services/InternalUser/ControllerService/endUserService';
import { CircularProgress } from '@material-ui/core';

function EndUserDetails(props) {
    const [state, setState] = useState({
        endUserID: '',
        endUserCustomerID: '',
        endUserName: '',
        endUserAddress1: '',
        endUserAddress2: '',
        endUserTown: '',
        endUserCounty: '',
        endUserCountryCode: '',
        endUserPostCode: '',
        endUserContactName: '',
        endUserContactPhone: '',
        endUserContactEmail: ''
    });

    useEffect(async () => {
        if (props.endUserID) {
            let res = await getEndUserByID(props.endUserID);
            if (res.success) {
                const data = res.data.list[0];
                setState((st) => ({
                    ...st,
                    endUserID: data.endUserID,
                    endUserCustomerID: data.endUserCustomerID,
                    endUserName: data.endUserName,
                    endUserAddress1: data.endUserAddress1,
                    endUserAddress2: data.endUserAddress2,
                    endUserTown: data.endUserTown,
                    endUserCounty: data.endUserCounty,
                    endUserCountryCode: data.endUserCountryCode,
                    endUserPostCode: data.endUserPostCode,
                    endUserContactName: data.endUserContactName,
                    endUserContactPhone: data.endUserContactPhone,
                    endUserContactEmail: data.endUserContactEmail,
                    loader: true
                }));
            }
        }
    }, []);

    return (
        state.loader ? (
            <Grid container direction="row" spacing={2} style={{ minWidth: '600px', minHeight: '300px' }}>
                <Grid xs={12} item className="typo">
                    <FormHelperText>End User Name</FormHelperText>
                    <Typography>{state.endUserName}</Typography>
                </Grid>
                <Grid xs={12} item className="typo">
                    <FormHelperText>Address</FormHelperText>
                    <Typography>{formatters.addressFormatter(state.endUserAddress1, state.endUserAddress2, state.endUserCounty, state.endUserPostCode)}</Typography>
                </Grid>
                <Grid xs={12} item className="typo">
                    <FormHelperText>Email</FormHelperText>
                    <Typography variant="button">{formatters.mailFormatter(state.endUserContactEmail)}</Typography>
                </Grid>
                <Grid item xs={12} className="typo">
                    <FormHelperText>Phone</FormHelperText>
                    <Typography variant="button">{formatters.phoneNumberFormatter(state.endUserContactPhone)}</Typography>
                </Grid>
            </Grid>
        ) : (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '500px', height: '300px' }}>
                <CircularProgress style={{ margin: 'auto' }} />
            </div>
        )
    );
}

export default EndUserDetails;
