import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, Grid, InputLabel, Tooltip } from '@material-ui/core';
import SignaturePad from 'react-signature-canvas';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useRef, useState } from 'react';
import { SecondaryButton } from '../Controls';
import { AppStorage } from '../Basic/storage-service';
import { useEffect } from 'react';
import './userSignature.scss';

export const UserSignatureReminder = (props) => {
    const [state, setState] = useState();
    const checkStatus = (value) => {
        setState({ chosenID: value });
        if (value === 1) {
            props.closeSign();
            props.onClose();
        } else if (value === 2) {
            props.postData(null, value);
            props.onClose();
        } else if (value === 3) {
            props.postData(null, value);
            props.onClose();
        }
    };
    return (
        <>
            <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Do you want to set the default signature?</DialogTitle>
                <DialogActions>
                    <Grid container spacing={1} style={{ padding: '15px' }}>
                        <Grid item xs={4}>
                            <Button onClick={() => checkStatus(1)} color="primary" variant="contained" fullWidth>
                                Yes
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button onClick={() => checkStatus(2)} color="secondary" variant="contained" fullWidth>
                                No
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button onClick={() => checkStatus(3)} variant="contained" fullWidth style={{ background: '#5a5a5a', color: 'white' }}>
                                Later
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};

const UserSignature = (props) => {
    const [state, setState] = useState({ techniciansign: null, signAvailable: true });
    const signCanvas = useRef();
    const clear = () => {
        setState((st) => ({
            ...st,
            techniciansign: null,
            signAvailable: false,
            imgSrc: null
        }));
        if (signCanvas?.current !== undefined) {
            signCanvas?.current.clear();
        }
    };
    const GetTechnicianSign = () => {
        let fields = state.fields;
        setState((st) => {
            const nst = {
                ...st,
                techniciansign: signCanvas.current.toDataURL(),
                fields: fields
            };
            return nst;
        });
    };

    useEffect(() => {
        setState((st) => ({ ...st, imgSrc: AppStorage.getSignature(), signAvailable: AppStorage.getSignature() === 'absent' ? false : true }));
    }, []);

    const handleData = async () => {
        // state.techniciansign && AppStorage.setSignature(state.techniciansign);
        state.techniciansign && props.postData(state.techniciansign, 1);
        (state.techniciansign || state.signAvailable) && props.onClose();
    };
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ position: 'relative' }}>
                        <InputLabel shrink>Signature</InputLabel>
                        <div
                            style={{
                                position: 'absolute',
                                right: 0
                            }}
                        >
                            <Tooltip title="Clear Signature">
                                <DeleteIcon
                                    onClick={clear}
                                    style={{
                                        cursor: 'pointer',
                                        marginTop: 5
                                    }}
                                />
                            </Tooltip>
                        </div>
                        {state.signAvailable ? (
                            <img src={state.imgSrc} className="sigContainer" alt="Broken Signature"></img>
                        ) : (
                            <div className="sigContainer">
                                <SignaturePad
                                    ref={signCanvas}
                                    canvasProps={{ className: 'sigPad' }}
                                    onEnd={() => {
                                        GetTechnicianSign();
                                    }}
                                    clearOnResize={false}
                                />
                            </div>
                        )}
                    </div>
                </Grid>
                <Grid xs={12}>
                    {/* <FormHelperText error>{!state.techniciansign && 'Please draw your signature'}</FormHelperText> */}
                    <FormHelperText error>Please draw your signature inside the box</FormHelperText>
                </Grid>
                <Grid container spacing={1} style={{ marginTop: 5 }}>
                    <Grid xs={6} item>
                        <SecondaryButton onClick={handleData}>SAVE</SecondaryButton>
                    </Grid>
                    <Grid xs={6} item>
                        <SecondaryButton onClick={props.onClose}>CANCEL</SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default UserSignature;
