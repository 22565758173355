import { postWithAuth, getWithAuth } from '../../Basic/HttpService';

export const getAllCostCategories = async () => {
    let res = await getWithAuth(`CostCategories`);
    return res;
};

export const getCostCategoriesById = async (id) => {
    let res = await getWithAuth(`CostCategories/${id}`);
    return res;
};

export const postCostCategoriesData = async (data) => {
    let res = await postWithAuth(`CostCategories`, data);
    return res;
};
