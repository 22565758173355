import React, { useEffect, useState } from 'react';
import { getAllStickies, GetAllStickyNotes } from '../../../../Core/Services/InternalUser/ControllerService/noteService';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import './NotesandSticky.scss';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import WorkIcon from '@material-ui/icons/Work';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(1)
        }
    }
}));

export const AllStickyNotes = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({
        stickyList: []
    });

    useEffect(() => {
        const fetchStickies = async () => {
            // Check if required props are provided before fetching stickies
            if (
                props.frompage &&
                (props.NoteVehicleID || props.NoteServiceAgID || props.customerID || props.NoteDriverID || props.noteRefsGuidID || props.depotID)
            ) {
                try {
                    const res = await GetAllStickyNotes(props.frompage, props.customerID, props.depotID, props.jobID, props.NoteVehicleID);
                    setState((st) => ({ ...st, stickyList: res.data || [] }));
                } catch (error) {
                    console.error('Error fetching stickies:', error);
                }
            }
        };

        fetchStickies();
    }, [props.frompage, props.NoteVehicleID, props.NoteServiceAgID, props.customerID, props.NoteDriverID, props.noteRefsGuidID, props.depotID]);

    return (
        <div className="alert-container">
            {state.stickyList.length > 0 &&
                state.stickyList.map((q, index) => (
                    <Alert
                        key={q.id || index}
                        variant={props.variant || 'standard'}
                        className="alert-items"
                        style={{ backgroundColor: props.bgColor ? props.bgColor : q.backColour, color: q.colour }}
                        severity="warning"
                        icon={
                            q.type == 'D' ? (
                                <LocalShippingIcon fontSize="inherit" style={{ color: q.colour }} />
                            ) : q.type == 'C' ? (
                                <PermContactCalendarIcon fontSize="inherit" style={{ color: q.colour }} />
                            ) : q.type == 'J' ? (
                                <WorkIcon fontSize="inherit" style={{ color: q.colour }} />
                            ) : q.type == 'V' ? (
                                <DriveEtaIcon fontSize="inherit" style={{ color: q.colour }} />
                            ) : q.type == 'CM' ? (
                                <ErrorOutlineIcon fontSize="inherit" style={{ color: q.colour }} />
                            ) : (
                                <WarningIcon fontSize="inherit" style={{ color: q.colour }} />
                            )
                        }
                    >
                        {q.title}
                        {q.createdBy ? ` (${q.createdBy})` : ''}
                        <br />
                        {q.description}
                    </Alert>
                ))}
        </div>
    );
};

export default AllStickyNotes;
