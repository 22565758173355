import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getDriverList = async (id) => {
    let res = await getWithAuth(`VehicleDriverHistory/GetDetails?VehicleID=${id}`);
    return res;
};

export const getDriverHistoryList = async (id) => {
    let res = await getWithAuth(`VehicleDriverHistory?VehicleID=${id}`);
    return res;
};

export const postDriverMilege = async (data) => {
    let res = await postWithAuth(`VehicleDriverHistory`, data);
    return res;
};

export const postDriverCheckIn = async (data) => {
    let res = await postWithAuth(`DriverCheckIn`, data);
    return res;
};

export const postDriverCheckOut = async (data) => {
    let res = await postWithAuth(`DriverCheckIn/VehicleDriverCheckOut`, data);
    return res;
};
