import React, { useEffect, useMemo, useState } from 'react';
import { EditButton, DataGrid, formatters, BreadCrumbs } from '../../../Core/Controls';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import AddRecall from '../Controllers/Vehicles/VehicleRecall/addRecallScreen';
import RecallDashboard from '../Controllers/Vehicles/VehicleRecall/recallDashDetail';

import DialogComp from '../../../Core/Dialog/DialogComp';
import { AppStorage } from '../../../Core/Basic/storage-service';
import { Typography } from '@material-ui/core';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Recalls', active: true }
];

const basecolumns = [
    { key: 'action', name: 'Action', width: 80 },
    { key: 'recallStatus', name: 'Status', width: 80 },
    { key: 'regNumber', name: 'Reg No', width: 80 },
    { key: 'chassisNo', name: 'Chassis No', width: 80 },
    { key: 'cust', name: 'Customer', width: 120 },
    { key: 'depot', name: 'Depot', width: 220 },
    { key: 'engineer', name: 'Engineer', width: 120 },
    { key: 'expiryDate', name: 'Expiry Date', width: 80 },
    { key: 'recallDate', name: 'Recall Date', width: 80 },
    { key: 'recallDescription', name: 'Description', width: 220 },
    { key: 'recallNumber', name: 'Recall Number', width: 100 },
    { key: 'sa', name: 'Service Area', width: 120 }
];

const DashboardRecallsList = (props) => {
    const [state, setState] = useState({
        showRecall: false,
        selectedRow: {}
    });

    useEffect(async () => {
        // pullChecksData();
    }, []);
    let checkhaveAccess = AppStorage.getAllowAcces();

    const pullChecksData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const actionButton = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton
                    onClick={() =>
                        setState((st) => ({
                            ...st,
                            reCallId: row.recallID,
                            selectedRow: row,
                            showDetails: true
                        }))
                    }
                    toolTipTitle="Edit Recall"
                />
            </div>
        );
    };

    const onClose = (fromScreen, recallID) => {
        setState((st) => ({
            ...st,
            showRecall: false,
            showDetails: fromScreen === 'add' ? true : false,
            reCallId: recallID,
            selectedRow: {}
        }));
        pullChecksData();
    };

    const handleAdd = () => {
        setState((st) => ({
            ...st,
            showRecall: true
        }));
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = actionButton;
        }
        return tempCols;
    }, []);

    let loadUrl = `Recalls/RecallByStatus?StatusID=0`;

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            {state.showRecall ? (
                <DialogComp
                    title={`Add Recall (${state.selectedRow.regNumber} - ${state.selectedRow.vehicleMakeModel}(${state.selectedRow.cust}))`}
                    onClose={onClose}
                    maxWidth="md"
                >
                    <AddRecall onClose={onClose} VehicleID={state.selectedRow.vehicleID} />
                </DialogComp>
            ) : null}

            {state.showDetails ? (
                // <DialogComp title="Add Recall" onClose={onClose} maxWidth="xl" fullWidth>
                <>
                    <div>
                        <Typography
                            color="secondary"
                            variant="h5"
                        >{`${state.selectedRow.regNumber} - ${state.selectedRow.vehicleMakeModel} (${state.selectedRow.cust})`}</Typography>
                    </div>
                    <RecallDashboard onClose={onClose} reCallId={state.reCallId} vehicleDesc={state.selectedRow.regNumber} />
                </>
            ) : (
                // </DialogComp>
                <GijgoGrid
                    columns={columns}
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    // btnTitle={`${checkhaveAccess?.CanUpdateVehicleRecalls ? 'Add Recall' : ''}`}
                    onBtnClick={handleAdd}
                    isReload={state.isReload}
                    getUrl={loadUrl}
                />
            )}
        </div>
    );
};

export default DashboardRecallsList;
