import React, { useEffect, useState, useMemo } from 'react';
import { getAllBranch } from '../../../Core/Services/Admin/branchService';
import { useToast } from '../../../Core/Hooks/useToast';
import { AddButton, EditButton, DataGrid, BreadCrumbs, formatters } from '../../../Core/Controls';
import AddEditBranch from './addEditBranch';
import DialogComp from '../../../Core/Dialog/DialogComp';
import '../../commonStyle.scss';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Branches', path: '/', active: true }
];

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: '5%'
    },
    { key: 'branchName', name: 'Name', width: '15%' },
    { key: 'branchShortName', name: 'Short Name', width: '10%' },
    // { key: 'address2', name: 'Address2' },
    // { key: 'town', name: 'Town' },
    // { key: 'county', name: 'County' },
    // { key: 'postcode', name: 'Post Code' },
    { key: 'branchFax', name: 'Branch Fax', headerRenderer: formatters.ColumnHeaderAlignleft, formatter: formatters.ColumnTextAlignleft, width: '10%' },
    { key: 'branchPhone', name: 'Phone', headerRenderer: formatters.ColumnHeaderAlignleft, formatter: formatters.ColumnTextAlignleft, width: '9%' },
    {
        key: 'branchAddress',
        name: 'Address'
        // formatter: (data) => formatters.addressFormatter(data.row?.address1, data.row?.address2, data.row?.town, data.row?.country, data.row?.postcode)
    }
];

const BranchScreen = (props) => {
    const { showToastError } = useToast();

    const [state, setState] = useState({
        show: false,
        showLoader: true,
        branchList: [],
        branchData: null,
        isReload: null
    });

    const pullBranchAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    useEffect(() => {
        pullBranchAndUpdateState();
    }, []);

    const handleAdd = (record) => {
        setState((st) => ({
            ...st,
            show: true,
            branchData: record
        }));
    };

    const closeBranchModal = (res) => {
        setState((st) => ({
            ...st,
            show: false,
            showAsgPermi: false
        }));
        res && pullBranchAndUpdateState();
    };

    const dataFormatter = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleAdd(row)} toolTipTitle="Edit Branch" />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);

    const title = state.branchData ? 'Update Branch' : 'Add Branch';
    const getUrl = `branches`;
    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={getUrl}
                btnTitle="Add Branch"
                onBtnClick={handleAdd}
                isReload={state.isReload}
            />
            {state.show ? (
                <DialogComp title={title} onClose={() => closeBranchModal(false)} maxWidth="lg" overflow="auto">
                    <AddEditBranch branchData={state.branchData} onFormSubmit={() => closeBranchModal(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default BranchScreen;
