import { postWithAuth, getWithAuth,putWithAuth } from '../../Basic/HttpService';

export const getAllDelayReason = async () => {
    let res = await getWithAuth(`BookingDateDelayReasons`);
    return res;
};


export const postDelayReason = async (data) => {
    let res = await postWithAuth(`BookingDateDelayReasons`, data);
    return res;
};
