import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, withStyles } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { useWindowSize } from '../Hooks/useWindowSize';

const closeIconStyle = {
    marginRight: 7
};

const useStyles = makeStyles((theme) => ({
    modalHeight: {
        // minHeight: ``, // Default height calculation
        overflowY: 'auto', // Enable vertical scrolling
        '@media (min-width: 821px) and (max-width: 1024px)': {
            minHeight: `calc(100vh - 650px)` // Adjust height for devices between 821px and 1024px
        },
        '@media (min-width: 770px) and (max-width: 820px)': {
            minHeight: `calc(100vh - 450px)` // Adjust height for devices between 770px and 820px
        },
        '@media (min-width: 768px) and (max-width: 769px)': {
            minHeight: `calc(100vh - 280px)` // Adjust height for devices between 768px and 769px
        },
        '@media (min-width: 712px) and (max-width: 767px)': {
            minHeight: `calc(100vh - 400px)` // Adjust height for devices between 712px and 767px
        }
    }
}));

const GlobalCss = withStyles((theme) => ({
    '@global': {
        '.MuiDialogContent-root': {
            padding: '10px',
            [theme.breakpoints.down('sm')]: {
                padding: '5px' // Customize the padding for 'sm' and up screens
            }
        },
        ' .MuiDialog-container': {
            height: '100%',

            [theme.breakpoints.down('sm')]: {
                height: (props) => (props.fullScreen ? '100%' : 'auto')
            }
        }
    }
}))(() => null);

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

let DialogComp = ({ title, children, footer, onClose, maxWidth = 'sm', overflow = 'hidden', height = 'none', fullScreen, onlyTab, ...rest }) => {
    const [winWidth, winHeight] = useWindowSize();
    const classes = useStyles();

    return (
        <>
            <GlobalCss fullScreen={fullScreen} />
            <Dialog
                onClose={onClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                open={true}
                maxWidth={maxWidth}
                {...rest}
                fullScreen={fullScreen}
                // fullScreen={onlyTab ? (winWidth > 1024 ? false : true) : fullScreen}
            >
                <Box display="flex" alignItems="center" style={{ position: title ? 'relative' : 'absolute', right: '0px' }}>
                    <Box flexGrow={1} style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {title && <DialogTitle style={rest.titleStyle}>{title}</DialogTitle>}
                    </Box>
                    {!rest.hideCloseAction && (
                        <Box>
                            <IconButton onClick={onClose} style={closeIconStyle}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    )}
                </Box>
                <DialogContent
                    style={{ overflow: overflow, height: height }}
                    // className={onlyTab ? classes.modalHeight : ''}
                >
                    {children}
                </DialogContent>
                {footer && <DialogActions>{footer}</DialogActions>}
            </Dialog>
        </>
    );
};

export default DialogComp;
