import React, { useEffect, useState, useMemo } from 'react';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import VehicleManufacturerModel from './vehicleManufacturerModel';
import { getAllMake } from '../../../../Core/Services/InternalUser/makeService';
import { AddButton, EditButton, BreadCrumbs, DataGrid } from '../../../../Core/Controls';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import ReactDOM from 'react-dom';
import '../../../commonStyle.scss';

const makeScreenStyle = {
    gridImage: { width: 90, height: 'auto', backgroundSize: 'cover', marginLeft: 'auto' },
    gridImageContainer: { width: '100%', textAlign: 'center' },
    gridEditButton: { textAlign: 'center' },
    gridIconsize: { fontSize: 20 }
};
const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicles', active: true },
    { name: 'Makes', active: true }
];

const makeLogoRender = (data) => {
    const spn = document.createElement('span');
    let isBase64Image = data?.row.makeLogo?.includes('base64');
    const lnk = data.row.makeLogo ? (
        <div style={makeScreenStyle.gridImageContainer}>
            <img src={isBase64Image ? data.row.makeLogo : 'data:image/png;base64,' + data.row.makeLogo} alt={'logo'} style={makeScreenStyle.gridImage} />
        </div>
    ) : (
        <div style={makeScreenStyle.gridImageContainer}>No Image Found</div>
    );
    ReactDOM.render(lnk, spn);
    return spn;
};

const basecolumns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'makeDescription', name: 'Description' },
    { key: 'makeLogo', name: 'Logo', formatter: makeLogoRender, width: '25%', filterable: 'no' },
    { key: 'makeActive', name: 'Is Active ?', formatter: (e) => (e.row.makeActive ? 'Yes' : 'No'), width: '20%' }
];

const VehicleManufacturer = (props) => {
    const [state, setState] = useState({
        modalToggle: false,
        uservalue: null,
        makeList: [],
        showLoader: true
    });

    useEffect(async () => {
        pullMakeData();
    }, []);

    const pullMakeData = async () => {
        setState((state) => ({
            ...state,
            isReload: new Date()
        }));
    };

    const actionButton = (data, row) => {
        return (
            <div className="make-btn-update" style={makeScreenStyle.gridEditButton}>
                <EditButton onClick={() => handleMakeUpdate(row)} toolTipTitle="Edit Make" />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = actionButton;
        }
        return tempCols;
    }, []);

    const title = state.userId ? 'Update Make' : 'Add Make';

    const handleMakeUpdate = (params) => {
        setState((state) => ({ ...state, modalToggle: true, userId: params?.makeID }));
    };

    const closeMakeDialog = (res) => {
        setState((state) => ({ ...state, modalToggle: false }));
        res && pullMakeData();
    };

    return (
        <div className="screen">
            <div sm={12}>
                <BreadCrumbs crumbs={crumbs} />
            </div>
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={'makes'}
                btnTitle="Add Make"
                onBtnClick={() => handleMakeUpdate(null)}
                isReload={state.isReload}
            />
            {/* <DataGrid
                columns={columns}
                rows={state.makeList}
                rowHeight={70}
                isRowHovered={true}
                headerRowHeight={45}
                enableSearch={true}
                loadingData={state.showLoader}
                btnTitle="Add Make"
                onBtnClick={handleMakeUpdate}
                paginate
                offset={210}
                rowsPerPage={7}
            /> */}
            {state.modalToggle ? (
                <DialogComp title={title} onClose={() => closeMakeDialog(false)}>
                    <VehicleManufacturerModel makeID={state.userId} onClose={() => closeMakeDialog(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default VehicleManufacturer;
