import { Button, FormHelperText, Grid, InputLabel } from "@material-ui/core";
import React, { useState } from "react";
import DialogComp from "../../../../Core//Dialog/DialogComp";
import { InputText, SecondaryButton, MandatoryField } from "../../../../Core/Controls";
import "../../../commonStyle.scss";

const LicenseTypeDialog = (props) => {
  const [state, setState] = useState({
    inputValue: "",
    blank: false,
    id: null,
  });
  const validate = () => {
    if (state.inputValue === "") {
      setState((st) => ({ ...st, blank: true }));
    } else {
      props.childValue.btnText !== "ADD" ? props.updateRowData({ ID: state.id, text: state.inputValue }) : props.addRowData(state.inputValue);
      props.onClose();
    }
  };

  return (
    <DialogComp title={props.title} onClose={props.onClose} maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12} className="mandatory-fields">
          <MandatoryField
            inputLabel="Description"
            placeholder="Description"
            autoFocus
            onFocus={(event) => {
              setState((st) => ({
                ...st,
                inputValue: event.target.value,
                id: props.childValue.data.driverLicenceTypeID,
              }));
            }}
            onChange={(event) => {
              setState((st) => ({
                ...st,
                inputValue: event.target.value,
                blank: false,
              }));
            }}
            defaultValue={props.childValue.data.driverLicenceTypeDescription ?? ""}
            errors={state.blank ? "This field is required" : ""}
          />
        </Grid>
        <Grid item xs={12} className="submit_btn">
          <SecondaryButton onClick={validate}>{props.childValue.btnText === "ADD" ? "ADD" : "UPDATE"}</SecondaryButton>
        </Grid>
      </Grid>
    </DialogComp>
  );
};

export default LicenseTypeDialog;
