import React, { useEffect, useState, useMemo } from 'react';
import { EditButton, BreadCrumbs, formatters } from '../../../Core/Controls';
import '../../commonStyle.scss';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';
import { useHistory } from 'react-router-dom';

const basecolumns = [
    { key: 'action', name: 'Action', width: 80, addFileUploaderIcon: true },
    { key: 'ino', name: 'Number', width: 80 },
    { key: 'idate', name: 'Invoice Date', width: 110, formatter: formatters.Date_DDMMYYYY },
    { key: 'dd', name: 'Due Date', width: 90, formatter: formatters.Date_DDMMYYYY },
    { key: 'total', name: 'Total', width: 90, formatter: formatters.NumberWithPound, align: 'right' },
    { key: 'c', name: 'Customer', width: 700 }
];

const SalesInvoiceScreen = (props) => {
    const [state, setState] = useState({
        modalToggle: false,
        invoiceID: null,
        VehicleID: props.VehicleID || null,
        ServiceAgentID: props.ServiceAgentID || null,
        isReload: null
    });
    const history = useHistory();

    useEffect(async () => {
        let getUrl;
        if (props.fromPage === 'job') {
            getUrl = `Invoices/SalesInvoiceByStatus${props.JobID ? `?JobID=${props.JobID}` : ''}`;
        } else {
            if (props.VehicleID) {
                getUrl = `Invoices/SalesInvoiceByStatus${props.VehicleID ? `?VehicleID=${props.VehicleID}` : ''}`;
            } else {
                getUrl = `Invoices/SalesInvoiceByStatus?status=${props.statusCode}`;
            }
        }
        setState((st) => ({
            ...st,
            getUrl: getUrl
        }));
    }, []);

    useEffect(() => {
        if (props.jobStatusID) {
            setState((st) => ({
                ...st,
                isReload: new Date()
            }));
        }
    }, [props.jobStatusID]);

    const actionButton = (data, row) => {
        return (
            <div className="Invoice-btn-update">
                <EditButton onClick={() => handleInvoiceUpdate(row)} toolTipTitle="Edit Invoice" />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = actionButton;
        }
        if (!props.VehicleID || props.JobID) {
            tempCols.splice(1, 0, { key: 'regno', name: 'Reg No', width: 85 });
        }
        if (props.statusCode != 'u') {
            tempCols.splice(4, 0, { key: 'dp', name: 'Date Paid', width: 90, formatter: formatters.Date_DDMMYYYY });
        }
        return tempCols;
    }, []);

    const handleInvoiceUpdate = (val) => {
        history.push({
            pathname: `/update_sales_invoice/${val.invoiceID}`,
            search: ''
        });
    };
    return (
        <div className={props.fromPage === 'job' || props.VehicleID ? '' : 'screen'}>
            <>
                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list.map((m) => ({ ...m, total: m.vat + m.net })),
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl={state.getUrl}
                    idFieldName="invoiceID"
                    fileUploaderConfigName="SalesInvoice"
                    isReload={state.isReload}
                    offset={props.fromPage === 'job' ? 410 : 350}
                />
            </>
        </div>
    );
};

export default SalesInvoiceScreen;
