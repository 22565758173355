import { Grid, InputLabel, Paper, Typography } from '@material-ui/core';
import InspectionImages from '../inspectionCheckImagesModal';
import { EditInapectionContaxt } from './editInspectionProviderImports';
import { memo, useContext } from 'react';

const brakeTest = {
    1: 'Roller Test',
    2: 'Decelerometer',
    3: 'Road Test'
};

export const EditInspectionBreakTest = memo(({ classes }) => {
    const { state, setState } = useContext(EditInapectionContaxt);
    return (
        <Grid item container direction="row" xs={12} sm={12} md={12}>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                            <Grid item xs={12} sm={3} md={2}>
                                <InputLabel shrink> Type of brake test </InputLabel>
                                <Typography variant="button"> {brakeTest[state.vehicleInspectionBrakeTestTypeID] || 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} md={2}>
                                <InputLabel shrink> Brake Test Number </InputLabel>
                                <Typography variant="button"> {state.vehicleInspectionBrakeTestNumber || 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} md={4}>
                                <InputLabel shrink> Road Conditions </InputLabel>
                                <Typography variant="button"> {state.vehicleInspectionRoadCondition || 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <InspectionImages state={state} setState={setState} disabled={state.hideSignOff} hideBtn={state.hideSignOff} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
});
