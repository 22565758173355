import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from '../../../../Core/Controls/Grid/index';
import { getAllJob } from '../../../../Core/Services/InternalUser/JOB/jobService';
import { BreadCrumbs, formatters } from '../../../../Core/Controls';
import { CustomIconButton, SecondaryButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import WorkIcon from '@material-ui/icons/Work';
import { Link } from 'react-router-dom';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import JobQueryModal from './JobStatusBtn/JobQuery';
import ReactDOM from 'react-dom';
import CustomerDetails from '../DetailsModal/customerDetails';
import ServiceAgentDetails from '../DetailsModal/serviceAgent';
import DepotDetails from '../DetailsModal/depotDetails';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import { getVechicleIDByRegNO } from '../../../../Core/Services/InternalUser/ServiceAgent/breakdownService';
import JobScreenModal from './JobScreenModal';
import { Button, ButtonGroup } from '@material-ui/core';
import { ChipWithoutLable } from '../../../../Core/Controls/Inputs/DatePicker';
import LinkIcon from '@material-ui/icons/Link';
import JobAllocationsModal from './JobAllocationModal';
import ContractSetPauseCompleted from '../../ContractScreens/ContractStatusTab/statusModals/ContractSetPausedComplete';
import SelectedVehicleTableComp from '../../ContractScreens/ContractStatusTab/statusModals/SelectedVehicleTableComp';

const base_columns = [
    { key: 'action', name: 'Action', width: '110', addFileUploaderIcon: true },
    { key: 'vehicleRegNumber', name: 'Reg Number', width: 110 },
    { key: 'jobDate', name: 'Date', formatter: formatters.Date_DDMMYYYY, width: 100 },
    // { key: 'agentOrderNo', name: 'Job Ref', width: 145 },
    { key: 'jobDescription', name: 'Job Description', width: 150 },
    { key: 'jobPriority', name: 'Job Priority', width: 140 },
    { key: 'partsTotal', name: 'Parts', formatter: formatters.NumberWithPound, align: 'right', width: 110 },
    { key: 'labourTotal', name: 'Labour', formatter: formatters.NumberWithPound, align: 'right', width: 110 },
    { key: 'chargableTotal', name: 'Total', formatter: formatters.NumberWithPound, align: 'right', width: 110 },
    { key: 'serviceAgentName', name: 'Service Area', width: 130 },
    // { key: 'customerCompanyName', name: 'Customer' },
    { key: 'depotName', name: 'Depot', width: 180 }
];

const getCrumbs = (code) => {
    let temp = {};
    let final = [
        { name: 'Home', path: '/' },
        { name: 'Jobs', active: true }
    ];
    if (code == 'AR') {
        temp = { name: 'Awaiting Approvals', active: true };
    } else if (code == 'DD') {
        temp = { name: 'Driver Defects', active: true };
    } else if (code == 'EP') {
        temp = { name: 'Estimates Pending', active: true };
    } else if (code == 'ER') {
        temp = { name: 'Estimates Revised', active: true };
    } else if (code == 'CQ') {
        temp = { name: 'Customer Query', active: true };
    } else if (code == 'JQ') {
        temp = { name: 'Query Job', active: true };
    } else if (code == 'FR') {
        temp = { name: 'Awaiting Review', active: true };
    } else if (code == 'AQ') {
        temp = { name: 'Agent Query', active: true };
    } else if (code == 'QJ') {
        temp = { name: 'Jobs In Query', active: true };
    } else if (code == 'AU') {
        temp = { name: 'Jobs Unassigned', active: true };
    } else if (code == 'CA') {
        temp = { name: 'Approved', active: true };
    } else if (code == 'EC') {
        temp = { name: 'Estimate Under Customer Review', active: true };
    } else if (code == 'EA') {
        temp = { name: 'Estimates Accepted', active: true };
    } else if (code == 'EQ') {
        temp = { name: 'Estimates Queried', active: true };
    } else if (code == 'FI') {
        temp = { name: 'Factory Inspection', active: true };
    } else {
        temp = { name: 'Open Jobs', active: true };
    }
    final.push(temp);
    return final;
};

const InternalUserJobsScreen = (props) => {
    const params = new URLSearchParams(window.location.search);
    const showAllRecordsParam = params.get('showAllRecords') || '';
    const vehicleJobStatusParam = params.get('vehicleJobStatus') || '';
    const serviceAgentName = params.get('agentName') || '';
    // const btn = params.get('btn') || '';
    let DummyList = [];
    const { userRoles } = useLoginInfo();
    const [state, setState] = useState({
        showLoader: true,
        open: false,
        rows: [],
        isReload: null,
        filterVal: '',
        // getUrl: `VehicleJob/?jobstatusid=${props.screenCode ? props.screenCode : ''}?Time=1`,
        buttonState: props.screenCode == 'FI' || props.screenCode == 'FC' ? 3 : +showAllRecordsParam || 1,
        checkedVehicleList: [],
        selectedIdList: []
    });

    useEffect(() => {
        if (state.buttonState) {
            let Time = state.buttonState == 3 ? '' : state.buttonState;
            if (['FR', 'CA'].includes(props.screenCode)) {
                Time = '';
            }
            setState((st) => ({
                ...st,
                getUrl: `VehicleJob/?jobstatusid=${props.screenCode ? props.screenCode : ''}&Time=${Time}${
                    serviceAgentName && `&serviceAgentName=${serviceAgentName}`
                }${vehicleJobStatusParam ? `&vehicleJobStatus=${vehicleJobStatusParam}` : ''}`
            }));
        }
    }, [state.buttonState]);

    const [filterValues, setFilterValue] = useState({
        filterVal: '',
        filterVehicleID: null,
        show: false,
        open: false,
        customerID: null,
        depotID: null,
        engineerID: '',
        serviceAgentID: null,
        vehicleMakeModel: '',
        vorVehicleID: null,
        filterbtnState: 1
    });

    const handleFilter = (cond, val) => {
        setState((st) => ({
            ...st,
            buttonState: val
        }));
    };

    console.log(filterValues);

    const history = useHistory();
    let checkhaveAccess = AppStorage.getAllowAcces();

    const isEngineer = userRoles.includes('engineer');
    const isServicearea = userRoles.includes('servicearea');

    const pullApiData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date(),
            showLoader: false
        }));
    };

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.jobID}/${val?.vehicleID}`,
            search: isEngineer || props.screenCode == 'FI' ? '?tab_testing_tabs=1' : '',
            state: 'jobs'
        });
    };

    const handleShowQueries = (val) => {
        setState((state) => ({ ...state, showJobQuery: true, jobID: val?.jobID }));
    };

    const closeTheDialog = (isReloadFlag) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.showJobQuery = false;
            newSt.jobID = '';
            newSt.setPauseCompleted = false;
            if (isReloadFlag) newSt.isReload = new Date();
            return newSt;
        });
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                {/* <Link to={`/controller/jobTabScreen/${data.row?.jobID}/${data.row?.jobVehicleID}`}> */}
                <CustomIconButton
                    icon={SpeakerNotesIcon}
                    className="modal-btn-update btn_space"
                    toolTipTitle="Show Queries"
                    onClick={() => handleShowQueries(row)}
                />
                {checkhaveAccess?.CanViewJobs && (
                    <CustomIconButton icon={WorkIcon} className="modal-btn-update btn_space" toolTipTitle="Edit Job" onClick={() => handleEditClick(row)} />
                )}
                {/* </Link>  */}
            </div>
        );
    };
    const isCustomer = useLoginInfo().userRoles.includes('customer');

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div
                onClick={() => {
                    if (props.screenCode == 'OJ' || props.screenCode == 'FR' || props.screenCode == 'CA' || props.screenCode == 'FI') {
                        handleEditClick(row);
                    } else {
                        handleEditRegClick(row);
                    }
                }}
                style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}
            >
                {row.vehicleRegNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.serviceAgentName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.depotName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customerCompanyName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const inputChange = (e) => {
        const value = e.target.value;
        setFilterValue((st) => ({
            ...st,
            filterVal: value
        }));
    };

    useEffect(() => {
        const fetchVehicleData = async () => {
            if (!props.VehicleID) {
                if (filterValues.filterVal.length > 4) {
                    try {
                        let resBrekDown = await getVechicleIDByRegNO(filterValues.filterVal);
                        setFilterValue((st) => {
                            let newSt = { ...st };
                            if (resBrekDown.data?.list) {
                                newSt.show = resBrekDown.success;
                                newSt.filterVehicleID = resBrekDown.data.list[0].vehicleID;
                                newSt.customerID = resBrekDown.data.list[0].customerID;
                                newSt.depotID = resBrekDown.data.list[0].depotID;
                                newSt.engineerID = resBrekDown.data.list[0].engineerID;
                                newSt.serviceAgentID = resBrekDown.data.list[0].serviceAgentID;
                                newSt.vehicleMakeModel = resBrekDown.data.list[0].vehicleMakeModel;
                                newSt.vorVehicleID = resBrekDown.data.list[0].vorVehicleID;
                            } else {
                                newSt.show = false;
                            }
                            return newSt;
                        });
                    } catch (error) {
                        console.error('Error fetching vehicle data:', error);
                        setFilterValue((st) => ({ ...st, show: false }));
                    }
                } else {
                    setFilterValue((st) => ({ ...st, show: false }));
                }
            }
        };

        fetchVehicleData();
    }, [props.VehicleID, filterValues.filterVal]);

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showSaModal: false,
            showCustomerModal: false,
            showDepotModal: false,
            showJobAllocationsModal: false
        }));
    };

    const handleJobAllocationClose = (isReloadFlag) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.showJobAllocationsModal = false;
            if (isReloadFlag) newSt.isReload = new Date();
            return newSt;
        });
    };
    const renderjobDate = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = <div style={{ textAlign: 'center' }}>{row.bookingDate ? row.bookingDate : row.jobDate}</div>;
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const getVal = () => {
        setState((st) => ({
            ...st,
            selectedIdList: DummyList.map((q) => q.vehicleID),
            checkedVehicleList: DummyList,
            showJobAllocationsModal: ['FI', 'OJ'].includes(props.screenCode),
            setPauseCompleted: props.screenCode === 'FC'
        }));
    };

    console.log(DummyList, 'DummyList');
    console.log(state.checkedVehicleList, 'state.checkedVehicleList');

    const mailButton = (gridRef, valueList) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justify="center">
                <ChipWithoutLable icon={LinkIcon} onClick={getVal} style={{ fontWeight: 'bold' }} toolTipTitle={'Job Allocation'} />
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const closeModal = (res) => {
        setFilterValue((state) => ({ ...state, open: false, show: false, filterVal: '' }));
        res && setState((state) => ({ ...state, isReload: res ? new Date() : '' }));
    };
    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        let actionjobDateCol = newColumn.find((item) => item.key === 'jobDate');
        if (actionjobDateCol || true) {
            actionjobDateCol.formatter = ({ row, col }, grid) => {
                const spn = document.createElement('span');
                const lnk = (
                    <div style={{ textAlign: 'center' }}>
                        {row.bookingDate ? formatters.YearFormatter(row.bookingDate) : formatters.YearFormatter(row.jobDate)}
                    </div>
                );
                ReactDOM.render(lnk, spn);
                return spn;
            };
        }
        if (!isCustomer && props.screenCode != 'OJ' && props.screenCode != 'FR' && props.screenCode != 'CA') {
            newColumn.splice(8, 0, { key: 'customerCompanyName', name: 'Customer', formatter: renderCustomerName, width: 250 });
        }
        if (isCustomer || props.screenCode == 'AR') {
            newColumn.splice(3, 0, { key: 'jobOrderNo', name: 'Order No', width: 145 });
        } else if (props.screenCode != 'OJ' && props.screenCode !== 'FR' && props.screenCode != 'CA') {
            newColumn.splice(3, 0, { key: 'agentOrderNo', name: 'Job Ref', width: 145 });
        }
        if (props.screenCode == 'OJ' || props.screenCode == 'FR' || props.screenCode == 'CA') {
            newColumn.splice(
                0,
                5,
                { key: 'vehicleRegNumber', name: 'Reg Number', width: 110 },
                { key: 'jobDescription', name: 'Job Description', width: 150 },
                { key: 'customerCompanyName', name: 'Customer Name', formatter: renderCustomerName, width: 250 },
                { key: 'depotName', name: 'Depot Name', width: 180 }
            );
            newColumn.splice(
                5,
                3,
                { key: 'serviceAgentName', name: 'Service Area', width: 130 },
                { key: 'technician', name: 'Engineer', width: 150 },
                { key: 'jobDate', name: 'Date', formatter: formatters.Date_DDMMYYYY, width: 90 }
            );
            newColumn.splice(
                9,
                6,
                { key: 'jobPriority', name: 'Job Priority', width: 140 },
                // { key: 'costCentre', name: 'Cost Center', width: 110 },
                { key: 'labourTotal', name: 'Labour', formatter: formatters.NumberWithPound, align: 'right', width: 110 },
                { key: 'partsTotal', name: 'Parts', formatter: formatters.NumberWithPound, align: 'right', width: 110 },
                { key: 'chargableTotal', name: 'Total', formatter: formatters.NumberWithPound, align: 'right', width: 110 }
                // { key: 'costType', name: 'Cost Type', width: 90 }
            );
        }
        if (props.screenCode == 'OJ') {
            newColumn.splice(4, 1, { key: 'vehicleJobStatus', name: 'Status', width: 150 });
            newColumn.splice(8, 1, { key: 'agentOrderNo', name: 'Job Ref', width: 145 });
        }
        if (props.screenCode == 'FR' || props.screenCode == 'CA') {
            newColumn.splice(4, 1, { key: 'agentOrderNo', name: 'Job Ref', width: 145 });
            newColumn.splice(8, 1, { key: 'vehicleJobStatus', name: 'Status', width: 150 });
        }
        if (isEngineer && props.screenCode == 'OJ') {
            newColumn.splice(
                0,
                newColumn.length,
                { key: 'vehicleRegNumber', name: 'Reg Number', width: 110 },
                { key: 'jobDescription', name: 'Job Description', width: 150 },
                { key: 'jobPriority', name: 'Job Priority', width: 140 },
                { key: 'depotName', name: 'Depot', width: 180 },
                { key: 'customerCompanyName', name: 'Customer', formatter: renderCustomerName, width: 250 },
                { key: 'vehicleJobStatus', name: 'Status', width: 150 },
                { key: 'jobDate', name: 'Date', formatter: formatters.Date_DDMMYYYY, width: 90 },
                { key: 'agentOrderNo', name: 'Job Ref', width: 145 }
            );
        }
        if (isServicearea && props.screenCode == 'OJ') {
            newColumn.splice(
                0,
                newColumn.length,
                { key: 'vehicleRegNumber', name: 'Reg Number', width: 110 },
                { key: 'jobDescription', name: 'Job Description', width: 150 },
                { key: 'jobPriority', name: 'Job Priority', width: 140 },
                { key: 'depotName', name: 'Depot', width: 180 },
                { key: 'customerCompanyName', name: 'Customer', formatter: renderCustomerName, width: 250 },
                { key: 'vehicleJobStatus', name: 'Status', width: 150 },
                { key: 'technician', name: 'Engineer', width: 150 },
                { key: 'jobDate', name: 'Date', formatter: formatters.Date_DDMMYYYY, width: 90 },
                { key: 'internalTotal', name: 'Internal Total', formatter: formatters.NumberWithPound, width: 180 },
                { key: 'chargableTotal', name: 'Chargeable Total', formatter: formatters.NumberWithPound, width: 180 },
                { key: 'agentOrderNo', name: 'Job Ref', width: 145 }
            );
        }
        if (isServicearea && props.screenCode == 'FR') {
            newColumn.splice(
                0,
                newColumn.length,
                { key: 'technician', name: 'Engineer', width: 150 },
                { key: 'vehicleRegNumber', name: 'Reg Number', width: 110 },
                { key: 'customerCompanyName', name: 'Customer', formatter: renderCustomerName, width: 250 },
                { key: 'depotName', name: 'Depot Name', width: 180 },
                { key: 'jobDate', name: 'Date', formatter: formatters.Date_DDMMYYYY, width: 90 },
                { key: 'jobDescription', name: 'Job Description', width: 150 },
                { key: 'internalTotal', name: 'Internal Total', width: 180 },
                { key: 'chargableTotal', name: 'Chargeable Total', width: 180 },
                { key: 'agentOrderNo', name: 'Job Ref', width: 145 }
            );
        }
        if (props.screenCode == 'FI') {
            newColumn.shift();
        }
        let agentNameCol = newColumn.find((element) => element.key === 'serviceAgentName');
        let depotNameCol = newColumn.find((element) => element.key === 'depotName');
        let regNumberCol = newColumn.find((element) => element.key === 'vehicleRegNumber');
        if (regNumberCol) {
            regNumberCol.formatter = renderRegNumberEdit;
        }
        if (depotNameCol) {
            depotNameCol.formatter = renderDepotName;
        }
        if (agentNameCol) {
            agentNameCol.formatter = renderAgentName;
        }
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        // if (props.screenCode == 'EP') {
        //     newColumn.splice(3, 0, {
        //         key: 'jobEstimatedCost',
        //         name: 'Estimate',
        //         formatter: formatters.ColumnTextAlignRight,
        //         headerRenderer: formatters.ColumnHeaderAlignRight
        //     });
        //     newColumn.splice(7, 2);
        // }
        return newColumn;
    }, [state.isReload]);

    const jobHandler = (params) => {
        setFilterValue((state) => ({ ...state, open: true }));
    };

    const onRowDataBound = (e, $row, id, record, GridRef) => {
        if (DummyList.some((q) => record.jobID == q.jobID)) {
            GridRef.setSelected(id);
        }
        if (record.contractID) {
            var checkboxCell = $row.find('input[type="checkbox"][data-role="selectRow"]').closest('td');
            checkboxCell.empty().append('<span></span>');
        }

        $row.css('background-color', record.background);
        $row.css('color', record.colour);
    };

    const onRowSelect = (e, $row, id, record) => {
        let vehicleIndex = DummyList.findIndex((q) => q.jobID === record.jobID);
        if (vehicleIndex == -1) {
            DummyList.push(record);
        }
    };
    const onRowUnSelect = (e, $row, id, record) => {
        let vehicleIndex = DummyList.findIndex((q) => q.jobID === record.jobID);
        if (vehicleIndex > -1) {
            DummyList.splice(vehicleIndex, 1);
        }
    };

    const crumbs = getCrumbs(props.screenCode);

    return (
        <div className={props.fromTabScreen ? '' : 'screen'} style={props.fromTabScreen ? { paddingTop: '10px' } : {}}>
            {filterValues.open && (
                <DialogComp
                    open={filterValues.open}
                    onClose={() => closeModal(false)}
                    title="Add Job"
                    maxWidth="md"
                    // overflow="auto"
                    // height="calc(100vh - 555px)"
                >
                    <JobScreenModal
                        VehicleID={filterValues.filterVehicleID}
                        customerID={filterValues.customerID}
                        depotID={filterValues.depotID}
                        technicianUserID={filterValues.engineerID}
                        serviceAgentID={filterValues.serviceAgentID}
                        VORId={filterValues.vorVehicleID}
                        onClose={closeModal}
                    />
                </DialogComp>
            )}
            <Grid container justify="space-between">
                <Grid item>
                    <Grid container spacing={2}>
                        {!props.fromTabScreen && (
                            <Grid item>
                                {' '}
                                <BreadCrumbs crumbs={crumbs} />
                            </Grid>
                        )}
                        {/* <Grid item>
                            {' '}
                            <div style={{ width: 'auto' }}>
                                <input
                                    type="text"
                                    onChange={inputChange}
                                    placeholder="Enter Reg Number"
                                    value={filterValues.filterVal}
                                    style={{ padding: 5 }}
                                />
                            </div>
                        </Grid> */}
                        <Grid item>
                            {' '}
                            {filterValues.show && (
                                <div style={{ marginLeft: '4px' }}>
                                    <SecondaryButton
                                        style={{ padding: '2px 16px' }}
                                        onClick={jobHandler}
                                        fullWidth
                                        // className="submit_btn"
                                    >
                                        Add
                                    </SecondaryButton>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {console.log(props.screenCode, 'props.screenCode')}
                {!['FR', 'CA', 'FI'].includes(props.screenCode) && !props.fromTabScreen && (
                    <Grid component="label" item alignItems="center">
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button style={{ fontSize: 12 }} variant={state.buttonState === 1 ? 'contained' : 'outlined'} onClick={() => handleFilter(true, 1)}>
                                Current
                            </Button>
                            <Button
                                style={{ fontSize: 12 }}
                                variant={state.buttonState === 2 ? 'contained' : 'outlined'}
                                onClick={() => handleFilter(false, 2)}
                            >
                                Future
                            </Button>
                            <Button style={{ fontSize: 12 }} variant={state.buttonState === 3 ? 'contained' : 'outlined'} onClick={() => handleFilter(true, 3)}>
                                All
                            </Button>
                        </ButtonGroup>
                    </Grid>
                )}
            </Grid>
            <GijgoGrid
                columns={columns}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                getUrl={state.getUrl}
                idFieldName={`${checkhaveAccess?.CanViewJobs && checkhaveAccess?.CanViewDocuments ? 'jobID' : null}`}
                fileUploaderConfigName={`${checkhaveAccess?.CanViewJobs && checkhaveAccess?.CanViewDocuments ? 'JobScreen' : null}`}
                selectionType={['FI', 'OJ', 'FC'].includes(props.screenCode) ? 'multiple' : ''}
                selectionMethod={['FI', 'OJ', 'FC'].includes(props.screenCode) ? (!isEngineer ? 'checkbox' : '') : ''}
                showCheckboxColFilterBtn={['FI', 'OJ', 'FC'].includes(props.screenCode) ? (!isEngineer ? mailButton : '') : ''}
                onRowDataBound={(e, $row, id, record, GridRef) => onRowDataBound(e, $row, id, record, GridRef)}
                onRowSelect={(e, $row, id, record) => onRowSelect(e, $row, id, record)}
                onRowUnSelect={(e, $row, id, record) => onRowUnSelect(e, $row, id, record)}
            />
            {state.showJobQuery && (
                <DialogComp title="Job Queries" maxWidth="sm" onClose={closeTheDialog}>
                    <JobQueryModal title="" jobQueryJobID={state.jobID} onClose={closeTheDialog} />
                </DialogComp>
            )}
            {state.showSaModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.jobServiceAgentID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                    <DepotDetails customerID={state.selectedRowData.jobDepotID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showJobAllocationsModal ? (
                <DialogComp open={true} onClose={() => handleJobAllocationClose(false)} title={'Job Allocation'}>
                    <JobAllocationsModal selectedRecords={state.checkedVehicleList || []} onClose={handleJobAllocationClose} />
                </DialogComp>
            ) : null}
            {state.setPauseCompleted ? (
                <DialogComp title={`Contract Pause / Complete`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetPauseCompleted onClose={closeTheDialog} checkedVehicleList={state.selectedIdList} fromTabScreen={props.fromTabScreen} />
                    <SelectedVehicleTableComp
                        selectedRecords={state.checkedVehicleList.map((q) => ({
                            reg: q.vehicleRegNumber,
                            vehicleType: q.jobDescription,
                            sa: q.serviceAgentName,
                            technician: q.technician
                        }))}
                    />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default InternalUserJobsScreen;
