import { FormHelperText, Grid, InputLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { InputText, SecondaryButton, SecondaryCheckbox, MandatoryField } from "../../../../Core/Controls";
import "../../../commonStyle.scss";

const EventDocumentsDialog = (props) => {
  const [state, setState] = useState({
    eventDocName: "",
    eventDocRequiredInDays: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!props.data.isAdd) {
      let temp = props.data.selectedRowData;
      setState((st) => ({
        ...st,
        eventDocID: temp.eventDocID,
        eventDocName: temp.eventDocName,
        eventDocRequiredInDays: temp.eventDocRequiredInDays,
        eventDocChase: temp.eventDocChase,
        eventDocLegal: temp.eventDocLegal,
      }));
    }
  }, []);
  const validate = () => {
    let noErrors = true;
    let err = {};

    if (!/[^\s]/.test(state.eventDocName)) {
      err.eventDocName = true;
      noErrors = false;
    }
    let temp = state.eventDocRequiredInDays;
    if (!/[^\s]/.test(temp)) {
      err.eventDocRequiredInDays = true;
      noErrors = false;
    } else if (+temp < 0) {
      err.eventDocRequiredInDaysN = true;
      noErrors = false;
    }
    setErrors(err);
    return noErrors;
  };
  const handleData = () => {
    if (validate()) {
      props.saveData(state);
      props.onClose();
    }
  };
  const valueChanged = (ev) => {
    let temp = ev.target;
    setState((st) => ({
      ...st,
      [temp.name]: temp.type === "checkbox" ? temp.checked : temp.value,
    }));
    setErrors({});
  };
  const checkPrevention = (event) => {
    if (/[^0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="mandatory-fields">
          <MandatoryField
            inputLabel="Name"
            name="eventDocName"
            placeholder="Doc Name"
            defaultValue={props.data.selectedRowData.eventDocName || ""}
            onChange={valueChanged}
            errors={errors.eventDocName ? "This field is required" : null}
          />
        </Grid>
        <Grid item xs={12} className="mandatory-fields">
          <MandatoryField
            inputLabel="Required In Days"
            type="number"
            name="eventDocRequiredInDays"
            placeholder="Doc Required In Days"
            defaultValue={props.data.selectedRowData.eventDocRequiredInDays}
            onKeyPress={checkPrevention}
            onChange={valueChanged}
            errors={errors.eventDocRequiredInDaysN ? "Negative value is not acceptable" : errors.eventDocRequiredInDays ? "This field is required" : null}
          />
        </Grid>
        <Grid item xs={6}>
          <SecondaryCheckbox onChange={valueChanged} checked={state.eventDocChase || false} name="eventDocChase" label="Chase Documents?" />
        </Grid>
        <Grid item xs={6}>
          <SecondaryCheckbox onChange={valueChanged} checked={state.eventDocLegal || false} name="eventDocLegal" label="Legal Documents?" />
        </Grid>
        <Grid item xs={12} className="submit_btn">
          <SecondaryButton onClick={handleData}>{props.data.isAdd ? "ADD" : "UPDATE"}</SecondaryButton>
        </Grid>
      </Grid>
    </>
  );
};
export default EventDocumentsDialog;
