import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Typography, FormHelperText, InputAdornment, IconButton, withStyles } from '@material-ui/core';
import { ContractProvider as StateProvider, ContractContext } from './contractProvider';
import { SecondaryButton, SimpleField, SelectBox, DatePicker, SingleSelect } from '../../../../../Core/Controls';
import moment from 'moment';
import { FromLoader } from '../../../../../Core/Loader/formLoader';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
import EuroIcon from '@material-ui/icons/Euro';

const GlobalCss = withStyles({
    '@global': {
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '.MuiOutlinedInput-adornedStart': {
            paddingLeft: '10px'
        }
    }
})(() => null);

export const JobSingleNote = (props) => {
    const { notes } = props.note;
    return (
        <>
            <div className="single-note">
                <div className="msg" style={{ fontSize: 14, fontStyle: 'italic', cursor: 'pointer', padding: '5px 10px' }}>
                    {notes || 'N/A'}
                </div>
            </div>
        </>
    );
};

const ContractField = (props) => {
    const { state, btnLoader, YesNoRadio, dropdown, inputChange, submitData, tempOptions, singleSelectChange, RedirectToContract } =
        useContext(ContractContext);
    console.log(state, 'state');
    let checkhaveAccess = AppStorage.getAllowAcces();
    return (
        <>
            {state.showLoader ? (
                <FromLoader />
            ) : (
                <div>
                    <GlobalCss />
                    <Grid container spacing={1} alignItems="flex-start">
                        <Grid item xs={12} lg={7}>
                            <Grid container spacing={1} style={{ border: '1px solid rgba(0,0,0,0.2)' }}>
                                {/* <Grid item xs={12}>
                                    <Grid
                                        container
                                        spacing={1}
                                        style={{ backgroundColor: '#0000000a', border: '1px solid rgba(0,0,0,0.2)', minHeight: '52px' }}
                                        alignItems="center"
                                    >
                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{}}>
                                            <div>Contract Ref</div>
                                            <strong style={{ cursor: 'pointer' }} onClick={RedirectToContract}>
                                                {' '}
                                                {state.vehicleData?.contractReference || 'N/A'}
                                            </strong>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{}}>
                                            <div>Contract Type</div>
                                            <strong>{state.vehicleData?.contractType || 'N/A'}</strong>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{}}>
                                            <div>Spreader Type</div>
                                            <strong>{state.vehicleData?.spreaderTypeDesc || 'N/A'}</strong>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                <Grid
                                    xs={12}
                                    item
                                    style={{
                                        alignItems: 'center',
                                        padding: '5px 0px 6px 8px',
                                        borderBottom: '1px solid rgba(0,0,0,0.2)',
                                        marginBottom: '5px',
                                        paddingRight: '35px',
                                        paddingLeft: '10px'
                                    }}
                                >
                                    <Typography variant="h6" color="secondary">
                                        Contract Details{' '}
                                        <span style={{ fontSize: '14px', color: 'black' }}>
                                            {/* <span onClick={RedirectToContract} style={{ cursor: 'pointer'}}> */}
                                            <span style={{ paddingRight: '35px', paddingLeft: '35px' }}>
                                                Contract Ref:{' '}
                                                <strong onClick={RedirectToContract} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                                    {state.vehicleData.contractReference || 'N/A'}
                                                </strong>
                                            </span>
                                            {` `}
                                            Contract Type: <strong style={{ paddingRight: '35px' }}>{state.vehicleData.contractType || 'N/A'}</strong> Spreader
                                            Type: <strong>{state.vehicleData.spreaderTypeDesc || 'N/A'}</strong>
                                        </span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                                    <FormHelperText shrink error required>
                                        {state.contractTypeID == 1 ? 'Contract Commencement' : 'Start date'}
                                    </FormHelperText>
                                    <DatePicker
                                        value={state.contractStartDate && moment(state.contractStartDate).format('YYYY-MM-DD')}
                                        onChange={inputChange}
                                        name="contractStartDate"
                                        disabled={moment(state.ApiContractStartDate).isBefore(moment().format('YYYY-MM-DD'))}
                                    />
                                    <FormHelperText error>{state.errors.contractStartDate}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                                    <FormHelperText shrink>{state.contractTypeID == 1 ? 'Contract Termination Date' : 'End date'}</FormHelperText>
                                    <DatePicker
                                        value={state.contractEndDate && moment(state.contractEndDate).format('YYYY-MM-DD')}
                                        onChange={inputChange}
                                        name="contractEndDate"
                                    />
                                    <FormHelperText error>{state.errors.contractEndDate}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                                    <FormHelperText shrink>Extended Date</FormHelperText>
                                    <DatePicker
                                        value={state.contractExtendedDate && moment(state.contractExtendedDate).format('YYYY-MM-DD')}
                                        onChange={inputChange}
                                        name="contractExtendedDate"
                                    />
                                </Grid>
                                {state.contractTypeID == 1 && (
                                    <>
                                        <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                                            <FormHelperText shrink error required>
                                                Winter Term (Weeks)
                                            </FormHelperText>
                                            <SimpleField onChange={inputChange} name="contractWinterTerms" value={state.contractWinterTerms} />
                                            <FormHelperText error>{state.errors.contractWinterTerms}</FormHelperText>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                                            <FormHelperText shrink error required>
                                                Winter Start Date
                                            </FormHelperText>
                                            <DatePicker
                                                value={state.contractWinterStartDate && moment(state.contractWinterStartDate).format('YYYY-MM-DD')}
                                                onChange={inputChange}
                                                name="contractWinterStartDate"
                                                disabled={moment(state.ApicontractWinterStartDate).isBefore(moment().format('YYYY-MM-DD'))}
                                            />
                                            <FormHelperText error>{state.errors.contractWinterStartDate}</FormHelperText>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                                            <FormHelperText shrink>Winter End Date</FormHelperText>
                                            <DatePicker
                                                value={state.contractWinterEndDate && moment(state.contractWinterEndDate).format('YYYY-MM-DD')}
                                                onChange={inputChange}
                                                name="contractWinterEndDate"
                                            />
                                            <FormHelperText error>{state.errors.contractWinterEndDate}</FormHelperText>
                                        </Grid>
                                    </>
                                )}

                                <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                                    <FormHelperText shrink error={state.contractTypeID == 1} required={state.contractTypeID == 1}>
                                        Purchase Order
                                    </FormHelperText>
                                    <SimpleField onChange={inputChange} name="contractPurchaseOrder" value={state.contractPurchaseOrder} />
                                    <FormHelperText error>{state.errors.contractPurchaseOrder}</FormHelperText>
                                </Grid>
                                {/* <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                            <FormHelperText shrink>Mileage (KMs)</FormHelperText>
                            <SimpleField onChange={inputChange} name="contractMileage" value={state.contractMileage} />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                            <FormHelperText shrink>Excess Mileage (pence per KM)</FormHelperText>
                            <SimpleField onChange={inputChange} name="contractExcessMileage" value={state.contractExcessMileage} />
                        </Grid> 
                        <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                            <FormHelperText shrink>Weekly Rental</FormHelperText>
                            <SimpleField onChange={inputChange} name="contractWeeklyRental" value={state.contractWeeklyRental} />
                        </Grid> */}

                                <Grid item xs={12} sm={4} md={4} lg={3} xl={state.contractTypeID == 1 ? 2 : 2}>
                                    <FormHelperText shrink>Invoice All Year</FormHelperText>
                                    <YesNoRadio name="contractInvoiceAllYear" value={state.contractInvoiceAllYear || false} onChange={inputChange} />
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                                    <FormHelperText shrink error required>
                                        Invoice Frequency
                                    </FormHelperText>
                                    <SelectBox
                                        name="contractInvoiceFrequency"
                                        value={state.contractInvoiceFrequency}
                                        onChange={inputChange}
                                        List={[
                                            {
                                                name: 'Daily',
                                                id: 'd'
                                            },
                                            {
                                                name: 'Weekly',
                                                id: 'w'
                                            },
                                            {
                                                name: '4 Weeks',
                                                id: '4'
                                            },
                                            {
                                                name: '52 Weeks',
                                                id: '5'
                                            },
                                            {
                                                name: 'Monthly',
                                                id: 'm'
                                            },
                                            {
                                                name: 'Yearly',
                                                id: 'y'
                                            }
                                        ]}
                                    />
                                    <FormHelperText error>{state.errors.contractInvoiceFrequency}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} lg={6} xl={state.contractTypeID == 1 ? 4 : 4}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <FormHelperText shrink>Annual Price Increase</FormHelperText>
                                            <YesNoRadio
                                                name="contractAnnualPriceIncrease"
                                                value={state.contractAnnualPriceIncrease || false}
                                                onChange={inputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormHelperText shrink>Increase %</FormHelperText>
                                            <SimpleField
                                                type="number"
                                                onChange={inputChange}
                                                name="contractIncreaseAmount"
                                                value={state.contractIncreaseAmount}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                                    <FormHelperText shrink>Increase Date</FormHelperText>
                                    <DatePicker
                                        value={state.contractIncreaseDate && moment(state.contractIncreaseDate).format('YYYY-MM-DD')}
                                        onChange={inputChange}
                                        name="contractIncreaseDate"
                                    />
                                </Grid>
                                {state.contractTypeID == 1 && (
                                    <Grid item xs={12} sm={4} md={4} lg={6} xl={4}>
                                        <FormHelperText shrink>Framework</FormHelperText>
                                        <SimpleField onChange={inputChange} name="contractFramework" value={state.contractFramework} />
                                        <FormHelperText error>{state.errors.contractFramework}</FormHelperText>
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={12}>
                                    <hr />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} className="mandatory-fields">
                                    <FormHelperText required shrink error>
                                        Depot
                                    </FormHelperText>
                                    <SingleSelect
                                        name="contractDepotID"
                                        options={dropdown.depotsList || []}
                                        value={tempOptions.contractDepotID}
                                        onChange={singleSelectChange('contractDepotID')}
                                    />
                                    <FormHelperText error>{state.errors.contractDepotID}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormHelperText shrink error required>
                                        Service Area
                                    </FormHelperText>
                                    <SingleSelect
                                        name="contractServiceAreaID"
                                        value={tempOptions.contractServiceAreaID}
                                        onChange={singleSelectChange('contractServiceAreaID')}
                                        options={dropdown.serviceAgents}
                                    />
                                    <FormHelperText error>{state.errors.contractServiceAreaID}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormHelperText shrink error={state.contractTypeID == 2} required={state.contractTypeID == 2}>
                                        Engineer
                                    </FormHelperText>
                                    <SingleSelect
                                        name="contractEngineerUserID"
                                        value={tempOptions.contractEngineerUserID}
                                        onChange={singleSelectChange('contractEngineerUserID')}
                                        options={dropdown.technicianList}
                                    />
                                    <FormHelperText error>{state.errors.contractEngineerUserID}</FormHelperText>
                                </Grid>
                                {state.contractTypeID == 1 && (
                                    <Grid item xs={12} sm={4} md={4}>
                                        <FormHelperText shrink error required>
                                            Summer Service Area
                                        </FormHelperText>
                                        <SingleSelect
                                            name="contractSummerServiceAreaID"
                                            value={tempOptions.contractSummerServiceAreaID}
                                            onChange={singleSelectChange('contractSummerServiceAreaID')}
                                            options={dropdown.serviceAgents}
                                        />
                                        <FormHelperText error>{state.errors.contractSummerServiceAreaID}</FormHelperText>
                                    </Grid>
                                )}

                                <Grid item xs={12} sm={12}>
                                    <hr />
                                </Grid>
                                {/* <Grid item xs={12}>
                            <Grid container spacing={1}> */}
                                {state.contractTypeID == 1 && (
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={2} lg={3} xl={2}>
                                                        <FormHelperText shrink error required>
                                                            Delivery Charge
                                                        </FormHelperText>
                                                        <SimpleField
                                                            type="number"
                                                            onChange={inputChange}
                                                            name="contractDeliveryCharge"
                                                            value={state.contractDeliveryCharge}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        {/* <EuroIcon color="secondary" /> */}
                                                                        <Typography color="secondary" variant="h6">
                                                                            £
                                                                        </Typography>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                        <FormHelperText error>{state.errors.contractDeliveryCharge}</FormHelperText>
                                                    </Grid>
                                                    <Grid item xs={12} sm={2} lg={3} xl={2}>
                                                        <FormHelperText shrink error required>
                                                            Collection Charge
                                                        </FormHelperText>
                                                        {/* <FormHelperText shrink error required>
                                                            Collection Charge
                                                        </FormHelperText> */}
                                                        <SimpleField
                                                            type="number"
                                                            onChange={inputChange}
                                                            name="contractCollectionCharge"
                                                            value={state.contractCollectionCharge}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        {/* <EuroIcon color="secondary" /> */}
                                                                        <Typography color="secondary" variant="h6">
                                                                            £
                                                                        </Typography>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                        <FormHelperText error>{state.errors.contractCollectionCharge}</FormHelperText>
                                                    </Grid>
                                                    <Grid item xs={12} sm={2} lg={3} xl={2}>
                                                        <FormHelperText shrink>Collection Charge Date</FormHelperText>
                                                        {/* <FormHelperText shrink>Collection Charge Date</FormHelperText> */}
                                                        <DatePicker
                                                            value={
                                                                state.contractCollectionChargeDate &&
                                                                moment(state.contractCollectionChargeDate).format('YYYY-MM-DD')
                                                            }
                                                            onChange={inputChange}
                                                            name="contractCollectionChargeDate"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={2} lg={3} xl={2}>
                                                        <FormHelperText shrink error required>
                                                            Rate (Weekly)
                                                        </FormHelperText>
                                                        <SimpleField
                                                            type="number"
                                                            onChange={inputChange}
                                                            name="contractWeeklyRental"
                                                            value={state.contractWeeklyRental}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        {/* <EuroIcon color="secondary" /> */}
                                                                        <Typography color="secondary" variant="h6">
                                                                            £
                                                                        </Typography>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                        <FormHelperText error>{state.errors.contractWeeklyRental}</FormHelperText>
                                                    </Grid>
                                                    <Grid item xs={4} md lg={3} xl={2}>
                                                        <FormHelperText shrink>Rate Daily</FormHelperText>
                                                        <div
                                                            style={{
                                                                fontSize: '18px',
                                                                height: '40px',
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            £ {Number(state.contractWeeklyRental / 7).toFixed(2)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={4} md lg={3} xl={2}>
                                                        <FormHelperText shrink>Rate Monthly</FormHelperText>
                                                        <div
                                                            style={{
                                                                fontSize: '18px',
                                                                height: '40px',
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            £ {Number((state.contractWeeklyRental * 52) / 12).toFixed(2)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={2} lg={3} xl={2}>
                                                        <FormHelperText shrink>Offhre Override</FormHelperText>
                                                        {/* <SimpleField onChange={inputChange} name="contractOffhireOverride" value={state.contractOffhireOverride} />
                                                         <FormHelperText error>{state.errors.contractOffhireOverride}</FormHelperText> */}
                                                        <YesNoRadio
                                                            name="contractOffhireOverride"
                                                            value={state.contractOffhireOverride || false}
                                                            onChange={inputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={2} lg={3} xl={2}>
                                                        <FormHelperText shrink>Mileage (KMs)</FormHelperText>
                                                        <SimpleField onChange={inputChange} name="contractMileage" value={state.contractMileage} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={2} lg={3} xl={3}>
                                                        <FormHelperText shrink>Excess Mileage (pence per KM)</FormHelperText>
                                                        <SimpleField onChange={inputChange} name="contractExcessMileage" value={state.contractExcessMileage} />
                                                    </Grid>
                                                    {/* <Grid item xs={12} sm={3} md lg={'auto'} xl={'auto'}>
                                                        <FormHelperText shrink>Delivery Arranged?</FormHelperText>
                                                        <YesNoRadio
                                                            name="contractDeliveryArranged"
                                                            value={state.contractDeliveryArranged || false}
                                                            onChange={inputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} md lg xl>
                                                        <FormHelperText shrink>Delivery Date</FormHelperText>
                                                        <DatePicker
                                                            value={state.contractDeliveryDate && moment(state.contractDeliveryDate).format('YYYY-MM-DD')}
                                                            onChange={inputChange}
                                                            name="contractDeliveryDate"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={3} md lg xl>
                                                        <FormHelperText shrink>Onsite Date</FormHelperText>
                                                        <DatePicker
                                                            value={state.contractOnsiteDate && moment(state.contractOnsiteDate).format('YYYY-MM-DD')}
                                                            onChange={inputChange}
                                                            name="contractOnsiteDate"
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>

                                            {/*<Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                     <Grid item xs={12} sm={3} md lg={'auto'} xl={'auto'}>
                                                        <FormHelperText shrink>Collection Arranged?</FormHelperText>
                                                        
                                                        <YesNoRadio
                                                            name="contractCollectionArranged"
                                                            value={state.contractCollectionArranged || false}
                                                            onChange={inputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} md lg xl>
                                                        <FormHelperText shrink>Collection Date</FormHelperText>
                                                        <DatePicker
                                                            value={state.contractCollectionDate && moment(state.contractCollectionDate).format('YYYY-MM-DD')}
                                                            onChange={inputChange}
                                                            name="contractCollectionDate"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} md lg xl>
                                                        <FormHelperText shrink>Return Date</FormHelperText>
                                                        <DatePicker
                                                            value={state.contractReturnDate && moment(state.contractReturnDate).format('YYYY-MM-DD')}
                                                            onChange={inputChange}
                                                            name="contractReturnDate"
                                                        />
                                                    </Grid> 
                                                </Grid>
                                            </Grid>*/}
                                            {/* <Grid item xs={12}>
                                                <Grid container spacing={1}></Grid>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                )}
                                {state.contractTypeID == 2 && (
                                    <>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <FormHelperText shrink>Annual Rate</FormHelperText>
                                            <SimpleField
                                                type="number"
                                                onChange={inputChange}
                                                name="contractAnnualRate"
                                                value={state.contractAnnualRate}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            {/* <EuroIcon color="secondary" /> */}
                                                            <Typography color="secondary" variant="h6">
                                                                £
                                                            </Typography>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                            <FormHelperText error>{state.errors.contractAnnualRate}</FormHelperText>
                                        </Grid>
                                    </>
                                )}

                                {/* </Grid>
                        </Grid> */}

                                <Grid item xs={12} sm={12}>
                                    <hr />
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        {state.contractTypeID == 1 && (
                                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={12}>
                                                        <FormHelperText shrink>Plough Required?</FormHelperText>
                                                        <Grid container>
                                                            <Grid item xs>
                                                                <YesNoRadio
                                                                    name="contractPloughRequired"
                                                                    value={state.contractPloughRequired || false}
                                                                    onChange={inputChange}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <SimpleField
                                                                    type="number"
                                                                    onChange={inputChange}
                                                                    name="contractPloughCharge"
                                                                    value={state.contractPloughCharge}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <Typography color="secondary" variant="h6">
                                                                                    £
                                                                                </Typography>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <FormHelperText error>{state.errors.contractPloughRequired}</FormHelperText>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FormHelperText shrink>Plough </FormHelperText>
                                                        <SimpleField
                                                            onChange={inputChange}
                                                            name="contractPloughDescription"
                                                            value={state.contractPloughDescription}
                                                        />
                                                        <FormHelperText error>{state.errors.contractPloughDescription}</FormHelperText>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item xs={12} sm={12}>
                                                    <FormHelperText shrink>Camera</FormHelperText>
                                                    <Grid container>
                                                        <Grid item xs>
                                                            <YesNoRadio onChange={inputChange} name="contractCamera" value={state.contractCamera} />
                                                        </Grid>
                                                        {state.contractTypeID == 1 && (
                                                            <Grid item xs={4}>
                                                                {' '}
                                                                <SimpleField
                                                                    type="number"
                                                                    onChange={inputChange}
                                                                    name="contractCameraCharge"
                                                                    value={state.contractCameraCharge}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <Typography color="secondary" variant="h6">
                                                                                    £
                                                                                </Typography>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                    <FormHelperText error>{state.errors.contractCamera}</FormHelperText>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <FormHelperText shrink>Camera </FormHelperText>
                                                    <SimpleField
                                                        onChange={inputChange}
                                                        name="contractCameraDescription"
                                                        value={state.contractCameraDescription}
                                                    />
                                                    <FormHelperText error>{state.errors.contractCameraDescription}</FormHelperText>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={12}>
                                                    <FormHelperText shrink>Tracker</FormHelperText>
                                                    <Grid container>
                                                        <Grid item xs>
                                                            <YesNoRadio onChange={inputChange} name="contractTracker" value={state.contractTracker} />
                                                        </Grid>
                                                        {state.contractTypeID == 1 && (
                                                            <Grid item xs={4}>
                                                                <SimpleField
                                                                    type="number"
                                                                    onChange={inputChange}
                                                                    name="contractTrackerCharge"
                                                                    value={state.contractTrackerCharge}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <Typography color="secondary" variant="h6">
                                                                                    £
                                                                                </Typography>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                    <FormHelperText error>{state.errors.contractTracker}</FormHelperText>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <FormHelperText shrink>Tracker </FormHelperText>
                                                    <SimpleField
                                                        onChange={inputChange}
                                                        name="contractTrackerDescription"
                                                        value={state.contractTrackerDescription}
                                                    />
                                                    <FormHelperText error>{state.errors.contractTrackerDescription}</FormHelperText>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={12}>
                                                    <FormHelperText shrink>Navigation?</FormHelperText>
                                                    <Grid container>
                                                        <Grid item xs>
                                                            <YesNoRadio
                                                                name="contractNavigation"
                                                                value={state.contractNavigation || false}
                                                                onChange={inputChange}
                                                            />
                                                        </Grid>
                                                        {state.contractTypeID == 1 && (
                                                            <Grid item xs={4}>
                                                                {' '}
                                                                <SimpleField
                                                                    type="number"
                                                                    onChange={inputChange}
                                                                    name="contractNavigationCharge"
                                                                    value={state.contractNavigationCharge}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <Typography color="secondary" variant="h6">
                                                                                    £
                                                                                </Typography>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                    <FormHelperText error>{state.errors.contractNavigationCharge}</FormHelperText>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <FormHelperText shrink>Navigation </FormHelperText>
                                                    <SimpleField
                                                        onChange={inputChange}
                                                        name="contractNavigationDescription"
                                                        value={state.contractNavigationDescription}
                                                    />
                                                    <FormHelperText error>{state.errors.contractNavigationDescription}</FormHelperText>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={12}>
                                                    <FormHelperText shrink>Onboard Weighing?</FormHelperText>
                                                    <Grid container>
                                                        <Grid item xs>
                                                            <YesNoRadio
                                                                name="contractOnboardWeighing"
                                                                value={state.contractOnboardWeighing || false}
                                                                onChange={inputChange}
                                                            />
                                                        </Grid>
                                                        {state.contractTypeID == 1 && (
                                                            <Grid item xs={4}>
                                                                <SimpleField
                                                                    type="number"
                                                                    onChange={inputChange}
                                                                    name="contractOnboardWeighingCharge"
                                                                    value={state.contractOnboardWeighingCharge}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <Typography color="secondary" variant="h6">
                                                                                    £
                                                                                </Typography>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                    <FormHelperText error>{state.errors.contractOnboardWeighingCharge}</FormHelperText>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <FormHelperText shrink>Onboard Weighing </FormHelperText>
                                                    <SimpleField
                                                        onChange={inputChange}
                                                        name="contractOnboardWeighingDescription"
                                                        value={state.contractOnboardWeighingDescription}
                                                    />
                                                    <FormHelperText error>{state.errors.contractOnboardWeighingDescription}</FormHelperText>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="flex-end"> */}
                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={12}>
                                                    <FormHelperText shrink>Temp Probe?</FormHelperText>
                                                    <Grid container>
                                                        <Grid item xs>
                                                            <YesNoRadio
                                                                name="contractTempProbe"
                                                                value={state.contractTempProbe || false}
                                                                onChange={inputChange}
                                                            />
                                                        </Grid>
                                                        {state.contractTypeID == 1 && (
                                                            <Grid item xs={4}>
                                                                {' '}
                                                                <SimpleField
                                                                    type="number"
                                                                    onChange={inputChange}
                                                                    name="contractTempProberCharge"
                                                                    value={state.contractTempProberCharge}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <Typography color="secondary" variant="h6">
                                                                                    £
                                                                                </Typography>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <FormHelperText shrink>Temp Probe </FormHelperText>
                                                    <SimpleField
                                                        onChange={inputChange}
                                                        name="contractTempProberDescription"
                                                        value={state.contractTempProberDescription}
                                                    />
                                                    <FormHelperText error>{state.errors.contractTempProberDescription}</FormHelperText>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {state.contractTypeID == 1 && (
                                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={12}>
                                                        <FormHelperText shrink>MOT</FormHelperText>
                                                        <Grid container>
                                                            <Grid item xs>
                                                                <YesNoRadio onChange={inputChange} name="contractMOT" value={state.contractMOT} />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <SimpleField
                                                                    type="number"
                                                                    onChange={inputChange}
                                                                    name="contractMOTCharge"
                                                                    value={state.contractMOTCharge}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <Typography color="secondary" variant="h6">
                                                                                    £
                                                                                </Typography>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <FormHelperText error>{state.errors.contractMOT}</FormHelperText>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FormHelperText shrink>MOT </FormHelperText>
                                                        <SimpleField
                                                            onChange={inputChange}
                                                            name="contractMOTDescription"
                                                            value={state.contractMOTDescription}
                                                        />
                                                        <FormHelperText error>{state.errors.contractMOTDescription}</FormHelperText>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={12}>
                                                    <FormHelperText shrink>Mid Season Calibration?</FormHelperText>
                                                    <Grid container>
                                                        <Grid item xs>
                                                            <YesNoRadio
                                                                name="contractMidSeasonCalibration"
                                                                value={state.contractMidSeasonCalibration || false}
                                                                onChange={inputChange}
                                                            />
                                                        </Grid>
                                                        {state.contractTypeID == 1 && (
                                                            <Grid item xs={4}>
                                                                <SimpleField
                                                                    type="number"
                                                                    onChange={inputChange}
                                                                    name="contractMidSeasonCalibrationCharge"
                                                                    value={state.contractMidSeasonCalibrationCharge}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <Typography color="secondary" variant="h6">
                                                                                    £
                                                                                </Typography>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <FormHelperText shrink>Mid Season Calibration </FormHelperText>
                                                    <SimpleField
                                                        onChange={inputChange}
                                                        name="contractMidSeasonCalibrationDescription"
                                                        value={state.contractMidSeasonCalibrationDescription}
                                                    />
                                                    <FormHelperText error>{state.errors.contractMidSeasonCalibrationDescription}</FormHelperText>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {state.contractTypeID == 1 && (
                                            <Grid item xs={12} sm={6} md={4} lg={3} xl={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={12} xl={6}>
                                                        <FormHelperText shrink>12 Weekly Brake Roller Test?</FormHelperText>
                                                        <Grid container>
                                                            <Grid item xs>
                                                                <YesNoRadio
                                                                    name="contract12WeeklyBrakeRollerTests"
                                                                    value={state.contract12WeeklyBrakeRollerTests || false}
                                                                    onChange={inputChange}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <SimpleField
                                                                    type="number"
                                                                    onChange={inputChange}
                                                                    name="contract12WeeklyBrakeRollerTestsCharge"
                                                                    value={state.contract12WeeklyBrakeRollerTestsCharge}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <Typography color="secondary" variant="h6">
                                                                                    £
                                                                                </Typography>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} xl={6}>
                                                        <FormHelperText shrink>12 Weekly Brake Roller Test </FormHelperText>
                                                        <SimpleField
                                                            onChange={inputChange}
                                                            name="contract12WeeklyBrakeRollerTestsDescription"
                                                            value={state.contract12WeeklyBrakeRollerTestsDescription}
                                                        />
                                                        <FormHelperText error>{state.errors.contract12WeeklyBrakeRollerTestsDescription}</FormHelperText>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={6}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={12} xl={6}>
                                                    <FormHelperText shrink>Bespoke</FormHelperText>
                                                    <Grid container>
                                                        <Grid item xs>
                                                            <YesNoRadio onChange={inputChange} name="contractBespoke" value={state.contractBespoke} />
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <SimpleField
                                                                type="number"
                                                                onChange={inputChange}
                                                                name="contractBespokeCharge"
                                                                value={state.contractBespokeCharge}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            {/* <EuroIcon color="secondary" /> */}
                                                                            <Typography color="secondary" variant="h6">
                                                                                £
                                                                            </Typography>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <FormHelperText error>{state.errors.contractBespoke}</FormHelperText>
                                                </Grid>
                                                <Grid item xs={12} sm={12} xl={6}>
                                                    <FormHelperText shrink>Bespoke </FormHelperText>
                                                    <SimpleField
                                                        onChange={inputChange}
                                                        name="contractBespokeDescription"
                                                        value={state.contractBespokeDescription}
                                                    />
                                                    <FormHelperText error>{state.errors.contractBespokeDescription}</FormHelperText>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {state.contractTypeID == 2 && (
                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                <FormHelperText shrink>Cab Beacons</FormHelperText>
                                                <YesNoRadio name="contractCabBeacons" value={state.contractCabBeacons || false} onChange={inputChange} />
                                            </Grid>
                                        )}
                                        <Grid item xs={12} lg={12} xl={12} className="mandatory-fields">
                                            <SimpleField
                                                multiline
                                                rows={10}
                                                FormHelperText="Notes"
                                                disabled={state.disabled}
                                                placeholder="Notes"
                                                value={state.contractNotes}
                                                onChange={inputChange}
                                                name="contractNotes"
                                                // errors={state.errors.contractDescription}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={5} style={{ marginTop: '-3px' }}>
                            <div className="single-note">
                                <div
                                    className="msg"
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        padding: '5px 10px',
                                        backgroundColor: '#F5F5F5',
                                        border: '1px solid rgba(0,0,0,0.2)',
                                        height: '32px'
                                    }}
                                >
                                    History
                                </div>
                            </div>
                            <div
                                style={{
                                    padding: '5px',
                                    minHeight: '430px',
                                    // maxHeight: '430px',
                                    // height: 'calc(100vh - 240px)',
                                    overflow: 'auto',
                                    border: '1px solid rgba(0,0,0,0.2)'
                                }}
                            >
                                {state.contractNotesList.length > 0 ? (
                                    <>
                                        {state.contractNotesList?.map((n, i) => (
                                            <div style={{ background: i % 2 ? '#F6EEEC' : '#ECF4F6', padding: '5px 10px' }}>
                                                <JobSingleNote key={i} note={n} />
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <div style={{ background: '#ECF4F6', padding: '5px 10px' }}>No Record Found</div>
                                )}
                            </div>
                        </Grid>
                    </Grid>

                    <br />
                    <Grid container spacing={1}>
                        <Grid item xs={props.vehicleContractID ? 12 : 6} className="submit_btn">
                            <SecondaryButton
                                isBtnLoader={btnLoader}
                                onClick={submitData}
                                // disabled={!(checkhaveAccess?.CanUpdateContracts && checkhaveAccess?.CanAddContracts)}
                                disabled={!checkhaveAccess[`${state.contractTypeID == 1 ? 'CanAddHireContracts' : 'CanAddMaintenanceContracts'}`]}
                            >
                                {props.vehicleContractID ? 'Update' : 'Submit'}
                            </SecondaryButton>
                        </Grid>
                        <Grid item xs={6} className="submit_btn" style={{ display: props.vehicleContractID ? 'none' : 'block' }}>
                            <SecondaryButton onClick={props.onClose} forceEnabled={true}>
                                Cancel
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </div>
            )}
        </>
    );
};
const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <ContractField {...props} />
        </StateProvider>
    );
};

export default Screen;
