import { FormHelperText, Grid, Typography, InputLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { DatePicker, YesButton, NoButton, formatters, TabView, SimpleField, SingleSelect } from '../../../../../../Core/Controls';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import '../../../../../commonStyle.scss';
import '../../../Vehicles/ScheduleVehicle/scheduleVehicleStyle.scss';
import '../../../controllerStyle.scss';
import '../../../Notes/NotesandSticky.scss';
import moment from 'moment';
import {
    getJobDetailsById,
    getAllJobDropDowns,
    postJobDetails,
    getJobEnggById
} from '../../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import JobHistory from '../../../Vehicles/ScheduleVehicle/jobHistory';
import UpcomingEvents from '../../../Vehicles/ScheduleVehicle/upcomingEvents';
import StickyNotes from '../../../Notes/stickyNotes';
import { useHistory } from 'react-router-dom';
import JobDetailsInfo from '../../../Vehicles/ScheduleVehicle/showJobDetailsInfo';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import FileUploaderButton from '../../../../../../Core/Controls/GenericFileUploader';
import InternalstatusBasedButton from './statusBtn';
import JobQueriesScreen from '../../jobQueriesScreen';
import { ChipWithoutLable, CustomChip } from '../../../../../../Core/Controls/Inputs/DatePicker';
import WorkIcon from '@material-ui/icons/Work';
import BuildIcon from '@material-ui/icons/Build';
import { AppStorage } from '../../../../../../Core/Basic/storage-service';
import { useLoginInfo } from '../../../../../../Core/Hooks/useLoginInfo';
import AddVor from '../../../VOR/AddEditVor';
import AddIcon from '@material-ui/icons/Add';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import ServiceAgentDetails from '../../../DetailsModal/serviceAgent';
import JobUpdtaeNotes from './jobUpdateNotes';
import DepotFilterList from '../../../../../../Core/Controls/LocationField/depotFilterList';

const alertBoxColorCode = {
    AA: 'warning',
    EP: 'warning',
    JP: 'warning',
    AU: 'error',
    JQ: 'error',
    FR: 'error',
    JD: 'error',
    JR: 'error',
    CA: 'success',
    JC: 'success',
    JA: 'success'
};

const InternalJobDetail = (props) => {
    const { jobDetails, setJobDetails } = props;
    const [jobDropDowns, setJobDropDowns] = useState({ serviceAgents: [], depots: [], jobTypes: [], technician: [] });
    const [state, setState] = useState({ depotFilterName: '', depotsID: null, filterTechnician: [] });
    const [errors, setErrors] = useState({});
    const { showToastSuccess, showToastError } = useToast();
    const history = useHistory();
    const [tempOptions, setTempOptions] = useState({
        jobServiceAgentIDValue: null,
        jobDepotIDValue: null,
        jobTypeIDValue: null,
        jobEngineerUserID: null
    });
    let checkhaveAccess = AppStorage.getAllowAcces();
    const { userRoles } = useLoginInfo();
    const isDriver = userRoles.includes('driver');
    const isCustomer = userRoles.includes('customer');
    const isServiceAgent = userRoles.includes('servicearea');
    const isEngineer = userRoles.includes('engineer');
    const pullJobDropDowns = async () => {
        let res = await getAllJobDropDowns(jobDetails.jobID);
        return res.data;
    };

    useEffect(async () => {
        if (jobDetails.jobID) {
            let dropDowns = await pullJobDropDowns(jobDetails.jobID);
            setJobDropDowns(dropDowns);
        }
    }, [jobDetails.jobID]);

    const refreshScreen = (res) => {
        res && setJobDetails((st) => ({ ...st, refreshDependancy: new Date() }));
        res && props.pullJobDetail();
    };

    //tech and Service Area disabled condition

    let serviceAreaAndTechDisabled =
        // jobDetails.jobStatusID === 'TS' ||
        jobDetails.jobStatusID === 'CR' || jobDetails.jobStatusID === 'AS' || jobDetails.jobStatusID === 'IN';

    const showVorModal = (res) => {
        setJobDetails((st) => ({
            ...st,
            [res]: true
        }));
    };

    const closeVORModal = (res) => {
        setJobDetails((st) => ({
            ...st,
            showVORPopup: false,
            showSaModal: false
        }));
        res && refreshScreen(true);
    };

    useEffect(() => {
        let newSt = {};
        let depotFilterName = '';
        let depotsID = null;

        if (jobDetails?.jobServiceAgentID && jobDropDowns?.serviceAgents?.length > 0) {
            newSt.jobServiceAgentIDValue = jobDropDowns?.serviceAgents.find(({ id }) => id === jobDetails?.jobServiceAgentID);
        }

        if (jobDetails?.jobDepotID && jobDropDowns?.depots?.length > 0) {
            depotFilterName = jobDropDowns?.depots.find(({ id }) => id === jobDetails?.jobDepotID)?.name;
            depotsID = jobDropDowns?.depots.find(({ id }) => id === jobDetails?.jobDepotID)?.id;
        }

        if (jobDetails?.jobTypeID && jobDropDowns?.jobTypes?.length > 0) {
            newSt.jobTypeIDValue = jobDropDowns?.jobTypes.find(({ id }) => id === jobDetails?.jobTypeID);
        }
        if (jobDetails?.jobEngineerUserID && jobDropDowns?.technician?.length > 0) {
            newSt.jobEngineerUserID = jobDropDowns?.technician.find(({ id }) => id === jobDetails?.jobEngineerUserID);
        }
        setTempOptions((st) => ({
            ...st,
            ...newSt
        }));

        setState((st) => ({ ...st, depotFilterName: depotFilterName, depotsID: depotsID }));

        PullEngineerList(jobDetails?.jobServiceAgentID);
    }, [
        jobDetails?.jobServiceAgentID,
        jobDetails?.jobDepotID,
        jobDropDowns?.depots,
        jobDetails?.jobTypeID,
        jobDropDowns?.jobTypes,
        jobDropDowns?.serviceAgents,
        jobDropDowns.technician,
        jobDetails?.jobEngineerUserID
    ]);

    const tempTab = [
        {
            label: 'History',
            body: <JobHistory jobID={props.scheduleJobID} refreshDependancy={jobDetails.refreshDependancy} height={400} />
        },
        {
            label: 'Job Updates',
            body: (
                <JobQueriesScreen
                    VehicleID={props.VehicleID}
                    JobID={props.scheduleJobID}
                    fromPage="job"
                    isHeaderNotShow={true}
                    height={400}
                    refreshDependancy={jobDetails.refreshDependancy}
                    isDisabled={jobDetails.isEditableFields}
                />
            )
        },

        {
            label: 'Incompleted Events',
            body: <UpcomingEvents vehicleID={props.vehicleID} refreshDependancy={jobDetails.refreshDependancy} jobID={props.scheduleJobID} height={400} />
        },
        {
            label: 'Reminder Notes',
            body: (
                <JobUpdtaeNotes
                    orderNo={jobDetails?.jobAgentOrderNo}
                    regNo={jobDetails.jobRegNumber}
                    vehicleID={props.vehicleID}
                    jobID={props.scheduleJobID}
                    height={400}
                    isDisabled={jobDetails.isEditableFields}
                />
            )
        }
    ];

    const validate = () => {
        let noErrors = true;
        let err = {};
        if (!state.depotsID) {
            err.jobDepotID = true;
            noErrors = false;
        }
        // if (!tempOptions.jobTypeIDValue) {
        //     err.jobTypeID = true;
        //     noErrors = false;
        // }
        if (!tempOptions.jobServiceAgentIDValue) {
            err.jobServiceAgentID = true;
            noErrors = false;
        }
        if (+jobDetails.jobLimit < 0) {
            err.jobLimit = true;
            noErrors = false;
        }
        if (+jobDetails.jobMileage < 0) {
            err.jobMileage = true;
            noErrors = false;
        }
        if (!tempOptions.jobEngineerUserID) {
            err.jobEngineerUserID = true;
            noErrors = false;
        }

        setErrors(err);
        return noErrors;
    };

    const handleData = async () => {
        if (validate()) {
            let data = {
                jobID: props.scheduleJobID,
                jobServiceAgentID: tempOptions.jobServiceAgentIDValue?.id,
                jobDepotID: state.depotsID,
                jobTypeID: tempOptions.jobTypeIDValue?.id,
                jobCustomerOrderNo: jobDetails.jobCustomerOrderNo || null,
                jobContactDetails: jobDetails.jobContactDetails || null,
                jobEstimatedCost: jobDetails.jobEstimatedCost || null,
                jobProformaInvNet: jobDetails.jobProformaInvNet || null,
                jobLimit: jobDetails.jobLimit || null,
                jobAuthorityRef: jobDetails.jobAuthorityRef || null,
                jobMileage: jobDetails.jobMileage || null,
                jobMileageDate: jobDetails.jobMileageDate || null,
                jobOutOfHours: jobDetails.jobOutOfHours || false,
                jobEngineerUserID: tempOptions.jobEngineerUserID?.id || ''
            };

            let response = await postJobDetails(data);
            if (response) {
                response.success ? showToastSuccess('Updated Job Details Successfully') : showToastError(response.message || 'Something went wrong');
                refreshScreen(true);
            } else {
                showToastError('Something went wrong');
            }
        }
    };

    const valueChanged = (ev) => {
        let temp = ev.target;
        setJobDetails((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
        setErrors({});
    };

    const radioButtonChange = useCallback((e) => {
        const { name, value, type } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setJobDetails((st) => ({ ...st, [name]: true }));
        } else if (value === 'false' && type.toLowerCase() === 'radio') {
            setJobDetails((st) => ({ ...st, [name]: false }));
        }
    }, []);

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({ ...st, [nm]: val }));
    };
    const singleSelectServiceArea = (nm) => (ev, val) => {
        if (val?.id) PullEngineerList(val?.id);
        setTempOptions((st) => ({ ...st, [nm]: val, jobEngineerUserID: null }));
    };
    let PullEngineerList = async (serviceAreaID) => {
        if (serviceAreaID) {
            let res = await getJobEnggById(serviceAreaID);
            if (res.success) {
                setState((d) => ({ ...d, filterTechnician: res.data.list }));
            }
        }
    };

    const goPreviousScreen = () => {
        history.goBack();
    };

    const handleEditDefect = (val) => {
        if (jobDetails.defectTypeCode == 'DD') {
            history.push({
                pathname: `/maintenance/defect/edit/${jobDetails.jobDefectID}/${jobDetails.jobVehicleID}`,
                state: 'Edit'
            });
        } else {
            history.push({
                pathname: `/maintenance/breakdowns/Edit/${jobDetails.jobDefectID}/${jobDetails.jobVehicleID}`,
                state: 'Edit'
            });
        }
    };

    const getMakeModel =
        jobDetails.vehicleMake || jobDetails.vehicleModel
            ? ` (${jobDetails.vehicleMake ? jobDetails.vehicleMake : ''} ${jobDetails.vehicleModel ? jobDetails.vehicleModel : ''})`
            : '';
    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${props.vehicleID}`,
            search: ''
        });
    };

    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const getDepotsValue = (val) => {
        try {
            setState((st) => {
                let newSt = { ...st };
                if (val.id) {
                    newSt.depotsID = val.id;
                    newSt.depotFilterName = val.name;
                } else {
                    newSt.depotsID = val.depotID;
                    newSt.depotFilterName = `${val.depotName} [${val.depotAccountCode || 'N/A'}](${val.depotAddress1}, ${val.depotTown} , ${
                        val.depotPostCode
                    })`;
                }
                return newSt;
            });
        } catch (error) {
            console.log(error);
        }
    };

    const clearVal = () => {
        try {
            setState((st) => {
                let newSt = { ...st };

                newSt.depotsID = null;
                newSt.depotFilterName = '';

                return newSt;
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="controller-screen" style={{ padding: '1px' }}>
            <Grid container>
                <Grid container spacing={2} style={{ marginTop: 5 }}>
                    <Grid item xs={12} lg={7}>
                        <Grid container justify="space-between" style={{ padding: '10px 10px 0px 0px' }} className="grid-style grey-grid">
                            <Grid item>
                                <Typography variant="h5" color="secondary">
                                    Job Details
                                </Typography>
                            </Grid>
                            <Grid item style={{ padding: '2px 5px 6px 2px' }}>
                                <Grid container spacing={1}>
                                    {jobDetails.jobDefectID && (
                                        <Grid item>
                                            <ChipWithoutLable
                                                icon={BuildIcon}
                                                iconFontSize={16}
                                                onClick={handleEditDefect}
                                                className="btn-m"
                                                toolTipTitle={jobDetails.defectTypeCode == 'DD' ? 'Defect Details' : 'Breakdown Details'}
                                            />
                                        </Grid>
                                    )}
                                    {jobDetails.isEditableFields && !jobDetails.vorVehicleID && !isDriver && !isCustomer && !isServiceAgent && (
                                        <Grid item>
                                            <CustomChip
                                                label={`${jobDetails.vorVehicleID ? 'Update' : 'Add'} VOR`}
                                                icon={AddIcon}
                                                toolTipTitle={`${jobDetails.vorVehicleID ? 'Update' : 'Add'} VOR`}
                                                iconFontSize={16}
                                                onClick={() => showVorModal('showVORPopup')}
                                            />
                                        </Grid>
                                    )}
                                    {/* {true && (
                                        <Grid item>
                                            <span style={{ position: 'relative', top: '-10px' }}>
                                                <FileUploaderButton
                                                    recordId={props.scheduleJobID}
                                                    fileUploaderConfigName="JobScreen"
                                                    fileCount={jobDetails.fileCount}
                                                    gridRelaod={() => refreshScreen(true)}
                                                    isDash={true}
                                                />
                                            </span>
                                        </Grid>
                                    )} */}

                                    <Grid item>
                                        {jobDetails.vehicleJobStatus && (
                                            <InternalstatusBasedButton
                                                refreshScreen={refreshScreen}
                                                handleData={handleData}
                                                vehicleID={props.vehicleID}
                                                position={props.position}
                                                status={jobDetails.jobStatusID}
                                                data={jobDetails}
                                                jobID={props.scheduleJobID ? props.scheduleJobID : jobDetails.jobID}
                                                goPreviousScreen={goPreviousScreen}
                                                showSave={checkhaveAccess?.CanUpdateJobs}
                                                btnType="chip"
                                                isShownHeadrer={true}
                                                otherOptionsBtnShow={checkhaveAccess?.CanUpdateJobs}
                                                customerOrderNo={jobDetails.jobCustomerOrderNo}
                                                checkStatus={state.checkStatus}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container style={{ padding: '10px', border: '1px solid lightgrey' }} className="grey-grid">
                            {jobDetails.jobStatusID == 'JC' && jobDetails.jobApproved ? (
                                <JobDetailsInfo jobDetails={jobDetails} />
                            ) : (
                                <Grid container spacing={1}>
                                    {/* {!jobDetails.isEditableFields || isEngineer || serviceAreaAndTechDisabled ? (
                                        <Grid item xs={12} md={6} className="input-style">
                                            <div className="input-style" style={{ cursor: 'pointer' }} onClick={() => showVorModal('showSaModal')}>
                                                <InputLabel shrink className="input_label">
                                                    Service Area
                                                </InputLabel>
                                                <div className="input_value">{tempOptions.jobServiceAgentIDValue?.name || 'N/A'}</div>
                                            </div>
                                        </Grid>
                                    ) : ( */}
                                    <Grid item xs={12} sm={6} md={6} className="mandatory-fields">
                                        <SingleSelect
                                            inputLabel="Service Area"
                                            isRequired={true}
                                            options={jobDropDowns?.serviceAgents}
                                            value={tempOptions.jobServiceAgentIDValue}
                                            onChange={singleSelectServiceArea('jobServiceAgentIDValue')}
                                            isDisabled={!jobDetails.isEditableFields || isEngineer || serviceAreaAndTechDisabled}
                                        />
                                        <FormHelperText error>{errors.jobServiceAgentID ? 'This field is required' : null}</FormHelperText>
                                    </Grid>
                                    {/* )} */}
                                    <Grid item xs={12} sm={6} md={6} className="mandatory-fields">
                                        <SingleSelect
                                            inputLabel="Engineer"
                                            options={state.filterTechnician}
                                            value={tempOptions.jobEngineerUserID}
                                            isRequired={true}
                                            onChange={singleSelectChange('jobEngineerUserID')}
                                            isDisabled={!jobDetails.isEditableFields || serviceAreaAndTechDisabled}
                                        />
                                        <FormHelperText error>{errors.jobEngineerUserID ? 'This field is required' : null}</FormHelperText>
                                    </Grid>

                                    {!jobDetails.isEditableFields ? (
                                        <Grid item xs={12} md={6} className="input-style">
                                            <InputLabel shrink className="input_label">
                                                Job Depot
                                            </InputLabel>
                                            <div className="input_value">{jobDetails.depotName || 'N/A'}</div>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} sm={12} md={12} className="mandatory-fields">
                                            <InputLabel required={true} shrink>
                                                Job Depot
                                            </InputLabel>
                                            <DepotFilterList
                                                onChange={inputChange}
                                                name="depotFilterName"
                                                value={state.depotFilterName}
                                                options={jobDropDowns.depots}
                                                getVal={getDepotsValue}
                                                clearValue={clearVal}
                                            />

                                            <FormHelperText error>{errors.jobDepotID ? 'This field is required' : null}</FormHelperText>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={6} md={6}>
                                        <SimpleField
                                            inputLabel="Customer Order No"
                                            value={jobDetails.jobCustomerOrderNo}
                                            name="jobCustomerOrderNo"
                                            onChange={valueChanged}
                                            isDisabled={!jobDetails.isEditableFields}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <SimpleField
                                            inputLabel="Contact Details"
                                            value={jobDetails.jobContactDetails}
                                            name="jobContactDetails"
                                            onChange={valueChanged}
                                            isDisabled={!jobDetails.isEditableFields}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} sm={6} md={6}>
                                        <SingleSelect
                                            inputLabel="Job Priority"
                                            isRequired={true}
                                            options={jobDropDowns.jobTypes}
                                            value={tempOptions.jobTypeIDValue}
                                            onChange={singleSelectChange('jobTypeIDValue')}
                                            isDisabled={!jobDetails.isEditableFields}
                                        />
                                        <FormHelperText error>{errors.jobTypeID ? 'This field is required' : null}</FormHelperText>
                                    </Grid> */}

                                    {!jobDetails.isEditableFields && (
                                        <Grid item xs={12} sm={4} md={6} className="input-style">
                                            <div className="input-style" style={{ cursor: 'pointer' }} onClick={() => showVorModal('showSaModal')}>
                                                <InputLabel shrink className="input_label">
                                                    Approved Order No
                                                </InputLabel>
                                                <div className="input_value">{jobDetails.jobApprovedOrderNo || 'N/A'}</div>
                                            </div>
                                        </Grid>
                                    )}
                                    {/* <Grid item xs={12} sm={6} md={4}>
                                        <SimpleField
                                            inputLabel="Order Limit"
                                            type="number"
                                            value={jobDetails.jobLimit || ''}
                                            name="jobLimit"
                                            onChange={valueChanged}
                                            isDisabled={!jobDetails.isEditableFields}
                                        />
                                        <FormHelperText error>{errors.jobLimit ? 'This value can not be negative' : null}</FormHelperText>
                                    </Grid> */}
                                    {/* <Grid item xs={12} sm={6} md={4}>
                                       
                                        <DatePicker
                                            inputLabel="Job Mileage Date"
                                            isRequired={true}
                                            inputProps={{
                                                max: new Date().toISOString().slice(0, 10)
                                            }}
                                            value={moment(jobDetails.jobMileageDate).format('YYYY-MM-DD')}
                                            name="jobMileageDate"
                                            onChange={valueChanged}
                                            isDisabled={!jobDetails.isEditableFields}
                                        />
                                        <FormHelperText error>{errors.jobMileageDate ? 'This value can not be negative' : null}</FormHelperText>
                                    </Grid> */}

                                    {/* <Grid item xs={12} sm={6} md={4}>
                                        <SimpleField
                                            inputLabel="Job Mileage"
                                            type="number"
                                            value={jobDetails.jobMileage}
                                            name="jobMileage"
                                            onChange={valueChanged}
                                            isDisabled={!jobDetails.isEditableFields}
                                        />
                                        <FormHelperText error>{errors.jobMileage ? 'This value can not be negative' : null}</FormHelperText>
                                    </Grid> */}

                                    {/* <Grid item xs={12} sm={6} md={4}>
                                        <Grid container>
                                            <Grid container item xs={12}>
                                                <InputLabel shrink>Out Of Hours?</InputLabel>
                                            </Grid>
                                            <Grid container item spacing={1}>
                                                <RadioGroup
                                                    row
                                                    aria-label="jobOutOfHours"
                                                    name="jobOutOfHours"
                                                    value={jobDetails.jobOutOfHours}
                                                    onChange={radioButtonChange}
                                                >
                                                    <FormControlLabel
                                                        checked={jobDetails.jobOutOfHours === true}
                                                        value={true}
                                                        control={<Radio />}
                                                        label="Yes"
                                                    />
                                                    <FormControlLabel
                                                        checked={jobDetails.jobOutOfHours === false}
                                                        value={false}
                                                        control={<Radio />}
                                                        label="No"
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                </Grid>
                            )}
                            <Grid container item xs={12} style={{ paddingBottom: '5px' }}>
                                {jobDetails.vehicleJobStatus && (
                                    <InternalstatusBasedButton
                                        refreshScreen={refreshScreen}
                                        handleData={handleData}
                                        vehicleID={props.vehicleID}
                                        status={jobDetails.jobStatusID}
                                        data={jobDetails}
                                        jobID={props.scheduleJobID ? props.scheduleJobID : jobDetails.jobID}
                                        goPreviousScreen={goPreviousScreen}
                                        showSave={true}
                                        otherOptionsBtnShow={false}
                                        isDeleteHide={true}
                                    />
                                )}
                            </Grid>
                            {/* <br />
                            <Grid item xs={12}>
                                <JobItemsScreen scheduleJobID={props.scheduleJobID} />
                            </Grid> */}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} lg={5} style={{ paddingTop: '0px' }}>
                        <Grid item xs={12}>
                            <TabView tabList={tempTab} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {jobDetails.showSaModal ? (
                <DialogComp open={true} onClose={() => closeVORModal(false)} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={tempOptions.jobServiceAgentIDValue?.id} onFormSubmit={() => closeVORModal(false)} />
                </DialogComp>
            ) : null}
            {jobDetails.showVORPopup ? (
                <DialogComp title={`${jobDetails.vorVehicleID ? 'Update' : 'Add'} VOR`} onClose={() => closeVORModal(false)} maxWidth="lg" overflow="auto">
                    <AddVor
                        VehicleID={jobDetails.jobVehicleID}
                        update={jobDetails.vorVehicleID}
                        vorVehicleID={jobDetails.vorVehicleID}
                        jobID={jobDetails.jobID}
                        onClose={closeVORModal}
                    />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default InternalJobDetail;
