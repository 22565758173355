import React, { useEffect, useState, useContext } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import { DriverProvider as StateProvider, DriverScreenContext } from './DriverProvider';
import AddEditDriver from './addEditDriver';
import Grid from '@material-ui/core/Grid';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import '../controllerStyle.scss';
import StickyNotes from './../Notes/stickyNotes';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { CustomChip } from '../../../../Core/Controls';
import FileUploaderButton from '../../../../Core/Controls/GenericFileUploader';
import { formatters } from '../../../../Core/Controls';

export const DriverDashBoard = (props) => {
    const { state } = useContext(DriverScreenContext);

    const [data, setData] = useState({
        show: false,
        DriverID: null
    });

    const isCustomer = useLoginInfo().userRoles.includes('customer');

    useEffect(() => {
        if (props.driverID) {
            setData((st) => ({
                ...st,
                DriverID: props.driverID
            }));
        }
    }, []);

    const handleAdd = (record) => {
        setData((st) => ({
            ...st,
            show: true,
            DriverID: record
        }));
    };

    const closeDriverModal = () => {
        setData((st) => ({
            ...st,
            show: false
        }));
    };

    return (
        <div className="controller-screen">
            {data.show ? (
                <AddEditDriver driverID={data.DriverID} onFormSubmit={closeDriverModal} />
            ) : (
                <div className="edit-controller-btn">
                    <StickyNotes frompage="Vehicle" frompage="Drivers" NoteDriverID={props.driverID} />
                    <Grid container spacing={1} className="mt_10" style={{ backgroundColor: '#f5f9f9' }}>
                        <Grid container className="grid-style white-grid" style={{ justifyContent: 'space-between' }}>
                            <Grid item className="mt_11">
                                <Typography variant="h5" color="secondary">
                                    Driver Details
                                </Typography>
                            </Grid>
                            <Grid item className="grid-title-row">
                                <div className="edit-controller-btn mt_10">
                                    <FileUploaderButton recordId={props.driverID} fileCount={state.fileCount} fileUploaderConfigName="Driver" />
                                    &nbsp;&nbsp;
                                    {!data.show && (
                                        <CustomChip
                                            icon={EditIcon}
                                            label="Edit"
                                            onClick={() => handleAdd(data.DriverID)}
                                            toolTipTitle="Edit Driver"
                                            style={{ fontWeight: 'bold' }}
                                        ></CustomChip>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-style gray-grid">
                            {!isCustomer && (
                                <Grid item xs={4} sm={4}>
                                    <div className="controller-label">
                                        <Typography variant="caption">Customer</Typography>
                                    </div>
                                    <Typography variant="button">{state.customer || 'N/A'}</Typography>
                                </Grid>
                            )}
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Depot Name</Typography>
                                </div>
                                <Typography variant="button">{state.driverDepotName || 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Cost Centre</Typography>
                                </div>
                                <Typography variant="button">{state.driverCostCentreDescription || 'N/A'}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-style white-grid">
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Licence Type</Typography>
                                </div>
                                <Typography variant="button">{state.driverLicenceDescription || 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">First name</Typography>
                                </div>
                                <Typography variant="button">{state.firstName || 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Middle Name</Typography>
                                </div>
                                <Typography variant="button">{state.middleName || 'N/A'}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-style gray-grid">
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Last Name</Typography>
                                </div>
                                <Typography variant="button">{state.lastName || 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Address1</Typography>
                                </div>
                                <Typography variant="button">{state.address1 || 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Address2</Typography>
                                </div>
                                <Typography variant="button">{state.address2 || 'N/A'}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-style white-grid">
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Town</Typography>
                                </div>
                                <Typography variant="button">{state.town || 'N/A'}</Typography>
                            </Grid>{' '}
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">County</Typography>
                                </div>
                                <Typography variant="button">{state.county || 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Telephone Number</Typography>
                                </div>
                                <Typography variant="button">{formatters.phoneNumberFormatter(state.telephone)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-style gray-grid">
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Mobile Number</Typography>
                                </div>
                                <Typography variant="button">{formatters.phoneNumberFormatter(state.driverMobile)}</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Date of Birth</Typography>
                                </div>
                                <Typography variant="button">{state.dateOfBirth1 || 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Payroll Number</Typography>
                                </div>
                                <Typography variant="button">{state.driverPayrollNumber || 'N/A'}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-style white-grid">
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Licence Check Frequency</Typography>
                                </div>
                                <Typography variant="button">{state.licenceCheckFrequencyDescription || 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Fuel Card Provider</Typography>
                                </div>
                                <Typography variant="button">{state.fuelCardProviderName || 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Fuel Card Number</Typography>
                                </div>
                                <Typography variant="button">{state.driverFuelCardNumber || 'N/A'}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-style gray-grid">
                            <Grid item xs={4} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Fuel Card PIN</Typography>
                                </div>
                                <Typography variant="button">{state.driverFuelCardPIN || 'N/A'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
};

const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <DriverDashBoard {...props} />
        </StateProvider>
    );
};
export default Screen;
