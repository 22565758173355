import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { formatters, SecondaryButton, TextBox } from '../../../../../../Core/Controls';
import { makeStyles } from '@material-ui/core/styles';
import { getStandardLabourLines, postStandardLabourLines } from '../../../../../../Core/Services/InternalUser/JOB/jobItemsService';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import { debounce, some } from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    table: {
        border: '1px solid rgba(0,0,0,0.05)',
        '& .MuiTableCell-root': {
            padding: '5px 7px 5px 0px'
        },
        '& .MuiTableRow-root:hover': {
            backgroundColor: 'orange'
        }
    },
    textError: {
        color: 'white'
    },
    textSucc: {
        color: 'black'
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '450px'
    },
    noDataText: {
        textAlign: 'center',
        padding: theme.spacing(2)
    }
}));

const LabourLineTableComp = ({ JobID, onClose, refreshScreen }) => {
    const classes = useStyles();
    const { showToastSuccess, showToastError } = useToast();
    const [rows, setRows] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [btnLoader, setBtnLoader] = useState(false);

    // Fetch labour lines data
    useEffect(() => {
        const fetchLabourLines = async () => {
            setLoading(true);
            try {
                const res = await getStandardLabourLines();
                if (res.success) {
                    setRows(res.data.table);
                } else {
                    setRows([]);
                    showToastError(res.message);
                }
            } catch (error) {
                setRows([]);
                showToastError('Error fetching labour lines.');
            }
            setLoading(false);
        };
        fetchLabourLines();
    }, []);

    // Filter rows based on search text
    useEffect(() => {
        const applyFilter = () => {
            const filterTextLower = filterText.trim().toLowerCase();
            if (!filterTextLower) {
                setFilteredTableData(rows);
                return;
            }

            const filterWords = filterTextLower.split(' ');
            const filteredData = rows.filter((row) => filterWords.every((word) => some(row, (value) => `${value}`.toLowerCase().includes(word))));
            setFilteredTableData(filteredData);
        };

        const debouncedFilter = debounce(applyFilter, 500);
        debouncedFilter();

        return () => debouncedFilter.cancel();
    }, [filterText, rows]);

    // Handle row selection for multi-select
    const handleRowClick = (rowData) => () => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.some((row) => row.lineItemID === rowData.lineItemID)) {
                // Remove row if already selected
                return prevSelectedRows.filter((row) => row.lineItemID !== rowData.lineItemID);
            }
            // Add row if not selected
            return [...prevSelectedRows, rowData];
        });
    };

    // Handle submission of selected rows
    const onSubmitStandard = useCallback(async () => {
        if (selectedRows.length === 0) {
            showToastError('No rows selected.');
            return;
        }

        setBtnLoader(true);
        const ids = selectedRows.map((row) => row.lineItemID);

        try {
            const res = await postStandardLabourLines({ jobID: JobID, ids });

            if (res.success) {
                showToastSuccess(`${selectedRows.length} Labour Line(s) added successfully`);
                refreshScreen();
                setSelectedRows([]);
            } else {
                showToastError(res.message);
            }
        } catch (error) {
            showToastError('Error submitting labour lines.');
        } finally {
            setBtnLoader(false);
        }
    }, [selectedRows, JobID]);

    // Show loader when loading, otherwise display the table
    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <TextBox margin="normal" required label="Search" onChange={(e) => setFilterText(e.target.value)} value={filterText} />
                        </Grid>
                        <Grid item>
                            <SecondaryButton
                                style={{ borderRadius: '20px', marginTop: '15px' }}
                                onClick={onSubmitStandard}
                                disabled={selectedRows.length === 0}
                                isBtnLoader={btnLoader}
                            >
                                Add
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    {loading ? (
                        <div className={classes.loaderContainer}>
                            <CircularProgress color="secondary" />
                        </div>
                    ) : (
                        <TableContainer style={{ height: '450px', borderRadius: '0px' }}>
                            <Table className={classes.table} stickyHeader>
                                <TableHead>
                                    <TableRow hover>
                                        <TableCell align="left" style={{ fontSize: 12, fontWeight: 600, padding: '10px 5px' }}>
                                            Labour Lines
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredTableData.length > 0 ? (
                                        filteredTableData.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                hover
                                                selected={selectedRows.some((r) => r.lineItemID === row.lineItemID)}
                                                onClick={handleRowClick(row)}
                                                style={{
                                                    backgroundColor: selectedRows.some((r) => r.lineItemID === row.lineItemID) ? 'orange' : ''
                                                }}
                                            >
                                                <TableCell className={row?.capClean !== 0 ? '' : classes.textError}>
                                                    <span style={{ color: 'red' }}>Description:</span> {row.lineItemDescription}
                                                    <span style={{ color: 'red' }}> Rate:</span> {formatters.CurrencyNumberWithPound(row.lineItemHourRate)}
                                                    <span style={{ color: 'red' }}> Hours:</span> {row.lineItemHours}
                                                    <span style={{ color: 'red' }}> Parts Total:</span> {formatters.CurrencyNumberWithPound(row.partsTotal)}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={1}>
                                                <Typography className={classes.noDataText} variant="body2">
                                                    No Data Found
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default LabourLineTableComp;
