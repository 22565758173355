import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputText, InputPassword, SecondaryButton, MandatoryField, SimpleField } from '../../../Core/Controls';
import { UserProvider as StateProvider, UserScreenContext } from './userProvider';
import DropdownModal from './dropdown';
import DialogComp from '../../../Core/Dialog/DialogComp';
import { FormHelperText, InputLabel } from '@material-ui/core';
import './user.scss';
import '../../commonStyle.scss';

const AddUserModal = (props) => {
    const {
        state,
        updateState,
        masterData,
        selectedData,
        ModuleChange,
        BranchChange,
        onSelectCheckbox,
        handleClose,
        DepotChange,
        handleClick,
        Updateuser,
        handleSubmit,
        CustChange,
        ServiceChange,
        btnLoader
    } = useContext(UserScreenContext);

    const fieldChange = (e) => {
        const { name, value } = e.target;
        if (state.userID && name === 'email') {
            updateState({ [name]: value, isUpdateEmail: true });
        } else {
            updateState({ [name]: value });
        }
    };

    const title = state.userID ? 'Update User' : 'Add User';

    return (
        <DialogComp title={title} onClose={() => handleClose(false)} height="80vh" overflow="auto" maxWidth="md">
            <Grid container spacing={1} className="add-user-screen  screen">
                <Grid item xs={12} sm={6} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="User Name"
                        className="InputText"
                        autoFocus={true}
                        name="name"
                        placeholder="User Name"
                        onChange={fieldChange}
                        value={state.name}
                        errors={state.errors['name']}
                    />
                </Grid>
                <Grid item xs={12} sm={6} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="User Initials"
                        placeholder="User Initials"
                        name="userInitials"
                        inputProps={{ maxLength: 4 }}
                        className="InputText"
                        autoFocus={false}
                        onChange={fieldChange}
                        value={state.userInitials}
                        errors={state.errors['userInitials']}
                    />
                </Grid>
                <Grid item xs={12} sm={6} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Email"
                        placeholder="Email"
                        fullWidth
                        name="email"
                        type="email"
                        onChange={fieldChange}
                        value={state.email}
                        errors={state.errors['email']}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SimpleField
                        inputLabel="Phone Number"
                        placeholder="Phone Number"
                        name="phone"
                        fullWidth
                        onChange={fieldChange}
                        value={state.phone}
                    />
                </Grid>
                {!state.userID || state.isUpdateEmail ? (
                    <>
                        <Grid item xs={12} sm={6} className="mandatory-fields">
                            <InputLabel required shrink>
                                Password
                            </InputLabel>
                            <InputPassword fullWidth autoComplete="new-password" placeholder="Password" name="password" onChange={fieldChange} />
                            <FormHelperText error>{state.errors['password']}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={6} className="mandatory-fields">
                            <InputLabel required shrink>
                                Confirm Password
                            </InputLabel>
                            <InputPassword fullWidth autoComplete="new-password" placeholder="Confirm Password" name="confirm" onChange={fieldChange} />
                            <FormHelperText error>{state.errors['confirm']}</FormHelperText>
                        </Grid>
                    </>
                ) : null}
                <Grid item xs={12} sm={12}>
                    <DropdownModal
                        state={state}
                        fieldChange={fieldChange}
                        masterData={masterData}
                        DepotChange={DepotChange}
                        selectedData={selectedData}
                        ModuleChange={ModuleChange}
                        BranchChange={BranchChange}
                        ServiceChange={ServiceChange}
                        onSelectCheckbox={onSelectCheckbox}
                        handleClick={handleClick}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    {!state.userID ? (
                        <SecondaryButton isBtnLoader={btnLoader} onClick={handleSubmit} className="submit_btn">
                            Submit
                        </SecondaryButton>
                    ) : (
                        <SecondaryButton isBtnLoader={btnLoader} onClick={Updateuser} className="submit_btn">
                            Update
                        </SecondaryButton>
                    )}
                </Grid>
            </Grid>
        </DialogComp>
    );
};
const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <AddUserModal />
        </StateProvider>
    );
};
export default Screen;
