import React, { useEffect, useMemo, useState } from 'react';
import { EditButton, DataGrid, formatters } from '../../../../../Core/Controls';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
import AddRecall from './addRecallScreen';
import RecallDashboard from './recallDashDetail';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import { AppStorage } from '../../../../../Core/Basic/storage-service';

const basecolumns = [
    { key: 'action', name: 'Action', width: 80 },
    { key: 'recallDate', name: 'Recall Date', formatter: formatters.Date_DDMMYYYY, width: 150 },
    { key: 'recallNumber', name: 'Recall Number', width: 100 },
    { key: 'recallExpiryDate', name: 'Expiry Date', formatter: formatters.Date_DDMMYYYY, width: 150 },
    {
        key: 'recallDescription',
        name: 'Description'
    },
    { key: 'recallStatus', name: 'Status', width: 120 }
];

const VehicleRecalls = (props) => {
    const [state, setState] = useState({
        showRecall: false
    });

    useEffect(async () => {
        // pullChecksData();
    }, []);
    let checkhaveAccess = AppStorage.getAllowAcces();

    const pullChecksData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const actionButton = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton
                    onClick={() =>
                        setState((st) => ({
                            ...st,
                            reCallId: row.recallID,
                            showDetails: true
                        }))
                    }
                    toolTipTitle="Edit Recall"
                />
            </div>
        );
    };

    const onClose = (fromScreen, recallID) => {
        setState((st) => ({
            ...st,
            showRecall: false,
            showDetails: fromScreen === 'add' ? true : false,
            reCallId: recallID
        }));
        pullChecksData();
    };

    const handleAdd = () => {
        setState((st) => ({
            ...st,
            showRecall: true
        }));
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = actionButton;
        }
        return tempCols;
    }, []);

    let loadUrl = `recalls?VehicleID=${props.VehicleID}`;
    return (
        <div>
            {state.showRecall ? (
                <DialogComp title={`Add Recall (${props.vehicleDesc})`} onClose={onClose} maxWidth="md">
                    <AddRecall onClose={onClose} VehicleID={props.VehicleID} />
                </DialogComp>
            ) : null}

            {state.showDetails ? (
                // <DialogComp title="Add Recall" onClose={onClose} maxWidth="xl" fullWidth>
                <RecallDashboard onClose={onClose} reCallId={state.reCallId} vehicleDesc={props.vehicleDesc} />
            ) : (
                // </DialogComp>
                <GijgoGrid
                    columns={columns}
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    btnTitle={`${checkhaveAccess?.CanUpdateVehicleRecalls ? 'Add Recall' : ''}`}
                    onBtnClick={handleAdd}
                    isReload={state.isReload}
                    getUrl={loadUrl}
                />
            )}
        </div>
    );
};

export default VehicleRecalls;
