import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getAllVor = async (flag) => {
    let res = await getWithAuth(`VORVehicles?active=${flag}`);
    return res;
};

export const getDropDownForVor = async (data) => {
    let res = await getWithAuth(`VORVehicles/screenDropdowns?VehicleID=${data}`);
    return res;
};

export const getVorByVehicle = async (id, flag) => {
    let res = await getWithAuth(`VORVehicles?VehicleID=${id}`);
    return res;
};

export const getVorByID = async (id) => {
    let res = await getWithAuth(`VORVehicles/${id}`);
    return res;
};

export const getVorNotesById = async (id) => {
    let res = await getWithAuth(`VORVehicles/VORLogByVORVehicleID/${id}`);
    return res;
};

export const addVor = async (data) => {
    let res = await postWithAuth(`VORVehicles`, data);
    return res;
};
