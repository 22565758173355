import { postWithAuth, getWithAuth,putWithAuth } from '../../Basic/HttpService';

export const getAllDriverType = async () => {
    let res = await getWithAuth(`DriverTypes`);
    return res;
};

export const postDriverType = async (data) => {
    let res = await postWithAuth(`DriverTypes`, data);
    return res;
};
export const putDriverType = async (data) => {
    let res = await putWithAuth(`DriverTypes/${data.driverTypeID}`, data);
    return res;
};
