import { postWithAuth, getWithAuth,putWithAuth } from '../../Basic/HttpService';

export const getAllEventDocs = async () => {
    let res = await getWithAuth(`Eventdocs`);
    return res;
};


export const postEventDocs = async (data) => {
    let res = await postWithAuth(`Eventdocs`, data);
    return res;
};
