import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getAllServiceAgents = async () => {
    let res = await getWithAuth(`ServiceAgents`);
    return res;
};

export const getServiceAgentById = async (id) => {
    let res = await getWithAuth(`ServiceAgents/${id}`);
    return res;
};

export const getServiceAgentsDropDown = async () => {
    let res = await getWithAuth(`ServiceAgents/screenDropdowns`);
    return res;
};

export const postServiceAgentData = async (data) => {
    let res = await postWithAuth(`ServiceAgents`, data);
    return res;
};

export const getServiceAgentFacilities = async () => {
    let res = await getWithAuth(`ServiceAgentFacilities`);
    return res;
};

export const postServiceAgentRating = async (data) => {
    let res = await postWithAuth(`ServiceAgents/UpdateRating`, data);
    return res;
};

export const getMapNewData = async () => {
    let res = await getWithAuth(`ServiceAgents/GenerateJsonfile`);
    return res;
};
