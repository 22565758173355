import { FormHelperText, Grid, InputLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { InputText, SecondaryButton, MandatoryField } from "../../../../Core/Controls";
import "../../../commonStyle.scss";

const BookingStatusDialog = (props) => {
  const [state, setState] = useState({ id: null, value: "", isError: false });
  const { collectionAndDeliverID, collectionAndDeliverDescription } = props.data.selectedRowData;
  useEffect(() => {
    if (!props.data.isAdd) {
      setState((st) => ({
        ...st,
        id: collectionAndDeliverID,
        value: collectionAndDeliverDescription,
      }));
    }
  }, []);
  const validate = () => {
    if (/[^\s]/.test(state.value)) {
      return true;
    }
    return false;
  };
  const handleData = () => {
    if (validate()) {
      props.saveData(state);
      props.onClose();
    } else {
      setState((st) => ({
        ...st,
        isError: true,
      }));
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="mandatory-fields">
          <MandatoryField
            inputLabel="Description"
            placeholder="Description"
            defaultValue={collectionAndDeliverDescription ?? ""}
            onChange={(event) => {
              setState((st) => ({
                ...st,
                value: event.target.value,
                isError: false,
              }));
            }}
            errors={state.isError ? "This field is required" : null}
          />
        </Grid>
        <Grid item xs={12} className="submit_btn">
          <SecondaryButton onClick={handleData}>{props.data.isAdd ? "ADD" : "UPDATE"}</SecondaryButton>
        </Grid>
      </Grid>
    </>
  );
};
export default BookingStatusDialog;
