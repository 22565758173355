import { createContext, useCallback, useEffect, useState } from 'react';
import { useToast } from '../../../Core/Hooks/useToast';
import { useHistory } from 'react-router-dom';
import { getWidgetsInfo, postTheme } from '../../../Core/Services/dasboardWidgetsService';

export const DashboardContext = createContext();

export const DashWidgetsProvider = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    let history = useHistory();

    const [hover, setHover] = useState({
        backgroundColorHover: '',
        id: null
    });

    const [state, setState] = useState({
        displayStyles: [],
        themes: []
    });

    const [open, setOpen] = useState(false);

    let onChange = (val) => {
        history.push(val);
    };

    const handleHover = (val, id, themeID) => () => {
        setHover((st) => {
            let newSt = { ...st };
            if (val && themeID === 2) {
                newSt.backgroundColorHover = '#ededed';
                newSt.id = id;
                newSt.color = 'black';
            } else if (val) {
                newSt.backgroundColorHover = 'rgab(0,0,0,0.9)';
                newSt.id = id;
                newSt.color = 'black';
            } else {
                newSt.backgroundColorHover = '';
                newSt.id = null;
                newSt.color = '';
            }

            return newSt;
        });
    };

    useEffect(async () => {
        pullSettingData();
    }, [open, props.widgetID, props.userWidgetID]);

    const pullSettingData = async () => {
        if (props.widgetID) {
            let res = await getWidgetsInfo(props.widgetID, props.userWidgetID);
            let data = res?.data?.list[0];
            setState((st) => ({
                ...st,
                displayStyles: res?.data.displayStyles,
                themes: res?.data.themes,
                userWidgetID: data.userWidgetID,
                widgetUsedID: data.widgetUsedID,
                displayStyleID: data.displayStyleID,
                themeID: data.themeID
            }));
        }
    };
    const handleBtn = (val) => {
        setState((st) => ({ ...st, displayStyleID: val }));
    };

    const handleBackgroundColor = (id) => () => {
        setState((st) => {
            let newSt = { ...st };
            newSt.themeID = id;
            return newSt;
        });
    };

    const handleTheme = (val) => () => {
        setOpen(!open);
    };

    const handleSubmit = async () => {
        let data = {
            userWidgetID: state.userWidgetID || null,
            userWidgetWidgetUsedID: state.widgetUsedID || null,
            userWidgetDisplayStyleID: state.displayStyleID || null,
            userWidgetThemeID: state.themeID || null
        };

        let res = await postTheme(data);
        if (res.success) {
            // showToastSuccess(res.data.message);
            props.pullDashboardData();
            props.onClose();
        } else {
            showToastError('error');
        }
    };

    return (
        <DashboardContext.Provider value={{ open, hover, handleHover, handleBtn, onChange, handleTheme, state, handleBackgroundColor, handleSubmit }}>
            {props.children}
        </DashboardContext.Provider>
    );
};
