import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getAllVorTypeDetail = async () => {
    let res = await getWithAuth(`vortypedetails`);
    return res;
};
export const getAllVorTypeList = async () => {
    let res = await getWithAuth(`vortypes`);
    return res;
};

export const postVorTypeDetail = async (data) => {
    let res = await postWithAuth(`vortypedetails`, data);
    return res;
};
