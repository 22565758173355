import { postWithAuth, getWithAuth } from "../../../Basic/HttpService";

export const getAllRetorqueTypes = async () => {
  let res = await getWithAuth(`Retorquereasons`);
  return res;
};

export const getRetorqueById = async (id) => {
  let res = await getWithAuth(`Retorquereasons/${id}`);
  return res;
};

export const postRetorqueData = async (data) => {
  let res = await postWithAuth(`Retorquereasons`, data);
  return res;
};
