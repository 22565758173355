import { DialogTitle, Divider, FormHelperText, Grid, IconButton, InputLabel, Typography } from '@material-ui/core';
import {
    CustomChip,
    DateTimePicker,
    DialogWithoutStaticHeader,
    InputText,
    SecondaryButton,
    SecondaryCheckbox,
    SimpleField
} from '../../../../../../Core/Controls';
import CloseIcon from '@material-ui/icons/Close';
import { useCallback, useState } from 'react';
import { addUpdateJobUpdateNote, getJobUpdateNotesbyId, getAllJobUpdateNotesList } from '../../../../../../Core/Services/InternalUser/JOB/jobQueryService';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import { useEffect } from 'react';
import ConfirmationModal from '../../../../../../Core/Modal/ConfirmationModal';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import moment from 'moment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { JobSingleNote } from './jobUpdateNotes';
import './../../jobReport.scss';
const FlaggedBox = (props) => {
    return (
        <>
            <InputLabel shrink>{props.label}</InputLabel>
            <Grid container alignItems="flex-end">
                <Grid item xs={1}>
                    <SecondaryCheckbox checked={props.chkValue} onChange={props.onCheckChange} name={props.chkName} disabled={props.disabled} />
                </Grid>
                <Grid item xs={11}>
                    <SimpleField
                        placeholder={props.label}
                        value={props.txtValue}
                        onChange={props.inputChange}
                        name={props.txtName}
                        disabled={props.disabled || !props.chkValue}
                        type={props.fieldType || 'text'}
                    />
                </Grid>
            </Grid>
        </>
    );
};
const AddEditJobsNotes = (props) => {
    const { showToastError, showToastSuccess } = useToast();

    const [btnLoader, setBtnLoader] = useState({ newBtnLoader: false, extBtnLoader: false });
    const [state, setState] = useState({
        errors: {},
        newErrors: {},
        jobUpdateID: props.id || null,
        jobUpdateJobID: props.jobID,
        jobUpdateReminderDate: '',
        jobUpdateNotes: '',
        jobUpdateNotesResult: '',
        jobUpdateDateCreated: '',
        jobUpdateDateClosed: moment().format('YYYY-MM-DDTHH:mm'),
        jobUpdateNotesActive: false,
        newjobUpdateReminderDate: '',
        newJobUpdateNotes: '',
        newNoteSendSMS: false,
        newNoteSendSMSReported: '',
        NoteList: []
    });
    const pullNoteAndUpdateState = async () => {
        let res = await getAllJobUpdateNotesList(props.jobID);
        if (res.success) {
            let arr = res.data.notelist;
            const objWithIdIndex = arr.findIndex((obj) => obj.id === props.id);
            if (objWithIdIndex > -1) {
                arr.splice(objWithIdIndex, 1);
            }
            setState((st) => ({ ...st, NoteList: arr }));
        }
        if (!res.success) {
            showToastError(res.message);
        }
    };
    const getAllDefectNotes = () => {
        pullNoteAndUpdateState();
        setState((st) => ({
            ...st,
            showAllNotes: true
        }));
    };
    const RenderTitle = () => {
        return (
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={6} container justify="space-between">
                    <Grid item>
                        <DialogTitle style={{ padding: 0 }}>
                            <span>Job Notes</span>
                            <span style={{ color: 'red' }}>
                                &nbsp; {props.orderNo} &nbsp; {props.regNo}
                            </span>
                            {props.showHistory ? (
                                <CustomChip style={{ marginLeft: 8 }} icon={AssignmentIcon} label="History" onClick={getAllDefectNotes} />
                            ) : null}
                        </DialogTitle>
                    </Grid>
                </Grid>
                <Grid item xs={6} container justify="space-between">
                    <Grid item>
                        <DialogTitle style={{ padding: 0 }}>Next Progress Reminder</DialogTitle>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={props.onClose} style={{ marginRight: 7, padding: 0 }}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    useEffect(async () => {
        if (props.id) {
            let res = await getJobUpdateNotesbyId(props.id);
            let data = res.data?.details[0];
            setState((st) => ({
                ...st,
                jobUpdateID: data.jobUpdateID,
                jobUpdateJobID: data.jobUpdateJobID,
                jobUpdateReminderDate: data.jobUpdateReminderDate,
                jobUpdateNotes: data.jobUpdateNotes,
                jobUpdateNotesResult: data.jobUpdateNotesResult,
                jobUpdateDateCreated: data.jobUpdateDateCreated,
                jobUpdateDateClosed: data.jobUpdateDateClosed || moment().format('YYYY-MM-DDTHH:mm'),
                jobUpdateNotesActive: data.jobUpdateNotesActive
            }));
        }
    }, []);
    const inputChange = useCallback((e) => {
        const { name, value, type, checked } = e.target;
        setState((st) => ({ ...st, [name]: type === 'checkbox' ? checked : value }));
    }, []);
    const closeConfirmationModal = (res) => {
        setState((st) => ({
            ...st,
            showConfirm: false
        }));
    };
    const confirmationModalHandler = (res) => {
        // if (validations()) {
        setState((st) => ({
            ...st,
            showConfirm: true
        }));
        // }
    };
    const validations = () => {
        const { jobUpdateNotes, jobUpdateReminderDate, jobUpdateDateClosed } = state;
        let formIsValid = true;
        let errors = {};
        if (!/[^\s]/.test(jobUpdateReminderDate)) {
            errors.jobUpdateReminderDate = 'The progress result field is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(jobUpdateNotes)) {
            errors.jobUpdateNotes = 'The note title field is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(jobUpdateDateClosed)) {
            errors.jobUpdateDateClosed = 'This field is required';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };
    const newValidations = () => {
        const { newJobUpdateNotes, newjobUpdateReminderDate, newNoteSendSMS, newNoteSendSMSReported } = state;
        let formIsValid = true;
        let errors = {};

        if (!/[^\s]/.test(newJobUpdateNotes)) {
            errors.newJobUpdateNotes = 'The note result is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(newjobUpdateReminderDate)) {
            errors.newjobUpdateReminderDate = 'The reminder start date field is required';
            formIsValid = false;
        }
        if (newNoteSendSMS) {
            if (!/[^\s]/.test(newNoteSendSMSReported)) {
                errors.newNoteSendSMSReported = 'The number field is required';
                formIsValid = false;
            }
        }
        if (!validations()) {
            formIsValid = false;
        }
        setState((st) => ({ ...st, newErrors: errors }));
        return formIsValid;
    };
    const handleSubmit = async (val) => {
        let formData;
        formData = {
            jobUpdateNotes: state.jobUpdateNotes,
            jobUpdateReminderDate: state.jobUpdateReminderDate,
            jobUpdateNotesResult: state.jobUpdateNotesResult,
            jobUpdateID: state.jobUpdateID,
            jobUpdateDateClosed: state.jobUpdateDateClosed,
            jobUpdateNotesActive: val,
            jobUpdateJobID: props.jobID
        };

        setBtnLoader((st) => ({ ...st, extBtnLoader: true }));
        let res = await addUpdateJobUpdateNote(formData);
        if (res.success) {
            showToastSuccess('Notes Added Successfully!');
            props.onFormSubmit(true);
        } else {
            showToastError(res.message);
        }
        setBtnLoader((st) => ({ ...st, extBtnLoader: false }));
    };
    const handleNewNoteSubmit = async () => {
        if (newValidations()) {
            handleSubmit(false);
            let formData;
            formData = {
                jobUpdateNotes: state.newJobUpdateNotes,
                jobUpdateReminderDate: state.newjobUpdateReminderDate,
                jobUpdateNotesResult: '',
                noteSendSMSNumber: state.newNoteSendSMSReported,
                noteSendSMS: state.newNoteSendSMS,
                jobUpdateID: null,
                jobUpdateJobID: props.jobID
            };
            setBtnLoader((st) => ({ ...st, newBtnLoader: true }));
            let res = await addUpdateJobUpdateNote(formData);
            if (res.success) {
                showToastSuccess('Notes Added Successfully!');
                props.onFormSubmit(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader((st) => ({ ...st, newBtnLoader: false }));
        }
    };
    const resetInput = (name) => {
        setState((st) => ({ ...st, [name]: '' }));
    };
    return (
        <DialogWithoutStaticHeader title={RenderTitle()} onClose={props.onClose} aria-labelledby="simple-dialog-title" fullWidth maxWidth="lg">
            <Grid container spacing={2} alignItems="flex-start" style={{ overflow: 'hidden', height: 'none' }}>
                <Grid item container xs={12} sm={12} md={6} spacing={2}>
                    <Grid item xs={12} sm={12} className="mandatory-fields">
                        <InputLabel shrink>{props.NoteID ? 'Existing' : ''} Reminder Notes</InputLabel>
                        <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                            {state.jobUpdateNotes}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} className="mandatory-fields">
                        <InputLabel shrink>Progress Result</InputLabel>
                        <InputText
                            multiline
                            rows={3}
                            placeholder="Progress Result"
                            value={state.jobUpdateNotesResult}
                            onChange={inputChange}
                            name="jobUpdateNotesResult"
                            autoFocus={true}
                        />
                        <FormHelperText error>{state.errors.jobUpdateNotesResult}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel shrink>Reminder Start Date & Time</InputLabel>
                        <DateTimePicker
                            placeholder="Start Date & Time"
                            name="jobUpdateReminderDate"
                            value={state.jobUpdateReminderDate}
                            onChange={inputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} className="mandatory-fields">
                        <InputLabel shrink>Reminder End Date & Time</InputLabel>
                        <DateTimePicker
                            placeholder="Reminder End Date & Time"
                            value={state.jobUpdateDateClosed}
                            onChange={inputChange}
                            name="jobUpdateDateClosed"
                        />
                        <FormHelperText error>{state.errors.jobUpdateDateClosed}</FormHelperText>
                    </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem style={{ marginRight: '-1px' }} />
                <Grid item container xs={12} sm={12} md={6} spacing={2}>
                    <Grid item xs={12} sm={12} className="mandatory-fields">
                        <InputLabel shrink>New Reminder Notes</InputLabel>
                        <InputText multiline rows={3} placeholder="New Reminder Notes" name="newJobUpdateNotes" onChange={inputChange} />
                        <FormHelperText error>{state.newErrors.newJobUpdateNotes}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={12} className="mandatory-fields">
                        <InputLabel shrink>Reminder Start Date & Time</InputLabel>
                        <DateTimePicker
                            placeholder="Reminder Start Date & Time"
                            value={state.newjobUpdateReminderDate}
                            onChange={inputChange}
                            name="newjobUpdateReminderDate"
                        />
                        <FormHelperText error>{state.newErrors.newjobUpdateReminderDate}</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <FlaggedBox
                            label={`Send text `}
                            chkName="newNoteSendSMS"
                            chkValue={state.newNoteSendSMS}
                            onCheckChange={inputChange}
                            txtName="newNoteSendSMSReported"
                            txtValue={state.newNoteSendSMSReported}
                            inputChange={inputChange}
                            resetInput={resetInput}
                            disabled={state.isDisable}
                            // fieldType="number"
                        />
                        <FormHelperText error>{state.newErrors.newNoteSendSMSReported}</FormHelperText>
                    </Grid>
                </Grid>
                <Grid item container xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <SecondaryButton isBtnLoader={btnLoader.extBtnLoader} onClick={confirmationModalHandler} fullWidth>
                            Close Reminder
                        </SecondaryButton>
                    </Grid>
                </Grid>
                <Grid item container xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <SecondaryButton isBtnLoader={btnLoader.newBtnLoader} disabled={!props.id} onClick={() => handleNewNoteSubmit(true)} fullWidth>
                            close & Create New Reminder
                        </SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>
            {state.showConfirm ? (
                <DialogComp title={`Close Reminder`} onClose={() => closeConfirmationModal(false)} maxWidth="sm">
                    <ConfirmationModal
                        message={`Are you sure to close remind and not create a new reminder?`}
                        handleSubmit={() => handleSubmit(false)}
                        handleCancel={() => closeConfirmationModal(false)}
                    />
                </DialogComp>
            ) : null}
            {state.showAllNotes ? (
                <DialogComp
                    open={true}
                    onClose={() =>
                        setState((st) => ({
                            ...st,
                            showAllNotes: false
                        }))
                    }
                    title={'All Notes'}
                    maxWidth="lg"
                >
                    {state.NoteList.length > 0 ? (
                        <div className="job_update_note">
                            {state.NoteList?.map((n, i) => (
                                <Grid container spacing={2}>
                                    <Grid xs={12}>
                                        <JobSingleNote key={i} note={n} />
                                    </Grid>
                                </Grid>
                            ))}
                        </div>
                    ) : (
                        <div className="job_update_note" style={{ textAlign: 'center' }}>
                            No Records Found
                        </div>
                    )}
                </DialogComp>
            ) : null}
        </DialogWithoutStaticHeader>
    );
};
export default AddEditJobsNotes;
