import { createContext, useCallback, useEffect, useState } from 'react';
import { useToast } from '../../../Core/Hooks/useToast';
import { getCompanyDetails, postCompanyDetails } from '../../../Core/Services/InternalUser/companyService';

export const CompanyScreenContext = createContext();

const countryList = [
    { id: 'GB', name: 'GB' },
    { id: 'IE', name: 'IE' }
];

export const CompanyProvider = (props) => {
    const { showToastSuccess, showToastError } = useToast();

    const [state, setState] = useState({
        errors: {},
        companyID: null,
        companyName: '',
        companyRegistrationNumber: '',
        companyVATNumber: '',
        companyLogo: '',
        companyEmail: '',
        companyPhone: '',
        companyWebsite: '',
        companyAddress1: '',
        companyAddress2: '',
        companyCity: '',
        companyCounty: '',
        companyPostCode: '',
        companyCountryCode: 'GB',
        companyRegisteredAddressSameAsCompanyAddress: false,
        companyRegisteredAddress1: '',
        companyRegisteredAddress2: '',
        companyRegisteredCity: '',
        companyRegisteredPostCode: '',
        companyRegisteredCounty: '',
        companyBankAccountName: '',
        companyRegisteredCountryCode: 'GB',
        companyBankSortCode: '',
        companyBankAccountNumber: '',
        companyBankName: ''
    });

    const [btnLoader, setBtnLoader] = useState(false);

    useEffect(async () => {
        pullCustomerdata();
    }, []);

    const pullCustomerdata = async () => {
        let res = await getCompanyDetails();

        if (res.data?.list) {
            const data = res.data?.list[0];
            setState((st) => ({
                ...st,
                companyID: data.companyID,
                companyName: data.companyName,
                companyRegistrationNumber: data.companyRegistrationNumber,
                companyVATNumber: data.companyVATNumber,
                companyLogo: data.companyLogo,
                companyEmail: data.companyEmail,
                companyPhone: data.companyPhone,
                companyWebsite: data.companyWebsite,
                companyAddress1: data.companyAddress1,
                companyAddress2: data.companyAddress2,
                companyCity: data.companyCity,
                companyCounty: data.companyCounty,
                companyPostCode: data.companyPostCode,
                companyCountryCode: data.companyCountryCode,
                companyRegisteredAddressSameAsCompanyAddress: data.companyRegisteredAddressSameAsCompanyAddress,
                companyRegisteredAddress1: data.companyRegisteredAddress1,
                companyRegisteredAddress2: data.companyRegisteredAddress2,
                companyRegisteredCity: data.companyRegisteredCity,
                companyRegisteredPostCode: data.companyRegisteredPostCode,
                companyRegisteredCounty: data.companyRegisteredCounty,
                companyBankAccountName: data.companyBankAccountName,
                companyRegisteredCountryCode: data.companyRegisteredCountryCode,
                companyBankSortCode: data.companyBankSortCode,
                companyBankAccountNumber: data.companyBankAccountNumber,
                companyBankName: data.companyBankName
            }));
        }
    };

    const setVal = (s) => {
        setState((st) => ({
            ...st,
            companyAddress1: s.addressLine1,
            companyAddress2: s.addressLine2,
            companyCity: s.town,
            companyCounty: s.county,
            companyPostCode: s.postCode
        }));
    };
    const setValRegistered = (s) => {
        setState((st) => ({
            ...st,
            companyRegisteredAddress1: s.addressLine1,
            companyRegisteredAddress2: s.addressLine2,
            companyRegisteredCity: s.town,
            companyRegisteredCounty: s.county,
            companyRegisteredPostCode: s.postCode
        }));
    };
    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;
        let newSt = {};
        if (value === 'true' && type.toLowerCase() === 'radio') {
            newSt[name] = true;
            newSt.companyRegisteredAddress1 = '';
            newSt.companyRegisteredAddress2 = '';
            newSt.companyRegisteredCity = '';
            newSt.companyRegisteredCounty = '';
            newSt.companyRegisteredPostCode = '';
            newSt.companyRegisteredCountryCode = '';
        } else if (value === 'false' && type.toLowerCase() === 'radio') {
            newSt[name] = false;
        } else {
            newSt[name] = value;
        }
        setState((st) => ({
            ...st,
            ...newSt
        }));
    }, []);

    const clearImage = () => {
        if (state.companyLogo) {
            setState((st) => ({ ...st, companyLogo: null }));
        }
    };

    let imagehandler = (event) => {
        let reader = new FileReader();
        reader.onload = (e) => {
            const { result } = e.target;
            setState((st) => ({ ...st, companyLogo: result }));
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    const validations = () => {
        const { companyName, companyEmail, companyRegistrationNumber, companyPhone, companyWebsite } = state;
        let formIsValid = true;
        let errors = {};
        let urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
        if (!/[^\s]/.test(companyName)) {
            errors.companyName = 'Company Name is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(companyEmail)) {
            errors.companyEmail = 'Customer Email is required';
            formIsValid = false;
        }
        let phoneReg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        if (companyPhone && !phoneReg.test(companyPhone)) {
            errors.companyPhone = 'Invalid Phone Number';
            formIsValid = false;
        }
        if (!/[^\s]/.test(companyRegistrationNumber)) {
            errors.companyRegistrationNumber = 'Registration Number is required';
            formIsValid = false;
        }
        if (companyWebsite && !urlRegex.test(companyWebsite)) {
            errors.companyWebsite = 'Invalid Url';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            setBtnLoader(true);
            let data = {
                companyID: state.companyID,
                companyName: state.companyName,
                companyRegistrationNumber: state.companyRegistrationNumber,
                companyVATNumber: state.companyVATNumber,
                companyLogo: state.companyLogo,
                companyEmail: state.companyEmail,
                companyPhone: state.companyPhone,
                companyWebsite: state.companyWebsite,
                companyAddress1: state.companyAddress1,
                companyAddress2: state.companyAddress2,
                companyCity: state.companyCity,
                companyCounty: state.companyCounty,
                companyPostCode: state.companyPostCode,
                companyCountryCode: state.companyCountryCode,
                companyRegisteredAddressSameAsCompanyAddress: state.companyRegisteredAddressSameAsCompanyAddress,
                companyRegisteredAddress1: state.companyRegisteredAddress1,
                companyRegisteredAddress2: state.companyRegisteredAddress2,
                companyRegisteredCity: state.companyRegisteredCity,
                companyRegisteredPostCode: state.companyRegisteredPostCode,
                companyRegisteredCounty: state.companyRegisteredCounty,
                companyBankAccountName: state.companyBankAccountName,
                companyRegisteredCountryCode: state.companyRegisteredCountryCode,
                companyBankSortCode: state.companyBankSortCode,
                companyBankAccountNumber: state.companyBankAccountNumber,
                companyBankName: state.companyBankName
            };
            let res = await postCompanyDetails(data);
            if (res.success) {
                showToastSuccess('Data saved successfully');
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    return (
        <CompanyScreenContext.Provider
            value={{
                state,
                btnLoader,
                handleSubmit,
                inputChange,
                imagehandler,
                clearImage,
                setVal,
                setValRegistered,
                countryList
            }}
        >
            {props.children}
        </CompanyScreenContext.Provider>
    );
};
