import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getDriverVehicleCheck = async (id) => {
    let res = await getWithAuth(`DriverDailyCheck/List?VehicleID=${id}`);
    return res;
};

export const getvehiclecheckchecksById = async (id) => {
    let res = await getWithAuth(`DriverDailyCheck/AddScreenDetails/${id}`);
    return res;
};

export const getEditDriverChekssById = async (id) => {
    let res = await getWithAuth(`DriverDailyCheck/EditScreenDetails/${id}`);
    return res;
};

export const postvehiclecheckchecksData = async (data) => {
    let res = await postWithAuth(`DriverDailyCheck`, data);
    return res;
};
export const PostImage = async (data) => {
    let res = await postWithAuth(`VehicleDailyCheckPhoto`, data);
    return res;
};
export const getImageByID = async (id) => {
    let res = await getWithAuth(`VehicleDailyCheckPhoto/${id}`);
    return res;
};

export const DeleteImageByID = async (id) => {
    let res = await postWithAuth(`VehicleInspectionsPhoto/Delete/${id}`);
};
