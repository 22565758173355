import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { getAllAdminDash } from '../../../Core/Services/Admin/AdminDashboardService';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import EventRoundedIcon from '@material-ui/icons/EventRounded';
import HealingRoundedIcon from '@material-ui/icons/HealingRounded';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import BlurOnRoundedIcon from '@material-ui/icons/BlurOnRounded';
import GavelRoundedIcon from '@material-ui/icons/GavelRounded';
import LinearScaleRoundedIcon from '@material-ui/icons/LinearScaleRounded';
import { Link } from 'react-router-dom';
import '../AdminDash.scss';

const AdminDashboard = () => {
    const [state, setState] = useState({
        rows_new: [],
        tilesData: []
    });

    useEffect(async () => {
        let res = await getAllAdminDash();
        const cards = res.data.dashboard;
        setState((st) => ({
            ...st,
            tilesData: cards[0]
        }));
    }, []);

    return (
        <div className="admin-dashboard">
            <Container className="cardGrid" maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/users/active`}>
                            <GroupRoundedIcon className="icon_Style" />
                            <Card className="card_Style">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">{state.tilesData.activeUsers || 0}</Typography>
                                    <Typography component="h1">Active Users</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/vehicle/eventdocs`}>
                            <EventRoundedIcon className="red_iconStyle" />
                            <Card className="red_cardStyle">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">{state.tilesData.eventDocuments || 0}</Typography>
                                    <Typography component="h1">Event Documents</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/vehicle/VehicleCheckChecks`}>
                            <HealingRoundedIcon className="red_iconStyle" />
                            <Card className="red_cardStyle">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">{state.tilesData.inspectionSheetChecks || 0}</Typography>
                                    <Typography component="h1">Inspection Sheet Checks</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/vehicle/checksection`}>
                            <NotificationsActiveRoundedIcon className="red_iconStyle" />
                            <Card className="red_cardStyle">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">{state.tilesData.inspectionSheetSections || 0}</Typography>
                                    <Typography component="h1">Inspection Sheet Sections</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/maintenance/maintenanceprovider`}>
                            <GavelRoundedIcon className="yellow_iconStyle" />
                            <Card className="yellow_cardStyle">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">{state.tilesData.maintenanceProviders || 0}</Typography>
                                    <Typography component="h1">Maintenance Providers</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/vehicle/make`}>
                            <BuildRoundedIcon className="purple_iconStyle" />
                            <Card className="purple_cardStyle">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">{state.tilesData.makes || 0}</Typography>
                                    <Typography component="h1">Makes</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/vehicle/model`}>
                            <BlurOnRoundedIcon className="purple_iconStyle" />
                            <Card className="purple_cardStyle">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">{state.tilesData.models || 0}</Typography>
                                    <Typography component="h1">Models</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/users/:all`}>
                            <PeopleAltRoundedIcon className="icon_Style" />
                            <Card className="card_Style">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">{state.tilesData.totalUsers || 0}</Typography>
                                    <Typography component="h1">Total Users</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/vehicle/vehicletype`}>
                            <LinearScaleRoundedIcon className="yellow_iconStyle" />
                            <Card className="yellow_cardStyle">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">{state.tilesData.vehicleTypes || 0}</Typography>
                                    <Typography component="h1">Vehicle Types</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default AdminDashboard;

//to={`/serviceagent/defects`}
//style={{ backgroundColor: "green" }}
