import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormHelperText } from '@material-ui/core';
import { InputText, SecondaryButton, MandatoryField } from '../../../../Core/Controls';
import { getSlippageById, postSlippageData } from '../../../../Core/Services/InternalUser/slippageService';
import { useToast } from '../../../.././Core/Hooks/useToast';
import '../../.././commonStyle.scss';

function SlippageModal(props) {
    const [state, setState] = useState({
        slippageID: null,
        slippageReasonDescription: '',
        errors: {}
    });

    const { showToastSuccess, showToastError } = useToast();

    const [btnLoader, setBtnLoader] = useState(false);

    useEffect(async () => {
        if (props.slippageID) {
            let res = await getSlippageById(props.slippageID);
            if (res.success) {
                setState((state) => ({
                    ...state,
                    slippageID: res.data.slippageReasonID,
                    slippageReasonDescription: res.data.slippageReasonDescription
                }));
            }
        }
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };

    const validations = () => {
        const { slippageReasonDescription } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(slippageReasonDescription)) {
            errors.slippageReasonDescription = 'This field is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            setBtnLoader(true);
            const slippageData = {
                slippageReasonDescription: state.slippageReasonDescription,
                slippageReasonID: state.slippageID
            };
            let res = await postSlippageData(slippageData);
            if (res.success) {
                showToastSuccess(res.message);
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    return (
        <Grid container direction="row" spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Description"
                    name="slippageReasonDescription"
                    variant="outlined"
                    placeholder="Description"
                    onChange={handleInputChange}
                    value={state.slippageReasonDescription}
                    errors={state.errors.slippageReasonDescription}
                />
            </Grid>

            <Grid item xs={12} className="submit_btn">
                <SecondaryButton isBtnLoader={btnLoader} fullWidth onClick={submitHandler}>
                    {props.slippageID ? 'Update' : 'Add'}
                </SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default SlippageModal;
