import { postWithAuth, getWithAuth, putWithAuth } from '../../../Basic/HttpService';

export const getAllBookingSchedule = async (id) => {
    let res = await getWithAuth(`ServiceAgentSchedules?VehicleID=&Status=${id}`);
    return res;
};

export const postServiceAgentBookingRequest = async (data) => {
    let res = await postWithAuth(`ServiceAgentSchedules/SetScheduleBookingRequest`, data);
    return res;
};
// https://devapi.fleet247.co.uk/api/ServiceAgentSchedules?VehicleID=&Status=2
