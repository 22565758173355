import React, { useEffect, useState, useMemo } from 'react';
import { useToast } from '../../../Core/Hooks/useToast';
import { AddButton, EditButton, DataGrid, BreadCrumbs, formatters } from '../../../Core/Controls';
import '../../commonStyle.scss';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { getPartRequests_GetNotes } from '../../../Core/Services/Admin/partService';
import DialogComp from '../../../Core/Dialog/DialogComp';
// import AddUpdatePart from '../PartAddUpdate';
import ReactDOM from 'react-dom';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';
import { useHistory } from 'react-router-dom';
import PartAddUpdate from './PartAddUpdate';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Parts', path: '/parts' },
    { name: 'Parts Received', path: '/', active: true }
];

const baseColumns = [
    { key: 'partNumber', name: 'Part Number', width: '10%' },
    { key: 'description', name: 'Description' },
    { key: 'qty', name: 'Quantity', width: '8%', align: 'right' },
    { key: 'warehouse', name: 'Warehouse', width: '8%' },
    { key: 'date', name: 'Date', width: '10%' },
    { key: 'received', name: 'Received', width: '8%' },
    { key: 'sa', name: 'Service Area', width: 110 },
    { key: 'eng', name: 'Engineer', width: 110 }
    // { key: 'userName', name: 'Engineer', headerRenderer: formatters.ColumnHeaderAlignleft, width: '9%' },
    // {
    //     key: 'type',
    //     name: 'Request Type',
    //     width: '10%'
    // },
    // { key: 'status', name: 'Status', width: '10%' }
];

const PartsReceived = (props) => {
    const history = useHistory();

    const isInternal = useLoginInfo().userRoles.includes('internal');
    const [state, setState] = useState({
        open: false,
        orderHistory: [],
        isReload: null
    });

    const PartsOrderHistory = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    useEffect(() => {
        PartsOrderHistory();
    }, []);

    const dataFormatter = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div
                title="Edit part"
                onClick={() => {
                    setState((st) => ({
                        ...st,
                        open: true,
                        title: `Part Details - ${row.partNumber}`,
                        partID: row.partID,
                        isAdd: false
                    }));
                }}
                style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}
            >
                {row.partNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'partNumber');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }

        return tempCols;
    }, []);

    const handleClose = (res) => {
        setState((st) => ({
            ...st,
            open: res ? true : false
        }));
        res && PartsOrderHistory();
    };
    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={'AdminParts/PartReceived_List'}
                isReload={state.isReload}
            />
            {state.open ? (
                <DialogComp open={state.open} onClose={() => handleClose(false)} title={state.title} maxWidth="md" fullWidth fullScreen overflow="auto">
                    <PartAddUpdate partID={state.partID} onClose={handleClose} screenID={'R'} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default PartsReceived;
