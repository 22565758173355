import { postWithAuth, getWithAuth } from '../../Basic/HttpService';

export const getAllVehicleDailyChecks = async () => {
    let res = await getWithAuth(`DailyCheckSections`);
    return res;
};

export const getVehicleDailyCheckById = async (id) => {
    let res = await getWithAuth(`DailyCheckSections/${id}`);
    return res;
};

export const postVehicleDailyChecks = async (data) => {
    let res = await postWithAuth(`DailyCheckSections`, data);
    return res;
};

export const getAllVehicleCheckSectionDropDown = async () => {
    let res = await getWithAuth(`VehicleCategories`);
    return res;
};
