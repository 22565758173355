import React, { useEffect, useMemo, useState } from 'react';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { InputLabel } from '@material-ui/core';

export default function SimpleRating(props) {
    const [state, setState] = React.useState({ ratingValue: 0, isDisabled: false });

    useEffect(async () => {
        setState((st) => ({
            ...st,
            ratingValue: props.ratingValue,
            isDisabled: props.isDisabled
        }));
    }, [props.ratingValue]);

    const returnValue = (event, newValue) => {
        setState(newValue);
        setState((st) => ({
            ...st,
            ratingValue: newValue
        }));
        props.onRatingHandle(event.target.name, newValue);
    };

    return (
        <Grid container alignItems="center">
            <Grid item>
                {props.title && <InputLabel shrink>{props.title}</InputLabel>}
                <Rating name={props.name} value={state.ratingValue} disabled={state.isDisabled} onChange={returnValue} />
            </Grid>
        </Grid>
    );
}
