import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from '../../../../Core/Controls/Grid/index';
import { AddButton, EditButton, BreadCrumbs } from '../../../../Core/Controls';
import DialogBox from './costCenterDialog';
import { useToast } from '../../../../Core/Hooks/useToast';
import DialogComp from '../../../../Core//Dialog/DialogComp';
import '../../../commonStyle.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import Grid from '@material-ui/core/Grid';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import { getAllCostCenter, postCostCenter } from '../../../../Core/Services/InternalUser/costCenterService';

// const crumbs = [
//     { name: 'Home', path: '/' },
//     { name: 'Admin', active: true },
//     { name: 'Cost Centres', active: true }
// ];
const basecolumns = [
    { key: 'costCentreDescription', name: 'Description', width: '75%' },
    {
        key: 'costCentreActive',
        name: 'Is Active ?',
        // formatter: (e) => (e.row.costCentreActive ? 'Yes' : 'No'),
        width: '20%'
    }
];
const CostCenter = (props) => {
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {}
    });
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);
    let checkhaveAccess = AppStorage.getAllowAcces();
    const pullApiData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const renderEdit = (ev, row) => {
        let RowData = { ...row, costCentreActive: row.costCentreActive.toLowerCase() == 'yes' ? true : false };
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton
                    toolTipTitle="Edit Cost Centre"
                    onClick={() => {
                        setState((st) => ({
                            ...st,
                            open: true,
                            title: 'Edit Cost Centre',
                            // selectedRowData: row,
                            selectedRowData: RowData,
                            isAdd: false
                        }));
                    }}
                />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        if (checkhaveAccess?.CanUpdateCustomerCostCentres) {
            tempCols.splice(0, 0, { key: 'action', name: 'Action', width: 80, formatter: renderEdit });
        }
        return tempCols;
    }, []);

    const saveData = async (outData) => {
        setBtnLoader(true);
        let response = await postCostCenter({
            costCentreCustomerID: props.customerID,
            costCentreID: outData.id || null,
            costCentreDescription: outData.value,
            costCentreActive: outData?.isChecked
        });
        if (response) {
            response.success
                ? showToastSuccess(response.message || 'Cost centre updated successfully')
                : showToastError(response.message || 'Something went wrong');
        } else {
            showToastError('Something went wrong');
        }
        setBtnLoader(false);
        pullApiData();
    };
    const addBtnClicked = () => {
        setState((st) => ({
            ...st,
            open: true,
            title: 'Add Cost Centre',
            isAdd: true,
            selectedRowData: {}
        }));
    };
    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false
        }));
    };
    return (
        <div>
            <GijgoGrid
                btnTitle={`${checkhaveAccess?.CanUpdateCustomerCostCentres ? 'Add Cost Centre' : ''}`}
                onBtnClick={addBtnClicked}
                getUrl={`Costcentres?CustomerID=${props.customerID}`}
                columns={columns}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
            />
            {state.open ? (
                <DialogComp open={state.open} onClose={handleClose} title={state.title} maxWidth="md">
                    <DialogBox data={state} onClose={handleClose} saveData={saveData} btnLoader={btnLoader} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default CostCenter;
