import React, { useState, useEffect } from 'react';
import '../../../commonStyle.scss';
import { getAllJobQueryList } from '../../../../Core/Services/InternalUser/JOB/jobQueryService';
import JobDetail from './JOBItems/JobDetails';
import { Button, Grid, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import moment from 'moment';
import { CustomChip, PrimaryButton, SecondaryButton } from '../../../../Core/Controls';
import AddEditJobsNotes from './JobDetails/Internal/addUpdateJobNotes';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import AddJobUpdateNotes from './JobDetails/Internal/addJobNotes';
import JobQueryModal from './JobStatusBtn/JobQuery';

const JobSingleNote = (props) => {
    const { orderNo, name, dateCreated, message, dateReplied } = props.note;
    return (
        <>
            <div>
                <div className="msg" style={{ fontSize: 14, fontWeight: 500 }}>
                    {message}
                </div>

                <div className="footer" style={{ fontSize: 12, color: 'grey', fontStyle: 'italic', textAlign: 'right', float: 'right' }}>
                    {name} @ {dateCreated ? moment(dateCreated).format('LLL') : '-'}
                </div>
                <br />
                {/* <hr style={{ opacity: '30%' }} /> */}
            </div>
        </>
    );
};
const JobQueriesScreen = (props) => {
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {}
    });
    useEffect(async () => {
        pullApiData();
    }, [props.refreshDependancy]);

    const pullApiData = async () => {
        let res = await getAllJobQueryList(props.JobID);
        setState((st) => ({
            ...st,
            showLoader: false,
            list: res.data.list
        }));
    };

    const handelModal = (name, val) => {
        setState((st) => ({
            ...st,
            [name]: val
        }));
    };

    const callonFormsubmit = (res) => {
        setState((st) => ({
            ...st,
            showAddJobUpdateNotes: false
        }));
        res && pullApiData();
    };
    return (
        <div>
            <Grid container style={{ border: '1px solid lightgrey' }}>
                <Grid item xs={12}>
                    {!props.isHeaderNotShow ? (
                        <Grid
                            container
                            xs={12}
                            className=""
                            justify="space-between"
                            alignItems="center"
                            style={{ padding: '7px', paddingRight: '5px', background: 'lightgrey' }}
                        >
                            <Grid item>
                                <Typography variant="body" color="secondary" style={{ color: '#f50057' }}>
                                    Job Updates
                                </Typography>
                            </Grid>
                            <Grid item>
                                {/* {props.isDisabled && ( */}
                                <PrimaryButton
                                    color="primary"
                                    size="small"
                                    onClick={() => handelModal('showAddJobUpdateNotes', true)}
                                    style={{ padding: '1px 10px', fontSize: 12 }}
                                >
                                    Add Job Updates
                                </PrimaryButton>
                                {/* )} */}
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item>
                            {/* {props.isDisabled && ( */}
                            <PrimaryButton
                                color="primary"
                                size="small"
                                onClick={() => handelModal('showAddJobUpdateNotes', true)}
                                style={{ padding: '1px 10px', fontSize: 12 }}
                            >
                                Add Job Updates
                            </PrimaryButton>
                            {/* )} */}
                        </Grid>
                    )}
                </Grid>

                <Grid
                    item
                    xs={12}
                    style={{
                        // padding: '5px',
                        height: props.height || '390px',
                        overflow: 'auto'
                    }}
                    // className="grid-style custom-scroll"
                >
                    {state.list?.map((n, i) => (
                        <div style={{ background: i % 2 ? '#F6EEEC' : '#ECF4F6', padding: '4px 10px', border: `1px solid ${i % 2 ? '#EAD7D2' : '#EAD7D2'}` }}>
                            <JobSingleNote key={i} note={n} />
                        </div>
                    ))}
                </Grid>
            </Grid>

            {state.showAddJobUpdateNotes ? (
                <DialogComp open={true} onClose={() => handelModal('showAddJobUpdateNotes', false)} title={'Job Update'} maxWidth="sm" fullWidth>
                    <JobQueryModal jobQueryJobID={props.JobID} onClose={callonFormsubmit} title={'Job Update'} hideGrid={true} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default JobQueriesScreen;
