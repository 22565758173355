import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormHelperText } from '@material-ui/core';
import { InputText, SecondaryButton, MandatoryField, SecondaryCheckbox } from '../../../../Core/Controls';
import { getDefectTypesId, postDefectTypesData } from '../../../../Core/Services/InternalUser/defectTypeService';
import { useToast } from '../../../../Core/Hooks/useToast';
import '../../.././commonStyle.scss';

function DefectTypeModal(props) {
    const [state, setState] = useState({
        defectTypeID: null,
        defectTypeCode: '',
        defectTypeDescription: '',
        defectEmailDocument: true,
        IsAdd: true,
        errors: {}
    });
    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        if (props.data) {
            const data = props.data;
            setState((state) => ({
                ...state,
                defectTypeCode: data.defectTypeCode,
                defectTypeDescription: data.defectTypeDescription,
                defectEmailDocument: data.defectEmailDocument,
                code: data.defectTypeCode,
                IsAdd: data.IsAdd
            }));
        }
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };
    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    const validations = () => {
        const { defectTypeDescription, defectTypeCode } = state; //[A-Z]{2}
        let pattern = /[A-Z]{2}/;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!defectTypeDescription) {
            errors.defectTypeDescription = ' Defect Types Description is required';
            formIsValid = false;
        }
        if (!pattern.test(defectTypeCode)) {
            errors.defectTypeCode = ' Defect Types Code Should be less then 2 Charecter';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const defectTypeData = {
                defectTypeCode: state.defectTypeCode,
                defectTypeDescription: state.defectTypeDescription,
                defectEmailDocument: state.defectEmailDocument,
                IsAdd: state.code ? false : true
            };
            let res = await postDefectTypesData(defectTypeData);
            if (res.success) {
                showToastSuccess('Defect Types Updated sucessfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
        }
    };

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Description"
                    name="defectTypeDescription"
                    placeholder="Description"
                    onChange={handleInputChange}
                    value={state.defectTypeDescription}
                    errors={state.errors.defectTypeDescription}
                />
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Defect Type Code"
                    name="defectTypeCode"
                    placeholder="Defect Type Code"
                    onChange={handleInputChange}
                    value={state.defectTypeCode}
                    errors={state.errors.defectTypeCode}
                    disabled={state.code ? true : false}
                />
            </Grid>
            <Grid item xs={12}>
                <SecondaryCheckbox checked={state.defectEmailDocument} name="defectEmailDocument" onChange={handleCheckbox} label="Defect Email Document?" />
            </Grid>

            <Grid item xs={12} className="submit_btn">
                <SecondaryButton onClick={submitHandler}>{props.data ? 'Update' : 'Add'}</SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default DefectTypeModal;
