import { postWithAuth, getWithAuth } from '../../Basic/HttpService';

export const getCompanyDetails = async () => {
    let res = await getWithAuth(`companys`);
    return res;
};

export const postCompanyDetails = async (data) => {
    let res = await postWithAuth(`companys`, data);
    return res;
};
