import React, { useState, useEffect, useMemo } from 'react';
import { getAllProductGroups } from '../../../../Core/Services/InternalUser/productGroupService';
import DataGrid from '../../../../Core/Controls/Grid';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import ProductGroupModal from './ProductGroupModal';
import { BreadCrumbs, AddButton, EditButton, formatters } from '../../../../Core/Controls';
import '../../.././commonStyle.scss';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Admin', active: true },
    { name: 'Product Groups', active: true }
];
const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'productGroupDescription', name: 'Description' },
    { key: 'productGroupMaxMargin', name: 'Max Margin', headerRenderer: formatters.ColumnHeaderAlignRight, formatter: formatters.ColumnTextAlignRight },
    { key: 'productGroupMinMargin', name: 'Min Margin', headerRenderer: formatters.ColumnHeaderAlignRight, formatter: formatters.ColumnTextAlignRight },
    { key: 'productGroupCode', name: 'Code' },
    { key: 'productGroupLeadTime', name: 'Lead Time', headerRenderer: formatters.ColumnHeaderAlignRight, formatter: formatters.ColumnTextAlignRight }
];

function ProductGroup(props) {
    const [state, setState] = useState({
        productgroupsList: [],
        loader: true,
        open: false
    });

    useEffect(async () => {
        pullProdcutGroups();
    }, []);

    const pullProdcutGroups = async () => {
        let res = await getAllProductGroups();
        setState((state) => ({
            ...state,
            productgroupsList: res.data,
            loader: false
        }));
    };

    const handleProductUpdate = (params) => {
        setState((state) => ({ ...state, open: true, productGroupID: params.productGroupID }));
    };
    const editActionButton = (data) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleProductUpdate(data.row)} toolTipTitle="Edit Product Group" />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = editActionButton;
        }
        return newColumn;
    });

    const title = state.productGroupID ? 'Update Product Group' : 'Add Product Group';

    const closeProductGroupModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullProdcutGroups();
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <DataGrid
                columns={columns}
                rows={state.productgroupsList}
                isRowHovered={true}
                enableSearch={true}
                loadingData={state.loader}
                btnTitle="Add Product Group"
                onBtnClick={handleProductUpdate}
                paginate
                offset={210}
            />
            {state.open ? (
                <DialogComp title={title} onClose={() => closeProductGroupModal(false)} maxWidth="md">
                    <ProductGroupModal productGroupID={state.productGroupID} onClose={() => closeProductGroupModal(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
}
export default ProductGroup;
