import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { Alert } from '@material-ui/lab';
import { getWarehouseParts } from '../../../Core/Services/Admin/partService';
import { Paper, TableHead, TableRow } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'grey',
        color: theme.palette.common.white,
        padding: '2px 5px'
    },
    body: {
        fontSize: 15,
        padding: '2px 5px',
        color: theme.palette.common.black
    }
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white'
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: '500px'
    }
});

export default function GetWarehousePartsList(props) {
    const [state, setState] = useState({
        List: []
    });
    useEffect(async () => {
        if (props.partID) {
            let res = await getWarehouseParts(props.partID, props.inStock);
            if (res.success) {
                setState((st) => ({
                    ...st,
                    List: res?.data?.table
                }));
            }
        }
    }, []);
    const classes = useStyles();

    return (
        //  style={{ borderRadius: '0px', maxHeight: '450px', overflow: 'auto', minWidth: '600px', minHeight: 550 }}
        <TableContainer
            component={Paper}
            className="custom-scroll"
            // style={{ minHeight: 600, minWidth: '600px' }} className="custom-scroll"
            style={{ minHeight: '400px', maxHeight: '770px', borderRadius: '0px' }}
        >
            <Table className={classes.table} style={{ minWidth: 550 }} aria-label="customized table" stickyHeader>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell width="33.33%">Warehouse</StyledTableCell>
                        <StyledTableCell width="33.33%">Quantity</StyledTableCell>
                        <StyledTableCell width="33.33">Service Area</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {state?.List.length > 0 ? (
                        state?.List.map((row) => {
                            const isSelectedWarehouse = props.selectedWarehouse?.name === row.warehouse;
                            const backgroundColor = row.background || (isSelectedWarehouse ? '#00FF00' : '');
                            const color = row.text;

                            const rowStyle = {
                                backgroundColor,
                                color
                            };
                            return (
                                <StyledTableRow key={row.warehouse} style={rowStyle}>
                                    <StyledTableCell>{row.warehouse}</StyledTableCell>
                                    <StyledTableCell>{row.quantity}</StyledTableCell>
                                    <StyledTableCell>{row.servicearea}</StyledTableCell>
                                </StyledTableRow>
                            );
                        })
                    ) : (
                        <StyledTableRow>
                            <StyledTableCell colSpan={2}>
                                <Alert severity="info">No Record Found</Alert>
                            </StyledTableCell>
                        </StyledTableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
