import React, { useState, useEffect, useMemo } from 'react';
import { getAllVorTypes } from '../../../Core/Services/InternalUser/VOR/vorTypesService';
import DataGrid from '../../../Core/Controls/Grid';
import DialogComp from '../../../Core/Dialog/DialogComp';
import VorTypeModal from './VorTypeModal';
import { BreadCrumbs, AddButton, EditButton } from '../../../Core/Controls';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import '../../commonStyle.scss';
import VORTypeDetail from '../VORTypeDetailScreen/vorTypeDetail'
import { Assignment } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicles', active: true },
    { name: 'VOR Types', active: true }
];

const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'vorTypeDescription', name: 'Description' }
];

function Vor(props) {
    const [state, setState] = useState({
        vorList: [],
        loader: true,
        open: false
    });

    useEffect(async () => {
        pullVorType();
    }, []);

    const pullVorType = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const vorHandler = (params) => {
        setState((state) => ({ ...state, open: true, vorTypeID: params?.vorTypeID }));
    };

    const reasonHandler = (va, x) => {
        setState((st) => ({ ...st, showReasons: va, focusedDescription: x?.vorTypeDescription }))
    }

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center', justifyContent: 'space-evenly' }}>
                <EditButton
                    onClick={() => vorHandler(row)}
                    toolTipTitle="Edit VOR Type"
                />
                <CustomIconButton
                    icon={Assignment}
                    onClick={() => reasonHandler(true, row)}
                    toolTipTitle="VOR Reasons"
                />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        return newColumn;
    });

    const title = state.vorTypeID ? 'Update VOR Type' : 'Add VOR Type';

    const closeVorModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullVorType();
    };

    return (
        state.showReasons ?
            <>
                <VORTypeDetail closeType={reasonHandler} vorTypeDescription={state.focusedDescription} />
            </>
            :
            <>
                <div className="screen">
                    <BreadCrumbs crumbs={crumbs} />
                    <GijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={columns}
                        getUrl={'vortypes'}
                        btnTitle="Add VOR Type"
                        onBtnClick={() => {
                            vorHandler(null);
                        }}
                        isReload={state.isReload}
                    />

                    {state.open ? (
                        <DialogComp title={title} onClose={() => closeVorModal(false)} maxWidth="md">
                            <VorTypeModal vorTypeID={state.vorTypeID} onClose={() => closeVorModal(true)} />
                        </DialogComp>
                    ) : null}
                </div>
            </>
    );
}
export default Vor;
