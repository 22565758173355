import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { InputText, SecondaryButton } from '../../../Core/Controls';
import { addLeadNotes } from '../../../Core/Services/InternalUser/ControllerService/EnquiriService';
import { useToast } from '../../../Core/Hooks/useToast';

const AddEditLeadNotes = (props) => {
    const [state, setState] = useState({
        leadNoteID: props.leadNoteID || null,
        leadNoteLeadID: props.leadNoteLeadID || null,
        leadNoteDescription: ''
    });

    const { showToastSuccess, showToastError } = useToast();

    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handleSubmit = async () => {
        const data = {
            leadNoteID: state.leadNoteID,
            leadNoteLeadID: state.leadNoteLeadID,
            leadNoteDescription: state.leadNoteDescription
        };
        let res = await addLeadNotes(data);
        if (res.success) {
            showToastSuccess('Note saved successfully');
            props.onFormSubmit(true);
        } else {
            showToastError(res.message);
        }
    };
    return (
        <Grid container spacing={2} style={{ width: '600px' }}>
            <Grid item xs={12} sm={12}>
                <InputLabel shrink>Description</InputLabel>
                <InputText
                    multiline
                    rows={8}
                    placeholder="Description"
                    value={state.leadNoteDescription}
                    onChange={inputChange}
                    name="leadNoteDescription"
                    autoFocus={true}
                />
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton onClick={handleSubmit} disabled={!state.leadNoteDescription} fullWidth className="submit_btn">
                    Save
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};
export default AddEditLeadNotes;
