import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import React, { useEffect, useState, useMemo } from 'react';
import { InputText, SecondaryButton, YesButton, NoButton } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';
import '../../controllerStyle.scss';
import { getAllJobQueryList, postJobQueryModal } from '../../../../../Core/Services/InternalUser/JOB/jobQueryService';
import moment from 'moment';
import './style.scss';
import { AppStorage } from '../../../../../Core/Basic/storage-service';

const JobSingleNote = (props) => {
    return (
        <div className="single-note">
            <div className="msg">{props.note.message}</div>
            <div className="footer">
                {props.note.name} @ {moment(props.note.dateCreated).format('LLL')}
            </div>
        </div>
    );
};

const JobQueryModal = (props) => {
    const [state, setState] = useState({
        jobQueryMessage: '',
        jobQueryClosed: false,
        showLoader: true,
        showBtnLoader: false,
        rows: []
    });
    let checkhaveAccess = AppStorage.getAllowAcces();
    const [errors, setErrors] = useState({});
    const { showToastSuccess, showToastError } = useToast();
    const pullJobQueryList = async () => {
        let res = await getAllJobQueryList(props.jobQueryJobID);
        setState((st) => ({
            ...st,
            rows: [...(res.data?.list || [])],
            showLoader: false
        }));
    };
    useEffect(() => {
        pullJobQueryList();
    }, []);

    const validate = () => {
        let noErrors = true;
        let err = {};
        if (!/[^\s]/.test(state.jobQueryMessage)) {
            err.jobQueryMessage = true;
            noErrors = false;
        }
        setErrors(err);
        return noErrors;
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handleData = async () => {
        setState((st) => ({
            ...st,
            showBtnLoader: true
        }));
        if (validate()) {
            let response = '';
            if (props.title == 'Reply Customer') {
                response = await postJobQueryModal({
                    jobQueryJobID: props.jobQueryJobID,
                    jobQueryMessage: state.jobQueryMessage,
                    customerMessage: true
                });
                setState((st) => ({
                    ...st,
                    showBtnLoader: false
                }));
            } else {
                response = await postJobQueryModal({
                    jobQueryJobID: props.jobQueryJobID,
                    jobQueryMessage: state.jobQueryMessage
                });
                setState((st) => ({
                    ...st,
                    showBtnLoader: false
                }));
            }
            if (response) {
                // eslint-disable-next-line no-unused-expressions
                response.success ? null : showToastError(response.message || 'Something went wrong');
                pullJobQueryList();
                props.onClose(true);
                setState((st) => ({
                    ...st,
                    showBtnLoader: false
                }));
            } else {
                showToastError('Something went wrong');
                setState((st) => ({
                    ...st,
                    showBtnLoader: false
                }));
            }
        }
    };

    const inputChange = (ev) => {
        let temp = ev.target;
        setState((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
        setErrors({});
    };

    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                {props.title && (
                    <div style={{ width: '200px', position: 'absolute', right: 85, top: 12 }}>
                        <SecondaryButton
                            onClick={handleData}
                            disabled={state.showBtnLoader || (state.jobQueryMessage.length === 0 && !checkhaveAccess?.CanDeleteJobLines)}
                            isBtnLoader={state.showBtnLoader}
                        >
                            {props.title}
                        </SecondaryButton>
                    </div>
                )}
                {props.title && (
                    <Grid item xs={12} className="mandatory-fields">
                        <InputLabel required shrink>
                            Message
                        </InputLabel>
                        <InputText name="jobQueryMessage" rows={5} multiline onChange={inputChange} autoFocus={true} />
                        <FormHelperText error>{errors.jobQueryMessage ? 'Message is empty!' : null}</FormHelperText>
                    </Grid>
                )}
                {props.title == 'Reply Query' && (
                    <Grid xs={12} item style={{ marginBottom: 8 }}>
                        <InputLabel shrink>Close Query</InputLabel>
                        <Grid xs={12} container spacing={1}>
                            <Grid item xs={6}>
                                <YesButton
                                    toolTipTitle="Yes"
                                    btnName="Yes"
                                    state={state.jobQueryClosed}
                                    name="jobQueryClosed"
                                    onClick={() => handleClick('jobQueryClosed', true)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <NoButton
                                    toolTipTitle="No"
                                    btnName="No"
                                    state={state.jobQueryClosed}
                                    name="jobQueryClosed"
                                    onClick={() => handleClick('jobQueryClosed', false)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {!props.hideGrid && (
                    <Grid item xs={12}>
                        {state.rows.length > 0 ? (
                            <div className="notes-container custom-scroll">
                                {state.rows?.map((n, i) => (
                                    <JobSingleNote key={i} note={n} />
                                ))}
                            </div>
                        ) : (
                            <div className="notes-container custom-scroll">No data found</div>
                        )}
                    </Grid>
                )}
            </Grid>
        </div>
    );
};
export default JobQueryModal;
