import { Card, Grid, Tooltip, Typography } from '@material-ui/core';
import './vorReporting.scss';

export const RenderReportingByFilter = (props) => {
    return (
        <Grid container spacing={2} style={{ padding: '10px 20px' }}>
            <hr
                style={{
                    flexShrink: 0,
                    borderWidth: '0px 0px thin',
                    borderStyle: 'solid',
                    borderColor: 'rgba(58, 53, 65, 0.12)',
                    margin: '1.1rem 0px'
                }}
            />
            {props.list?.length > 0 ? (
                props.list?.map((option, i = 1) => {
                    return (
                        <Grid item sm={6} md={4} lg={i === 0 ? 3 : 2}>
                            <div className={`${i == 0 ? 'categorybar' : 'seprationBar'}`}>
                                {/* <Tooltip title={option.val} arrow key={`card${i}`}> */}
                                <div className="vortype-container" style={{ marginLeft: `${i == 0 ? '9px' : '0px'}` }}>
                                    <div
                                        className="vortype-data"
                                        onClick={props.handleEditRegClick ? () => props.handleEditRegClick(option, props.label) : ''}
                                        style={{ cursor: props.handleEditRegClick ? 'pointer' : 'default' }}
                                    >
                                        <div style={{ fontSize: 24, fontWeight: 600 }}> {option.sum ? option.sum : 0}</div>
                                        <span style={{ fontSize: 12, marginBottom: 6 }}> {option.val ? option.val : `${'\u{2800}'}`}</span>
                                    </div>
                                </div>
                                {/* </Tooltip> */}
                            </div>
                        </Grid>
                    );
                })
            ) : (
                <Grid item xs={12} style={{ padding: '30px 20px' }}>
                    <div style={{ width: '100%', textAlign: 'center' }}>{props.noRow}</div>
                </Grid>
            )}
        </Grid>
    );
};

const RenderReporting = (props) => {
    let getSize = props.label === 'Top 5 Vehicles' || props.label === 'Top 5 Makes' || props.label === 'Top 5 Models';
    return (
        <Card className={props.class}>
            <Grid container spacing={2} item xs={12} style={{ padding: '10px 20px' }}>
                <Grid item xs={6} md={3} lg={2} style={{ maxWidth: 100, minWidth: 50 }}>
                    <div>
                        <Typography vraient="h5" color="secondary" className="dashHeading">
                            {props.label}
                        </Typography>
                    </div>
                </Grid>
                {props.list?.length > 0 ? (
                    props.list?.map((option, i = 1) => {
                        return (
                            <Grid item>
                                <div className={`${i == 0 ? 'categorybar' : 'seprationBar'}`}>
                                    <Tooltip title={option.val} arrow key={`card${i}`}>
                                        <div className="vortype-container" style={{ marginLeft: `${i == 0 ? '9px' : '0px'}` }}>
                                            <div className="vortype-data">
                                                <div style={{ fontSize: 24, fontWeight: 600 }}> {option.total}</div>
                                                <span style={{ fontSize: 12, marginBottom: 6 }}> {option.val}</span>
                                            </div>
                                        </div>
                                    </Tooltip>
                                </div>
                            </Grid>
                        );
                    })
                ) : (
                    <Grid item style={{ padding: '30px 20px' }}>
                        {props.noRow}
                    </Grid>
                )}
            </Grid>
        </Card>
    );
};

export default RenderReporting;
