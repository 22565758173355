import React, { useState, useEffect, useMemo } from 'react';
import { getAllFuelTypes } from '../../../../Core/Services/InternalUser/fuelTypeService';
import DataGrid from '../../../../Core/Controls/Grid';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import FuelTypesModal from './FuelTypesModal';
import { BreadCrumbs, EditButton, formatters } from '../../../../Core/Controls';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import '../../.././commonStyle.scss';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicles', active: true },
    { name: 'Fuel Types', active: true }
];
const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'fuelTypeDescription', name: 'Description' },
    { key: 'fuelTypeDisplayBatteryLevel', name: 'Display Battery Level ?', width: '15%' },
    { key: 'fuelTypeActive', name: 'Is Active ?', width: '15%' }
];

function FuelTypes(props) {
    const [state, setState] = useState({
        fuelList: [],
        loader: true,
        open: false
    });

    useEffect(async () => {
        pullAllData();
    }, []);

    const pullAllData = async () => {
        setState((state) => ({
            ...state,
            isReload: new Date()
        }));
    };

    const submitHandler = (params) => {
        setState((state) => ({ ...state, open: true, fuelTypeID: params?.fuelTypeID }));
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => submitHandler(row)} toolTipTitle="Edit Fuel Types" />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        return newColumn;
    });

    const title = state.fuelTypeID ? 'Update Fuel Types' : 'Add Fuel Types';

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullAllData();
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                columns={columns}
                getUrl={`FuelTypes`}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                btnTitle="Add Fuel Type"
                onBtnClick={() => submitHandler(null)}
            />
            {state.open ? (
                <DialogComp title={title} onClose={() => closeModal(false)} maxWidth="md">
                    <FuelTypesModal fuelTypeID={state.fuelTypeID} onClose={closeModal} />
                </DialogComp>
            ) : null}
        </div>
    );
}

export default FuelTypes;
