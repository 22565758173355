import React, { useEffect, useState, useMemo } from 'react';
import { BreadCrumbs, AddButton, DataGrid, EditButton, formatters } from '../../../../Core/Controls';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { getAllVehicleCheckSection } from '../../../../Core/Services/InternalUser/vehicleCheckSection';
import CheckSectionModal from './checkSectionModal';

const crumb = [
    { name: 'Home', path: '/' },
    { name: 'Vehicle Checks', active: true },
    { name: 'Inspection Sections', active: true }
];
const basecolumns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'inspectionSheetSectionDescription', name: 'Description' },
    {
        key: 'inspectionSheetSectionPosition',
        name: 'Position',
        width: '13%',
        headerRenderer: formatters.ColumnHeaderAlignRight,
        formatter: formatters.ColumnTextAlignRight
    },
    { key: 'vehicleCategoryDescription', name: 'Category', width: '13%' },
    { key: 'inspectionSheetSectionActive', name: 'Is Active ?', formatter: (e) => (e.row.inspectionSheetSectionActive ? 'Yes' : 'No'), width: '13%' }
];
const CheckSection = () => {
    const [state, setState] = useState({
        checkSectionList: [],
        showLoader: true,
        modalToggle: false
    });

    useEffect(async () => {
        pullData();
    }, []);

    const pullData = async () => {
        let res = await getAllVehicleCheckSection();

        setState((state) => ({
            ...state,
            checkSectionList: res.data,
            showLoader: false
        }));
    };
    const actionButton = (data) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleButton(data.row)} toolTipTitle="Edit Vehicle Check Section" />
            </div>
        );
    };
    const handleButton = (params) => {
        setState((state) => ({ ...state, modalToggle: true, userID: params.inspectionSheetSectionID }));
    };

    const closeDialog = (res) => {
        setState((state) => ({ ...state, modalToggle: false }));
        res && pullData();
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = actionButton;
        }
        return tempCols;
    }, []);

    const title = state.userID ? 'Update Vehicle Check Section' : 'Add Vehicle Check Section';

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumb} />
            <DataGrid
                columns={columns}
                rows={state.checkSectionList}
                isRowHovered={true}
                enableSearch={true}
                loadingData={state.showLoader}
                btnTitle="Add Vehicle Check Section"
                onBtnClick={handleButton}
                paginate
                offset={210}
            />
            {state.modalToggle ? (
                <DialogComp title={title} onClose={() => closeDialog(false)}>
                    <CheckSectionModal checkSectionID={state.userID} onClose={() => closeDialog(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default CheckSection;
