import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from '../../../../Core/Controls/Grid/index';
import { AddButton, EditButton, BreadCrumbs } from '../../../../Core/Controls';
import DialogBox from './driverTypeDialog';
import { useToast } from '../../../../Core/Hooks/useToast';
import DialogComp from '../../../../Core//Dialog/DialogComp';
import '../../../commonStyle.scss';

import { getAllDriverType, postDriverType, putDriverType } from '../../../../Core/Services/InternalUser/driverTypeService';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Drivers', active: true },
    { name: 'Driver Types', active: true }
];

const basecolumns = [
    { key: 'action', name: 'Action', width: '7%' },
    { key: 'driverTypeDescription', name: 'Description' }
];
const DriverType = () => {
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        isReload: null
    });
    const { showToastSuccess, showToastError } = useToast();

    const pullApiData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };
    const renderEdit = (ev, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <EditButton
                    toolTipTitle="Edit Driver Type"
                    onClick={() => {
                        setState((st) => ({
                            ...st,
                            open: true,
                            title: 'Edit Driver Type',
                            selectedRowData: row,
                            isAdd: false
                        }));
                    }}
                />
            </div>
        );
    };
    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);

    const addRowData = async (outData) => {
        let response = await postDriverType({ driverTypeDescription: outData });
        pullApiData();
        showToastSuccess(response.message || 'Driver type successfully added');
    };
    const updateRowData = async (outData) => {
        let response = await putDriverType({
            driverTypeID: outData.id,
            driverTypeDescription: outData.value
        });
        pullApiData();
        showToastSuccess(response.message || 'Driver type successfully updated');
    };
    const addBtnClicked = () => {
        setState((st) => ({
            ...st,
            open: true,
            title: 'Add Driver Type',
            isAdd: true,
            selectedRowData: {}
        }));
    };
    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false
        }));
    };

    let getUrl = `DriverTypes`;

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={getUrl}
                btnTitle="Add Driver Type"
                onBtnClick={addBtnClicked}
                isReload={state.isReload}
            />
            {state.open ? (
                <DialogComp open={state.open} onClose={handleClose} title={state.title} maxWidth="md">
                    <DialogBox data={state} onClose={handleClose} addRowData={addRowData} updateRowData={updateRowData} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default DriverType;
