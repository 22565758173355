import React, { useContext } from 'react';
import { Button, Hidden, Typography, Grid } from '@material-ui/core';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { SecondaryButton } from '../../../Core/Controls';
// import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { DashWidgetsProvider as StateProvider, DashboardContext } from './DashboardProvider';
import CheckIcon from '@material-ui/icons/Check';
import { ModalLoader } from '../../../Core/Loader/formLoader';

const styles = {
    mainDiv: {
        width: '100%'
    },

    buttonStyle: {
        textTransform: 'capitalize',
        margin: '5px'
    }
};
const buttonIcons = {
    3: FormatListBulletedIcon,
    2: ViewComfyIcon,
    1: ViewColumnIcon
};
const SetWidgets = (props) => {
    const { handleBtn, state, handleBackgroundColor, handleSubmit } = useContext(DashboardContext);

    return (
        <>
            {state.displayStyles.length ? (
                <div>
                    <Typography variant="body1" gutterBottom color="secondary">
                        Set Dashboard Layout
                    </Typography>
                    <div style={styles.mainDiv}>
                        {state.displayStyles.map((m, i) => {
                            let Icon = buttonIcons[m.id];
                            return (
                                <Button
                                    key={i + 1}
                                    style={styles.buttonStyle}
                                    variant={state?.displayStyleID == m.id ? 'contained' : 'outlined'}
                                    color="secondary"
                                    onClick={() => handleBtn(m.id)}
                                >
                                    <Icon /> {m.name}
                                </Button>
                            );
                        })}
                    </div>
                    <br />

                    <Typography variant="body1" gutterBottom color="secondary">
                        Theme Colour
                    </Typography>

                    <div style={{ width: '100%' }}>
                        <ul style={{ listStyleType: 'none', display: 'flex', padding: 0 }}>
                            {state?.themes.map((m, i) => {
                                return (
                                    <li key={i + 2} style={{ cursor: 'pointer', margin: '0px 5px' }} onClick={handleBackgroundColor(m.id)}>
                                        <Avatar style={{ background: m.backgroundColour, height: 40, width: 40, border: m.id === 2 ? '1px solid grey' : '' }}>
                                            <div>{state?.themeID === m.id && <CheckIcon style={{ color: m.id === 2 ? 'black' : '' }} />}</div>
                                        </Avatar>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <br />
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <SecondaryButton onClick={handleSubmit}>Update</SecondaryButton>
                        </Grid>
                    </Grid>
                </div>
            ) : (
                <div style={{ width: '100%' }}>
                    <ModalLoader />
                </div>
            )}
        </>
    );
};

const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <SetWidgets {...props} />
        </StateProvider>
    );
};
export default Screen;
