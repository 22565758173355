import * as Components from '../../../../Components';
import { TypeOfAuth } from '../menu-auth-type';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BuildIcon from '@material-ui/icons/Build';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import GavelIcon from '@material-ui/icons/Gavel';
import DriveEtaIcon from '@material-ui/icons/DriveEta';

export default [
    {
        label: 'Dashboard',
        path: '/',
        exact: true,
        component: Components.DriverDashboard,
        authType: TypeOfAuth.Both,
        icon: <DashboardIcon />
    },
    {
        label: 'Maintenance',
        authType: TypeOfAuth.Both,
        icon: <GavelIcon />,
        children: []
    },
    {
        label: 'Breakdowns',
        path: '/maintenance/breakdowns/:all',
        exact: true,
        component: Components.DriverBreakDownDashboard,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Defects',
        path: '/maintenance/defects',
        exact: true,
        component: Components.DriverDefectsScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Vehicles',
        authType: TypeOfAuth.Both,
        icon: <DriveEtaIcon />,
        path: '/controller/Vehicles',
        exact: true,
        component: Components.DriverVehicleScreen
    },

    {
        label: 'VehicleDashBoard',
        path: '/controller/editVehicle/:id/:name?/:serviceAgentid?',
        exact: true,
        component: Components.EditVehicle,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'All Checks',
        path: '/internalDailyChecks/all',
        exact: true,
        hidden: true,
        component: () => {
            return <Components.DailyCheckInternalUser statusCode="" resultCode="" fromPage="All Checks" role="DriveRole" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Failed',
        path: '/internalDailyChecks/failed',
        exact: true,
        hidden: true,
        component: () => {
            return <Components.DailyCheckInternalUser statusCode="" resultCode="F" fromPage="Failed" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'DashScreen',
        path: '/maintenance/dash/:screenName',
        exact: true,
        hidden: true,
        component: Components.DriverDash,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'CheckoutScreen',
        path: '/maintenance/checkout',
        exact: true,
        hidden: true,
        component: Components.CheckOutScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'VehicleDashBoard',
        path: '/controller/editVehicle/:id/:name?',
        exact: true,
        component: Components.EditVehicle,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'EditBreakDown',
        path: '/maintenance/breakdowns/Edit/:BreakDownID/:VehicleID',
        exact: true,
        hidden: true,
        component: Components.EditBreakdownLink,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'EditDefect',
        path: '/maintenance/defect/edit/:DefectID/:VehicleID',
        exact: true,
        hidden: true,
        component: Components.DefectEditDetails,
        authType: TypeOfAuth.Auth
    }
];
