import { postWithAuth, getWithAuth } from '../../Basic/HttpService';
// Conviction
export const getlicenceConvictionList = async (id) => {
    let res = await getWithAuth(`LicenceConviction?LicenceCheckID=${id}`);
    return res;
};

export const getLicenceConvictionbyid = async (id) => {
    let res = await getWithAuth(`LicenceConviction/${id}`);
    return res;
};

export const postlicenceConviction = async (data) => {
    let res = await postWithAuth(`LicenceConviction`, data);
    return res;
};

//Licence Check

export const getLicenceCheckList = async () => {
    let res = await getWithAuth(`LicenceCheck`);
    return res;
};

export const getLicenceCheckbyid = async (id) => {
    let res = await getWithAuth(`LicenceCheck/${id}`);
    return res;
};

export const postLicenceCheck = async (data) => {
    let res = await postWithAuth(`LicenceCheck`, data);
    return res;
};

export const LicenceCheckDropdown = async (data) => {
    let res = await getWithAuth(`LicenceCheck/screenDropdowns`);
    return res;
};
