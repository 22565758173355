import { postWithAuth, getWithAuth, putWithAuth } from '../../../Basic/HttpService';
import { memoize } from 'lodash';

export const getAllFuelPurchase = async (id) => {
    let res = await getWithAuth(`FuelPurchases?VehicleID=${id}`);
    return res;
};

export const getFuelPurchaseById = async (vehicleid, Id) => {
    // let res = await getWithAuth(`FuelPurchases?VehicleID=${vehicleid}/${Id}`);

    let res = await getWithAuth(`FuelPurchases/${Id}`);
    return res;
};

export const getFuelPurchaseScreenDropdowns = memoize(async () => {
    let res = await getWithAuth(`FuelPurchases/screenDropdowns`);
    return res;
});

export const addFuelPurchase = async (data) => {
    let res = await postWithAuth('FuelPurchases', data);
    return res;
};
