import { InputLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useMemo, useState } from 'react';
import { DataGrid, EditButton, MandatoryField, Multiselect, SecondaryButton, formatters, SelectBox, SecondaryCheckbox } from '../../../Core/Controls';
import { useToast } from '../../../Core/Hooks/useToast';
import { getAllDropdowns, addReportCatalogues, getReportCataloguesById } from '../../../Core/Services/Admin/reportCatalogueService';
import { FormHelperText } from '@material-ui/core';
import '../../InternalUser/Controllers/controllerStyle.scss';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: '5%'
    },
    {
        key: 'reportCatalogueColumnDisplayName',
        name: 'Column Name'
    },
    {
        key: 'reportCatalogueColumnDatabaseName',
        name: 'Database Column'
    },
    {
        key: 'reportCatalogueColumnFieldType',
        name: 'Field Type'
    },
    {
        key: 'reportCatalogueColumnSelectionType',
        name: 'Selection Type'
    },
    {
        key: 'reportCatalogueColumnLength',
        name: 'Field Length'
    },
    {
        key: 'reportCatalogueColumnMandatory',
        name: 'Field Required',
        formatter: formatters.YesNo
    }
];

const AddEditReportCatalogue = (props) => {
    const { showToastSuccess, showToastError } = useToast();

    const [state, setState] = useState({
        errors: {},
        reportsList: [],
        reportCatalogueName: '',
        tablesToUseList: '',
        reportCatalogueSQL: '',
        reportCatalogueColumnDisplayName: '',
        reportCatalogueColumnDatabaseName: '',
        reportCatalogueColumnFieldType: '',
        reportCatalogueColumnSelectionType: '',
        reportCatalogueColumnLength: '',
        reportCatalogueColumnMandatory: true,
        reportCatalogueColumnMandatoryList: [],
        reportCatalogueColumnSelectionTypesList: [],
        reportCatalogueColumnFieldTypesList: [],
        catlogId: null,
        reportCatalogueID: null,
        tablesListIds: []
    });

    const [btnLoader, setBtnLoader] = useState(false);

    const valueChanged = (ev) => {
        let temp = ev.target;
        setState((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
    };

    useEffect(async () => {
        let res = await getAllDropdowns();
        if (res.success) {
            setState((st) => ({
                ...st,
                reportCatalogueColumnMandatoryList: [],
                reportCatalogueColumnSelectionTypesList: res.data.reportCatalogueSelectionTypes,
                reportCatalogueColumnFieldTypesList: res.data.reportCatalogueFieldTypes
            }));
        }
        if (props.reportCatalogueID) {
            let res = await getReportCataloguesById(props.reportCatalogueID);
            let data = res.data;
            setState((st) => ({
                ...st,
                reportsList: data?.reportCatalogueColumns?.map((m, i) => ({ ...m, id: i + 1 })) || [],

                tablesToUseList: res.data.reportCatalogueTables[0]?.reportCatalogueTableName,
                reportCatalogueID: res.data.reportCatalogues[0]?.reportCatalogueID,
                reportCatalogueName: res.data.reportCatalogues[0]?.reportCatalogueName,
                reportCatalogueSQL: res.data.reportCatalogues[0]?.reportCatalogueSQL
            }));
        }
    }, []);

    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const validations = (val) => {
        const {
            reportCatalogueName,
            tablesToUseList,
            reportCatalogueSQL,
            reportCatalogueColumnDisplayName,
            reportCatalogueColumnDatabaseName,
            reportCatalogueColumnFieldType,
            reportCatalogueColumnSelectionType,
            reportCatalogueColumnLength
        } = state;
        let formIsValid = true;
        let errors = {};
        let errorMessage = '';
        if (!val) {
            if (!reportCatalogueName) {
                errors.reportCatalogueName = 'Report Catalogue Name is required';
                errorMessage = errorMessage.concat(errors.reportCatalogueName) + '\n';
                formIsValid = false;
            }
            if (!tablesToUseList) {
                errors.tablesToUseList = 'Tables To Use is required';
                errorMessage = errorMessage.concat(errors.tablesToUseList) + '\n';
                formIsValid = false;
            }
            if (!reportCatalogueSQL) {
                errors.reportCatalogueSQL = 'reportCatalogueSQL  is required.';
                errorMessage = errorMessage.concat(errors.reportCatalogueSQL) + '\n';
                formIsValid = false;
            }
        }
        if (val) {
            if (!reportCatalogueColumnDisplayName) {
                errors.reportCatalogueColumnDisplayName = 'Column Name is required.';
                errorMessage = errorMessage.concat(errors.reportCatalogueColumnDisplayName) + '\n';
                formIsValid = false;
            }
            if (!reportCatalogueColumnDatabaseName) {
                errors.reportCatalogueColumnDatabaseName = 'Database Column is required.';
                errorMessage = errorMessage.concat(errors.reportCatalogueColumnDatabaseName) + '\n';
                formIsValid = false;
            }
            if (!reportCatalogueColumnFieldType) {
                errors.reportCatalogueColumnFieldType = 'Field Type is required.';
                errorMessage = errorMessage.concat(errors.reportCatalogueColumnFieldType) + '\n';

                formIsValid = false;
            }
            if (!reportCatalogueColumnSelectionType) {
                errors.reportCatalogueColumnSelectionType = 'Selection Type is required.';
                errorMessage = errorMessage.concat(errors.reportCatalogueColumnSelectionType) + '\n';
                formIsValid = false;
            }
            if (!reportCatalogueColumnLength) {
                errors.reportCatalogueColumnLength = 'Field Length is required.';
                errorMessage = errorMessage.concat(errors.reportCatalogueColumnLength) + '\n';
                formIsValid = false;
            }
        }
        setState((st) => ({ ...st, errors: errors }));
        if (errorMessage) {
            alert(errorMessage);
        }
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations(false)) {
            const data = {
                reportCatalogueID: state.reportCatalogueID,
                reportCatalogueName: state.reportCatalogueName,
                reportCatalogueSQL: state.reportCatalogueSQL,
                reportCatalogueColumnsVM: state.reportsList.map((m) => ({
                    reportCatalogueColumnDisplayName: m.reportCatalogueColumnDisplayName,
                    reportCatalogueColumnDatabaseName: m.reportCatalogueColumnDatabaseName,
                    reportCatalogueColumnFieldType: m.reportCatalogueColumnFieldType,
                    reportCatalogueColumnSelectionType: m.reportCatalogueColumnSelectionType,
                    reportCatalogueColumnLength: m.reportCatalogueColumnLength,
                    reportCatalogueColumnMandatory: m.reportCatalogueColumnMandatory
                })),
                reportCatalogueTablesVM: [
                    {
                        reportCatalogueTableName: state.tablesToUseList
                    }
                ]
            };
            try {
                setBtnLoader(true);
                let res = await addReportCatalogues(data);
                if (res.success) {
                    showToastSuccess(res.message);
                    props.onClose(true);
                }
                setBtnLoader(false);
            } catch (error) {
                setBtnLoader(false);
                showToastError(`An error occurred: ${error.message}`);
            }
        }
    };

    const onSelectCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st, [name]: checked };
            return nst;
        });
    };

    const handleAdd = () => {
        if (validations(true)) {
            let newPartToAdd = {
                reportCatalogueColumnDisplayName: state.reportCatalogueColumnDisplayName,
                reportCatalogueColumnDatabaseName: state.reportCatalogueColumnDatabaseName,
                reportCatalogueColumnFieldType: state.reportCatalogueColumnFieldType,
                reportCatalogueColumnSelectionType: state.reportCatalogueColumnSelectionType,
                reportCatalogueColumnLength: state.reportCatalogueColumnLength,
                reportCatalogueColumnMandatory: state.reportCatalogueColumnMandatory
            };
            setState((st) => ({
                ...st,
                reportsList: state.reportsList.filter((item) => item.id !== state.catlogId)
            }));
            setState((st) => {
                newPartToAdd.id = st.reportsList.length === 0 ? 1 : Math.max(...st.reportsList.map((c) => c.id)) + 1;
                return {
                    ...st,
                    reportsList: [...st.reportsList, newPartToAdd],
                    reportCatalogueColumnDisplayName: '',
                    reportCatalogueColumnDatabaseName: '',
                    reportCatalogueColumnFieldType: '',
                    reportCatalogueColumnSelectionType: '',
                    reportCatalogueColumnLength: '',
                    reportCatalogueColumnMandatory: true,
                    isUpdate: false,
                    catlogId: undefined
                };
            });
        }
    };

    const handleUpdatePart = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                id: params.id,
                reportCatalogueColumnDisplayName: params.reportCatalogueColumnDisplayName,
                reportCatalogueColumnDatabaseName: params.reportCatalogueColumnDatabaseName,
                reportCatalogueColumnFieldType: params.reportCatalogueColumnFieldType,
                reportCatalogueColumnSelectionType: params.reportCatalogueColumnSelectionType,
                reportCatalogueColumnLength: params.reportCatalogueColumnLength,
                reportCatalogueColumnMandatory: params.reportCatalogueColumnMandatory,
                catlogId: params.id,
                isUpdate: true
            };
            return nst;
        });
    };

    const handelClose = () => {
        props.onClose(false);
    };

    const dataFormatter = (data) => {
        return (
            <div>
                <EditButton onClick={() => handleUpdatePart(data.row)} toolTipTitle="Edit Report" />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);

    return (
        <div className="controller-screen">
            <Grid container spacing={1}>
                <Grid item xs={6} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Report Catalogue Name"
                        placeholder="Report Catalogue Name"
                        value={state.reportCatalogueName}
                        name="reportCatalogueName"
                        onChange={inputChange}
                        errors={state.errors.reportCatalogueName}
                    />
                </Grid>
                <Grid item xs={6} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Tables to use"
                        placeholder="Tables to use"
                        value={state.tablesToUseList}
                        name="tablesToUseList"
                        onChange={inputChange}
                        errors={state.errors.tablesToUseList}
                    />
                </Grid>
                <Grid item xs={12} sm={12} className="mandatory-fields">
                    <MandatoryField
                        multiline
                        rows={6}
                        inputLabel="SQL"
                        placeholder="SQL"
                        autoFocus={true}
                        value={state.reportCatalogueSQL}
                        name="reportCatalogueSQL"
                        onChange={inputChange}
                        errors={state.errors.reportCatalogueSQL}
                    />
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item xs={3} md={2} className="submit_btn">
                        <SecondaryButton isBtnLoader={btnLoader} onClick={handleSubmit}>
                            Submit
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={3} md={2} className="submit_btn">
                        <SecondaryButton onClick={handelClose}>Cancel</SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} md={4} lg={2} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Column Name"
                        placeholder="Column Name"
                        value={state.reportCatalogueColumnDisplayName}
                        name="reportCatalogueColumnDisplayName"
                        onChange={inputChange}
                        errors={state.errors.reportCatalogueColumnDisplayName}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Database Column"
                        placeholder="Database Column"
                        value={state.reportCatalogueColumnDatabaseName}
                        name="reportCatalogueColumnDatabaseName"
                        onChange={inputChange}
                        errors={state.errors.reportCatalogueColumnDatabaseName}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2} className="mandatory-fields">
                    <InputLabel required shrink>
                        Field Type
                    </InputLabel>
                    <SelectBox
                        name="reportCatalogueColumnFieldType"
                        value={state.reportCatalogueColumnFieldType || ''}
                        onChange={valueChanged}
                        List={state.reportCatalogueColumnFieldTypesList}
                    />
                    <FormHelperText error>{state.errors.reportCatalogueColumnFieldType}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2} className="mandatory-fields">
                    <InputLabel required shrink>
                        Selection Type
                    </InputLabel>
                    <SelectBox
                        name="reportCatalogueColumnSelectionType"
                        value={state.reportCatalogueColumnSelectionType || ''}
                        onChange={valueChanged}
                        List={state.reportCatalogueColumnSelectionTypesList}
                    />
                    <FormHelperText error>{state.errors.reportCatalogueColumnSelectionType}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Field Length"
                        type="number"
                        placeholder="Field Length"
                        value={state.reportCatalogueColumnLength}
                        name="reportCatalogueColumnLength"
                        onChange={inputChange}
                        errors={state.errors.reportCatalogueColumnLength}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2} lg={1} className="checkbox-font" style={{ marginTop: 8 }}>
                    <SecondaryCheckbox
                        onChange={onSelectCheckbox}
                        checked={state.reportCatalogueColumnMandatory}
                        name="reportCatalogueColumnMandatory"
                        value={state.reportCatalogueColumnMandatory}
                        label="Is Mandatory?"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2} lg={1} className="submit_btn">
                    <InputLabel>{'\u{2800}'}</InputLabel>
                    <SecondaryButton onClick={handleAdd} fullWidth className="submit_btn">
                        {state.catlogId ? 'Update' : 'ADD'}
                    </SecondaryButton>
                </Grid>
                <Grid item sm={12} md={12}>
                    <DataGrid rows={state.reportsList} offset={550} columns={columns} rowPerPage={9} paginate loadingData={state.showLoader} />
                </Grid>
            </Grid>
        </div>
    );
};

export default AddEditReportCatalogue;
