import { postWithAuth, getWithAuth, putWithAuth } from '../../../Basic/HttpService';
import { memoize } from 'lodash';

export const getAllDriver = async () => {
    let res = await getWithAuth('drivers');
    return res;
};

export const getDataById = async (Id) => {
    let res = await getWithAuth(`drivers/${Id}`);
    return res;
};
export const getDriverById = async (Id) => {
    let res = await getWithAuth(`drivers/${Id}`);
    return res;
};

export const getDriverScreenDropdowns = memoize(async () => {
    let res = await getWithAuth(`drivers/screenDropdowns`);
    return res;
});

export const addDriver = async (data) => {
    let res = await postWithAuth('drivers', data);
    return res;
};

export const updateDriver = async (id, data) => {
    let res = await putWithAuth(`drivers/${id}`, data);
    return res;
};
