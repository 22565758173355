import { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import './ButtonModal.scss';
import { makeStyles } from '@material-ui/core';

const AppButtonGroup = (props) => {
    const useStyles = makeStyles((theme) => ({
        btnContainer: {
            '& .app-select-btn-grp button.Mui-selected': {
                color: (props) => `${props.textColour || 'white'}`,
                backgroundColor: (props) => `${props.backgroundColour || '#53af50'}`
            }
        }
    }));

    const classes = useStyles(props.options.find((m) => m[props.valuePropertyName] === +props.value));

    const ConvertOps = (ops) => {
        return ops.map((op) => {
            return {
                id: `${op[props.valuePropertyName || 'id']}`,
                name: `${op[props.textPropertyName || 'name']}`
            };
        });
    };

    const [state, setState] = useState({
        value: `${props.value}`,
        options: ConvertOps(props.options) || []
    });

    useEffect(() => {
        setState((st) => ({ ...st, value: props.value }));
    }, [props.value]);

    useEffect(() => {
        props.options && setState((st) => ({ ...st, options: ConvertOps(props.options) }));
    }, [props.options]);

    const clickBtnGrup = (e, val) => {
        if (!props.disabled) {
            setState((st) => ({ ...st, value: val }));
            props.onChange && props.onChange({ [props.name]: val });
        }
    };

    return (
        <span style={{ display: 'flex' }} className={`app-btn-group ${classes.btnContainer}`}>
            <ToggleButtonGroup disabled={props.disabled} className="app-select-btn-grp" size="small" value={state.value} onChange={clickBtnGrup} exclusive>
                {state.options.map((op, i) => (
                    <ToggleButton key={i} value={op.id} style={{ width: props.width ? props.width : 'auto' }}>
                        {op.name}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </span>
    );
};

export default AppButtonGroup;
