import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, FormHelperText, InputLabel } from '@material-ui/core';
import { SecondaryCheckbox, InputText, SecondaryButton, MandatoryField } from '../../../../Core/Controls';
import { getMakeById, postMakeData } from '../../../../Core/Services/InternalUser/makeService';
import { useToast } from '../../../../Core/Hooks/useToast';
import '../../../commonStyle.scss';

const DialogStyle = {
    dialogInput: { width: '100%' },
    mandatoryLabel: { color: 'red' }
};

const VehicleManufacturerModel = (props) => {
    const [state, setState] = useState({ makeDescription: '', makeActive: true, makeLogo: '', makeID: null, errors: {} });

    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        if (props.makeID) {
            let res = await getMakeById(props.makeID);
            if (res.success) {
                setState((state) => ({
                    ...state,
                    makeID: res.data.makeID,
                    makeDescription: res.data.makeDescription,
                    makeActive: res.data.makeActive,
                    makeLogo: res.data.makeLogo
                }));
            } else {
                showToastError(res.message);
            }
        }
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((state) => ({ ...state, [name]: value }));
    };

    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    let handleImage = (event) => {
        let reader = new FileReader();
        reader.onload = (e) => {
            const { result } = e.target;
            setState((state) => {
                const nst = { ...state, makeLogo: result };
                return nst;
            });
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    const clearImage = () => {
        if (state.makeLogo) {
            setState((st) => ({ ...st, makeLogo: null }));
        }
    };

    const validations = () => {
        const { makeDescription } = state;
        let formIsValid = true;
        let errors = {};
        if (!makeDescription) {
            errors.makeDescription = 'Description is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const makeData = {
                makeID: state.makeID,
                makeDescription: state.makeDescription,
                makeLogo: state.makeLogo,
                makeActive: state.makeActive
            };
            let res = await postMakeData(makeData);
            if (res.success) {
                showToastSuccess(res.message);
                props.onClose(true);
            } else {
                showToastError(res.message);
                // props.onClose(false);
            }
        }
    };

    return (
        <Grid container direction="row" spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Description"
                    name="makeDescription"
                    value={state.makeDescription}
                    onChange={handleInputChange}
                    errors={state.errors.makeDescription}
                />
            </Grid>
            <Grid item xs={12}>
                <InputLabel shrink>Logo </InputLabel>
                {state.makeLogo ? (
                    <SecondaryButton
                        onClick={clearImage}
                        style={{
                            width: '25%',
                            textTransform: 'capitalize'
                        }}
                    >
                        Remove Logo
                    </SecondaryButton>
                ) : (
                    <input type="file" name="makeLogo" onChange={handleImage} accept="image/*" />
                )}
            </Grid>
            <Grid item xs={12}>
                <SecondaryCheckbox checked={state.makeActive} name="makeActive" onChange={handleCheckbox} label="Is Active ?" />
            </Grid>
            <Grid item xs={12} className="submit_btn">
                <SecondaryButton onClick={submitHandler}>{props.makeID ? 'Update' : 'Add'}</SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default VehicleManufacturerModel;
