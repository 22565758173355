import React, { useEffect, useMemo, useState } from 'react';
import AddEditDefectModal from './addEditDefectScreen';
import DataGrid from '../../../../Core/Controls/Grid';
import { getAllDefects, getAllVehicleDefects, getAllCustomerDefects } from '../../../../Core/Services/InternalUser/Maintenance/DefectsService';
import { getVechicleIDByRegNO } from '../../../../Core/Services/InternalUser/ServiceAgent/breakdownService';
import { EditButton, BreadCrumbs, formatters } from '../../../../Core/Controls';
import WorkIcon from '@material-ui/icons/Work';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { Link } from 'react-router-dom';
import DefectEditDetails from './DefectEditDetails';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { InputText, SecondaryButton, MandatoryField, SimpleField, Accordion, SingleSelect } from '../../../../Core/Controls';
import Grid from '@material-ui/core/Grid';
import BuildIcon from '@material-ui/icons/Build';
import { VideogameAssetOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import CustomerDetails from '../../Controllers/DetailsModal/customerDetails';
import ServiceAgentDetails from '../../Controllers/DetailsModal/serviceAgent';
import ReactDOM from 'react-dom';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const modalgridStyle = {
    formatterEditBtn: { textAlign: 'center' }
};

const baseCrumbs = [
    { name: 'Home', path: '/' },
    { name: 'Maintenance', active: true },
    { name: 'Defects', path: '/', active: true }
];

const baseColumns = [
    { key: 'action', name: 'Action', width: 120, addFileUploaderIcon: true },
    {
        key: 'defectNumberGenerated',
        name: 'Defect No',
        headerRenderer: formatters.ColumnHeaderAlignLeft,
        formatter: formatters.ColumnTextAlignLeft,
        width: '6%'
    },
    { key: 'agent', name: 'Service Area', width: '10%' },
    {
        key: 'eta',
        name: 'ETA',
        formatter: formatters.Date_DDMMYYYY,
        width: '7%'
    },
    {
        key: 'defectDate',
        name: 'Date ',
        formatter: formatters.Date_DDMMYYYY,
        width: '7%'
    },

    { key: 'defectDescription', name: 'Details' },
    { key: 'defectContactName', name: 'Reported By', width: '8%' },
    { key: 'status', name: 'Status', width: '15%' }
    // { key: 'defectDriverName', name: 'Driver Name', width: 110 },
    // { key: 'defectDriverTelephone', name: 'Driver Phone', width: 120 },
    // { key: 'defectType', name: 'Defect Type', width: 130 }
];

const DefectsScreen = (props) => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const [state, setState] = useState({
        modalToggle: false,
        rowVehicleID: null,
        defectID: null,
        List: [],
        showLoader: true,
        fromPage: 'Defects',
        show: false,
        filterVal: ''
    });
    let history = useHistory();
    const { userRoles } = useLoginInfo();
    const isDriver = userRoles.includes('driver');

    const handleFilter = (e) => {
        const { value } = e.target;
        setState((st) => ({ ...st, filterVal: value }));
    };

    useEffect(async () => {
        if (!props.VehicleID) {
            if (state.filterVal.length > 4) {
                let resBrekDown = await getVechicleIDByRegNO(state.filterVal);
                setState((st) => {
                    let newSt = { ...st };
                    if (resBrekDown.data?.list) {
                        newSt.show = resBrekDown.success;
                        newSt.filterVehicleID = resBrekDown.data?.list[0].vehicleID;
                    } else {
                        newSt.show = false;
                    }

                    return newSt;
                });
            } else {
                setState((st) => ({ ...st, show: false }));
            }
        }
        if (props.frompage === 'Vehicles') {
            setState((st) => ({
                ...st,
                fromPage: props.frompage
            }));
        }
        // pullDefects();
    }, [props.VehicleID, state.filterVal]);

    const pullDefects = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };
    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.defectJobID}/${val?.defectVehicleID}`,
            state: 'defects'
        });
    };
    const handleEditDefect = (val) => {
        history.push({
            pathname: `/maintenance/defect/edit/${val?.defectID}/${val?.defectVehicleID}`,
            state: 'Edit'
        });
    };

    const renderEdit = (data, row) => {
        return (
            <Grid item>
                <CustomIconButton
                    icon={BuildIcon}
                    color="secondary"
                    className="modal-btn-update btn_space"
                    // onClick={() => handleUpdate(row, null, 'modalEditDetail')}
                    onClick={() => handleEditDefect(row)}
                    toolTipTitle="Edit Defect"
                />
                {/* <EditButton toolTipTitle="Edit Defect" onClick={() => handleUpdate(row, null, 'modalEditDetail')} /> */}
                {/* {isDriver ? null : (
                    <CustomIconButton
                        icon={WorkIcon}
                        color="secondary"
                        className="modal-btn-update btn_space"
                        onClick={() => handleEditClick(row)}
                        toolTipTitle="Job Details"
                    />
                )} */}
            </Grid>
        );
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.defectRegNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.serviceAgentName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customer}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.defectVehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showSaModal: false,
            showCustomerModal: false,
            showDepotModal: false
        }));
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        let agentNameCol = tempCols.find((element) => element.key === 'serviceAgentName');
        if (agentNameCol) {
            agentNameCol.formatter = renderAgentName;
        }
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        if (!props.VehicleID) {
            if (!props.isCust) {
                tempCols.splice(2, 0, { key: 'customer', name: 'Customer Name', width: '8%', formatter: renderCustomerName });
            }

            tempCols.splice(1, 0, { key: 'defectRegNumber', name: 'Reg No', width: '8%', formatter: renderRegNumberEdit });
        }
        // if (window.innerWidth > 600) {
        //     tempCols.splice(
        //         6,
        //         0,
        //         { key: 'defectDriverName', name: 'Driver Name', width: 110 },
        //         { key: 'defectDriverTelephone', name: 'Driver Phone', width: 120 }
        //     );
        //     // tempCols.splice(1, 0, { key: 'defectType', name: 'Defect Type', width: 130 });
        // }
        return tempCols;
    }, []);
    const inputChange = (e) => {
        const value = e.target.value;
        setState((st) => ({
            ...st,
            filterVal: value
        }));
    };

    const crumbs = useMemo(() => {
        const tempCrumbs = [...baseCrumbs];
        if (state.modalEditDetail) {
            tempCrumbs.splice(3, 0, { name: `Defect Number ${state.defectNumberGenerated} [${formatters.DateTimeFormatter(state.defectDate)}]`, active: true });
        }
        return tempCrumbs;
    }, [state.modalToggle]);

    const handleUpdate = (data, vehicleId, modalName) => {
        setState((st) => {
            let newSt = { ...st };
            if (data) {
                newSt.defectID = data.defectID;
                newSt.rowVehicleID = data.defectVehicleID;
                newSt.defectNumberGenerated = data.defectNumberGenerated;
                newSt.defectDate = data.defectDate;
            }
            if (vehicleId) {
                newSt.rowVehicleID = vehicleId;
            }
            newSt[modalName] = true;

            return newSt;
        });
    };

    const closeModalHandler = (res, modalName) => {
        setState((state) => ({ ...state, [modalName]: false, show: false, filterVal: false }));
        res && pullDefects();
    };

    let urlForcondition = `VehicleDefects${props.VehicleID ? `?VehicleID=${props.VehicleID}` : ''}${props.customerID ? `?customerID=${props.customerID}` : ''}`;

    const gridBtnTitle = state.show || props.VehicleID ? 'Add Defect' : null;
    const isPage = !!(props.VehicleID || props.customerID);
    return (
        <div className={!isPage ? `screen` : ''} style={{ marginTop: 8 }}>
            {state.modalToggle ? (
                <AddEditDefectModal
                    defectID={state.defectID}
                    VehicleID={props.VehicleID || state.rowVehicleID}
                    onClose={() => closeModalHandler(true, 'modalToggle')}
                    fromPage={state.fromPage}
                />
            ) : state.modalEditDetail ? (
                <DefectEditDetails
                    defectID={state.defectID}
                    VehicleID={props.VehicleID || state.rowVehicleID}
                    // onClose={() => closeModalHandler(true, 'modalEditDetail')}
                    // fromPage={state.fromPage}
                    // pullDetails={props.pullDetails}
                />
            ) : (
                <>
                    {!isPage && (
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center', position: 'relative', top: 0 }}>
                            <div style={{ width: 'auto' }}>
                                <BreadCrumbs crumbs={crumbs} className="edit-service-agent-btn" />
                            </div>
                            {/* &nbsp;&nbsp;
                            {checkhaveAccess.CanAddDefects && (
                                <div style={{ width: 'auto' }}>
                                    <input type="text" onChange={inputChange} placeholder="Enter Reg Number" style={{ padding: 5 }} />
                                </div>
                            )} */}
                            {gridBtnTitle && (
                                <div style={{ marginLeft: '4px' }}>
                                    <SecondaryButton
                                        style={{ padding: '2px 16px' }}
                                        onClick={() => handleUpdate(null, state.filterVehicleID, 'modalToggle')}
                                        fullWidth
                                        // className="submit_btn"
                                    >
                                        Add
                                    </SecondaryButton>
                                </div>
                            )}
                        </div>
                    )}
                    <GijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={columns}
                        getUrl={urlForcondition}
                        // btnTitle={gridBtnTitle}
                        idFieldName={`${checkhaveAccess?.CanUploadDefectDocuments ? 'defectID' : null}`}
                        fileUploaderConfigName={`${checkhaveAccess?.CanUploadDefectDocuments ? 'DefectScreen' : null}`}
                        // onBtnClick={() => handleUpdate(null, state.filterVehicleID, 'modalToggle')}
                        isReload={state.isReload}
                    />
                </>
            )}
            {state.showSaModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.serviceAgentID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default DefectsScreen;
