import {
    Button,
    ButtonGroup,
    FormHelperText,
    Grid,
    InputLabel,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    withStyles
} from '@material-ui/core';
import React, { useState } from 'react';
import { formatters, NoButton, PrimaryButton, SecondaryButton, YesButton } from '../../../../../../Core/Controls';
import ConfirmationModal from '../../../../../../Core/Modal/ConfirmationModal';
import DepotFilterList from '../../../../../../Core/Controls/LocationField/depotFilterList';
import { Alert } from '@material-ui/lab';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#F5F5F5'
        }
        // cursor: 'pointer'
    }
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 12,
        fontWeight: 700,
        padding: 1,
        backgroundColor: 'skyblue'
    },
    body: {
        fontSize: 14,
        padding: 1
    }
}))(TableCell);

export const JobStatusHandler = ({ onClose, list, depotList, submitSendForApproval, isBtnLoader, state, setState, handleStockMail }) => {
    const [ErrorMessage, setErrorMessage] = useState('');

    const getDepotsValue = (val) => {
        try {
            setState((st) => {
                let newSt = { ...st };
                if (val.id) {
                    newSt.depotID = val.id;
                    newSt.depotFilterName = val.name;
                } else {
                    newSt.depotID = val.depotID;
                    newSt.depotFilterName = `${val.depotName} [${val.depotAccountCode || 'N/A'}](${val.depotAddress1}, ${val.depotTown} , ${
                        val.depotPostCode
                    })`;
                }
                return newSt;
            });
        } catch (error) {
            console.log(error);
        }
    };

    const clearVal = () => {
        try {
            setState((st) => {
                let newSt = { ...st };

                newSt.depotID = null;
                newSt.depotFilterName = '';

                return newSt;
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    console.log(list, 'list');

    const isExpressOrder = list.some(
        (item) => (item.status === 'E' || item.tempstatus === 'E') && !item.isDeleted && (!item.vehicleJobPartID || item.isStatusUpdable)
    );
    const isFitted = list.some(
        (item) => (item.status === 'I' || item.tempstatus === 'I') && !item.isDeleted && (!item.vehicleJobPartID || item.isStatusUpdable)
    );
    const isStock = list.some(
        (item) => (item.status === 'S' || item.tempstatus === 'S') && !item.isDeleted && (!item.vehicleJobPartID || item.isStatusUpdable)
    );

    let alertMessage = '';

    if (isFitted && isStock && isExpressOrder) {
        alertMessage =
            'Please note that the fitted and stock order parts will be delivered to the selected warehouse as below and express order parts will be delivered to the depot selected above.';
    } else if (isFitted && isStock) {
        alertMessage = 'Please note that the fitted and stock order parts will be delivered to the selected warehouse as below.';
    } else if (isFitted && isExpressOrder) {
        alertMessage =
            'Please note that the fitted parts will be delivered to the selected warehouse as below and express order parts will be delivered to the depot selected above.';
    } else if (isStock && isExpressOrder) {
        alertMessage =
            'Please note that the stock order parts will be delivered to the selected warehouse as below and express order parts will be delivered to the depot selected above.';
    } else if (isFitted) {
        alertMessage = 'Please note that the fitted parts will be delivered to the selected warehouse as below.';
    } else if (isStock) {
        alertMessage = 'Please note that the stock order parts will be delivered to the selected warehouse as below.';
    } else if (isExpressOrder) {
        alertMessage = 'Please note that the express order parts will be delivered to the depot selected above.';
    } else alertMessage = 'Are you sure you want to submit?';

    const isUpdate = list.some((item) => !item.vehicleJobPartID || item.isStatusUpdable);

    const valid = () => {
        if (isExpressOrder) {
            if (state.depotFilterName) {
                submitSendForApproval();
            } else {
                setErrorMessage('Please ensure you have selected the express delivery depot');
            }
        } else {
            submitSendForApproval();
        }
    };

    console.log(state, 'statessssssss');

    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                <>
                    {isExpressOrder ? (
                        <>
                            <Grid item xs={12}>
                                <InputLabel shrink error>
                                    Express Delivery Depot
                                </InputLabel>
                                <DepotFilterList
                                    onChange={handleInputChange}
                                    name="depotFilterName"
                                    value={state.depotFilterName}
                                    options={depotList}
                                    getVal={getDepotsValue}
                                    clearValue={clearVal}
                                />
                                <FormHelperText shrink error>
                                    *Please Select a depot to deliver express Parts
                                </FormHelperText>
                            </Grid>
                            {/* {state.depotID && (
                                <Grid item xs={12}>
                                    <Alert severity="info">Parts will be delivered to the express delivery depot selected above</Alert>
                                </Grid>
                            )} */}
                        </>
                    ) : null}
                    {alertMessage && (
                        <Grid item xs={12}>
                            <Alert severity="info">{alertMessage}</Alert>
                        </Grid>
                    )}
                    {ErrorMessage && (
                        <Grid item xs={12}>
                            <Alert severity="warning">{ErrorMessage}</Alert>
                        </Grid>
                    )}

                    {isUpdate && (
                        <Grid item xs={12}>
                            <TableContainer style={{ minHeight: 300, overflow: 'auto', maxWidth: '100%' }}>
                                <Table size="small" stickyHeader>
                                    <TableHead>
                                        <TableRow className="header">
                                            <StyledTableCell width={'50%'}>Part</StyledTableCell>
                                            <StyledTableCell width={'10%'} align="right">
                                                Quantity
                                            </StyledTableCell>
                                            <StyledTableCell width={'20%'} align="right">
                                                Warehouse
                                            </StyledTableCell>
                                            <StyledTableCell width={'20%'} align="right">
                                                Status
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {list
                                            ?.filter((k) => !k.isDeleted)
                                            .map((j, i) => {
                                                if (!j.vehicleJobPartID || j.isStatusUpdable) {
                                                    return (
                                                        <StyledTableRow>
                                                            <StyledTableCell>
                                                                {' '}
                                                                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                                                    <div style={{ fontSize: 14, fontWeight: 500 }}>{j.partNumber} &nbsp;</div>(
                                                                    <div style={{ fontSize: 12, fontStyle: 'italic' }}>{j.part}</div>)
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="right">{j.quantity}</StyledTableCell>
                                                            <StyledTableCell align="right">{j.warehouseDesc}</StyledTableCell>
                                                            <StyledTableCell align="right">{j.statusDesc}</StyledTableCell>
                                                        </StyledTableRow>
                                                    );
                                                }
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                </>

                <Grid item xs={6}>
                    <SecondaryButton onClick={valid} isBtnLoader={isBtnLoader}>
                        Submit{' '}
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton onClick={onClose}>Cancel </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};
