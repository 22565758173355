import React from 'react';
import { BreadCrumbs, TabView } from '../../../../Core/Controls';
import DriverDashBoard from './driverDashboard';
import NoteDashboard from './../Notes/index';
import { LicenceChecks } from './../LicenceCheck';
import { AppStorage } from '../../../../Core/Basic/storage-service';
export default function EditDriver(props) {
    const id = props.match.params.id;
    const name = props.match.params.name;

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Drivers', path: '/controller/driver' },
        { name: `${props.location.name}`, path: '/', active: true }
    ];
    let checkhaveAccess = AppStorage.getAllowAcces();
    const tabs = [
        {
            label: 'Dashboard',
            body: <DriverDashBoard driverID={id} />
        },
        ...(checkhaveAccess?.CanViewNotes
            ? [
                  {
                      label: 'Notes',
                      body: <NoteDashboard frompage="Drivers" NoteDriverID={id} noteRefsID={id} />
                  }
              ]
            : []),
        ...(checkhaveAccess?.CanDoDriverLicenceChecks
            ? [
                  {
                      label: 'Licence Checks ',
                      body: <LicenceChecks frompage="Drivers" driverID={id} noteRefsID={id} />
                  }
              ]
            : [])
    ];

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} className="edit-vehicle-btn" m={5} />
            <TabView tabList={tabs} id="testing_tabs" />
        </div>
    );
}
