import { postWithAuth, getWithAuth, putWithAuth } from '../../Basic/HttpService';

export const postMessageToAllCustomers = async (data) => {
    let res = await postWithAuth('CustomerMessages/SendMessageAllCustomers', data);
    return res;
};

export const getMessageByID = async (id) => {
    let res = await getWithAuth(`JobMessages?JobID=${id}`);
    return res;
};

export const sendMessage = async (id, message) => {
    let res = await postWithAuth('JobMessages/SendMessage', {
        ID: id,
        Message: message
    });
    return res;
};