import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { InputText, SecondaryButton, DatePicker, SingleSelect } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { ContractSetCollected, ContractsStepper_DropDown } from '../../../../../Core/Services/InternalUser/InternalDashService';

import moment from 'moment';

const ContractSetCollectedModal = (props) => {
    const [state, setState] = useState({
        errors: {},
        contractServiceAreaID: null,
        contractEngineerUserID: null,
        contractDeliveryDate: moment().format('YYYY-MM-DD'),
        contractDeliveryCharge: null,
        companies: [],
        transportCompanyID: null,
        transportCompanyID: null
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await ContractsStepper_DropDown();
                let selectedTransportCompany = null;

                if (props.contractDeliveryTransportCompanyID) {
                    selectedTransportCompany = res.data.companies.find((q) => q.id === props.contractDeliveryTransportCompanyID);
                }

                setState((st) => ({
                    ...st,
                    companies: res.data.companies || [],
                    serviceAgents: res.data.serviceAgents || [],
                    technicians: res.data.technicians || [],
                    transportCompanyID: selectedTransportCompany
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [props.contractDeliveryTransportCompanyID]); // Include dependency here if you want to rerun the effect on prop change

    const onFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => ({ ...st, [nm]: val }));
    };

    const validations = () => {
        const { contractDeliveryDate, contractDeliveryCharge, transportCompanyID, contractServiceAreaID, contractEngineerUserID } = state;
        let formIsValid = true;
        let errors = {};
        if (!contractDeliveryDate) {
            errors.contractDeliveryDate = 'Date is required';
            formIsValid = false;
        }
        // if (!contractDeliveryCharge) {
        //     errors.contractDeliveryCharge = 'Delivery Charge is required';
        //     formIsValid = false;
        // }
        if (!transportCompanyID?.id) {
            errors.transportCompanyID = 'Company is required';
            formIsValid = false;
        }
        // if (!contractServiceAreaID?.id) {
        //     errors.contractServiceAreaID = 'Service area is required';
        //     formIsValid = false;
        // }
        // if (!contractEngineerUserID?.id) {
        //     errors.contractEngineerUserID = 'Engineer is required';
        //     formIsValid = false;
        // }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const SubmitHandler = async () => {
        if (validations()) {
            setBtnLoader(true);
            let totalVehicles = props.checkedVehicleList.length;
            let failedResponses = 0;

            for (let index = 0; index < totalVehicles; index++) {
                try {
                    let response = await ContractSetCollected({
                        contractVehicleID: props.checkedVehicleList[index],
                        contractDeliveryDate: state.contractDeliveryDate,
                        transportCompanyID: state.transportCompanyID?.id
                    });

                    // Display the counter n of n for each vehicle
                    showToastSuccess(`Vehicle ${index + 1} of ${totalVehicles}: ${response.success ? 'Success' : 'Failed'}`);

                    if (!response.success) {
                        failedResponses += 1;
                    }
                } catch (error) {
                    // Handle the error for the specific vehicle
                    showToastError(`An error occurred for vehicle ${index + 1}: ${error.message}`);
                    failedResponses += 1;
                }
            }

            // Final success or failure message after all API calls
            if (failedResponses === 0) {
                showToastSuccess('All vehicle delivery details saved successfully.');
                props.onClose(true);
            } else {
                showToastError(`${failedResponses} vehicle(s) failed.`);
            }

            setBtnLoader(false);
        }
    };

    // const SubmitHandler = async () => {
    //     if (validations()) {
    //         setBtnLoader(true);
    //         let totalVehicles = props.checkedVehicleList.length;

    //         let promises = props.checkedVehicleList.map((q, index) => {
    //             return ContractSetCollected({
    //                 contractVehicleID: q,
    //                 contractDeliveryDate: state.contractDeliveryDate,
    //                 transportCompanyID: state.transportCompanyID?.id
    //             }).then((response) => {
    //                 // Display the counter n of n for each vehicle
    //                 showToastSuccess(`Vehicle ${index + 1} of ${totalVehicles}: ${response.success ? 'Success' : 'Failed'}`);
    //                 return response;
    //             });
    //         });

    //         try {
    //             let responses = await Promise.all(promises);

    //             // Check if all responses are successful
    //             let allSuccessful = responses.every((response) => response.success);

    //             if (allSuccessful) {
    //                 showToastSuccess('All vehicle delivery details saved successfully.');
    //                 props.onClose(true);
    //             } else {
    //                 // Find out which responses failed
    //                 let failedResponses = responses.filter((response) => !response.success);
    //                 showToastError(`Error: ${failedResponses.length} items failed.`);
    //             }
    //         } catch (error) {
    //             // Handle any other errors that may occur
    //             showToastError(`An error occurred: ${error.message}`);
    //         }

    //         setBtnLoader(false);
    //     }
    // };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Collection Date</InputLabel>
                <DatePicker name="contractDeliveryDate" placeholder="Delivery Date" value={state.contractDeliveryDate || ''} onChange={onFieldChange} />
                <FormHelperText error>{state.errors.contractDeliveryDate}</FormHelperText>
            </Grid>
            {/* <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Service Area</InputLabel>
                <SingleSelect options={state.serviceAgents} value={state.contractServiceAreaID} onChange={singleSelectChange('contractServiceAreaID')} />
                <FormHelperText error>{state.errors.contractServiceAreaID}</FormHelperText>
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Engineer</InputLabel>
                <SingleSelect options={state.technicians} value={state.contractEngineerUserID} onChange={singleSelectChange('contractEngineerUserID')} />
                <FormHelperText error>{state.errors.contractEngineerUserID}</FormHelperText>
            </Grid> */}
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Transport Company</InputLabel>
                <SingleSelect options={state.companies} value={state.transportCompanyID} onChange={singleSelectChange('transportCompanyID')} />
                <FormHelperText error>{state.errors.transportCompanyID}</FormHelperText>
            </Grid>

            <Grid item xs={12}>
                <SecondaryButton isBtnLoader={btnLoader} fullWidth className="submit_btn" onClick={SubmitHandler}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default ContractSetCollectedModal;
