import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TableRow, TableCell } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';

export const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 16,
        padding: '2px 8px',
        color: '#f50057'
    },
    body: {
        fontSize: 14,
        padding: '2px 8px',
        '&.MuiTableCell-root': {
            verticalAlign: 'top !important'
        }
    }
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#F5F5F5'
        }
    }
}))(TableRow);
