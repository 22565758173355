import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { getAllCustomerDashboard } from '../../../Core/Services/Customer/customerDashService';
import { Link } from 'react-router-dom';
import LocalTaxiRoundedIcon from '@material-ui/icons/LocalTaxiRounded';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CheckIcon from '@material-ui/icons/Check';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import BuildIcon from '@material-ui/icons/Build';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import '../CustomerDash.scss';
import ReactSpeedometer from 'react-d3-speedometer';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { makeStyles } from '@material-ui/core/styles';
import DashboardCard from '../../InternalUser/Dashboard/dashboardCard';
import { getAllDashboardWidgets } from '../../../Core/Services/dasboardWidgetsService';
import { FromLoader } from '../../../Core/Loader/formLoader';
import ClearIcon from '@material-ui/icons/Clear';
import ErrorIcon from '@material-ui/icons/Error';
import { SecondaryButton } from '../../../Core/Controls';
import DialogComp from '../../../Core/Dialog/DialogComp';
import WorkIcon from '@material-ui/icons/Work';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { Switch } from '@material-ui/core';
import { AppStorage } from '../../../Core/Basic/storage-service';

const useStyles = makeStyles({
    root: {
        width: '90%'
    }
});

let gridStyle = {
    1: 12,
    2: 6,
    3: 3
};

let redColor = '#ff4757';
let redShadowColor = 'rgb(255 32 54 / 53%)';
let yellowColor = '#ffc312';
let yellowShadowColor = 'rgba(255, 236, 30, 0.75)';
let purpleColor = '#7d38df';
let purpleShadowColor = 'rgba(200, 61, 255, 0.596)';
let greenColor = '#009432';
let greenShadowColor = 'rgba(0, 148, 50, 0.53)';
let blueColor = '#1127ee';
let blueShadowColor = '#909bf7';

const CustomerDashobard = () => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const [state, setState] = useState({
        rows_new: [],
        tilesData: [],
        widgetList: [],
        showFilter: false
    });

    const [checked, setChecked] = useState({});

    const [widgets, setWidgets] = useState({});
    let DowntimeDash = [
        {
            Icon: LocalTaxiRoundedIcon,
            numberIcon: state.tilesData.breakdownPercent,
            title: state.tilesData.breakdowns || 0,
            subTittle: 'Breakdown',
            background: redColor,
            showDowBackground: redShadowColor,
            path: checkhaveAccess?.CanViewBreakdowns ? '/maintenance/breakdowns/:all' : ''
        },
        {
            Icon: LocalTaxiRoundedIcon,
            numberIcon: state.tilesData.defectPercent,
            title: state.tilesData.defects || 0,
            subTittle: 'Defects',
            background: redColor,
            showDowBackground: redShadowColor,
            path: checkhaveAccess?.CanViewDefects ? '/maintenance/defects' : ''
        },
        {
            Icon: LocalTaxiRoundedIcon,
            numberIcon: state.tilesData.vorPercent,
            title: state.tilesData.vor || 0,
            subTittle: 'VOR',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: checkhaveAccess?.CanViewVOR ? {
                pathname:  '/controller/vor',
                query: { FromDash: true }
            }:''
        }
    ];

    let MaintananceEvent = [
        {
            id: 5,
            Icon: LocalTaxiRoundedIcon,
            title: state.tilesData.serviceCount || 0,
            subTittle: 'Services',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/maintenance/schedules/S'
        },
        {
            id: 6,
            Icon: DirectionsCarIcon,
            title: state.tilesData.motCount || 0,
            subTittle: 'MOTs',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/maintenance/schedules/M'
        },
        {
            id: 7,
            Icon: BuildIcon,
            title: state.tilesData.inspectionCount || 0,
            subTittle: 'Inspections',
            background: redColor,
            showDowBackground: redShadowColor,
            path: '/maintenance/schedules/I'
        },
        {
            id: 8,
            Icon: FormatListBulletedIcon,
            title: state.tilesData.otherCount || 0,
            subTittle: 'Others',
            background: purpleColor,
            showDowBackground: purpleShadowColor,
            path: '/maintenance/schedules/O'
        }
    ];

    let FleetDash = [
        {
            Icon: AttachMoneyIcon,

            title: state.tilesData.taxCount || 0,
            subTittle: 'Tax',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/controller/Vehicles/T'
        },
        {
            Icon: CreditCardIcon,

            title: state.tilesData.insuranceCount || 0,
            subTittle: 'Insurance',
            background: purpleColor,
            showDowBackground: purpleShadowColor,
            path: '/controller/Vehicles/I'
        },
        {
            Icon: CheckIcon,

            title: state.tilesData.complianceCount || 0,
            subTittle: 'Compliance',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/schedule/outstandingdocuments'
        }
    ];

    let FleetSchedule = [
        {
            Icon: ErrorIcon,
            title: state.tilesData.bookingsRequested || 0,
            subTittle: 'Bookings Requested',
            background: yellowColor,
            showDowBackground: yellowShadowColor,
            path: '/schedule/bookingsrequsted'
        },
        {
            Icon: CheckIcon,
            title: state.tilesData.bookingsAccepted || 0,
            subTittle: 'Bookings Accepted',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/schedule/acceptedbyagents'
        },

        {
            Icon: ClearIcon,

            title: state.tilesData.bookingsRejected || 0,
            subTittle: 'Bookings Rejected',
            background: redColor,
            showDowBackground: redShadowColor,
            path: '/schedule/bookingsrejected'
        },
        {
            Icon: BuildIcon,
            title: state.tilesData.bookingsConfirmed || 0,
            subTittle: 'Bookings Confirmed',
            background: redColor,
            showDowBackground: redShadowColor,
            path: '/schedule/confirmedbookings'
        }
    ];

    let DriverCheck = [
        {
            Icon: ClearIcon,
            title: state.tilesData.dailyChecksFailed || 0,
            subTittle: 'Failed',
            background: redColor,
            showDowBackground: redShadowColor,
            path: '/customerDailyChecks/failed'
        },
        {
            Icon: ErrorIcon,
            title: state.tilesData.dailyChecksMonitored || 0,
            subTittle: 'Monitor',
            background: yellowColor,
            showDowBackground: yellowShadowColor,
            path: '/customerDailyChecks/monitor'
        },

        {
            Icon: CheckIcon,

            title: state.tilesData.dailyChecksPassed || 0,
            subTittle: 'Passed',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/customerDailyChecks/passed'
        },
        {
            Icon: BuildIcon,
            title: state.tilesData.dailyChecksUnreviewed || 0,
            subTittle: 'Unreviewed',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/customerDailyChecks/unreviwed'
        }
    ];

    let Jobs = [
        {
            Icon: CheckIcon,
            total: state.tilesData.jobsApprovedTotal || 0,
            title: state.tilesData.jobsApproved || 0,
            subTittle: 'Approved',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/serviceagent/CA'
        },
        {
            Icon: CheckIcon,
            total: state.tilesData.jobsInQueryTotal || 0,
            title: state.tilesData.jobsInQuery || 0,
            subTittle: 'In Query',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/serviceagent/CQ'
        },
        {
            Icon: CheckIcon,
            title: state.tilesData.jobsAwaitingApprovals || 0,
            total: state.tilesData.jobsAwaitingApprovalTotal || 0,
            subTittle: 'Awaiting Approvals',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/serviceagent/AR'
        }
    ];
    let ReportList = [
        {
            button: true,
            Icon: DashboardIcon,
            background: blueColor,
            showDowBackground: blueShadowColor,
            title: 'VOR',
            subTittle: ' Reporting',

            path: '/controller/VorReporting/'
        },
        {
            button: true,
            Icon: DashboardIcon,
            background: blueColor,
            showDowBackground: blueShadowColor,
            title: 'Defect',
            subTittle: 'Reporting',
            path: '/maintenance/defect/reporting/'
        },
        {
            button: true,
            Icon: DashboardIcon,
            background: blueColor,
            showDowBackground: blueShadowColor,
            title: 'Breakdown',
            subTittle: 'Reporting ',
            path: '/maintenance/breakdown/reporting/'
        }
        // {
        //     button: true,
        //     Icon: DashboardIcon,
        //     background: blueColor,
        //     showDowBackground: blueShadowColor,
        //     title: 'Job',
        //     subTittle: ' Reporting ',
        //     path: '/job/job_Reporting'
        // }
    ];

    let setList = {
        9: DowntimeDash,
        10: MaintananceEvent,
        14: FleetDash,
        11: FleetSchedule,
        12: Jobs,
        13: DriverCheck,
        16: ReportList
    };

    useEffect(async () => {
        let res = await getAllCustomerDashboard();
        const cards = res.data.filterdCustomer;
        pullDashboardData();
        setState((st) => ({
            ...st,
            tilesData: cards[0]
        }));
    }, []);

    const pullDashboardData = async () => {
        let dashRes = await getAllDashboardWidgets('customerdashboard');
        setState((st) => ({
            ...st,
            widgetList: dashRes?.data.displayStyles
        }));
    };

    function isNullCheck(val) {
        if (val) {
            return val;
        }
        return '0';
    }

    const handleFilter = () => {
        setState((st) => ({
            ...st,
            showFilter: !st.showFilter
        }));
    };

    useEffect(() => {
        var b = localStorage.getItem('_dashboard_');
        let val = JSON.parse(b);
        if (val) {
            setWidgets((st) => ({
                ...st,
                Grid_1: val?.gridObj.Grid_1,
                Grid_2: val?.gridObj.Grid_2,
                Grid_3: val?.gridObj.Grid_3,
                buttonID: val?.buttonID || 1
            }));
        }
    }, []);

    const PopUpCust = () => {
        const handleChange = (e) => {
            const { name, checked } = e.target;

            setChecked((st) => ({
                ...st,
                [name]: checked
            }));
        };

        return (
            <Grid container spacing={2} justify="flex-start">
                {state.widgetList &&
                    state.widgetList.map((m) => (
                        <Grid item container xs={6}>
                            <Grid item xs={6}>
                                <Typography varient="h6">{m.widgetName}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Switch
                                    checked={checked[m.widgetName]}
                                    onChange={handleChange}
                                    name={m.widgetName}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            </Grid>
                        </Grid>
                    ))}
                <Grid item xs={12}>
                    <SecondaryButton onClick={handleFilter}>Submit</SecondaryButton>
                </Grid>
            </Grid>
        );
    };

    return (
        <div className="customer-dashboard">
            <Grid container spacing={1}>
                {/* <Grid item xs={12}>
                    <SecondaryButton onClick={handleFilter}>Widget Filter</SecondaryButton>
                </Grid> */}
                {state.widgetList?.length > 0 ? (
                    state.widgetList.map((m, i) => {
                        let gs = gridStyle[m.displayStyleID];
                        let list = setList[m.widgetUsedID];
                        return (
                            <Grid item xs={12} sm={12} md={gs === 3 ? 6 : gs} lg={gs === 3 ? 6 : gs} style={{ padding: '6px 8px' }} xl={gs} key={i}>
                                <DashboardCard
                                    list={list || []}
                                    themeID={m.themeID}
                                    heading={m.widgetName}
                                    gridstyleID={m.displayStyleID}
                                    widgetID={m.widgetUsedID}
                                    hoverColor={m.hover}
                                    userWidgetID={m.userWidgetID}
                                    pullDashboardData={pullDashboardData}
                                />
                            </Grid>
                        );
                    })
                ) : (
                    <div style={{ width: '100%' }}>
                        <FromLoader />
                    </div>
                )}
            </Grid>
            {state.showFilter ? (
                <DialogComp onClose={handleFilter} title={'Filter List'}>
                    <PopUpCust />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default CustomerDashobard;
