import React, { useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, Typography } from '@material-ui/core';
import DialogComp from '../../../Core/Dialog/DialogComp';
import { formatters, DataGrid, BreadCrumbs } from '../../../Core/Controls';
import AddIcon from '@material-ui/icons/Add';
import { EnquiriesProvider, EnquiriesScreenContext } from './EnquirieProvider';
import EnquiryStepper from './EnquiryStepper/EnquiryStepper';
import '../Controllers/controllerStyle.scss';
import AddEditLeadNotes from './AddEnquiryNotes';
import { CustomChip } from '../../../Core/Controls';
import '../Maintenance/BreackdownDefectStepper/defect.scss';
import FileUploaderButton from '../../../Core/Controls/GenericFileUploader';

const columns = [
    { key: 'leadNoteDateCreated', name: 'Created', width: 150, formatter: formatters.Date_DDMMMYYYY_HHMM },
    { key: 'leadNoteDescription', name: 'Notes' }
];

const crumbsObj = { new_enquiry: 'New Enquiries', live_enquiry: 'Live Enquiries', successful_enquiry: 'Successful Enquiries', lost_enquiry: 'Lost Enquiries' };

const EnquiryEditModal = (props) => {
    let setVal = props?.location?.search.replace('?', '').trim();
    const crumbs = [
        { name: 'Home', path: '/' },
        { name: crumbsObj[setVal], path: `/enquiries/${setVal}` },
        { name: 'Edit Enquiry', active: true }
    ];
    const { state, pullEnquiryByID, pullNotes, GotoEditMode, optionsState } = useContext(EnquiriesScreenContext);
    const [data, setData] = useState({});

    const closeNoteModal = (res) => {
        setData((st) => ({
            ...st,
            show: false
        }));
        res && pullEnquiryByID();
        res && pullNotes();
    };

    const addNotes = (res) => {
        setData((st) => ({
            ...st,
            show: true
        }));
    };

    return (
        <div className="screen">
            <Grid container spacing={1}>
                <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" />

                <EnquiryStepper
                    data={state}
                    leadStatusID={state.leadStatusID}
                    leadLostReasonsList={optionsState.leadLostReasonsList}
                    onClose={closeNoteModal}
                />

                <Grid container item xs={12} spacing={1} className="edit-controller-btn">
                    <Grid item xs={12} md={12} lg={7}>
                        <Grid container item xs={12} justify="space-between" style={{ paddingTop: '4px' }} className="grid-style">
                            <Grid item>
                                <Typography variant="h5" color="secondary">
                                    Enquiry Details
                                </Typography>
                            </Grid>

                            <Grid item style={{ paddingTop: 1, paddingBottom: 3, paddingRight: 3 }}>
                                <FileUploaderButton recordId={props.EnquiriesID} fileCount={state.fileCount} fileUploaderConfigName="Enquiries" />
                                &nbsp;&nbsp;
                                <CustomChip icon={AddIcon} label="Edit" onClick={GotoEditMode} style={{ fontWeight: 'bold' }} />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid container item className="grid-style" style={{ backgroundColor: '#f9f9f9' }}>
                                <Grid item xs={6} sm={4}>
                                    <FormHelperText shrink>Company Name</FormHelperText>
                                    <Typography variant="button">{state.leadCompanyName || 'N\\A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <FormHelperText shrink>Address</FormHelperText>
                                    <Typography variant="button">
                                        {formatters.addressFormatter(state.leadAddress1, state.leadAddress2, state.leadCity, state.leadPostcode)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item className="grid-style white-grid">
                                <Grid item xs={6} sm={4}>
                                    <FormHelperText shrink> Contact Name</FormHelperText>
                                    <Typography variant="button">{state.leadMainContactName || 'N\\A'}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <FormHelperText shrink>Email</FormHelperText>
                                    <Typography variant="button">{formatters.mailFormatter(state.leadMainContactEmail)}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                    <FormHelperText shrink>Phone Number</FormHelperText>
                                    <Typography variant="button">{formatters.phoneNumberFormatter(state.leadMainContactPhone)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item className="grid-style" style={{ backgroundColor: '#f9f9f9' }}>
                                <Grid item xs={6} sm={4}>
                                    <FormHelperText shrink> Fleet Size</FormHelperText>
                                    <Typography variant="button">{state.leadFleetSize || 'N\\A'}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <FormHelperText shrink>Type Of Vehicles</FormHelperText>
                                    <Typography variant="button">
                                        {state?.leadSelectedVehicleTypes?.map((m) => {
                                            return m?.leadVehicleTypeDescription + ' ';
                                        }) || 'N\\A'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item className="grid-style white-grid">
                                <Grid item xs={6} sm={4}>
                                    <FormHelperText shrink>Referrer Name</FormHelperText>
                                    <Typography variant="button">{null || 'N\\A'}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <FormHelperText shrink>Referrer Email</FormHelperText>
                                    <Typography variant="button"> {formatters.mailFormatter(state.leadReferrerEmail)}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                    <FormHelperText shrink>Branch Name</FormHelperText>
                                    <Typography variant="button">{state.branchName || 'N\\A'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={5}>
                        <Grid container item xs={12} justify="space-between" style={{ paddingTop: '4px' }} className="grid-style grey-grid">
                            <Grid item>
                                <Typography variant="h5" color="secondary">
                                    Enquiry Notes
                                </Typography>
                            </Grid>
                            <Grid item style={{ paddingTop: 1, paddingBottom: 3, paddingRight: 3 }}>
                                <CustomChip icon={AddIcon} label="Add Note" onClick={addNotes} style={{ fontWeight: 'bold' }} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <DataGrid columns={columns} rows={state.notesList} offset={435} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {data.show ? (
                <DialogComp title="Enquiry Notes" onClose={() => closeNoteModal(false)} maxWidth="md">
                    <AddEditLeadNotes leadNoteID={state.leadNoteID} leadNoteLeadID={state.leadID} onFormSubmit={() => closeNoteModal(true)} {...props} />
                </DialogComp>
            ) : null}
        </div>
    );
};

const Screen = (props) => {
    return (
        <EnquiriesProvider {...props}>
            <EnquiryEditModal {...props} />
        </EnquiriesProvider>
    );
};
export default Screen;
