import React, { useState, useEffect, useMemo } from 'react';
import { getAllCostCategories } from '../../../../Core/Services/InternalUser/costCategoriesService';
import DataGrid from '../../../../Core/Controls/Grid';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import CostCategoriesModal from './CostCategoriesModal';
import { BreadCrumbs, EditButton, formatters } from '../../../../Core/Controls';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import '../../.././commonStyle.scss';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Jobs', active: true },
    { name: 'Cost Categories', active: true }
];
const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'costCategory', name: 'Cost Category' },
    { key: 'costCategoryLedgerCode', name: 'GL Code' },
    { key: 'active', name: 'Is Active ?' }
];

function CostCategories(props) {
    const [state, setState] = useState({
        CostCategoriesList: [],
        loader: true,
        open: false
    });

    useEffect(async () => {
        pullAllData();
    }, []);

    const pullAllData = async () => {
        setState((state) => ({
            ...state,
            isReload: new Date()
        }));
    };

    const submitHandler = (params) => {
        setState((state) => ({ ...state, open: true, costCategoryID: params?.costCategoryID }));
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => submitHandler(row)} toolTipTitle="Edit Cost Category" />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        return newColumn;
    });

    const title = state.costCategoryID ? 'Update Cost Categories' : 'Add Cost Categories';

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullAllData();
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                columns={columns}
                getUrl={`CostCategories`}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                btnTitle="Add Cost Category"
                onBtnClick={() => submitHandler(null)}
            />
            {state.open ? (
                <DialogComp title={title} onClose={() => closeModal(false)} maxWidth="sm">
                    <CostCategoriesModal costCategoryID={state.costCategoryID} onClose={() => closeModal(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
}

export default CostCategories;
