import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';

import moment from 'moment';
import { SecondaryButton, SingleSelect } from '../../../../Core/Controls';
import { getVehicleScreenDropdowns } from '../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { useToast } from '../../../../Core/Hooks/useToast';
import { getVehicleJobs_Reallocate } from '../../../../Core/Services/InternalUser/ServiceAgent/breakdownService';
import { getJobEnggById } from '../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';

const StyledTableCell = withStyles((theme) => ({
    head: {
        // padding: ' 5px',
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: '#bdbdbd82'
    },
    body: {
        fontSize: 12
        // padding: ' 5px'
        // fontSize: 11,
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'grey',
            color: 'white'
        },
        cursor: 'pointer'
    }
}))(TableRow);

const JobAllocationModal = (props) => {
    console.log(props, 'props');
    const [state, setState] = useState({
        errors: {},
        contractDeliveryDate: '', //moment().format('YYYY-MM-DD')
        contractDeliveryCharge: null,
        serviceAgent: null,
        serviceAgents: [],
        technician: []
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        let res = await getVehicleScreenDropdowns();
        setState((st) => ({
            ...st,
            serviceAgents: res.data.serviceAgents
            // technician: res.data.technician
        }));
    }, []);

    useEffect(async () => {
        if (state.serviceAgent?.id) {
            let res = await getJobEnggById(state.serviceAgent?.id);
            if (res.success) {
                setState((d) => ({ ...d, technician: res.data.list }));
            }
        }
    }, [state.serviceAgent?.id]);

    const onFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => ({ ...st, [nm]: val }));
    };

    const validations = () => {
        const { engineerID, serviceAgent } = state;
        let formIsValid = true;
        let errors = {};

        // if (!engineerID?.id) {
        //     errors.engineerID = 'This field is required';
        //     formIsValid = false;
        // }

        if (!serviceAgent?.id) {
            errors.serviceAgent = 'This field is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const SubmitHandler = async () => {
        if (validations()) {
            let data = {
                jobIds: props.selectedRecords.map((q) => q.jobID),
                engineerID: state.engineerID?.id,
                serviceAgent: state.serviceAgent?.id
            };
            console.log(data, 'data');
            try {
                setBtnLoader(true);
                let res = await getVehicleJobs_Reallocate(data);
                if (res.success) {
                    showToastSuccess(`${res.message}`);
                    setBtnLoader(false);
                    props.onClose(true);
                } else {
                    showToastError(res.message);
                    setBtnLoader(false);
                }
            } catch (error) {
                setBtnLoader(false);
                showToastError(`An error occurred: ${error.message}`);
            }
        }
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Service Area</InputLabel>
                <SingleSelect options={state.serviceAgents} value={state.serviceAgent} onChange={singleSelectChange('serviceAgent')} />
                <FormHelperText error>{state.errors.serviceAgent}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <InputLabel shrink>Engineer</InputLabel>
                <SingleSelect options={state.technician} value={state.engineerID} onChange={singleSelectChange('engineerID')} />
                <FormHelperText error>{state.errors.engineerID}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <TableContainer className="custom-scroll" style={{ height: '350px' }}>
                    <Table size="small" width="550px" stickyHeader>
                        <TableHead>
                            <TableRow className="header">
                                <StyledTableCell>Reg Number</StyledTableCell>
                                <StyledTableCell>Job Description</StyledTableCell>
                                <StyledTableCell>Service Area</StyledTableCell>
                                <StyledTableCell>Engineer</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.selectedRecords.map((q) => {
                                return (
                                    <StyledTableRow key={q.vehicleRegNumber}>
                                        <StyledTableCell>{q.vehicleRegNumber}</StyledTableCell>
                                        <StyledTableCell>{q.jobDescription}</StyledTableCell>
                                        <StyledTableCell>{q.serviceAgentName}</StyledTableCell>
                                        <StyledTableCell>{q.technician}</StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton fullWidth isBtnLoader={btnLoader} className="submit_btn" onClick={SubmitHandler} disabled={props.selectedRecords.length <= 0}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default JobAllocationModal;
