import { Button, ButtonGroup, FormHelperText, Grid, InputLabel } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, MandatoryField, SecondaryButton, SimpleField, SingleSelect } from '../../../Core/Controls';
import { ServiceAreaHolidays_AddUpdate } from '../../../Core/Services/ServiceAgent/Holiday.js';
import { useToast } from '../../../Core/Hooks/useToast.js';
import moment from 'moment';
import { getJobEnggById } from '../../../Core/Services/InternalUser/ControllerService/jobDetailsService.js';

const AddUpdateHoliday = ({ selectedDate, serviceAreaID, userData, onClose }) => {
    console.log(userData, 'userData');
    const [state, setState] = useState({
        holidayStartDate: userData?.holidayStartDate ? moment(userData?.holidayStartDate).format('YYYY-MM-DD') : selectedDate,
        holidayEndDate: userData?.holidayEndDate ? moment(userData?.holidayEndDate).format('YYYY-MM-DD') : '',
        userHolidayRecordID: userData?.userHolidayRecordID || null,
        holidayNotes: userData?.holidayNotes || '',
        errors: {},
        engineerUserID: null,
        engineersList: []
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        if (serviceAreaID) {
            let res = await getJobEnggById(serviceAreaID);
            if (res.success) {
                let newSt = {};
                newSt.engineersList = res.data.list;
                if (userData?.userHolidayUserID) {
                    newSt.engineerUserID = res.data.list?.find(({ id }) => id === userData?.userHolidayUserID);
                }
                setState((d) => ({ ...d, ...newSt }));
            }
        }
    }, [serviceAreaID]);

    const inputChange = useCallback((e) => {
        const { name, value } = e.target;
        if (name === 'holidayStartDate') {
            setState((st) => ({ ...st, holidayEndDate: moment(value).format('YYYY-MM-DD') }));
        }
        setState((st) => ({ ...st, [name]: value }));
    }, []);
    const OnSingleSelectChange = (nm) => (e, val) => {
        setState((state) => ({
            ...state,
            [nm]: val
        }));
    };

    let validation = () => {
        let isValid = true;
        let errors = {};
        const { engineerUserID, holidayEndDate, holidayStartDate } = state;
        if (!engineerUserID?.id) {
            errors.engineerUserID = 'This field is required';
            isValid = false;
        }

        if (!holidayStartDate) {
            errors.holidayNotes = 'This Field is Required';
            isValid = false;
        }
        if (!holidayEndDate) {
            errors.holidayEndDate = 'This Field is Required';
            isValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return isValid;
    };

    const submitFrom = async () => {
        if (validation()) {
            try {
                setBtnLoader(true);
                let data = {
                    userHolidayRecordID: state.userHolidayRecordID,
                    userHolidayUserID: state.engineerUserID?.id,
                    serviceAreaID: serviceAreaID,
                    holidayStartDate: state.holidayStartDate,
                    holidayNotes: state.holidayNotes,
                    holidayEndDate: state.holidayEndDate
                };

                let res = await ServiceAreaHolidays_AddUpdate(data);
                if (res.success) {
                    showToastSuccess(res?.data?.message);
                    setBtnLoader(false);
                    onClose(true);
                } else {
                    showToastError(res?.message);
                    setBtnLoader(false);
                }
            } catch (error) {
                setBtnLoader(false);
                showToastError(`An error occurred: ${error.message}`);
            }
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <SingleSelect
                    inputLabel="Engineer"
                    isRequired={true}
                    style={{ marginTop: -8 }}
                    options={state.engineersList}
                    value={state.engineerUserID}
                    onChange={OnSingleSelectChange('engineerUserID')}
                />
                <FormHelperText error>{state.errors.engineerUserID}</FormHelperText>
            </Grid>

            <Grid item xs={6}>
                <InputLabel shrink error>
                    Start Date
                </InputLabel>
                <DatePicker
                    value={state.holidayStartDate}
                    onChange={inputChange}
                    name="holidayStartDate"
                    error={state.errors.holidayStartDate}
                    InputProps={{ inputProps: { max: `${state.holidayEndDate}`, min: selectedDate } }}
                    autoFocus={true}
                />
                <FormHelperText error>{state.errors.holidayStartDate}</FormHelperText>
            </Grid>
            <Grid item xs={6}>
                <InputLabel shrink error>
                    End Date
                </InputLabel>
                <DatePicker
                    value={state.holidayEndDate}
                    onChange={inputChange}
                    name="holidayEndDate"
                    error={state.errors.holidayEndDate}
                    InputProps={{ inputProps: { min: `${state.holidayStartDate}` } }}
                />
                <FormHelperText error>{state.errors.holidayEndDate}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <SimpleField
                    inputLabel="Notes"
                    multiline
                    rows={4}
                    onChange={inputChange}
                    name="holidayNotes"
                    value={state.holidayNotes}
                    // errors={state.errors.holidayNotes}
                />
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton isBtnLoader={btnLoader} onClick={submitFrom}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default AddUpdateHoliday;
