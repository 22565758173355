import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from '../../../../Core/Controls/Grid/index';
import { AddButton, EditButton, BreadCrumbs } from '../../../../Core/Controls';
import DialogBox from './licenseTypeDialog';
import { useToast } from '../../../../Core/Hooks/useToast';
import '../../../commonStyle.scss';

import { getAllApiData, postDataToApi, updateDataToApi } from '../../../../Core/Services/InternalUser/licenseTypeService';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Drivers', active: true },
    { name: 'Driver License Types', active: true }
];
const basecolumns = [
    { key: 'action', name: 'Action', width: '7%' },
    { key: 'driverLicenceTypeDescription', name: 'Description' }
];
const LicenseType = () => {
    const { showToastSuccess, showToastError } = useToast();
    const [value, setValue] = useState({
        title: 'Add Driver License Types',
        data: [],
        btnText: 'ADD',
        isOpen: false,
        rows: [],
        showLoader: true,
        isReload: null
    });

    const pullApiData = async () => {
        setValue((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const dialogClose = () => {
        setValue((st) => ({
            ...st,
            isOpen: false
        }));
    };

    const addBtnClicked = () => {
        setValue((st) => ({
            ...st,
            title: 'Add Driver License Types',
            data: [],
            btnText: 'ADD',
            isOpen: true
        }));
    };

    const renderEdit = (ev, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <EditButton
                    toolTipTitle="Edit Driver License Type"
                    onClick={() => {
                        setValue((st) => ({
                            ...st,
                            title: 'Edit Driver License Type',
                            btnText: 'SAVE CHANGES',
                            isOpen: true,
                            data: row
                        }));
                    }}
                />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);
    const addRowData = async (value) => {
        let mes = await postDataToApi({ driverLicenceTypeDescription: value });
        pullApiData();
        if (mes) {
            mes.success ? showToastSuccess(mes.message || 'License type added successfully') : showToastError(mes.message || 'Something went wrong');
        } else {
            showToastError('Something went wrong');
        }
    };
    const updateRowData = async (value) => {
        let mes = await updateDataToApi({
            driverLicenceTypeID: value.ID,
            driverLicenceTypeDescription: value.text
        });
        pullApiData();
        if (mes) {
            mes.success ? showToastSuccess(mes.message || 'License type updated successfully') : showToastError(mes.message || 'Something went wrong');
        } else {
            showToastError('Something went wrong');
        }
    };

    let getUrl = `DriverLicenceTypes`;

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={getUrl}
                btnTitle="Add Driver License Type"
                onBtnClick={addBtnClicked}
                isReload={value.isReload}
            />

            {value.isOpen ? (
                <DialogBox
                    className="dialog-container"
                    onClose={dialogClose}
                    title={value.title}
                    childValue={value}
                    addRowData={addRowData}
                    updateRowData={updateRowData}
                />
            ) : null}
        </div>
    );
};
export default LicenseType;
