import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Grid from '@material-ui/core/Grid';
import { InputText, SecondaryButton, MandatoryField, SelectBox, YesButton, NoButton } from '../../../Core/Controls';
import DialogComp from '../../../Core/Dialog/DialogComp';
import AddEditModal from './AddEditModal';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Label from '@material-ui/icons/Label';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import InfoIcon from '@material-ui/icons/Info';
import ForumIcon from '@material-ui/icons/Forum';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import EditIcon from '@material-ui/icons/Edit';
import '../../commonStyle.scss';
const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
            color: 'var(--tree-view-color)'
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent'
        }
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular
        }
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2)
        }
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit'
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0)
    },
    labelIcon: {
        marginRight: theme.spacing(1)
    },
    editBtn: {
        marginRight: theme.spacing(2)
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1
    }
}));

function TreeItems(props) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, editBtn: EditBtn, labelInfo, color, bgColor, ...other } = props;
    const [state, setState] = useState({
        loader: true,
        open: false
    });
    const btnClickHandler = (params) => {
        setState((state) => ({ ...state, open: true }));
    };
    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false }));
    };
    const title = state.ID ? 'Update Left Menu' : 'Add Left Menu';
    return (
        <TreeItem
            label={
                <div className={classes.labelRoot}>
                    <LabelIcon color="inherit" className={classes.labelIcon} />
                    <Typography variant="body2" className={classes.labelText}>
                        {labelText}
                    </Typography>
                    <div>
                        <Button
                            // variant="outline"
                            onClick={btnClickHandler}
                            size="small"
                            color="secondary"
                        >
                             <EditIcon />
                        </Button>
                    </div>
                    {state.open ? (
                        <DialogComp title={title} onClose={() => closeModal(false)} maxWidth="sm">
                            <AddEditModal
                                //  retorquePositionID={state.retorquePositionID}
                                onClose={() => closeModal(true)}
                            />
                        </DialogComp>
                    ) : null}
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </div>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label
            }}
            {...other}
        />
    );
}

TreeItems.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    // editBtn: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired
};

const useStyles = makeStyles({
    root: {
        marginTop: 20,
        marginLeft: 40,
        height: 444,
        flexGrow: 1,
        maxWidth: 400
    }
});

export default function TreeViewScreen() {
    const [state, setState] = useState({
        loader: true,
        open: false
    });

    const btnClickHandler = (params) => {
        setState((state) => ({ ...state, open: true }));
    };
    const classes = useStyles();
    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false }));
    };
    const title = state.ID ? 'Update Left Menu' : 'Add Left Menu';
    return (
        <>
            <div className="add_btn" style={{ marginTop: '5%' }}>
                <Button variant="contained" onClick={btnClickHandler} size="medium" color="secondary" style={{ textTransform: 'capitalize' }}>
                    Add
                </Button>
                {/* <Button
                    variant="contained"
                    onClick={btnClickHandler}
                    size="medium"
                    color="secondary"
                    style={{ marginLeft: 4, textTransform: 'capitalize' }}
                >
                     Edit
                </Button> */}
            </div>
            {state.open ? (
                <DialogComp title={title} onClose={() => closeModal(false)} maxWidth="sm">
                    <AddEditModal
                        //  retorquePositionID={state.retorquePositionID}
                        onClose={() => closeModal(true)}
                    />
                </DialogComp>
            ) : null}
            <TreeView
                className={classes.root}
                defaultExpanded={['3']}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24 }} />}
            >
                <TreeItems nodeId="3" labelText="Navigation" labelIcon={Label}>
                    <TreeItems
                        nodeId="5"
                        labelText="Left"
                        labelIcon={SupervisorAccountIcon}
                        // editBtn={<EditIcon />}
                        // labelInfo="90"
                        color="#1a73e8"
                        bgColor="#e8f0fe"
                    >
                        <TreeItems
                            nodeId="6"
                            labelText="Screen 1"
                            labelIcon={InfoIcon}
                            // labelInfo="2,294"
                            color="#e3742f"
                            bgColor="#fcefe3"
                        />

                        <TreeItems
                            nodeId="7"
                            labelText="Screen 2"
                            labelIcon={ForumIcon}
                            // labelInfo="3,566"
                            color="#a250f5"
                            bgColor="#f3e8fd"
                        />
                        <TreeItems
                            nodeId="8"
                            labelText="Screen 3"
                            labelIcon={LocalOfferIcon}
                            // labelInfo="733"
                            color="#3c8039"
                            bgColor="#e6f4ea"
                        />
                    </TreeItems>
                </TreeItems>
            </TreeView>
        </>
    );
}
