import { postWithAuth, getWithAuth,putWithAuth } from '../../Basic/HttpService';

export const getAllCollectionNDelivery = async () => {
    let res = await getWithAuth(`CollectionAndDelivery`);
    return res;
};


export const postCollectionNDelivery = async (data) => {
    let res = await postWithAuth(`CollectionAndDelivery`, data);
    return res;
};
