import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from '../../../Core/Controls/Grid/index';
import { AddButton, EditButton, BreadCrumbs } from '../../../Core/Controls';
import DialogBox from './vorTypeDetailDialog';
import DialogComp from '../../../Core/Dialog/DialogComp';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import '../../commonStyle.scss';
import { Assignment } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import VorSubCat from '../VORSubCatScreen/VorSubCat';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';

const basecolumns = [
    { key: 'action', name: 'Action', width: '7%' },
    { key: 'vorTypeDescription', name: 'VOR Type', width: '30%' },
    { key: 'vorTypeDetailDescription', name: 'Description' }
];
const VORTypeDetail = (props) => {
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        isAdd: true,

        showSubCat: false,
    });

    const subCatHandler = (va, x) => {
        setState((st) => ({ ...st, showSubCat: va, focusedRow: x }))
    }

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Vehicles', active: true },
        {
            name:
                <div onClick={() => props.closeType(false, null)} style={{ cursor: 'pointer' }}>
                    VOR Types ({props.vorTypeDescription})
                </div >,
        },
        { name: 'VOR Reasons', active: true },
    ];

    useEffect(async () => {
        pullApiData();
    }, []);

    const pullApiData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const renderEdit = (ev, row) => {
        return (
            <div style={{ textAlign: 'center', justifyContent: 'space-evenly' }}>
                <EditButton
                    toolTipTitle="Edit VOR Type Detail"
                    onClick={() => {
                        setState((st) => ({
                            ...st,
                            open: true,
                            selectedRowData: row,
                            isAdd: false
                        }));
                    }}
                />
                <CustomIconButton
                    icon={Assignment}
                    onClick={() => subCatHandler(true, row)}
                    toolTipTitle="VOR Sub Categories"
                />
            </div>
        );
    };
    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);

    const addBtnClicked = () => {
        setState((st) => ({
            ...st,
            open: true,
            isAdd: true,
            selectedRowData: {}
        }));
    };
    const handleClose = (res) => {
        setState((st) => ({
            ...st,
            open: false
        }));
        res && pullApiData();
    };

    return (
        state.showSubCat ?
            <>
                <VorSubCat closeType={props.closeType} closeSubCat={subCatHandler} vorTypeDescription={props.vorTypeDescription} focusedRow={state.focusedRow} />
            </>
            :
            <div className="screen">
                <BreadCrumbs crumbs={crumbs} />

                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl={`vortypedetails${'?vorTypeDescription=' + props.vorTypeDescription}`}
                    btnTitle="Add VOR Type Detail"
                    onBtnClick={addBtnClicked}
                    isReload={state.isReload}
                />
                {state.open ? (
                    <DialogComp
                        open={state.open}
                        onClose={() => {
                            handleClose(false);
                        }}
                        title={state.isAdd ? 'Add VOR Type Detail' : 'Edit VOR Type Detail'}
                        maxWidth="md"
                    >
                        <DialogBox
                            data={state}
                            onClose={() => {
                                handleClose(true);
                            }}
                        />
                    </DialogComp>
                ) : null}
            </div>
    );
};

export default VORTypeDetail;
