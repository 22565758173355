import React, { useState } from 'react';
import { useEffect } from 'react';
import { useToast } from '../../../../Core/Hooks/useToast';
import { GetMasterContractHistory } from '../../../../Core/Services/InternalUser/contractService';
import { JobSingleNote } from '../../Controllers/Vehicles/Contract/contractTab';

const MasterContractHistory = (props) => {
    const [state, setState] = useState({
        masterContractHistory: []
    });
    const { showToastSuccess, showToastError } = useToast();
    useEffect(async () => {
        if (props.contractID) {
            let masterHistory = await GetMasterContractHistory(props.contractID);
            if (masterHistory.success) {
                setState((st) => ({ ...st, masterContractHistory: masterHistory.data.table }));
            }
        }
    }, []);

    return (
        <div
            style={{
                padding: '5px',
                minHeight: '430',
                // height: 'calc(100vh - 240px)',
                overflow: 'auto',
                border: '1px solid rgba(0,0,0,0.2)',
                maxHeight: 430
            }}
            className="custom-scroll"
        >
            {state.masterContractHistory.length > 0 ? (
                <>
                    {state.masterContractHistory?.map((n, i) => (
                        <div style={{ background: i % 2 ? '#F6EEEC' : '#ECF4F6', padding: '5px 10px' }}>
                            <JobSingleNote key={i} note={n} />
                        </div>
                    ))}
                </>
            ) : (
                <div style={{ background: '#ECF4F6', padding: '5px 10px' }}>No Record Found</div>
            )}
        </div>
    );
};

export default MasterContractHistory;
