import React, { useEffect, useState, useCallback, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { SecondaryCheckbox, SecondaryButton, CustomChip, SimpleField } from '../../../Core/Controls';
import { getAllFilterCustomers, postFilterCustomers } from '../../../Core/Services/InternalUser/customerFilterList';
import { useToast } from '../../../Core/Hooks/useToast';
import { CircularProgress } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { AppContext } from '../../../Core/App/PageLevel/AppContextProvider';

function CustomerList(props) {
    const { showToastSuccess, showToastError } = useToast();
    const { dashBoardDataReload } = useContext(AppContext);

    const [state, setState] = useState({
        custList: [],
        errors: {},
        showLoader: true,
        setBtn: ''
    });

    useEffect(async () => {
        let res = await getAllFilterCustomers();
        res.data.customers.forEach(function (n) {
            let chekExist = res?.data?.filterCustomers?.find((l) => l.userFilterCustomerId == n.id);
            if (chekExist) {
                n.active = true;
            } else n.active = false;
        });
        setState((st) => ({
            ...st,
            custList: res?.data?.customers,
            showLoader: false
        }));
    }, []);

    const handleCheckbox = useCallback((i) => (e) => {
        const { name, checked } = e.target;
        let changedArray = [...state.custList];
        let objIndex = state.custList.findIndex((x) => x.id == i);
        changedArray[objIndex][name] = checked;
        setState((state) => {
            const nst = { ...state, custList: changedArray, setBtn: '' };
            return nst;
        });
    });

    const setCustCheck = useCallback((val) => {
        let newCust = state.custList.map((c) => ({ ...c, active: val }));
        setState((state) => {
            const nst = { ...state, custList: newCust, setBtn: val ? 'check' : 'uncheck' };
            return nst;
        });
    });

    const handelSubmit = useCallback(async (val) => {
        let data = { customerIds: null };
        if (val) {
            data = { customerIds: state.custList.filter((x) => x.active).map((x) => x.id) };
        }
        let res = await postFilterCustomers(data);
        if (res.success) {
            // showToastSuccess(res.message);
            props.onClose(true);
            dashBoardDataReload();
        } else {
            showToastError(res.message);
        }
    });
    return (
        <Grid container direction="row" spacing={2}>
            {state.showLoader ? (
                <Grid item sm={12} container alignItems="center" justify="center" style={{ width: 'calc(100vh - 230px)', height: 300 }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <>
                    <Grid container spacing={2} justify="flex-end">
                        <Grid item>
                            <CustomChip
                                icon={DoneAllIcon}
                                color={`${state.setBtn == 'check' ? 'secondary' : ''}`}
                                label=" Check All"
                                onClick={() => setCustCheck(true)}
                            />
                        </Grid>
                        <Grid item>
                            <CustomChip
                                icon={HighlightOffIcon}
                                color={`${state.setBtn == 'uncheck' ? 'secondary' : ''}`}
                                label="Uncheck All"
                                onClick={() => setCustCheck(false)}
                            />
                        </Grid>
                    </Grid>
                    {state.custList.map((m, i = 1) => (
                        <Grid item xs={6} sm={4} md={3}>
                            <SecondaryCheckbox checked={m.active} name="active" onChange={handleCheckbox(m.id)} label={m.name} />
                        </Grid>
                    ))}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <SecondaryButton onClick={() => handelSubmit(true)} fullWidth className="submit_btn">
                                Set Filter
                            </SecondaryButton>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SecondaryButton onClick={() => handelSubmit(false)} fullWidth className="submit_btn">
                                Reset Filter
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
}

export default CustomerList;
