import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { FormHelperText, InputLabel } from "@material-ui/core";
import { InputText, SecondaryButton, SecondaryCheckbox } from "../../../../Core/Controls";
import { NotesProvider, NotesScreenContext } from "./NotesProvider";

const DeleteNotes = (props) => {
  const { state, handleSubmit, inputChange, handleCheckbox, handleDeleteSubmit } = useContext(NotesScreenContext);
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SecondaryButton onClick={handleDeleteSubmit} className="submit_btn">
          Yes
        </SecondaryButton>
      </Grid>
      <Grid item xs={6}>
        <SecondaryButton
          onClick={() => {
            props.onFormCancel();
          }}
          className="submit_btn"
        >
          No
        </SecondaryButton>
      </Grid>
    </Grid>
  );
};

const Screen = (props) => {
  return (
    <NotesProvider {...props}>
      <DeleteNotes {...props} />
    </NotesProvider>
  );
};
export default Screen;
