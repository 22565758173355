import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DateTimePicker, InputText, SecondaryButton, SingleSelect } from '../../../../../../Core/Controls';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import { getAllScheduleDropdowns } from '../../../../../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';
import {
    postRecallBookedStep,
    postRecallOnsiteAndWorkedShop,
    postRecallCompleted,
    getRecallAgents
} from '../../../../../../Core/Services/InternalUser/Vehicle/vehicleRecallService';
import moment from 'moment';

const AssignServiceAgentModal = (props) => {
    const [state, setState] = useState({
        commonDate: moment().format('YYYY-MM-DDTHH:mm'),
        // commonDate: '',
        errors: {},
        serviceAgentID: props.stepData.recallServiceAgentID,
        serviceAgentIDValue: null
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();

    useEffect(() => {
        if (props.fromPage === 'serviceAgent') {
            getServiceAgentList();
        }
    }, []);

    const getServiceAgentList = async () => {
        let newSt = {};
        let res = await getRecallAgents();
        if (state.serviceAgentID && res.data.serviceAgents.length > 0) {
            newSt.serviceAgentIDValue = res.data.serviceAgents.find(({ id }) => id === state.serviceAgentID);
        }
        setState((st) => ({
            ...st,
            serviceAgentsList: res.data.serviceAgents,
            ...newSt
        }));
    };

    const validations = () => {
        const { serviceAgentIDValue, commonDate, recallCompletedComments } = state;
        let formIsValid = true;
        let errors = {};
        if (!serviceAgentIDValue && props.fromPage === 'serviceAgent') {
            errors.serviceAgentIDValue = ' This field is required';
            formIsValid = false;
        }
        if (!commonDate) {
            errors.commonDate = ' This field is required';
            formIsValid = false;
        }
        if (!recallCompletedComments && props.fromPage === 'CompleteRecall') {
            errors.recallCompletedComments = ' This field is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => ({ ...st, [nm]: val }));
    };
    const dateFieldsChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const SubmitHandler = async () => {
        let FormData;
        let res = {};
        if (validations()) {
            setBtnLoader(true);
            if (props.fromPage === 'serviceAgent') {
                FormData = {
                    recallID: props.recallID,
                    recallDateBooked: state.commonDate,
                    recallServiceAgentID: state.serviceAgentIDValue?.id
                };
                res = await postRecallBookedStep(FormData);
            }
            if (props.fromPage === 'workShop' || props.fromPage === 'onSite') {
                FormData = {
                    fromPage: props.fromPage === 'onSite' ? 'O' : '',
                    recallID: props.recallID,
                    recallDateOnSite: props.fromPage === 'onSite' ? state.commonDate : '',
                    recallDateWorkshop: props.fromPage === 'workShop' ? state.commonDate : ''
                };
                res = await postRecallOnsiteAndWorkedShop(FormData);
            }
            if (props.fromPage === 'CompleteRecall') {
                FormData = {
                    recallID: props.recallID,
                    recallDateCompleted: state.commonDate,
                    recallCompletedComments: state.recallCompletedComments
                };
                res = await postRecallCompleted(FormData);
            }
            if (res.success) {
                showToastSuccess(res.message);
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    return (
        <Grid container spacing={2}>
            {props.fromPage === 'serviceAgent' ? (
                <Grid item xs={12} className="mandatory-fields">
                    <InputLabel shrink>Service Area</InputLabel>
                    <SingleSelect
                        options={state.serviceAgentsList || []}
                        value={state.serviceAgentIDValue}
                        onChange={singleSelectChange('serviceAgentIDValue')}
                    />
                    <FormHelperText error>{state.errors.serviceAgentIDValue}</FormHelperText>
                </Grid>
            ) : null}
            <Grid item xs={12}>
                <InputLabel shrink>Date</InputLabel>
                <DateTimePicker
                    placeholder="Date"
                    value={state.commonDate}
                    onChange={dateFieldsChange}
                    name="commonDate"
                    // InputProps={{ inputProps: { max: `${state.commonDate}` } }}
                />
                <FormHelperText error>{state.errors.commonDate}</FormHelperText>
            </Grid>
            {props.fromPage === 'CompleteRecall' && (
                <Grid item xs={12}>
                    <InputLabel shrink>Comment</InputLabel>
                    <InputText multiline rows={4} onChange={dateFieldsChange} name="recallCompletedComments" value={state.recallCompletedComments} />
                    <FormHelperText error>{state.errors.recallCompletedComments}</FormHelperText>
                </Grid>
            )}
            <Grid item xs={12}>
                <SecondaryButton fullWidth isBtnLoader={btnLoader} className="submit_btn" onClick={SubmitHandler}>
                    {props.fromPage === 'serviceAgent'
                        ? 'Booked Recall'
                        : props.fromPage === 'CompleteRecall'
                        ? 'Complete Recall'
                        : props.fromPage === 'onSite'
                        ? 'Set On Site Date'
                        : props.fromPage === 'workShop'
                        ? 'Set In Work Shop Date'
                        : ''}
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default AssignServiceAgentModal;
