import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { SecondaryButton } from '../../../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import JobEstimateModal from '../../JobStatusBtn/JobEstimate';
import JobQueryModal from '../../JobStatusBtn/JobQuery';
import SubmitWarnngModal from '../../JobStatusBtn/SubmitWarning';
import { CustomChip } from '../../../../../../Core/Controls';
import AddIcon from '@material-ui/icons/Add';
import AcceptEstimateModal from '../../JobStatusBtn/AcceptEstimateModal';
import HelpIcon from '@material-ui/icons/Help';

const CancelButton = (props) => {
    return <SecondaryButton onClick={props.goPreviousScreen}>Cancel</SecondaryButton>;
};

function ServiceAgentstatusBasedButton(props) {
    const [state, setState] = useState({
        showEstimate: false,
        title: ''
    });

    const { status, vehicleID, data } = props;

    const handelPopup = async (title, setShow) => {
        if (data.jobOrderTotal != 0 && title == 'Submit Cost') {
            setShow = 'showSubmitCostModal';
        }
        setState((st) => ({
            ...st,
            title: title,
            [setShow]: true
        }));
    };
    const showSubmitModal = (res) => {
        setState((st) => ({
            ...st,
            showSubmit: false
        }));
    };
    const closeTheDialog = async (res) => {
        setState((st) => ({
            ...st,
            showEstimate: false,
            showSubmit: false,
            showSubmitCostModal: false
        }));
        res && props.refreshScreen(res);
    };

    const CostomButton = (btnLable, modalName, icon) => {
        return (
            <>
                {props.btnType == 'chip' ? (
                    <Grid item>
                        <CustomChip
                            icon={icon}
                            label={`${btnLable}`}
                            onClick={() => {
                                handelPopup(`${btnLable}`, `${modalName}`);
                            }}
                            style={{ fontWeight: 'bold' }}
                            color="green"
                        />
                    </Grid>
                ) : (
                    <SecondaryButton
                        onClick={() => {
                            handelPopup(`${btnLable}`, `${modalName}`);
                        }}
                    >
                        {`${btnLable}`}
                    </SecondaryButton>
                )}
            </>
        );
    };
    return (
        <Grid container item spacing={0} className="controller-screen">
            <Grid container item xs={12} className="btn-hw" spacing={1}>
                {(status == 'JQ' && data.jobInAgentQuery) || (status == 'EA' && data.jobIsEstimate)
                    ? CostomButton('Revise Estimate', 'showEstimate', AddIcon)
                    : null}
                {/* {(status == 'JQ' || status == 'EA') && data.jobIsEstimate && data.jobInAgentQuery? CostomButton('Revise Estimate', 'showEstimate', AddIcon) : null} */}
                {(status == 'AA' || status == 'JP') && CostomButton('Submit Estimate', 'showEstimate', AddIcon)}
                {(status == 'AA' || status == 'JP' || (status == 'JQ' && data.jobInAgentQuery) || status == 'EA') &&
                    CostomButton('Submit Cost', 'showSubmit', AddIcon)}
                {status == 'AA' && CostomButton('Set In Progress', 'showEstimate', AddIcon)}
                {/* {status !== 'AU' && (
                    <CancelButton vehicleID={vehicleID} jobRegNumber={data.jobRegNumber} position={props.position} goPreviousScreen={props.goPreviousScreen} />
                )} */}
            </Grid>

            {state.showSubmit && (
                <DialogComp title="Are you sure to submit order with zero cost?" maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <SubmitWarnngModal title={state.title} onYes={showSubmitModal} onClose={closeTheDialog} />
                </DialogComp>
            )}
            {state.showSubmitCostModal && (
                <DialogComp title={state.title} maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <AcceptEstimateModal
                        title={state.title}
                        mileageIsRequired={data.mileageRequired}
                        jobOrderTotal={data.jobOrderTotal}
                        jobEstimatedCost={data.jobEstimatedCost}
                        jobOrderDate={data.jobOrderDate}
                        jobAgentOrderNo={data.jobAgentOrderNo}
                        jobQueryJobID={props.jobID}
                        jobRegNumber={data.jobRegNumber}
                        jobMileage={data.jobMileage}
                        jobMileageDate={data.jobMileageDate}
                        onClose={closeTheDialog}
                        jobStatus={status}
                    />
                </DialogComp>
            )}
            {state.showEstimate && (
                <DialogComp title={state.title} maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <JobEstimateModal
                        title={state.title}
                        jobOrderTotal={data.jobOrderTotal}
                        jobEstimatedCost={data.jobEstimatedCost}
                        jobOrderDate={data.jobOrderDate}
                        jobAgentOrderNo={data.jobAgentOrderNo}
                        jobQueryJobID={props.jobID}
                        jobRegNumber={data.jobRegNumber}
                        onClose={closeTheDialog}
                        jobIsEstimate={data.jobIsEstimate}
                    />
                </DialogComp>
            )}
        </Grid>
    );
}

export default ServiceAgentstatusBasedButton;
