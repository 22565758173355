import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormHelperText, MenuItem, TextField } from '@material-ui/core';
import { InputText, SecondaryButton, MandatoryField, SelectBox, YesButton, NoButton } from '../../../Core/Controls';
import { getRetorquepositionId, postRetorquepositionData } from '../../../Core/Services/InternalUser/VOR/retorquePositionsService';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import { useToast } from '../../../Core/Hooks/useToast';
import '../../commonStyle.scss';

function AddEditModal(props) {
    const [state, setState] = useState({
        retorquePositionID: null,
        url: '',
        component: '',
        authType: '',
        parentMenuID: '',
        parentMenuList: [],
        menuItemID: '',
        menuItemList: [],
        lable: '',
        isActive: true,
        errors: {}
    });

    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        if (props.retorquePositionID) {
            let res = await getRetorquepositionId(props.retorquePositionID);
            if (res.success) {
                setState((state) => ({
                    ...state,
                    retorquePositionID: res.data.retorquePositionID,
                    parentMenuID: res.data.parentMenuID,
                    url: res.data.url,
                    component: res.data.component,
                    authType: res.data.authType,
                    lable: res.data.lable
                }));
                const parentMenu = Data.map((item) => ({
                    id: item.parentMenuID,
                    name: item.parentMenuName
                }));
                setState((st) => ({
                    ...st,
                    parentMenuList: parentMenu
                }));
            }
        }
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };
    const handleClick = (name, value) => {
        setState((st) => ({ ...st, [name]: value }));
    };

    const validations = () => {
        const { lable } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(lable)) {
            errors.lable = ' This field is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const retorquepositionData = {
                retorquePositionID: state.retorquePositionID,
                url: state.url,
                component: state.component,
                authType: state.authType,
                lable: state.lable.length,
                parentMenuID: state.parentMenuID
            };
            props.onClose(true);
            // let res = await postRetorquepositionData(retorquepositionData);
            // if (res.success) {
            //     showToastSuccess('Retorque Position Updated sucessfully');
            //     props.onClose(true);
            // } else {
            //     showToastError(res.message);
            // }
        }
    };

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={6} className="mandatory-fields">
                <InputLabel required shrink>
                    Menu Item
                </InputLabel>
                <SelectBox name="menuItemID" value={state.menuItemID || ' '} onChange={handleInputChange} List={Data} />
                <FormHelperText error>{state.errors.menuItemID}</FormHelperText>
            </Grid>
            <Grid item xs={6} className="mandatory-fields">
                <MandatoryField inputLabel="Label" name="lable" value={state.lable} onChange={handleInputChange} />
                <FormHelperText error>{state.errors.lable}</FormHelperText>
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel required shrink>
                    Menu Icons
                </InputLabel>
                <TextField select size="small" variant="outlined" fullWidth>
                    {
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                    }
                    {Data.length > 0 &&
                        Data.map((option) => (
                            <MenuItem key={option.id} value={option.id || ''}>
                                {option.icons}
                            </MenuItem>
                        ))}
                </TextField>
            </Grid>
            <Grid item xs={6}>
                <InputLabel shrink>Parent Menu</InputLabel>
                <SelectBox name="parentMenuID" value={state.parentMenuID || ' '} onChange={handleInputChange} List={ParentMenuData} />
                {/* <FormHelperText error>{state.errors.parentMenuID}</FormHelperText> */}
            </Grid>

            <Grid item xs={6} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Sequence"
                    type="number"
                    name="sequence"
                    placeholder="Sequence"
                    onChange={handleInputChange}
                    value={state.sequence}
                    errors={state.errors.sequence}
                />
            </Grid>

            {/* <Grid xs={12} container style={{ margin: 8 }}>
                <Grid xs={12} container spacing={1}>
                    <Grid item xs={12}>
                        Is Active ?
                    </Grid>
                    <Grid item xs={6}>
                        <YesButton state={state.isActive} name="isActive" onClick={() => handleClick('isActive', true)} />
                    </Grid>
                    <Grid item xs={6}>
                        <NoButton state={state.isActive} name="isActive" onClick={() => handleClick('isActive', false)} />
                    </Grid>
                </Grid>
            </Grid> */}

            <Grid item xs={12} className="submit_btn">
                <SecondaryButton onClick={submitHandler}>{props.retorquePositionID ? 'Update' : 'Add'}</SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default AddEditModal;

const ParentMenuData = [
    {
        id: 1,
        name: 'Driver'
    },
    {
        id: 2,
        name: 'Jobs'
    },
    {
        id: 3,
        name: 'Events'
    },
    {
        id: 4,
        name: 'Vehicles'
    }
];

const Data = [
    {
        id: 1,
        name: 'License Types',
        icons: <AcUnitIcon style={{ fontSize: '17px' }} />
    },
    {
        id: 2,
        name: 'Cost Categories',
        icons: <AirlineSeatReclineExtraIcon style={{ fontSize: '17px' }} />
    },
    {
        id: 3,
        name: 'Slippage Reasons',
        icons: <ApartmentIcon style={{ fontSize: '17px' }} />
    },
    {
        id: 4,
        name: 'Fuel Types',
        icons: <AllInclusiveIcon style={{ fontSize: '17px' }} />
    }
];
