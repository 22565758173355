import React, { useEffect, useState, useMemo, createContext, useCallback } from 'react';
import { AddButton, DataGrid, EditButton, formatters } from '../../../../Core/Controls';
import '../../../commonStyle.scss';
import { getAllFuelPurchase } from '../../../../Core/Services/InternalUser/ControllerService/fuelPurchaseService';
import FuelPurchaseModal from './addEditFuelPurchase';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { useToast } from '../../../../Core/Hooks/useToast';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: '5%'
    },
    { key: 'fuelPurchaseDate', name: 'Purchase Date', formatter: formatters.Date_DDMMMYYYY_HHMM },
    { key: 'fuelPurchaseLitres', name: 'Litres' },
    { key: 'fuelPurchaseCost', name: 'Cost' },
    { key: 'paymentMethod', name: 'Payment Method' },
    { key: 'fuelPurchaseNotes', name: 'Notes' }
];

export const FuelPurchaseDashboard = (props) => {
    const { showToastError, showToastSuccess } = useToast();
    let checkhaveAccess = AppStorage.getAllowAcces();
    const [state, setState] = useState({
        show: false,
        showLoader: true,
        FuelPurchaseList: [],
        fuelID: null
    });

    const handleAdd = (record) => {
        setState((st) => ({
            ...st,
            show: true,
            fuelID: record
        }));
    };

    const title = `${state.fuelID ? 'Update' : 'Add'} Fuel`;

    const pullFuelPurchaseAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    useEffect(async () => {
        pullFuelPurchaseAndUpdateState();
    }, [props.VehicleID]);

    const closeFuelPurchasesModal = (res) => {
        setState((st) => ({
            ...st,
            show: false,
            showDelete: false
        }));
        res && pullFuelPurchaseAndUpdateState();
    };

    const dataFormatter = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleAdd(row.fuelPurchaseID)} toolTipTitle="Edit Fuel" />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);
    const gridBtnTitle = `Add Fuel`;
    return (
        <div>
            <GijgoGrid
                columns={columns}
                getUrl={`FuelPurchases?VehicleID=${props.VehicleID}`}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                btnTitle={checkhaveAccess?.CanUpdateVehicleFuelRecords ? gridBtnTitle : ''}
                onBtnClick={() => handleAdd(null)}
            />
            {state.show && (
                <DialogComp title={title} onClose={() => closeFuelPurchasesModal(false)} maxWidth="sm">
                    <FuelPurchaseModal fuelID={state.fuelID} VehicleID={props.VehicleID} onFormSubmit={closeFuelPurchasesModal} {...props} />
                </DialogComp>
            )}
        </div>
    );
};

export default FuelPurchaseDashboard;
