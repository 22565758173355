import { FormHelperText, Grid, InputLabel, Paper, Tooltip, Typography } from '@material-ui/core';
import { InputText } from '../../../../../../Core/Controls';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import DeleteIcon from '@material-ui/icons/Delete';
import SignaturePad from 'react-signature-canvas';
import { useContext } from 'react';
import { InspectionCheCkContaxt } from './InspectionCheckProvide';

export const InSpectionSignOff = ({ classes }) => {
    const { state, useDefaultSig, clear, GetTechnicianSign, signCanvas, portalSettings, flags, handleInput } = useContext(InspectionCheCkContaxt);

    return (
        <>
            <Grid item xs={12} md={12}>
                <Paper className={classes.paper}>
                    <Grid container xs={12}>
                        <h3 className={classes.headings}>Vehicle Inspector</h3>
                    </Grid>
                    <Grid container xs={12}>
                        <div
                            className={classes.headings}
                            style={{ marginTop: -20 }}
                            dangerouslySetInnerHTML={{ __html: portalSettings.portalSettingTechnicianDeclaration }}
                        />
                    </Grid>
                    <Grid container spacing={1} justify="space-between">
                        <Grid item xs={12} lg={6}>
                            {/* <Grid item xs={12} md={12} lg={12}> */}
                            <InputLabel shrink>Vehicle Checker Comment</InputLabel>
                            <InputText
                                multiline
                                rows={8}
                                placeholder="Vehicle checker comment"
                                name="vehicleInspectionInspectionComment"
                                onChange={handleInput}
                                value={state.vehicleInspectionInspectionComment}
                                fullWidth
                            />
                            <FormHelperText error>{state.errors?.vehicleInspectionInspectionComment}</FormHelperText>
                            {/* </Grid> */}
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            {/* <Grid item xs={12} sm={12} md={12} lg={12} className="mandatory-fields"> */}
                            <div style={{ position: 'relative' }}>
                                <InputLabel shrink>
                                    Signature{' '}
                                    {state.signAvailable ? (
                                        <Tooltip title="Use default signature">
                                            <BorderColorIcon onClick={useDefaultSig} style={{ cursor: 'pointer', margin: '0px 0px -5px 5px' }} />
                                        </Tooltip>
                                    ) : null}
                                </InputLabel>
                                <div
                                    style={{
                                        position: 'absolute',
                                        right: 0
                                    }}
                                >
                                    <Tooltip title="Clear Signature">
                                        <DeleteIcon
                                            onClick={clear}
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </Tooltip>
                                </div>

                                {state.imgtechniciansign ? (
                                    <div className="sigContainer">
                                        <img
                                            src={state.techniciansign}
                                            alt="my signature"
                                            style={{
                                                display: 'block',
                                                width: 500
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className="sigContainer">
                                        <SignaturePad
                                            ref={signCanvas}
                                            canvasProps={{ className: 'sigPad' }}
                                            // canvasProps={{ width: 770, height: 130, className: "sigCanvas" }}
                                            onEnd={() => {
                                                GetTechnicianSign();
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                            <FormHelperText error>{state.errors?.techniciansign}</FormHelperText>
                            {/* </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid container xs={12}>
                        <h5 className={classes.headings} style={{ height: 20 }}>
                            &nbsp;
                        </h5>
                    </Grid>
                </Paper>
            </Grid>
        </>
    );
};
