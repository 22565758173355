import { CircularProgress, Grid, InputLabel, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { CustomNewColorButton, CustomColorButton, formatters, SecondaryButton } from '../../../../Core/Controls';
import '../../../commonStyle.scss';
import { postSetVehicleMOTandTax, getVehicleMOTandTax } from '../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { useToast } from '../../../../Core/Hooks/useToast';
import { useEffect } from 'react';
import { useLoader } from '../../../../Core/Hooks/useLoader';

const SetVehicleMOTTaxExpiryDate = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const { showLoader, hideLoader } = useLoader();

    const [state, setState] = useState({
        motExpiryDate: null,
        taxExpiryDate: null,
        showLoader: true,
        msg: null
    });
    useEffect(async () => {
        let res = await getVehicleMOTandTax(props.vehicleId, props?.regNo);
        if (res.success) {
            setState((st) => ({
                ...st,
                motExpiryDate: res.data.motExpiryDate,
                taxExpiryDate: res.data.taxExpiryDate,
                taxStatus: res.data.taxStatus,
                msg: res?.data?.message || null,
                showLoader: false
            }));
        }
    }, []);
    const handleData = async () => {
        let data = {
            motExpiryDate: state.motExpiryDate,
            taxExpiryDate: state.taxExpiryDate,
            taxStatus: state.taxStatus,
            VehicleID: props.vehicleId
        };
        let res = await postSetVehicleMOTandTax(data);
        if (res.success) {
            showToastSuccess(res?.data?.message);
            props.onFormSubmit(true);
        } else {
            showToastError(res?.message || 'Something went wrong!');
        }
    };
    return (
        <>
            <Grid container spacing={1}>
                {state.showLoader ? (
                    <Grid item sm={12} container alignItems="center" justify="center" style={{ width: 'calc(100vh - 230px)', height: 150 }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="body1">Latest Dates from DVLA</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1">MOT Due Date </Typography>
                            <Typography>{formatters.DateOnlyFormatter(state.motExpiryDate)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1">TAX Due</Typography>
                            <Typography>{formatters.DateOnlyFormatter(state.taxExpiryDate)}</Typography>
                        </Grid>

                        {state.msg != null ? (
                            <>
                                <Grid item xs={12} md={12} className="mandatory-fields">
                                    <Typography variant="body1" color="secondary">
                                        {state.msg}
                                    </Typography>
                                </Grid>
                                <Grid xs={12} item className="submit_btn">
                                    <SecondaryButton onClick={props.onClose}>Close</SecondaryButton>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12} md={12} className="mandatory-fields">
                                    <Typography variant="body1" color="secondary">
                                        Do you want to update vehicle with the above dates from DVLA?
                                    </Typography>
                                </Grid>
                                <Grid xs={6} item className="submit_btn">
                                    <CustomNewColorButton BtnColor="green" onClick={handleData}>
                                        Yes
                                    </CustomNewColorButton>
                                </Grid>
                                <Grid xs={6} item className="submit_btn">
                                    <SecondaryButton onClick={props.onClose}>No</SecondaryButton>
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </Grid>
        </>
    );
};

export default SetVehicleMOTTaxExpiryDate;
