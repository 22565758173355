import React, { useContext, useEffect } from 'react';
import { FormHelperText, Typography, InputLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ContractContext } from './contractProvider';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
import { FromLoader } from '../../../../../Core/Loader/formLoader';
import { useState } from 'react';
import { getContractHistoryById, VehicleContracts_GetNotesHistory } from '../../../../../Core/Services/InternalUser/contractService';
import { Satellite } from '@material-ui/icons';
import moment from 'moment';
import { formatters } from '../../../../../Core/Controls';

const CurrencyFormatterSpan = (val) => {
    return (
        // <span
        //     style={{
        //         textAlign: 'left',
        //         width: '100%'
        //     }}
        // >
        val ? `£${val?.toFixed(2).replace(/(\d)(?=( \d{3})+(?!\d)) /g, '$1,')}` : '00'
        // </span>
    );
};
export const JobSingleNote = (props) => {
    const { notes } = props.note;
    console.log(notes);
    return (
        <>
            <div className="single-note">
                <div className="msg" style={{ fontSize: 14, fontStyle: 'italic', cursor: 'pointer', padding: '5px 10px' }}>
                    {notes || 'N/A'}
                </div>
            </div>
        </>
    );
};
const ContractHistoryDetails = (props) => {
    const [state, setState] = useState({ selectedData: {}, contractNotesList: [] });
    useEffect(() => {
        const pullData = async () => {
            let res = await getContractHistoryById(props.id);
            console.log(res, 'resp');
            if (res.success) {
                setState((st) => ({ selectedData: res?.data?.list[0] }));
            }
            // console.log(res, "res");
            let contractNotesRes = await VehicleContracts_GetNotesHistory(props.id);
            setState((st) => ({ ...st, contractNotesList: contractNotesRes?.data?.notes?.table || [] }));
        };
        pullData();
    }, [props.id]);

    return (
        <>
            <Grid container item spacing={1} style={{ padding: '10px 0 5px 1px' }}>
                <Grid item md={12} lg={8}>
                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="grid-style white-grid">
                        <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                            <FormHelperText shrink>Contract Reference</FormHelperText>
                            <Typography variant="button">{state.selectedData?.ref}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                            <FormHelperText shrink>Contract Type</FormHelperText>
                            <Typography variant="button">{state.selectedData?.contractType}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                            <FormHelperText shrink>Spreader Type</FormHelperText>
                            <Typography variant="button">{state.selectedData?.spreaderTypeDesc || 'N\\A'}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="grid-style gray-grid">
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <FormHelperText shrink>{state.selectedData.contractTypeID == 1 ? 'Contract Commencement' : 'Start date'}</FormHelperText>
                            <Typography variant="button">
                                {(state.selectedData?.contractStartDate && moment(state.selectedData?.contractStartDate).format('YYYY-MM-DD')) || 'N\\A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <FormHelperText shrink>{state.selectedData.contractTypeID == 1 ? 'Contract Termination Date' : 'End date'}</FormHelperText>
                            <Typography variant="button">
                                {(state.selectedData?.contractEndDate && moment(state.selectedData?.contractEndDate).format('YYYY-MM-DD')) || 'N\\A'}
                            </Typography>
                        </Grid>
                        {state.selectedData.contractTypeID == 1 && (
                            <>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Winter Term (Weeks)</FormHelperText>
                                    <Typography variant="button">{state.selectedData?.contractWinterTerms}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Winter Start date</FormHelperText>
                                    <Typography variant="button">
                                        {(state.selectedData?.contractWinterStartDate &&
                                            moment(state.selectedData?.contractWinterStartDate).format('YYYY-MM-DD')) ||
                                            'N\\A'}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="grid-style white-grid">
                        {state.selectedData.contractTypeID == 1 && (
                            <>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Winter End date</FormHelperText>
                                    <Typography variant="button">
                                        {(state.selectedData?.contractWinterEndDate &&
                                            moment(state.selectedData?.contractWinterEndDate).format('YYYY-MM-DD')) ||
                                            'N\\A'}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <FormHelperText shrink>Extended Date</FormHelperText>
                            <Typography variant="button">
                                {(state.selectedData?.contractExtendedDate && moment(state.selectedData?.contractExtendedDate).format('YYYY-MM-DD')) || 'N\\A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <FormHelperText shrink>Purchase Order</FormHelperText>
                            <Typography variant="button">{state.selectedData?.contractPurchaseOrder}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <FormHelperText shrink>Invoice Frequency </FormHelperText>
                            <Typography variant="button">{state.selectedData?.contractInvoiceFrequency}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="grid-style gray-grid">
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <FormHelperText shrink>Invoice All Year</FormHelperText>
                            <Typography variant="button">{state.selectedData?.contractInvoiceAllYear ? 'Yes' : 'No' || 'N\\A'}</Typography>
                        </Grid>
                        {state.selectedData.contractTypeID == 1 && (
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <FormHelperText shrink>Framework</FormHelperText>
                                <Typography variant="button">{state.selectedData?.contractFramework || 'N\\A'}</Typography>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <Grid container>
                                <Grid item xs={7}>
                                    <FormHelperText shrink>Annual Price Increase</FormHelperText>
                                    <Typography variant="button">{state.selectedData?.contractAnnualPriceIncrease ? 'Yes' : 'No' || 'N\\A'}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <FormHelperText shrink>Increase %</FormHelperText>
                                    <Typography variant="button">{state.selectedData?.contractAnnualPriceIncrease || 'N\\A'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <FormHelperText shrink>Increase Date</FormHelperText>
                            <Typography variant="button">
                                {(state.selectedData?.contractIncreaseDate && moment(state.selectedData?.contractIncreaseDate).format('YYYY-MM-DD')) || 'N\\A'}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="grid-style white-grid">
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <FormHelperText shrink>Depot</FormHelperText>
                            <Typography variant="button">{state.selectedData?.depot || 'N\\A'}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <FormHelperText shrink>Service Area</FormHelperText>
                            <Typography variant="button">{state.selectedData?.serviceArea || 'N\\A'}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <FormHelperText shrink>Engineer</FormHelperText>
                            <Typography variant="button">{state.selectedData?.engineer || 'N\\A'}</Typography>
                        </Grid>
                        {state.selectedData.contractTypeID == 1 && (
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <FormHelperText shrink>Summer Service Area</FormHelperText>
                                <Typography variant="button">{state.selectedData?.summerServiceArea || 'N\\A'}</Typography>
                            </Grid>
                        )}
                    </Grid>

                    {state.selectedData.contractTypeID == 1 && (
                        <>
                            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="grid-style gray-grid">
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Delivery Charge</FormHelperText>
                                    <Typography variant="button">
                                        {formatters.CurrencyFormatter(state.selectedData?.contractDeliveryCharge) || 'N\\A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Delivery Arranged?</FormHelperText>
                                    <Typography variant="button">{state.selectedData?.contractDeliveryArranged ? 'Yes' : 'No'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Delivery Date</FormHelperText>
                                    <Typography variant="button">
                                        {(state.selectedData?.contractDeliveryDate && moment(state.selectedData?.contractDeliveryDate).format('YYYY-MM-DD')) ||
                                            'N\\A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Onsite Date</FormHelperText>
                                    <Typography variant="button">
                                        {(state.selectedData?.contractOnsiteDate && moment(state.selectedData?.contractOnsiteDate).format('YYYY-MM-DD')) ||
                                            'N\\A'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="grid-style white-grid">
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Collection Charge</FormHelperText>
                                    <Typography variant="button">
                                        {' '}
                                        {formatters.CurrencyFormatter(state.selectedData?.contractCollectionCharge) || 'N\\A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Collection Arranged?</FormHelperText>
                                    <Typography variant="button"> {state.selectedData?.contractCollectionArranged ? 'Yes' : 'No' || 'N\\A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Collection Date</FormHelperText>
                                    <Typography variant="button">
                                        {' '}
                                        {(state.selectedData?.contractCollectionDate &&
                                            moment(state.selectedData?.contractCollectionDate).format('YYYY-MM-DD')) ||
                                            'N\\A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Offhire Override</FormHelperText>
                                    <Typography variant="button">{state.selectedData?.contractOffhireOverride ? 'Yes' : 'No' || 'N\\A'}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="grid-style gray-grid">
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Rate (Weekly)</FormHelperText>
                                    <Typography variant="button">{formatters.CurrencyFormatter(state.selectedData?.contractWeeklyRental) || 'N\\A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Rate Daily</FormHelperText>
                                    <Typography variant="button">
                                        {formatters.CurrencyFormatter(state.selectedData?.contractWeeklyRental / 7) || 'N\\A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Return Date</FormHelperText>
                                    <Typography variant="button">
                                        {' '}
                                        {(state.selectedData?.contractReturnDate && moment(state.selectedData?.contractReturnDate).format('YYYY-MM-DD')) ||
                                            'N\\A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Collection Charge Date</FormHelperText>
                                    <Typography variant="button">
                                        {' '}
                                        {(state.selectedData?.contractCollectionChargeDate &&
                                            moment(state.selectedData?.contractCollectionChargeDate).format('YYYY-MM-DD')) ||
                                            'N\\A'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="grid-style white-grid">
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Mileage (KMs)</FormHelperText>
                                    <Typography variant="button">{state.selectedData?.contractMileage || 'N\\A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FormHelperText shrink>Excess Mileage (pence per KM)</FormHelperText>
                                    <Typography variant="button">{state.selectedData?.contractExcessMileage || 'N\\A'}</Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="grid-style gray-grid">
                        {state.selectedData.contractTypeID == 2 && (
                            <>
                                <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                                    <FormHelperText shrink>Annual Rate</FormHelperText>
                                    <Typography variant="button">{state.selectedData?.contractAnnualRate}</Typography>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                            <FormHelperText shrink>Notes</FormHelperText>
                            <Typography variant="button">{state.selectedData?.contractNotes || 'N\\A'}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="grid-style white-grid">
                        {state.selectedData.contractTypeID == 1 && (
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container>
                                            <Grid item xs>
                                                <FormHelperText shrink>Plough Required?</FormHelperText>
                                                {/* <Typography>
                                                {state.selectedData?.contractPloughRequired ? 'Yes' : 'No' || 'N\\A'}
                                                {formatters.CurrencyFormatter(state.selectedData?.contractPloughCharge) || 'N\\A'}
                                            </Typography> */}
                                                <Typography variant="button">
                                                    {state.selectedData?.contractPloughRequired ? 'Yes' : 'No' || 'N\\A'}
                                                    {state.selectedData?.contractPloughCharge &&
                                                        `(${CurrencyFormatterSpan(state.selectedData?.contractPloughCharge)})`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormHelperText shrink>Plough</FormHelperText>
                                        <Typography variant="button">{state.selectedData?.contractPloughDescription || 'N\\A'}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <Grid container>
                                        <Grid item xs>
                                            <FormHelperText shrink>Camera?</FormHelperText>
                                            <Typography variant="button">
                                                {state.selectedData?.contractCamera ? 'Yes' : 'No' || 'N\\A'}{' '}
                                                {state.selectedData.contractTypeID == 1 && (
                                                    <>
                                                        {state.selectedData?.contractCameraCharge &&
                                                            `(${CurrencyFormatterSpan(state.selectedData?.contractCameraCharge)})`}
                                                    </>
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormHelperText shrink>Camera</FormHelperText>
                                    <Typography variant="button">{state.selectedData?.contractCameraDescription || 'N\\A'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <Grid container>
                                        <Grid item xs>
                                            <FormHelperText shrink>Tracker?</FormHelperText>
                                            <Typography variant="button">
                                                {state.selectedData?.contractTracker ? 'Yes' : 'No' || 'N\\A'}
                                                {state.selectedData.contractTypeID == 1 && (
                                                    <>
                                                        {state.selectedData?.contractTrackerCharge &&
                                                            `(${CurrencyFormatterSpan(state.selectedData?.contractTrackerCharge)})`}
                                                    </>
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormHelperText shrink>Tracker</FormHelperText>
                                    <Typography variant="button">{state.selectedData?.contractTrackerDescription || 'N\\A'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <Grid container>
                                        <Grid item xs>
                                            <FormHelperText shrink>Navigation?</FormHelperText>
                                            <Typography variant="button">
                                                {state.selectedData?.contractNavigation ? 'Yes' : 'No' || 'N\\A'}
                                                {state.selectedData.contractTypeID == 1 && (
                                                    <>
                                                        {state.selectedData?.contractNavigationCharge &&
                                                            `(${CurrencyFormatterSpan(state.selectedData?.contractNavigationCharge)})`}
                                                    </>
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormHelperText shrink>Navigation</FormHelperText>
                                    <Typography variant="button">{state.selectedData?.contractNavigationDescription || 'N\\A'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="grid-style gray-grid">
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <Grid container>
                                        <Grid item xs>
                                            <FormHelperText shrink>Onboard Weighing?</FormHelperText>
                                            <Typography variant="button">
                                                {state.selectedData?.contractOnboardWeighing ? 'Yes' : 'No' || 'N\\A'}
                                                {state.selectedData.contractTypeID == 1 && (
                                                    <>
                                                        {state.selectedData?.contractOnboardWeighingCharge &&
                                                            `(${CurrencyFormatterSpan(state.selectedData?.contractOnboardWeighingCharge)})`}
                                                    </>
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormHelperText shrink>Onboard Weighing</FormHelperText>
                                    <Typography variant="button">{state.selectedData?.contractOnboardWeighingDescription || 'N\\A'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <Grid container>
                                        <Grid item xs>
                                            <FormHelperText shrink>Temp Probe?</FormHelperText>
                                            <Typography variant="button">
                                                {state.selectedData?.contractTempProbe ? 'Yes' : 'No' || 'N\\A'}
                                                {state.selectedData.contractTypeID == 1 && (
                                                    <>
                                                        {state.selectedData?.contractTempProberCharge &&
                                                            `(${CurrencyFormatterSpan(state.selectedData?.contractTempProberCharge)})`}
                                                    </>
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormHelperText shrink>Temp Probe</FormHelperText>
                                    <Typography variant="button">{state.selectedData?.contractTempProberDescription || 'N\\A'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {state.selectedData.contractTypeID == 1 && (
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container>
                                            <Grid item xs>
                                                <FormHelperText shrink>MOT?</FormHelperText>
                                                <Typography variant="button">
                                                    {state.selectedData?.contractMOT ? 'Yes' : 'No' || 'N\\A'}
                                                    {state.selectedData.contractTypeID == 1 && (
                                                        <>
                                                            {state.selectedData?.contractMOTCharge &&
                                                                `(${CurrencyFormatterSpan(state.selectedData?.contractMOTCharge)})`}
                                                        </>
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormHelperText shrink>MOT</FormHelperText>
                                        <Typography variant="button">{state.selectedData?.contractMOTDescription || 'N\\A'}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <Grid container>
                                        <Grid item xs>
                                            <FormHelperText shrink>Mid Season Calibration?</FormHelperText>
                                            <Typography variant="button">
                                                {state.selectedData?.contractMidSeasonCalibration ? 'Yes' : 'No' || 'N\\A'}
                                                {state.selectedData.contractTypeID == 1 && (
                                                    <>
                                                        {state.selectedData?.contractMidSeasonCalibrationCharge &&
                                                            `(${CurrencyFormatterSpan(state.selectedData?.contractMidSeasonCalibrationCharge)})`}
                                                    </>
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormHelperText shrink>Mid Season Calibration</FormHelperText>
                                    <Typography variant="button">{state.selectedData?.contractMidSeasonCalibrationDescription || 'N\\A'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="grid-style white-grid">
                        {state.selectedData.contractTypeID == 1 && (
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container>
                                            <Grid item xs>
                                                <FormHelperText shrink>12 Weekly Brake Roller Test?</FormHelperText>
                                                <Typography variant="button">
                                                    {state.selectedData?.contract12WeeklyBrakeRollerTests ? 'Yes' : 'No' || 'N\\A'}
                                                    {state.selectedData.contractTypeID == 1 && (
                                                        <>
                                                            {state.selectedData?.contract12WeeklyBrakeRollerTestsCharge &&
                                                                `(${CurrencyFormatterSpan(state.selectedData?.contract12WeeklyBrakeRollerTestsCharge)})`}
                                                        </>
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormHelperText shrink>12 Weekly Brake Roller Test</FormHelperText>
                                        <Typography variant="button">{state.selectedData?.contract12WeeklyBrakeRollerTestsDescription || 'N\\A'}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <Grid container>
                                        <Grid item xs>
                                            <FormHelperText shrink>Bespoke?</FormHelperText>
                                            <Typography variant="button">
                                                {state.selectedData?.contractBespoke ? 'Yes' : 'No' || 'N\\A'}
                                                {state.selectedData.contractTypeID == 1 && (
                                                    <>
                                                        {state.selectedData?.contractBespokeCharge &&
                                                            `(${CurrencyFormatterSpan(state.selectedData?.contractBespokeCharge)})`}
                                                    </>
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormHelperText shrink>Bespoke</FormHelperText>
                                    <Typography variant="button">{state.selectedData?.contractBespokeDescription || 'N\\A'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}></Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}></Grid>
                    </Grid>

                    {state.selectedData.contractTypeID == 2 && (
                        <>
                            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="grid-style gray-grid">
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                    <FormHelperText shrink>Cab Beacons</FormHelperText>
                                    <Typography variant="button">{state.selectedData?.contractCabBeacons || 'N\\A'}</Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>

                <Grid item md={12} lg={4}>
                    <div className="single-note">
                        <div
                            className="msg"
                            style={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                padding: '5px 10px',
                                backgroundColor: '#F5F5F5',
                                border: '1px solid rgba(0,0,0,0.2)',
                                height: '35px'
                            }}
                        >
                            History
                        </div>
                    </div>
                    <div
                        style={{
                            padding: '5px',
                            minHeight: '430px',
                            // maxHeight: '430px',
                            // height: 'calc(100vh - 240px)',
                            overflow: 'auto',
                            border: '1px solid rgba(0,0,0,0.2)'
                        }}
                    >
                        {state.contractNotesList?.length > 0 ? (
                            <>
                                {state.contractNotesList?.map((n, i) => (
                                    <div style={{ background: i % 2 ? '#F6EEEC' : '#ECF4F6', padding: '5px 10px' }}>
                                        <JobSingleNote key={i} note={n} />
                                    </div>
                                ))}
                            </>
                        ) : (
                            <div style={{ background: '#ECF4F6', padding: '5px 10px' }}>No Record Found</div>
                        )}
                    </div>
                </Grid>
            </Grid>
        </>
    );
};
export default ContractHistoryDetails;
