import { postWithAuth, getWithAuth, putWithAuth } from '../../../../Basic/HttpService';

export const postAgentArrivalTime = async (data) => {
    let res = await postWithAuth(`VehicleDefects/AgentArrived`, data);
    return res;
};
export const postVehicalRecoveredTime = async (data) => {
    let res = await postWithAuth(`VehicleDefects/VehicleRecovered`, data);
    return res;
};
export const postWorkinProgress = async (data) => {
    let res = await postWithAuth(`VehicleDefects/WorkInProgress`, data);
    return res;
};
export const postJobStepper = async (data) => {
    let res = await postWithAuth(`VehicleDefects/CreateDefectJob`, data);
    return res;
};
export const postTechnicianSignOff = async (data) => {
    let res = await postWithAuth(`VehicleDefects/DefectResolved`, data);
    return res;
};
export const postAdministratorSignOff = async (data) => {
    let res = await postWithAuth(`VehicleDefects/DefectSignedOff`, data);
    return res;
};
