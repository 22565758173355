import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { getAllDriverDash } from '../../../Core/Services/Driver/driverDashboard';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import HealingRoundedIcon from '@material-ui/icons/HealingRounded';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import PersonIcon from '@material-ui/icons/Person';

import { Link } from 'react-router-dom';
import '../../InternalUser/InternalDash.scss';

const AdminDashboard = () => {
    const [state, setState] = useState({
        rows_new: [],
        tilesData: []
    });

    const checkOut = 'Check Out';
    const checkIn = 'Check In';
    const driverCheck = 'Driver Check';

    useEffect(async () => {
        let res = await getAllDriverDash();
        const cards = res.data.dashboard;
        setState((st) => ({
            ...st,
            tilesData: cards[0]
        }));
    }, []);

    return (
        <div className="internal-dashboard">
            <Container className="cardGrid" maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link
                            style={{ textDecoration: 'none' }}
                            color="inherit"
                            to={{ pathname: `/maintenance/dash/Breakdown`, state: { breakdownType: 'BO' } }}
                        >
                            <BuildRoundedIcon className="red_card_icon" />
                            <Card className="red_cardDesign ">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">{state.tilesData.breakdownOnsite || 0}</Typography>
                                    <Typography component="h1">Breakdowns Onsite</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link
                            style={{ textDecoration: 'none' }}
                            color="inherit"
                            to={{ pathname: `/maintenance/dash/Breakdown`, state: { breakdownType: 'BR' } }}
                        >
                            <LocalShippingRoundedIcon className="red_card_icon" />
                            <Card className="red_cardDesign">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">{state.tilesData.breakdownRoadside || 0}</Typography>
                                    <Typography component="h1">Breakdowns Roadside</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/maintenance/dash/Defects`}>
                            <HealingRoundedIcon className="yellow_card_icon" />
                            <Card className="yellow_cardDesign">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">{state.tilesData.driverDefect || 0}</Typography>
                                    <Typography component="h1">Driver Defect</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/internalDailyChecks/all`}>
                            <CheckCircleOutlineIcon className="purple_iconStyle" />
                            <Card className="purple_cardStyle">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">{state.tilesData.driverCheck || 0}</Typography>
                                    <Typography component="h1">Driver Check</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/internalDailyChecks/failed`}>
                            <CancelOutlinedIcon className="red_card_icon" />
                            <Card className="red_cardDesign">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">{state.tilesData.failedChecks || 0}</Typography>
                                    <Typography component="h1">Failed Check</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/maintenance/dash/${checkIn}`}>
                            <CancelOutlinedIcon className="red_card_icon" />
                            <Card className="red_cardDesign">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">{state.tilesData.checkIn || 0}</Typography>
                                    <Typography component="h1">Check In</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={4} lg={3} className="upper">
                        <Link
                            style={{ textDecoration: 'none' }}
                            color="inherit"
                            // to={`/`}
                            to={`/maintenance/checkout`}
                        >
                            <PersonIcon className="red_card_icon" />
                            <Card className="red_cardDesign">
                                <CardContent className="cardContent">
                                    <Typography className="numaricData">{state.tilesData.checkedInCount || 0}</Typography>
                                    <Typography component="h1">Check Out</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default AdminDashboard;
