import { postWithAuth, getWithAuth } from "../../Basic/HttpService";

export const getAllVehicleCategories = async () => {
  let res = await getWithAuth(`VehicleCategories`);
  return res;
};

export const getVehicleCategoriesById = async (id) => {
  let res = await getWithAuth(`VehicleCategories/${id}`);
  return res;
};

export const postVehicleCategoriesData = async (data) => {
  let res = await postWithAuth(`VehicleCategories`, data);
  return res;
};
