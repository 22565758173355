import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { InputText, SecondaryButton, DatePicker, YesButton, NoButton } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';

import moment from 'moment';
import { ContractSetOffHire } from '../../../../../Core/Services/InternalUser/InternalDashService';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import ConfirmationModal from '../../../../../Core/Modal/ConfirmationModal';

const ContractSetOffHireModal = (props) => {
    const { authorityReference, winterEndDate } = props;

    const [state, setState] = useState({
        errors: {},
        contractOffHireDate: winterEndDate ? moment(winterEndDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        contractOffHireAuthorityReference: authorityReference || ''
        // contractPaused: false
    });

    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);

    const onFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const validations = () => {
        const { contractOffHireDate } = state;
        let formIsValid = true;
        let errors = {};
        if (!contractOffHireDate) {
            errors.contractOffHireDate = 'Date is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    // const SubmitHandler = async () => {
    //     if (validations()) {
    //         setBtnLoader(true);
    //         const totalVehicles = props.selectedRecords.length;
    //         let failedResponses = 0;

    //         for (let index = 0; index < totalVehicles; index++) {
    //             const vehicle = props.selectedRecords[index];
    //             const isAfter = moment(vehicle.earliestOffHire).isAfter(moment(state.contractOffHireDate).format('YYYY-MM-DD'));

    //             if (isAfter) {
    //                 // Show confirmation modal
    //                 const confirm = await new Promise((resolve) => {
    //                     setState((st) => ({
    //                         ...st,
    //                         showCancelConfirmModal: true,
    //                         confirmResolve: resolve,
    //                         confirmMessage: `${vehicle.reg ? `${vehicle.reg} earliest` : 'Earliest'}  off hire date (${moment(vehicle.earliestOffHire).format(
    //                             'YYYY-MM-DD'
    //                         )}) is later than entered winter off hire date (${state.contractOffHireDate}). Do you wish to continue?`
    //                     }));
    //                 });

    //                 if (!confirm) {
    //                     // If user cancels, skip to the next vehicle
    //                     continue;
    //                 }
    //             }

    //             try {
    //                 let response = await ContractSetOffHire({
    //                     contractVehicleID: vehicle.contractVehicleID,
    //                     contractOffHireDate: state.contractOffHireDate,
    //                     contractOffHireAuthorityReference: state.contractOffHireAuthorityReference
    //                 });

    //                 showToastSuccess(`Vehicle ${index + 1} of ${totalVehicles}: ${response.success ? 'Success' : 'Failed'}`);
    //                 if (!response.success) failedResponses += 1;
    //             } catch (error) {
    //                 showToastError(`An error occurred for vehicle ${index + 1}: ${error.message}`);
    //                 failedResponses += 1;
    //             }
    //         }

    //         // Final success or failure message
    //         if (failedResponses === 0) {
    //             showToastSuccess('Vehicle off-hire details saved successfully.');
    //             props.onClose(true);
    //         } else {
    //             showToastError(`${failedResponses} vehicle(s) failed.`);
    //         }

    //         setBtnLoader(false);
    //     }
    // };

    const SubmitHandler = async () => {
        if (validations()) {
            setBtnLoader(true);
            const totalVehicles = props.selectedRecords.length;
            let failedResponses = 0;

            for (let index = 0; index < totalVehicles; index++) {
                const vehicle = props.selectedRecords[index];
                const isAfter = moment(vehicle.earliestOffHire).isAfter(moment(state.contractOffHireDate).format('YYYY-MM-DD'));

                if (isAfter) {
                    // Show confirmation modal
                    const confirm = await new Promise((resolve) => {
                        setState((st) => ({
                            ...st,
                            showCancelConfirmModal: true,
                            confirmResolve: resolve,
                            confirmMessage: `${vehicle.reg ? `${vehicle.reg} earliest` : 'Earliest'} off hire date (${moment(vehicle.earliestOffHire).format(
                                'YYYY-MM-DD'
                            )}) is later than entered winter off hire date (${state.contractOffHireDate}). Do you wish to continue?`
                        }));
                    });

                    if (!confirm) {
                        // If user cancels, stop further processing
                        setBtnLoader(false);
                        return;
                    }
                }

                try {
                    let response = await ContractSetOffHire({
                        contractVehicleID: vehicle.contractVehicleID,
                        contractOffHireDate: state.contractOffHireDate,
                        contractOffHireAuthorityReference: state.contractOffHireAuthorityReference
                    });

                    showToastSuccess(`Vehicle ${index + 1} of ${totalVehicles}: ${response.success ? 'Success' : 'Failed'}`);
                    if (!response.success) failedResponses += 1;
                } catch (error) {
                    showToastError(`An error occurred for vehicle ${index + 1}: ${error.message}`);
                    failedResponses += 1;
                }
            }

            // Final success or failure message
            if (failedResponses === 0) {
                showToastSuccess('Vehicle off-hire details saved successfully.');
                props.onClose(true);
            } else {
                showToastError(`${failedResponses} vehicle(s) failed.`);
            }

            setBtnLoader(false);
        }
    };

    const handleModalSubmit = () => {
        setState((st) => ({
            ...st,
            showCancelConfirmModal: false
        }));
        state.confirmResolve(true);
    };

    const handleModalCancel = () => {
        setState((st) => ({
            ...st,
            showCancelConfirmModal: false
        }));
        if (state.confirmResolve) {
            state.confirmResolve(false);
        }
    };

    // const SubmitHandler = async () => {
    //     if (validations()) {
    //         setBtnLoader(true);
    //         let promises = props.checkedVehicleList.map((q) => {
    //             return ContractSetOffHire({
    //                 contractVehicleID: q,
    //                 contractOffHireDate: state.contractOffHireDate,
    //                 contractOffHireAuthorityReference: state.contractOffHireAuthorityReference
    //             });
    //         });

    //         try {
    //             let responses = await Promise.all(promises);

    //             // Check if all responses are successful
    //             let allSuccessful = responses.every((response) => response.success);

    //             if (allSuccessful) {
    //                 showToastSuccess('Vehicle delivery details saved successfully.');
    //                 props.onClose(true);
    //             } else {
    //                 // Find out which responses failed
    //                 let failedResponses = responses.filter((response) => !response.success);
    //                 showToastError(`Error: ${failedResponses.length} items failed.`);
    //             }
    //         } catch (error) {
    //             // Handle any other errors that may occur
    //             showToastError(`An error occurred: ${error.message}`);
    //         }
    //         setBtnLoader(false);
    //     }
    // };
    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Winter Off Hire Date</InputLabel>
                <DatePicker name="contractOffHireDate" placeholder="Off Hire Date" value={state.contractOffHireDate} onChange={onFieldChange} />
                <FormHelperText error>{state.errors.contractOffHireDate}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputLabel shrink>Off Hire Authority Reference</InputLabel>
                <InputText
                    placeholder="Off Hire Authority Reference"
                    value={state.contractOffHireAuthorityReference}
                    onChange={onFieldChange}
                    name="contractOffHireAuthorityReference"
                />
                <FormHelperText error>{state.errors.contractOffHireAuthorityReference}</FormHelperText>
            </Grid>
            {/* <Grid xs={12} container item>
                <InputLabel shrink>Contract Paused ?</InputLabel>
                <Grid xs={12} container item spacing={1}>
                    <Grid item xs={6}>
                        <YesButton state={state.contractPaused} name="contractPaused" onClick={() => handleClick('contractPaused', true)} />
                    </Grid>
                    <Grid item xs={6}>
                        <NoButton state={state.contractPaused} name="contractPaused" onClick={() => handleClick('contractPaused', false)} />
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid item xs={12}>
                <SecondaryButton fullWidth isBtnLoader={btnLoader} className="submit_btn" onClick={SubmitHandler}>
                    Submit
                </SecondaryButton>
            </Grid>
            {state.showCancelConfirmModal && (
                <DialogComp title={'Confirmation'} maxWidth="sm" onClose={handleModalCancel}>
                    <ConfirmationModal
                        message={state.confirmMessage}
                        handleCancel={handleModalCancel}
                        handleSubmit={handleModalSubmit}
                        confirmLabel={'Yes'}
                        cancelLabel={'Abort'}
                    />
                </DialogComp>
            )}
        </Grid>
    );
};

export default ContractSetOffHireModal;
