import { postWithAuth, getWithAuth, putWithAuth } from '../../Basic/HttpService';

export const getjobByType = async (
    start,
    end,
    Id,
    serviceID,
    enggID,
    DepotID,
    makeID,
    modelID,
    vehicleTypeID,
    vehicleCategoryID,
    ownershipTypeID,
    dateFilterID,
    vehicleAgeID,
    costType
) => {
    let res = await getWithAuth(
        `customers/GetCustomerJobSpend?custid=${Id || ''}&startDate=${start}&endDate=${end}&ServiceAgentID=${serviceID}&TechnicianUserID=${enggID}
        &DepotID=${DepotID}
        &make=${makeID}
        &model=${modelID}
        &vehicleType=${vehicleTypeID}
        &vehicleCategory=${vehicleCategoryID}
        &ownershipType=${ownershipTypeID}
        &dateFilter=${dateFilterID}
        &vehicleAge=${vehicleAgeID}&CostTypeCode=${costType}
        `
    );
    // let res = await getWithAuth(`customers/GetCustomerJobSpend?startDate=2020-03-01&endDate=2022-06-28&custId=2`);
    return res;
};
