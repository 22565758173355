import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, FormControlLabel } from '@material-ui/core';
import { SecondaryButton, DatePicker, MandatoryField, formatters } from '../../../Core/Controls';
import { useToast } from '../../../Core/Hooks/useToast';
import { postVehicleBreakdown, getVehicleBreakdownsbyID } from '../../../Core/Services/InternalUser/ServiceAgent/breakdownService';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RoomIcon from '@material-ui/icons/Room';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialogComp from '../../../Core/Dialog/DialogComp';
import GetAgentLocation from '../../InternalUser/Maintenance/BreakDown/getLocations';
import { useHistory } from 'react-router-dom';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';
import moment from 'moment';
import CustomizedSteppers from '../../InternalUser/Maintenance/BreackdownDefectStepper/defectBreackDownStepper';
import BreakDownEditDetails from '../../InternalUser/Maintenance/BreakDown/BreakDownEditDetails';
import BreakDownDetails from '../../InternalUser/Maintenance/BreakDown/BreakDownDetails';
import Mapheader from '../../InternalUser/Maintenance/BreakDown/locationHeaderComp';

let domainName = window.location.host;
const trimedhost = domainName.includes('localhost') ? 'localhost' : domainName;

const AddBreaddownModal = (props) => {
    const { displayName } = useLoginInfo();
    const [btnLoader, setBtnLoader] = useState(false);

    const { showToastSuccess, showToastError } = useToast();
    let history = useHistory();

    const [state, setState] = useState({
        reportedBy: displayName,
        reg: props.regNumber,
        dateOfReg: moment().format('YYYY-DD-MM'),
        defectID: null,
        breakDownType: 'BR',
        driverName: displayName,
        driverPhone: null,
        breakDownLocation: '',
        problemReported: '',
        replacementvehicleRequired: false,
        defectPG9Issued: false,
        mobilityUsed: 0,
        mobilityRequested: 0,
        reportingDepot: null,
        callOutRequired: false,
        defectVOR: false,
        policeOnScene: 0,
        passengersInvolved: false,
        accDamage: false,
        vehicleLoaded: false,
        urgentCallOut: false,
        policeOnScene: false,
        mobilityRequested: false,
        mobilityUsed: false,
        errors: {},
        showJob: false,
        loadType: 1,
        showMap: false,
        vehicleID: props.VehicleID,
        desc: null
    });

    useEffect(async () => {
        setState((st) => ({
            ...st,
            domainName: trimedhost
            // breakDownType: props.breakdownType
        }));
        pullBreackdownById();
    }, []);

    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: true }));
        } else if (value === 'false' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: false }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    }, []);

    const validations = () => {
        let formIsValid = true;
        let errors = {};
        if (!state.breakDownLocation) {
            errors.breakDownLocation = 'Location is required';
            formIsValid = false;
        }
        if (!state.reportedBy) {
            errors.reportedBy = 'Reported By field is required';
            formIsValid = false;
        }
        if (!state.reportedPhone) {
            errors.reportedPhone = 'Reported phone field is required';
            formIsValid = false;
        }
        if (!state.problemReported) {
            errors.problemReported = 'Problem reported field is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            setBtnLoader(true);
            let res = await postVehicleBreakdown(state);
            if (res.success) {
                showToastSuccess(`Breakdown number ${res.data.defectNumber} generated successfully`);
                history.push(`/maintenance/breakdowns/Edit/${res.data.defectID}/${props.VehicleID}`);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    const pullBreackdownById = async () => {
        if (props.breakDownID) {
            let res = await getVehicleBreakdownsbyID(props.breakDownID);

            let data = res.data.details[0];
            let vehicleJobProgressDates = res.data.vehicleJobProgressDates;
            setState((st) => ({
                ...st,
                defectID: data.defectID,
                vehicleID: data.defectVehicleID,
                breakDownType: data.defectTypeCode,
                mileage: data.defectMileage,
                reportingDepot: data.defectReportingDepotID,
                breakDownLocation: data.defectLocation,
                reportedPhone: data.defectContactNumber,
                reportedBy: data.defectContactName,
                driverName: data.defectDriverName,
                driverPhone: data.defectDriverTelephone,
                problemReported: data.defectDescription,
                callOutRequired: data.defectCallOutRequired,
                urgentCallOut: data.defectUrgentCallOut,
                putOnVOR: data.defectVOR,
                policeOnScene: data.defectPoliceOnScene,
                passengersInvolved: data.defectPassengersInvolved,
                numberOfPassenger: data.defectNumberOfPassengers,
                accDamage: data.defectAccidentDamage,
                iNSREF: data.defectInsuranceReference,
                replacementvehicleRequired: data.defectReplacementVehicle,
                domainName: data.domainName,
                vehicleLoaded: data.defectVehicleLoaded,
                loadType: data.defectVehicleLoadTypeID,
                mobilityRequested: data.defectMobilityRequested,
                mobilityUsed: data.defectMobilityUsed,
                PG9Issued: data.defectPG9Issued,
                defectNumberGenerated: data.defectNumberGenerated,
                defectResolvedNotes: data.defectResolvedNotes,
                reportingDepotName: data.reportingDepot,
                vehicleLoadTypeDescription: data.vehicleLoadTypeDescription,
                defectRegNumber: data.defectRegNumber,
                customer: data.customer,
                reportingDepot: data.reportingDepot,
                make: data.make,
                model: data.model,
                vorVehicleID: data.vorVehicleID,
                vehicleJobProgress: vehicleJobProgressDates,
                defectDateCreated: formatters.DateTimeFormatter(data.defectDateCreated),
                defectDateCallOutArrived: formatters.DateTimeFormatter(data.defectDateCallOutArrived),
                defectDateVehicleRecovered: formatters.DateTimeFormatter(data.defectDateVehicleRecovered),
                defectDateResolved: formatters.DateTimeFormatter(data.defectDateResolved),
                defectDateSignOff: formatters.DateTimeFormatter(data.defectDateSignOff),
                fileCount: data.fileCount
            }));
        }
    };

    const handleShowLocation = () => {
        setState((st) => ({ ...st, showMap: true }));
    };
    const closetModal = (res) => {
        setState((st) => ({ ...st, showJob: false, showMap: false }));
    };

    const handleUpdateLocation = (location) => {
        setState((st) => ({ ...st, breakDownLocation: location, showMap: false }));
    };

    return (
        <div>
            {state.showMap ? (
                <DialogComp title={<Mapheader />} onClose={() => closetModal(false)} maxWidth="lg" fullScreen>
                    <GetAgentLocation location={state.breakDownLocation} updateLocation={handleUpdateLocation} />
                </DialogComp>
            ) : null}

            <Grid container spacing={2}>
                <Grid xs={12} sm={6} item>
                    <Grid container>
                        <Grid item xs={12}>
                            <InputLabel shrink>Breakdown Type?</InputLabel>
                        </Grid>
                        <Grid container item spacing={1}>
                            <RadioGroup row aria-label="DefectCallOutRequired" name="breakDownType" value={state.breakDownType} onClick={inputChange}>
                                <FormControlLabel value="BR" control={<Radio />} label="Road Side" />
                                <FormControlLabel value="BO" control={<Radio />} label="On Site" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                            <InputLabel required shrink>
                                Breakdown Date
                            </InputLabel>
                            <DatePicker placeholder="Date Of Breakdown" value={state.dateOfReg} onChange={inputChange} name="dateOfReg" />
                            <FormHelperText error>{state.errors.dateOfReg}</FormHelperText>
                        </Grid> */}
                {/* <Grid xs={12} sm={6} md={4} lg={3} item>
                    <Grid container>
                        <Grid item xs={12}>
                            <InputLabel shrink>Breakdown Type?</InputLabel>
                        </Grid>
                        <Grid container item spacing={1}>
                            <RadioGroup row aria-label="DefectCallOutRequired" name="breakDownType" value={state.breakDownType} onClick={inputChange}>
                                <FormControlLabel value="BR" control={<Radio />} label="Road Side" />
                                <FormControlLabel value="BO" con    trol={<Radio />} label="On Site" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid> */}
                <Grid item xs={12} sm={6} md={6} lg={6} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Breakdown Location"
                        placeholder="Breakdown Location"
                        value={state.breakDownLocation}
                        onChange={inputChange}
                        name="breakDownLocation"
                        errors={state.errors.breakDownLocation}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CustomIconButton
                                        toolTipTitle="Get location"
                                        onClick={() => {
                                            handleShowLocation(state.defectID);
                                        }}
                                        icon={RoomIcon}
                                    />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Reported By"
                        placeholder="Reported By"
                        value={state.reportedBy || ''}
                        onChange={inputChange}
                        name="reportedBy"
                        errors={state.errors.reportedBy}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Reported Phone"
                        placeholder="Reported Phone"
                        value={state.reportedPhone}
                        onChange={inputChange}
                        name="reportedPhone"
                        errors={state.errors.reportedPhone}
                    />
                </Grid>
                <Grid item xs={12} sm={12} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Problem Reported"
                        multiline
                        rows={3}
                        placeholder="Problem Reported"
                        value={state.problemReported}
                        onChange={inputChange}
                        name="problemReported"
                        errors={state.errors.problemReported}
                    />
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SecondaryButton onClick={submitHandler} isBtnLoader={btnLoader}>
                            Add
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={6}>
                        <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default AddBreaddownModal;
