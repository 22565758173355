import React, { useEffect, useState, useMemo } from 'react';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { getDepots } from '../../../../../Core/Services/InternalUser/ControllerService/depotsService';
import { EditButton, DataGrid, BreadCrumbs, formatters } from '../../../../../Core/Controls';
import AddEditDepots from './addEditDepots';
import EditDepot from './editDashboard';
import '../../../../commonStyle.scss';
import { Link } from 'react-router-dom';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
import ReactDOM from 'react-dom';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import CustomerDetails from '../../DetailsModal/customerDetails';
import ServiceAgentDetails from '../../DetailsModal/serviceAgent.jsx';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
import { Person } from '@material-ui/icons';
import ImportDepots from './ImportDepot.jsx';
import { useLoginInfo } from '../../../../../Core/Hooks/useLoginInfo';

const baseColumns = [
    { key: 'action', name: 'Action', width: 80, addFileUploaderIcon: true },
    { key: 'depotAccountCode', name: 'Depot Code', width: 100 },
    { key: 'depotName', name: 'Depot Name' },
    // { key: 'depotName', name: 'Name' },
    // { key: 'depotAddress', name: 'Address 1', width: 200 },
    // { key: 'depotAddress2', name: 'Address 2', width: 200 },
    // { key: 'city', name: 'City', width: 150 },
    // { key: 'county', name: 'County', width: 150 },
    // { key: 'postCode', name: 'Post Code', width: 100 },
    { key: 'vehicles', name: 'Vehicles', width: 90 }
    // { key: 'serviceAgent', name: 'Service Area', width: 140 }
];

const crumbs = [
    { name: 'Home', path: '/' },
    // { name: 'Vehicles', active: true },
    { name: 'Depot', active: true }
];

const VehicleDepots = (props) => {
    const { userRoles } = useLoginInfo();
    const isEngineer = userRoles.includes('engineer');
    const isServicearea = userRoles.includes('servicearea');
    const { showToastError } = useToast();
    let getAccessParam = AppStorage.getAllowAcces();
    const [state, setState] = useState({
        show: false,
        showLoader: true,
        depotsList: [],
        depotID: null,
        isReload: null,
        showImport: false
    });
    const onImportBtnClick = () => {
        setState((st) => ({ ...st, showImport: true }));
    };
    let checkhaveAccess = AppStorage.getAllowAcces();

    const handleAdd = (record) => {
        setState((st) => ({
            ...st,
            show: true,
            depotID: record
        }));
    };

    const pullDepotsAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    // useEffect(async () => {
    //     pullDepotsAndUpdateState();
    // }, []);

    const handleEditClick = (val) => {
        props.history.push({
            pathname: `/controller/Depots/${val.depotID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const dataFormatter = (data, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <EditButton toolTipTitle="Edit Depot" onClick={() => handleEditClick(row)} />
            </div>
        );
    };

    const closeDepotsModal = (res) => {
        setState((st) => ({
            ...st,
            show: false
        }));
        res && pullDepotsAndUpdateState();
    };

    const renderEmail = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = <div style={{ cursor: 'pointer', color: '#f50057' }}>{formatters.mailFormatter(row.depotEmail)}</div>;
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.serviceAgent}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customer}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showSaModal: false,
            showCustomerModal: false,
            showImport: false
        }));
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        // if (isEngineer || isServicearea) {
        //     tempCols.splice(0, tempCols.length, { key: 'depotAccountCode', name: 'Depot Code', width: 100 }, { key: 'depotName', name: 'Address' });
        // }
        let actionCol = tempCols.find((element) => element.key === 'action');
        let emailCol = tempCols.find((element) => element.key === 'depotEmail');
        let agentNameCol = tempCols.find((element) => element.key === 'serviceAgent');
        let customerNameCol = tempCols.find((element) => element.key === 'customer');
        if (emailCol) {
            emailCol.formatter = renderEmail;
        }
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        if (customerNameCol) {
            customerNameCol.formatter = renderCustomerName;
        }
        if (agentNameCol) {
            agentNameCol.formatter = renderAgentName;
        }
        // if (window.innerWidth > 600) {
        //     tempCols.splice(
        //         3,
        //         0,
        //         { key: 'depotAddress1', name: 'Address1' },
        //         { key: 'depotAddress2', name: 'Address2' },
        //         { key: 'depotTown', name: 'Town' },
        //         { key: 'depotCounty', name: 'County' },
        //         { key: 'depotCountryCode', name: 'Code', width: '4%' }
        //     );
        // }
        return tempCols;
    }, []);

    let getUrl = `depots`;
    const btnToRender = [
        {
            btnIcon: Person,
            btnTitle: `${checkhaveAccess?.CanImportDepots ? 'Import Depots' : ''}`,

            btnLabel: 'Import Depots',
            onBtnClick: onImportBtnClick
        }
    ];
    return (
        <div className="screen">
            {/* <EditDepot depotID={state.depotID} onFormClose={closeDepotsModal} /> */}
            {/* <AddEditDepots depotID={state.depotID} onFormClose={closeDepotsModal} /> */}
            {state.show ? (
                <AddEditDepots depotID={state.depotID} onFormClose={closeDepotsModal} />
            ) : (
                <>
                    <BreadCrumbs crumbs={crumbs} />
                    <GijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={columns}
                        getUrl={getUrl}
                        btnToRender={btnToRender}
                        // onBtnClick={() => handleAdd(null)}
                        // btnTitle={getAccessParam['CanUpdateDepots'] ? 'Add Depot' : ''}
                        isReload={state.isReload}
                        idFieldName="depotID"
                        fileUploaderConfigName="DepotScreen"
                    />
                </>
            )}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showImport ? (
                <DialogComp open={true} onClose={handleClose} title={'Import Depots'} fullScreen>
                    <ImportDepots />
                </DialogComp>
            ) : null}
            {state.showSaModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.serviceAgentID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default VehicleDepots;
