import React, { useEffect, useMemo, useState } from 'react';
import { BreadCrumbs, CustomChip, TabView, formatters, BackButton } from '../../../../Core/Controls';
import JobNotes from '../../Controllers/Notes/index';
import JobDetail from './JOBItems/JobDetails';
import '../controllerStyle.scss';
import JobMessages from './JOBItems/JobMessages';
import InvoiceScreen from '../../Invoices/jobPurchaseInvoice';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import InternalJobDetail from './JobDetails/Internal';
import SalesInvoiceScreen from '../../Invoices/jobSalesInvoice';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import BuildIcon from '@material-ui/icons/Build';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import AddVor from '../VOR/AddEditVor';
import { useHistory } from 'react-router-dom';
import InternalJobItem from './JobDetails/Internal/jobItemscreen';
import CustomerJobItemScreen from './JobDetails/Customer/jobItemscreen';
import AgentJobItemsScreen from './JobDetails/ServiceAgent/jobItemscreen';
import { getJobDetailsById, getJobEnggById, getJobRequiredDocuments } from '../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import OutStandingDocuments from './../../Schedule/OutstandingDocuments/index';

function JobTabScreen(props) {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const history = useHistory();
    const id = props.match.params.id;
    const vehicleID = props.match.params?.vehicleID;
    const tabIndex = props.match?.params?.tabIndex;

    let { state } = props.location;
    const [index, setIndex] = useState(undefined);
    const [value, setValue] = useState({});
    const [modalData, setModalData] = useState({});

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Jobs', active: true }
    ];
    const { userRoles } = useLoginInfo();

    const isServiceAgent = userRoles.includes('servicearea');
    const isCustomer = userRoles.includes('customer');
    const isinternal = userRoles.includes('internal');
    const isEngineer = userRoles.includes('engineer');

    const [jobDetails, setJobDetails] = useState({
        jobDescription: '',
        jobDepotID: '',
        jobServiceAgentID: '',
        vehicleMake: '',
        vehicleModel: '',
        jobRegNumber: '',
        refresherFlag: new Date(),
        jobEngineerUserID: null,
        customerID: null,
        vorVehicleID: null,
        masterWarehouseCode: null
    });

    const [jobdocument, setJobDocument] = useState({
        list: [],
        inspectionDoc: {}
    });

    const pullDocuments = async (val) => {
        if (val) {
            let res = await getJobRequiredDocuments(val);
            setJobDocument((state) => ({ ...state, list: res.data.list, inspectionDoc: res.data.list[0] }));
        }
    };

    const pullJobDetail = async () => {
        let res = await getJobDetailsById(id);
        let res1 = res.data.list;
        let data = res1?.length > 0 ? res.data?.list[0] : res.data?.list;
        if (data) {
            setJobDetails({
                jobID: data.jobID,
                jobAgentOrderNo: data.jobAgentOrderNo,
                jobContactDetails: data.jobContactDetails,
                jobOrderDate: formatters.DateOnlyFormatter(data.jobDate),
                jobBookingDate: formatters.DateOnlyFormatter(data.bookingDate),
                jobOrderTotal: data.orderTotal,
                jobCustomerOrderNo: data.jobCustomerOrderNo || '',
                jobEstimatedCost: data.jobEstimatedCost || '',
                jobProformaInvNet: data.jobProformaInvNet || '',
                jobLimit: data.jobLimit || '',
                jobAuthorityRef: data.jobAuthorityRef || '',
                jobMileageDate: data.jobMileageDate || '',
                jobMileage: data.jobMileage || '',
                jobDepotID: data.jobDepotID || '',
                jobTypeID: data.jobTypeID || '',
                jobServiceAgentID: data.jobServiceAgentID || '',
                jobVehicleID: data.jobVehicleID,
                jobRegNumber: data.jobRegNumber,
                vehicleMake: data.vehicleMake,
                vehicleModel: data.vehicleModel,
                // vehicleJobStatus: data.vehicleJobStatus,
                customerName: data.customerCompanyName,
                jobDescription: data.jobDescription,
                jobStatusID: data.jobStatusID,
                jobOrderNo: data.jobOrderNo,
                customerIsOnStop: data.customerIsOnStop,
                customerCreditAccountMessage: data.customerCreditAccountMessage,
                jobInCustomerQuery: data.jobInCustomerQuery,
                jobInAgentQuery: data.jobInAgentQuery,
                defectTypeCode: data.defectTypeCode,
                jobDefectID: data.jobDefectID,
                vorVehicleID: data.vorVehicleID,
                jobIsEstimate: data.jobIsEstimate,
                jobApprovedDate: formatters.DateFormatter(data.jobApprovedDate),
                approvedBy: data.approvedBy,
                jobApprovedOrderNo: data.jobApprovedOrderNo,
                jobApproved: data.jobApproved,
                serviceAgentName: data.serviceAgentName,
                depotName: data.depotName,
                mileageRequired: data.mileageRequired,
                refreshDependancy: new Date(),
                fileCount: data.fileCount,
                islabourRateSet: data.labourRate == 0 ? false : true,
                labourRate: data.labourRate,
                // jobInAgentQuery: data.jobInAgentQuery,
                markup: data.markup,
                signOffNotes: data.signOffNotes,
                signOffSignature: data.signOffSignature,
                signedOffByName: data.signedOffByName,
                jobCustomerOrderTotal: data.jobCustomerOrderTotal,
                nonChargable: data.nonchargeable,
                chargeable: data.chargeable,
                isEditableFields: data.allowUpdate === 'Y' ? true : false,
                // data.jobStatusID == 'AU' ||
                // data.jobStatusID == 'AA' ||
                // data.jobStatusID == 'JP' ||
                // data.jobStatusID == 'ER' ||
                // data.jobStatusID == 'JQ' ||
                // data.jobStatusID == 'IQ' ||
                // data.jobStatusID == 'AR' ||
                // data.jobStatusID == 'EA',
                jobEngineerUserID: data?.jobEngineerUserID,
                customerID: data.customerID,
                hideAddMileageAndDate: data.jobMileageDate && data.jobMileage ? true : false,
                jobTypeDescription: data.jobTypeDescription,
                // jobCreatedUserName: data.jobCreatedUserName,
                // jobDateCreated: data.jobDateCreated,
                jobEngineerAssignedUserName: data.jobEngineerAssignedUserName,
                jobCreatedUserName: data.jobCreatedUserName,
                jobAgentAssignedUserName: data.jobAgentAssignedUserName,
                jobWorkInProgressUserName: data.jobWorkInProgressUserName,
                jobPausedUserName: data.jobPausedUserName,
                jobAwaitingPartsUserName: data.jobAwaitingPartsUserName,
                jobEstimatedUserName: data.jobEstimatedUserName,
                jobEstimateApprovedUserName: data.jobEstimateApprovedUserName,
                jobSignOffUserName: data.jobSignOffUserName,
                jobCostReviewedUserNAme: data.jobCostReviewedUserNAme,
                jobProformaCreatedUserName: data.jobProformaCreatedUserName,
                jobApprovedUserName: data.jobApprovedUserName,
                jobCompletionUserName: data.jobCompletionUserName,
                jobDateEngineerAssigned: data.jobDateEngineerAssigned,
                jobDateCreated: data.jobDateCreated,
                jobDateAgentAssigned: data.jobDateAgentAssigned,
                jobVehicleArrivedDate: data.jobVehicleArrivedDate,
                jobWorkInProgressDate: data.jobWorkInProgressDate,
                jobPausedDate: data.jobPausedDate,
                jobAwaitingPartsDate: data.jobAwaitingPartsDate,
                // jobIsEstimate: data.jobIsEstimate,
                // jobEstimatedCost: data.jobEstimatedCost,
                jobEstimatedDate: data.jobEstimatedDate,
                jobEstimateApprovedDate: data.jobEstimateApprovedDate,
                jobSignOffDate: data.jobSignOffDate,
                jobCostReviewedDate: data.jobCostReviewedDate,
                jobIsProforma: data.jobIsProforma,
                // jobProformaInvNet: data.jobProformaInvNet,
                jobProformaDateCreated: data.jobProformaDateCreated,
                jobProformaFileName: data.jobProformaFileName,
                // jobApproved: data.jobApproved,
                // jobApprovedDate: data.jobApprovedDate,
                jobCompletionDate: data.jobCompletionDate,
                jobCostReviewedUserName: data.jobCostReviewedUserName,
                vehicleJobStatus: data.vehicleJobStatus,
                jobEngineer: data.jobEngineer,
                masterWarehouseCode: data.warehouseCode,
                canDelete: data.canDelete === 'Y' ? true : false,
                outstandingLines: data.outstandingLines,
                factoryInspectionUser: data.factoryInspectionUser,
                factoryInspectionDate: data.factoryInspectionDate,
                stepperAllowed: data.stepperAllowed === 'Y' ? true : false,
                reviewMessage: data.reviewMessage
            });
        }
        pullDocuments(id);
    };

    useEffect(() => {
        pullJobDetail();
    }, []);

    useEffect(() => {
        if (tabIndex) {
            const n = Date.now();
            setValue((st) => ({ ...st, timeDependency: n }));
            setIndex(+tabIndex || undefined);
        }
    }, [tabIndex]);

    const commonTabs = [
        {
            label: 'Job Details',
            body: (
                <>
                    <JobDetail jobDetails={jobDetails} scheduleJobID={id} pullJobDetail={pullJobDetail} />
                    <InternalJobDetail
                        scheduleJobID={id}
                        jobDetails={jobDetails}
                        setJobDetails={setJobDetails}
                        vehicleID={vehicleID}
                        pullJobDetail={pullJobDetail}
                    />
                </>
            )
        },
        {
            label: 'Job Items',
            body: (
                <>
                    <JobDetail jobDetails={jobDetails} scheduleJobID={id} showDoc={true} pullJobDetail={pullJobDetail} jobdocument={jobdocument} />
                    <InternalJobItem
                        scheduleJobID={id}
                        VehicleID={vehicleID}
                        checkhaveAccess={checkhaveAccess}
                        setJobDetails={setJobDetails}
                        jobDetails={jobDetails}
                        jobStatusID={jobDetails.jobStatusID}
                        pullJobDetail={pullJobDetail}
                    />
                </>
            )
        },
        // {
        //     label: 'Purchase Invoices',
        //     body: (
        //         <>
        //             <JobDetail jobDetails={jobDetails} scheduleJobID={id} pullJobDetail={pullJobDetail} />
        //             <InvoiceScreen
        //                 scheduleJobID={id}
        //                 VehicleID={vehicleID}
        //                 JobID={id}
        //                 fromPage="job"
        //                 pullJobDetail={pullJobDetail}
        //                 jobStatusID={jobDetails.jobStatusID}
        //             />
        //         </>
        //     )
        // },
        {
            label: 'Sales Invoices',
            body: (
                <>
                    <JobDetail jobDetails={jobDetails} scheduleJobID={id} pullJobDetail={pullJobDetail} />
                    <SalesInvoiceScreen
                        scheduleJobID={id}
                        VehicleID={vehicleID}
                        JobID={id}
                        pullJobDetail={pullJobDetail}
                        fromPage="job"
                        jobStatusID={jobDetails.jobStatusID}
                    />
                </>
            )
        }
    ];

    const additionalTabs = [
        {
            label: 'Job Notes',
            body: (
                <>
                    <JobDetail jobDetails={jobDetails} scheduleJobID={id} pullJobDetail={pullJobDetail} />
                    <JobNotes frompage="Jobs" fromTab={true} JobID={id} noteRefsGuidID={id} />
                </>
            )
        }
        // {
        //     label: 'Messages',
        //     body: (
        //         <>
        //             <JobDetail jobDetails={jobDetails} scheduleJobID={id} pullJobDetail={pullJobDetail} />
        //             <JobMessages scheduleJobID={id} />
        //         </>
        //     )
        // }
    ];

    const customerTab = {
        label: 'Job Items',
        body: <CustomerJobItemScreen scheduleJobID={id} VehicleID={vehicleID} checkhaveAccess={checkhaveAccess} />
    };

    const serviceAgentTab = {
        label: 'Job Items',
        body: <AgentJobItemsScreen scheduleJobID={id} VehicleID={vehicleID} checkhaveAccess={checkhaveAccess} />
    };

    const tabs = useMemo(() => {
        let baseTabs = [...commonTabs];
        if (isinternal || isServiceAgent) {
            if (checkhaveAccess?.CanViewJobs) {
                baseTabs = [...baseTabs, ...additionalTabs];
            }
        }

        // else if (isServiceAgent) {
        //     baseTabs = [serviceAgentTab, ...baseTabs];
        // }
        else if (isCustomer && checkhaveAccess?.CanViewJobs) {
            baseTabs = [customerTab, ...baseTabs];
        }

        if (isEngineer) {
            // if (checkhaveAccess?.CanViewJobs) {
            let findIndex = baseTabs.findIndex((tab) => tab.label === 'Sales Invoices');
            if (findIndex !== -1) {
                baseTabs.splice(findIndex, 1);
            }
            // }
        }
        return baseTabs;
    }, [jobDetails, isinternal, isServiceAgent, isCustomer, checkhaveAccess, isEngineer]);

    const handleAddPopUp = () => {
        setModalData((st) => ({ ...st, showVor: true }));
    };

    const closeAddPopUp = (flag, name) => {
        flag && pullJobDetail();
        setModalData((st) => ({ ...st, showVor: false }));
        if (flag) {
            history.replace({ state: { ...state, vorVehicleID: '' } });
        }
    };
    const GoBack = () => {
        history.goBack();
    };

    return (
        <div className="screen">
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center', position: 'relative', top: 1 }}>
                <div style={{ width: 'auto', display: 'flex', alignItems: 'center' }}>
                    <BackButton onClick={GoBack} />
                    <BreadCrumbs crumbs={crumbs} m={5} />
                </div>
                &nbsp;&nbsp;
                <div style={{ width: 'auto' }}>
                    {!isCustomer && (state?.vorVehicleID || jobDetails.vorVehicleID) && (
                        <CustomChip color="secondary" className="btn-m" icon={BuildIcon} label="VOR" onClick={handleAddPopUp} />
                    )}
                </div>
            </div>
            <TabView selectedIndex={index} tabList={tabs} id="testing_tabs" timeDependency={value.timeDependency} />
            {modalData.showVor && (
                <DialogComp
                    title={`${state?.vorVehicleID || jobDetails.vorVehicleID ? 'Update' : 'Add'} VOR`}
                    onClose={() => closeAddPopUp(false)}
                    maxWidth="lg"
                    overflow="auto"
                >
                    <AddVor
                        VehicleID={vehicleID}
                        update={state?.vorVehicleID || jobDetails.vorVehicleID}
                        vorVehicleID={state?.vorVehicleID || jobDetails.vorVehicleID}
                        onClose={closeAddPopUp}
                    />
                </DialogComp>
            )}
        </div>
    );
}

export default JobTabScreen;
