import { postWithAuth, getWithAuth } from '../../Basic/HttpService';

export const getAllDefectTypes = async () => {
    let res = await getWithAuth(`DefectTypes`);
    return res;
};

export const getDefectTypesId = async (id) => {
    let res = await getWithAuth(`DefectTypes/${id}`);
    return res;
};

export const postDefectTypesData = async (data) => {
    let res = await postWithAuth(`DefectTypes`, data);
    return res;
};
