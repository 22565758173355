import React, { useState, useEffect, useMemo } from 'react';
import { getAllEmailTemplates } from '../../../../Core/Services/InternalUser/emailTemplateService';
import DataGrid from '../../../../Core/Controls/Grid';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import EmailTemplatesModal from './EmailTemplatesModal';
import { BreadCrumbs, AddButton, EditButton, formatters } from '../../../../Core/Controls';
import '../../../commonStyle.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicles', active: true },
    { name: 'Email Templates ', active: true }
];

const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'code', name: 'Code', width: '10%' },
    { key: 'subject', name: 'Subject', width: '25%' },
    { key: 'body', name: 'Body', width: '40%' },
    { key: 'createdBy', name: 'Created By', width: '10%' },
    { key: 'dateCreated', name: 'Date Created', formatter: formatters.Date_DDMMMYYYY_HHMM, width: '13%' },
    { key: 'updatedBy', name: 'Updated By', width: '10%' },
    { key: 'dateUpdated', name: 'Date Updated', formatter: formatters.Date_DDMMMYYYY_HHMM, width: '13%' }
];

function EmailTemplates(props) {
    const [state, setState] = useState({
        EmailTemplateList: [],
        loader: true,
        data: null,
        open: false
    });

    useEffect(async () => {
        pullEmailTemplates();
    }, []);

    const pullEmailTemplates = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const submitHandler = (params) => {
        setState((state) => ({ ...state, open: true, data: params }));
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => submitHandler(row)} toolTipTitle="Edit Email Templates" />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        return newColumn;
    }, []);

    const title = state.data ? 'Update Email Templates' : 'Add Email Templates';

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false, data: null }));
        res && pullEmailTemplates();
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />

            <GijgoGrid
                columns={columns}
                getUrl={`EmailTemplates`}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                btnTitle="Add Email Template"
                onBtnClick={() => submitHandler(null)}
            />
            {state.open ? (
                <DialogComp title={title} onClose={() => closeModal(false)} maxWidth="sm" overflow="auto">
                    <EmailTemplatesModal data={state.data} onClose={() => closeModal(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
}
export default EmailTemplates;
