import { Grid, Paper, Button, OutlinedInput, InputAdornment, IconButton } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SendIcon from '@material-ui/icons/Send';
import moment from 'moment';
import { useLoginInfo } from '../../../../../Core/Hooks/useLoginInfo';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getVehicleMessages, postMessage } from '../../../../../Core/Services/InternalUser/Vehicle/messageService';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '40%',
        marginLeft: 'auto',
        margin: 10,
        backgroundColor: '#fef0d2',
        padding: 20
    },
    outgoning: {
        width: '40%',
        margin: 10,
        backgroundColor: '#a7ebf3',
        padding: 20
    },
    empty: {
        textAlign: 'center',
        margin: 10,
        padding: 20
    },
    customScroll: {
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.1em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#1976d2',
            opacity: 5,
            outline: '1px solid slategrey'
        }
    },
    activeBorder: {
        borderLeft: '7px solid #1976d2 '
    }
}));

const borderStyle = {
    br: {
        borderRight: '5px solid #ffae00'
    },
    bl: {
        borderLeft: '5px solid #1976d2'
    }
};

const DefaultMesaage = (props) => {
    const { displayName } = useLoginInfo();
    const [state, setState] = useState({
        message: null,
        customerName: null,
        messageList: [],
        showLoader: true
    });

    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() => elementRef.current.scrollIntoView());
        return <div ref={elementRef} />;
    };

    const getMessages = async () => {
        if (props.VehicleID) {
            let res = await getVehicleMessages(props.VehicleID);
            if (res.success) {
                setState((st) => ({
                    ...st,
                    messageList: res.data.list.sort((a, b) => (a.sdate > b.sdate ? 1 : b.sdate > a.sdate ? -1 : 0)),
                    showLoader: false
                }));
            }
        }
    };
    useEffect(async () => {
        getMessages();
    }, []);

    function handleEnter(e) {
        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
            submitMessage();
        }
    }

    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const validation = () => {
        let isValid = true;
        if (!state.message) {
            isValid = false;
        }
        if (!/[^\s]/.test(state.message)) {
            isValid = false;
        }
        return isValid;
    };

    const submitMessage = async () => {
        if (validation()) {
            let data = {
                ID: props.VehicleID,
                message: state.message
            };

            let res = await postMessage(data);
            if (res.success) {
                getMessages();
                setState((st) => ({
                    ...st,
                    message: ''
                }));
            }
        }
    };

    const classes = useStyles();

    return (
        <div className="controller-screen">
            <Grid
                container
                style={{
                    height: 'calc(100vh - 200px)',
                    overflow: 'hidden',
                    marginTop: '20px',
                    boxShadow: '2px 2px 7px 1px #9E9E9E'
                }}
            >
                {' '}
                <Grid item xs={12} md={12} lg={12} xl={12} style={{ padding: '0px 10px 10px 10px' }}>
                    <Typography variant="h6">&nbsp;</Typography>
                    {state.showLoader ? (
                        <div
                            style={{
                                height: 'calc(100vh - 300px)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                        </div>
                    ) : (
                        <div className={classes.customScroll} style={{ height: 'calc(100vh - 300px)' }}>
                            {state.messageList.length !== 0 ? (
                                <>
                                    {state.messageList.map((m, i) => {
                                        let classText = m.name === displayName ? classes.outgoning : classes.paper;
                                        return (
                                            <div>
                                                <Paper
                                                    key={i}
                                                    className={classText}
                                                    style={m.rdate ? (m.name === displayName ? borderStyle.bl : borderStyle.br) : null}
                                                >
                                                    <Grid container spacing={2}>
                                                        <div style={{ width: '100%' }}>
                                                            <Typography>{m.notes}</Typography>
                                                        </div>
                                                        <div style={{ width: '50%' }}>
                                                            <small>
                                                                {m.name} @ {moment(m.sdate).format('MMM Do YYYY, h:mm a')}
                                                            </small>
                                                        </div>

                                                        {m.rdate && (
                                                            <div style={{ width: '50%', textAlign: 'right' }}>
                                                                <small> seen {moment(m.rdate).format('MMM Do YYYY, h:mm a')}</small>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </Paper>
                                            </div>
                                        );
                                    })}
                                    <AlwaysScrollToBottom />
                                </>
                            ) : (
                                <div>
                                    <div className={classes.empty}>
                                        <Grid container spacing={2}>
                                            <div style={{ width: '100%' }}>
                                                <Typography>There are no messages sent/received</Typography>
                                            </div>
                                            <div style={{ width: '100%' }}>
                                                <small>{moment().format('MMM Do YYYY, h:mm a')}</small>
                                            </div>
                                        </Grid>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    <Grid container spacing={1} justify="space-between">
                        <Grid item xs={12}>
                            <OutlinedInput
                                fullWidth
                                placeholder="Type a new message"
                                name="message"
                                value={state.message}
                                onChange={handleInput}
                                onKeyDown={handleEnter}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton edge="end">
                                            <SendIcon onClick={submitMessage} style={{ cursor: 'pointer' }} color="secondary" />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default DefaultMesaage;
