const storage = localStorage;

const getName = () => storage.getItem('_displayName_');
const isReadOnlyUser = () => storage.getItem('_isReadOnlyUser_') === 'true';
const getToken = () => storage.getItem('_token_');
const getLastLogin = () => storage.getItem('_lastLogin_');
const getUserRoles = () => (storage.getItem('_userRoles_') || '').split(',');
const loginInfoUpdatedAt = () => storage.getItem('_login_info_updated_at_');
const getAllowAcces = () => {
    const vl = storage.getItem('_allowAccess_');
    if (vl) {
        return JSON.parse(vl);
    }
    return vl;
};

const getPortalSetting = () => {
    const setting = storage.getItem('_portal_setting_');
    if (setting) {
        return JSON.parse(setting);
    }
    return setting;
};

const setPortalSetting = (setting) => {
    storage.setItem('_portal_setting_', JSON.stringify(setting));
};

const saveFileUploaderConfig = (data) => {
    var obj = {};
    data.forEach((o) => (obj[o.Name] = o.Id));
    storage.setItem('__fileUploaderConfig__', JSON.stringify(obj));
};

const getFileUploaderConfig = () => {
    return JSON.parse(storage.getItem('__fileUploaderConfig__') || {});
};

const getSignature = () => {
    return storage.getItem('_user_sign_');
};

const setSignature = (sign) => {
    sign && storage.setItem('_user_sign_', sign);
};

const getSignatureStatus = () => {
    return storage.getItem('_signature_status_');
};

const setSignatureStatus = (ID) => {
    ID && storage.setItem('_signature_status_', ID);
};

const getSignNotifiedStatus = () => {
    return storage.getItem('_signature_notified_') || 0;
};

const setSignNotifiedStatus = (ID) => {
    ID && storage.setItem('_signature_notified_', ID);
};

export const AppStorage = {
    getItem: storage.getItem,
    setItem: storage.setItem,
    removeItem: storage.removeItem,
    getName,
    isReadOnlyUser,
    getToken,
    getLastLogin,
    getUserRoles,
    loginInfoUpdatedAt,
    getPortalSetting,
    setPortalSetting,
    isLoggedIn: () => !!getName() && !!getToken(),
    performLogin: ({ data }) => {
        storage.setItem('_token_', data.token);
        storage.setItem('_displayName_', data.displayName);
        storage.setItem('_isReadOnlyUser_', data.isReadOnly || false);
        storage.setItem('_lastLogin_', data.lastLogin);
        storage.setItem('_userRoles_', data.roles);
        storage.setItem('_login_info_updated_at_', Date.now());
        storage.setItem('_user_sign_', data.defaultSignature || 'absent');
        storage.setItem('_signature_status_', data.signatureStatus);
        storage.setItem('_allowAccess_', JSON.stringify(data.access));
    },
    saveFileUploaderConfig,
    getFileUploaderConfig,
    performLogout: () => {
        const portalSetting = getPortalSetting();
        storage.clear();
        if (portalSetting) {
            setPortalSetting({ ...portalSetting, expired: true });
        }
        storage.setItem('_login_info_updated_at_', Date.now());
    },
    getSignature,
    setSignature,
    getSignatureStatus,
    setSignatureStatus,
    setSignNotifiedStatus,
    getSignNotifiedStatus,
    getAllowAcces
};
