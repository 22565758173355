import { postWithAuth, getWithAuth, putWithAuth } from '../../../Basic/HttpService';

export const getDropDownHolidayUser = async (id) => {
    let res = await getWithAuth(`UserHolidays/UserHolidays_DropDowns`);
    return res;
};
export const getHolidayDataByID = async (userID, year) => {
    let res = await getWithAuth(`UserHolidays/UserHolidays_GetDetails?UserHolidayUserID=${userID}&Year=${year}`);
    return res;
};

export const postHolidayUserData = async (data) => {
    let res = await postWithAuth(`UserHolidays/UserHolidays_AddUpdate`, data);
    return res;
};

export const ServiceAreaHolidays_DropDowns = async (id) => {
    let res = await getWithAuth(`ServiceAreaHolidays/ServiceAreaHolidays_DropDowns`);
    return res;
};
export const ServiceAreaHolidays_ByDate = async (date) => {
    let res = await getWithAuth(`ServiceAreaHolidays/ServiceAreaHolidays_ByDate?date=${date}`);
    return res;
};
export const ServiceAreaHolidays_GetDetails = async (AreaID, year) => {
    let res = await getWithAuth(`ServiceAreaHolidays/ServiceAreaHolidays_GetDetails?ServiceAreaID=${AreaID}&Year=${year}`);
    return res;
};
export const ServiceAreaHolidays_AddUpdate = async (data) => {
    let res = await postWithAuth(`ServiceAreaHolidays/ServiceAreaHolidays_AddUpdate`, data);
    return res;
};
export const DeleteHolidayRecodrs = async (data) => {
    let res = await postWithAuth(`ServiceAreaHolidays/ServiceAreaHolidays_Delete`, data);
    return res;
};
