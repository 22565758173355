import { FormHelperText, Grid, InputLabel, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { SecondaryButton, SelectBox, SingleSelect } from '../../../../../../Core/Controls';
import { getJobEnggById } from '../../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import { postAssignEngineer } from '../../../../../../Core/Services/InternalUser/ControllerService/jobStepperService';
import { useToast } from '../../../../../../Core/Hooks/useToast';

const AssignJobStep = ({ handleNext, serviceAgentList, jobDetails, isDisabled }) => {
    const { showToastWarning, showToastError, showToastSuccess } = useToast();

    const [state, setState] = useState({
        filterTechnician: [],
        jobAgentID: jobDetails.jobServiceAgentID,
        jobEngineerUserID: jobDetails.jobEngineerUserID,
        errors: {}
    });

    const [btnLoader, setBtnLoader] = useState(false);

    let PullEngineerList = async (serviceAreaID) => {
        if (serviceAreaID) {
            let res = await getJobEnggById(serviceAreaID);
            if (res.success) {
                setState((d) => ({ ...d, filterTechnician: res.data.list }));
            }
        }
    };

    useEffect(() => {
        if (state.jobAgentID) {
            try {
                PullEngineerList(state.jobAgentID);
            } catch (error) {
                console.log(error);
            }
        }
    }, [state.jobAgentID]);

    const validations = () => {
        const { jobAgentID, jobEngineerUserID } = state;
        let formIsValid = true;
        let errors = {};
        if (!jobAgentID) {
            errors.jobAgentID = 'Service Area is required';
            formIsValid = false;
        }
        if (!jobEngineerUserID) {
            errors.jobEngineerUserID = 'Engineer is required';
            formIsValid = false;
        }

        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            setBtnLoader(true);
            let data = {
                jobID: jobDetails.jobID,
                jobAgentID: state.jobAgentID,
                jobEngineerUserID: state.jobEngineerUserID
            };
            let res = await postAssignEngineer(data);

            if (res.success) {
                setBtnLoader(false);
                showToastSuccess(res.data.message);
                handleNext();
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setState((st) => {
            const nst = { ...st };
            nst[name] = value;

            return nst;
        });
    };

    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel shrink error>
                        Service Area{' '}
                    </InputLabel>
                    <SelectBox
                        List={serviceAgentList || []}
                        name="jobAgentID"
                        value={state.jobAgentID}
                        onChange={handleInputChange}
                        disabled={jobDetails.jobServiceAgentID || !isDisabled}
                    />
                    <FormHelperText error>{state.errors.jobAgentID}</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel shrink error>
                        Engineer{' '}
                    </InputLabel>
                    <SelectBox
                        List={state.filterTechnician || []}
                        name="jobEngineerUserID"
                        value={state.jobEngineerUserID}
                        onChange={handleInputChange}
                        disabled={!isDisabled}
                    />
                    <FormHelperText error>{state.errors.jobEngineerUserID}</FormHelperText>
                </Grid>
                {isDisabled && (
                    <Grid item xs={12}>
                        <SecondaryButton onClick={handleSubmit} isBtnLoader={btnLoader}>
                            Submit
                        </SecondaryButton>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default AssignJobStep;
