import * as Components from '../../../../Components';
import { TypeOfAuth } from '../menu-auth-type';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import GavelIcon from '@material-ui/icons/Gavel';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import WorkIcon from '@material-ui/icons/Work';
import ExploreRoundedIcon from '@material-ui/icons/ExploreRounded';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import BuildIcon from '@material-ui/icons/Build';

import DeleteIcon from '@material-ui/icons/Delete';

export default [
    {
        label: 'Dashboard',
        path: '/',
        exact: true,
        component: Components.CustomerDashobard,
        authType: TypeOfAuth.Both,
        icon: <DeleteIcon />
    },
    {
        label: 'Drivers',
        component: Components.CustomerDriverScreen,
        path: '/controller/driver',
        authType: TypeOfAuth.Auth,
        icon: <PersonIcon />
    },
    {
        label: 'Vehicle',
        component: Components.CustomerVehicleScreen,
        path: '/controller/vehicles/:all?',
        authType: TypeOfAuth.Auth,
        icon: <DriveEtaIcon />
    },
    {
        label: 'Depots',
        path: '/controller/Depots',
        exact: true,
        component: Components.CustomerDepots,
        authType: TypeOfAuth.Auth,
        icon: <LocalShippingIcon />
    },
    {
        label: 'Driver',
        component: Components.EditDriver,
        path: '/controller/editdriver/:id/:name',
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'Daily Checks',
        authType: TypeOfAuth.Both,
        icon: <AssignmentTurnedInIcon />,
        children: []
    },
    {
        label: 'All Checks',
        path: '/customerDailyChecks/all',
        exact: true,
        component: () => {
            return <Components.DailyChecksCustomer statusCode="" resultCode="" fromPage="All Checks" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Unreviewed',
        path: '/customerDailyChecks/unreviwed',
        exact: true,
        component: () => {
            return <Components.DailyChecksCustomer statusCode="1" resultCode="" fromPage="Unreviewed" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Failed',
        path: '/customerDailyChecks/failed',
        exact: true,
        component: () => {
            return <Components.DailyChecksCustomer statusCode="" resultCode="F" fromPage="Failed" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Passed',
        path: '/customerDailyChecks/passed',
        exact: true,
        component: () => {
            return <Components.DailyChecksCustomer statusCode="" resultCode="P" fromPage="Passed" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Monitor',
        path: '/customerDailyChecks/monitor',
        exact: true,
        component: () => {
            return <Components.DailyChecksCustomer statusCode="" resultCode="M" fromPage="Monitor" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Estimates',
        authType: TypeOfAuth.Both,
        icon: <ExploreRoundedIcon />,
        children: []
    },
    {
        label: 'Received',
        path: '/customer/EP',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="EP" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Requested',
        path: '/customer/ER',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="ER" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Jobs',
        authType: TypeOfAuth.Both,
        icon: <WorkIcon />,
        children: []
    },
    {
        label: 'All Jobs',
        path: '/controller/Jobs',
        exact: true,
        component: Components.CustomerJobScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Awaiting Approvals',
        path: '/serviceagent/AR',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="AR" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Estimates Awaiting Approvals',
        path: '/serviceagent/EC',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="EC" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Estimates Queried',
        path: '/serviceagent/EQ',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="EQ" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Open Jobs',
        path: '/serviceagent/OJ',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="JP" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Approved',
        path: '/serviceagent/CA',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="CA" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Customer Query',
        path: '/serviceagent/CQ',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="JQ" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Maintenance',
        authType: TypeOfAuth.Both,
        icon: <GavelIcon />,
        children: []
    },
    {
        label: 'Breakdowns',
        path: '/maintenance/breakdowns/:all',
        exact: true,
        component: Components.CustomerBreakDownDashboard,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Defects',
        path: '/maintenance/defects',
        exact: true,
        component: Components.CustomerDefectsScreen,
        authType: TypeOfAuth.Auth
    },

    {
        label: 'Vehicle Checks',
        path: '/maintenance/vehiclecheck',
        exact: true,
        component: Components.CustomerVehicleCheckDaily,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'VOR',
        component: Components.VorScreen,
        path: '/controller/vor',
        authType: TypeOfAuth.Auth,
        exact: true
        // hidden: true
    },

    {
        label: 'Schedules',
        authType: TypeOfAuth.Both,
        icon: <AccessTimeIcon />,
        children: []
    },
    {
        label: 'All Schedules',
        path: '/maintenance/schedules/:all',
        exact: true,
        component: Components.CustomerAllVehicleSchedules,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Bookings Requsted',
        path: '/schedule/bookingsrequsted',
        exact: true,
        authType: TypeOfAuth.Auth,
        component: () => {
            return <Components.InternalUserSchedule status="1" />;
        }
    },
    {
        label: 'Accepted by Agents',
        path: '/schedule/acceptedbyagents',
        exact: true,
        authType: TypeOfAuth.Auth,
        component: () => {
            return <Components.InternalUserSchedule status="2" />;
        }
    },
    {
        label: 'Bookings Rejected',
        path: '/schedule/bookingsrejected',
        exact: true,
        authType: TypeOfAuth.Auth,
        component: () => {
            return <Components.InternalUserSchedule status="3" />;
        }
    },
    {
        label: 'Confirmed Bookings',
        path: '/schedule/confirmedbookings',
        exact: true,
        authType: TypeOfAuth.Auth,
        component: () => {
            return <Components.InternalUserSchedule status="5" />;
        }
    },

    {
        label: 'VehicleDashBoard',
        path: '/controller/editVehicle/:id/:name?',
        exact: true,
        component: Components.EditVehicle,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Service Agent Map',
        path: '/controller/getagentLocation/:location?',
        hidden: true,
        authType: TypeOfAuth.Auth,
        component: Components.GetAgentLocation
    },
    {
        label: 'BookJob',
        path: '/controller/jobTabScreen/:id/:vehicleID/:tabIndex?',
        exact: true,
        component: Components.jobTabScreen,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Unassigned Jobs',
        path: '/controller/unassigned-jobs',
        exact: true,
        hidden: true,
        component: Components.UnassignedJob,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Message',
        path: '/message',
        exact: true,
        hidden: true,
        component: Components.InternalUserChatting,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'EditBreakDown',
        path: '/maintenance/breakdowns/Edit/:BreakDownID/:VehicleID',
        exact: true,
        hidden: true,
        component: Components.EditBreakdownLink,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'EditDefect',
        path: '/maintenance/defect/edit/:DefectID/:VehicleID',
        exact: true,
        hidden: true,
        component: Components.DefectEditDetails,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Outstanding Documents',
        path: '/schedule/outstandingdocuments',
        exact: true,
        // hidden: true,
        component: Components.OutStandingDocuments,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Job Reminders',
        path: '/controller/jobreminder',
        exact: true,
        // hidden: true,
        component: Components.JobReminder,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Vor Reporting',
        path: '/controller/VorReporting',
        exact: true,
        hidden: true,
        component: Components.CustomerVORReporting,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Breakdown Reporting',
        path: '/maintenance/breakdown/reporting/:id?',
        exact: true,
        hidden: true,
        component: Components.CustomerBreakDownDefectReporting,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Defect Reporting',
        path: '/maintenance/defect/reporting/:id?',
        exact: true,
        hidden: true,
        component: Components.CustomerBreakDownDefectReporting,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Job Reporting',
        path: '/job/job_Reporting',
        exact: true,
        hidden: true,
        component: Components.JobReportScreen,
        authType: TypeOfAuth.Auth
    },

    {
        label: 'Sales - Unpaid',
        path: '/salesunpaid',
        exact: true,
        component: () => {
            return <Components.SalesInvoiceScreen statusCode="u" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Sales - Paid',
        path: '/salespaid',
        exact: true,
        component: () => {
            return <Components.SalesInvoiceScreen statusCode="p" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Sales - Paid',
        path: '/update_sales_invoice/:id?',
        exact: true,
        component: Components.UpdateSalesInvoice,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Schedule Planner',
        path: '/schedule/scheduleplanner',
        exact: true,
        component: Components.SchedulePlannerScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Schedules calendar',
        path: '/maintenance/schedulescalendar',
        exact: true,
        component: Components.ScheduleDashboard,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Service Agents',
        authType: TypeOfAuth.Auth,
        icon: <BuildIcon />,
        path: '/serviceagent',
        exact: true,
        component: Components.ServiceAgent,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Service Agent  DashBoard',
        path: '/controller/editServiceAgent/:id',
        exact: true,
        component: Components.EditServiceAgent,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Service Agent Map',
        path: '/controller/getagentLocation/:location?',
        hidden: true,
        authType: TypeOfAuth.Auth,
        component: Components.GetAgentLocation
    }
];
