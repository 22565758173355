import React, { useState, useEffect, useMemo } from 'react';
import { getAllRetorqueposition } from '../../../Core/Services/InternalUser/VOR/retorquePositionsService';
import DataGrid from '../../../Core/Controls/Grid';
import DialogComp from '../../../Core/Dialog/DialogComp';
import RetorquePositionsModal from './RetorquePositionsModal';
import { BreadCrumbs, AddButton, EditButton } from '../../../Core/Controls';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import '../../commonStyle.scss';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicles', active: true },
    { name: 'Retorque Positions ', active: true }
];

const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'retorquePositionDescription', name: 'Description' }
];

function RetorquePositions(props) {
    const [state, setState] = useState({
        RetorquePositionsList: [],
        loader: true,
        open: false
    });

    useEffect(async () => {
        pullRetorquePositions();
    }, []);

    const pullRetorquePositions = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const retorquePositionHandler = (params) => {
        setState((state) => ({ ...state, open: true, retorquePositionID: params?.retorquePositionID }));
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => retorquePositionHandler(row)} toolTipTitle="Edit Retorque Position" />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        return newColumn;
    });

    const title = state.retorquePositionID ? 'Update Retorque Position' : 'Add Retorque Position';

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullRetorquePositions();
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={'Retorquepositions'}
                btnTitle="Add Retorque Position"
                onBtnClick={() => retorquePositionHandler(null)}
                isReload={state.isReload}
            />
            {state.open ? (
                <DialogComp title={title} onClose={() => closeModal(false)} maxWidth="md">
                    <RetorquePositionsModal retorquePositionID={state.retorquePositionID} onClose={() => closeModal(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
}
export default RetorquePositions;
