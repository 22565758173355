import { postWithAuth, getWithAuth } from "../../Basic/HttpService";

export const getAllServiceAgentFacilities = async () => {
  let res = await getWithAuth(`ServiceAgentFacilities`);
  return res;
};

export const getServiceAgentFacilitiesById = async (id) => {
  let res = await getWithAuth(`ServiceAgentFacilities/${id}`);
  return res;
};

export const postServiceAgentFacilitiesData = async (data) => {
  let res = await postWithAuth(`ServiceAgentFacilities`, data);
  return res;
};
