import { postWithAuth, getWithAuth } from '../../Basic/HttpService';

export const getAllMenuItems = async (id) => {
    let res = await getWithAuth(`NavigationMenu?roleID=${id}`); //
    return res;
};

export const roleBaseTab = async () => {
    let res = await getWithAuth(`NavigationMenu/RoleBasedMenu`);
    return res;
};
export const getMenuTab = async () => {
    let res = await getWithAuth(`NavigationMenu/getroles`);
    return res;
};
export const getMenuItemsById = async (id) => {
    let res = await getWithAuth(`NavigationMenu/${id}`);
    return res;
};

export const postMenuItems = async (data) => {
    let res = await postWithAuth(`NavigationMenu`, data);
    return res;
};

export const getMenuItemsDropdown = async (id, menuid) => {
    let res = '';
    if (menuid) {
        res = await getWithAuth(`NavigationMenu/screenDropdowns?roleID=${id}&menuID=${menuid}`);
    } else {
        res = await getWithAuth(`NavigationMenu/screenDropdowns?roleID=${id}`);
    }
    return res;
};
