import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import { DateTimePicker, InputText, SecondaryButton, SecondaryCheckbox, SimpleField } from '../../../../../../Core/Controls';
import { useCallback, useState } from 'react';
import { addUpdateJobUpdateNote } from '../../../../../../Core/Services/InternalUser/JOB/jobQueryService';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import moment from 'moment';
const FlaggedBox = (props) => {
    return (
        <>
            <InputLabel shrink>{props.label}</InputLabel>
            <Grid container alignItems="flex-end">
                <Grid item xs={1}>
                    <SecondaryCheckbox checked={props.chkValue} onChange={props.onCheckChange} name={props.chkName} disabled={props.disabled} />
                </Grid>
                <Grid item xs={11}>
                    <SimpleField
                        placeholder={props.label}
                        value={props.txtValue}
                        onChange={props.inputChange}
                        name={props.txtName}
                        disabled={props.disabled || !props.chkValue}
                        type={props.fieldType || 'text'}
                    />
                </Grid>
            </Grid>
        </>
    );
};
const AddJobUpdateNotes = (props) => {
    const { showToastError, showToastSuccess } = useToast();

    const [btnLoader, setBtnLoader] = useState({ newBtnLoader: false, extBtnLoader: false });
    const [state, setState] = useState({
        errors: {},
        jobUpdateID: props.id || null,
        jobUpdateJobID: props.jobID,
        jobUpdateReminderDate: '',
        jobUpdateNotes: '',
        jobUpdateNotesResult: '',
        jobUpdateDateCreated: '',
        jobUpdateDateClosed: moment().format('YYYY-MM-DDTHH:mm'),
        jobUpdateNotesActive: false
    });

    const inputChange = useCallback((e) => {
        const { name, value, type, checked } = e.target;
        setState((st) => ({ ...st, [name]: type === 'checkbox' ? checked : value }));
    }, []);

    const validations = () => {
        const { jobUpdateNotes, jobUpdateReminderDate, jobUpdateDateClosed, noteSendSMSNumber, noteSendSMS } = state;
        let formIsValid = true;
        let errors = {};
        if (!/[^\s]/.test(jobUpdateReminderDate)) {
            errors.jobUpdateReminderDate = 'The progress result field is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(jobUpdateNotes)) {
            errors.jobUpdateNotes = 'The note title field is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(jobUpdateDateClosed)) {
            errors.jobUpdateDateClosed = 'This field is required';
            formIsValid = false;
        }
        if (noteSendSMS) {
            if (!/[^\s]/.test(noteSendSMSNumber)) {
                errors.noteSendSMSNumber = 'The number field is required';
                formIsValid = false;
            }
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations) {
            let formData;
            formData = {
                jobUpdateNotes: state.jobUpdateNotes,
                jobUpdateReminderDate: state.jobUpdateReminderDate,
                jobUpdateNotesResult: '',
                noteSendSMSNumber: state.noteSendSMSNumber || '',
                noteSendSMS: state.noteSendSMS || false,
                jobUpdateID: null,
                jobUpdateJobID: props.jobID
            };
            setBtnLoader((st) => ({ ...st, extBtnLoader: true }));
            let res = await addUpdateJobUpdateNote(formData);
            if (res.success) {
                showToastSuccess('Notes Added Successfully!');
                props.onFormSubmit(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader((st) => ({ ...st, extBtnLoader: false }));
        }
    };
    const resetInput = (name) => {
        setState((st) => ({ ...st, [name]: '' }));
    };
    return (
        <div>
            <Grid item container xs={12} sm={12} spacing={2}>
                <Grid item xs={12} sm={12} className="mandatory-fields">
                    <InputLabel shrink>New Reminder Notes</InputLabel>
                    <InputText multiline rows={3} placeholder="New Reminder Notes" name="jobUpdateNotes" onChange={inputChange} />
                    <FormHelperText error>{state.errors.jobUpdateNotes}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} className="mandatory-fields">
                    <InputLabel shrink>Reminder Start Date & Time</InputLabel>
                    <DateTimePicker
                        placeholder="Reminder Start Date & Time"
                        value={state.jobUpdateReminderDate}
                        onChange={inputChange}
                        name="jobUpdateReminderDate"
                    />
                    <FormHelperText error>{state.errors.jobUpdateReminderDate}</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <FlaggedBox
                        label={`Send text `}
                        chkName="noteSendSMS"
                        chkValue={state.noteSendSMS}
                        onCheckChange={inputChange}
                        txtName="noteSendSMSNumber"
                        txtValue={state.noteSendSMSNumber}
                        inputChange={inputChange}
                        resetInput={resetInput}
                        disabled={state.isDisable}
                        // fieldType="number"
                    />
                    <FormHelperText error>{state.errors.noteSendSMSNumber}</FormHelperText>
                </Grid>
            </Grid>
            <Grid item container xs={12} sm={12} spacing={2}>
                <Grid item xs={12}>
                    <SecondaryButton isBtnLoader={btnLoader.extBtnLoader} onClick={handleSubmit} fullWidth>
                        Create New Reminder
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};
export default AddJobUpdateNotes;
