import * as Components from '../../../../Components';
import { TypeOfAuth } from '../menu-auth-type';
import DashboardIcon from '@material-ui/icons/Dashboard';
import InfoIcon from '@material-ui/icons/Info';

export default [
    {
        label: 'Dashboard',
        path: '/',
        exact: true,
        component: Components.LeadDashboard,
        authType: TypeOfAuth.Both,
        icon: <DashboardIcon />
    },
    {
        label: 'Enquires', // this is called screen
        path: '/Enquiries',
        authType: TypeOfAuth.Both,
        icon: <InfoIcon />,
        children: []
    },
    {
        label: 'New Enquires',
        path: '/enquiries/new_enquiry',
        exact: true,
        component: () => {
            return <Components.LeadEnquiriesScreen id={1} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Live Enquires',
        path: '/enquiries/live_enquiry',
        exact: true,
        component: () => {
            return <Components.LeadEnquiriesScreen id={2} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Lost Enquires',
        path: '/enquiries/lost_enquiry',
        exact: true,
        component: () => {
            return <Components.LeadEnquiriesScreen id={4} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Successful Enquires',
        path: '/enquiries/successful_enquiry',
        exact: true,
        component: () => {
            return <Components.LeadEnquiriesScreen id={3} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Edit Enquiry',
        path: '/Enquiries/edit/:id?',
        exact: true,
        hidden: true,
        component: Components.EnquiryEditModal,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Add Enquiry',
        path: '/Enquiries/add/:id?',
        exact: true,
        hidden: true,
        component: Components.AddEnquiries,
        authType: TypeOfAuth.Auth
    }
];
