import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { InputLabel, FormHelperText } from "@material-ui/core";
import { InputText, SecondaryButton, MandatoryField } from "../../../../Core/Controls";
import { getProductGroupsById, postProductGroupsData } from "../../../../Core/Services/InternalUser/productGroupService";
import { useToast } from "../../../../Core/Hooks/useToast";
import "../../.././commonStyle.scss";

function ProductGroupModal(props) {
  const [state, setState] = useState({
    productGroupID: null,
    productGroupDescription: "",
    productGroupMaxMargin: "",
    productGroupMinMargin: "",
    productGroupCode: "",
    productGroupLeadTime: "",
    errors: {},
  });

  const { showToastSuccess, showToastError } = useToast();

  useEffect(async () => {
    if (props.productGroupID) {
      let res = await getProductGroupsById(props.productGroupID);
      if (res.success) {
        setState((state) => ({
          ...state,
          productGroupID: res.data.productGroupID,
          productGroupDescription: res.data.productGroupDescription,
          productGroupMaxMargin: res.data.productGroupMaxMargin,
          productGroupMinMargin: res.data.productGroupMinMargin,
          productGroupCode: res.data.productGroupCode,
          productGroupLeadTime: res.data.productGroupLeadTime,
        }));
      }
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const validations = () => {
    const { productGroupDescription, productGroupMaxMargin, productGroupMinMargin, productGroupCode, productGroupLeadTime } = state;
    let emptyStrigPattern = /[^\s]/;
    let formIsValid = true;
    let errors = {};
    if (!emptyStrigPattern.test(productGroupDescription)) {
      errors.productGroupDescription = "Description is required";
      formIsValid = false;
    }
    if (!emptyStrigPattern.test(productGroupMaxMargin)) {
      errors.productGroupMaxMargin = "Max margin is required";
      formIsValid = false;
    }
    if (!emptyStrigPattern.test(productGroupMinMargin)) {
      errors.productGroupMinMargin = "Min margin is required";
      formIsValid = false;
    }
    if (!emptyStrigPattern.test(productGroupCode)) {
      errors.productGroupCode = "Code is required";
      formIsValid = false;
    }
    if (!emptyStrigPattern.test(productGroupLeadTime)) {
      errors.productGroupLeadTime = "Lead time is required";
      formIsValid = false;
    }
    setState((state) => ({ ...state, errors: errors }));
    return formIsValid;
  };
  const submitHandler = async () => {
    if (validations()) {
      const productGroupData = {
        productGroupID: state.productGroupID,
        productGroupDescription: state.productGroupDescription,
        productGroupMaxMargin: state.productGroupMaxMargin,
        productGroupMinMargin: state.productGroupMinMargin,
        productGroupCode: state.productGroupCode,
        productGroupLeadTime: state.productGroupLeadTime,
      };
      let res = await postProductGroupsData(productGroupData);
      if (res.success) {
        showToastSuccess("Product groups added sucessfully");
        props.onClose(true);
      } else {
        showToastError(res.message);
      }
    }
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} className="mandatory-fields">
        <MandatoryField
          inputLabel="Description"
          name="productGroupDescription"
          placeholder="Description"
          onChange={handleInputChange}
          value={state.productGroupDescription}
          errors={state.errors.productGroupDescription}
        />
      </Grid>

      <Grid item xs={6} className="mandatory-fields">
        <MandatoryField
          inputLabel="Max Margin"
          type="number"
          name="productGroupMaxMargin"
          placeholder="Max Margin"
          onChange={handleInputChange}
          value={state.productGroupMaxMargin}
          errors={state.errors.productGroupMaxMargin}
        />
      </Grid>

      <Grid item xs={6} className="mandatory-fields">
        <MandatoryField
          inputLabel="Min Margin"
          type="number"
          name="productGroupMinMargin"
          placeholder="Min Margin"
          onChange={handleInputChange}
          value={state.productGroupMinMargin}
          errors={state.errors.productGroupMinMargin}
        />
      </Grid>
      <Grid item xs={6} className="mandatory-fields">
        <MandatoryField
          inputLabel="Code"
          name="productGroupCode"
          placeholder="Code"
          onChange={handleInputChange}
          value={state.productGroupCode}
          errors={state.errors.productGroupCode}
        />
      </Grid>
      <Grid item xs={6} className="mandatory-fields">
        <MandatoryField
          inputLabel="Lead Time"
          type="number"
          name="productGroupLeadTime"
          placeholder="Lead Time"
          onChange={handleInputChange}
          value={state.productGroupLeadTime}
          errors={state.errors.productGroupLeadTime}
        />
      </Grid>

      <Grid item xs={12} className="submit_btn">
        <SecondaryButton onClick={submitHandler}>{props.productGroupID ? "Update" : "Add"}</SecondaryButton>
      </Grid>
    </Grid>
  );
}

export default ProductGroupModal;
