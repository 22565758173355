import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, ComposedChart } from 'recharts';
import { PieChart, Pie, Sector, LabelList } from 'recharts';

const barColors = {
    0: '#0074D9',
    1: '#FF4136',
    2: '#2ECC40',
    3: '#FF851B',
    4: '#7FDBFF',
    5: '#B10DC9',
    6: '#FFDC00',
    7: '#001f3f',
    8: '#39CCCC',
    9: '#01FF70',
    10: '#85144b',
    11: '#F012BE',
    12: '#3D9970',
    13: '#111111',
    14: '#AAAAAA'
};
const renderCurrencyLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
        <g>
            <text x={x + width / 2} y={y - radius} fill="#000000" textAnchor="middle" dominantBaseline="middle">
                {value ? <>£{value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}
            </text>
        </g>
    );
};

const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
        <g>
            <text x={x + width / 2} y={y - radius} fill="#000000" textAnchor="middle" dominantBaseline="middle">
                {value}
            </text>
        </g>
    );
};

export const ReportingBarChart = (props) => {
    const { list, label, hideCurrnecy } = props;
    return (
        <div>
            <div style={{ height: 250, width: '100%' }}>
                {list.length > 0 ? (
                    <ResponsiveContainer>
                        <BarChart
                            data={list}
                            margin={{
                                top: 20,
                                right: 10,
                                left: 10,
                                bottom: 20
                            }}
                        >
                            <XAxis
                                dataKey="name"
                                style={{ fontSize: 10, fontWeight: 600 }}
                                textAnchor="end"
                                sclaeToFit="true"
                                verticalAnchor="start"
                                interval={0}
                                angle={props.small ? '-15' : '0'}
                            />
                            <YAxis />
                            <Tooltip contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }} position={{ y: 20 }} />
                            <Legend />
                            {hideCurrnecy ? (
                                <Bar dataKey="Total Days" fill={'White'} barSize={30} legendType="none">
                                    <LabelList dataKey="Total Days" content={renderCustomizedLabel} />
                                    {list.map((entry, index) => {
                                        if (props.handleEditRegClick) {
                                            return (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={barColors[index]}
                                                    onClick={() => {
                                                        props.handleEditRegClick(entry, props.setval);
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            );
                                        } else {
                                            return <Cell key={`cell-${index}`} fill={barColors[index]} />;
                                        }
                                    })}
                                </Bar>
                            ) : (
                                <Bar dataKey="total" barSize={40}>
                                    <LabelList dataKey="total" content={renderCurrencyLabel} />
                                    {list.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={barColors[index]}
                                            // onClick={() => {
                                            //     handleDetailpopUp(entry, ' Depots');
                                            // }}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    ))}
                                </Bar>
                            )}
                        </BarChart>
                    </ResponsiveContainer>
                ) : (
                    <Grid item container justifyContent="space-evenly" alignContent="center">
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <div>No Records Found</div>
                        </div>
                    </Grid>
                )}
            </div>
        </div>
    );
};
