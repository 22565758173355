import { Grid, InputLabel } from '@material-ui/core';
import React, { useState } from 'react';
import { DateTimePicker, PrimaryButton, SecondaryButton, SingleSelect } from '../../../../../../Core/Controls';
import { Alert } from '@material-ui/lab';
import { postFactionInspection, postJobCostReviewed } from '../../../../../../Core/Services/InternalUser/ControllerService/jobStepperService';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const FactoryInspection = ({ handleNext, onClose, isServiceAgent, jobDetails, isDisabled, isinternal }) => {
    const { showToastWarning, showToastError, showToastSuccess } = useToast();
    const history = useHistory();
    const [btnLoader, setBtnLoader] = useState(false);

    const handleSubmit = async () => {
        setBtnLoader(true);
        let res = await postFactionInspection(jobDetails.jobID);

        if (res.success) {
            showToastSuccess(res.data.message);
            history.push('/serviceagent/FI');
            handleNext();
            setBtnLoader(false);
        } else {
            showToastError(res.message);
        }
        setBtnLoader(false);
    };
    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Alert severity="warning">
                        {
                            'Please click the Complete Factory Inspection button below if you have reviewed job and updated Factory Inspection job line with any missing data'
                        }
                    </Alert>
                </Grid>

                <>
                    <Grid item xs={6}>
                        <PrimaryButton onClick={handleSubmit} isBtnLoader={btnLoader}>
                            Yes
                        </PrimaryButton>
                    </Grid>
                    <Grid item xs={6}>
                        <SecondaryButton onClick={onClose}>No</SecondaryButton>
                    </Grid>
                </>
            </Grid>
        </div>
    );
};

export default FactoryInspection;
